<template>
  <div 
    class="base-dropdown" 
    :role="role"
  >
    <transition name="drop">
      <div 
        v-if="opened && !mobileMode" 
        v-click-outside="close"
        class="dropdown" 
        :class="[directionTest, positionTest]" 
      >
        <slot v-if="opened && !mobileMode"></slot>
      </div>
    </transition>
    <base-modal 
      :open="opened && mobileMode" 
      class="dropdown-modal" 
      :header="header"
      @close="close" 
    >
      <template #input>
        <slot name="input"></slot>
      </template>
      <slot v-if="opened && mobileMode"></slot>
    </base-modal>
  </div>
</template>

<script>
import { isMobileMode } from '@/utils';

export default {
  name: 'BaseDropdown',
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'listbox',
    },
    header: {
      type: String,
    },
    direction: {
      type: String,
    },
    position: {
      type: String,
    },
  },
  emits:['open', 'close'],
  data() {
    return {
      mobileMode: isMobileMode(),
      element: null,
    };
  },
  computed: {
    directionTest() {
      if (this.element && !this.direction && this.opened) {
        const top = this.$el.getBoundingClientRect().top;
        const mid = window.innerHeight / 2;
        return top < mid ? 'down' : 'up';
      }
      return this.direction;
    },
    positionTest() {
      if (this.position) {
        return this.position;
      }
      if (this.element) {
        const right = this.$el.getBoundingClientRect().right;
        const mid = window.innerWidth / 2;
        return right < mid ? 'right' : 'left';
      }
      return 'left';
    },
  },
  mounted() {
    this.element = this.$el;
    this.$nextTick(() =>
      window.addEventListener('resize', () => {
        this.mobileMode = isMobileMode();
      }));
  },
  methods: {
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>


<style scoped lang="scss">
@import "@/styles/mixins.scss";

.dropdown {
  min-width: 50px;
  overflow-y: auto;
  z-index: 1500;
  position: absolute;
  border-radius: 2px;
  transform: translateY(0);
  font-size: 14px;
  opacity: 1;

  @include not-phone {
    &.down {
      transform-origin: top;
    }
    &.up {
      bottom: 100%;
      transform-origin: bottom;
    }

    &.right {
      left: 0;
    }

    &.left {
      right: 0;
    }
  }

  @include phone {
    display: none;
  }
}

.dropdown-modal {
  @include not-phone {
    display: none;
  }
}

.drop-enter-active, .drop-leave-active {
  transition: all 0.3s;
}

.drop-enter-from, .drop-leave-to {
  &.down {
    transform: translateY(-1em);
  }
  &.up {
    transform: translateY(1em);
  }
  opacity: 0;
}
</style>
