<template>
  <div>
    <template v-if="disabled">
      <base-attributes 
        sectionTitleLabel="directory_server" 
        :attributes="serverAttributes"
      >
        <template #default="slotProps">
          <copyable-field 
            :modelValue="slotProps.att.value" 
            :disabled="!slotProps.att.copyable" 
            :sensitive="slotProps.att.sensitive" 
            :onHover="!mobile" 
            rawValue
          ></copyable-field>
        </template>
      </base-attributes>
      <base-attributes 
        sectionTitleLabel="user_lookup" 
        :attributes="userAttributes"
      >
        <template #default="slotProps">
          <copyable-field 
            :modelValue="slotProps.att.value" 
            :disabled="!slotProps.att.copyable" 
            :sensitive="slotProps.att.sensitive" 
            :onHover="!mobile" 
            rawValue
          ></copyable-field>
        </template>
      </base-attributes>
    </template>
    <div 
      v-else 
      class="org-ldap"
    >
      <list-row noActions>
        <list-column allowOverflow>
          <h3>{{ $t('directory_server') }}</h3>
          <form-row label="host">
            <base-input 
              :modelValue="ldap.host" 
              :autofocus="true"
              @update:modelValue="$emit('updateLdapField', 'host', $event)" 
            />
          </form-row>
          <form-row label="port">
            <base-input 
              type="number" 
              :modelValue="ldap.port" 
              @update:modelValue="$emit('updateLdapField', 'port', parseInt($event, 10))" 
            />
          </form-row>
          <form-row label="connect_timeout">
            <base-input 
              type="number" 
              :modelValue="ldap.connectTimeout" 
              @update:modelValue="$emit('updateLdapField', 'connectTimeout', parseInt($event, 10))" 
            />
          </form-row>
          <form-row label="auth_method">
            <base-select 
              :modelValue="ldap.authMethod" 
              :items="authMethods" 
              @update:modelValue="$emit('updateLdapField', 'authMethod', $event)"
            />
          </form-row>
          <form-row label="bind_dn">
            <base-input 
              :modelValue="ldap.bindDn" 
              @update:modelValue="$emit('updateLdapField', 'bindDn', $event)"
            />
          </form-row>
          <form-row label="bind_password">
            <base-input 
              type="password" 
              :modelValue="ldap.bindPassword" 
              @update:modelValue="$emit('updateLdapField', 'bindPassword', $event)"
            />
          </form-row>
          <form-row label="encryption">
            <base-select 
              :modelValue="ldap.encryption" 
              :items="encryptions"
              @update:modelValue="$emit('updateLdapField', 'encryption', $event)"
            />
          </form-row>
          <template v-if="mobile">
            <h3>{{ $t('user_lookup') }}</h3>
            <form-row label="base_dn">
              <base-input 
                :modelValue="ldap.baseDn" 
                @update:modelValue="$emit('updateLdapField', 'baseDn', $event)"
              />
            </form-row>
            <form-row label="scope">
              <base-select 
                :modelValue="ldap.scope" 
                :items="scopes" 
                @update:modelValue="$emit('updateLdapField', 'scope', $event)"
              />
            </form-row>
            <form-row label="filter">
              <base-input 
                :modelValue="ldap.filter" 
                @update:modelValue="$emit('updateLdapField', 'filter', $event)"
              />
            </form-row>
            <form-row label="query_timeout">
              <base-input 
                type="number" 
                :modelValue="ldap.queryTimeout" 
                @update:modelValue="$emit('updateLdapField', 'queryTimeout', parseInt($event, 10))"
              />
            </form-row>
          </template>
        </list-column>
        <list-column 
          v-if="!mobile" 
          class="user-lookup"
        >
          <h3>{{ $t('user_lookup') }}</h3>
          <form-row label="base_dn">
            <base-input 
              :modelValue="ldap.baseDn" 
              @update:modelValue="$emit('updateLdapField', 'baseDn', $event)"
            />
          </form-row>
          <form-row label="scope">
            <base-select 
              :modelValue="ldap.scope" 
              :items="scopes" 
              @update:modelValue="$emit('updateLdapField', 'scope', $event)"
            />
          </form-row>
          <form-row label="filter">
            <base-input 
              :modelValue="ldap.filter" 
              @update:modelValue="$emit('updateLdapField', 'filter', $event)"
            />
          </form-row>
          <form-row label="query_timeout">
            <base-input 
              type="number" 
              :modelValue="ldap.queryTimeout" 
              @update:modelValue="$emit('updateLdapField', 'queryTimeout', parseInt($event, 10))"
            />
          </form-row>
        </list-column>
      </list-row>
    </div>
  </div>
</template>
<script>

import { isMobileMode } from '@/utils';

export default {
  name: 'LdapConfiguration',
  props: {
    ldap: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['updateLdapField'],
  data() {
    return {
      authMethods: [{
        i18n: true,
        value: 'none',
        name: this.$t('none'),
        label: 'none',
      },
      {
        i18n: true,
        value: 'Simple',
        name: this.$t('simple'),
        label: 'simple',
      }],
      encryptions: [{
        i18n: true,
        value: 'none',
        name: this.$t('none'),
        label: 'none',
      }],
      scopes: [{
        i18n: true,
        value: 'none',
        name: this.$t('none'),
        label: 'none',
      },
      {
        i18n: true,
        value: 'subtree',
        name: this.$t('subtree'),
        label: 'subtree',
      },
      {
        i18n: true,
        value: 'one_level',
        name: this.$t('one_level'),
        label: 'one_level',
      }],
    };
  },
  computed: {
    mobile() {
      return isMobileMode();
    },
    serverAttributes() {
      return [
        { labelKey: 'host', value: this.ldap.host, copyable: true },
        { labelKey: 'port', value: this.ldap.port, copyable: true },
        { labelKey: 'connect_timeout', value: this.ldap.connectTimeout, copyable: true },
        { labelKey: 'auth_method', value: this.ldap.authMethod, copyable: true },
        { labelKey: 'bind_dn', value: this.ldap.bindDn, copyable: true },
        { labelKey: 'bind_password', value: this.ldap.bindPassword, copyable: true, sensitive: true },
        { labelKey: 'encryption', value: this.ldap.encryption, copyable: true },
      ];
    },
    userAttributes() {
      return [
        { labelKey: 'base_dn', value: this.ldap.baseDn, copyable: true },
        { labelKey: 'scope', value: this.ldap.scope, copyable: true },
        { labelKey: 'filter', value: this.ldap.filter, copyable: true },
        { labelKey: 'query_timeout', value: this.ldap.queryTimeout, copyable: true },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
.org-ldap {
  :deep(.list-row) {
    padding: 15px 0;
    .list-column {
      padding-left: 0px;
      flex: 1 1 350px;
    }
  }
}

.form-row {
  width: 100%;
}

.list-row:hover {
  background-color: unset;
}

.user-lookup {
  align-self: flex-start;
}
</style>
