<template>
  <div>
    <QuotaForm 
      :initialQuota="quota" 
      :errors="errors" 
      submitLabel="submit" 
      @submit="updateQuota"
    />
  </div>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import QuotaForm from './QuotaForm';

export default {
  name: 'EditQuota',
  components: { QuotaForm },
  props: {
    quota: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated-quota'],
  data() {
    return {
      errors: {
        name: '',
      },
    };
  },
  methods: {
    async updateQuota(quota) {
      this.errors.name = '';
      const resp = await apis.quotas.update(quota.id, quota);
      if (resp.ok) {
        notify.success(this.$t('quotas.update_quota_success'));
        this.$emit('updated-quota', resp.data);
        this.$router.push(`/admin/quotas/${quota.id}/details`);
      } else {
        if (resp.errors.map(c => c.errorCode).includes('ALREADY_EXISTS')) {
          this.errors.name = this.$t('name_already_exists');
        }
        notify.error(this.$t('quotas.update_quota_error'));
      }
    },
  },
};
</script>
