<template>
  <base-loader v-if="loading"></base-loader>
  <div v-else>
    <div 
      v-if="canCreate && hasInheritedBranding" 
      class="message-container"
    >
      <div class="message">
        <empty-message
          icon="fa fa-lightbulb-o"
          :textLabel="createTextLabel"
          buttonIcon="fa fa-plus-circle"
          :buttonLabel="createButtonLabel"
          :buttonRoute="{ name: 'create' }"
        />
      </div>
    </div>
    <div v-else-if="hasInheritedBranding">
      {{ $t('branding.inherited') }}
    </div>
    <div v-else-if="branding">
      <BrandingDetail 
        :branding="branding" 
        @deleted="loadOrganizationBranding"
      />
    </div>
  </div>
</template>

<script>
import authz from '@/authz';
import notify from '@/utils/notify';
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import BrandingDetail from './BrandingDetail';

export default {
  name: 'BrandingView',
  components: { BrandingDetail },
  data() {
    return {
      branding: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization', 'userContext']),
    hasInheritedBranding() {
      return (this.branding || {}).factory
        || (this.branding || {}).organization.id !== this.selectedOrganization.id;
    },
    hasPermissionCreateDefault() {
      return authz.hasPermission('global:reseller:branding')
        && this.selectedOrganization.id === this.userContext.org.id;
    },
    createTextLabel() {
      return this.hasPermissionCreateDefault ? 'branding.no_default' : 'branding.inherited';
    },
    createButtonLabel() {
      return this.hasPermissionCreateDefault ? 'branding.create_default' : 'branding.create';
    },
    canCreate() {
      return this.branding.factory
        || (this.branding.organization.id !== this.selectedOrganization.id);
    },
  },
  async created() {
    this.loading = true;
    await this.loadOrganizationBranding();
    this.loading = false;
  },
  methods: {
    async loadOrganizationBranding() {
      const resp = await apis.branding.getEffective(this.selectedOrganization.id);
      if (!resp) {
        notify.error(this.$t('unexpected_error'));
      } else if (resp.status === 400 && resp.errors) {
        resp.errors.forEach(err => notify.error(this.$t(err.message)));
      } else {
        this.branding = resp.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .message-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    max-width: 700px;
  }
</style>
