import { CrudApi } from './api';

class BillingSettings extends CrudApi {
  constructor() {
    super('/reseller/settings/billing');
  }

  search(organizationId) {
    return this.get('reseller/settings/billing/search', {
      qs: {
        organizationId,
      },
    });
  }

  hasIssuedInvoices(organizationId) {
    return this.get('reseller/settings/billing/hasIssuedInvoices', {
      qs: {
        organizationId,
      },
    });
  }
}
const billingSettings = new BillingSettings();
export default billingSettings;
