import ApiDocsSection from './ApiDocsSection';
import CustomizeApiDocsView from './CustomizeApiDocsView'

export default [
    {
        path: 'api-docs',
        component: ApiDocsSection,
        meta: {
            protected: ['reseller:apiDocs'],
            title: () => 'api_docs',
        },
        children: [
            {
                name: 'CustomizeApiDocsView',
                path: '',
                component: CustomizeApiDocsView,
            },
        ]
    }
]