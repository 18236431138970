import { subDays } from '@/utils/dates';

const DATE_FORMAT = 'YYYY-MM-DD';

export const discountMixin = {
  methods: {
    dateFormatting(date) {
      return this.$date(date, DATE_FORMAT, true);
    },
    durationFormatting(discount, discountType) {
      const type = discountType || discount.type.toLowerCase();
      const duration = discount.durationDays;
      const renewable = discount.isRenewable;
      const renewDurationMonths = discount.renewDurationMonths;
      const renewableOccurrences = discount.renewOccurrences;

      if (!duration) {
        if (!renewable) {
          return this.$t(`monetization.discounts.${type}.no_duration`);
        } else if(renewableOccurrences === -1) {
          return this.$tc(`monetization.discounts.${type}.renewal_frequency`, renewDurationMonths, {frequency: renewDurationMonths})
        } else {
          return this.$tc(`monetization.discounts.${type}.renewal_frequency_with_occurrences`, renewDurationMonths, {frequency: renewDurationMonths, occurrence: renewableOccurrences})
        }
      }
      return duration > 1
        ? this.$t('monetization.discounts.lasts_days', { duration })
        : this.$t('monetization.discounts.lasts_day', { duration });
    },
    scopeFormatting(scope) {
      if (scope === 'ALL_PRODUCTS') {
        return this.$t('monetization.discounts.scopes.all_products');
      } else if (scope === 'CATEGORIES') {
        return this.$t('monetization.discounts.scopes.categories');
      } else if (scope === 'PRODUCTS') {
        return this.$t('monetization.discounts.scopes.products');
      }
      return scope;
    },
    getStatus(discount) {
      if (discount.isDeactivated) {
        return {
          color: 'gray',
          label: 'monetization.discounts.deactivated',
        };
      }
      if (discount.status === 'CURRENT') {
        return {
          color: 'green',
          label: 'monetization.discounts.current',
        };
      } else if (discount.status === 'UPCOMING') {
        return {
          color: 'blue',
          label: 'monetization.discounts.upcoming',
        };
      } else if (discount.status === 'ENDED') {
        return {
          color: 'red',
          label: 'monetization.discounts.ended',
        };
      }
      return {
        color: 'gray',
        label: discount.status,
      };
    },
    discountStartDate(discount) {
      return this.$date((discount || {}).startDate, 'MMM DD YYYY', true);
    },
    discountEndDate(discount) {
      if (!discount || !discount.endDate) {
        return this.$t('monetization.none');
      }
      const ed = subDays((discount || {}).endDate, 1, true);
      return this.$date(ed, 'MMM DD YYYY', true);
    },
    packageDiscount(discount) {
      const label = this.$t('monetization.discounts.scopes.all_products');
      const amount = discount.packageDiscount;
      return {
        label,
        amount,
      };
    },
    findOrigins(discount) {
      if (discount.mode === 'ORGANIZATION') {
        return [this.$t('monetization.discounts.custom')];
      }

      return [discount.appliedPricing.name[this.locale]];
    },
  },
};
