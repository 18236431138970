
<template>
  <div>
    <action-dropdown
      :items="deleteAction"
      @select="openConfirmDialog = true"
      @close="$emit('close')"
    />
    <confirm-modal
      :open="openConfirmDialog"
      headerLabel="branding.delete_header"
      :detailsLabel="detailsLabel"
      headerIcon="fa fa-check-circle"
      :executing="executing"
      class="confirm-modal"
      @ok="confirmDelete"
      @cancel="openConfirmDialog = false"
    />
  </div>
</template>

<script>
import apis from '@/utils/apis';

export default {
  name: 'BrandingActions',
  props: {
    branding: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  data() {
    return {
      openConfirmDialog: false,
      executing: false,
    };
  },
  computed: {
    detailsLabel() {
      return 'branding.delete_custom';
    },
    deleteAction() {
      return [
        {
          icon: 'fa fa-trash',
          value: 'DELETE',
          label: 'branding.delete_header',
        },
      ];
    },
  },
  methods: {
    async confirmDelete() {
      this.executing = true;
      await apis.branding.remove(this.branding.id);
      this.executing = false;
      this.openConfirmDialog = false;
      this.$emit('deleted');
    },
  },
};
</script>
