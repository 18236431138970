<template>
  <advanced-details 
    v-if="!!organization" 
    :loading="loading" 
    titleLabel="native_auth"
  >
    <template 
      v-if="showManagePolicyOperations"
      #operations 
    >
      <div class="operation">
        <action-icon 
          icon="fa fa-edit" 
          tooltipLabel="edit" 
          expandOnHover 
          clickable 
          @click="invertShowForm"
        ></action-icon>
        <action-dropdown 
          v-if="!isRootOrg" 
          :items="deleteAction" 
          @select="confirmDeletion = true"
        ></action-dropdown>
        <confirm-modal 
          v-if="confirmDeletion" 
          :open="confirmDeletion"
          headerLabel="remove_password_policy" 
          detailsLabel="remove_password_policy_confirm"
          @ok="deletePolicy" 
          @cancel="confirmDeletion = false"
        ></confirm-modal>
      </div>
    </template>
    <div v-if="!showForm">
      <list-header>
        {{ $t('denied_domains') }}
      </list-header>
      <list-row>
        <list-column>
          {{ securitySettings.blockedNativeLoginDomain || $t('none') }}
        </list-column>
      </list-row>
      <list-header>
        {{ $t('password_complexity_policy') }}
      </list-header>
      <list-row 
        v-for="policy in passwordPolicy.constraints" 
        :key="policy.name" 
        noBorder 
        noActions
      >
        <list-column 
          :size="3" 
          textColumn 
          class="look-disabled"
        >
          {{ $t(policy.name) }}
        </list-column>
        <list-column 
          :size="1" 
          textColumn
        >
          {{ policy.isMandatory ? policy.value : $t('not_available') }}
        </list-column>
      </list-row>
      <div v-if="isParentPolicy && organization.isReseller">
        <empty-message 
          :icon="'fa fa-lightbulb-o'" 
          :textLabel="'password_policy_info'"
        ></empty-message>
        <base-button @click="invertShowForm">
          <base-icon :icon="'fa fa-plus-circle'" /> {{ $t('create_password_policy_button') }}
        </base-button>
      </div>
    </div>
    <div v-else>
      <NativeConfiguration 
        :blockedNativeLoginDomain="securitySettings.blockedNativeLoginDomain" 
        :passwordPolicy="passwordPolicy" 
        @submit="submit" 
        @cancel="cancel"
      />
    </div>
  </advanced-details>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import authz from '@/authz';
import ListColumn from '@/components/list/ListColumn';
import ListRow from '@/components/list/ListRow';
import BaseButton from '@/components/BaseButton';
import NativeConfiguration from './NativeConfiguration';

export default {
  name: 'NativeConfigurationView',
  components: {
    ListColumn,
    ListRow,
    BaseButton,
    NativeConfiguration,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: true,
      executing: false,
      // this will main the original copy fetched from api call
      passwordPolicy: {
        constraints: [],
        isParentPolicy: false,
      },
      loading: false,
      securitySettings: {},
      showForm: false,
      confirmDeletion: false,
    };
  },
  computed: {
    deleteAction() {
      return [{
        value: 'delete',
        label: 'delete',
        icon: 'fa fa-trash',
      }];
    },
    isParentPolicy() {
      return this.passwordPolicy.isParentPolicy ?
        this.passwordPolicy.isParentPolicy : false;
    },
    isRootOrg() {
      return this.organization.isReseller &&
        !this.organization.parent &&
        !this.organization.deleted;
    },
    showManagePolicyOperations() {
      return !this.isParentPolicy &&
        !this.showForm &&
        authz.hasPermission('reseller:authentication');
    },
  },
  async created() {
    this.loading = true;
    await this.fetchOrgSecuritySettings();
    this.loading = false;
  },
  methods: {
    invertShowForm() {
      this.showForm = !this.showForm;
    },
    cancel() {
      this.fetchOrgSecuritySettings();
      this.invertShowForm();
    },
    async submit(updatedPolicy) {
      const newSec = {
        ...this.securitySettings,
        blockedNativeLoginDomain: updatedPolicy.updatedBlockedNativeLoginDomain,
        passwordPolicy: {
          constraints: updatedPolicy.updatedConstraints,
        },
      };
      const resp = await apis.organizations.updateSecuritySettings(this.organization.id, newSec);
      if (resp && resp.errors) {
        notify.error(this.$t('password_policy_updated_error'));
      } else {
        notify.success(this.$t('password_policy_updated_success'));
        await this.fetchOrgSecuritySettings();
      }
      this.invertShowForm();
    },
    async deletePolicy() {
      const resp = await apis.organizations.deletePasswordPolicy(this.organization.id);
      if (resp && resp.errors) {
        notify.error(this.$t('password_policy_updated_error'));
      } else {
        notify.success(this.$t('password_policy_updated_success'));
        await this.fetchOrgSecuritySettings();
      }
      this.confirmDeletion = false;
    },
    async fetchOrgSecuritySettings() {
      if (authz.hasPermission('reseller:*')) {
        const resp = await apis.organizations.getSecuritySettings(this.organization.id);
        if (resp.status !== 200 || !resp.data) {
          notify.error(this.$t('unexpected_error'));
        } else {
          this.securitySettings = resp.data;
          this.passwordPolicy = resp.data.passwordPolicy;
        }
      } else {
        const resp = await apis.organizations.getPasswordPolicy(this.organization.id);
        if (resp.status !== 200 || !resp.data) {
          notify.error(this.$t('unexpected_error'));
        } else {
          this.passwordPolicy = { constraints: { ...resp.data } };
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">

.operation {
  display: flex;
  flex-direction: row;
}
</style>
