<template>
  <a 
    :href="authUrl" 
    class="login-button oidc-login-button fake-a" 
    :style="css"
  >
    <div class="button-container">
      <base-image 
        height="16" 
        :src="logo" 
        :altText="displayName"
      />
      <p class="display-name">{{ $t('sign_in_with', { displayName }) }}</p>
    </div>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

const DEFAULT_PROVIDERS = {
  GOOGLE: { logo: '/static/img/google_signin.svg', displayName: 'Google' },
  MICROSOFT: { logo: '/static/img/microsoft_signin.svg', displayName: 'Microsoft' },
  SALESFORCE: { logo: '/static/img/salesforce_signin.svg', displayName: 'Salesforce', css: 'color:white;background-color:#00a1e0;border-color:#00a1e0;' },
  AUTH0: { logo: '/static/img/auth0_signin.svg', displayName: 'Auth0', css: 'color:white;background-color:#EB5424;border-color:#EB5424;' },
  OKTA: { logo: '/static/img/okta_signin.svg', displayName: 'Okta' },
};

export default {
  name: 'OidcLoginButton',
  props: {
    idp: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    isGoogle() {
      return this.idp.provider === 'GOOGLE';
    },
    isCustom() {
      return this.idp.provider === 'CUSTOM';
    },
    isMicrosoft() {
      return this.idp.provider === 'MICROSOFT';
    },
    isAuth0() {
      return this.idp.provider === 'AUTH0';
    },
    authUrl() {
      const state = encodeURIComponent(`path=${this.path},locale=${this.locale}`);
      return `${this.idp.authUrl},${state}`;
    },
    displayName() {
      if (this.isCustom) {
        return this.idp.displayName;
      }
      return DEFAULT_PROVIDERS[this.idp.provider].displayName;
    },
    logo() {
      if (this.isCustom) {
        return this.idp.logo;
      }
      return DEFAULT_PROVIDERS[this.idp.provider].logo;
    },
    css() {
      if (this.isCustom) {
        return this.idp.css;
      }
      return DEFAULT_PROVIDERS[this.idp.provider].css;
    },
  },
};
</script>

<style lang="scss" scoped>
.oidc-login-button {
  border: 1px solid black;
  border-radius: 24px;
  color: black;
  width: 100%;
  display: flex;

  .button-container {
    position: relative;
    padding-left: 24px;
    margin-left: calc(50% - 75px);
  }

  .display-name {
    padding-left: 5px;
    font-size: 14px;
    padding-right: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: left;
  }

  img {
    left: -5px;
    position: absolute;
    top: 12px;
  }

}
</style>
