<template>
  <div>
    <div 
      class="i18n-toggle" 
      :class="[{'multi-language': hasMultipleLangs}]"
    >
      <slot :lang="lang"></slot>
      <div :class="containerStyle">
        <template v-if="languageItems.length === 1">
        </template>
        <template v-else-if="!showSelect">
          <div 
            v-for="l in languageItems"
            :key="l.value" 
            tabindex="0"
            class="clickable" 
            :class="[{ active: l.value === lang }, languageStyle]"
            @click="switchLang(l.value)"
            @keyup.enter="switchLang(l.value)"
          >
            {{ $t(l.label) }}
            <div 
              v-if="missingTranslation(l.value)" 
              class="required"
            >
              *
            </div>
          </div>
        </template>
        <base-select 
          v-else 
          v-model="lang" 
          :items="languageItems" 
          placeholder="test"
        />
      </div>
    </div>
    <language-indicator 
      v-if="showCompletionBadge" 
      :requiredLanguages="requiredLanguages"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'I18nToggle',
  props: {
    requiresAll: {
      type: Boolean,
      default: false,
    },
    requiredLanguages: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['switched'],
  data() {
    return {
      isMarkdownElement: false,
      isCodeEditorElement: false,
      containerStyle: 'language-container',
      languageStyle: 'language',
      selectedLang: null,
    };
  },
  computed: {
    ...mapGetters(['orgBrandingInfo']),
    lang: {
      get() {
        return this.selectedLang || this.orgBrandingInfo.activeLanguages[0];
      },
      set(newValue) {
        this.selectedLang = newValue;
      },
    },
    languageItems() {
      return this.orgBrandingInfo.activeLanguages.map(l => ({
        value: l,
        label: `languages.${l}.short`,
        required: this.requiresAll && !this.requiredLanguages[l],
      }));
    },
    showSelect() {
      return this.languageItems.length >= 3 && !this.isMarkdownElement && !this.isCodeEditorElement;
    },
    missingTranslation() {
      return elementLang => this.requiresAll && !this.requiredLanguages[elementLang];
    },
    showCompletionBadge() {
      return this.requiresAll && this.showSelect;
    },
    hasMultipleLangs() {
      return this.languageItems.length > 1;
    },
  },
  mounted() {
    if (!this.$el.querySelector) {
      return;
    }
    this.isMarkdownElement = this.$el.querySelector('.toastui-editor-defaultUI');
    this.isCodeEditorElement = this.$el.querySelector('.v-codemirror');
    if (this.isMarkdownElement) {
      this.containerStyle = 'language-container-markdown';
      this.languageStyle = 'language-markdown';
    }
    if (this.isCodeEditorElement) {
      this.containerStyle = 'language-container-codeeditor';
      this.languageStyle = 'language-codeeditor';
    }
  },
  methods: {
    switchLang(value) {
      this.lang = value;
      const input = this.$el.querySelector('input');
      if (input) {
        input.focus();
      }
      this.$emit('switched');
    },
  },
};
</script>

<style scoped lang="scss">
.multi-language {
  :deep(input) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.i18n-toggle {
  display: flex;
  align-items: stretch;
  position: relative;
  > *:first-child {
    flex: 1;
  }
}

.language-container {
  display: flex;
}

.language-container-markdown {
  display: flex;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.language-container-codeeditor {
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 25px;
  z-index: 9;
}

.language {
  border-left-width: 0px;
  padding: 6px;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.language-markdown {
  padding: 4px;
  padding-right: 0px;
  margin: 5px;
  margin-right: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    font-weight: bold;
  }
}

.language-codeeditor {
  padding: 4px;
  padding-right: 0px;
  margin: 5px;
  margin-right: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    font-weight: bold;
  }
}

.required {
  padding-left: 2px;
}

:deep(.base-select) {
  .select {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left-width: 0px !important;
    padding-left: 12px !important;
    min-width: 52px !important;
  }
  .fa-caret-down {
    right: 10px !important;
    font-size: 12px !important;
  }
  .base-item {
    padding: 0 !important;
  }
}
</style>
