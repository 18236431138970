const TheActivity = () => import(/* webpackChunkName: "mainApp" */ './TheActivity');
const ActivityList = () => import(/* webpackChunkName: "mainApp" */ './ActivityList');

export default [
  {
    name: 'activity',
    path: 'activity',
    component: TheActivity,
    abstract: true,
    meta: {
      title: () => 'activity',
    },
    children: [
      {
        name: 'activityList',
        path: '',
        component: ActivityList,
      },
    ],
  },
];
