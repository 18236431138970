import { CrudApi } from './api';

class TrialsApi extends CrudApi {
  constructor() {
    super('trials');
  }

  getNumRemainingAutoApprovedTrials(organizationId) {
    return this.get('/trials/remaining', { qs: { organization_id: organizationId } });
  }

  selfExtendTrial() {
    return this.post('/trials/extend');
  }

  extendTrialByEmail(trialId, token) {
    return this.post(`/anon/trials/${trialId}/extend`, {
      headers: { 'Content-Type': 'application/json' },
      body: token,
    });
  }

  setTrialPassword(token, newPassword) {
    return this.post('/anon/trials/password', { qs: { trialToken: token }, body: newPassword });
  }

  activateTrialByEmail(trialId, token) {
    return this.post(`/anon/trials/${trialId}/activate`, {
      headers: { 'Content-Type': 'application/json' },
      body: token,
    });
  }

  trialStatus(token) {
    return this.get('/anon/trials/tokenstate', { qs: { trialToken: token } });
  }

  publicCreate(obj, options) {
    const path = '/anon/trials';
    return this.post(path, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj),
      ...options,
    });
  }

  approveTrial(trialId) {
    const path = `/trials/${trialId}/activate`;
    return this.post(path);
  }

  terminateTrial(trialId, purge) {
    const path = `/trials/${trialId}/terminate`;
    return this.post(path, { qs: { purge } });
  }

  denyTrial(trialId, denyReason) {
    const path = `/trials/${trialId}/deny`;
    return this.post(path, {
      body: denyReason,
    });
  }

  extendTrial(trialId, extendDate) {
    const path = `/trials/${trialId}/extend`;
    return this.post(path, { qs: { extendDate } });
  }

  convertTrialToBillable(trialId, billableStartDate) {
    const path = `/trials/${trialId}/convertToBillable`;
    return this.post(path, { qs: { billableStartDate } });
  }

  resendEmail(trialId, email) {
    const path = `/trials/${trialId}/resend_email`;
    return this.get(path, { qs: { email } });
  }

  getTrialsRegistrationInfo() {
    const path = '/anon/trials/registrationInfo';
    return this.get(path);
  }

  getRecaptchaInfo() {
    const path = '/anon/trials/recaptchaInfo';
    return this.get(path);
  }

  isValidRecaptchaResponse(response) {
    const path = `/anon/trials/recaptchaInfo/${response}`;
    return this.get(path);
  }
}

const trials = new TrialsApi();
export default trials;
