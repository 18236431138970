// Service Accounts (Environment states)
const ERROR_PROVISIONING = 'ERROR_PROVISIONING';
const ERROR_PURGING = 'ERROR_PURGING';
const PENDING = 'PENDING';
const PENDING_PURGE = 'PENDING_PURGE';
const PROVISIONED = 'PROVISIONED';
const PROVISIONING = 'PROVISIONING';
const PURGED = 'PURGED';
const PURGING = 'PURGING';

// Organizations status
const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const DISABLED = 'DISABLED';
const DELETED = 'DELETED';

const getAccountStateObject = (state, full = false) => {
  switch (state) {
    case PROVISIONED: {
      return {
        color: 'green',
        label: 'provisioned',
        icon: 'fa-check-circle',
      };
    }
    case PENDING:
      if (full) {
        return {
          color: 'blue',
          label: 'pending',
          icon: 'fa-cog fa-spin',
          animated: true,
        };
      }
      // fall through
    case PROVISIONING: {
      return {
        color: 'blue',
        label: 'provisioning',
        icon: 'fa-cog fa-spin',
        animated: true,
      };
    }
    case ERROR_PURGING:
      if (full) {
        return {
          color: 'red',
          label: full ? 'error_purging' : 'error',
          icon: 'fa-times-circle',
        };
      }
      // fall through
    case ERROR_PROVISIONING: {
      return {
        color: 'red',
        label: 'error_provisioning',
        icon: 'fa-times-circle',
      };
    }
    case PENDING_PURGE:
      if (full) {
        return {
          color: 'grey',
          label: 'pending_purge',
          icon: 'fa-cog fa-spin',
          animated: true,
        };
      }
      // fall through
    case PURGING: {
      return {
        color: 'grey',
        label: 'purging',
        icon: 'fa-cog fa-spin',
        animated: true,
      };
    }
    case PURGED: {
      return {
        color: 'grey',
        label: 'purged',
      };
    }
    default: {
      return {
        color: 'grey',
        label: 'unknown',
      };
    }
  }
};

const getOrganizationStatusObject = (status) => {
  status = (status || '').toUpperCase();
  switch (status) {
    case ACTIVE:
      return {
        color: 'green',
        label: 'active'
      };
    case INACTIVE:
      return {
        color: 'grey',
        label: 'inactive'
      };
    case DISABLED:
      return {
        color: 'grey',
        label: 'disabled'
      };
    case DELETED:
      return {
        color: 'red',
        label: 'deleted'
      };
    default:
      return {
        color: 'grey'
      };
  }
}

export {
  ERROR_PROVISIONING,
  ERROR_PURGING,
  PENDING,
  PENDING_PURGE,
  PROVISIONED,
  PROVISIONING,
  PURGED,
  PURGING,
  getAccountStateObject,
  getOrganizationStatusObject
};
