import { createI18n } from 'vue-i18n';

import store from '@/store';
import apis from '@/utils/apis';
import merge from 'deepmerge';
import { setDateLocale } from '@/utils/dates/formatter';
import { reactive } from 'vue';

let defaultLanguage;
let activeLanguages;
let languagesList;

const i18n = createI18n({
  locale: undefined, // set locale
  messages: {}, // set locale messages
  numberFormats: { },
  allowComposition: true,
  globalInjection: true,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  postTranslation: (str, key) => {
    if (typeof str === 'string') {
      return str;
    } else if (!str) {
      return key;
    }
    return str;
  },
});

const ot = i18n.global.t;
i18n.global.t = (m, ...args) => {
  /*
    Vue-i18n doesn't support the '.' character in interpolation or our custom formatting using ||, so instead 
    we catch the errors thrown and handle the translation+interpolation ourselves
    Development and production modes differ in the error format we get (in dev its a string, in prod its an error with a code)
  */
  if (!m) {
    return '';
  }
  const formattedArgs = formatArgs(args);
  
  if (process.env.NODE_ENV == 'development') {
    return handleDevErrorMode(m, formattedArgs);
  } 
  return handleProdErrorMode(m, formattedArgs);
};
const ote = i18n.global.te;
i18n.global.te = (m, ...args) => {
  return ote((m) + '', ...args);
};

function handleDevErrorMode(m, args) {
  let braceOrPluralErr = false;
    const oerr = console.error;
    console.error = (error) => { 
      braceOrPluralErr = error.includes('Unbalanced closing brace') 
        || error.includes('Unterminated closing brace')
        || error.includes('Plural must have messages')
      if (!(braceOrPluralErr)) {
        oerr(error);
      }
    };
    let tm = ot((m) + '', ...args);
    console.error = oerr;
    if (braceOrPluralErr) {
      return manuallyInterpolateLabel(tm, args[0]);
    }
    return tm;
}

function handleProdErrorMode(m, args) {
  let tm;
  let braceOrPluralErr = false;
  try {
    tm = ot((m) + '', ...args);
  } catch (error) {
    const eCode = error.code
    if (eCode != undefined) {
      braceOrPluralErr = eCode == 6 || eCode == 7 || eCode == 11;
      if (braceOrPluralErr) {
        const msg = getLabel(m);
        return manuallyInterpolateLabel(msg, args[0]);
      }
      console.error(error);
    }   
  }
  return tm;
}

const loadedFiles = {};

const hasFileBeenLoaded = (file, lang) => loadedFiles[lang] && loadedFiles[lang].includes(file);

function addLoadedFile(file, lang) {
  if (!loadedFiles[lang]) {
    loadedFiles[lang] = [];
  }
  loadedFiles[lang].push(file);
}

function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  localStorage.setItem('locale', lang);
  apis.users.updateLocale(lang);
  store.dispatch('setLocale', lang);
  setDateLocale(lang);
  return lang;
}

function fetchPluginLabels(lang, file) {
  return apis.i18n.getPluginLabels(lang, file);
}

function fetchLabel(lang, file) {
  return apis.i18n.getLabel(lang, file);
}

const isActive = lang => lang && activeLanguages.includes(lang);

export function getBrowserLanguage() {
  const lang = navigator.languages;
  for (let i = 0; i < lang.length; i += 1) {
    if (isActive(lang[i])) {
      return lang[i];
    }
  }
  return null;
}

function getCurrentLanguage(userLang) {
  const lang = localStorage.getItem('locale');
  const browserLanguage = getBrowserLanguage();
  if (isActive(userLang)) {
    return userLang;
  } else if (isActive(lang)) {
    return lang;
  } else if (browserLanguage) {
    return browserLanguage;
  }
  return defaultLanguage;
}

export function getLanguages() {
  return languagesList;
}

export function getTranslationMap() {
  const effectiveActiveLanguages =
    store.getters.orgBrandingInfo && store.getters.orgBrandingInfo.activeLanguages ?
      store.getters.orgBrandingInfo.activeLanguages : activeLanguages;
  return reactive(effectiveActiveLanguages.reduce((acc, cur) => ({ ...acc, [cur]: undefined }), {}));
}

async function initializeMessages(loadedLanguages) {
  const locales = loadedLanguages
    .map(async o => ({
      long: (await fetchLabel(o, o)).long,
      short: o.toUpperCase(),
    }));
  const promises = await Promise.all(locales);
  const languages = promises.reduce((o, a) => {
    o[a.short.toLowerCase()] = a;
    return o;
  }, {});
  loadedLanguages.forEach(file => i18n.global.setLocaleMessage(file, { languages }));
}

export async function init(userLang) {
  if (activeLanguages) {
    return;
  }
  const info = await apis.hidden.getBrandingInfo();
  const lang = await apis.hidden.getLanguages();
  defaultLanguage = info.data.defaultLanguage;
  store.dispatch('setDefaultLocale', defaultLanguage);
  activeLanguages = info.data.activeLanguages;
  languagesList = lang.data;
  setI18nLanguage(getCurrentLanguage(userLang));
  await initializeMessages(activeLanguages);
}

export async function loadLanguageAsync(file, userLang) {
  const lang = getCurrentLanguage(userLang);
  if (!hasFileBeenLoaded(file, lang)) {
    const labels = file === 'plugins' ? await fetchPluginLabels(lang, file) : await fetchLabel(lang, file);
    const mergeOpts = {
      arrayMerge: (_, src) => src[0],
    };
    i18n.global.setNumberFormat(lang, { currency: { style: 'currency' }, decimal: { style: 'decimal' } });
    i18n.global.setLocaleMessage(lang, merge(i18n.global.getLocaleMessage(lang), labels, mergeOpts));
    addLoadedFile(file, lang);
  }
  document.documentElement.setAttribute('lang', lang);
  return lang;
}

export async function switchLanguage(lang) {
  if (i18n.global.locale === lang) {
    return Promise.resolve(lang);
  }
  localStorage.setItem('locale', lang);
  const curLang = i18n.global.locale;
  document.documentElement.setAttribute('lang', lang);
  await Promise.all((loadedFiles[curLang] || []).map(f => loadLanguageAsync(f)));
  return setI18nLanguage(lang);
}

/*
  These are helper functions in order to get a label using a key
  In the case of the label being nested the key is a string with dot delimiters
  between the levels (ex. "service.operation.create")
  localeMessages is an object, and so this helper function allows us to access the label using the key
*/
function getLabel(labelKey) {
  const localeMessages = i18n.global.getLocaleMessage(i18n.global.locale);
  return labelKey.split('.').reduce(indexOf, localeMessages);
}

function indexOf(obj,i) {
  return obj[i]
}

/*
  format interpolation values to end with ellipses if 
  they exceed a max length and don't include white spaces
*/
function formatArgs(args) {
  const MAX_LENGTH = 50;
  const formattedArgs = [];
  for (const arg of args) {
      let formattedArg = formatInterpolation(arg, MAX_LENGTH);
    formattedArgs.push(formattedArg);
  }
  return formattedArgs;
}

function formatInterpolation(arg, MAX_LENGTH) {
  let formattedArg = { ...arg };
  for (const [key, value] of Object.entries(formattedArg)) {
    if (shouldBeShortened(value)) {
      let subString = value;
      let formattedInterpolation = value;
      // handle boldified interpolations
      if (value.indexOf('<strong>') === 0) {
        const parsedSubstring = value.slice(value.indexOf('<strong>') + '<strong>'.length, value.indexOf('</strong>'));
        if (parsedSubstring.length > MAX_LENGTH) {
          subString = parsedSubstring.slice(0, MAX_LENGTH - 1);
          formattedInterpolation = `<strong title="${parsedSubstring}">` + subString + '&hellip;' + '</strong>';
        }
      }
      else if (subString.length > MAX_LENGTH) {
        subString = value.slice(0, MAX_LENGTH - 1);
        formattedInterpolation = subString + '&hellip;';
      }
      formattedArg[key] = formattedInterpolation;
    }
  }
  return formattedArg;
}

function shouldBeShortened(value) {
  return value && (typeof value === 'string' || value instanceof String) && !value.includes(' ');
}

function manuallyInterpolateLabel(message, interpolation) {
  const fieldWithOrRegex = /{\s?([\w.]+)(\s?(\|{2})\s?([\w.]+))*\s?}/g;
  const fieldWithOrReplacements = [];

  let match = fieldWithOrRegex.exec(message);
  while (match) {
    const field = match[0];
    const variableReplacementRegex = /([\w.]+)/g;
    const variableReplacements = [];
    
    let fieldVariableMatch = variableReplacementRegex.exec(field);
    while (fieldVariableMatch) {
      variableReplacements.push(fieldVariableMatch[1])
      fieldVariableMatch = variableReplacementRegex.exec(field);
    }
    
    let replaced = false;
    for (let i = 0; i < variableReplacements.length && !replaced; i++) {
      const objAttribute = interpolation[variableReplacements[i]];
      if (objAttribute != null && objAttribute != undefined) {
        fieldWithOrReplacements.push({field: field, replacement: objAttribute})
        replaced = true;
      }
    }

    match = fieldWithOrRegex.exec(message);
  }

  for (const fieldWithOrReplacement of fieldWithOrReplacements) {
    message = message.replace(fieldWithOrReplacement["field"], fieldWithOrReplacement["replacement"])
  }
  
  return message;
}
export default i18n;
