<template>
  <div class="title-container">
    <div class="secondary-title">
      <div>{{ $t('org_billing.cc_info') }}</div>
    </div>
    <div class="edit">
      <action-icon
        v-if="creditCardPresent && isMemberOfOrg"
        icon="fa fa-edit"
        tooltipLabel="edit"
        expandOnHover
        clickable
        @click="$router.push({ name: 'edit-credit-card', props: { organization: organization } });"
      />
    </div>
  </div>
  <div v-if="creditCardPresent">
    <div
      v-if="aboutToExpire || isExpired"
      class="alertWrapper"
    >
      <alert-box
        v-if="aboutToExpire"
        alertType="WARNING"
        label="org_billing.cc_exp_warning"
        border
      />
      <alert-box
        v-else-if="isExpired"
        alertType="ERROR"
        label="org_billing.cc_exp_error"
        border
      />
    </div>
    <base-attributes
      :attributes="creditCardAttributes"
      noTitle
    >
      <template #default="slotProps">
        <div>
          <copyable-field
            :modelValue="slotProps.att.value"
            :disabled="!slotProps.att.copyable"
            :onHover="!mobile"
          >
            <div v-if="slotProps.att.i18n">
              {{ $t(slotProps.att.value) }}
            </div>
            <div v-else>
              {{ slotProps.att.value }}
            </div>
          </copyable-field>
        </div>
      </template>
    </base-attributes>
  </div>
  <div
    v-else
    class="noCC"
  >
    <div>{{ $t('org_billing.no_cc_configured') }}</div>
  </div>
</template>
<script>
import { getNow, LIST_SIMPLE_DATE_FORMAT, daysBetweenDates, endOfMonth } from '@/utils/dates';
import { mapGetters } from 'vuex';
import { isMobileMode } from '@/utils';

export default {
  name: 'CreditCardInfo',
  mixins: [],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    creditCard: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['myOrganization']),
    mobile() {
      return isMobileMode();
    },
    creditCardPresent() {
      return !!this.creditCard && !!this.creditCard.cardName;
    },
    creditCardAttributes() {
      const customAttributes = [];
      if (this.creditCard.customAttribute) {
        if (this.creditCard.customAttribute.customerRefNum) {
          customAttributes.push({ labelKey: 'org_billing.cc_chase_profile_id', value: this.creditCard.customAttribute.customerRefNum });
        }
      }
      return [
        { labelKey: 'org_billing.cc_name', value: this.creditCard.cardName },
        { labelKey: 'org_billing.cc_type', value: this.creditCard.cardType },
        { labelKey: 'org_billing.cc_number', value: this.creditCard.cardMaskedNumber },
        { labelKey: 'org_billing.cc_exp', value: this.creditCard.cardExp },
        { labelKey: 'org_billing.billing_address_line1', value: this.creditCard.billingAddressLineOne, copyable: true },
        { labelKey: 'org_billing.billing_address_line2', value: this.creditCard.billingAddressLineTwo, copyable: true },
        { labelKey: 'org_billing.billing_address_city', value: this.creditCard.billingAddressCity, copyable: true },
        { labelKey: 'org_billing.billing_address_province', value: this.creditCard.billingAddressProvince, copyable: true },
        { labelKey: 'org_billing.billing_address_postalcode', value: this.creditCard.billingAddressPostalCode, copyable: true },
        { labelKey: 'org_billing.billing_address_country', value: this.creditCard.billingAddressCountry, copyable: true },
      ].concat(customAttributes);
    },
    aboutToExpire() {
      const currentDate = getNow();
      const diff = daysBetweenDates(currentDate, this.formatCardExpDate(this.creditCard.cardExp));
      return diff >= 0 && diff <= 31;
    },
    isExpired() {
      const currentDate = getNow();
      const diff = daysBetweenDates(currentDate, this.formatCardExpDate(this.creditCard.cardExp));
      return diff < 0;
    },
    isMemberOfOrg() {
      return this.myOrganization.id === this.organization.id;
    },
  },
  methods: {
    formatCardExpDate(cardExp) {
      const month = cardExp.substring(0, 2);
      const year = `20${cardExp.substring(2, 4)}`;
      return endOfMonth(this.$date([year, month, '01'].join('-'), LIST_SIMPLE_DATE_FORMAT));
    },
  },
}
</script>
<style lang="scss" scoped>
.title-container {
  display:flex;
  justify-content: space-between;
}
.secondary-title {
  margin: 0;
}
.edit {
  float: right;
}
.noCC {
  color: var(--detail-text);
  text-align: left;
  padding-bottom: 20px;
}
.alertWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>