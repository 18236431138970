import { ReadOnlyApi } from './api';

class Discounts extends ReadOnlyApi {
  constructor() {
    super('discounts');
  }

  fetchDiscountMetadata(orgId) {
    return this.get('/discounts/discount_metadata', {
      qs: {
        organization_id: orgId,
      },
    });
  }
}

const discounts = new Discounts();

export default discounts;
