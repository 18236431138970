import AuthenticationSection from './AuthenticationSection';
import IdentityProviderList from './identityproviders/IdentityProviderList';
import IdentityProviderForm from './identityproviders/IdentityProviderForm';
import IdentityProviderUserConfig from './identityproviders/IdentityProviderUserConfig';
import SAMLSettingsForm from './samlsettings/SAMLSettingsForm';
import ServiceProviderList from './serviceproviders/ServiceProviderList';
import ServiceProviderForm from './serviceproviders/ServiceProviderForm';

export default [
  {
    path: 'authentication',
    component: AuthenticationSection,
    meta: {
      protected: ['reseller:authentication'],
    },
    children: [
      {
        path: '',
        redirect: { name: 'providers-list' },
      },
      {
        name: 'providers-list',
        path: 'external-sso',
        component: IdentityProviderList,
      },
      {
        name: 'create-provider',
        path: 'external-sso/create',
        component: IdentityProviderForm,
        props: { switchOrgRouteName: 'providers-list' },
      },
      {
        name: 'edit-provider',
        path: 'external-sso/:id/edit',
        component: IdentityProviderForm,
        props: route => ({ id: route.params.id, switchOrgRouteName: 'providers-list' }),
      },
      {
        name: 'provider-user-config',
        path: 'external-sso/:id/manage_create_user',
        component: IdentityProviderUserConfig,
        props: route => ({ id: route.params.id, switchOrgRouteName: 'providers-list' }),
      },
      {
        name: 'as-sso',
        path: 'as-sso',
        redirect: { name: 'service-providers' },
      },
      {
        name: 'create-service-provider',
        path: 'as-sso/service-providers/create',
        component: ServiceProviderForm,
        props: { switchOrgRouteName: 'service-providers' },
      },
      {
        name: 'edit-service-provider',
        path: 'as-sso/service-providers/:id/edit',
        component: ServiceProviderForm,
        props: route => ({ id: route.params.id, switchOrgRouteName: 'service-providers' }),
      },
      {
        name: 'service-providers',
        path: 'as-sso/service-providers',
        component: ServiceProviderList,
      },
      {
        name: 'saml-settings',
        path: 'as-sso/saml-settings',
        component: SAMLSettingsForm,
      },
    ],
  },
];
