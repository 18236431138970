import { CrudApi } from './api';

class KnowledgeBaseApi extends CrudApi {
  constructor() {
    super('/content/kb');
  }

  effective(orgId) {
    return this.get('content/kb/effective', {
      qs: {
        organization_id: orgId,
      },
    });
  }

  sync(id) {
    return this.post(`content/kb/${id}/sync`);
  }
}
const knowledgeBaseApi = new KnowledgeBaseApi();
export default knowledgeBaseApi;
