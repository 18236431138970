<template>
  <div class="navigator">
    <h3>
      <base-icon 
        v-if="icon" 
        :icon="icon" 
      /> {{ header }}
    </h3>
    <dashboard-section>
      <panel-item 
        v-for="content in contentItems"
        :key="content.id" 
        class="panel" 
        :to="{ path: content.url }" 
      >
        {{ content.title }}
      </panel-item>
    </dashboard-section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import PanelItem from '@/components/panel/PanelItem';
import DashboardSection from '@/components/dashboard/DashboardSection';

export default {

  name: 'BaseNavigator',
  components: { DashboardSection, PanelItem },
  props: {
    icon: {
      type: String,
      required: false,
    },
    header: {
      type: String,
      required: false,
    },
    contentItems: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
  },
};

</script>

<style scoped lang="scss">
  .navigator {
    em {
      padding-right: 5px;
    }
    h4 {
      padding-left: 10px;
      font-weight: normal;
      &.selected {
        font-weight: bold;
        border-left: 2px solid;
      }
    }
  }
</style>
