<template>
  <form 
    :id="id" 
    class="base-form" 
    novalidate
    @submit.prevent="submit" 
  >
    <slot></slot>
    <div 
      class="form-control-banner" 
      :class="{'dont-show': !showFormControlBanner}"
    >
      <div 
        v-if="!hideCancel || !hideSubmit" 
        class="buttons-container" 
        :class="{'full-width': fullWidth}"
      >
        <span 
          v-if="formControlDescriptionLabel"
          class="description" 
        >{{ $t(formControlDescriptionLabel) }}</span>
        <base-button 
          v-if="!hideCancel"
          id="cancel"
          type="button" 
          size="lg" 
          label="cancel"
          :rounded="roundedButtons"
          :disabled="disableCancelButton"
          @click="cancel"
        >
          {{ $t(cancelLabel) }}
        </base-button>
        <template v-for="action in otherActions">
          <base-button 
            v-if="otherActions"
            :key="action.key"
            type="button" 
            size="lg"
            :rounded="roundedButtons"
            :disabled="action.disabled || executing"
            @click="otherAction(action.key)"
          >
            {{ $t(action.label) }}
          </base-button>
        </template>
        <base-button 
          v-if="!hideSubmit"
          id="submit" 
          type="submit" 
          size="lg" 
          label="submit"
          :rounded="roundedButtons"
          :disabled="disableSubmitButton"
          :executing="executing"
          :transitionButton="transitionButton"
          :class="disabled ? 'disable' : '' "
        >
          <base-icon 
            v-if="submitIcon" 
            :icon="submitIcon"
          />
          {{ $t(submitLabel) }}
        </base-button>
        <span 
          v-if="cancelLink"
          class="cancel" 
        >
          {{ $t('or') }}
          <base-button
            size="lg"
            class="exit-form"
            @click="exitForm()"
          >
            {{ $t('cancel') }}
          </base-button>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseForm',
  props: {
    id: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledCancel: {
      type: Boolean,
      default: false,
    },
    executing: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: 'cancel',
    },
    submitLabel: {
      type: String,
      default: 'submit',
    },
    submitIcon: {
      type: String,
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    transitionButton: {
      type: Boolean,
      default: false,
    },
    defaultBack: {
      type: Object,
    },
    otherActions: {
      type: Array,
      required: false,
      validator: v => v.find(b => !b.key || !b.label) === undefined,
    },
    cancelLink: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
    },
    showFormControlBanner: {
      type: Boolean,
      default: false
    },
    formControlDescriptionLabel: {
      type: String,
      default: null
    },
    roundedButtons: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit', 'otherAction', 'cancel'],
  computed: {
    ...mapGetters([
      'previousRoute',
    ]),
    disableCancelButton() {
      return this.disabledCancel || this.executing;
    },
    disableSubmitButton() {
      return this.disabled || this.executing;
    },
  },
  mounted() {
    if (!this.autofocus) {
      return;
    }
    const first = this.$el.querySelector('input:not([tabindex="-1"]):not([type="dont-show"]):not([class="multiselect__input"]), ' +
        'textarea:not([tabindex="-1"])');
    if (first !== null) {
      first.focus();
    }
  },
  methods: {
    submit() {
      if (!this.disableSubmitButton) {
        this.$emit('submit');
      }
    },
    otherAction(key) {
      this.$emit('otherAction', key);
    },
    exitForm() {
      this.$router.navigateBackOrDefault(this.defaultBack);
    },
    cancel() {
      if (this.onCancel) {
        this.$emit('cancel');
        return;
      }
      this.$router.navigateBackOrDefault(this.defaultBack);
    },
  },
};
</script>


<style scoped lang="scss">

  .base-form {
    :deep(.input-control), :deep(.base-select), :deep(.password-strength), :deep(.description) {
      max-width: 600px;
      box-sizing: border-box;
    }
  }

  .buttons-container {
    text-align: left;
    &:not(.full-width) {
      margin-top: 35px;
      padding-bottom: 50px;
    }
    &.full-width {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 5px;
      button {
        margin-top: 10px;
      }
    }

    .button:not(:last-child) {
        margin-right: 4px;
    }

    .description {
      float: left;
      margin-top: 8px;
    }
  }

.exit-form {
  border: none;
  &:hover {
    background-color: unset;
    text-decoration: underline;
  }
}

.cancel {
  padding-left: 20px;

  :deep(.button) {
    min-width: 0px;
  }
}

.form-control-banner:not(.dont-show) {
  background-color: var(--primary-light);
  width: 100%;
  margin-left: -25px;
  margin-top: 1em;
  padding: .5em 25px .5em 25px;
  clear: both;

  span.description {
    color: white;
  }
}

</style>
