import Api from './api';

class I18nApi extends Api {
  getPluginLabels(lang, file) {
    return this.get(`i18n/${lang}/${file}`, {}, false, false, false);
  } 

  getLabel(lang, file) {
    return this.get(`/static/labels/${lang}/${file}.json`, { qs: { build_hash: process.env.VUE_APP_BUILD_HASH } }, false, true, false);
  }
}

const i18nApi = new I18nApi();

export default i18nApi;
