<template>
  <div 
    class="panel" 
    :class="{ 'has-border': border }"
  >
    <div 
      v-if="$slots.header"
      class="panel-header" 
    >
      <slot name="header"></slot>
    </div>
    <div class="panel-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BasePanel',
  props: {
    header: {
      type: String,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>


<style scoped lang="scss">
.panel {
  border-radius: 6px;
  font-size: 14px;
}

.panel .panel-header {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  padding-left: 20px;
  font-size: 15px;
}

.panel .panel-content {
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: bold;
}
</style>
