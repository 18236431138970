<template>
  <div class="form-page">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div 
          v-if="title" 
          class="main-title"
        >
          <h2 v-if="!hasTitleSlot">
            {{ $te(title) ? $t(title) : title }}
          </h2>
          <h2 v-else>
            <slot name="title"></slot>
          </h2>
          <div 
            v-if="!hasDescriptionSlot"
            class="description" 
          >
            {{ $te(description) ? $t(description) : description }}
          </div>
          <div 
            v-else 
            class="description"
          >
            <slot name="description"></slot>
          </div>
        </div>
        <base-form 
          v-if="onCancel"
          :executing="executing"
          :disabled="disabled"
          :hideCancel="hideCancel"
          :hideSubmit="hideSubmit"
          :cancelLabel="cancelLabel"
          :submitLabel="submitLabel"
          :otherActions="otherActions"
          :cancelLink="cancelLink"
          @otherAction="$emit('otherAction', $event)"
          @cancel="$emit('cancel', $event)"
          @submit="$emit('submit', $event)"
        >
          <slot></slot>
        </base-form>
        <base-form 
          v-else
          :executing="executing"
          :disabled="disabled"
          :hideCancel="hideCancel"
          :hideSubmit="hideSubmit"
          :cancelLabel="cancelLabel"
          :submitLabel="submitLabel"
          :defaultBack="defaultBack"
          :cancelLink="cancelLink"
          :otherActions="otherActions"
          @otherAction="$emit('otherAction', $event)"
          @submit="$emit('submit', $event)"
        >
          <slot></slot>
        </base-form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormPage',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
    },
    hideSubmit: {
      type: Boolean,
    },
    cancelLabel: {
      type: String,
    },
    submitLabel: {
      type: String,
      default: 'submit',
    },
    executing: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    defaultBack: {
      type: Object,
    },
    otherActions: {
      type: Array,
      required: false,
    },
    cancelLink: {
      type: Boolean,
    },
    onCancel: {
      type: null,
    },
  },
  emits: ['submit', 'cancel', 'otherAction'],
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasDescriptionSlot() {
      return !!this.$slots.description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';
.form-page {
  padding: 0 150px;
  @include phone {
    padding: 0px 20px;
  }
}
.main-title {
  max-width: 600px;
}
</style>
