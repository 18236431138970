import { CrudApi } from './api';

class CacheApi extends CrudApi {
  constructor() {
    super('');
  }

  clearFetcherCache(connectionId, environmentId) {
    return this.del('/cache/fetcher', { qs: { connectionId, environmentId } });
  }
}

const cacheApi = new CacheApi();
export default cacheApi;
