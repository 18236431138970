<script>
import merge from 'deepmerge';
import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import Chart from './Chart';

use([LineChart]);

export default {
  name: 'LineChart',
  extends: Chart,
  props: {
    xAxes: {
      type: Array,
    },
    yAxes: {
      type: Array,
    },
  },
  computed: {
    echartXAxes() {
      if (!this.xAxes) {
        return this.series.map(s => ({
          type: 'category',
          data: s.labels,
          boundaryGap: false,
        }));
      }

      return this.xAxes.map(x => ({
        type: 'category',
        data: x.labels,
        nameLocation: x.namePosition,
        boundaryGap: false,
        ...x,
      }));
    },
    echartYAxes() {
      if (!this.yAxes) {
        return [{
          type: 'value',
        }];
      }
      return this.yAxes.map(y => ({
        type: 'value',
        nameLocation: 'middle',
        nameGap: 40,
        ...y,
      }));
    },
    tooltipData: () => ({ trigger: 'axis', confine: true }),
    gridData: () => ({
      left: '6.2%',
      right: '6.2%',
      bottom: '10%',
    }),
    legendData() {
      return this.series.length > 1 ? { data: this.series.map(e => e.name), top: '14%' } : null;
    },
    seriesData() {
      return this.series.map((e, idx) => ({
        name: e.name,
        type: 'line',
        data: e.value,
        symbol: e.symbol ? e.symbol : 'emptyCircle',
        smooth: false,
        lineStyle: e.lineStyle,
        itemStyle: e.itemStyle,
        ...(this.yAxes ?
          { yAxisIndex: Math.min(idx, this.yAxes.length > 0 ? this.yAxes.length - 1 : 0) } :
          {}
        ),
        ...(this.xAxes ?
          { xAxisIndex: Math.min(idx, this.xAxes.length > 0 ? this.xAxes.length - 1 : 0) } :
          {}
        ),
      }));
    },
    chartSpecificProps() {
      const chartPropsToOverride = {
        xAxis: this.echartXAxes,
        yAxis: this.echartYAxes,
      };
      return this.customChartProp ?
        merge(chartPropsToOverride, this.customChartProp) :
        chartPropsToOverride;
    },
  },
};
</script>
