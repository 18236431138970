<template>
  <advanced-details 
    :loading="loading"
    :titleLabel="titleLabel"
  >  
    <base-list 
      v-if="!edit && !loading"
      ariaLabel="currentQuotaOrgs.name" 
      enableInfiniteScroll 
      :loading="loading" 
      :list="filteredOrganizations"
      :ariaRowCount="filteredOrganizations.length"
    >
      <list-header>
        <list-column>{{ $t('organization') }}</list-column>
        <list-column>{{ $t('service_connection') }}</list-column>
      </list-header>
      <list-row 
        v-for="org in filteredOrganizations"
        :key="org.id" 
      >
        <list-column>
          {{ upperFirstCharOnly(org.name) }}
        </list-column>
        <list-column>
          <service-label :connection="currentQuota.serviceConnection"></service-label>
        </list-column>
      </list-row>
      <template #empty>
        <empty-message
          v-if="!assignedToAtleastOneOrg && !edit"
          icon="fa fa-plug"
          :textLabel="getEmptyListLabel"
        ></empty-message>
      </template>
    </base-list>

    <template #operations>
      <action-icon
        v-if="!edit"
        icon="fa fa-edit"
        tooltipLabel="edit"
        expandOnHover
        clickable
        @click="toggleForm"
      ></action-icon>
    </template>
    
    <AssignQuotaForm 
      v-if="edit" 
      :connection="connection" 
      switchOrgRouteName="quotasList" 
      :cancelLabel="'back'"
      @cancel="afterAssigned"
    />
  </advanced-details>
</template>
  
  <script>
  import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
  import { sortBy } from '@/utils';
  import notify from '@/utils/notify';
  import apis from '@/utils/apis';
  import { mapGetters } from 'vuex';
  import AssignQuotaForm from './AssignQuotaForm';
  import { upperFirstCharOnly } from '@/utils/label';

  export default {
    name: 'AssignedOrganizations',
    components: { AssignQuotaForm },
    mixins: [orgSwitchNavigationMixin],
    props: {
      connection: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        organizations: [],
        quotas: [],
        edit: false,
      };
    },
    computed: {
      ...mapGetters(['selectedOrganization']),
      currentQuota() {
        return this.quotas.find(q => q.id === this.$route.params.id)
      },
      currentQuotaOrgsIds() {
        return this.currentQuotaOrgs.map(o => o.id);
      },
      currentQuotaOrgs() {
        return this.currentQuota?.organizations || [];
      },
      currentOrganization() {
        return this.selectedOrganization;
      },
      assignedToAtleastOneOrg() {
        return this.filteredOrganizations.length > 0;
      },
      filteredOrganizations() {
        return this.organizations
            .filter(o => this.currentQuotaOrgsIds?.includes(o.id))
            .filter(o => o.serviceConnections.map(sc => sc.id).includes(this.connection.id));
      },
      titleLabel() {
        return this.$t('assigned_organizations');
      },
      getEmptyListLabel() {
      return 'no_assigned_organizations';
    },
    },
    async created() {
      this.loading = true;
      await this.refresh();
      if (this.$route.query.edit) {
        this.edit = this.$route.query.edit;
      }
      this.loading = false;
    },
    methods: {
      upperFirstCharOnly,
      async fetchOrganizations() {
        const orgListResp = await apis.organizations.getOrgListWithServiceConnections();
        if (orgListResp.ok) {
          this.organizations = orgListResp.data.sort(sortBy(o => o.name));
        } else {
          this.error = true;
        }
      },
      async fetchOwnedQuotas() {
        const qs = {
          qs: {
            type: 'owned',
            organization_id: this.selectedOrganization.id,
            connection_id: this.connection.id,
          },
        };
        const response = await apis.quotas.list(qs);
        if (response && response.status !== 200) {
          const message =
            response.status === 403
              ? 'quotas.not_authorized_to_list_quotas'
              : 'unexpected_error';
          notify.error(this.$t(message));
          return;
        }
        this.quotas = response.data.sort(sortBy(q => q.name));
      },
      async refresh() {
        await Promise.all([
         this.fetchOrganizations(),
          this.fetchOwnedQuotas(),
        ]);
      },
      async afterAssigned() {
        await this.refresh();
        this.toggleForm();
      },
      toggleForm() {
        this.edit = !this.edit;
      },
    },
  };
  </script>  