<template>
  <div class="clock-container">
    <div class="time-container">
      <div>
        <div 
          v-if="!hideControls" 
          class="toggle-container clickable"
        >
          <action-icon 
            icon="fa fa-chevron-up" 
            :class="elementType === 'time-select' ? 'clock-toggle-time-select' : 'clock-toggle'"
            aria-hidden="true" 
            tooltipLabel="time_picker.increase_hour"
            @click="clicked(HOUR_UP)" 
          />
          <action-icon 
            icon="fa fa-chevron-up" 
            :class="elementType === 'time-select' ? 'clock-toggle-right' : 'clock-toggle'" 
            aria-hidden="true" 
            tooltipLabel="time_picker.increase_minute"
            @click="clicked(MINUTE_UP)" 
          />
        </div>
        <div class="clock-input">
          <base-input 
            v-model="hourInput" 
            :alignment="'center'" 
            size="sm"
            @blur="blurHour" 
            @focus="$emit('clearError')" 
          />
          <div 
            :class="elementType === 'time-select' ? 
              'input-separator-time-select' 
              : 'input-separator'"
          >
            :
          </div>
          <base-input 
            v-model="minuteInput" 
            :alignment="'center'" 
            size="sm"
            @blur="blurMinute" 
          />
        </div>
        <div 
          v-if="typeof error === 'string' && error"
          class="error-label error red time-error" 
        >
          {{ $t(error) }}&nbsp;
        </div>
        <div 
          v-if="!hideControls" 
          class="toggle-container clickable"
        >
          <action-icon 
            icon="fa fa-chevron-down" 
            :class="elementType === 'time-select' ? 'clock-toggle-time-select' : 'clock-toggle'"
            aria-hidden="true" 
            tooltipLabel="time_picker.decrease_hour"
            @click="clicked(HOUR_DOWN)" 
          />
          <action-icon 
            icon="fa fa-chevron-down" 
            :class="elementType === 'time-select' ? 'clock-toggle-right' : 'clock-toggle'"
            aria-hidden="true" 
            tooltipLabel="time_picker.decrease_minute"
            @click="clicked(MINUTE_DOWN)" 
          />
        </div>
      </div>
      <div 
        v-if="!hideAmPm" 
        class="time-period"
      >
        <base-button 
          :class="elementType === 'time-select' ? 'time-period-margin' : 'time-period'" 
          :rounded="false" 
          size="xs"
          @click="switchPeriod" 
        >
          {{ $t(period) }}
        </base-button>
      </div>
    </div>
    <div
      v-if="elementType === 'time-filter'"
      class="time-nav-container"
    >
      <base-button 
        v-if="!standalone && !hideControls" 
        class="time-nav-button" 
        :rounded="false" 
        @click="back"
      >
        {{ $t('back') }}
      </base-button>
      <base-button 
        v-if="!hideNow" 
        class="time-nav-button" 
        :rounded="false" 
        @click="now"
      >
        {{ $t('now') }}
      </base-button>
      <base-button 
        v-if="!hideControls" 
        class="time-nav-button" 
        :rounded="false" 
        @click="close"
      >
        {{ $t('close') }}
      </base-button>
    </div>
  </div>
</template>


<script>
import { get24HTimeObj, getTimeObj } from '@/utils/dates';
import ActionIcon from '../ActionIcon';

const AM = 'am';
const PM = 'pm';
const DEFAULT_HOUR = '12';
const DEFAULT_MIN = '00';
const DEFAULT_PERIOD = AM;

export default {
  name: 'BaseTimePicker',
  components: { ActionIcon },
  props: {
    date: {
      type: Date,
      required: false,
    },
    utc: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: true,
    },
    hideAmPm: {
      type: Boolean,
      default: true,
    },
    hideNow: {
      type: Boolean,
      default: true,
    },
    is24Hour: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
    },
    /**
     * denotes if the BaseTimePicker is being used as a standlone widget or as
     * part of another component. In the latter case a 'Back' button will be shown
     * inside the TimePicker to navigate back to the component from where the
     * TimePicker was openned.
     */
    standalone: {
      type: Boolean,
      default: true,
    },
    elementType: {
      type: String,
      default: "time-filter"
    }
  },
  emits: ['update:modelValue', 'clearError', 'timeUpdate', 'back', 'close'],
  data() {
    return {
      period: undefined,
      hourInput: undefined,
      minuteInput: undefined,
      HOUR_UP: 'hourUp',
      HOUR_DOWN: 'hourDown',
      MINUTE_UP: 'minuteUp',
      MINUTE_DOWN: 'minuteDown',
    };
  },
  watch: {
    date(newDate) {
      if (newDate) {
        this.setTimeFromDate(newDate);
      }
    },
  },
  created() {
    if (this.date) {
      this.setTimeFromDate(this.date);
    } else {
      this.hourInput = DEFAULT_HOUR;
      this.minuteInput = DEFAULT_MIN;
      this.period = DEFAULT_PERIOD;
    }
    if (this.elementType === 'time-select') {
      this.$emit('update:modelValue', this.getTime());
    }
  },
  methods: {
    setTimeFromDate(date) {
      const timeObj = this.is24Hour ? getTimeObj(date.toTimeString())
        : getTimeObj(date.toLocaleTimeString());
      this.hourInput = timeObj.hours.length === 1 ? `0${timeObj.hours}` : timeObj.hours;
      this.minuteInput = timeObj.minutes;
      this.period = timeObj.period;
    },
    blurHour() {
      let hour = isNaN(this.hourInput) ? 1 : Number(this.hourInput);
      if ((hour === 0 || hour === 24) && !this.is24Hour) {
        hour = 12;
        this.period = AM;
      }
      if (hour > 12 && hour < 24 && !this.is24Hour) {
        hour -= 12;
        this.period = PM;
      }
      this.setHour(hour);
    },
    blurMinute() {
      this.setMinute(isNaN(this.minuteInput) ? 0 : Number(this.minuteInput));
    },
    clicked(pressedNode) {
      const currHour = Number(this.hourInput) || 1;
      const currMinute = Number(this.minuteInput) || 0;

      if (pressedNode === this.HOUR_UP) {
        this.setHour(currHour + 1);
      } else if (pressedNode === this.HOUR_DOWN) {
        this.setHour(currHour - 1);
      } else if (pressedNode === this.MINUTE_UP) {
        this.setMinute(currMinute + 1);
      } else if (pressedNode === this.MINUTE_DOWN) {
        this.setMinute(currMinute - 1);
      }
    },
    setHour(num) {
      if (this.is24Hour) {
        this.set24Hour(num);
      } else {
        this.set12Hour(num);
      }
      if (this.elementType === 'time-select') {
        this.$emit('update:modelValue', this.getTime());
      } else {
        this.$emit('timeUpdate', this.getTime());
      }
    },
    set12Hour(num) {
      if (num < 1) {
        this.hourInput = '12';
      } else if (num > 12) {
        this.hourInput = '01';
      } else {
        this.hourInput = this.formatHourInput(num);
      }
    },
    set24Hour(num) {
      if (num < 0) {
        this.hourInput = '0';
      } else if (num > 23) {
        this.hourInput = '23';
      } else {
        this.hourInput = this.formatHourInput(num);
      }
    },
    formatHourInput(num) {
      return (num < 10) ? `0${num}` : `${num}`;
    },
    setMinute(num) {
      if (num < 0) {
        this.minuteInput = '59';
      } else if (num > 59) {
        this.minuteInput = '00';
      } else {
        this.minuteInput = (num < 10) ? `0${num}` : `${num}`;
      }
      if (this.elementType === 'time-select') {
        this.$emit('update:modelValue', this.getTime());
      } else {
        this.$emit('timeUpdate', this.getTime());
      }
    },
    switchPeriod() {
      this.period = this.period.toLowerCase() === AM ? PM : AM;
      if (this.elementType === 'time-select') {
        this.$emit('update:modelValue', this.getTime());
      } else {
        this.$emit('timeUpdate', this.getTime());
      }
    },
    now() {
      const timeObj = getTimeObj();
      this.hourInput = timeObj.hours;
      this.minuteInput = timeObj.minutes;
      this.period = timeObj.period;
      this.$emit('timeUpdate', this.getTime());
    },
    getTime() {
      const time = `${this.hourInput}:${this.minuteInput} ${this.period}`;
      return this.is24Hour ? getTimeObj(time, this.utc) : get24HTimeObj(time, this.utc);
    },
    back() {
      this.$emit('back', this.getTime());
    },
    close() {
      this.$emit('close', this.getTime());
    },
  },
};
</script>

<style scoped lang="scss">
.clock-container {
  margin: 10px;
  padding: 5px;
  cursor: auto;
}

.time-container {
  display: flex;
  > :first-child {
    flex: 2;
  }
}

.clock-input {
  display: flex;
  :deep(.input-control) {
    input {
      max-width: 130px;
      padding: 10px 10px;
    }
  }
}

.input-separator-time-select {
  margin: auto;
  font-weight: bold;
  margin-left: -20em;
  margin-right: 3em;
}

.input-separator {
   margin: auto;
   font-weight: bold;
   margin-left: 7px;
   margin-right: 7px;
 }

.time-period {
  margin: auto;
  :deep(.button) {
    height: 35px;
    font-size: 14px;
  }
  :deep(.button-content) {
    align-items: center;
  }
}
.time-period-margin {
  margin-left: -18em;
}

.toggle-container:last-of-type {
  padding-top: 10px;
}

.toggle-container:first-of-type {
  padding-bottom: 10px;
}

.toggle-container {
  display: flex;
  font-size: 17px;
  :first-child {
    margin-right: 20px;
  }
}

.clock-toggle {
   flex: 1;
   text-align: center;
 }

.clock-toggle-time-select {
  flex: 1;
  position: relative;
  margin-left: -7em;
  text-align: center;
  color: green;
}

.clock-toggle-right {
  flex: 2;
  margin-left: -23em;
  text-align: center;
}

.time-nav-container {
  width: 100%;
  display: flex;
  margin-top: 10px;

  :first-child {
    margin-left: 0px;
  }

  .time-nav-button {
    flex: 1;
    padding: 5px;
  }
}

:deep(button) {
  margin: 0px;
  margin-left: 5px;
  padding: 2px;
  min-width: 0px;
}

:deep(.input) {
  max-width: 130px;
  text-align: center;
}

.time-error {
  margin-top: 5px;
}
</style>
