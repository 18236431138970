import { format, getNow } from '@/utils/dates';

export default {
  here: {
    attribution() {
      return `Map Tiles &copy; ${format(getNow(), 'YYYY')} ` +
            '<a href="http://developer.here.com">HERE</a>';
    },
    url(config) {
      const style = config.style_id || 'reduced.day';
      const size = config.size || '512';
      return `https://2.base.maps.ls.hereapi.com/maptile/2.1/basetile/newest/${style}/{z}/{x}/{y}/${size}/png8?apiKey=${config.api_key}`;
    },
  },
  stamen: {
    attribution() {
      return 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.';
    },
    url(config) {
      return `https://stamen-tiles.a.ssl.fastly.net/${config.style || 'terrain'}/{z}/{x}/{y}.png`;
    },
  },
  mapbox: {
    attribution() {
      return '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    },
    url(config) {
      const style = config.style_id || 'mapbox/light-v10';
      return `https://api.mapbox.com/styles/v1/${style}/tiles/{z}/{x}/{y}?access_token=${config.token}`;
    },
  },
};
