<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="trial-registration-left">
    <div 
      class="upper-html-snippet"  
      v-html="registrationHTMLContent"
    ></div>
    <div class="already-member">
      <div>
        <div>{{ $t("already_member") }}</div>
        <base-icon 
          icon="fa fa-users" 
          class="icon-size" 
        />
      </div>
      <base-button @click="goToLogin">
        <base-icon icon="fa fa-sign-in" />
        {{ $t("sign_in_trial") }}
      </base-button>
    </div>
    <div class="language-p">
      <LanguagePicker />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import browserHelper from '@/utils/browser';
import BaseButton from '@/components/BaseButton';
import LanguagePicker from '@/app/components/LanguagePicker';

export default {
  name: 'TrialRegistrationLeft',
  components: { BaseButton, LanguagePicker },
  props: {
    registrationInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'locale',
      'isSingleEntrypointLogin',
    ]),
    registrationHTMLContent() {
      return this.registrationInfo[this.locale];
    },
  },
  methods: {
    goToLogin() {
      if (this.isSingleEntrypointLogin) {
        this.$router.push('/');
      } else {
        browserHelper.goTo('login');
      }
    },
  },
};
</script>

<style scoped lang="scss">

  .trial-registration-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    position: relative;
    min-width: 400px;
  }

  .upper-html-snippet {
    display: flex;
    flex: 1;
    float: right;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px
  }

  .already-member {
    flex: 1;
    width: 100%;
    font-size: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
  }

  .icon-size {
    font-size: 40px;
    padding: 20px;
  }

  .language-p {
    width: 100%;
    text-align: left;
  }

</style>
