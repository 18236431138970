import { format, getNow, datesForPresets, MONTH_YEAR_FORMAT, LIST_SIMPLE_DATE_FORMAT } from '@/utils/dates';
/**
 * This utility is used to help interacting with the /src/components/filters/MultiCriteriaFilter
 */

export const FILTER_TYPE = {
    DEFAULT: 'default',
    TAGS: 'tags',
    DATE_PRESET: 'date_preset'
};

/**
 * Build a simple filter using a key (same as the label key) and a list of values.
 * 
 * @param {string}  key A string value acting both as the filter key and the label key
 * @param {array}   values A list of string values
 * @param {boolean} uppercase Whether the value should be in UPPERCASE
 * @returns A simple filter to provide as input to the MultiCriteriaFilter
 */
export const buildSimpleFilter = (key, values, uppercase = false) => {
    return {
        type: FILTER_TYPE.DEFAULT,
        key: {
          value: key,
          label: key
        },
        clearable: true,
        singleOption: false,
        loadDefaultValue: false,
        values: values.map(value => {
          return {
            value: (uppercase) ? value.toUpperCase() : value,
            label: value
          }
        })
    };
}

/**
 * Build a date preset filter using a key
 * 
 * @param {*} key A string value acting both as the filter key and the label key
 * @returns A date preset filter to provide as input to the MultiCriteriaFilter
 */
export const buildDatePresetFilter = (key) => {
    return {
        type: FILTER_TYPE.DATE_PRESET,
        key: {
          value: key,
          label: key
        },
        clearable: true,
        singleOption: true,
        loadDefaultValue: false,
        values: getDatePresets()
    };
}

const getDatePresets = () => {
    const now = getNow();
    const oneMonthAgo = datesForPresets(false)['month-1'](now)[0];
    const twoMonthsAgo = datesForPresets(false)['month-2'](now)[0];
    const threeMonthsAgo = datesForPresets(false)['month-3'](now)[0];
    return [
        {
            value: format(datesForPresets(false)['1d'](now)[0], LIST_SIMPLE_DATE_FORMAT),
            label: 'date_ranges_long.1d'
        },
        {
            value: format(datesForPresets(false)['7d'](now)[0], LIST_SIMPLE_DATE_FORMAT),
            label: 'date_ranges_long.7d'
        },
        {
            value: format(datesForPresets(false)['30d'](now)[0], LIST_SIMPLE_DATE_FORMAT),
            label: 'date_ranges_long.30d'
        },
        {
            value: format(oneMonthAgo, LIST_SIMPLE_DATE_FORMAT),
            label: format(oneMonthAgo, MONTH_YEAR_FORMAT)
        },
        {
            value: format(twoMonthsAgo, LIST_SIMPLE_DATE_FORMAT),
            label: format(twoMonthsAgo, MONTH_YEAR_FORMAT)
        },
        {
            value: format(threeMonthsAgo, LIST_SIMPLE_DATE_FORMAT),
            label: format(threeMonthsAgo, MONTH_YEAR_FORMAT)
        }
    ];
};