<template>
  <base-menu-page
    :operations="operations"
    :searchEnabled="true"
    :header="header"
    :sections="sections"
    :noPadding="sections.length === 1"
    :displayAllChildren="true"
    @search="search = $event"
  >
    <router-view :search="search"></router-view>
  </base-menu-page>
</template>
<script>
import authz from '@/authz';
import { mapGetters } from 'vuex';

export default {
  name: 'QuotasSection',
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    header() {
      return {
        logo: 'sliders',
        title: this.$t('quotas.name'),
        subtitle: this.$t('quotas.description'),
      };
    },
    sections() {
      return [
        {
          title: this.$t('quotas.owned_quotas'),
          to: { name: 'quotas-owned' },
          permission: 'reseller:quotas',
        }].filter(child => !child.permission ||
        (authz.hasPermission(child.permission) && this.selectedOrganization.isReseller));
    },
    operations() {
      if (authz.hasPermission('reseller:quotas')) {
        return [{
          route: 'quotas/owned',
          label: 'quotas.add_quota',
          icon: 'fa fa-plus',
          href: '/quotas/owned/create',
          action: () => this.$router.push({ name: 'createQuota' }),
        }].filter(o => this.$route.name !== o.route && this.selectedOrganization.isReseller);
      }
      return [];
    },
  },
};
</script>
