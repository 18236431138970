
import apis from '@/utils/apis';
import { downloadAsCsv, downloadAsPdf } from '@/utils/download';
import notify from '@/utils/notify';
import { subDays, LIST_SIMPLE_DATE_FORMAT, MONTH_NAME_YEAR_FORMAT } from '@/utils/dates';

const LABEL_PREFIX = 'reports.invoice_status';

export const invoiceMixin = {
  methods: {
    formatStatus(status) {
      return this.$t(`${LABEL_PREFIX}.${status}`);
    },
    formatInvoiceEndDate(invoice, format = LIST_SIMPLE_DATE_FORMAT) {
      if (!invoice || !invoice.detail || !invoice.detail.endDate) {
        return this.$t('not_available');
      }
      const ed = subDays(invoice.detail.endDate, 1);
      return this.$date(ed, format, true);
    },
    statusColor(status) {
      return {
        IN_PROGRESS: 'blue',
        IN_REVIEW: 'yellow',
        ISSUED: 'orange',
        OVERDUE: 'red',
        PAID: 'green',
        COMPLETE: 'green',
        VOID: 'grey',
      }[status];
    },
    async downloadInvoice(invoice, language, type) {
      const resp = await apis.invoices.downloadInvoice(invoice.id, language, type);
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('reports.unexpected_error_invoice_download'));
        return;
      }
      const invoiceName = `${this.$t('reports.invoice')} ${invoice.organization.name} ${this.$date(invoice.detail.startDate, MONTH_NAME_YEAR_FORMAT, true)}`;
      const downloadAs = !type || type === 'pdf' ? downloadAsPdf : downloadAsCsv;
      const success = downloadAs(resp.body, invoiceName);
      if (!success) {
        notify.error(this.$t('reports.unexpected_error_invoice_download'));
      }
    },
  },
};

export const flaggedInvoiceMixin = {
  methods: {
    flaggedInvoice(invoice) {
      return invoice.flags && invoice.flags.length > 0 && invoice.status === 'IN_REVIEW';
    },
    invoiceFlagMetadata(invoice) {
      if (invoice.flags && invoice.flags.length > 0) {
        return invoice.flags[0];
      }
      return {};
    },
    async approveInvoice(invoice) {
      notify.pending(this.$t('reports.approve_pending'));
      const resp = await apis.invoices.approve(invoice.id);
      if (resp.status === 200 && resp?.data) {
        this.$emit('invoiceUpdated', resp.data);
        if (resp.data.status.toLowerCase() == 'complete') {
          notify.success(this.$t('reports.complete_invoice_success'));
        }
        else {
          notify.success(this.$t('reports.approve_success'));
        }
      } else {
        notify.error(this.$t('reports.approve_failed'));
      }
    },
    async markAsPaid(invoice) {
      const resp = await apis.invoices.markAsPaid(invoice.id);
      if (resp && resp.status === 200) {
        this.$emit('invoiceUpdated', resp.data);
        notify.success(this.$t('reports.mark_as_paid_success'));
      } else {
        notify.error(this.$t('reports.mark_as_paid_failed'));
      }
    },
    isWaitingPayment(invoice) {
      const statuses = ['ISSUED', 'OVERDUE'];
      return statuses.includes(invoice.status);
    },
    isVoidActionEnabled(invoice) {
      const statuses = ['IN_PROGRESS', 'IN_REVIEW', 'ISSUED', 'OVERDUE']
      return statuses.includes(invoice.status);
    },
    isMarkedVoided(invoice) {
      return invoice.voidInvoiceReason && invoice.status === 'VOID';
    }
  },
  computed: {
    metadata() {
      return this.invoiceFlagMetadata(this.invoice);
    },
    authorName() {
      return `${this.metadata.user.firstName} ${this.metadata.user.lastName}`;
    },
    authorEmail() {
      return this.metadata.user.email;
    },
    message() {
      return this.metadata.message;
    },
    createdTime() {
      return this.$date(this.metadata.createdAt);
    },
  },
};
