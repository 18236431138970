/* eslint-disable no-use-before-define */
import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';
import routes from '../app/routes';
import { beforeEachNav } from './BeforeEachNav';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => beforeEachNav(to, from, next, store));

router.navigateBackOrDefault = (defaultReturn) => {
  if (store.getters.previousRoute && store.getters.previousRoute.name) {
    router.push(store.getters.previousRoute);
    return;
  }
  if (defaultReturn) {
    router.push(defaultReturn);
    return;
  }
  router.push('/');
};

const buildPath = path => adaptWorkspace(adaptOrg(path));

const getWorkspaceQuery = () => {
  const query = new URLSearchParams(window.location.search);
  return query.get('workspace');
};

const inheritWorspaceQuery = (path) => {
  const resolved = router.resolve(path);
  const matched = resolved.matched[resolved.matched.length - 1];
  return matched.path.startsWith('/services')
    && !(matched.meta || {}).requiresWorkspaceQuery;
};

const adaptWorkspace = (path) => {
  const workspace = getWorkspaceQuery();
  if (!workspace || !inheritWorspaceQuery(path)) {
    return path;
  }
  return { ...path, query: { ...path.query, workspace } };
};

const adaptOrg = (path) => {
  const selectedOrg = store.getters.selectedOrganization;
  if (!store.getters.loggedIn && !selectedOrg) {
    return path;
  }
  let actualPath = { };
  if (typeof path === 'object') {
    actualPath = { query: {}, ...path };
  } else {
    actualPath = { query: {}, path };
  }
  const queryOrg = (path.query || {}).org;
  const myOrg = store.getters.myOrganization;
  const newQuery = { org: queryOrg || selectedOrg.entryPoint };
  if (myOrg && actualPath.query && actualPath.query.org === myOrg.entryPoint) {
    delete actualPath.query.org;
  } else if (!myOrg || myOrg.id !== selectedOrg.id) {
    actualPath.query = {
      ...actualPath.query,
      ...newQuery,
    };
  }
  return actualPath;
};

const actualPush = router.push.bind(router);
router.push = path => actualPush(buildPath(path));

const actualReplace = router.replace.bind(router);
router.replace = path => actualReplace(buildPath(path));

const actualGo = router.go.bind(router);
router.go = path => actualGo(buildPath(path));

export default router;
