<template>
  <div>
    <BaseStateContainer
      v-show="executing"
      type="branding"
      hideLeftButton
      hideMiddleButton
      hideRightButton
      :actionMessage="isUpdateOperation ? $t('branding.updating') : $t('branding.creating')"
      infoMessage="branding.favicon_upload_warning"
      :forceShowLoader="true"
    />
    <base-loader v-if="loading"></base-loader>
    <div 
      v-else 
      v-show="!executing"
    >
      <form-page
        title="branding.appearance"
        :defaultBack="{name:'brandingView'}"
        :disabled="!canSubmit"
        :executing="executing"
        @submit="execute"
      >
        <alert-box
          v-if="imageUploadErrors.length"
          border
          alertType="ERROR"
        >
          {{ $t("branding.error_uploading_images", {images: imageUploadErrors}) }}
        </alert-box>
        <alert-box
          v-if="executing && images.logoSquare"
          border
          alertType="INFO"
        >
          {{ $t("branding.favicon_upload_warning") }}
        </alert-box>
        <form-row label="branding.application_name">
          <base-input v-model="applicationName"></base-input>
        </form-row>
        <BrandingFormLogoRow
          ref="logoRow"
          :fileUploadEndpoint="uploadEndpoint"
          :fileUploadSquare="uploadSquare"
          :logoExists="logoExists"
          :squareLogoExists="squareLogoExists"
          :uploadImages="uploadImages"
          @addedLogo="setLogo"
          @addedLogoSquare="setLogoSquare"
        />
        <form-row label="branding.colors_css">
          <span class="description">{{ $t('branding.colors_description') }} </span>
          <router-link 
            target="_blank" 
            class="description"
            :to="instructionsLink"
          >
            {{ $t('branding.colors_description_instructions') }}
          </router-link>
          <code-editor 
            v-model="colorInput" 
            language="CSS"
          />
        </form-row>
        <form-row 
          label="branding.graph_colors" 
          descriptionLabel="branding.graph_colors_description"
        >
          <ColorSwatch
            ref="graphColors"
            :model-value="graphColors"
            @update:modelValue="updateGraphColors"
          />
        </form-row>
        <form-row
          label="branding.custom_css"
          descriptionLabel="branding.custom_css_description"
        >
          <code-editor 
            v-model="customCss" 
            language="CSS" 
          />
        </form-row>
        <form-row 
          label="branding.custom_icons" 
          descriptionLabel="branding.custom_icons_description"
          :error="validCustomIcons ? null : $t('invalid_json')"
        >
          <code-editor 
            v-model="customIcons" 
            language="JSON" 
          />
        </form-row>
        <div class="main-title">
          <h2>{{ $t("branding.internationalization") }}</h2>
        </div>
        <form-row label="branding.languages">
          <tag-select
            :modelValue="tagValues"
            :options="tagOptions"
            placeholderLabel="branding.add_language"
            @addTag="addTag"
            @removeTag="removeTag"
          ></tag-select>
        </form-row>
        <form-row 
          v-if="activeLanguagesList.length > 1" 
          label="branding.default_language"
        >
          <base-select 
            v-model="defaultLanguage" 
            :items="activeLanguagesList"
          ></base-select>
        </form-row>
      </form-page>
    </div>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { sortBy } from '@/utils';
import { mapGetters } from 'vuex';
import { getLanguages } from '@/i18n';
import BrandingFormLogoRow from './BrandingFormLogoRow';
import ColorSwatch from './ColorSwatch';
import BaseStateContainer from '../../Services/components/states/BaseStateContainer';
import {getColorHex, GRAPH_COLORS } from "@/components/nextgen/charts/branding";

export default {
  name: 'BrandingForm',
  components: {
    BrandingFormLogoRow,
    ColorSwatch,
    BaseStateContainer,
  },
  emits: ['removeTag'],
  data() {
    return {
      branding: (this.$route?.params || {}).branding,
      loading: false,
      applicationName: this.branding ? this.branding.applicationName : '',
      colorInput: '',
      graphColors: [],
      customCss: '',
      images: {
        logo: {},
        logoSquare: {},
      },
      languages: [],
      activeLanguages: [],
      activeLanguagesList: [],
      defaultLanguage: '',
      defaultTimezone: 'America/Montreal',
      customIcons: '',
      executing: false,
      uploadImages: false,
      imageUploadErrors: [],
      isUpdateOperation: false,
      instructionsLink: '/hc/administration/branding/',
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
    canSubmit() {
      const requiredProperties =
        !!this.applicationName && this.activeLanguagesList.length > 0;
      const imagesSet = this.logoSetForUpload && this.squareLogoSetForUpload;
      if (this.branding) {
        return requiredProperties && this.validCustomIcons;
      }
      return requiredProperties && imagesSet && this.validCustomIcons;
    },
    uploadEndpoint() {
      if (!this.branding) {
        return 'upload';
      }
      return `/rest/brandings/${this.branding.id}/artifacts/logo`;
    },
    logoExists() {
      if (!this.branding) {
        return null;
      }
      return !!(this.branding.artifacts || []).find(a => a.name === 'logo');
    },
    squareLogoExists() {
      if (!this.branding) {
        return false;
      }
      return !!(this.branding.artifacts || []).find(
        a => a.name === 'logo_square',
      );
    },
    uploadSquare() {
      if (!this.branding) {
        return 'upload';
      }
      return `/rest/brandings/${this.branding.id}/artifacts/logo_square`;
    },
    logoSetForUpload() {
      return (
        !!this.images.logo && Object.keys(this.images.logo || {}).length !== 0
      );
    },
    squareLogoSetForUpload() {
      return (
        !!this.images.logoSquare &&
        Object.keys(this.images.logoSquare || {}).length !== 0
      );
    },
    tagValues() {
      return this.activeLanguagesList.map(l => l.name);
    },
    tagOptions() {
      return (this.languages || []).map(l => ({
        value: l.name,
        remove: () => this.$emit('removeTag', l.name),
      }));
    },
    brandingBody() {
      return {
        applicationName: this.applicationName,
        organization: {
          id: this.selectedOrganization.id,
        },
        styles: this.generateStylesWithGraphColors(),
        artifacts: this.generateArtifacts(),
        defaultTimezone: this.defaultTimezone,
        defaultLanguage:
          this.activeLanguagesList.length === 1
            ? this.activeLanguagesList.map(l => l.value)[0]
            : this.defaultLanguage,
        activeLanguages: this.activeLanguagesList.map(l => l.value).toString(),
      };
    },
    validCustomIcons() {
      if (this.customIcons === '' || this.customIcons === null || this.customIcons === undefined) {
        return true;
      }
      try {
        const parsed = JSON.parse(this.customIcons);
        return parsed && typeof parsed === 'object';
      } catch (err) {
        return false;
      }
    },
  },
  watch: {
    images: {
      handler(value) {
        const filesWithErrors = [];
        let filesInDropzone = 0;
        let success = 0;

        Object.keys(value).forEach((key) => {
          const status = value[key].status;
          const accepted = value[key].accepted;
          if (!status) {
            return;
          }
          filesInDropzone += 1;
          if (status === 'success') {
            success += 1;
          } else if (status === 'error' && accepted === true) {
            filesWithErrors.push(key);
          }
        });

        if (filesWithErrors.length > 0) {
          this.executing = false;
          this.imageUploadErrors = filesWithErrors;
        } else if (this.executing && filesInDropzone === success) {
          this.executing = false;
          this.backToDetailsView();
        }
      },
      deep: true,
    },
  },
  async created() {
    // If there is no branding set in the params, we call the api to set it
    if (!((this.branding || {}).id)) {
      this.branding = await this.getOrgBranding();
    }
    this.applicationName = this.branding ? this.branding.applicationName : '';
    this.colorInput = this.trimColorCss(this.decodeArtifact('colors.css'));
    this.customCss = this.decodeArtifact('custom.css');
    this.graphColors = this.readGraphColors()
    this.customIcons = JSON.stringify((this.branding || {}).customIcons, '', 2) || '';

    await this.loadLanguages();
  },
  methods: {
    updateGraphColors(color, index) {
      this.graphColors[index] = color;
    },
    readGraphColors() {
      let graphColors = this.branding?.styles?.filter(style => style.name.startsWith('graph')).sort(sortBy(s => s.name)).map(s => s.value) || [];
      if (graphColors.length === 0) {
        graphColors = GRAPH_COLORS.map(c => getColorHex(c))
      }
      return graphColors;
    },
    generateStylesWithGraphColors() {
      const existingGraphStyles = this.branding?.styles?.filter(style => style.name.startsWith('graph')).sort(sortBy(s => s.name)) || [];
      const styles = this.branding?.styles?.filter(style => !style.name.startsWith('graph')) || [];
      this.graphColors.forEach((color, index) => {
        let style = {
          name: 'graph' + index,
          value: color
        };
        if (existingGraphStyles.length > 0) {
          style = {
            id: existingGraphStyles[index].id,
            ...style
          }
        }
        styles.push(style);
      });
      return styles;
    },
    setLogo(logo) {
      this.images.logo = {};
      this.images.logo = logo;
    },
    setLogoSquare(logo) {
      this.images.logoSquare = {};
      this.images.logoSquare = logo;
    },
    trimColorCss(decodedColor) {
      const rootSelector = ':root{';
      let trimmedColor = decodedColor.trim();
      if (trimmedColor.startsWith(rootSelector)) {
        // We remove an additional character at the end to remove the closing bracket
        trimmedColor = trimmedColor
          .substring(rootSelector.length, trimmedColor.length - 2)
          .trim();
      }
      return trimmedColor;
    },
    decodeArtifact(filename) {
      if (!this.branding) {
        return '';
      }
      const colorArtifact = (this.branding.artifacts || []).find(
        x => x.name === filename,
      );
      if (!colorArtifact || !colorArtifact.content) {
        return '';
      }
      return atob(colorArtifact.content);
    },
    generateArtifacts() {
      const artifacts = [];
      if (this.colorInput) {
        artifacts.push({
          name: 'colors.css',
          content: btoa(this.colorInput),
        });
      }
      if (this.customCss) {
        artifacts.push({
          name: 'custom.css',
          content: btoa(this.customCss),
        });
      }
      return artifacts;
    },
    async execute() {
      this.isUpdateOperation = !!this.branding;

      this.executing = true;
      let resp = {};
      if (this.isUpdateOperation) {
        resp = await this.doUpdate();
      } else {
        resp = await this.doCreate();
      }
      if (resp.status === 204 || resp.status === 200) {
        this.branding = resp.data;
        if (
          this.isUpdateOperation &&
          !this.logoSetForUpload &&
          !this.squareLogoSetForUpload
        ) {
          this.completed();
        } else {
          this.uploadImages = true;
        }
      }
    },
    backToDetailsView() {
      notify.success(this.isUpdateOperation ? this.$t('events.system.brandings.updated.success') : this.$t('events.system.brandings.created.success'));
      this.$router.push({ name: 'brandingView' });
    },
    newBrandingEntity() {
      const newBranding = {
        applicationName: this.applicationName,
        organization: {
          id: this.selectedOrganization.id,
        },
        artifacts: this.generateArtifacts(),
        defaultTimezone: this.defaultTimezone,
        defaultLanguage:
          this.activeLanguagesList.length === 1
            ? this.activeLanguagesList.map(l => l.value)[0]
            : this.defaultLanguage,
        activeLanguages: this.activeLanguagesList.map(l => l.value).toString(),
      };
      if (this.branding) {
        newBranding.id = this.branding.id;
      }
      return newBranding;
    },
    async doUpdate() {
      const newBranding = {
        id: this.branding.id,
        ...this.brandingBody,
        customIcons: JSON.parse(this.customIcons || '{}'),
      };
      return apis.branding.update(this.branding.id, newBranding);
    },
    async doCreate() {
      const newBranding = {
        ...this.brandingBody,
      };
      return apis.branding.create(newBranding);
    },
    completed() {
      notify.success(this.isUpdateOperation ? this.$t('events.system.brandings.updated.success') : this.$t('events.system.brandings.created.success'));
      this.$router.push({ name: 'brandingView' });
    },
    async loadLanguages() {
      this.languages = getLanguages().map(l => ({
        value: l,
        label: this.$t(`languages.${l}.long`),
        name: this.$t(`languages.${l}.long`),
      }));
      this.activeLanguages = this.branding
        ? this.branding.activeLanguages.split(',')
        : [];
      this.defaultLanguage = this.branding ? this.branding.defaultLanguage : '';
      if (this.branding) {
        this.activeLanguagesList = this.languages.filter(l =>
          this.activeLanguages.includes(l.value),
        );
      } else {
        this.activeLanguagesList = this.languages;
      }
    },
    addTag(tag) {
      this.activeLanguagesList.push(
        this.languages.filter(l => l.name === tag)[0],
      );
    },
    removeTag(tag) {
      this.activeLanguagesList = this.activeLanguagesList.filter(
        l => l.name !== tag,
      );
    },

    async getOrgBranding() {
      const resp = await apis.branding.getEffective(
        this.selectedOrganization.id,
      );
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      }
      const effectiveBranding = resp.data;
      const brandingOrgId = (effectiveBranding.organization || {}).id;
      return brandingOrgId === this.selectedOrganization.id ? resp.data : null;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
