import { getQueryFromMap, getPathWithQueryParams } from '@/utils';
import { unflattenFormFields } from '@/utils/operation';
import Api, { restPath } from './api';

class OperationsApi extends Api {
  precondition(serviceCode, envName, entityType, envId, operation, orgId) {
    const fullPath = `/services/operations/${serviceCode}/${envName}/${entityType}/precondition`;
    return this.post(fullPath, {
      qs: {
        id: envId,
        operation,
        org_id: orgId,
      },
    });
  }

  validate(serviceCode, envName, entityType, operation, orgId, entity, context) {
    const fullPath = `/services/operations/${serviceCode}/${envName}/${entityType}/validate`;
    return this.post(fullPath, {
      body: unflattenFormFields(entity),
      qs: {
        operation,
        org_id: orgId,
        ...context,
      },
    });
  }

  execute(serviceCode, organizationId, envName, entityType, operation, id, entity, context) {
    let path = `/services/${serviceCode}/${envName}/${entityType}`;
    if (id) {
      path = `${path}/${id}`;
    }
    return this.post(path, {
      body: unflattenFormFields(entity),
      qs: {
        ...context,
        operation,
        org_id: organizationId,
        windowId: this.$store.getters.windowId,
      },
    });
  }

  // Execute a patch operation on the entity
  executePatch(serviceCode, organizationId, envName, entityType, id, entity, context) {
    let path = `/services/${serviceCode}/${envName}/${entityType}`;
    if (id) {
      path = `${path}/${id}`;
    }
    return this.patch(path, {
      body: unflattenFormFields(entity),
      qs: {
        ...context,
        org_id: organizationId,
        windowId: this.$store.getters.windowId,
      },
    });
  }

  // Redirect operations are special cases where we only return the path.
  // They are intended to be opened to a new tab and to return a redirect
  // response to an outside service.
  buildRedirectURL(serviceCode, organizationId, envName, entityType, operation, id, context) {
    const path = `/services/${serviceCode}/${envName}/${entityType}/${id}/redirect`;

    const qs = {
      ...context,
      operation,
      org_id: organizationId,
    };

    const pathWithQueryParms = getPathWithQueryParams(path, qs);
    return restPath(pathWithQueryParms);
  }

  estimateCost(serviceCode, organizationId, envName, entityType, operation, entity) {
    const path = `/services/cost_estimates/${serviceCode}/${envName}/${entityType}`;
    return this.post(path, {
      body: unflattenFormFields(entity),
      qs: {
        operation,
        org_id: organizationId,
      },
    }).then((resp) => {
      if (resp.status === 404) {
        return { noCostEstimation: true };
      }
      return resp;
    });
  }

  download(connectionId, organizationId, environmentId, entityType,
    operation, id, entity, context) {
    const path = `/rest/services/operations/${connectionId}/download`;
    const qs = {
      ...context,
      entityType,
      operation,
      id,
      environmentId,
      jsonEntity: JSON.stringify({ ...entity, id }),
    };
    window.location.href = `${path}?${getQueryFromMap(qs)}`;
  }
}

export default new OperationsApi();
