import { ReadOnlyApi } from './api';

class PermissionApi extends ReadOnlyApi {
  constructor() {
    super('permissions');
  }
}

const permissionApi = new PermissionApi();

export default permissionApi;
