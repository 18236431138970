import BaseRoute from '@/app/components/BaseRoute';

const ServiceConnectionsList = () => import(/* webpackChunkName: "admin" */ './ServiceConnectionsList');
const CreateServiceConnection = () => import(/* webpackChunkName: "admin" */ './CreateServiceConnection');
const EditServiceConnection = () => import(/* webpackChunkName: "admin" */ './EditServiceConnection');
const ServiceConnectionDetails = () => import(/* webpackChunkName: "admin" */ './ServiceConnectionDetails');
const ServiceConnectionPage = () => import(/* webpackChunkName: "admin" */ './ServiceConnectionPage');
const ServiceConnectionLinkEnvironments = () => import(/* webpackChunkName: "admin" */ './ServiceConnectionLinkEnvironments');
const ManagePricings = () => import(/* webpackChunkName: "admin" */ './pricings/ManagePricings');
const PoliciesForm = () => import(/* webpackChunkName: "admin" */ './policies/PoliciesForm');
const ManagePolicies = () => import(/* webpackChunkName: "admin" */ './policies/ManagePolicies');
const AssignedOrgs = () => import(/* webpackChunkName: "admin" */ './assignedorgs/AssignedOrgs');
const AddPricing = () => import(/* webpackChunkName: "admin" */ './pricings/AddPricing');
const EditPricing = () => import(/* webpackChunkName: "admin" */ './pricings/EditPricing');

export default [
  {
    path: 'services',
    component: BaseRoute,
    meta: {
      protected: ['reseller:connections'],
      title: () => 'services',
    },
    children: [
      {
        name: 'serviceConnectionsList',
        path: '',
        component: ServiceConnectionsList,
      },
      {
        name: 'createServiceConnection',
        path: 'add',
        component: CreateServiceConnection,
        meta: {
          protected: ['reseller:connections'],
        },
      },
      {
        name: 'serviceConnectionLinkEnvironments',
        path: 'linkEnvironments/:connId',
        component: ServiceConnectionLinkEnvironments,
        props: route => ({ ...route.params, switchOrgRouteName: 'serviceConnectionsList' }),
        meta: {
          protected: ['reseller:connections'],
          title: () => 'serviceConnections.link_environments',
        },
      },
      {
        name: 'serviceConnectionPage',
        path: ':connId',
        component: ServiceConnectionPage,
        props: route => ({ ...route.params, switchOrgRouteName: 'serviceConnectionsList' }),
        redirect: { name: 'serviceConnectionDetails' },
        meta: {
          protected: ['reseller:connections'],
          title: () => 'serviceConnections.connection_details',
        },
        children: [
          {
            name: 'serviceConnectionDetails',
            path: 'details',
            component: ServiceConnectionDetails,
          },
          {
            name: 'editServiceConnection',
            path: 'details/edit',
            component: EditServiceConnection,
          },
          {
            name: 'connectionPricings',
            path: 'pricings',
            component: ManagePricings,
            meta: {
              protected: ['reseller:pricing'],
              title: () => 'serviceConnections.connection_pricings',
            },
          },
          {
            name: 'AddPricing',
            path: 'pricings/add',
            component: AddPricing,
            meta: {
              protected: ['reseller:pricing'],
              title: () => 'serviceConnections.connection_pricings',
            },
          },
          {
            name: 'EditPricing',
            path: 'pricings/:pricingId',
            component: EditPricing,
            meta: {
              protected: ['reseller:pricing'],
              title: () => 'serviceConnections.connection_pricings',
            },
          },
          {
            name: 'policies',
            path: 'policies',
            props: true,
            component: ManagePolicies,
            meta: {
              protected: ['reseller:connections'],
              title: () => 'serviceConnections.connection_policies',
            },
            children: [
              {
                name: 'policiesForm',
                path: ':selectedSection',
                props: true,
                component: PoliciesForm,
              },
            ],
          },
          {
            name: 'connectionOrgs',
            path: 'assigned_organizations',
            component: AssignedOrgs,
            meta: {
              title: () => 'assigned_organizations',
            },
          },
        ],
      },
    ],
  },
];
