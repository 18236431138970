<template>
  <list-row 
    class="clickable" 
    role="button" 
    tabindex="0"
    @click="click"
  >
    <list-column :size="2">
      <ui-value 
        :modelValue="filterOption.name" 
        class="option" 
        :style="{ 'font-weight': active ? 'bold' : 'normal' }"
      />
    </list-column>
  </list-row>
</template>
<script>

import { getNow, subHours, subDays } from '@/utils/dates';

export default {
  name: 'TimeFilterOption',
  props: {
    filterOption: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
  },
  emits: ['optionSelect'],
  methods: {
    click() {
      // Currently we don't support the notion of relative filters. As a quick fix to make
      // these filter options relative I'm checking for the label to set the start and end
      // dates. This technical debt will be addressed in https://cloudops.atlassian.net/browse/MC-16078
      // where we will add support for both relative and absolute filter options
      const NOW = getNow();
      if (this.filterOption.name.text === 'view_filters.one_hour') {
        this.$emit('optionSelect', {
          start: subHours(NOW),
          end: NOW,
          predefinedOption: this.filterOption.name,
        });
      } else if (this.filterOption.name.text === 'view_filters.one_day') {
        this.$emit('optionSelect', {
          start: subDays(NOW),
          end: NOW,
          predefinedOption: this.filterOption.name,
        });
      } else if (this.filterOption.name.text === 'view_filters.one_week') {
        this.$emit('optionSelect', {
          start: subDays(NOW, 7),
          end: NOW,
          predefinedOption: this.filterOption.name,
        });
      } else if (this.filterOption.name.text === 'view_filters.one_month') {
        this.$emit('optionSelect', {
          start: subDays(NOW, 30),
          end: NOW,
          predefinedOption: this.filterOption.name,
        });
      } else {
        this.$emit('optionSelect', {
          start: this.filterOption.start,
          end: this.filterOption.end,
          predefinedOption: this.filterOption.name,
        });
      }
    },
  },
};
</script>
