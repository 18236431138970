<template>
  <header class="api-docs-header">
    <div 
      div 
      class="top"
    >
      <div class="left">
        <div class="logo">
          <app-link :to="'/api-docs'">
            <app-logo 
              class="app-logo long"
              :src="`/rest/appearance/logo?org=${orgEntryPoint}`"
            />
          </app-link>
          <app-link :to="'/api-docs'">
            <app-logo 
              class="app-logo short"
              :src="`/rest/appearance/logo_square?org=${orgEntryPoint}`"
            />
          </app-link>
        </div>
        <div class="title">
          API Documentation
        </div>
      </div>
      <div class="right">
        <base-select 
          :items="[coreDoc, ...serviceDocs]" 
          :modelValue="$route.params.serviceType" 
          @update:modelValue="goToApiDoc"
        ></base-select>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    docs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    coreDoc() {
      return {
        value: undefined,
        display: 'Core',
      }
    },
    serviceDocs() {
      return this.docs.map(d => ({
        value: d.serviceType,
        display: d.display,
      }));
    },
    orgEntryPoint() {
      return (this.selectedOrganization || {}).entryPoint;
    },
  },
  methods: {
    async goToApiDoc(v) {
      if (v) {
        await this.$router.push(`/api-docs/${v}`)
      } else {
        await this.$router.push(`/api-docs`)
      }
      window.location = "#";
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins';

.api-docs-header {
  background-color: var(--api-docs-header-bg);
  color: var(--api-docs-header-text);

  .app-logo {
    filter: brightness(0) invert(100%);
  }

  height: $header-size;
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header-size;
  }
  .top .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 1;
    padding: 10px;

    @include phone {
      .logo .short {
        height: 28px;
        display: block;
      }
      .logo .long {
        display: none;
      }
    }

    @include not-phone {
      .logo .short {
        display: none;
      }
      .logo .long {
        display: block;
        max-width: 200px;
        max-height: 40px;
      }
    }
  }
  .base-select {
    min-width: 275px;
    margin-right: 20px;
    color: var(--text);
  }

  .title {
    margin-left: 8px;
  }
}

:deep(.base-select) {
  img {
    width: 30px !important;
    height: auto !important;
    margin-left: -5px;
  }

  .search-content > span {
    display: flex;
    align-items: center;
  }

  .base-item .long {
    display: flex;
    align-items: center;
  }
}
</style>