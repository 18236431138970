<template>
  <div>
    <span 
      v-for="i in intervalOptions" 
      :key="i" 
      role="button" 
      tabindex="0" 
      :class="['clickable','intervalOption', isIntervalActive(i) ? 'link-on-primary' : '']"
      @keyup.enter="$emit('click', i)" 
      @click="$emit('click', i)"
    >
      {{ i }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'ChartIntervalSelector',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    intervalOptions: {
      type: Array,
      default: () => ['1h', '1d', '7d', '30d'],
    },
  },
  emits: ['click'],
  methods: {
    isIntervalActive(currentInterval) {
      return currentInterval === this.modelValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.intervalOption {
  padding-left: 5px;
}
</style>
