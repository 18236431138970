<template>
  <div class="toggle-buttons">
    <div 
      v-for="(choice, $index) in choices" 
      :key="choice.name" 
      tabindex="0" 
      class="toggle-button clickable" 
      :class="{active: choice.name === selected || (!selected && $index === 0)}" 
      @click="clickedChoice(choice)" 
      @keydown.space.prevent="clickedChoice(choice)" 
      @keydown.enter.prevent="clickedChoice(choice)"
    >
      <base-icon 
        v-if="choice.icon"
        :icon="choice.icon" 
        class="{ fixed: !withText }" 
      /> <span 
        v-if="withText"
        class="option-label" 
      >{{ $t(choice.label || choice.name) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    choices: {
      type: Array,
      required: true,
    },
    withText: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Boolean, String],
      required: true,
    },
    size: {
      type: String,
      validator: v => ['xs', 'sm', 'md', 'lg'].includes(v),
    },
  },
  emits: ['toggle'],
  data() {
    return {};
  },
  methods: {
    clickedChoice(choice) {
      this.$emit('toggle', choice);
    },
  },
};
</script>


<style scoped lang="scss">
.toggle-buttons {
  white-space: nowrap;
  .toggle-button {
    display: inline-block;
    padding: 6.5px 12px;
    transition: background-color 0.5s, color 0.5s;
    $radius: 18px;

    em {
      font-size: 18px;
    }

    &:first-child {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }

    &:not(:first-child) {
      border-left: none;
    }

    &:last-child {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }

    .fixed {
      width: 18px;
      height: 18px;
    }

  }
}
</style>
