<template>
  <div class="trial-registration-form">
    <base-form
      :hideCancel="true"
      :disabled="!recaptchaVerified && recaptchaEnabled"
      :executing="executing"
      :fullWidth="true"
      :submitLabel="$t('register_trial')"
      @submit="callRegister"
    >
      <div>
        <div class="register-title">
          {{ $t('register_today') }}
        </div>
        <div class="first-last-name">
          <div class="width-adjust">
            <form-row
              :label="$t('label_first_name')"
              :error="$t(errorsObj.firstName)"
            >
              <base-input
                v-model="firstName"
                icon="fa fa-address-card-o"
                :maxLength="50"
                @focus="validateErrors.firstName=null"
              ></base-input>
            </form-row>
          </div>
          <div class="width-adjust">
            <form-row
              :label="$t('label_last_name')"
              :error="$t(errorsObj.lastName)"
            >
              <base-input
                v-model="lastName"
                icon="fa fa-address-card"
                :maxLength="50"
                @focus="validateErrors.lastName=null"
              ></base-input>
            </form-row>
          </div>
        </div>
      </div>
      <div>
        <form-row
          :label="$t('label_email')"
          :error="$t(errorsObj.email)"
        >
          <base-input
            v-model="email"
            icon="fa fa-at"
            type="email"
            :maxLength="255"
            @focus="validateErrors.email=null"
          ></base-input>
        </form-row>
      </div>
      <div>
        <form-row
          :label="$t('label_phone_number')"
          :error="$t(errorsObj.phoneNumber)"
        >
          <base-input
            v-model="phoneNumber"
            icon="fa fa-phone"
            type="phone"
            :maxLength="255"
            @focus="validateErrors.phoneNumber=null"
          ></base-input>
        </form-row>
      </div>
      <div>
        <form-row
          :label="$t('label_company_name')"
          :error="$t(errorsObj.organizationName)"
        >
          <base-input
            v-model="organizationName"
            icon="fa fa-building"
            :maxLength="50"
            @focus="validateErrors.organizationName=null"
          ></base-input>
        </form-row>
      </div>
      <div>
        <form-row
          :label="$t('label_comment')"
          :error="$t(errorsObj.blurb)"
        >
          <base-text-area
            v-model="blurb"
            :rows="3"
            @focus="validateErrors.blurb=null"
          ></base-text-area>
        </form-row>
      </div>
      <TrialRecaptcha
        v-if="recaptchaEnabled && recaptchaSitekey"
        :key="locale"
        :sitekey="recaptchaSitekey"
        @recaptchaVerified="onRecaptchaVerified"
        @recaptchaError="onRecaptchaError"
      />
    </base-form>
    <div class="terms">
      <span>{{ $t('click_submit_you_agree') }}&nbsp;</span>
      <router-link
        target="_blank"
        class="link-on-primary clickable fake-a"
        :to="trialTermsUrl"
      >
        {{ $t('terms_of_service') }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import validators from '@/utils/validators';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import errors from '@/utils/errors';
import TrialRecaptcha from './TrialRecaptcha';

export default {
  name: 'TrialRegistrationForm',
  components: { TrialRecaptcha },
  emits: ['registerSuccess'],
  data() {
    return {
      executing: false,
      firstName: '',
      lastName: '',
      email: '',
      userName: '',
      phoneNumber: '',
      organizationName: '',
      blurb: '',
      recaptchaVerified: false,
      recaptchaEnabled: false,
      recaptchaSitekey: null,
      validateErrors: {
        organizationName: '',
        phoneNumber: '',
        email: '',
        lastName: '',
        firstName: '',
        blurb: '',
      },
      errorResponse: {},
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    trialTermsUrl() {
      return `/rest/anon/trials/terms/${this.locale}`;
    },
    errorsObj() {
      const err = {};
      errors.parse(this.$t.bind(this), this.errorResponse, err);
      const emailError = (this.errorResponse.errors || [])
        .find(e => (e.context || {}).field === 'email');
      if (emailError) {
        err.email = this.$t('email_domain_not_allowed', emailError.context);
      }
      return { ...this.validateErrors, ...err };
    },
  },
  async created() {
    await this.getRecaptchaInfo();
  },
  methods: {
    validate() {
      if (!this.firstName
          || !this.lastName
          || !this.email
          || !this.phoneNumber
          || !this.organizationName
      ) {
        this.validateErrors.firstName = (!this.firstName) ? this.$t('missing_first_name') : '';
        this.validateErrors.lastName = (!this.lastName) ? this.$t('missing_last_name') : '';
        this.validateErrors.email = (!this.email) ? this.$t('missing_email') : '';
        this.validateErrors.phoneNumber = (!this.phoneNumber) ? this.$t('missing_phone_number') : '';
        this.validateErrors.organizationName = (!this.organizationName) ? this.$t('missing_company_name') : '';
      } else {
        this.validateErrors.firstName = (!validators.validName(this.firstName)) ? this.$t('invalid_first_name_length') : '';
        this.validateErrors.lastName = (!validators.validName(this.lastName)) ? this.$t('invalid_last_name_length') : '';
        this.validateErrors.email = (!validators.validEmail(this.email)) ? this.$t('invalid_email') : '';
        this.validateErrors.phoneNumber = (!validators.validPhone(this.phoneNumber)) ? this.$t('invalid_phone_number') : '';
        this.validateErrors.organizationName = (!validators.validCompanyName(this.organizationName)) ? this.$t('invalid_organization_name') : '';
        this.validateErrors.blurb = (!validators.validBlurb(this.blurb)) ? this.$t('too_long_blurb') : '';
      }
      return Object.values(this.validateErrors).every(err => (err === ''));
    },
    onRecaptchaVerified(response) {
      this.recaptchaVerified = response;
    },
    onRecaptchaError() {
      this.recaptchaEnabled = false;
    },
    async callRegister() {
      if (!this.validate()) {
        return;
      }
      const trialRegistration = {
        firstName: this.firstName,
        lastName: this.lastName,
        organizationName: this.organizationName,
        email: this.email,
        blurb: this.blurb,
        language: this.locale,
        phoneNumber: this.phoneNumber,
      };
      this.executing = true;
      const res = await apis.trials.publicCreate(trialRegistration);
      this.executing = false;
      if (res.errors) {
        if (res.errors.find(e => e.errorCode === 'NATIVE_AUTHENTICATION_NOT_SUPPORTED')) {
          notify.error(this.$t('trial_not_supported_for_domain'));
        }
        this.errorResponse = res;
      } else if (res.data) {
        const status = res.data.status;
        if (status === 'SUBMITTED') {
          this.$emit('registerSuccess', true);
        }
      }
    },
    async getRecaptchaInfo() {
      const resp = await apis.trials.getRecaptchaInfo();
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('trials.error_fetch_settings'));
        return;
      }
      this.recaptchaEnabled = resp.data.enableRecaptcha;
      this.recaptchaSitekey = resp.data.recaptchaSitekey;
    },
  },
};
</script>


<style scoped lang="scss">
  @import '@/styles/login-form';
  .trial-registration-form {
    :deep(.form-row) {

      .label {
        font-size: 1rem;
      }
      @include not-phone {
        padding-bottom: 1px !important;
      }
      @include phone {
        font-size: 1.5em;
        padding-bottom: 1px !important;
      }
    }

    .terms {
      font-size: small;
      text-align: center;
      @include not-phone {
        padding-top: 15px;
      }
      @include phone {
        padding-top: 5px;
      }
      padding-bottom: 15px;
    }

    .register-title {
      @include phone {
        font-size: 1.5em;
        margin: 1px 0;
      }
      @include not-phone {
        font-size: 2em;
        margin: 10px 0;
      }
    }

    .first-last-name {
      @include not-phone {
        display: flex;
        justify-content: space-between;
      }
    }
    .width-adjust {
      @include not-phone {
        width: 48%;
      }
    }
  }
</style>
