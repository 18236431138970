import { ReadOnlyApi } from './api';

class HelpCenterApi extends ReadOnlyApi {
  constructor() {
    super('kb');
  }

  getTopicById(id) {
    return this.get(`kb/categories/${id}`);
  }

  getArticleById(id) {
    return this.get(`kb/articles/${id}`);
  }

  searchForArticles(query, lang, org) {
    const qs = {
      q: query,
      language: lang,
      org,
    };
    return this.get('kb/articles/search', { qs });
  }

  getTopicBySlug(slug, lang, org) {
    const qs = {
      language: lang,
      org,
    };
    return this.get(`kb/${slug}`, { qs });
  }

  getArticleBySlug(topicSlug, articleSlug, lang, org) {
    const qs = {
      language: lang,
      org,
    };
    return this.get(`kb/${topicSlug}/${articleSlug}`, { qs });
  }
}

const helpCenterApi = new HelpCenterApi();

export default helpCenterApi;
