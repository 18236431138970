import { CrudApi } from './api';

class PluginsApi extends CrudApi {
  constructor() {
    super('plugins');
  }

  configurations(id) {
    return this.get(`/plugins/${id}/configurations`);
  }

  updateParameters(id, params) {
    return this.put(`/plugins/${id}/parameters`, {
      body: JSON.stringify(params),
    });
  }
  
  features(id) {
    return this.get(`/plugins/${id}/features`);
  }
}

const pluginsApi = new PluginsApi();

export default pluginsApi;
