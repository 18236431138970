import { CrudApi } from './api';

class ProductCatalogApi extends CrudApi {
  constructor() {
    super('product_catalogs');
  }

  getUsageTypes(serviceType, organizationId, connectionIds) {
    return this.get(`product_catalogs/${serviceType}/usage_types`, {
      qs: {
        organization_id: organizationId,
        connection_id: (connectionIds || []).join(','),
      },
    });
  }

  getCapturableAttributes(serviceType, organizationId, usageType) {
    return this.get(`product_catalogs/${serviceType}/capturable_attributes`, {
      qs: {
        organization_id: organizationId,
        usage_type: usageType,
      },
    });
  }

  getCapturableAttributesByUsageType(serviceType, organizationId) {
    return this.get(`product_catalogs/${serviceType}/capturable_attributes_by_usage_type`, {
      qs: {
        organization_id: organizationId,
      },
    });
  }

  getFilterableFields(serviceType, organizationId, usageType) {
    return this.get(`product_catalogs/${serviceType}/filterable_fields`, {
      qs: {
        organization_id: organizationId,
        usage_type: usageType,
      },
    });
  }

  getFilterableFieldsByUsageType(serviceType, organizationId) {
    return this.get(`product_catalogs/${serviceType}/filterable_fields_by_usage_type`, {
      qs: {
        organization_id: organizationId,
      },
    });
  }

  getFilterableValues(serviceType, organizationId, connectionIds, usageType, filterableField) {
    return this.get(`product_catalogs/${serviceType}/filterable_values`, {
      qs: {
        organization_id: organizationId,
        usage_type: usageType,
        connection_id: (connectionIds || []).join(','),
        filterable_field: filterableField,
      },
    });
  }

  validateExpression(expression) {
    return this.post('product_catalogs/validate_expression', {
      body: expression,
    });
  }
}

const productCatalogApi = new ProductCatalogApi();
export default productCatalogApi;
