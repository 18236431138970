<template>
  <div v-if="!waiting">
    <h1>{{ $t('set_password_header') }}</h1>
    <p>{{ $t('reset_password_step2') }}</p>

    <base-form
      :fullWidth="true"
      :hideCancel="true"
      :disabled="!isValid"
      submitLabel="save_password"
      @submit="callReset"
    >
      <form-row label="password">
        <div class="input-container">
          <choose-password
            id="password"
            v-model="password"
            :isEnterprise="true"
            :error="errors.password"
            :disallowedStrings="orgInfo"
            size="lg"
            :rounded="true"
            :placeholder="$t('password')"
            @valid-new-password="validPassword"
          />
        </div>
      </form-row>
      <form-row label="confirm_new_password">
        <div class="input-container">
          <base-input
            id="confirmPassword"
            v-model="passwordConfirm"
            type="password"
            :error="errors.passwordConfirm"
            :placeholder="$t('reenter_password')"
            icon="fa fa-lock"
            size="lg"
            :rounded="true"
            required
          />
        </div>
      </form-row>
    </base-form>
  </div>
  <div v-else>
    <BaseStateContainer
      v-show="waiting"
      type="enterprise"
      :icon="icon"
      hideLeftButton
      hideMiddleButton
      hideRightButton
      :actionMessage="actionMessage"
      :forceShowLoader="!taskFailed"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import BaseStateContainer from "@/app/Main/Services/components/states/BaseStateContainer.vue";
import BaseInput from "@/components/input/BaseInput.vue";
import ChoosePassword from "@/components/ChoosePassword.vue";
import FormRow from "@/components/form/FormRow.vue";
import BaseForm from "@/components/form/BaseForm.vue";

export default {
  name: 'SetEnterprisePasswordForm',
  components: {BaseForm, FormRow, ChoosePassword, BaseInput, BaseStateContainer},
  props: {
    orgInfo: {
      type: Array,
      required: true,
    },
  },
  emits: ['successfulRegistration'],
  data() {
    return {
      password: '',
      passwordConfirm: '',
      errors: {
        password: false,
        passwordConfirm: false,
      },
      newPasswordIsValid: false,
      waiting: false,
      pollingStatus: 'PENDING',
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    isValid() {
      return this.newPasswordIsValid;
    },
    taskFailed() {
      return this.pollingStatus === 'FAILURE';
    },
    taskSuccess() {
      return this.pollingStatus === 'SUCCESS' || this.pollingStatus === 'PARTIAL_SUCCESS';
    },
    actionMessage() {
      return this.taskFailed ? 'enterprise.enterprise_onboarding_error' :
        'enterprise.enterprise_onboarding'
    },
    icon() {
      return this.taskFailed ? 'fa fa-exclamation-triangle yellow' : '';
    },
  },
  methods: {
    validate() {
      if (!this.password) {
        notify.error(this.$t('missing_password'));
        return;
      }
      if (!this.passwordConfirm) {
        notify.error(this.$t('missing_password_confirm'));
        return;
      }
      if (this.password !== this.passwordConfirm) {
        notify.error(this.$t('password_confirm_error'));
        return;
      }
      return this.validateErrors();
    },
    validateErrors() {
      this.errors.password = !this.password;
      this.errors.passwordConfirm = !this.passwordConfirm
        || (this.password !== this.passwordConfirm);

      return !(this.errors.password || this.errors.passwordConfirm);
    },
    validPassword(value) {
      this.newPasswordIsValid = value;
    },
    async callReset() {
      // removes toast notification
      notify.destroy();
      if (!this.validate()) {
        return;
      }
      const resp = await apis.enterpriseRegistrations.register(this.password, this.$route.query.registrationToken);
      if (!resp || !resp.ok) {
        notify.error(this.$t("enterprise_registration.error_completing_registration"));
        return;
      }
      this.waiting = true;
      await this.waitForCompletion(resp.taskId);
      if (this.taskSuccess) {
        this.$router.replace('/home');
        this.$emit('successfulRegistration');
      }
    },
    async waitForCompletion(taskId) {
      /* Poll for 5m every 5s */
      for (let attempts = 60; attempts >= 0; attempts--) {
        const res = await apis.tasks.find(taskId);
        if (res.ok || res.status === 200) {
          this.pollingStatus = res.data.status
        } else {
          this.pollingStatus = 'FAILURE'
          break;
        }

        if (this.pollingStatus === 'SUCCESS' || this.pollingStatus === 'PARTIAL_SUCCESS') {
          break;
        }

        if (this.pollingStatus === 'FAILURE') {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/login-form';

#set-password-form {
  :deep(.form-row .label-row) {
    display: none;
  }
}
</style>
