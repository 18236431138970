import { CrudApi } from './api';

class InvoiceConfig extends CrudApi {
  constructor() {
    super('/reseller/settings/invoice-config');
  }

  search(organizationId) {
    return this.get('reseller/settings/invoice-config/search', {
      qs: {
        organizationId,
      },
    });
  }
}
const invoiceConfig = new InvoiceConfig();
export default invoiceConfig;
