<template>
  <div 
    ref="dropdown_listener" 
    class="action-dropdown" 
    @keypress.capture="capture"
  >
    <action-icon 
      v-if="!hideIcon && permittedActions.length > 0" 
      icon="fa fa-ellipsis-v" 
      :expandOnHover="true" 
      tooltipLabel="more_actions" 
      @click.stop="toggleOpen"
    />
    <items-dropdown
      keydownRef="dropdown_listener"
      :class="opened || open ? '' : 'hidden'"
      :opened="opened || open"
      :items="permittedActions"
      header="select_action"
      direction="down"
      position="left"
      size="sm"
      @close="handleClose"
    />
  </div>
</template>
<script>
import authz from '@/authz';

/**
 * Create an action dropdown. Usually used in resource rows
 */
export default {
  name: 'ActionDropdown',
  props: {
    /**
     *
        {
            value: String,
            label: String,
            icon: String,
            href: String,
            permission: String,
        }
     */
    items: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close', 'select'],
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    permittedActions() {
      const actions = this.items
        .filter(i => !i.permission || authz.hasPermission(i.permission));
      return actions;
    },
  },
  mounted(){
    let self=this;

    window.addEventListener('keyup', function(ev) {
      if(ev.key=='Tab'){
        self.handleClose();
      }     
    });    
  },

  methods: {
    toggleOpen() {
      if (this.opened) {
        this.handleClose();
      } else {
        this.handleOpen();
      }
    },
    handleOpen() {
      this.$emit('open');
      this.opened = true;
    },
    handleClose(event, item) {
      this.opened = false;
      this.$emit('close', event, item);
      if (event) {
        this.$emit('select', event, item);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.action-dropdown {
  position: relative;
}

.hidden {
  visibility: hidden;
}
</style>
