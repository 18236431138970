<template>
  <div 
    class="list-header" 
    role="row" 
    :class="{ 'desktop-only': !mobile }"
  >
    <slot></slot>
    <div 
      v-if="!noActions" 
      class="action-column"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ListHeader',
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
};
</script>


<style scoped lang="scss">

.list-header {
  display: flex;
  flex-direction: row;
  padding: 10px 32px;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: left;
}

</style>
