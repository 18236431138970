import { CrudApi } from './api';

class TermsOfService extends CrudApi {
    constructor() {
        super('terms_of_service');
    }

    getPendingToS() {
        return this.get('/terms_of_service/pending')
    }

    acceptToS(tosIds) {
        return this.post('/terms_of_service/accept', { body: tosIds })
    }
}

const termsOfService = new TermsOfService();
export default termsOfService;
