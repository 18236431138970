<template>
  <span></span>
</template>

<script setup>
import useEvents from '@/utils/events/useEvents';

const props = defineProps({
  codes: {
    type: Array,
    required: true,
  },
  currentOrganization: {
    type: Boolean,
    default: true,
  },
});

const $emit = defineEmits(['receive'])

const { onSystemActivity } = useEvents();
onSystemActivity(props.codes, props.currentOrganization)(e => $emit('receive', e));

</script>
