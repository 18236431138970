import { boldify, flatten } from '@/utils';
import store from '@/store';
import i18n from '@/i18n';
import { findServiceLabel } from '../label';
import router from '@/router';

const checkStatus = (activity, status) => activity.status === status;

const isSuccess = activity => checkStatus(activity, 'SUCCESS');
const isPending = activity => checkStatus(activity, 'PENDING');
const isFailure = activity => checkStatus(activity, 'FAILURE');
const isPartialSuccess = activity => checkStatus(activity, 'PARTIAL_SUCCESS');

function getRootContext(activity) {
  if ((activity.events || []).length > 0) {
    if (activity.events[0].eventCode === activity.eventCode) {
      return {
        ...JSON.parse(activity.eventContext),
        ...JSON.parse(activity.events[0].eventContext || {}),
      };
    }
  }
  return JSON.parse(activity.eventContext);
}

function buildTitle(status) {
  return i18n.global.t(`events.system.notifications.${status.toLowerCase()}`);
}

function buildButton(event, eventCtx) {
  function getChainContext(ctx) {
    if (typeof ctx === 'string' || ctx instanceof String) {
      return JSON.parse(ctx);
    } else {
      return ctx;
    }
  }

  function buildButton0(chain) {
    if (chain.view == null) {
      return null;
    }

    let path;
    if (chain.view.root) {
      // Redirect to the root page when specified to.
      path = '/' + [
        chain.view.root,
        ...(chain.view.segments || [])
      ].join('/');
    } else {
      // Redirect to service workspace by default
      path = '/' + [
        'services',
        chain.view.serviceCode,
        chain.view.environmentName,
        ...(chain.view.segments || [])
      ].join('/');
    }

    const query = {
      ...chain.view.params,
      ...(chain.view.organizationCode == null ? {} : { 'org': chain.view.organizationCode }),
    };

    return {
      label: i18n.global.t(chain.view.label, eventCtx),
      name: 'chainAction',
      callback: () => {
        router.push({ path, query });
      },
    };
  }

  const ctx = getChainContext(event.eventContext);
  const btn = ctx.chain == null ? null : buildButton0(ctx.chain);

  return btn;
}

const instanceLocale = () => {
  return i18n.global.locale;
}

const getEventContext = (activity, parent) => {
  if (activity.eventContext) {
    const curCtx = getRootContext(activity);
    const parentCtx = parent && parent.eventContext ? JSON.parse(parent.eventContext) : {};
    return boldify(flatten({ ...curCtx, ...parentCtx }));
  }
  return {};
};

const getServiceLabel = (serviceType, activity) => {
  const eventCode = activity.eventCode;
  let status = activity.status;
  if (isPartialSuccess(activity)) {
    status = 'FAILURE';
  }
  return `events.service_operation.${serviceType}.${eventCode}.${status}`.toLowerCase();
};

const getActivityLabel = (activity) => {
  if (activity.category === 'SYSTEM') {
    const labelKey = i18n.global.te(`events.system.${activity.eventCode}.${activity.status}`.toLowerCase())
      ? `events.system.${activity.eventCode}.${activity.status}`
      : `events.system.${activity.eventCode}.pending`;
    return labelKey.toLowerCase();
  }
  const serviceType = activity.serviceConnectionType;
  return findServiceLabel(serviceType, store.getters.serviceDescriptors, getServiceLabel, activity);
};

const getActivityName = (serviceConnectionType, eventCode) => getActivityLabel({
  eventCode,
  serviceConnectionType,
  category: serviceConnectionType,
  status: 'name',
});


export {
  getActivityLabel, getActivityName, getEventContext, buildTitle, buildButton,
  isSuccess, isPending, isFailure, isPartialSuccess, instanceLocale
};
