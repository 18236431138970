<template>
  <form-page
    autofocus
    :title="$t('edit_organization')"
    hideCancel
    hideSubmit
  >
    <OrganizationForm
      :executing="executing"
      :organization="organization"
      :hideBillableToggle="organization.isBillable"
      :errors="errorsObj"
      :sourcePage="getSourcePage"
      @submit="updateOrg"
    />
  </form-page>
</template>

<script>
import authz from '@/authz';
import apis from '@/utils/apis';
import errors from '@/utils/errors';
import notify from '@/utils/notify';
import {asc} from '@/utils';
import {mapActions, mapGetters} from 'vuex';
import OrganizationForm from './OrganizationForm';
import store from '@/store';

export default {
  name: 'EditOrganization',
  components: { OrganizationForm },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['refreshOrg','cancel'],
  data() {
    return {
      executing: false,
      errorResponse: {},
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
      'selectedOrganization',
    ]),
    errorsObj() {
      const err = {};
      errors.parse(this.$t.bind(this), this.errorResponse, err);
      return { ...err, customFieldErrors: this.errorResponse.errors };
    },
    getSourcePage() {
      if (this.$route.query.s && this.$route.query.s!==''){
        return this.$route.query.s
      }
      return store.getters.previousRoute.name;
    },
  },
  methods: {
    ...mapActions([
      'updateCurrentOrgAttributes',
      'reloadUserContext',
    ]),
    verifyBeforeUpdate(org) {
      // basic check if billable is selected by no date
      if (org.isBillable && !org.billableStartDate) {
        org.isBillable = false;
      }

      // check if there is a start date and billable is false
      if (org.billableStartDate && !org.isBillable) {
        org.billableStartDate = null;
      }
      org.serviceConnections = undefined;
    },
    hasBillingPermission() {
      return authz.hasPermission('reseller:organizationBilling') && (this.organization.id !== this.myOrganization.id
        || this.isRootOrg);
    },
    isRootOrg() {
      return !this.organization.parent;
    },
    async updateOrg(org, billableOrgInfo) {
      this.executing = true;
      this.verifyBeforeUpdate(org);
      const resp = await apis.organizations.update(this.organization.id, org);
      if (resp && resp.errors) {
        this.errorResponse = resp;
        this.executing = false;
        return;
      } else {
        if (org.isBillable && this.hasBillingPermission() && billableOrgInfo !== null) {
          const resp2 = await apis.organizations.setBillable(org.id, billableOrgInfo);
          if (resp2 && resp2.errors) {
            notify.error(this.$t('reports.unexpected_error_billable'));
            return;
          }
        }
        if (this.selectedOrganization.id === org.id) {
          if (!org.serviceConnections) {
            const conns = await apis.serviceConnections.listForOrg(this.selectedOrganization.id);
            org.serviceConnections = conns.data.sort(asc(c => c.name));
          }
          this.updateCurrentOrgAttributes(org);
        }
        this.$emit('refreshOrg');
        if (this.selectedOrganization.id === this.organization.id) {
          this.reloadUserContext();
        }
      }

      this.$router.push({ name: this.getSourcePage })
      this.executing = false;
    },
  },
};
</script>
