<template>
  <advanced-details titleLabel="service_providers.title">
    <base-list 
      aria-labelledby="service_providers.title" 
      ariaDescription="service_providers.description" 
      :ariaRowCount="filteredServiceProviders.length"
    >
      <list-header>
        <list-column>{{ $t('service_providers.name') }}</list-column>
        <list-column>{{ $t('service_providers.entity_id') }}</list-column>
        <list-column>{{ $t('service_providers.consumer_assert_url') }}</list-column>
      </list-header>
      <base-loader v-if="loading"></base-loader>
      <list-row 
        v-for="serviceprovider in filteredServiceProviders"
        :key="serviceprovider.id" 
      >
        <list-column>
          {{ serviceprovider.name }}
        </list-column>
        <list-column>
          <copyable-field 
            v-if="issuerId(serviceprovider)" 
            :modelValue="issuerId(serviceprovider)" 
            :onHover="true"
          >
            <div>{{ issuerId(serviceprovider) }}</div>
          </copyable-field>
        </list-column>
        <list-column>
          <copyable-field 
            v-if="consumerUrl(serviceprovider)" 
            :modelValue="consumerUrl(serviceprovider)" 
            :onHover="true"
          >
            <div>{{ consumerUrl(serviceprovider) }}</div>
          </copyable-field>
        </list-column>
        <template #actions>
          <action-icon 
            icon="fa fa-edit" 
            :to="{name:'edit-service-provider', params: {id: serviceprovider.id}}"
          />
          <action-dropdown
            :items="actions"
            @select="confirmAction(serviceprovider.id, $event)"
            @close="$emit('close')"
          />
          <div v-tooltip.top="{ content: $t('copied'), shown: serviceprovider.id === copiedSp, triggers: ['manual']}" />
        </template>
      </list-row>
      <template #empty>
        <empty-message
          v-if="!filteredServiceProviders.length && !loading"
          icon="fa fa-cube"
          textLabel="service_providers.no_service_providers"
        />
      </template>
      <confirm-modal
        headerIcon="fa fa-check-circle"
        :open="openConfirmDialog"
        :headerLabel="actionContext.title"
        :headerLabelInterpolation="actionContext.interpolation"
        :detailsLabel="actionContext.message"
        :detailsInterpolation="actionContext.interpolation"
        :confirmLabel="actionContext.submitLabel"
        @ok="deleteProvider"
        @cancel="close" 
      />
    </base-list>
  </advanced-details>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { searchFilter } from '@/utils';
import { mapGetters } from 'vuex';

import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';

export default {
  name: 'ServiceProviders',
  mixins: [resellerOrgNavigationMixin],
  emits: ['close'],
  data() {
    return {
      timeout: '',
      copiedSp: null,
      loading: true,
      serviceProviders: [],
      actionContext: {
        id: '',
        interpolation: { name: '' },
        title: 'service_providers.delete_title',
        message: 'service_providers.delete_message',
        submitLabel: 'confirm',
      },
      openConfirmDialog: false,
      organization: {},
    };
  },
  computed: {
    ...mapGetters(['globalProperties', 'selectedOrganization']),
    showButton() {
      return this.$route.name === 'serviceProvidersList';
    },
    search() {
      return this.getParameterValue(this.$route.query.q);
    },
    filteredServiceProviders() {
      return this.serviceProviders.filter(
        searchFilter(this.search, p => [
          p.name,
          this.issuerId(p),
          this.consumerUrl(p),
        ]),
      );
    },
    actions() {
      return [
        {
          value: 'DELETE',
          icon: 'fa fa-trash',
          label: 'service_providers.delete_service_provider',
        },
        {
          value: 'COPY',
          icon: 'fa fa-clipboard',
          label: 'service_providers.copy_endpoint',
        },
        {
          value: 'COPY_METADATA',
          icon: 'fa fa-clipboard',
          label: 'service_providers.copy_metadata_url',
        },
      ];
    },
  },
  watch: {
    selectedOrganization(oldOrg, newOrg) {
      if (oldOrg.id !== newOrg.id) {
        this.load();
      }
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    getParameterValue(param) {
      return Array.isArray(param) ? param[0] : param;
    },
    issuerId(sp) {
      return sp.config ? sp.config.serviceProviderIssuer : undefined;
    },
    consumerUrl(sp) {
      return sp.config ? sp.config.assertionConsumerUrl : undefined;
    },
    buildSAMLEndpointURL(providerId) {
      let host;
      if (this.organization.customDomain) {
        host = this.organization.customDomain.domain;
      } else {
        host = this.globalProperties['public.host'];
      }
      const portProperty = this.globalProperties['public.port'];
      const port = (portProperty && portProperty !== '80' && portProperty !== '443')
        ? `:${portProperty}`
        : '';
      return `${this.globalProperties.protocol}://${host}${port}/rest/saml/provider/${providerId}`;
    },
    copySAMLEndpointURL(spId) {
      this.writeToClipboard(this.buildSAMLEndpointURL(spId), spId);
      this.copiedSp = spId;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.copiedSp = null;
        this.timeout = null;
      }, 1000);
    },
    copyMetadataURL(spId) {
      this.writeToClipboard(`${this.buildSAMLEndpointURL(spId)}/metadata`, spId);
      this.copiedSp = spId;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.copiedSp = null;
        this.timeout = null;
      }, 1000);
    },
    writeToClipboard(text, spId) {
      navigator.clipboard.writeText(text);
      this.copiedSp = spId;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.copiedSp = null;
        this.timeout = null;
      }, 1000);
    },
    async fetchOrganization() {
      const response = await apis.organizations.find(this.selectedOrganization.id);
      this.organization = response.data;
    },
    async fetchServiceProviders() {
      this.loading = true;
      const resp = await apis.serviceProviders.listForOrg(this.selectedOrganization.id);
      if (resp && resp.status !== 200) {
        notify.error(this.$t('service_providers.error_list_service_providers'));
        this.loading = false;
        return;
      }
      this.serviceProviders = resp.data;
      this.loading = false;
    },
    confirmAction(spId, action) {
      switch (action) {
        case 'DELETE':
          this.confirmDeleteServiceProvider(spId);
          break;
        case 'COPY':
          this.copySAMLEndpointURL(spId);
          break;
        case 'COPY_METADATA':
          this.copyMetadataURL(spId);
          break;
        default:
          break;
      }
    },
    confirmDeleteServiceProvider(spId) {
      const selectedSP = this.serviceProviders.find(sp => sp.id === spId);
      const spName = selectedSP ? selectedSP.name : null;
      this.actionContext.id = spId;
      this.actionContext.interpolation.name = spName;
      this.openConfirmDialog = true;
    },
    async deleteProvider() {
      const spName = this.actionContext.interpolation.name;
      const response = await apis.serviceProviders.remove(
        this.actionContext.id,
        {},
      );
      if (!response) {
        notify.error(this.$t('unexpected_error'));
      } else if (response.status === 400 && response.errors) {
        response.errors.forEach(err => notify.error(this.$t(err.message)));
      } else if (response.status !== 200) {
        notify.error(
          this.$t('service_providers.delete_api_error', { name: spName }),
        );
      } else {
        notify.success(this.$t('service_providers.deleted', { name: spName }));
        this.fetchServiceProviders();
      }
      this.close();
    },
    close() {
      this.actionContext.id = null;
      this.actionContext.interpolation.name = null;
      this.openConfirmDialog = false;
    },
    async load() {
      this.fetchOrganization();
      this.fetchServiceProviders();
    },
  },
};
</script>
