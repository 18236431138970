<template>
  <div class="landing-form">
    <div 
      v-if="!submitted"
      class="forgot-password-form"
    >
      <h1>{{ $t('reset_password_header') }}</h1>
      <p>{{ $t('reset_password_step1') }}</p>
      <base-form 
        class="forgot-password-form" 
        :hideCancel="true" 
        :fullWidth="true" 
        submitLabel="reset" 
        :transitionButton="true"
        @submit="requestResetPassword" 
      >
        <form-row label="email">
          <div class="input-container">
            <base-input
              v-model="email"
              type="email"
              :error="error"
              icon="fa fa-at"
              size="lg"
              placeholder="enter_email"
              :rounded="true"
            >
            </base-input>
          </div>
        </form-row>
      </base-form>
    </div>
    <div 
      v-if="submitted"
      class="submitted-message"
    >
      <h1>{{ $t('email_sent_header') }}</h1>
      <p>{{ $t('email_sent_explainer') }}</p>
    </div>

    <div class="link-container">
      <a 
        role="button" 
        tabindex="0" 
        @keyup.enter="$emit('rememberedPassword')" 
        @click="$emit('rememberedPassword')"
      >{{ $t("back_to_login") }}</a>
    </div>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';

export default {
  name: 'ForgottenPasswordForm',
  emits: ['rememberedPassword'],
  data() {
    return {
      email: '',
      error: false,
      submitted: false,
    };
  },

  methods: {
    validateEmail() {
      this.error = false;

      if (!this.email || !this.email.length) {
        this.error = true;
      }

      return !this.error;
    },
    requestResetPassword() {
      if (!this.validateEmail()) {
        notify.error(this.$t('email_validation_error'));
        return;
      }

      this.submitted = true;
      apis.users.forgotPassword(this.email);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/login-form';

.forgot-password-form {
  :deep(.form-row .label-row) {
    display: none;
  }
}
</style>
