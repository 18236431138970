<template>
  <base-message 
    :icon="'fa fa-search'" 
    class="no-results" 
    textLabel="search_results_empty"
  />
</template>

<script>
import BaseMessage from './BaseMessage';

export default {
  name: 'NoResultsMessage',
  components: { BaseMessage },
};
</script>
