<template>
  <div>
    <noscript><iframe v-if="containerId" :src="src" height="0" width="0" style="display:none;visibility:hidden"/></noscript>
    <system-activity-listener 
      :codes="codes" 
      @receive="load" 
    />
  </div>
</template>

<script>
import SystemActivityListener from '@/events/SystemActivityListener';

// GTM requires 2 snippets (1 HTML, 1 javascript) placed at the top of the webage
// docs can be found here https://developers.google.com/tag-platform/tag-manager/web
export default {
  name: 'GoogleTagManager',
  components: { SystemActivityListener },
  props: {
    containerId: {
      type: String,
      required: true,
    },
  },
  emits: ['refresh'],
  computed: {
    src() {
      return `https://www.googletagmanager.com/ns.html?id=${this.containerId}`;
    },
    codes() {
      return [
        'feedback_settings.create',
        'feedback_settings.update',
        'feedback_settings.delete',
      ];
    },
  },
  async created() {
    await this.activateGtm(window, document, 'script', 'dataLayer', this.containerId);
  },
  methods: {
    async activateGtm(w, d, s, l, i) { // (window, document, 'script', 'dataLayer', 'GTM-XXXXXX')
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    },
    async load() {
      this.$emit('refresh');
    },
  },
};
</script>
