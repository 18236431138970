import { ReadOnlyApi } from './api';

class SystemInfoApi extends ReadOnlyApi {
  constructor() {
    super('systeminfo/releasenotes');
  }
}

const systemInfoApi = new SystemInfoApi();

export default systemInfoApi;
