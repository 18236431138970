<template>
  <v-chart 
    ref="chart"
    :option="option"
    autoresize
    @click="handleClick"
    @legendselectchanged="handleLegendSelectChanged"
  />
</template>

<script>
import merge from 'deepmerge';
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';

import {
  CanvasRenderer,
} from 'echarts/renderers';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
]);

export default {
  name: 'EChart',
  components: {
    'v-chart': ECharts,
  },
  props: {
    // should be of form: [{name: 'name1', value:[] || Object}]
    series: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    defaultColors: {
      type: Array,
      default: () => [],
    },
    // if useBaseChart is true then only this data prop will be used to build the chart as it is
    // passed to v-chart option prop in above template. Due to that this component can also
    // be used to render any type of chart, without creating any chart specific child component
    // (ex. LineChart), instead of only acting as a template for other sub-component.
    data: {
      type: Object,
      default: () => {},
    },
    useBaseChart: {
      type: Boolean,
      default: false,
    },
    handlesMouseClick: {
      type: Boolean
    },
    // using customChartProp, the view components that use charts
    // can override a chart's properties depending on use cases
    customChartProp: {
      type: Object,
      default: () => {},
    },
    customToolBoxProps: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    option() {
      let optn = this.data;
      if (!this.useBaseChart) {
        optn = {
          // if we want to add more features to ECharts then
          // 1. add a data prop to optn here pointing to a computed prop as value
          // 2. add a default implementation for the computed prop. Ex.- titleData or tooltipData
          // 3. override the default implementation in child component.
          //    Ex. - checkout @component/echart/LineChat.vue
          title: this.titleData,
          tooltip: this.tooltipData,
          legend: this.legendData,
          grid: this.gridData,
          toolbox: this.toolboxData,
          series: this.seriesData,
        };
        if (this.defaultColors && this.defaultColors.length > 0) {
          optn.color = this.defaultColors;
        }
        // merge the chartSpecificProps into optn to override the fields
        optn = merge(optn, this.chartSpecificProps);
      }
      return optn;
    },
    // these are default implementations which can be overriden by child components
    tooltipData() { return {}; },
    legendData() { return {}; },
    gridData() { return {}; },
    toolboxData() {
      return this.customToolBoxProps || {
      show: 'true',
      feature: {
        saveAsImage: {
          show: true,
          title: 'save',
        },
      },
    }
    },
    seriesData() { return {}; },
    titleData() {
      return { text: this.title, subtext: this.subTitle };
    },
    chartSpecificProps() {
      return this.customChartProp || {};
    },
  },
  methods: {
    // these are default implementations which can be overriden by child components
    handleClick() { return {}; },
    handleLegendSelectChanged() { return {}; },
    dispatchAction() { return {}; },
  }
};
</script>
