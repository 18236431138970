import apis from "@/utils/apis";
import {sortBy} from '@/utils';
import { getDate, getNow, isWithinRangeInclusiveStart } from "@/utils/dates";

const fetchCommitment = async (id) => {
  const resp = await apis.commitments.find(id);
  return resp.data;
};

const findCycleForCommitment = (commitment, billingCycles, billingCycleId) => {
  if (billingCycleId && billingCycleId !== '') {
    return billingCycles.find(bc => bc.id === billingCycleId) || {};
  }

  const sortedCycles = billingCycles.sort(sortBy(c => c.startDate));
  
  const cyclesForCommitment = sortedCycles
    .filter(bc => bc.commitments.filter(c => c.commitment.id === commitment.id).length > 0);
  
  return cyclesForCommitment
    .find(cycle =>
      isWithinRangeInclusiveStart(getNow(), getDate(cycle.startDate), getDate(cycle.endDate))) ||
    sortedCycles[sortedCycles.length - 1];
};

const fetchCycles = async (organization) => {
  const resp = await apis.billable.getBillingCycles(organization.id, true);
  return resp.data;
};

const fetchPricing = async (cycle) => {
  const pricingPackage = await apis.pricingPackages.find(cycle.pricingPackages[0].packageId);
  const pricingDefinition = pricingPackage.data.pricingDefinition;
  const resp = await apis.pricings.getEffectivePricing(pricingDefinition.id, cycle.startDate);
  return resp.data;
};

export { fetchCommitment, fetchCycles, findCycleForCommitment, fetchPricing };
