import apis from '@/utils/apis';
import { FilterType } from '@/utils/filters';

import router from '@/router';

import { vuex as Home } from './Home';
import { vuex as Administration } from './Administration';
import { vuex as Services } from './Services';
import { vuex as Profile } from './Profile';
import { vuex as Activity } from './Activity';
import { vuex as SystemSection } from './System';

const OPEN_MENU = 'OPEN_MENU';
const CLOSE_MENU = 'CLOSE_MENU';
const OPEN_SECONDARY_NAV = 'OPEN_SECONDARY_NAV';
const CLOSE_SECONDARY_NAV = 'CLOSE_SECONDARY_NAV';
const ADD_ALL_NOTIFICATIONS = 'ADD_ALL_NOTIFICATIONS';
const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const ADD_SENSITIVE_NOTIFICATION_DATA = 'ADD_SENSITIVE_NOTIFICATION_DATA';
const SET_SERVICE_DESCRIPTORS = 'SET_SERVICE_DESCRIPTORS';
const ADD_PILL = 'ADD_PILL';
const REMOVE_PILL = 'REMOVE_PILL';
const REMOVE_PILLS_OF_TYPE = 'REMOVE_PILLS_OF_TYPE';
const REPLACE_PILL = 'REPLACE_PILL';
const SET_PILLS = 'SET_PILLS';
const RESET_FILTERS = 'RESET_FILTERS';

const menuOpenStorageKey = 'menuOpen';
const getMenuOpenFromStorage = () => {
  const fromStorage = localStorage.getItem(menuOpenStorageKey);
  if (fromStorage) {
    return JSON.parse(fromStorage);
  }
  return window.innerWidth >= 768;
};
const setMenuOpenInStorage = menuOpen => localStorage.setItem(menuOpenStorageKey, menuOpen);

const computedFilters = (filtersets) => {
  const filters = {};

  const collectFilters = (pills) => {
    const pillMap = {};
    pills.forEach((pill) => {
      const previousData = pillMap[pill.type];
      if (previousData instanceof Array) {
        pillMap[pill.type].push(pill.data || pill.value);
      } else if (previousData != null) {
        pillMap[pill.type] = [previousData, (pill.data || pill.value)];
      } else {
        pillMap[pill.type] = pill.data || pill.value;
      }
    });
    return pillMap;
  };

  Object.entries(filtersets).forEach(([k, v]) => {
    filters[k] = collectFilters(v);
  });

  return filters;
};

// filterDependencies
const filterDependencies = {
  [FilterType.ORG_SCOPE]: [FilterType.USER, FilterType.ENV],
  [FilterType.SERVICE]: [FilterType.ENV],
  [FilterType.USAGE_FOR]: [FilterType.SERVICE, FilterType.ENV],
};

const clearFilterDependencies = (commit, filterset, key) => {
  (filterDependencies[key] || []).forEach((link) => {
    commit(REMOVE_PILL, { filterset, key: link });
  });
};


// initial state
const intialState = {
  menuOpen: getMenuOpenFromStorage(),
  secondaryNavOpen: false,
  notifications: [],
  serviceDescriptors: {},
  pills: {
    activity: [],
    reporting: [],
    accounts: [],
    entity: [],
    org_report: [],
    customers_report: [],
    daily_cost_overview: [],
    invoice_report: [],
  },
};


// getters
const getters = {
  menuOpen: state => state.menuOpen,
  secondaryNavOpen: state => state.secondaryNavOpen,
  notifications: state => state.notifications,
  pendingNotificationsCount: state => state.notifications.filter(n => n.status === 'PENDING' || n.status === 'published').length,
  pendingActivityCount: state => state.notifications.filter(n => !n.sysNotif && (n.status === 'PENDING' || n.status === 'published')).length,
  pendingAnnouncementCount: state => state.notifications.filter(n => n.sysNotif && n.isNew && n.status === 'published').length,
  serviceDescriptors: state => state.serviceDescriptors,
  pills: state => state.pills,
  filters: state => computedFilters(state.pills),
};

// actions
const actions = {
  openMenu: ({ commit }) => commit(OPEN_MENU),
  closeMenu: ({ commit }) => commit(CLOSE_MENU),
  toggleMenu: ({ commit, state }) => {
    if (state.menuOpen) {
      commit(CLOSE_MENU);
    } else {
      commit(OPEN_MENU);
    }
  },
  openSecondaryNav: ({ commit }, query) => commit(OPEN_SECONDARY_NAV, query),
  closeSecondaryNav: ({ commit }) => commit(CLOSE_SECONDARY_NAV),
  loadNotifications: ({ commit, state }) => {
    if (state.notifications.length > 0) {
      return;
    }
    apis.activity.list({ qs: { only_mine: true, status: 'PENDING' } }).then((json) => {
      commit(ADD_ALL_NOTIFICATIONS, json.data);
    });
  },
  addNotification: ({ commit }, notification) => commit(ADD_NOTIFICATION, notification),
  closeNotification: ({ commit }, notificationId) => commit(CLOSE_NOTIFICATION, notificationId),
  updateNotification: ({ commit }, notification) => commit(UPDATE_NOTIFICATION, notification),
  addSensitiveNotificationData: ({ commit }, sensitiveData) =>
    commit(ADD_SENSITIVE_NOTIFICATION_DATA, sensitiveData),
  loadServiceDescriptors: ({ commit }) => {
    apis.serviceDescriptors.list().then((json) => {
      const descriptorsMap = json.data.reduce((memo, service) => {
        memo[service.type] = service;
        return memo;
      }, {});
      commit(SET_SERVICE_DESCRIPTORS, descriptorsMap);
    });
  },
  replacePill({ commit }, { filterset, pill }) {
    clearFilterDependencies(commit, filterset, pill.key);
    commit(REPLACE_PILL, { filterset, pill });
  },
  removePill({ commit }, { filterset, key }) {
    clearFilterDependencies(commit, filterset, key);
    commit(REMOVE_PILL, { filterset, key });
  },
  removePillsOfType({ commit }, { filterset, type }) {
    commit(REMOVE_PILLS_OF_TYPE, { filterset, type });
  },
  appendPill({ commit }, { filterset, pill }) {
    commit(ADD_PILL, { filterset, pill });
  },
  setPills({ commit }, { filterset, pills }) {
    commit(SET_PILLS, { filterset, pills });
  },
  resetFilters({ commit }) {
    commit(RESET_FILTERS);
  },
};

// mutations
const mutations = {
  [OPEN_MENU](state) {
    state.menuOpen = true;
    setMenuOpenInStorage(true);
  },
  [CLOSE_MENU](state) {
    state.menuOpen = false;
    setMenuOpenInStorage(false);
  },
  [OPEN_SECONDARY_NAV](state, query) {
    if (query) {
      router.push({ query: { ...router.currentRoute.value.query, ...query } });
    }
    state.secondaryNavOpen = true;
  },
  [CLOSE_SECONDARY_NAV](state) {
    state.secondaryNavOpen = false;
  },
  [ADD_ALL_NOTIFICATIONS](state, notifications) {
    state.notifications = [...state.notifications,
      ...notifications.filter(n => n.serviceConnectionId)];
  },
  [CLOSE_NOTIFICATION](state, notificationId) {
    state.notifications = state.notifications.filter(n => n.id !== notificationId);
  },
  [ADD_NOTIFICATION](state, notification) {
    state.notifications.unshift(notification);
  },
  [UPDATE_NOTIFICATION](state, notification) {
    const nIdx = state.notifications.findIndex(n => n.id === notification.id);
    if (nIdx >= 0) {
      const updatedNotif = Object.assign({}, state.notifications[nIdx], notification);
      state.notifications[nIdx] = updatedNotif;
    }
  },
  [ADD_SENSITIVE_NOTIFICATION_DATA](state, sensitiveData) {
    appendSensitiveNotifications(state.notifications, sensitiveData);
  },
  [SET_SERVICE_DESCRIPTORS](state, descriptors) {
    state.serviceDescriptors = descriptors;
  },
  [ADD_PILL](state, { filterset, pill }) {
    if (!state.pills[filterset]) {
      state.pills[filterset] = [];
    }
    state.pills[filterset] = [...state.pills[filterset], pill];
    state.pills = { ...state.pills }; // force reactiveness
  },
  [REMOVE_PILL](state, { filterset, key }) {
    if (state.pills[filterset]) {
      state.pills[filterset] = state.pills[filterset].filter(p => p.key !== key);
    }
  },
  [REMOVE_PILLS_OF_TYPE](state, { filterset, type }) {
    if (state.pills[filterset]) {
      state.pills[filterset] = state.pills[filterset].filter(c => c.type !== type);
    }
  },
  [REPLACE_PILL](state, { filterset, pill }) {
    if (!state.pills[filterset]) {
      state.pills[filterset] = [];
    }
    const toReplace = state.pills[filterset].findIndex(p => p.key === pill.key);
    if (toReplace !== -1) {
      state.pills[filterset][toReplace] = pill;
    } else {
      state.pills[filterset] = [...state.pills[filterset], pill];
    }
    state.pills = { ...state.pills };
  },
  [SET_PILLS](state, { filterset, pills }) {
    if (state.pills[filterset]) {
      state.pills[filterset].splice(0, state.pills[filterset].length + 1, ...pills);
    } else {
      state.pills[filterset] = [...pills];
    }
    state.pills = { ...state.pills };
    
  },
  [RESET_FILTERS](state) {
    Object.keys(state.pills).forEach(key => state.pills[key] = []);
  },
};

export const appendSensitiveNotifications = (notifications, sensitiveData) => {
  const nIdx = notifications.findIndex(n => n.correlationId === sensitiveData.correlationId);
  if (nIdx < 0) {
    return;
  }

  const notification = notifications[nIdx];

  // Initialize sensitiveData if not present
  if (!notification.sensitiveData) {
    notification.sensitiveData = [];
  }

  for (const data of sensitiveData.data) {
    // Check if the data already exists
    const existingValue = notification.sensitiveData.find(sd => (sd.label + sd.value) === data.label + data.value);

    // Add the data if it does not exist
    if (!existingValue) {
      notification.sensitiveData.push(data);
    }
  }
}

export default {
  state: intialState,
  getters,
  actions,
  mutations,
  modules: { Home, Administration, Services, Profile, Activity, SystemSection },
};
