<template>
  <div>
    <advanced-details 
      :loading="!loaded" 
      titleLabel="saml_settings.title"
    >
      <template #operations>
        <action-icon 
          v-show="!editMode" 
          icon="fa fa-edit"
          tooltipLabel="edit" 
          expandOnHover 
          clickable 
          @click="editMode = true"
        />
      </template>
      <div class="secondary-title">
        <h2>{{ $t('service_providers.entity_id') }}:</h2>
        <copyable-field :modelValue="entityId">
          {{ entityId }}
        </copyable-field>
        <div class="description">
          {{ $t('saml_settings.idp_id') }}
        </div>
      </div>
      <base-form 
        class="form" 
        submitLabel="save" 
        :hideSubmit="!editMode" 
        :executing="executing" 
        :hideCancel="!editMode" 
        :disabled="incompleteForm" 
        @submit="submit" 
        @cancel="cancel"
      >
        <form-row 
          label="saml_settings.certificate" 
          descriptionLabel="saml_settings.certificate_desc" 
          :error="errorCertificate"
        >
          <base-text-area 
            v-model="samlSettings.certificate" 
            :disabled="!editMode" 
            @focus="errors.certificate = null"
          />
        </form-row>
        <form-row 
          label="saml_settings.private_key" 
          descriptionLabel="saml_settings.private_key_desc" 
          :error="errorPrivateKey"
        >
          <base-text-area 
            v-model="samlSettings.privateKey" 
            :disabled="!editMode" 
            @focus="errors.privateKey = null"
          />
        </form-row>
        <confirm-modal
          v-if="leave"
          headerIcon="fa fa-check-circle"
          :open="confirmLeave"
          headerLabel="saml_settings.leave_confirmation_title"
          detailsLabel="saml_settings.leave_confirmation"
          confirmLabel="discard"
          @ok="leave"
          @cancel="confirmLeave = false"
        />
      </base-form>
    </advanced-details>
  </div>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { mapGetters } from 'vuex';

import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';

function newSamlSettings() {
  return {
    id: undefined,
    certificate: undefined,
    privateKey: undefined,
    organization: undefined,
  };
}

export default {
  name: 'SamlSettingsForm',
  mixins: [resellerOrgNavigationMixin],
  beforeRouteLeave(to, from, next) {
    if (!this.editMode) {
      next();
    } else {
      this.confirmLeave = true;
      this.leave = next;
    }
  },
  data() {
    return {
      samlSettings: {
        id: undefined,
        certificate: undefined,
        privateKey: undefined,
        organization: undefined,
      },
      errors: {},
      executing: false,
      editMode: false,
      loaded: false,
      leave: null,
      confirmLeave: false,
    };
  },
  computed: {
    ...mapGetters(['globalProperties', 'selectedOrganization']),
    entityId() {
      let host;
      if (this.samlSettings.organization && this.samlSettings.organization.customDomain) {
        host = this.samlSettings.organization.customDomain.domain;
      } else {
        host = this.globalProperties['public.host'];
      }
      return this.buildHost(host);
    },
    incompleteForm() {
      return !(this.samlSettings.certificate
            && this.samlSettings.privateKey);
    },
    errorCertificate() {
      return this.errors.certificate ? this.$t(`saml_settings.error_codes.${this.errors.certificate}`) : undefined;
    },
    errorPrivateKey() {
      return this.errors.privateKey ? this.$t(`saml_settings.error_codes.${this.errors.privateKey}`) : undefined;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.errors = {};
      this.loaded = false;
      await Promise.all([this.fetchOrganization(), this.fetchSamlSettings()]);
      this.loaded = true;
    },
    clear() {
      this.samlSettings = newSamlSettings();
    },
    async fetchSamlSettings() {
      const resp = await apis.samlSettings.listForOrg(this.selectedOrganization.id);
      if (!resp || resp.status !== 200) {
        this.samlSettings = newSamlSettings();
        notify.error(this.$t('saml_settings.error_fetch_settings'));
        return;
      }
      const fetchedSAMLSettings = Object.values(resp.data)[0];
      if (fetchedSAMLSettings) {
        this.samlSettings.id = fetchedSAMLSettings.id;
        this.samlSettings.certificate = fetchedSAMLSettings.certificate;
        this.samlSettings.privateKey = fetchedSAMLSettings.privateKey;
      }
    },
    async fetchOrganization() {
      const response = await apis.organizations.find(this.selectedOrganization.id);
      this.samlSettings.organization = response.data;
    },
    async submit() {
      this.executing = true;
      this.errors = {};
      let resp = null;
      if (!this.samlSettings.id) {
        resp = await apis.samlSettings.create(this.samlSettings);
      } else {
        resp = await apis.samlSettings.update(this.samlSettings.id, this.samlSettings);
      }
      if (resp.status === 200) {
        notify.success(this.$t('saml_settings.updated_saml_settings'));
        this.samlSettings.id = resp.data.id;
        this.editMode = false;
      } else {
        resp.errors
          .filter(value => value.ref)
          .filter(value => value.ref.scope)
          .forEach(value =>
            this.errors[value.ref.scope] = value.ref.code,
          );
        notify.error(this.$t('saml_settings.error_updating_saml_settings'));
      }
      this.executing = false;
    },
    cancel() {
      this.load();
      this.executing = false;
      this.editMode = false;
    },
    buildHost(host) {
      const portProperty = this.globalProperties['public.port'];
      const port = (portProperty && portProperty !== '80' && portProperty !== '443')
        ? `:${portProperty}`
        : '';
      return `${this.globalProperties.protocol}://${host}${port}`;
    },
  },
};
</script>
