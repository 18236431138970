<template>
  <base-select
    clearable
    :items="items"
    :icon="icon"
    :placeholder="placeholder"
    :size="size"
    @update:modelValue="input"
    @item="updateItem"
  />
</template>

<script>

export default {
  name: 'MultiSelectInput',
  props: {
    items: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Set,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'search',
    },
    icon: {
      type: String,
      default: 'fa fa-list',
    },
    size: {
      type: String,
      validator: v => ['sm', 'md'].includes(v),
    },
  },
  emits: ['update:modelValue', 'add', 'remove', 'addItem', 'removeItem'],
  data() {
    return {
    };
  },
  computed: {

  },
  methods: {
    input(choice) {
      // Update selection
      const selection = new Set(this.modelValue);
      if (selection.has(choice)) {
        selection.delete(choice);
        this.$emit('remove', choice);
      } else {
        selection.add(choice);
        this.$emit('add', choice);
      }

      this.$emit('update:modelValue', selection);
    },
    updateItem(item) {
      if (this.modelValue.has(item.value)) {
        this.$emit('removeItem', item);
      } else {
        this.$emit('addItem', item);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
