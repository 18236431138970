export type Size = 'none' | '4xs' | '3xs' | '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl';

export function getSizeInRem(size: Size, baseFontSize: number = 16): string {
  const sizes: Record<Size, number> = {
    none: 0,
    '4xs': 2,
    '3xs': 4,
    '2xs': 6,
    xs: 8,
    s: 12,
    m: 16,
    l: 20,
    xl: 24,
    '2xl': 32,
    '3xl': 48,
  };

  const sizeInPixels = sizes[size];
  const sizeInRem = sizeInPixels / baseFontSize;

  return `${sizeInRem}rem`;
}
