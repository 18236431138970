<template>
  <div>
    <div class="tags">
      <base-tag 
        v-for="tag in shownTags" 
        :key="tag.id" 
        :tag="tag.name" 
        :colorClass="tag.colorClass || 'green'" 
        :isHtml="tag.isHtml" 
        :sizeClass="tag.sizeClass"
      />
      <slot></slot>
      <action-text
        v-if="tagsRemaining"
        class="more-tags"
        :label="'+' + tagsRemaining"
        @click.stop.prevent="showMore"
      />
      <action-text
        v-else-if="showingMore"
        class="more-tags"
        label="<" 
        @click.stop.prevent="showLess"
      />
    </div>
  </div>
</template>
<script>
const MAX_INIT_TAGS = 2;
export default {
  name: 'BaseTags',
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    limitTags: {
      type: Boolean,
    },
  },
  data() {
    return {
      maxTags: MAX_INIT_TAGS,
      showingMore: false,
    };
  },
  computed: {
    shownTags() {
      const shownTags = this.tags.filter((u, idx) => idx < this.maxTags);
      return shownTags.length + 1 === this.tags.length ? this.tags : shownTags;
    },
    tagsRemaining() {
      return this.shownTags.length === this.tags.length
        ? 0 : Math.max(this.tags.length - this.maxTags, 0);
    },
  },
  methods: {
    showMore() {
      this.showingMore = true;
      this.maxTags = this.tags.length;
    },
    showLess() {
      this.showingMore = false;
      this.maxTags = MAX_INIT_TAGS;
    },
  },
};
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.more-tags {
  border: 1px var(--text) solid;
  border-radius: 5px;
  width: 20px;
  padding: 1.5px;
  text-align: center;
}
</style>
