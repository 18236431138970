<template>
  <base-list 
    :ariaLabel="ariaLabel" 
    :ariaRowCount="filteredDiscounts.length"
  >
    <list-header>
      <list-column :size="1">
        {{ $t('Select') }}
      </list-column>
      <list-column>{{ $t('type') }}</list-column>
      <list-column>{{ $t('name') }}</list-column>
      <list-column>{{ $t('monetization.discounts.discount_and_amount') }}</list-column>
      <list-column>{{ $t('status') }}</list-column>
      <list-column>{{ $t('monetization.offer_period') }}</list-column>
      <list-column>{{ $t('monetization.discounts.duration') }}</list-column>
    </list-header>
    <list-row 
      v-for="discount in filteredDiscounts"
      :key="discount.id" 
    >
      <list-column :size="1">
        <base-checkbox 
          class="small-cb" 
          :modelValue="isSelected(discount.id)" 
          @update:modelValue="emitDiscount(discount)" 
        />
      </list-column>
      <list-column>
        {{ typeLabel(discount) }}
      </list-column>
      <list-column>
        {{ discount.name[locale] }}
      </list-column>
      <list-column>
        <div v-if="discount.scope == 'ALL_PRODUCTS'">
          {{ $t('monetization.discounts.scopes.all_products') }}:
          <span 
            v-if="isCredit(discount)"
            class="discount-list-amount" 
          >{{ creditToCurrency(discount.packageDiscount) }}</span>
          <span 
            v-else
            class="discount-list-amount" 
          >{{ $t("monetization.discounts.percentage.number_with_percentage", {number: discount.packageDiscount}) }}</span>
        </div>
        <div v-else>
          <div 
            v-for="discountKey in previewDiscount(discount.discountedCategories)" 
            :key="discountKey"
          >
            {{ discount.discountedLabels[discountKey][locale] }}:
            <span 
              v-if="isCredit(discount)"
              class="discount-list-amount" 
            >{{ creditToCurrency(discount.discountedCategories[discountKey]) }}</span>
            <span 
              v-else
              class="discount-list-amount" 
            >{{ $t("monetization.discounts.percentage.number_with_percentage", {number: discount.discountedCategories[discountKey]}) }}</span>
            <br>
          </div>
          <div 
            v-for="discountKey in previewDiscount(discount.discountedProducts)" 
            :key="discountKey"
          >
            {{ discount.discountedLabels[discountKey][locale] }}:
            <span 
              v-if="isCredit(discount)"
              class="discount-list-amount" 
            >{{ creditToCurrency(discount.discountedProducts[discountKey]) }}</span>
            <span 
              v-else
              class="discount-list-amount" 
            >{{ $t("monetization.discounts.percentage.number_with_percentage", {number: discount.discountedProducts[discountKey]}) }}</span>
            <br>
          </div>
          <a 
            v-if="previewDiscountRemaining(discount.discountedProducts, discount.discountedCategories) > 0"
            :to="{ name: isCredit(discount) ? 'creditDetails': 'discountDetails', params: { discountId: discount.id } }"
          >
            {{ $t("monetization.discounts.preview_extra", {number: previewDiscountRemaining(discount.discountedProducts, discount.discountedCategories)}) }}
          </a>
        </div>
      </list-column>

      <list-column>
        <base-badge 
          :state="getStatus(discount).label" 
          :color="getStatus(discount).color" 
        />
      </list-column>

      <list-column>
        <span class="discount-list-timerange">
          {{ dateFormatting(discount.startDate) }} <base-icon icon="fa fa-caret-right" /> {{ discount.cutoffDate ? dateFormatting(discount.cutoffDate) : $t('monetization.discounts.no_expiry_date') }}
        </span>
      </list-column>

      <list-column>
        {{ durationFormatting(discount) }}
      </list-column>
    </list-row>
    <template #empty>
      <empty-message
        v-if="!filteredDiscounts.length"
        icon="fa fa-cube"
        textLabel="monetization.no_credits_and_discounts"
      ></empty-message>
    </template>
  </base-list>
</template>

<script>
import { diff } from '@/utils/dates';
import { mapGetters } from 'vuex';
import { currencyMixin } from '@/mixins/currencyMixin';
import { discountMixin } from '@/mixins/discountMixin';

export default {
  name: 'SelectableDiscountList',
  mixins: [currencyMixin, discountMixin],
  props: {
    ariaLabel: {
      type: String,
      required: true,
    },
    discounts: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedDiscounts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      previewLimit: 3,
    };
  },
  computed: {
    ...mapGetters(['locale', 'billingSettings']),
    search() {
      return this.getParameterValue(this.$route.query.q);
    },
    filteredDiscounts() {
      return this.discounts
        .filter(dc => !dc.isDeactivated)
        .sort((a, b) => this.sortByStatusAndDate(a, b));
    },
    currency() {
      return (this.billingSettings || {}).defaultCurrency;
    },
  },
  methods: {
    sortByStatusAndDate(a, b) {
      const status1 = this.getStatusLevel(a);
      const status2 = this.getStatusLevel(b);
      if (status1 < status2) {
        return -1;
      } else if (status1 > status2) {
        return 1;
      }
      return -1 * diff(a.startDate, b.startDate);
    },
    getStatusLevel(discount) {
      if (discount.isDeactivated) {
        return 4;
      } else if (discount.status === 'CURRENT') {
        return 1;
      } else if (discount.status === 'UPCOMING') {
        return 2;
      } else if (discount.status === 'ENDED') {
        return 3;
      }
      return 0;
    },
    getParameterValue(param) {
      return Array.isArray(param) ? param[0] : param;
    },
    creditToCurrency(amount) {
      return this.formatShortCurrency(amount || 0, this.currency);
    },
    previewDiscount(discountMap) {
      const discountArray = Object.keys(discountMap || {});
      return discountArray.slice(0, Math.min(this.previewLimit, discountArray.length));
    },
    previewDiscountRemaining(discountProducts, discountCategories) {
      const prodExcess = Math.max(Object.keys(discountProducts).length - this.previewLimit, 0);
      const catExcess = Math.max(Object.keys(discountCategories).length - this.previewLimit, 0);
      return Math.max(prodExcess, catExcess);
    },
    isSelected(discountId) {
      return this.selectedDiscounts.some(d => d.id === discountId);
    },
    emitDiscount(discount) {
      this.$emit('update:modelValue', discount);
    },
    isCredit(discount) {
      return discount.type === 'CREDIT';
    },
    typeLabel(discount) {
      return discount.type === 'PERCENTAGE' ?
        this.$t('monetization.discounts.percentage.name') :
        this.$t('monetization.discounts.credit.name');
    },
  },
};
</script>

<style scoped lang="scss">

.deprecated {
  display: flex;
  justify-content:flex-end;
  flex-direction: row;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  :deep(.label) {
    font-size: 14px;
    padding-right: 10px;
  }
}
.discount-list-timerange {
    .fa { display:inline; }
}
.discount-list-amount {
 font-weight: bold;
}

:deep(.base-checkbox) {
    align-content: center;
}
</style>
