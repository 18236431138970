import { CrudApi } from './api';

class ServiceScopesApi extends CrudApi {
  constructor() {
    super('service_scopes');
  }

  fetchAppliedServiceScope(orgId, serviceType, connectionId) {
    return this.get(`${this.path}/applied_service_scope`, 
      { 
        qs: {
          organization_id: orgId,
          service_type: serviceType,
          connection_id: connectionId
        }
     }
    );
  }
}

const serviceScopesApi = new ServiceScopesApi();
export default serviceScopesApi;