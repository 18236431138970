<template>
  <div>
    <router-view></router-view>
    <form-page 
      :title="!!id ? 'service_providers.edit': 'service_providers.create'"
      :disabled="!canSubmit"
      :defaultBack="{ name: 'serviceProvidersList' }"
      @submit="submit"
    >
      <form-row label="service_providers.name">
        <base-input v-model="serviceProvider.name" />
      </form-row>
      <form-row label="service_providers.provider_type">
        <base-select
          v-model="serviceProvider.type"
          :items="getProviders"
          :disabled="true"
        />
      </form-row>
      <div v-if="serviceProvider.type">
        <div class="main-title">
          <div>{{ getSectionName(serviceProvider.type) }}</div>
        </div>
        <div 
          v-for="(item,index) in providerTypeFields"
          :key="index" 
        >
          <div v-if="item.type === 'textfield'">
            <form-row 
              :label="$t(`service_providers.${item.name}`)" 
              :optional="item.optional"
            >
              <base-input
                v-model="serviceProvider.config[item.name]"
              />
            </form-row>
          </div>
          <div v-else-if="item.type === 'dropdown'">
            <form-row :label="$t(`service_providers.${item.name}`)">
              <base-select
                v-model="serviceProvider.config[item.name]"
                :items="getDropdownOptions(item.fieldType || item.name)"
                :placeholder="$t('service_providers.option_placeholder')"
              />
            </form-row>
          </div>
        </div>
        <div v-if="serviceProvider.type === 'SAML'">
          <div class="main-title ">
            <div>{{ $t('service_providers.attribute_mapping') }}</div>
          </div>
          <div 
            v-for="(item,index) in serviceProvider.config.responseAttributes" 
            :key="index"
            class="add-field-row" 
          >
            <form-row  
              class="flex-1" 
              :label="index === 0 ? 'service_providers.attribute_name' : null" 
            >
              <base-input 
                v-model="item.attributeName"
                class="attribute-field" 
              />
            </form-row>
            <form-row 
              class="flex-1" 
              :label="index === 0 ? 'service_providers.attribute_format' : null" 
            >
              <base-select 
                v-model="item.nameFormat" 
                class="attribute-field" 
                :items="getDropdownOptions('attribute_format')" 
                :placeholder="$t('service_providers.attribute_format_placeholder')"
              />
            </form-row>
            <form-row  
              class="flex-1" 
              :label="index === 0 ? 'service_providers.attribute_value' : null" 
            >
              <base-select 
                v-model="item.attributeValueField" 
                class="attribute-field" 
                :items="attributeValueOptions" 
                :placeholder="$t('service_providers.attribute_value_placeholder')" 
              />
            </form-row>
            <action-icon 
              :class="index === 0 ? 'delete-button-one' : 'delete-button'" 
              icon="fa fa-trash" 
              @click="removeResponseAttribute(index)"
            />
          </div>
        </div>
        <base-button 
          class="field-btn" 
          @click="addAttributeField" 
        >
          {{ $t('service_providers.add_field') }}
        </base-button>
      </div>
    </form-page>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import { mapGetters } from 'vuex';

const NAMEID_FORMAT_OPTIONS = ['UNSPECIFIED', 'EMAIL_ADDRESS', 'ENTITY', 'PERSISTENT', 'TRANSIENT'];
const SIGN_OPTIONS = ['ASSERTION', 'RESPONSE', 'ASSERTION_AND_RESPONSE'];

export default {
  name: 'ServiceproviderSection',
  mixins: [orgSwitchNavigationMixin],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      serviceProvider: {
        id: null,
        name: '',
        type: '',
        config: {
          nameIdField: 'user.id',
          nameIdFormat: 'UNSPECIFIED',
          responseAttributes: [],
        },
      },
      loading: true,
      providers: ['SAML'],
      attributeValueOptions: [],
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization', 'locale']),
    getProviders() {
      return Object.values(this.providers).map(provider =>
        ({ value: provider, label: `service_providers.${provider}` }));
    },
    providerTypeFields() {
      const typeFields = this.types[this.serviceProvider.type] || [];
      return typeFields;
    },
    canSubmit() {
      let canSubmit = this.serviceProvider.name && this.serviceProvider.type;
      if (this.serviceProvider.type === 'SAML') {
        canSubmit = canSubmit && (this.serviceProvider.name
            && this.serviceProvider.type
            && this.serviceProvider.config.serviceProviderIssuer
            && this.serviceProvider.config.assertionConsumerUrl
            && this.serviceProvider.config.sign);
        canSubmit = canSubmit && Object.values(this.serviceProvider.config.responseAttributes)
          .map(att => att.attributeName && att.nameFormat && att.attributeValueField)
          .reduce((a, b) => a && b, true);
      }
      return canSubmit;
    },
    types() {
      return {
        SAML: [{ name: 'serviceProviderIssuer', type: 'textfield' },
          { name: 'assertionConsumerUrl', type: 'textfield' },
          { name: 'nameIdField', type: 'dropdown' },
          { name: 'nameIdFormat', type: 'dropdown' },
          { name: 'nameQualifier', type: 'textfield', optional: true },
          { name: 'spNameQualifier', type: 'textfield', optional: true },
          { name: 'sign', type: 'dropdown' },
        ],
      };
    },
  },
  async created() {
    this.loading = true;
    await this.populateIdp();
    await this.attributeFieldOptions();
    this.loading = false;
  },
  methods: {
    removeResponseAttribute(index) {
      this.serviceProvider.config.responseAttributes.splice(index, 1);
    },
    getSectionName(type) {
      if (type === 'SAML') {
        return this.$t('service_providers.saml_configuration');
      }
      return '';
    },
    getOptions(fieldType) {
      if (fieldType === 'sign') {
        return SIGN_OPTIONS;
      } else if (fieldType === 'nameIdFormat') {
        return NAMEID_FORMAT_OPTIONS;
      } else if (fieldType === 'attribute_format') {
        return ['UNSPECIFIED', 'URI', 'BASIC'];
      } else if (fieldType === 'nameIdField' || fieldType === 'attribute_value') {
        return this.attributeValueOptions;
      }
      return [];
    },
    getDropdownOptions(fieldType) {
      const options = this.getOptions(fieldType);
      return Object.values(options).map((option) => {
        if (option instanceof Object) {
          return option;
        }
        return { value: option, label: `service_providers.${option}` };
      });
    },
    addAttributeField() {
      const defaultAttributes = {
        attributeName: '',
        nameFormat: '',
        attributeValueField: '',
      };
      this.serviceProvider.config.responseAttributes.push(defaultAttributes);
    },
    async attributeFieldOptions() {
      let options = [];
      const userValueOptions = await apis.serviceProviders.userFields();
      if (userValueOptions.status === 200 && userValueOptions.data) {
        Object.values(userValueOptions.data).forEach(option =>
          options.push({ value: `user.${option}`, label: `service_providers.saml_fields.user.${option}` }),
        );
      } else {
        notify.error(this.$t('unexpected_error'));
      }
      const orgValueOptions = await apis.serviceProviders.orgFields();
      if (orgValueOptions.status === 200 && orgValueOptions.data) {
        Object.values(orgValueOptions.data).forEach(option =>
          options.push({ value: `organization.${option}`, label: `service_providers.saml_fields.org.${option}` }),
        );
      } else {
        notify.error(this.$t('unexpected_error'));
      }
      options = options.concat(await this.getCustomFields());
      this.attributeValueOptions = options;
    },
    async getCustomFields() {
      const resp = await apis.customFields
        .list({ qs: { organization_id: this.selectedOrganization.id } });
      if (resp && resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return [];
      }
      return resp.data.map(cf => ({
        value: `${cf.type.toLowerCase()}.${cf.field}`,
        label: cf.nameTranslations[this.locale],
      }));
    },
    async submit() {
      let resp = {};
      const SP = this.buildSP();
      if (!this.id) {
        resp = await apis.serviceProviders.create(SP);
      } else {
        resp = await apis.serviceProviders.update(this.id, SP);
      }
      if (resp.status === 200) {
        const successLabel = this.id ? 'service_providers.sp_update_success' : 'service_providers.sp_add_success';
        notify.success(
          this.$t(successLabel, { name: this.serviceProvider.name }),
        );
        this.$router.push({ name: 'service-providers' });
      } else if (resp.errors) {
        notify.error(this.$t('unexpected_error'));
      }
    },
    buildResponseAttributes() {
      const responseAttributes = [];
      Object.values(this.serviceProvider.config.responseAttributes).forEach(attribute =>
        responseAttributes.push({
          attributeName: attribute.attributeName,
          nameFormat: attribute.nameFormat.toUpperCase(),
          attributeValueField: {
            fieldName: attribute.attributeValueField.split('.')[1],
            sourceModel: attribute.attributeValueField.split('.')[0],
          },
        }),
      );
      return responseAttributes;
    },
    buildSP() {
      const conf = { assertionConsumerUrl: this.serviceProvider.config.assertionConsumerUrl,
        serviceProviderIssuer: this.serviceProvider.config.serviceProviderIssuer,
        sign: this.serviceProvider.config.sign,
        nameIdField: {
          sourceModel: this.serviceProvider.config.nameIdField.split('.')[0],
          fieldName: this.serviceProvider.config.nameIdField.split('.')[1],
        },
        nameIdFormat: this.serviceProvider.config.nameIdFormat,
        nameQualifier: this.serviceProvider.config.nameQualifier,
        spNameQualifier: this.serviceProvider.config.spNameQualifier,
        responseAttributes: this.buildResponseAttributes(),
      };
      const SP = {
        type: this.serviceProvider.type,
        name: this.serviceProvider.name,
        config: conf,
        organization: {
          id: this.selectedOrganization.id,
        },
      };
      if (this.id) {
        SP.id = this.id;
      }
      return SP;
    },
    async populateIdp() {
      if (this.id) {
        const serviceProvider = await apis.serviceProviders.find(this.id);
        if (serviceProvider.status !== 200 || !serviceProvider.data) {
          notify.error(this.$t('unexpected_error'));
        } else {
          const nameIdField = serviceProvider.data.config.nameIdField;
          const nameIdFormat = serviceProvider.data.config.nameIdFormat;
          this.serviceProvider = {
            ...serviceProvider.data,
            config: {
              ...serviceProvider.data.config,
              nameIdField: nameIdField ? `${nameIdField.sourceModel}.${nameIdField.fieldName}` : 'user.id',
              nameIdFormat: nameIdFormat || 'UNSPECIFIED',
            },
          };
          this.populateResponseAttributes(serviceProvider.data);
        }
      }
    },
    populateResponseAttributes(serviceProvider) {
      if (serviceProvider.config.responseAttributes) {
        const attributes = serviceProvider.config.responseAttributes.map(att => ({
          nameFormat: att.nameFormat,
          attributeName: att.attributeName,
          attributeValueField: `${att.attributeValueField.sourceModel}.${att.attributeValueField.fieldName}`,
        }));
        this.serviceProvider.config.responseAttributes = attributes;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add-field-row {
  display:flex;
}

.field-btn {
  display: block;
}

.attribute-field {
  margin-right:1em;
}
.delete-button{
  margin-top: 0.5em;
}
.delete-button-one{
  margin-top: 2.5em;
}
.main-title {
  max-width: 600px;
  padding-top: 1em;
}
</style>
