import { CrudApi } from './api';

class IdentityProviderApi extends CrudApi {
  constructor() {
    super('/identity_providers');
  }
  types() {
    return this.get('identity_providers/types', {});
  }
  defaultProviders() {
    return this.get('identity_providers/default_providers', {});
  }
  getNewUserConfig(providerId) {
    return this.get(`identity_providers/${providerId}/newUserConfig`)
  }
  updateNewUserConfig(providerId, config) {
    return this.post(`identity_providers/${providerId}/newUserConfig`, { body: { ...config } })
  }
}

const identityProviderApi = new IdentityProviderApi();
export default identityProviderApi;
