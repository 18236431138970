import { currencyMixin } from '@/mixins/currencyMixin';

export const adjustmentMixin = {
  mixins: [currencyMixin],
  data() {
    return {
      // used as id of invoice detail since it is null
      ALL_PRODUCTS: 'ALL_PRODUCTS',
      Type: {
        PERCENTAGE: 'PERCENTAGE',
        CREDIT: 'CREDIT',
        TAX: 'TAX',
      },
    };
  },
  methods: {
    /**
     * Returns the percentage discount for the given item id
     * @param {Object} discount the discount model
     * @param {String} itemId   the item id
     * @returns formatted percentage discount
     */
    getDiscountPercentage(discount, itemId) {
      const scope = this.getscope(discount);
      const getPercentage = {
        ALL_PRODUCTS() {
          return discount.packageDiscount;
        },
        CATEGORIES() {
          return (discount.discountedCategories || {})[itemId];
        },
        PRODUCTS() {
          return (discount.discountedProducts || {})[itemId];
        },
      };
      return scope ? `${getPercentage[scope]()}%` : null;
    },
    /**
     * Returns the item id of the invoice item.
     * The item id for the invoice detail is null, so 'ALL_PRODUCTS' is used in the UI.
     * @param {Object} invoiceItem the invoice item
     * @returns item id
     */
    getItemId(invoiceItem) {
      return invoiceItem.productId || invoiceItem.categoryId || this.ALL_PRODUCTS;
    },
    /**
     * Returns the formatted amount applied by a given discount
     * @param {Object}  discount              the discount model
     * @param {Array}   discountAdjustments   the discounts adjustments
     * @param {String}  currency              the currency
     * @returns discounted amount
     */
    getDiscountAmount(discount, discountAdjustments, currency) {
      const amount = discountAdjustments
        .find(adjustment => adjustment.source.discount.id === discount.id).amount;
      return this.formatShortCurrency(amount, currency);
    },
    /**
     * Returns true if any discounts of given type have been
     * applied to the invoice item on the item's scope after tax.
     *
     * @param {Object} invoiceItem  the invoice item
     * @param {String} type         the type of discount
     * @returns true if discounts of type applied
     */
    hasAdjustments(invoiceItem, type) {
      return invoiceItem.adjustments
        && invoiceItem.adjustments.some(d => d.type === type);
    },
    /**
     * Returns true if any discounts of given type have been
     * applied to the invoice item on the item's scope before taxes.
     *
     * @param {Object} invoiceItem  the invoice item
     * @param {String} type         the type of discount
     * @returns true if discounts of type applied
     */
    hasPreTaxAdjustments(invoiceItem, type) {
      return invoiceItem.preTaxAdjustments
        && invoiceItem.preTaxAdjustments.some(d => d.type === type);
    },
    /**
     * Returns true if any discounts of given type have been applied
     * to the invoice item regardless of the scope after taxes.
     *
     * @param {Object} invoiceItem  the invoice item
     * @param {String} type         the type of discount
     * @returns true if discounts of type applied
     */
    hasAggregatedAdjustments(invoiceItem, type) {
      return invoiceItem.adjustmentAggregations
        && invoiceItem.adjustmentAggregations.some(d => d.type === type);
    },
    /**
     * Returns true if any discounts of given type have been applied
     * to the invoice item regardless of the scope before taxes.
     *
     * @param {Object} invoiceItem  the invoice item
     * @param {String} type         the type of discount
     * @returns true if discounts of type applied
     */
    hasPreTaxAggregatedAdjustments(invoiceItem, type) {
      return invoiceItem.preTaxAdjustmentAggregations
        && invoiceItem.preTaxAdjustmentAggregations.some(d => d.type === type);
    },
    /**
     * Returns adjustments of a given type applied to an invoice item after tax.
     * @param {Object} invoiceItem  the invoice item
     * @param {String} type         the type of discount
     * @returns discounts
     */
    getAdjustments(invoiceItem, type) {
      return (invoiceItem.adjustments || []).filter(d => d.type === type);
    },
    /**
     * Returns true if discounts of given type have been applied to the invoice detail after taxes.
     * @param {String} type          the type of discount
     * @returns true if discounts of type have been applied to the invoice item
     */
    hasAfterTaxAdjustments(type) {
      const adjustmentAggregations = this.invoiceDetail.adjustmentAggregations;
      return adjustmentAggregations && adjustmentAggregations.some(agg => type === agg.type);
    },
    /**
     * Returns the discount scope.
     * While it should not happen, if 'scope' is undefined
     * the scope will be inferred from the discount definition.
     * @param {Object} discount the discount
     * @returns the discount scope
     */
    getscope(discount) {
      const scope = discount.scope;
      if (scope) {
        return scope;
      }
      if (discount.packageDiscount) {
        return 'ALL_PRODUCTS';
      }
      if (Object.keys(discount.discountedCategories || {}).length > 0) {
        return 'CATEGORIES';
      }
      if (Object.keys(discount.discountedProducts || {}).length > 0) {
        return 'PRODUCTS';
      }
      return null;
    },
  },
};
