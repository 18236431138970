<template>
  <div>
    <div class="tile-holder">
      <div 
        class="tile tile-border" 
        :class="[hoverClass, { active }]"
      >
        <div class="icon-container">
          <div class="tile-name">
            <slot name="name"></slot>
          </div>
          <slot name="rhs"></slot>
        </div>
        <div class="tile-image">
          <slot name="image"></slot>
        </div>
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTile',
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
    },
  },
  computed: {
    hoverClass() {
      return this.clickable ? 'tile-hover' : '';
    },
  },
};
</script>

<style scoped lang="scss">

  .tile-holder {
    padding-left: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tile:not(:last-child) {
      margin-right: 15px;
    }
  }

  .tile {

    width: 210px;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 7px;
    padding-right: 7px;
    padding-left: 7px;
    margin-top: 10px;
    flex-direction: column;

    transition: transform 0.5s;
    transform: scale(0.98);

    &.add {
      border-style: dashed;
      border-width: 2px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .fa-plus {
          font-size: 85px;
          opacity:0.8;
        }
      }
    }

    img {
      max-width: 150px;
      max-height: 150px;
      width: auto;
      height: auto;
      opacity: 0.8;
    }
  }

  /* MC-8154 roll back CSS temporarily */
  .tile-hover {
    cursor: pointer;
    &:hover {
      transform: scale(1);
    }
    &:not(:hover):not(.active) {
      .tile-image {
        transform: scale(0.95);
      }
      &.gray-border, &.grey-border {
        .tile-image {
          filter: grayscale(100%);
          opacity: 0.5;
          transform: scale(0.95);
        }
      }
    }
  }

  .tile-hover.active {
    transform: none;
    z-index: 10;
  }

  .tile-hover:hover {
    transform: none;
    z-index: 9;
  }

  .tile-name {
    flex: 1;
    text-align: left;
    font-size: 16px;
    overflow: hidden;
  }

  .tile-image {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    justify-content: center;
    user-select: none;
    width: 100%;
    transform: scale(1);
    transition: transform 0.5s, filter 0.5s;

    .fa.fa-book {
      font-size: 70px;
    }
  }

  .icon-container {
    height: 25px;
    padding-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    em {
      font-size: 20px;
    }
  }

</style>
