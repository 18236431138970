<template>
  <confirm-modal 
    :open="open" 
    :headerLabel="headerLabel" 
    :disabled="!source"
    @ok="execute" 
    @close="$emit('close')" 
    @cancel="$emit('close')" 
  >
    <base-input v-model="source"></base-input>
    <div class="description">
      {{ $t('admin_content.unauthenticated_link') }}
    </div>
  </confirm-modal>
</template>

<script>
import apis from '@/utils/apis';
import { mapGetters } from 'vuex';

export default {
  name: 'KnowledgeBaseForm',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    defaultSource: {
      type: String,
      default: 'https://github.com/cloudops/cloudmc-kb',
    },
    kb: {
      type: Object,
    },
  },
  emits: ['close', 'executing'],
  data() {
    return {
      sourceInput: null,
    };
  },
  computed: {
    source: {
      get() {
        return this.sourceInput || this.defaultSource;
      },
      set(value) {
        this.sourceInput = value;
      },
    },
    ...mapGetters([
      'selectedOrganization',
    ]),
    headerLabel() {
      return this.kb ? 'admin_content.update_kb' : 'admin_content.create_kb';
    },
  },
  methods: {
    async doCreate() {
      await apis.knowledgeBase.create({
        organizationId: this.selectedOrganization.id,
        sources: [
          {
            url: this.source,
          },
        ],
      });
    },
    async doUpdate() {
      await apis.knowledgeBase.update(this.kb.id, {
        ...this.kb,
        sources: [
          {
            url: this.source,
          },
        ],
      });
    },
    async execute() {
      if (this.kb) {
        await this.doUpdate();
      } else {
        await this.doCreate();
      }
      this.$emit('executing');
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">

.modal-container .description {
  margin-bottom: 30px;
  margin-top: 5px;
}
</style>
