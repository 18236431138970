<template>
  <form-page 
    :disabled="!!Object.keys(errors).length" 
    @submit="submit" 
    @cancel="$emit('cancel')"
  >
    <list-header>
      {{ $t('denied_domains') }}
    </list-header>
    <list-row>
      <list-column>
        <div class="inline">
          <base-checkbox v-model="deniedDomainsEnabled" />
          <base-input 
            v-model="domain" 
            placeholder="example.com"
            style="width: 300px" 
            :disabled="!deniedDomainsEnabled" 
          />
        </div>
      </list-column>
    </list-row>
    <list-header>
      {{ $t('password_complexity_policy') }}
    </list-header>
    <list-header noActions>
      <list-column :size="0.5">
        {{ $t('password_apply') }}
      </list-column>
      <list-column :size="2">
        {{ $t('password_validation_rule') }}
      </list-column>
      <list-column :size="1.5">
        {{ $t('password_value') }}
      </list-column>
    </list-header>
    <list-row 
      v-for="rule in localConstraintCopy" 
      :key="rule.name" 
      :class="{'lrow-padding' : errors[rule.name]}" 
      noActions 
      noBorder
    >
      <list-column 
        :size="0.5" 
        class="centre-aligned"
      >
        <base-checkbox 
          v-model="rule.isMandatory" 
          :disabled="isMinPassLengthRule(rule)" 
        ></base-checkbox>
      </list-column>
      <list-column 
        :size="2" 
        class="centre-aligned" 
      >
        {{ $t(rule.name) }}
      </list-column>
      <list-column :size="1.5">
        <input 
          type="number" 
          :value="rule.value" 
          class="input"
          @input="handleUpdate(rule, $event)" 
        />
        <div 
          v-if="errors[rule.name]" 
          class="error red error-margin"
        >
          {{ errors[rule.name] }}&nbsp;
        </div>
      </list-column>
    </list-row>
  </form-page>
</template>

<script>
import BaseCheckbox from '@/components/BaseCheckbox';
import ListHeader from '@/components/list/ListHeader';
import BaseInput from '@/components/input/BaseInput';

const MIN_PASSWORD_LENGTH_ID = 'min_password_length';
const PASSWORD_LENGTH_LOWER_BOUND = 'passwordLengthLowerBound';
const PASSWORD_LENGTH_UPPER_BOUND = 'passwordLengthUpperBound';
const OTHER_CONSTRAINT_LOWER_BOUND = 'otherConstraintLowerBound';
const OTHER_CONSTRAINT_UPPER_BOUND = 'otherConstraintUpperBound';

export default {
  name: 'NativeConfiguration',
  components: {
    ListHeader,
    BaseCheckbox,
    BaseInput,
  },
  props: {
    passwordPolicy: {
      required: true,
      type: Object,
    },
    blockedNativeLoginDomain: {
      type: String,
    },
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      localConstraintCopy: [],
      passwordLengthLowerBound: this.passwordPolicy[PASSWORD_LENGTH_LOWER_BOUND],
      passwordLengthUpperBound: this.passwordPolicy[PASSWORD_LENGTH_UPPER_BOUND],
      otherConstraintLowerBound: this.passwordPolicy[OTHER_CONSTRAINT_LOWER_BOUND],
      otherConstraintUpperBound: this.passwordPolicy[OTHER_CONSTRAINT_UPPER_BOUND],
      domain: null,
      deniedDomainsEnabled: false,
    };
  },
  computed: {
    errors() {
      const errors = {};
      this.localConstraintCopy.forEach((c) => {
        if (c.isMandatory) {
          if (c.name === MIN_PASSWORD_LENGTH_ID) {
            if ((c.value < this.passwordLengthLowerBound ||
                  c.value > this.passwordLengthUpperBound)) {
              errors[c.name] = this.$t('value_outside_range_error', { min: this.passwordLengthLowerBound, max: this.passwordLengthUpperBound });
            }
          } else if (c.value < this.otherConstraintLowerBound ||
                      c.value > this.otherConstraintUpperBound) {
            errors[c.name] = this.$t('value_outside_range_error', { min: this.otherConstraintLowerBound, max: this.otherConstraintUpperBound });
          }
        }
      });
      return errors;
    },
  },
  watch: {
    deniedDomainsEnabled() {
      if (!this.deniedDomainsEnabled) {
        this.domain = undefined;
      }
    },
  },
  created() {
    this.localConstraintCopy = [...this.passwordPolicy.constraints];
    this.domain = this.blockedNativeLoginDomain;
    this.deniedDomainsEnabled = !!this.domain;
  },
  methods: {
    isMinPassLengthRule(rule) {
      return rule.name === 'min_password_length';
    },
    handleUpdate(constraint, event) {
      const index = this.localConstraintCopy.findIndex(el => el.name === constraint.name);
      this.localConstraintCopy[index].value = parseInt(event.target.value.trim(), 10);
      this.localConstraintCopy[index].isMandatory = true;
    },
    submit() {
      if (!this.deniedDomainsEnabled || !this.domain) {
        this.domain = undefined;
      }
      this.$emit('submit', { updatedBlockedNativeLoginDomain: this.domain, updatedConstraints: this.localConstraintCopy });
    },
  },
};
</script>
<style lang="scss" scoped>

.centre-aligned {
  align-self: center;
}

.error-margin {
  margin-top: 43px;
  position: absolute;
}

.input {
  width: 100px;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  transition: border-color 1s;
  box-sizing: border-box;
}

.inline {
  display: flex;
}

.lrow-padding{
  padding-bottom: 45px;
}
</style>
