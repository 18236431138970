<template>
  <div>
    <base-loader v-if="loading"></base-loader>
    <form-page 
      v-else 
      title="feedback_and_support.label" 
      submitLabel="save" 
      :executing="executing" 
      @cancel="cancel" 
      @submit="submit"
    >
      <form-row 
        label="feedback_and_support.feedback_slack_webhook" 
        descriptionLabel="feedback_and_support.feedback_slack_webhook_description" 
        :error="$t(errors.slackWebhookUrl)"
      >
        <base-input v-model="settings.feedbackSlackWebhookUrl" />
      </form-row>
      <form-row 
        label="feedback_and_support.feedback_email" 
        descriptionLabel="feedback_and_support.feedback_email_description" 
        :error="$t(errors.feedbackEmail)"
      >
        <base-input v-model="settings.feedbackEmail" />
      </form-row>
      <form-row 
        v-if="support" 
        label="feedback_and_support.support_platform"
      >
        <radio-group 
          :options="supportOptions" 
          name="supportOption" 
          :modelValue="support" 
          @update:modelValue="changePlatform"
        />
      </form-row>
      <div v-if="support === 'email'">
        <form-row 
          label="feedback_and_support.support_email" 
          descriptionLabel="feedback_and_support.support_email_description" 
          :error="$t(errors.supportEmail)"
        >
          <base-input v-model="settings.supportEmail" />
        </form-row>
      </div>
      <div v-else>
        <form-row 
          label="feedback_and_support.zendesk_widget_key" 
          descriptionLabel="feedback_and_support.zendesk_widget_key_description"
        >
          <base-input v-model="settings.zendeskWidgetKey" />
        </form-row>
        <form-row 
          label="feedback_and_support.window_zesettings" 
          :error="$t(errors.widgetZesettings)" 
          optional
        >
          <span class="description">{{ $t("feedback_and_support.window_zesettings_description") }} </span>
          <a 
            target="_blank" 
            class="description"
            rel="noopener"
            href="https://developer.zendesk.com/embeddables/docs/widget/settings"
          >
            {{ $t("feedback_and_support.window_zesettings_description_instructions") }}
          </a>
          <code-editor 
            v-model="settings.widgetZesettings" 
            language="JAVASCRIPT"
          />
        </form-row>
      </div>
      <form-row 
        label="feedback_and_support.help_center_url" 
        descriptionLabel="feedback_and_support.help_center_url_desc"
      >
        <base-input v-model="settings.helpCenterUrl" />
      </form-row>
      <form-row 
        label="feedback_and_support.matomo_host" 
        descriptionLabel="feedback_and_support.matomo_host_desc" 
        :error="$t(errors.matomoHost)"
      >
        <base-input v-model="settings.matomoHost" />
      </form-row>
      <form-row 
        label="feedback_and_support.matomo_website_id" 
        descriptionLabel="feedback_and_support.matomo_website_id_desc" 
        :error="$t(errors.matomoWebsiteId)"
      >
        <base-input v-model="settings.matomoWebsiteId" />
      </form-row>
      <form-row 
        label="feedback_and_support.google_tag_manager_container_id" 
        descriptionLabel="feedback_and_support.google_tag_manager_container_id_desc" 
        :error="$t(errors.googleTagManagerContainerId)"
      >
        <base-input v-model="settings.googleTagManagerContainerId" />
      </form-row>
    </form-page>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import apis from '@/utils/apis';
import validators from '@/utils/validators';
import notify from '@/utils/notify';
import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';

export default {
  name: 'FeedbackSupportSettings',
  mixins: [resellerOrgNavigationMixin, orgSwitchNavigationMixin],
  data() {
    return {
      executing: false,
      loading: false,
      supportOptions: [
        { name: 'email', value: 'email', is18n: true },
        { name: 'feedback_and_support.zendesk_widget', value: 'widget', is18n: true },
      ],
      support: undefined,
      settings: {
        id: undefined,
        organization: {
          id: undefined,
        },
        feedbackSlackWebhookUrl: undefined,
        feedbackEmail: undefined,
        supportEmail: undefined,
        zendeskWidgetKey: undefined,
        widgetZesettings: undefined,
        helpCenterUrl: undefined,
        matomoHost: undefined,
        matomoWebsiteId: undefined,
        googleTagManagerContainerId: undefined,
      },
      errors: {
        feedbackEmail: null,
        supportEmail: null,
        slackWebhookUrl: null,
        widgetZesettings: null,
        matomoHost: null,
        matomoWebsiteId: null,
        googleTagManagerContainerId: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
  },
  async created() {
    this.loading = true;
    const resp = await apis.feedbackSettings
      .getOrganizationFeedbackSettings(this.selectedOrganization.id);
    if (resp.ok && resp.data) {
      this.settings = resp.data;
      if (this.settings.zendeskWidgetKey && this.settings.zendeskWidgetKey !== '') {
        this.support = 'widget';
      } else {
        this.support = 'email';
      }
    } else {
      this.settings.organization.id = this.selectedOrganization.id;
      this.support = 'email';
    }
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'loadFeedbackSettings',
    ]),
    changePlatform(type) {
      this.support = type;
    },
    removeOtherPlatformValues() {
      if (this.support === 'email') {
        this.settings.zendeskWidgetKey = undefined;
        this.settings.widgetZesettings = undefined;
      } else {
        this.settings.supportEmail = undefined;
      }
    },
    isValidFeedbackEmail() {
      if (this.settings.feedbackEmail) {
        if (!validators.validEmail(this.settings.feedbackEmail)) {
          this.errors.feedbackEmail = 'invalid_email_address';
          return false;
        }
      }
      return true;
    },
    isValidSupportEmail() {
      if (this.settings.supportEmail) {
        if (!validators.validEmail(this.settings.supportEmail)) {
          this.errors.supportEmail = 'invalid_email_address';
          return false;
        }
      }
      return true;
    },
    isValidSlackWebhookUrl() {
      if (this.settings.feedbackSlackWebhookUrl) {
        if (!this.settings.feedbackSlackWebhookUrl.startsWith('https://hooks.slack.com/services/')) {
          this.errors.slackWebhookUrl = 'invalid_slack_webhook_url';
          return false;
        }
      }
      return true;
    },
    isValidMotomoConfig() {
      if (this.settings.matomoHost || this.settings.matomoWebsiteId) {
        if (!this.settings.matomoHost) {
          this.errors.matomoHost = 'feedback_and_support.matomo_host_required';
          this.errors.matomoWebsiteId = null;
          return false;
        } else if (!this.settings.matomoWebsiteId) {
          this.errors.matomoWebsiteId = 'feedback_and_support.matomo_website_id_required';
          this.errors.matomoHost = null;
          return false;
        }
      }
      return true;
    },
    isValidInputs() {
      const validFeedbackEmail = this.isValidFeedbackEmail();
      const validSupportEmail = this.isValidSupportEmail();
      const validUrl = this.isValidSlackWebhookUrl();
      const validMotomoConfig = this.isValidMotomoConfig();
      if (validFeedbackEmail) {
        this.errors.feedbackEmail = null;
      }
      if (validSupportEmail) {
        this.errors.supportEmail = null;
      }
      if (validUrl) {
        this.errors.slackWebhookUrl = null;
      }
      if (validMotomoConfig) {
        this.errors.matomoHost = null;
        this.errors.matomoWebsiteId = null;
      }
      return (validFeedbackEmail && validUrl && validSupportEmail && validMotomoConfig);
    },
    async submit() {
      if (!this.isValidInputs()) {
        return;
      }
      this.removeOtherPlatformValues();
      this.executing = true;
      let resp;
      if (this.settings.id) {
        resp = await apis.feedbackSettings
          .updateOrganizationFeedbackSettings(this.settings.id, this.settings);
      } else {
        resp = await apis.feedbackSettings
          .createOrganizationFeedbackSettings(this.settings);
      }
      if (resp.ok) {
        notify.success(this.$t('feedback_and_support.updated_support_configuration'));
        this.loadFeedbackSettings();
        this.$router.push({ name: 'feedbackSupportView', params: { recentChange: true } });
      } else {
        Object.values(resp.errors)
          .filter(err => err.context && err.context.field)
          .forEach((err) => {
            this.errors[err.context.field] = err.context.labelKey;
          });
        notify.error(this.$t('feedback_and_support.update_support_configuration_failed'));
      }
      this.executing = false;
    },
    async cancel() {
      this.$router.push({ name: 'feedbackSupportView' });
    },
  },
};
</script>
