<template>
  <main class="login page">
    <div class="top logo">
      <app-logo
        class="app-logo"
        :src="`/rest/appearance/logo`"
      />
    </div>
    <LoginBox
      v-if="!loading"
      :orgName="orgName"
      :orgSupportsPasswordReset="orgSupportsPasswordReset"
      :orgEntryPoint="orgEntryPoint"
      :initialMode="initialMode"
      :customerOnboarding="customerOnboarding"
      :idps="idps"
    />
    <ContactUs 
      :contact="contactUs"       
      :loginPage="true"
    />
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import apis from '@/utils/apis';
import AppLogo from '@/components/AppLogo';
import LoginBox from './components/LoginBox';
import ContactUs from './components/ContactUs';

/**
  Need because you don't have access to the Vue component in before route enter.
 */
let orgInfo;
let orgName;
let orgSupportsPasswordReset;
let orgEntryPoint;
let customerOnboarding;
let validToken;
let idps;
let contactUs = {
  email: '',
  phone: '',
};

export default {
  name: 'LoginPage',
  components: { LoginBox, AppLogo, ContactUs },
  async beforeRouteEnter(to, from, next) {
    orgInfo = await apis.hidden.getOrganizationInfo();
    if (orgInfo.id) {
      orgName = orgInfo.name;
      orgSupportsPasswordReset = orgInfo.isDbAuth;
      orgEntryPoint = orgInfo.entryPoint;
      // if billing mode is credit card, link to customer onboarding instead of trial
      customerOnboarding = (orgInfo.billingMode === 'CREDIT_CARD');
    }
    idps = orgInfo.idps;
    contactUs.email = orgInfo.contactUsEmail;
    contactUs.phone = orgInfo.contactUsPhone;
    next();
  },
  data() {
    return {
      loading: true,
      orgName,
      orgSupportsPasswordReset,
      orgEntryPoint,
      customerOnboarding,
      validToken,
      idps,
      contactUs,
    };
  },
  computed: {
    initialMode() {
      if (this.$route.query.resetToken) {
        return this.validToken ? 'resetPassword' : 'tokenExpired';
      }
      if (this.$route.query.trialToken) {
        return 'setPassword';
      }
      if (this.$route.query.registrationToken) {
        return this.validToken ? 'setEnterprisePassword' : 'tokenExpired';
      }
      return 'passwordLogin';
    },
  },
  async created() {
    await this.changeOrganization(orgInfo);
    if (this.$route.query.resetToken) {
      const res = await apis.users.getIsResetTokenValid(this.$route.query.resetToken);
      const status = res.status;
      this.validToken = (status === 200);
    } else if (this.$route.query.registrationToken) {
      const res = await apis.enterpriseRegistrations.validateRegistrationToken(this.$route.query.registrationToken);
      this.validToken = (res && res.ok);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'changeOrganization',
    ]),
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins';
@import '@/styles/animations';

.page {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width:100%;
  height:100%;
  overflow-y: auto;
  animation: fadein .5s;
}

.top {
  height: 160px;
  img.app-logo {
    max-height: 100px;
    max-width: 250px;
    margin-top: 50px;
    margin-bottom: 50px;

    @include phone {
      margin-bottom: 45px;
      margin-top: 20px;
    }
  }
}
.footer {
  display:flex;
  align-items: flex-end;
  padding: 20px;
  font-size: 14px;

  .links {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    a {
      color: inherit;
      text-decoration:none;
      margin-left: 8px;
      margin-right:8px;
    }

    @include phone {
      flex-direction: column;
      a {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      span {
        display: none;
      }
    }
  }

  @include phone {
    min-height: 150px;
  }

  @include not-phone {
    flex: 1;
  }

  .social-container {
    margin-bottom:10px;
    .social {
      display: inline-block;
      border-radius: 5px;
      > div {
        width:22px;
        height:22px;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      &:not(:first-child) {
        margin-left:5px;
      }
    }
  }
}

:deep(.base-form button) {
  width: 70%;
}

:deep(.base-form .buttons-container) {
  align-items: center;
}

.contact-links {
  display: flex;
  padding-bottom: 20px;
  font-size: 14px;
  gap: 20px;
}

</style>
