import { CrudApi } from './api';

class ServiceDescriptorsApi extends CrudApi {
  constructor() {
    super('services/descriptors');
  }

  findServices(serviceType, orgId, entityType) {
    return this.get(`${this.path}/${serviceType}/entities/${entityType}`, { qs: { org_id: orgId } });
  }

  getService(serviceType) {
    return this.get(`/services/descriptors/${serviceType}`);
  }

  parameters(serviceType, partnerEnabled = false, connectionId = '') {
    return this.get(`/services/descriptors/${serviceType}/parameters`, { qs: { partnerEnabled, connectionId } });
  }

  reloadParameters(serviceType, partnerEnabled = false, connection) {
    return this.post(`/services/descriptors/${serviceType}/reload_parameters`, { body: connection, qs: { partnerEnabled } });
  }

  infraEntities(serviceType) {
    return this.get(`/services/descriptors/${serviceType}/infra/entities`);
  }
}

const serviceDescriptorsApi = new ServiceDescriptorsApi();

export default serviceDescriptorsApi;
