<template>
  <system-activity-listener 
    :codes="codes" 
    @receive="receivedBrandingEvent"
  />
</template>
<script>
import SystemActivityListener from '@/events/SystemActivityListener';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { mapGetters, mapActions } from 'vuex';
import { DEFAULT_DATE_FORMAT, format, getNow } from '@/utils/dates';

export default {
  name: 'BrandingListener',
  components: { SystemActivityListener },
  data() {
    return {
      orgCustomStyle: null,
      orgCustomColors: null,
      faviconHtml: null,
      branding: null,
      orgBrandingId: (this.myOrganization || {}).id,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'myOrganization',
      'orgBrandingInfo',
    ]),
    codes() {
      return [
        'brandings.updated',
        'brandings.created',
        'brandings.deleted',
        'branding_artifacts.updated',
      ];
    },
  },
  watch: {
    selectedOrganization(newOrg) {
      const newOrgEntrypoint = newOrg ? newOrg.entryPoint : '';
      this.loadOrgBrandingInfo(newOrgEntrypoint);
      this.reloadOrgBranding(newOrg, false);
    },
    orgBrandingInfo() {
      this.updateFaviconElement();
    },
  },
  created() {
    this.orgCustomStyle = document.getElementById('custom_css');
    this.orgCustomColors = document.getElementById('colors_css');
  },
  methods: {
    ...mapActions([
      'loadOrgBrandingInfo',
      'loadUserBrandingInfo',
    ]),
    createCssElement(href) {
      const tag = href ? 'link' : 'style';
      const elm = document.createElement(tag);
      elm.type = 'text/css';
      elm.rel = 'stylesheet';
      if (href) {
        elm.href = href;
      }
      return elm;
    },
    updateFaviconElement() {
      const newHtml = this.orgBrandingInfo.faviconHtml;
      if (newHtml) {
        const head = document.head;
        // get old ones
        const oldNodes = head.querySelectorAll('*[data-favicon]');
        oldNodes.forEach(c => head.removeChild(c));

        // add new ones
        const selectedOrgEntrypoint = this.selectedOrganization ? this.selectedOrganization.entryPoint : '';
        const tempHead = document.createElement('head');
        tempHead.innerHTML = newHtml;
        tempHead.childNodes.forEach((c) => {
          c.setAttribute('data-favicon', '');
          const newRef = `${c.getAttribute('href')}&org=${selectedOrgEntrypoint}`;
          c.setAttribute('href', newRef);
          head.appendChild(c);
        });
      }
    },
    artifactLastUpdated(artifactName) {
      const brandingInfo = (this.orgBrandingInfo || {});
      const artifact = (brandingInfo.artifacts || []).find(art => art.name === artifactName);
      return artifact ? artifact.updatedAt : format(getNow(), DEFAULT_DATE_FORMAT);
    },
    async receivedBrandingEvent(event) {
      const deleteSuccess = event.eventCode === 'brandings.deleted' && event.status === 'SUCCESS';
      const eventSuccess = event.status === 'SUCCESS';

      if (event.organizationId === this.myOrganization.id) {
        this.loadUserBrandingInfo();
      }

      if (deleteSuccess || (eventSuccess &&
          event.organizationId === (((await this.getEffective()) || {}).organization || {}).id)) {
        this.reloadOrgBranding(this.selectedOrganization, true);
        this.loadOrgBrandingInfo(this.selectedOrganization.entryPoint);
      }
    },
    async getEffective() {
      const resp = await apis.branding.getEffective(this.selectedOrganization.id);
      if (!resp) {
        notify.error(this.$t('unexpected_error'));
        return null;
      }
      if (resp.status === 400 && resp.errors) {
        resp.errors.forEach(err => notify.error(this.$t(err.message)));
        return null;
      }
      this.branding = resp.data;
      return resp.data;
    },
    reloadOrgBranding(newOrg, reloadIfSame) {
      this.updateFaviconElement();

      const head = document.head;
      if ((newOrg || {}).id === this.orgBrandingId && !reloadIfSame) {
        return;
      }
      const colorAnimation = this.createCssElement();
      colorAnimation.id = 'transition-colors';
      colorAnimation.innerHTML = `* {
        transition: color 1s, background-color 1s, border-color 1s !important;
      }`;
      head.appendChild(colorAnimation);

      const removeOrgCustom = this.orgCustomStyle;
      const removeOrgColors = this.orgCustomColors;

      if (!newOrg || (this.myOrganization && this.myOrganization.id === newOrg.id)) {
        this.orgCustomStyle = this.createCssElement(`/rest/appearance/custom.css?&t=${this.artifactLastUpdated('custom.css')}`);
        this.orgCustomColors = this.createCssElement(`/rest/appearance/colors.css?&t=${this.artifactLastUpdated('colors.css')}`);
      } else {
        this.orgCustomStyle = this.createCssElement(`/rest/appearance/custom.css?org=${newOrg.entryPoint}&t=${this.artifactLastUpdated('custom.css')}`);
        this.orgCustomColors = this.createCssElement(`/rest/appearance/colors.css?org=${newOrg.entryPoint}&t=${this.artifactLastUpdated('colors.css')}`);
      }
      head.appendChild(this.orgCustomStyle);
      head.appendChild(this.orgCustomColors);

      setTimeout(() => {
        if (removeOrgCustom) {
          head.removeChild(removeOrgCustom);
        }
        if (removeOrgColors) {
          head.removeChild(removeOrgColors);
        }
        if (colorAnimation) {
          head.removeChild(colorAnimation);
        }
      }, 1500);
    },
  },
};
</script>
