<template>
  <list-column>
    <div class="sparkline">
      <sparkline-chart :series="existingActivity" />
    </div>
  </list-column>
  <list-column
    allowOverflow
    :size="1"
    class="state-column"
  >
    <div class="state-container">
      <base-badge
        class="badge"
        :state="statusBadge.label"
        :color="statusBadge.color"
        :animate="false"
      >
      </base-badge>
    </div>
  </list-column>
  <list-column
    @mouseover="onMouseOver(HOVERABLE_COLUMN.SINCE)"
    @mouseleave="onMouseExit(HOVERABLE_COLUMN.SINCE)"
  >
    {{ formatRelativeDate(organization.statusUpdatedAt, HOVERABLE_COLUMN.SINCE) }}
  </list-column>
  <list-column
    @mouseover="onMouseOver(HOVERABLE_COLUMN.AGE)"
    @mouseleave="onMouseExit(HOVERABLE_COLUMN.AGE)"
  >
    {{ formatRelativeDate(organization.creationDate, HOVERABLE_COLUMN.AGE) }}
  </list-column>
  <list-column v-if="showTags">
    <base-tags
      v-if="organization.tags"
      :tags="tags"
    />
  </list-column>
</template>

<script>
import authz from '@/authz';
import { sortBy } from '@/utils';
import { distanceInWordsToNow } from '@/utils/dates';
import SparklineChart from '@/components/echart/SparklineChart';
import { getOrganizationStatusObject } from '@/utils/states';

const HOVERABLE_COLUMN  = {
	SINCE: 0,
	AGE: 1,
}

export default {
  name: 'OrganizationRow',
  components: { SparklineChart },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    activity: {
      type: Array,
      default: () => new Array(30).fill(0),
    },
  },
  data() {
    return {
      hoveringStates: [false, false],
      HOVERABLE_COLUMN
    }
  },
  computed: {
    tags() {
      return this.organization.tags ?
        [...this.organization.tags].sort(sortBy(t => t.name))
          .sort(sortBy(t => !t.system))
          .map((t) => {
            if (t.system) {
              t.colorClass = 'readonly';
            }
            return t;
          })
        : [];
    },
    existingActivity() {
      if (!this.activity || this.activity.length < 2) {
        return [];
      }
      return this.activity;
    },
    showTags() {
      return authz.hasPermission('reseller:orgsMeta');
    },
    statusBadge() {
      return getOrganizationStatusObject(this.organization.status);
    },
  },
  methods: {
    onMouseOver(index) {
      this.hoveringStates[index] = true
    },
    onMouseExit(index) {
      this.hoveringStates[index] = false
    },
    formatRelativeDate(date, index) {
      if (this.hoveringStates[index]) {
        return this.$date(date, 'YYYY-MM-DD');
      }
      return distanceInWordsToNow(date);
    },
  },
};
</script>

<style scoped lang="scss">
.sparkline {
  width: 96px;
  height: 32px;
}

:deep(.popover) {
  min-width: 100px !important;
}
</style>
