<template>
  <div class="view-filters">
    <div 
      v-for="(filter, idx) in shownFilters"
      :key="filter.queryParam" 
      class="view-filter" 
    >
      <time-filter 
        v-if="filter.type === 'TIME'" 
        role="dialog" 
        :filter="filter" 
        :simple="simple"
        @change="change($event, idx)" 
      />
      <datasource-filter 
        v-else-if="filter.type === 'DATASOURCE'" 
        :filter="filter" 
        :simple="simple"
        @change="change($event, idx)" 
      />
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import TimeFilter from './TimeFilter';
import DatasourceFilter from './DatasourceFilter';

const VIEW_FILTERS_SET = 'view_filters';

export default {
  name: 'ViewFilters',
  components: { TimeFilter, DatasourceFilter },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    simple: {
      type: Boolean,
    },
  },
  emits: ['query'],
  data() {
    return {
      options: {},
    };
  },
  computed: {
    shownFilters() {
      return this.filters.filter(f => !f.hide);
    },
  },
  methods: {
    ...mapActions([
      'replacePill',
    ]),
    change(o, idx) {
      const filterKey = this.filters[idx].key;
      this.$emit('query', o);
      this.replacePill({
        filterset: VIEW_FILTERS_SET,
        pill: { type: filterKey, key: filterKey, value: o },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.view-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .view-filter {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
