import store from '@/store';

const handle = (data) => {
  store.dispatch('addSensitiveNotificationData', data);
  if (data.windowId === store.getters.windowId) {
    store.dispatch("openSecondaryNav", { secondary: 'notifications' });
  }
};

export default {
  handle,
};