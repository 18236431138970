import { CrudApi } from './api';

class RoleApi extends CrudApi {
  constructor() {
    super('roles');
  }

  impliedEnvRolesEnabledStatus(connId, orgId) {
    return this.get(`${this.path}/${connId}/${orgId}/implied_roles_enabled_status`);
  }
  
  listApplicableRolesForEnvironments(environments) {
    return this.get(`roles/applicable_roles_for_environments`, { qs: { environmentIds: environments } });
  }
}

const roleApi = new RoleApi();

export default roleApi;
