<template>
  <form-page
    :title="title"
    :defaultBack="isCustomDiscount ? {name: 'org-billing'} : {name: 'pricingPackageList'}"
    :disabled="false"
    :executing="executing"
    @submit="submitForm"
  >
    <div class="discounts-and-credits-form">
      <form-row
        label="name"
        :error="errors.name"
      >
        <i18n-toggle
          requiresAll
          :requiredLanguages="discount.name"
        >
          <template #default="{ lang }">
            <base-input
              v-model="discount.name[lang]"
              @focus="errors.name = null"
            ></base-input>
          </template>
        </i18n-toggle>
      </form-row>
      <form-row
        v-if="!currentDiscount"
        label="monetization.discounts.start_date"
        :descriptionLabel="`monetization.discounts.${type}.start_date_desc`"
        :error="errors.startDate"
      >
        <date-input
          v-model="discount.startDate"
          allowEmpty
          utc
          :minDate="minStartDate"
          :maxDate="maxEndDate"
          @update:modelValue="startUpdated"
        ></date-input>
      </form-row>
      <form-row
        v-show="!noCutoffDate"
        label="monetization.discounts.cutoff_date"
        :descriptionLabel="`monetization.discounts.${type}.cutoff_date_desc`"
        :error="errors.cutoffDate"
      >
        <date-input
          v-model="discount.cutoffDate"
          allowEmpty
          utc
          :minDate="minCutoffDate"
          :maxDate="maxEndDate"
          :disabled="noCutoffDate"
          @update:modelValue="endUpdated"
        ></date-input>
      </form-row>
      <form-row>
        <base-checkbox
          v-if="!isCustomDiscount"
          v-model="noCutoffDate"
          sm
          label="monetization.discounts.no_cutoff_date"
          :disabled="isCustomDiscount"
        ></base-checkbox>
      </form-row>
      <div
        v-if="!currentDiscount && credit"
        class="enable-renewable-credits"
      >
        {{ $t('monetization.discounts.credit.renewable') }}
        <base-tooltip
          toggleOnFocus
          :message="$t('monetization.discounts.credit.renewable_desc')"
          direction="right"
        >
          <base-icon
            class="tooltip-icon"
            icon="fa fa-info-circle"
          />
        </base-tooltip>
        <base-toggle
          v-model="discount.isRenewable"
          class="renewable-credit-toggle"
        />
      </div>
      <div v-if="!currentDiscount && discount.isRenewable">
        <form-row
          label="monetization.discounts.credit.renewable_discount_ends"
          :error="errors.occurrenceError"
        >
          <div class="occurrence-wrapper">
            <radio-group
              :options="occurrenceOptions"
              name="occurrenceOption"
              :modelValue="occurrenceOption"
              @update:modelValue="updateOccurrenceOption"
            />
            <div>
              <div class="placeholder-div"></div>
              <div>
                <base-input
                  v-model="discount.renewOccurrences"
                  type="number"
                  size="sm"
                  :disabled="isNeverSelected"
                  class="occurrence-textbox"
                  suffix="monetization.discounts.credit.occurrences"
                  @focus="errors.occurrenceError = null"
                />
              </div>
            </div>
          </div>
        </form-row>
      </div>
      <div v-if="!discount.isRenewable">
        <form-row
          v-if="!currentDiscount"
          label="monetization.discounts.duration"
          :descriptionLabel="`monetization.discounts.${type}.duration_desc`"
          :error="errors.durationDays"
        >
          <base-input
            v-model="discount.durationDays"
            :disabled="noDuration"
            type="number"
            suffix="monetization.discounts.days"
            @focus="errors.durationDays = null"
          ></base-input>
        </form-row>
        <form-row v-if="!currentDiscount">
          <base-checkbox
            v-model="noDuration"
            sm
            :label="`monetization.discounts.${type}.no_duration`"
            @update:modelValue="errors.durationDays = null"
          ></base-checkbox>
        </form-row>
      </div>
      <form-row
        v-if="!currentDiscount"
        label="monetization.discounts.scope"
      >
        <base-select
          v-model="discount.scope"
          :items="scopes"
        ></base-select>
      </form-row>
      <form-row
        v-if="isCustomDiscount && discount.scope !== 'ALL_PRODUCTS'"
        label="monetization.pricing_packages"
      >
        <base-select
          v-model="selectedPackage"
          :items="packages"
          @update:modelValue="updatePackage"
        ></base-select>
      </form-row>
      <form-row
        v-if="discount.scope === 'ALL_PRODUCTS' && !currentDiscount"
        :label="`monetization.discounts.${type}.amount`"
        :error="errors.packageDiscount"
      >
        <base-input
          v-model="discount.packageDiscount"
          type="number"
          :suffix="suffix"
          @focus="errors.packageDiscount = null"
        ></base-input>
      </form-row>
    </div>
    <div
      v-if="!currentDiscount"
      class="category-product-scope"
    >
      <div v-if="discount.scope === 'CATEGORIES'">
        <div :class="{ 'error-border': !!errors.categoryError }">
          <base-tabs
            light
            :tabs="productCatalogs"
            :selected="defaultOrSelectedCatalog"
            @tabChange="changeCatalog"
          ></base-tabs>
          <base-list
            ariaLabel="monetization.discounts.scopes.categories"
            :ariaRowCount="filteredCatalogCategories.length"
          >
            <list-header noActions>
              <list-column :size="1">
                {{ $t('monetization.discounts.category') }}
              </list-column>
              <list-column :size="1">
                {{ $t('monetization.discounts.number_of_products') }}
              </list-column>
              <list-column :size="2">
                {{ $t(`monetization.discounts.${type}.amount`) }}
              </list-column>
            </list-header>
          </base-list>
          <list-row
            v-for="category in filteredCatalogCategories"
            :key="category.id"
            noActions
          >
            <list-column :size="1">
              {{ category.name[locale] }}
            </list-column>
            <list-column :size="1">
              {{ findNumberOfPricingProducts(category.id) }}
            </list-column>
            <list-column :size="2">
              <base-input
                v-model="discount.discountedCategories[category.id]"
                type="number"
                :suffix="suffix"
                @focus="errors.categoryError = null"
              ></base-input>
            </list-column>
          </list-row>
        </div>
        <div
          v-if="errors.categoryError"
          class="error-label red"
        >
          {{ $te(errors.categoryError) ? $t(errors.categoryError) : errors.categoryError }}&nbsp;
        </div>
      </div>
      <div v-if="discount.scope === 'PRODUCTS'">
        <div :class="{ 'error-border': !!errors.productError }">
          <base-tabs
            light
            class="detailed-scope"
            :tabs="productCategories"
            :selected="defaultOrSelectedCategory"
            @tabChange="changeCategory"
          ></base-tabs>
          <base-list
            ariaLabel="monetization.products"
            :ariaRowCount="filteredCategoryProducts.length"
          >
            <list-header noActions>
              <list-column :size="1">
                {{ $t('monetization.discounts.product') }}
              </list-column>
              <list-column :size="1">
                {{ $t('monetization.discounts.ref_price') }}
              </list-column>
              <list-column :size="2">
                {{ $t(`monetization.discounts.${type}.amount`) }}
              </list-column>
              <list-column
                v-if="!credit"
                :size="1"
              >
                {{ $t('monetization.discounts.discounted_price') }}
              </list-column>
            </list-header>
          </base-list>
          <list-row
            v-for="pp in filteredCategoryProducts"
            :key="pp.id"
            noActions
          >
            <list-column :size="1">
              <div>{{ pp.product.name[locale] }}</div>
              <div class="detail-text">
                {{ pp.product.sku }}
              </div>
            </list-column>
            <list-column :size="1">
              {{ getReferencePrice(pp) }}
            </list-column>
            <list-column :size="2">
              <base-input
                v-model="discount.discountedProducts[pp.product.id]"
                type="number"
                :suffix="suffix"
                @focus="errors.productError = null"
              ></base-input>
            </list-column>
            <list-column
              v-if="!credit"
              :size="1"
            >
              {{ getDiscountedPrice(pp) }}
            </list-column>
          </list-row>
        </div>
        <div
          v-if="errors.productError"
          class="error-label red"
        >
          {{ $te(errors.productError) ? $t(errors.productError) : errors.productError }}&nbsp;
        </div>
      </div>
    </div>
  </form-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDate, getNow, startOfDay } from '@/utils/dates';
import { getTranslationMap } from '@/i18n';
import { pricingUnitFormatterMixin } from '@/mixins/pricingUnitFormatter';
import { reactive } from 'vue';

export default {
  name: 'DiscountForm',
  mixins: [pricingUnitFormatterMixin],
  props: {
    credit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => (reactive({
        name: getTranslationMap(),
        type: undefined,
        startDate: undefined,
        cutoffDate: undefined,
        durationDays: undefined,
        scope: undefined,
        packageDiscount: undefined,
        discountedCategories: {},
        discountedProducts: {},
        isRenewable: false,
        renewDurationMonths: null,
        renewOccurrences: undefined
      })),
    },
    pricingPackage: {
      type: Object,
      required: true,
    },
    effectivePricing: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isCustomDiscount: {
      type: Boolean,
      default: false,
    },
    packages: {
      type: Array,
      default() {
        return [];
      },
    },
    minStartDate: {
      type: Date,
      default: ({ pricingPackage }) => {
        let today = startOfDay(getNow(true), true);
        let packageStart = new Date(pricingPackage.startDate);
        return today > packageStart ? today : packageStart;
      },
    },
  },
  emits: ['submit', 'update:modelValue', 'startUpdated', 'packageUpdated'],
  data() {
    return {
      discount: this.modelValue,
      occurrenceOptions: [
        { name: this.$t('monetization.discounts.credit.never'), value: 'never', is18n: true },
        { name: this.$t('monetization.discounts.credit.after'), value: 'after', is18n: true }
      ],
      occurrenceOption: 'never',
      errors: {
        startDate: undefined,
        cutoffDate: undefined,
        name: undefined,
        durationDays: undefined,
        packageDiscount: undefined,
        categoryError: undefined,
        productError: undefined,
        occurrenceError: undefined
      },
      scopes: [{
        label: 'monetization.discounts.scopes.all_products',
        value: 'ALL_PRODUCTS',
      }, {
        label: 'monetization.discounts.scopes.categories',
        value: 'CATEGORIES',
      }, {
        label: 'monetization.discounts.scopes.products',
        value: 'PRODUCTS',
      }],
      selectedCatalog: undefined,
      selectedCategory: undefined,
      selectedPackage: undefined,
      noCutoffDate: this.isCustomDiscount,
      noDuration: false,
      executing: false,
      renewOccurrences: undefined
    };
  },
  computed: {
    isNeverSelected() {
      return this.occurrenceOption === 'never';
    },
    currentDiscount() {
      return this.discount.status === 'CURRENT';
    },
    ...mapGetters(['locale', 'billingSettings']),
    minCutoffDate() {
      if (this.currentDiscount) {
        return this.minStartDate;
      } else if (this.discount.startDate) {
        const start = getDate(this.discount.startDate, true);
        return start;
      }
      return this.minStartDate;
    },
    maxEndDate() {
      return this.pricingPackage.endDate ? getDate(this.pricingPackage.endDate, true) : null;
    },
    type() {
      return this.discount.type.toLowerCase();
    },
    productCatalogs() {
      const catalogs = (this.effectivePricing.productCatalogs || [])
        .map(catalog => ({ label: catalog.name[this.locale], value: catalog.id }));
      return catalogs;
    },
    productCategories() {
      const categoriesForDisplay = [];
      (this.effectivePricing.productCatalogs || [])
        .forEach((catalog) => {
          catalog.categories
            .filter(category => this.findNumberOfPricingProducts(category.id) > 0)
            .forEach(category => categoriesForDisplay.push({
              label: `${catalog.name[this.locale]} | ${category.name[this.locale]}`,
              value: category.id,
            }));
        });
      return categoriesForDisplay;
    },
    currency() {
      return (this.billingSettings || {}).defaultCurrency;
    },
    defaultOrSelectedCatalog() {
      return this.selectedCatalog || (this.productCatalogs[0] || {}).value;
    },
    defaultOrSelectedCategory() {
      return this.selectedCategory || (this.productCategories[0] || {}).value;
    },
    filteredCatalogCategories() {
      const catalog = (this.effectivePricing.productCatalogs || [])
        .find(cat => cat.id === this.defaultOrSelectedCatalog);
      if (catalog) {
        return catalog.categories
          .filter(category => this.findNumberOfPricingProducts(category.id) > 0);
      }
      return [];
    },
    filteredCategoryProducts() {
      const products = this.effectivePricing.pricingProducts
        .filter(pp => pp.product.categoryId === this.defaultOrSelectedCategory);
      return products;
    },
    suffix() {
      return this.credit ? this.currency : '%';
    },
  },
  watch: {
    discount: {
      handler(updatedDiscount) {
        this.$emit('update:modelValue', updatedDiscount);
      },
      deep: true,
    },
    pricingPackage() {
      if (this.pricingPackage.id !== this.selectedPackage) {
        this.discount.discountedCategories = {};
        this.discount.discountedProducts = {};
        this.discount.appliedPricing = { 'id': this.pricingPackage.id };
        this.selectedPackage = this.pricingPackage.id;
      }
    },
    noDuration: {
      handler(noDuration) {
        if (noDuration) {
          this.discount.durationDays = null;
        }
      },
    },
  },
  created() {
    if (!this.discount.id) {
      this.discount.startDate = this.minStartDate;
      this.discount.cutoffDate = this.minCutoffDate;
    } else {
      this.noCutoffDate = !this.discount.cutoffDate;
      this.noDuration = !this.discount.durationDays;
    }
    this.discount.type = this.credit ? 'CREDIT' : 'PERCENTAGE';
  },
  methods: {
    updateOccurrenceOption(type) {
      this.occurrenceOption = type;
    },
    updatePackage(pricingPackage) {
      this.discount.discountedCategories = this.discount.discountedCategories ? this.discount.discountedCategories : {};
      this.discount.discountedProducts = this.discount.discountedProducts ? this.discount.discountedProducts : {};
      this.discount.appliedPricing = { 'id': pricingPackage };
      this.$emit('packageUpdated', pricingPackage, this.discount.startDate);
    },
    async submitForm() {
      this.executing = true;
      if (this.validateAll()) {
        if (this.noDuration) {
          this.discount.durationDays = null;
        }
        if (this.noCutoffDate) {
          this.discount.cutoffDate = null;
        }
        if (this.discount.isRenewable) {
          this.discount.renewDurationMonths = 1;
        }
        this.$emit('submit', this.discount);
      } else {
        this.executing = false;
      }
    },
    async startUpdated(date) {
      this.updateCutoffDate(date);
      this.$emit('startUpdated', date);
      this.errors.categoryError = null;
      this.errors.productError = null;
      this.errors.startDate = null;
    },
    endUpdated() {
      this.errors.cutoffDate = null;
    },
    updateCutoffDate(date) {
      if (!this.discount.cutoffDate ||
        (getDate(this.discount.startDate, true) >= getDate(this.discount.cutoffDate, true))) {
        this.errors.cutoffDate = null;
        this.discount.cutoffDate = date;
      }
    },
    changeCatalog(event) {
      this.selectedCatalog = event.value;
    },
    changeCategory(event) {
      this.selectedCategory = event.value;
    },
    findNumberOfPricingProducts(categoryId) {
      return this.effectivePricing.pricingProducts
        .filter(pp => pp.product.categoryId === categoryId)
        .length;
    },
    getReferencePrice(pricingProduct) {
      const cur = this.currency;
      const unit = this.formatProductUnit(pricingProduct.product, cur);
      return `${pricingProduct.unitPrice[cur]} ${unit}`;
    },
    getDiscountedPrice(pricingProduct) {
      const cur = this.currency;
      const unit = this.formatProductUnit(pricingProduct.product, cur);
      const discount = this.discount.discountedProducts[pricingProduct.id];
      if (discount) {
        const multiplier = (1 - (discount / 100));
        const discountedPrice = (multiplier * pricingProduct.unitPrice[cur]).toFixed(2);
        return `${discountedPrice} ${unit}`;
      }
      return `${pricingProduct.unitPrice[cur]} ${unit}`;
    },
    hasMissingLangs(attrib) {
      return !!Object.keys(attrib).find(l => !attrib[l]);
    },
    validateName() {
      if (this.hasMissingLangs(this.discount.name)) {
        this.errors.name = this.$t('monetization.discounts.errors.required_field');
        return false;
      }
      return true;
    },
    validateOccurrence() {
      const occurrence = this.occurrenceOption === 'never' ? -1 : Number.parseFloat(this.discount.renewOccurrences);

      if (this.occurrenceOption === 'after' && (!occurrence || occurrence <= 0)) {
        this.errors.occurrenceError = occurrence <= 0 ? this.$t('monetization.discounts.errors.occurrences_error') : this.$t('monetization.discounts.errors.required_field');
        return false;
      }
      this.discount.renewOccurrences = occurrence;
      return true;
    },
    validateDuration() {
      if (this.noDuration) {
        return true;
      }
      if (!this.discount.durationDays) {
        this.errors.durationDays = this.$t('monetization.discounts.errors.required_field');
        return false;
      }
      const duration = Number.parseFloat(this.discount.durationDays);
      if (!Number.isInteger(duration) || duration < 1) {
        this.errors.durationDays = this.$t('monetization.discounts.errors.duration');
        return false;
      }
      this.discount.durationDays = duration;
      return true;
    },
    validateStartDate() {
      if (!this.discount.startDate) {
        this.errors.startDate = this.$t('monetization.discounts.errors.required_field');
        return false;
      }
      const start = getDate(this.discount.startDate, true);
      if (start < this.minStartDate || (this.maxEndDate && start > this.maxEndDate)) {
        this.errors.startDate = this.$t('monetization.discounts.errors.start_date');
        return false;
      }
      return true;
    },
    validateCutoffDate() {
      if (!this.noCutoffDate) {
        if (!this.discount.cutoffDate) {
          this.errors.cutoffDate = this.$t('monetization.discounts.errors.required_field');
          return false;
        }
        const cutoff = getDate(this.discount.cutoffDate, true);
        if (cutoff < this.minCutoffDate || (this.maxEndDate && cutoff > this.maxEndDate)) {
          this.errors.cutoffDate = this.$t('monetization.discounts.errors.cutoff_date');
          return false;
        }
      }
      return true;
    },
    validateScope() {
      switch (this.discount.scope) {
        case 'ALL_PRODUCTS': {
          if (!this.discount.packageDiscount) {
            this.errors.packageDiscount = this.$t('monetization.discounts.errors.required_field');
            return false;
          }
          const discount = parseFloat(this.discount.packageDiscount);
          if (Number.isNaN(discount) || discount <= 0 || (!this.credit && discount > 100)) {
            this.errors.packageDiscount = this.credit ?
              this.$t('monetization.discounts.errors.credit_value') : this.$t('monetization.discounts.errors.discount_value');
            return false;
          }
          this.discount.packageDiscount = discount;
          return true;
        }
        case 'CATEGORIES': {
          if (this.noDiscountedCategories()) {
            this.errors.categoryError = this.$t(`monetization.discounts.errors.no_${this.type}_specified`);
            return false;
          }
          const invalid = Object.keys(this.discount.discountedCategories).some((id) => {
            const categoryDiscount = parseFloat(this.discount.discountedCategories[id]);
            if (Number.isNaN(categoryDiscount) || categoryDiscount <= 0
              || (!this.credit && categoryDiscount > 100)) {
              this.errors.categoryError = this.credit ?
                this.$t('monetization.discounts.errors.credit_value') : this.$t('monetization.discounts.errors.discount_value');
              return true;
            }
            this.discount.discountedCategories[id] = categoryDiscount;
            this.discount.packageDiscount = null;
            return false;
          });
          return !invalid;
        }
        case 'PRODUCTS': {
          if (this.noDiscountedProducts()) {
            this.errors.productError = this.$t(`monetization.discounts.errors.no_${this.type}_specified`);
            return false;
          }
          const invalid = Object.keys(this.discount.discountedProducts).some((id) => {
            const productDiscount = parseFloat(this.discount.discountedProducts[id]);
            if (Number.isNaN(productDiscount) || productDiscount <= 0
              || (!this.credit && productDiscount > 100)) {
              this.errors.productError = this.credit ?
                this.$t('monetization.discounts.errors.credit_value') : this.$t('monetization.discounts.errors.discount_value');
              return true;
            }
            this.discount.discountedProducts[id] = productDiscount;
            this.discount.packageDiscount = null;
            return false;
          });
          return !invalid;
        }
        default:
          return false;
      }
    },
    validateAll() {
      const validName = this.validateName();
      let validStart = true;
      if (!this.currentDiscount) {
        validStart = this.validateStartDate();
      }
      const validCutoff = this.validateCutoffDate();
      const validDuration = this.validateDuration();
      const validScope = this.validateScope();
      const validOccurrence = this.validateOccurrence();
      const validParams = validName && validStart
        && validCutoff && validScope;
      return this.credit && this.discount.isRenewable ? validParams && validOccurrence : validParams && validDuration;
    },
    noDiscountedCategories() {
      Object.keys(this.discount.discountedCategories).forEach((id) => {
        if (!this.discount.discountedCategories[id] ||
          !this.productCategories.some(cat => cat.value === id)) {
          delete this.discount.discountedCategories[id];
        }
      });
      return Object.keys(this.discount.discountedCategories).length === 0;
    },
    noDiscountedProducts() {
      Object.keys(this.discount.discountedProducts).forEach((id) => {
        if (!this.discount.discountedProducts[id] ||
          !this.effectivePricing.pricingProducts.some(prod => prod.product.id === id)) {
          delete this.discount.discountedProducts[id];
        }
      });
      return Object.keys(this.discount.discountedProducts).length === 0;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/mixins';

.discounts-and-credits-form {
  max-width: 600px;

  .pad-top {
    padding-top: 15px;
  }
}

.input-control {
  width: 100%;
}

.category-product-scope {
  max-width: 800px;
  padding-top: 10px;

  .error-border {
    border: 1px solid var(--input-error-border);
    box-sizing: border-box;
  }

  .error-label {
    margin-top: 4px;
    text-align: left;
    font-size: 12px;
  }
}

.enable-renewable-credits {
  align-self: flex-start;
  margin-top: 20px;
  display: flex;
  align-items: center;

  .fa-plus {
    margin-right: 5px;
  }

  .renewable-credit-toggle {
    margin-left: 10px;
  }

  .tooltip-icon {
    margin-left: 5px;
  }

  :deep(.popover) {
    min-width: 350px;
  }
}

.occurrence-wrapper {
  display: flex;
  align-items: center;
}

.occurrence-textbox {
  margin: 0 8px 0 8px;
}

.placeholder-div {
  height: 23px;
  margin-bottom: 12px;
}
</style>
