import { CrudApi } from './api';

class ResourceCommitments extends CrudApi {
  constructor() {
    super('resource_commitments');
  }
  getPricingMetaInfo(pricingId) {
    return this.get(
      'resource_commitments/details_for_pricing', { qs: { pricingId } });
  }

  getUsage(serviceConnectionId, organizationId) {
    const qs = {
      org_id: organizationId,
    };
    return this.get(
      `services/connections/${serviceConnectionId}/resource_commitment_usage`, { qs });
  }
}

const res = new ResourceCommitments();
export default res;
