<template>
  <div v-if="!loading">
    <form-page 
      navbarForm
      :executing="executing"
      :title="'monetization.edit_billable_org_info_title'"
      :description="'monetization.edit_billable_org_info_description'"
      :disabled="!isComplete"
      :defaultBack="{name:'billing-overview'}"
      @submit="submitBillingCycleChange"
      @cancel="goBack"
    >
      <form-row 
        label="monetization.edit_billable_org_info_billing_cycle" 
        descriptionLabel="monetization.edit_billable_org_info_billing_cycle_descr"
      >
        <base-select 
          v-model="selectedCycleId" 
          size="sm" 
          :items="cycleItems" 
          placeholder="monetization.edit_billable_org_info_billing_cycle_place_holder"
        />
      </form-row>
      <form-row 
        label="monetization.edit_billable_org_info_pricing_package" 
        descriptionLabel="monetization.edit_billable_org_info_pricing_package_descr"
      >
        <base-select 
          v-model="selectedPackageId" 
          size="sm" 
          :items="filteredPackages" 
          placeholder="monetization.edit_billable_org_info_pricing_package_placeholder"
        />
      </form-row>
      <div 
        v-if="!selectedPackageId || !selectedCycleId" 
        class="alert-box"
      >
        <alert-box 
          label="monetization.select_billing_cycle_and_package" 
          alertType="INFO" 
          border
        ></alert-box>
      </div>
      <div v-else>
        <div class="discounts-group">
          <form-row>
            <template #titleRight>
              <slot>
                <div class="discount-header">
                  {{ $t('monetization.choose_cycle_package_discounts') }} <b>{{ packageName }}</b>
                </div>
              </slot>
            </template>
            <SelectableDiscountList 
              class="selectable-list"
              :discounts="packageDiscounts"
              :selectedDiscounts="selectedPackageDiscounts"
              :ariaLabel="$t('monetization.choose_cycle_package_discounts') + ' ' + packageName"
              @update:modelValue="updatePackageDiscounts" 
            />
          </form-row>
        </div>
        <div class="discounts-group">
          <form-row>
            <template #titleRight>
              <slot>
                <div class="discount-header">
                  {{ $t('monetization.choose_cycle_org_discounts') }} <b>{{ orgName }}</b>
                </div>
              </slot>
            </template>
            <SelectableDiscountList 
              class="selectable-list"
              :discounts="orgDiscounts"
              :selectedDiscounts="selectedOrgDiscounts"
              :ariaLabel="$t('monetization.choose_cycle_org_discounts') + ' ' + orgName"
              @update:modelValue="updateOrgDiscounts" 
            />
          </form-row>
        </div>
      </div>
      <confirm-modal
        v-if="leave"
        headerIcon="fa fa-check-circle"
        :open="confirmLeave"
        headerLabel="monetization.edit_billable_org_info_modal_header"
        detailsLabel="monetization.edit_billable_org_info_modal_descr"
        confirmLabel="monetization.edit_billable_org_info_modal_confirm"
        @ok="leave"
        @cancel="confirmLeave = false"
      />
    </form-page>
    <confirm-modal
      v-if="openConfirmationModal"        
      headerIcon="fa fa-edit"
      :open="openConfirmationModal"
      headerLabel="monetization.update_assiged_pricing_package"
      :headerLabelInterpolation="{ organization: organization.name }"
      detailsLabel="monetization.rollback_warning_update_assigned_pricing_package"
      :detailsInterpolation="{
        date: displayDate,
        organization: organization.name,
        packageBefore: packagesBefore[selectedCycleId].label,
        packageAfter: selectedPricingPackage.label,
      }"
      confirmLabel="update"
      :executing="executing"
      @ok="submitBillingCycleChange"
      @cancel="openConfirmationModal = false"
    />
  </div>
  <base-loader v-else></base-loader>
</template>
<script>
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { sortBy } from '@/utils';
import { getNow, isBefore, subDays, LIST_SIMPLE_DATE_FORMAT, format } from '@/utils/dates';
import SelectableDiscountList from './SelectableDiscountList';
import store from '@/store';

export default {
  name: 'BillableOrgInfoEdit',
  components: { SelectableDiscountList },
  beforeRouteLeave(to, from, next) {
    if (this.isComplete || this.forceLeave) {
      next();
    } else {
      this.confirmLeave = true;
      this.leave = next;
    }
  },
  props: {
    cycleId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      info: {},
      cycles: [],
      packages: [],
      discounts: [],
      loading: true,
      executing: false,
      selectedPackageId: '',
      selectedPackage: {},
      selectedCycleId: '',
      selectedCycle: {},
      packageDiscounts: [],
      orgDiscounts: [],
      selectedOrgDiscounts: [],
      selectedPackageDiscounts: [],
      leave: false,
      confirmLeave: false,
      openConfirmationModal: false,
      organization: {},
      filteredPackages: []
    };
  },
  computed: {
    ...mapGetters(['locale']),
    displayDate() {
      return this.cycleItems.filter(b => b.state === 'IN_PROGRESS' || b.state === 'AWAITING_INVOICE').reduce((acc, b) => {
        if (!acc) {
          return b.startDate;
        }
        return isBefore(acc, b.startDate) ? acc : b.startDate;
      }, null);
    },
    currentOrganizationId() {
      return this.$route.params.id;
    },
    cycleItems() {
      return this.cycles
        .filter(bc => bc.state === 'WAITING_INVOICE' || bc.state === 'FUTURE' || bc.state === 'IN_PROGRESS')
        .map(bc => ({
          label: this.cycleDate(bc),
          value: bc.id,
        }))
        .sort(sortBy(bc => bc.startDate));
    },
    isComplete() {
      if (this.selectedPackageId === '' || this.selectedCycleId === '') {
        return false;
      }
      return true;
    },
    selectedBillingCycle() {
      return this.cycleItems.find(bc => bc.value === this.selectedCycleId);
    },
    selectedPricingPackage() {
      return this.packageItems.find(p => p.id === this.selectedPackageId);
    },
    packageName() {
      return this.selectedPackage.name[this.locale];
    },
    orgName() {
      return this.organization.name;
    },
  },
  watch: {
    async selectedPackageId(packageId) {
      if (packageId) {
        this.selectedPackage = this.packages.find(p => p.id === packageId);
        const resp = await apis.pricingPackages.getDiscountsOfBothType(packageId);
        if (resp.ok) {
          this.packageDiscounts = resp.data
            .filter(dc => !dc.isDeactivated)
            .filter(dc => dc.status !== 'ENDED');
          if (this.selectedCycleId) {
            this.selectedPackageDiscounts = this.packageDiscounts;
          }
        } else {
          notify.error(this.$t('monetization.error_fetching_discounts'));
        }
      }
    },
    async selectedCycleId(billingCycleId) {
      if (billingCycleId) {
        const selectedCycle = this.cycles.find(c => c.id === billingCycleId);
        this.selectedCycle = selectedCycle;
        this.orgDiscounts = this.discounts
          .filter(d => d.mode === 'ORGANIZATION')
          .filter(d => !d.isDeactivated)
          .filter(d => d.status !== 'ENDED');

        this.selectedOrgDiscounts = selectedCycle.discounts
          .filter(d => this.orgDiscounts.some(cd => cd.id === d.discountId))
          .map(d => ({ ...d, id: d.discountId }));

        this.selectedPackageId = this.packages
          .find(p => p.id === this.selectedCycle.pricingPackages[0].packageId).id;
        await this.filterPricingPackagesBasedOnCurrency()
      }
    },
  },
  async created() {
    await Promise.all([
      this.fetchInfo(),
      this.fetchCycles(),
      this.fetchPackages(),
      this.fetchOrganization(),
      this.fetchDiscounts(),
    ]);
    await this.filterPricingPackagesBasedOnCurrency()
    this.selectedCycleId = (this.cycleId || '');
    this.loading = false;
  },
  methods: {
    async filterPricingPackagesBasedOnCurrency() {
      const asyncFilter = async (arr, predicate) => {
          const results = await Promise.all(arr.map(predicate));

          return arr.filter((_v, index) => results[index]).map(p => ({
          ...p,
          label: p.name[this.locale],
          value: p.id,
        }));
    }

    let applicablePackages = this.packages;
    if (this.selectedCycleId) {
      applicablePackages = this.packages.filter(p => {
        const packageStartDate = moment.utc(p.startDate).format('YYYY-MM-DD');
        const packageEndDate = (p.endate!==null && p.endDate !== undefined)  ? moment.utc(p.endDate).format('YYYY-MM-DD') : null;
        const validStartDate = packageStartDate < this.selectedCycle.endDate 
        const validEndDate = packageEndDate !== null ? (packageEndDate >= this.selectedCycle.startDate) : true
        return validStartDate && validEndDate
      })
    }
    this.filteredPackages = await asyncFilter(applicablePackages, async (p) => {
      const pricing =  await apis.pricings.getEffectivePricing(p.pricingDefinition.id, format((this.selectedCycle?.startDate || getNow()), LIST_SIMPLE_DATE_FORMAT, true))
      return pricing?.data?.supportedCurrencies?.includes(this.info.currency) || false 
    });
    },
    cycleDate(cycle) {
      const sd = this.$date(cycle.startDate, 'MMM DD YYYY', true);
      const ed = this.$date(subDays(cycle.endDate, 1), 'MMM DD YYYY', true);
      return `${sd} - ${ed}`;
    },
    async submitBillingCycleChange() {
      if (!this.openConfirmationModal && isBefore(this.selectedBillingCycle.startDate, getNow())) {
        this.openConfirmationModal = true;
        return;
      }
      this.executing = true;
      const req = {
        billableOrgInfoId: this.info.id,
        billingCycleId: this.selectedCycleId,
        pricingPackageId: this.selectedPackageId,
        discountIds: [
          ...this.selectedOrgDiscounts.map(d => d.id),
          ...this.selectedPackageDiscounts.map(d => d.id),
        ],
      };
      const resp = await apis.billable.updateBillableOrgInfo(this.currentOrganizationId, req);
      if (resp.ok) {
        notify.success(this.$t('monetization.success_updating_billing_info'));
        this.goBack();
      } else {
        notify.error(this.$t('monetization.error_updating_billing_info'));
      }
      this.executing = false;
    },
    changePackageDiscountTab(event) {
      this.selectedPackageDiscountTab = event;
    },
    changeOrgDiscountTab(event) {
      this.selectedOrgDiscountTab = event;
    },
    async fetchInfo() {
      const resp = await apis.billable.getBillableOrgInfo(this.currentOrganizationId);
      if (!resp.ok) {
        notify.error(this.$t('monetization.error_fetching_billing_info'));
        return;
      }
      this.info = resp.data;
    },
    async fetchCycles() {
      const resp = await apis.billable.getBillingCycles(this.currentOrganizationId, true);
      if (!resp.ok) {
        notify.error(this.$t('monetization.error_fetching_cycles'));
        return;
      }
      this.cycles = resp.data;
    },
    async fetchPackages() {
      const resp = await apis.organizations
        .fetchApplicablePricingPackages(this.currentOrganizationId);
      if (!resp.ok) {
        notify.error(this.$t('monetization.error_fetching_packages'));
        return;
      }
      this.packages = resp.data;
    },
    async fetchOrganization() {
      const resp = await apis.organizations.find(this.currentOrganizationId);
      if (!resp.ok) {
        notify.error(this.$t('monetization.error_fetching_organizations'));
      }
      this.organization = resp.data;
    },
    async fetchDiscounts() {
      const resp = await apis.discounts.list({ qs: {
        organization_id: this.currentOrganizationId,
      } });
      if (!resp.ok) {
        notify.error(this.$t('monetization.error_fetching_discounts'));
        return;
      }
      this.discounts = resp.data;
    },
    updatePackageDiscounts(discount) {
      const isSelected = this.selectedPackageDiscounts.some(d => d.id === discount.id);
      if (isSelected) {
        this.selectedPackageDiscounts = this.selectedPackageDiscounts
          .filter(d => d.id !== discount.id);
      } else {
        this.selectedPackageDiscounts.push(discount);
      }
    },
    updateOrgDiscounts(discount) {
      const isSelected = this.selectedOrgDiscounts.some(d => d.id === discount.id);
      if (isSelected) {
        this.selectedOrgDiscounts = this.selectedOrgDiscounts
          .filter(d => d.id !== discount.id);
      } else {
        this.selectedOrgDiscounts.push(discount);
      }
    },
    goBack() {
      const prev = store.getters.previousRoute.name
      if(prev === 'editOrganization'){
        this.$router.push({ name: prev, query: {s: (this.$route.query.s || '')}})
        return;
      }
      this.$router.navigateBackOrDefault('billing-overview');
    }
  },
};
</script>
<style lang="scss" scoped>
.empty-discounts {
  text-align: center;
  .empty-message {
    margin-bottom: 20px;
  }
}
.discounts-group {
  padding-top: 10px;
}

.discount-header {
  padding-bottom: 10px;
}
.selectable-list {
  padding-bottom: 5px;
}

.alert-box {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

:deep(.checkbox) {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
