import { CrudApi } from './api';

class PricingApi extends CrudApi {
  constructor() {
    super('/pricings');
  }

  getCurrencies() {
    return this.get('pricings/currencies', {});
  }

  getEffectivePricing(id, theDate) {
    return this.get(`pricings/${id}/effective`, {
      qs: {
        date: theDate,
      },
    });
  }

  addChange(pricingId, body) {
    return this.post(`pricings/${pricingId}/changes`, { body });
  }

  updateChange(pricingId, changeId, body) {
    return this.put(`pricings/${pricingId}/changes/${changeId}`, { body });
  }

  deleteChange(pricingId, changeId) {
    return this.del(`pricings/${pricingId}/changes/${changeId}`);
  }

  listChanges(pricingId) {
    return this.get(`pricings/${pricingId}/changes`);
  }


  getChange(pricingId, changeId) {
    return this.get(`pricings/${pricingId}/changes/${changeId}`);
  }
}

const pricingApi = new PricingApi();
export default pricingApi;
