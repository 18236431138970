<template>
  <button 
    :id="id" 
    ref="button" 
    tabindex="0"
    :data-mc-button-role="buttonRole ? buttonRole : ''"
    :type="type" 
    :title="$t(tooltipLabel || '')" 
    :disabled="disabled || executing" 
    :class="[type, {rounded, 'full-width': fullWidth, 'transition-button': transitionButton, transparent, disabled: disabled || executing}, size]" 
    @click="click" 
    @mouseover="onHover = true" 
    @focus="onHover = true" 
    @mouseout="onHover = false" 
    @blur="onHover = false"
  >
    <a 
      click.prevent 
      :href="href" 
      :target="newTab ? '_blank': '_self'" 
      class="fake-a"
    >
      <div>
        <div 
          v-if="executing"
          class="spinner-container" 
        >
          <base-icon icon="fa fa-cog fa-spin" />
        </div>
        <div>
          <div 
            class="button-content" 
            :class="{'has-arrow': showArrow}"
          >
            <slot></slot>
          </div>
        </div>
        <div class="arrow">
          <transition name="zoom-out">
            <base-icon 
              v-if="showArrow" 
              icon="angle-double-right" 
              class="transition-icon"
            />
          </transition>
        </div>
      </div>
    </a>
  </button>
</template>

<script>
/**
 * Button component used across the entire application
 */
export default {
  name: 'BaseButton',
  props: {
    /**
     * This is an id
     */
    id: {
      type: String,
    },
    /**
     * Type of button (button, submit, etc..)
     */
    type: {
      type: String,
      default: 'button',
    },
    /**
     * Button with rounded corners.
     */
    rounded: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: v => ['xs', 'sm', 'md', 'lg'].includes(v),
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    transitionButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, button will be disabled with a spinner.
     */
    executing: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    newTab: {
      type: Boolean,
      default: false
    },
    preventDefault: {
      type: Boolean,
      default: true,
    },
    tooltipLabel: {
      type: String,
    },
    buttonRole: {
      type: String,
    }
  },
  emits: [
    /**
     * Submitted when button is clicked
     */
    'click'
  ],
  data() {
    return {
      onHover: false,
    };
  },
  computed: {
    showArrow() {
      return this.onHover && this.transitionButton;
    },
  },
  methods: {
    click(e) {
      if (this.preventDefault && this.type !== 'submit') {
        e.preventDefault();
      }
      if (!this.disabled && !this.executing) {
        this.$emit('click', e);
        this.$refs.button.blur();
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

button {
  height: 40px;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 125px;
  border-radius: 5px;
  white-space: nowrap;
  user-select: none;
  position: relative;

  @include phone {
    :deep(.base-item .long) {
      white-space: unset;
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  > div {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &.transition-button {
    padding: 10px 30px;
  }

  &:active:not(.disabled) {
    transform: translateY(0.5px);
  }

  &.rounded {
    border-radius: 24px;
    margin: 0 2.5px 0 2.5px;
  }

  &.full-width {
    width: 100%;
  }

  &.xs {
    font-size: $form-element-font-size-xs;
    min-width: 90px;
  }

  &.sm {
    font-size: $form-element-font-size-sm;
    height: $form-element-height-sm;
  }

  &.lg {
    font-size: 16px;
  }

  &.submit, &.executable {
    .button-content {
        padding: 0px 20px;
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }

}

.button-content {
  transform: translateX(0);
  transition: transform 0.5s;
  padding-left: 5px;
  padding-right: 5px;  
  &.has-arrow {
    transform: translateX(-8px);
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.zoom-out-enter-active, .zoom-out-leave-active {
  transition: transform 0.5s;
  transform: scale(1);
}
.zoom-out-enter-from, .zoom-out-leave-to {
  transform: scale(0);
}

.spinner-container {
  position: absolute;
  left: 10px;
}

</style>
