<template>
  <div>
    <base-menu-page
      :header="header"
      :operations="operations"
      :sections="sections"
    />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ApiDocsSection",
  data() {
    return {};
  },
  computed: {
    header() {
      return {
        logo: "fa fa-code",
        title: this.$t("api_docs_customization.title"),
        subtitle: this.$t("api_docs_customization.subtitle"),
      };
    },
    sections() {
      return [];
    },
    operations() {
      return [];
    },
  },
};
</script>
