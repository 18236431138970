<template>
  <div 
    ref="dropdown_listener"
    class="autocomplete-input" 
  >
    <div 
      v-if="activateSlot && selectedItem"
      class="selected-item" 
    >
      <slot :item="selectedItem"></slot>
      <action-icon 
        icon="fa fa-close" 
        :aria-label="$t('clear')"
      />
    </div>
    <base-input 
      v-else
      ref="autocomplete_search" 
      type="text"
      role="combobox"
      :modelValue="searchTerm || modelValue"
      :placeholder="itemsMap[modelValue] || placeholder"
      :icon="icon"
      :error="error"
      @update:modelValue="handleInput"
      @focus="focus"
      @blur="blur"
      @click="focus"
      @keydown="keydown"
      @return="acceptDefault"
    ></base-input>
    <items-dropdown
      :focusDropdownElements="false"
      :loading="loading"
      direction="down"
      keydownRef="dropdown_listener"
      :error="dropdownError"
      :items="items"
      :opened="open && !!items.length"
      @close="close"
      @update:modelValue="selectItem"
    >
      <template #input>
        <!-- for mobile -->
        <base-input
          type="text"
          :modelValue="searchTerm"
          :icon="icon"
          :error="error"
          @update:modelValue="handleInput"
          @focus="focus"
          @click="focus"
          @keydown="focus"
          @return="acceptDefault"
        ></base-input>
      </template>
      <template #top>
        <slot name="top"></slot>
      </template>
      <template 
        v-if="activateSlot" 
        #default="slotProps"
      >
        <slot :item="slotProps.item"></slot>
      </template>
    </items-dropdown>
  </div>
</template>

<script>
export default {
  name: 'AutocompleteInput',
  props: {
    items: {
      type: Array,
      required: true,
    },
    allItems: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
    },
    searchTerm: {
      type: String,
      default: null,
    },
    activateSlot: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    dropdownError: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'open', 'search', 'select', 'close'],
  data() {
    return {
      open: false,
      inputFocused: false,
    };
  },
  computed: {
    itemsMap() {
      return this.allItems.reduce((m, e) => {
        m[e.value] = e.display || e.value;
        return m;
      }, {});
    },
    selectedItem() {
      if (!this.allItems || !this.modelValue) {
        return null;
      }
      return this.allItems.find(i => i.value === this.modelValue);
    },
  },
  methods: {
    acceptDefault() {
      if (this.items.length === 1) {
        this.close(this.items[0].value);
      }
    },
    handleInput(value) {
      this.$emit('search', value);
      this.$emit('update:modelValue', value);
    },
    selectItem(value) {
      this.$emit('select', value);
    },
    focus() {
      this.inputFocused = true;
      if (!this.open) {
        this.$emit('open');
      }
      this.open = true;
    },
    blur() {
      this.inputFocused = false;
    },
    keydown(event) {
      if (event && event.key === 'Tab') {
        this.open = false;
      } else {
        this.focus();
      }
    },
    close(value) {
      if (!(this.inputFocused && !value)) {
        this.open = false;
      } else {
        return;
      }
      if (value) {
        this.$emit('close', value);
        this.$emit('update:modelValue', value);
      }
    },
  },
};
</script>


<style scoped lang="scss">
.autocomplete-input {
  position: relative;

  .selected-item {
    display: flex;
    align-items: center;

    .base-item {
      padding: 0px;
    }

    .clear-button {
      margin-left: 12px;
    }
  }

:deep(.dropdown) {
    width: 100%;

    .item {
      text-align: left;
    }
     .item {
        color: #4c4c4c;
    }
  }
}
</style>
