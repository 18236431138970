import QuotasSection from './QuotasSection';
import QuotasList from './QuotasList';
import CreateQuotaForm from './CreateQuotaForm';
import QuotaPage from './QuotaPage';
import QuotaDetails from './QuotaDetails';
import AssignedOrganizations from './AssignedOrganizations';
import EditQuota from './EditQuota';

export default [
  {
    path: 'quotas',
    props: true,
    component: QuotasSection,
    meta: {
      protected: ['reseller:quotas'],
    },
    children: [
      {
        name: 'quotasList',
        path: '',
        redirect: { name: 'quotas-owned' },
      },
      {
        name: 'quotas-owned',
        path: 'owned',
        component: QuotasList,
        props: { type: 'owned' },
      },
    ],
  },
  {
    name: 'createQuota',
    path: 'quotas/owned/create',
    component: CreateQuotaForm,
  },
  {
    name: 'quotaPage',
    path: 'quotas/:id',
    meta: {
      title: () => 'quota',
    },
    component: QuotaPage,
    props: true,
    redirect: { name: 'quotaDetails' },
    children: [{
      name: 'quotaDetails',
      path: 'details',
      component: QuotaDetails,
      props: true,
    }, {
      name: 'editQuota',
      component: EditQuota,
      path: 'details/edit',
    }, {
      name: 'quotaAssignedOrganizations',
      path: 'assigned_organizations',
      component: AssignedOrganizations,
      props: ({ switchOrgRouteName: 'quotasList' }),
    }],
  },
];
