<template>
  <div class="base-checkboxes">
    <base-checkbox 
      v-for="option in options" 
      :key="option.name" 
      :disabled="disabled" 
      :modelValue="valueSet.has(option.value)" 
      :label="option.name" 
      :description="option.description" 
      class="base-checkbox"
      @update:modelValue="input(option.value, $event)" 
    />
  </div>
</template>

<script>
export default {
  name: 'BaseCheckboxes',
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    valueSet() {
      return new Set(this.modelValue);
    },
  },
  methods: {
    input(value, checked) {
      if (checked) {
        this.valueSet.add(value);
      } else {
        this.valueSet.delete(value);
      }
      const newValue = Array.from(this.valueSet);
      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>

<style scoped lang="scss">
.base-checkbox {
    margin-top: 5px;
}
</style>
