
import { CrudApi } from './api';
import utils from '../httputils';

class ReportsApi extends CrudApi {
  getOrganizationPricing(startDate, endDate, organizationId, connectionId, environmentId,
    language, currency, csvFormat = false) {
    const qs = {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
      service_connection_id: connectionId,
      environment_id: environmentId,
    };
    const options = { qs };

    if (csvFormat) {
      qs.language = language;
      qs.currency = currency;

      options.headers = { [utils.ACCEPT_HEADER]: utils.TEXT_CSV };
      options.responseType = 'blob';
    }

    return this.get('/reports/organization_pricing', options);
  }

  getCustomersPricing(startDate, endDate, organizationId, connectionId, includeOrgsWithoutCost) {
    return this.get('/reports/customers', {
      qs: {
        start_date: startDate,
        end_date: endDate,
        organization_id: organizationId,
        service_connection_id: connectionId,
        include_organizations_without_cost: includeOrgsWithoutCost
      },
    });
  }

  getCustomerGrowth(startDate, endDate, organizationId) {
    return this.get('/reports/growth', {
      qs: {
        start_date: startDate,
        end_date: endDate,
        organization_id: organizationId,
      },
    });
  }

  getCustomerRevenue(billingCycle, language, organizationId) {
    return this.get('/reports/customers/revenue_tax_report', {
      qs: {
        language,
        billing_cycle: billingCycle,
        organization_id: organizationId,
      },
      responseType: 'blob',
    });
  }

  getCustomerOverview(language, organizationId, startDate, endDate, connectionId) {
    return this.get('/reports/customers/overview_report', {
      qs: {
        language,
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        service_connection_id: connectionId,
      },
      responseType: 'blob',
    });
  }

  getNativePricing(connectionId, orgId, startDate, endDate) {
    return this.get(`/reports/native_pricing/${connectionId}`, {
      qs: {
        organization_id: orgId,
        start_date: startDate,
        end_date: endDate,
      }
    });
  }

  getDailyCostOverview(groupBy, organizationId, connectionId, environmentId, currentDate, comparedToDate, locale) {
    let maxCostGroups = 0;
    if (groupBy === "PRODUCT_CATEGORY"){
      maxCostGroups = 7;
    }
    return this.get(`/reports/daily_cost_overview/${organizationId}`, {
      qs: {
        groupBy: groupBy,
        connectionId: connectionId,
        environmentId: environmentId,
        currentDate: currentDate,
        comparedToDate: comparedToDate,
        locale: locale,
        maxCostGroups: maxCostGroups
      },
    });
  }

  getDailyCostActivity(organizationId, qs) {
    return this.get(`/reports/daily_cost_overview/activity/${organizationId}`, qs);
  }

  getInventoryCapacity(serviceType, reportName, organizationId) {
    return this.get(`/infra/reports/${serviceType}/capacity/${reportName}`, {
      qs:{
        organizationId
      }
    });
  }
}

const reportsApi = new ReportsApi();

export default reportsApi;
