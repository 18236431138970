/**
 * Next-gen components
 */
import { App } from 'vue';
import ButtonComponents from './buttons';
import DisplayComponents from './display';
import LayoutComponents from './layout';
import MiscComponents from './misc';
import TypographyComponents from './typography';
import NavigationComponents from './navigation';
import InputComponents from './inputs';
import OverlayComponents from './overlays';
import FormComponents from './form';
import SdkComponents from './sdk';
import ChartComponents from './charts';

export default {
  install(app: App) {
    Object.entries({
      ...ButtonComponents,
      ...DisplayComponents,
      ...LayoutComponents,
      ...MiscComponents,
      ...TypographyComponents,
      ...NavigationComponents,
      ...InputComponents,
      ...OverlayComponents,
      ...FormComponents,
      ...SdkComponents,
      ...ChartComponents
    }).forEach(e => app.component(e[0], e[1]));
  },
};
