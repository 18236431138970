import { CrudApi } from './api';

const ROOT_CONTEXT = '/content/categories';
class NotificationCategoriesApi extends CrudApi {
  constructor() {
    super(ROOT_CONTEXT);
  }
}
const notificationCategories = new NotificationCategoriesApi();
export default notificationCategories;
