import BaseRoute from '@/app/components/BaseRoute';
import { init, loadLanguageAsync } from '@/i18n';
import { LANGUAGE_ENTRY_POINT } from '.';
import TrialRegistration from './TrialRegistration';
import TrialActivateExtendByEmail from './TrialActivateExtendByEmail';

export default [{
  path: '',
  component: BaseRoute,
  children: [
    {
      path: 'extend/:trialId',
      component: TrialActivateExtendByEmail,
      props: function dynamicPropsFn(route) {
        return {
          trialId: route.params.trialId,
          trialToken: route.query.trialToken,
          action: 'extension',
        };
      },
    },
    {
      path: 'activate/:trialId',
      component: TrialActivateExtendByEmail,
      props: function dynamicPropsFn(route) {
        return {
          trialId: route.params.trialId,
          trialToken: route.query.trialToken,
          action: 'activation',
        };
      },
    },
    {
      name: 'trialRegistration',
      path: '',
      component: TrialRegistration,
    },
    {
      path: ':trial(.*)',
      component: TrialRegistration,
    },
  ],

  async beforeEnter(to, from, next) {
    const host = window.location.host.split('.');
    const language = LANGUAGE_ENTRY_POINT[host[0]];
    await init(language);
    await loadLanguageAsync('trial');
    return next();
  },
}];
