<template>
  <div 
    :id="`viewer-${id}`" 
    :class="[{ boxed },'markdown-viewer']" 
  ></div>
</template>
<script>
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { v4 as uuidv4 } from 'uuid';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

export default {
  name: 'MarkdownViewer',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
  watch: {
    modelValue() {
      this.viewer.setMarkdown(this.modelValue);
    },
  },
  mounted() {
    this.viewer = new Viewer({
      el: document.querySelector(`#viewer-${this.id}`),
      initialValue: this.modelValue,
    });
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/mixins.scss';

:deep(.toastui-editor-contents) {
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.boxed {
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 20px 20px;
  max-height: 400px;
  overflow: auto;
}
</style>
