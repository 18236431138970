<template>
  <div class="input-control">
    <div class="input">
      <div 
        v-if="prefix" 
        class="prefix side-label unit-border plain-bg"
      >
        {{ $t(prefix) }}
      </div>
      <base-icon 
        v-if="icon" 
        :icon="icon" 
        :class="['left', { 'has-value': modelValue || focus }, size, 'input-icon-' + cursor]" 
        customizable 
        @click="toggleFocusInput"
      />
      <svg-icon 
        v-if="svgIcon" 
        :icon="svgIcon"
        :class="['left', svgIcon, { 'has-value': modelValue || focus }, size, 'input-icon-' + cursor]" 
      />
      <input 
        :id="id"
        ref="input"
        size="1"
        :name="name"
        :type="inputType"
        :aria-label="modelValue"
        :placeholder="$te(placeholder) ? $t(placeholder) : placeholder"
        :readonly="readonly"
        :maxlength="maxLength"
        :disabled="disabled"
        :value="modelValue"
        :class="inputClass"
        @input="change"
        @focus="onFocus"
        @blur="onBlur"
        @click="onClick"
        @keydown="onKeydown"
        @keyup.enter="onReturn"
        @keypress="inputType === 'number' ? filterOnlyNumeric($event) : () => null"
      />
      <base-icon 
        v-if="isPassword" 
        :icon="showPasswordField ? 'fa fa-eye' : 'fa fa-eye-slash'" 
        :class="['fa right',size]"
        :tabindex="0"
        aria-hidden="true"
        :aria-label="$t(sensitiveIconLabel)" 
        customizable
        @click="togglePasswordVisibility" 
        @keydown.enter="togglePasswordVisibility"
        @keydown.space="togglePasswordVisibility"
      />
      <action-icon 
        v-if="inputIsClearable && !isClearableSvg" 
        icon="fa fa-times" 
        :class="['right', 'close', size]" 
        @click="clearValue"
      />
      <div
        v-if="inputIsClearable && isClearableSvg"
        class="clear-icon-svg"
        @click="clearValue"
      >
        <svg-icon 
          icon="search-close-icon"
          class="close-icon" 
        />
      </div>
     
      <div
        v-if="suffix" 
        :title="suffixLabel" 
        :class="['suffix side-label unit-border plain-bg', size]"
      >
        {{ suffixLabel }}
      </div>
    </div>
    <div 
      v-if="typeof error === 'string' && error"
      class="error-label error red" 
    >
      {{ error }}&nbsp;
    </div>
  </div>
</template>

<script>
const TEXT_INPUT = 'text';
const PASSWORD_INPUT = 'password';
const NUMBER_INPUT = 'number';

export default {
  name: 'BaseInput',
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [ String, Number, Date ],
      required: false,
    },
    type: {
      type: String,
    },
    icon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    rounded: {
      type: Boolean,
    },
    size: {
      type: String,
      default: 'md',
      validator: v => ['sm', 'md', 'lg'].includes(v),
    },
    transparent: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    decimal: {
      type: Boolean,
      default: true,
    },
    maxLength: {
      type: Number,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: 'text',
    },
    readonly: {
      type: Boolean,
    },
    alignment: {
      type: String,
      default: 'left',
      validator: v => ['left', 'center', 'right'].includes(v),
    },
    softBorder: {
      type: Boolean,
      default: false
    },
    svgIcon: {
      type: String
    },
    isClearableSvg: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'update:modelValue', 'focus', 'blur', 'return', 'keydown'],
  data() {
    return {
      focus: false,
      showPasswordField: false,
      sensitiveIconLabels: ["revealPassword", "hidePassword"],
    };
  },
  computed: {
    inputIsClearable() {
      return this.clearable && this.modelValue !== '' && this.modelValue !== null && this.modelValue !== undefined;
    },
    errorLabel() {
      return typeof this.error === 'string' ? this.error : '';
    },
    inputType() {
      if (!this.isPassword) {
        return this.type;
      }
      return this.showPasswordField ? TEXT_INPUT : PASSWORD_INPUT;
    },
    isPassword() {
      return this.type === PASSWORD_INPUT;
    },
    wideIcons() {
      return ['address', 'key'];
    },
    iconClass() {
      if (this.icon && this.wideIcons.some(wide => this.icon.includes(wide))) {
        return { 'has-wide-icon': this.icon };
      }
      return { 'has-icon': this.icon || this.svgIcon };
    },
    sensitiveIconLabel() {
      return this.sensitiveIconLabels[this.showPasswordField ? 1 : 0];
    },
    inputClass() {
      return [
        {
          ...this.iconClass,
          'has-value': this.modelValue,
          'has-clear': this.clearable,
          'has-prefix': this.prefix,
          'has-suffix': this.suffix,
          'password-padding': this.isPassword,
          'soft-border': this.softBorder,
          disabled: this.disabled,
          error: this.error,
          rounded: this.rounded,
          transparent: this.transparent,
        },
        this.size,
        this.alignment,
      ];
    },
    suffixLabel() {
      const found = this.$te(this.suffix);
      return found ? this.$t(this.suffix) : this.suffix;
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    filterOnlyNumeric(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'e', 'E', '-'];
      if (!this.decimal) {
        keysAllowed.splice(keysAllowed.indexOf('.'), 1);
      }

      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    change(e) {
      let emitValue = e.target.value.trim();
      if(this.type === NUMBER_INPUT && emitValue === "") {
        emitValue = null;
      }
      this.$emit('update:modelValue', emitValue);
    },
    onFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    onBlur(e) {
      this.focus = false;
      this.$emit('blur', e.target.value.trim());
    },
    onClick() {
      this.focus = true;
      this.$emit('click');
    },
    onKeydown(event) {
      this.$emit('keydown', event);
    },
    onReturn() {
      this.$emit('return');
    },
    clearValue() {
      this.$emit('update:modelValue', '');
      this.$refs.input.focus();
    },
    togglePasswordVisibility() {
      this.showPasswordField = !this.showPasswordField;
    },
    toggleFocusInput() {
      this.$refs.input.click();
      this.$refs.input.focus();
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/variables.scss';

.input {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.input-control {
  flex: 1;
}

.side-label {
  padding: 8px 20px;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  text-align: center;
}

.prefix {
  height: $form-element-height-md;
  box-sizing: border-box;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-right-width: 0;
}

.suffix {
  height: $form-element-height-md;
  box-sizing: border-box;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-left-width: 0;

  &.lg {
    font-size: $form-element-font-size-lg;
    height: $form-element-height-lg;
  }

  &.md {
    height: $form-element-height-md;
  }

  &.sm {
    font-size: $form-element-font-size-sm;
    height: $form-element-height-sm;
  }
}

input {
  height: $form-element-height-md;
  font-size: 1rem;
  border-radius: 3px;
  flex: 1;
  flex-basis: auto;
  padding: 10px 12px;
  border-width: 1px;
  border-style: solid;
  transition: border-color 1s;
  box-sizing: border-box;
  margin-left: 0;

  &.soft-border {
    border-color: var(--plain-dark);
  }

  &.transparent {
    background-color: transparent;
    border-width: 2px;
  }

  &.lg {
    font-size: $form-element-font-size-lg;
    height: $form-element-height-lg;
  }

  &.md {
    height: $form-element-height-md;
  }

  &.sm {
    font-size: $form-element-font-size-sm;
    height: $form-element-height-sm;
  }

  &.has-icon {
    padding-left: 40px;
  }

  &.has-wide-icon {
    padding-left: 45px;
  }

  &.has-clear {
    padding-right: 40px;
  }

  &.rounded {
    border-radius: 18px;
  }

  &.has-suffix {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.has-prefix {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.password-padding {
    padding-right: 40px;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

em,
.close {
  font-size: 20px;
  position: absolute;

  &.lg {
    font-size: 25px;
  }

  &.sm {
    font-size: 16px;
  }
}

em.left,
svg.left {
  left: 15px;
}

em.right,
svg.right,
.close.right {
  right: 15px;
  transform: scale(0.8);
  cursor: pointer;
}

em.input-icon-text,
svg.input-icon-text {
  cursor: text;
}

em.input-icon-default,
svg.input-icon-default {
  cursor: default;
}

em.input-icon-pointer,
svg.input-icon-pointer {
  cursor: pointer;
}

svg.left {
  position: absolute;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-label {
  margin-top: 4px;
}
.clear-icon-svg {
  height: 1.125rem;
  width: 1.125rem;
  position: absolute;
  right: 0.688rem;
}
.close-icon {
  height: 0.625rem;
  width: 0.625rem;
  margin: 0.25rem;
}
</style>
