function computeUsageInTier(usage, tier) {
  // PER_UNIT case
  let usageInTier;
  if (!usage || usage < tier.lowerBound) {
    usageInTier = 0;
  } else if (tier.upperBound && usage > tier.upperBound) {
    usageInTier = tier.upperBound - tier.lowerBound;
  } else {
    usageInTier = usage - tier.lowerBound;
  }

  if (tier.pricingMode === "FLAT_FEE") {
    if (tier.chunkSize >= 0) {
      // CHUNKED pricing case
      usageInTier = Math.ceil(usageInTier/tier.chunkSize);
    } else {
      // FLAT_FEE case
      // need to take into account if usage near 0
      usageInTier = Math.abs(usageInTier) <= 1e-6 ? 0 : 1; // floating point
    }
  }
  return usageInTier;
}

export {
  computeUsageInTier
}
