import store from '@/store';

const handle = ({ windowId, targetUrl, windowName }) => {
  if (windowId === store.getters.windowId) {
    window.open(targetUrl, windowName);
  }
};

export default {
  handle,
};