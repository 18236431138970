<template>
  <div class="contact-support-page">
    <base-breadcrumb :items="breadcrumb"></base-breadcrumb>
    <div v-if="emailSent">
      <empty-message 
        icon="fa fa-tick" 
        textLabel="ticket_sent_confirmation"
        buttonIcon="fa fa-question-circle" 
        buttonLabel="help_center_title" 
        :buttonRoute="{ path: '/hc' }"
      >
      </empty-message>
    </div>
    <base-form 
      v-else 
      :submitLabel="$t('submit')" 
      :defaultBack="{path:'/hc'}" 
      :disabled="submitDisabled" 
      :executing="executing" 
      @submit="submitTicket"
    >
      <h2>{{ $t('ticket_title') }}</h2>
      <form-row label="ticket_subject">
        <base-input 
          v-model="ticketSubject"
          icon="fa fa-comment" 
        ></base-input>
      </form-row>
      <form-row label="ticket_priority">
        <base-select 
          :items="priorities" 
          :modelValue="defaultPriority" 
          @update:modelValue="ticketPriority = $event"
        ></base-select>
      </form-row>
      <form-row label="ticket_description">
        <base-text-area v-model="ticketContent"></base-text-area>
      </form-row>
    </base-form>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import FormRow from '@/components/form/FormRow';
import BaseInput from '@/components/input/BaseInput';
import BaseTextArea from '@/components/BaseTextArea';
import BaseBreadcrumb from '@/components/BaseBreadcrumb';
import EmptyMessage from '@/components/EmptyMessage';
import BaseSelect from '@/components/BaseSelect';
import { mapGetters } from 'vuex';

export default {
  name: 'ContactSupportPage',
  components: { BaseSelect, EmptyMessage, BaseBreadcrumb, BaseTextArea, BaseInput, FormRow },

  data() {
    return {
      ticketSubject: '',
      ticketContent: '',
      ticketPriority: '',
      emailSent: false,
      executing: false,
      priorities: [{
        label: 'ticket_normal',
        value: 'NORMAL',
      }, {
        label: 'ticket_urgent',
        value: 'URGENT',
      }, {
        label: 'ticket_question',
        value: 'QUESTION',
      }],
    };
  },

  computed: {
    ...mapGetters(['selectedOrganization']),
    breadcrumb() {
      return [
        {
          label: 'help_center_title',
          to: { path: '/hc' },
        },
        {
          label: 'support',
        },
      ];
    },
    defaultPriority() {
      return this.ticketPriority || this.priorities[0].label;
    },
    submitDisabled() {
      return (this.ticketSubject === '' || this.ticketContent === '');
    },
  },

  methods: {
    async submitTicket() {
      this.executing = true;

      const emailDetails = {
        priority: this.ticketPriority,
        subject: this.ticketSubject,
        content: this.ticketContent,
      };

      const resp = await apis.support
        .sendJiraServiceDeskEmail(emailDetails, this.selectedOrganization.id);
      this.executing = false;

      if (resp && resp.status === 200) {
        this.emailSent = true;
      } else {
        notify.error(this.$t('error_sending_support_email'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .contact-support-page {
    width: 100%;
  }

  :deep(.input) {
    text-align: left;
  }

  :deep(.breadcrumb) {
    margin-bottom: 40px;
  }

  h2 {
    padding-bottom: 20px;
    display: flex;
  }

  :deep(textarea) {
    font-family: unset;
    font-size: 16px;
  }
</style>
