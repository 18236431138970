<template>
  <div>
    <form-row label="monetization.commitments.committed_products">
      <base-tabs
        light
        :tabs="categoryIds"
        :selected="defaultOrSelectedCategory"
        @tabChange="changeCategory"
      >
      </base-tabs>
      <base-list
        aria-labelledby="committed_products"
        :aria-describedby="description"
        noActions
        :list="productTuples"
        :ariaRowCount="productTuples.length"
      >
        <list-header>
          <list-column :size="1.5">
            {{ $t('monetization.commitments.product') }}
          </list-column>
          <list-column>{{ $t('monetization.commitments.committed_amount') }}</list-column>
          <list-column v-if="isDiscount">
            {{ $t('monetization.commitments.discount') }}
          </list-column>
          <list-column v-if="isDiscount">
            {{ $t('monetization.commitments.discounted_price') }}
          </list-column>
          <list-column v-if="isDiscount">
            {{ $t('monetization.commitments.discounted_cost') }}
          </list-column>
        </list-header>
        <list-row
          v-for="[committedProduct, pricedProduct] in productTuples"
          :key="committedProduct.id"
        >
          <list-column :size="1.5">
            <div class="flex-box-row">
              {{ pricedProduct.name[locale] }}
              <base-badge
                v-if="committedProduct.deprecated"
                class="padding-left-badge"
                :state="'monetization.commitments.not_applicable'"
                :color="gray"
              ></base-badge>
            </div>
            <div class="description">
              {{ pricedProduct.sku }}
            </div>
          </list-column>
          <list-column>
            <div>{{ committedProduct.committedAmount }}</div>
            <div class="description">
              <span v-if="committedProduct.product.unit !== 'UNIT' || !periodLabel(committedProduct)">{{ unitLabel(committedProduct) }}<span v-if="periodLabel(committedProduct)">-</span></span>{{ periodLabel(committedProduct) }}
            </div>
          </list-column>
          <list-column v-if="isDiscount">
            <div>{{ committedProduct.discountAmount }}{{ $t('%') }}</div>
          </list-column>
          <list-column v-if="isDiscount">
            <div class="flex-box-row">
              {{ getDiscountedPrice(committedProduct) }}
              <committed-product-tier-tooltip
                v-if="hasTiers(committedProduct)"
                class="padding-left-tooltip"
                isDiscount
                :estimate="getEstimate(committedProduct)"
              />
            </div>
            <div class="description">
              {{ $t('reports.per') }}
              <span v-if="committedProduct.product.unit !== 'UNIT' || !periodLabel(committedProduct)">{{ unitLabel(committedProduct) }}<span v-if="periodLabel(committedProduct)">-</span></span>{{ periodLabel(committedProduct) }}
            </div>
          </list-column>
          <list-column v-if="isDiscount">
            <div>{{ getDiscountedCost(committedProduct) }}</div>
          </list-column>
        </list-row>
        <template #empty>
          <empty-message
            icon="fa fa-money"
            textLabel="monetization.commitments.no_committed_products"
          />
        </template>
      </base-list>
    </form-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { currencyMixin } from '@/mixins/currencyMixin';
import CommittedProductTierTooltip from './form/CommittedProductTierTooltip'

export default {
  name: 'CommittedProductList',
  components: { CommittedProductTierTooltip },
  mixins: [currencyMixin],
  props: {
    commitment: {
      type: Object,
      required: true,
    },
    isDiscount: {
      type: Boolean,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    pricing: {
      type: Object,
      required: true,
    },
    billingCycle: {
      type: Object,
      required: true,
    },
    fixedRateCommittedProductEstimations: {
      type: Object,
    },
    variableRateCommittedProductEstimations: {
      type: Object,
    },
  },
  data() {
    return {
      description: 'monetization.committed_products.list_desc',
      selectedCategory: ''
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    committedProducts() {
      const pricingProducts = this.pricing.pricingProducts;
      const decoratedCommittedProducts = [];
      this.commitment.committedProducts.forEach(cp => {
        const pricedProduct = pricingProducts.find(pp => pp.product.id === cp.product.id);
        const isDeprecated = pricedProduct ? pricedProduct.deprecated : true;
        const decoratedCommittedProduct = {
          ...cp,
          deprecated: isDeprecated
        };
        decoratedCommittedProducts.push(decoratedCommittedProduct);
      });

      return decoratedCommittedProducts.sort((x, y) => {
        return Number(x.deprecated) - Number(y.deprecated) ||
          x.product.name[this.locale].localeCompare(y.product.name[this.locale]);
      });
    },
    defaultOrSelectedCategory() {
      return this.selectedCategory || (this.categoryIds[0] || {}).value;
    },
    categoryIds() {
      const currentCatIds = this.committedProducts.map(p => this.getProduct(p))
        .filter(pp => !!pp)
        .filter(pp => !!pp.categoryId)
        .map(pp => pp.categoryId);
      const ids =  [...new Set(currentCatIds)]
        .map((cat) => {
          const productCatalogs = this.pricing.productCatalogs || [{ categories: [] }];
          return productCatalogs.map(pc => pc.categories).flat(1)?.find(c => c.id === cat) || []
        })
        .filter(c => !!c)
        .map(cat => ({
          name: cat.id,
          label: cat.name[this.locale],
          value: cat.id,
        }))
        .sort((a,b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
        return ids;
    },
    productTuples() {
      const value = this.committedProducts
        .map(pp => ([pp, this.getProduct(pp)]))
        .filter(tup => !!tup[0] && !!tup[1])
        .filter(tup => tup[1].categoryId === this.defaultOrSelectedCategory);
        return value;
    },
    isFixedRate() {
      return this.commitment.rateType === 'FIXED_RATE';
    },
    isVariableRate() {
      return this.commitment.rateType === 'VARIABLE_RATE';
    },
  },
  watch: {
    categoryIds(updatedCategories) {
      if (updatedCategories &&
        !this.updatedCategories.some(c => c.value === this.selectedCategory)) {
        this.selectedCategory = this.updatedCategories[0].value;
      }
    },
  },
  methods: {
    unitLabel(cp) {
      if (cp.product.unit.unit) {
        return this.$t(`units.${cp.product.unit.unit.toLowerCase()}`);
      }
      return cp.product.unit.name[this.locale];
    },
    periodLabel(cp) {
      return cp.product.period ? this.$t(`period.${cp.product.period.toLowerCase()}`) : '';
    },
    hasTiers(cp) {
      const estimate = this.isFixedRate ? this.fixedRateCommittedProductEstimations[cp.product.id] || {} :
        this.variableRateCommittedProductEstimations[cp.product.id] || {};
      return estimate.hasTiers;
    },
    getEstimate(cp) {
      const fakeEstimate = {
        unitPrice: 0,
        discountCost: 0,
        discountUnitPrice: 0,
      };
      return this.isFixedRate ? this.fixedRateCommittedProductEstimations[cp.product.id] || fakeEstimate :
        this.variableRateCommittedProductEstimations[cp.product.id] || fakeEstimate;
    },
    getProduct(committedProduct) {
      const allProds = this.pricing.productCatalogs?.map(pc => pc.products).flat(1) || [];
      return allProds.find(p => p.id === committedProduct.product.id);
    },
    getDiscountedPrice(committedProduct) {
      const discountPrice = this.isFixedRate ?
        this.fixedRateCommittedProductEstimations[committedProduct.product.id].discountUnitPrice :
        this.variableRateCommittedProductEstimations[committedProduct.product.id].discountUnitPrice;

      const price = discountPrice || 0;
      return this.formatShortCurrency(price, this.billingCycle.currency);
    },
    getDiscountedCost(committedProduct) {
      const discountCost = this.isFixedRate ?
        this.fixedRateCommittedProductEstimations[committedProduct.product.id].discountCost :
        this.variableRateCommittedProductEstimations[committedProduct.product.id].discountCost;

      const cost = discountCost || 0
      return this.formatShortCurrency(cost, this.billingCycle.currency);
    },
    changeCategory(event) {
      this.selectedCategory = event.value;
    },
  },
};
</script>

<style lang="scss" scoped>

.flex-box-row {
  display: flex;
}

:deep(.state .rectangle) {
  font-size: 10px;
}

.padding-left-badge {
  padding-left: 8px;
}


.padding-left-tooltip {
  padding-left: 4px;
}
</style>
