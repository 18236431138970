import { mapGetters, mapActions } from 'vuex';

export const organizationSwitchMixin = {
  computed: {
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapActions([
      'changeOrganization',
    ]),
    async changeOrgAndRedirect(org, path, customQueryParams) {
      await this.changeOrganization(org);
      let toRoute = null;
      let queryParams = customQueryParams ? customQueryParams : { ...this.$route.query };
      queryParams.org = org.entryPoint;
      
      if (this.$route.path === '/reporting/daily-cost-overview'){
        queryParams = {
          usage_for: org.id
        }
      }

      if (path) {
        toRoute = {
          path,
          query: queryParams,
          params: this.$route.params,
        }
      } else {
        toRoute = {
          path: this.$route.path,
          query: queryParams,
          params: this.$route.params,
        }
      }

      const toMatch = this.$router.resolve(toRoute);

      const { changeOrgRedirect } = (toMatch.matched
        .find(m => m.meta && m.meta.changeOrgRedirect) || {}).meta || {};

      if (changeOrgRedirect) {
        const isFunction = changeOrgRedirect instanceof Function;
        const path = isFunction ? changeOrgRedirect(this.$route.params) : changeOrgRedirect;
        this.$router.push({ ...toRoute, path });
      } else {
        this.$router.push(toRoute);
      }
    },
  },
};
