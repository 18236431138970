import { CrudApi } from './api';

class PricingPackageApi extends CrudApi {
  constructor() {
    super('/pricing_packages');
  }
  getDiscounts(id, credit) {
    return credit ?
      this.get(`pricing_packages/${id}/discounts`, { qs: { type: 'CREDIT' } }) :
      this.get(`pricing_packages/${id}/discounts`, { qs: { type: 'PERCENTAGE' } });
  }
  getDiscountsOfBothType(id) {
    return this.get(`pricing_packages/${id}/discounts`);
  }
  addDiscount(id, body) {
    return this.post(`pricing_packages/${id}/discounts`, { body });
  }
  deactivateDiscount(pricingPackageId, discountId) {
    return this.put(`pricing_packages/${pricingPackageId}/discounts/${discountId}/deactivate`);
  }
  deleteDiscount(pricingPackageId, discountId) {
    return this.del(`pricing_packages/${pricingPackageId}/discounts/${discountId}`);
  }
  getDiscount(pricingPackageId, id) {
    return this.get(`pricing_packages/${pricingPackageId}/discounts/${id}`);
  }
  editDiscount(pricingPackageId, id, body) {
    return this.put(`pricing_packages/${pricingPackageId}/discounts/${id}`, { body });
  }
  isDeletable(pricingPackageId) {
    return this.get(`pricing_packages/${pricingPackageId}/isDeletable`);
  }
}

const pricingPackageApi = new PricingPackageApi();
export default pricingPackageApi;
