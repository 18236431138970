/**
 * A helper function used to sort arrays
 * @param {Array} fns defines a list of comparator functions
 * 
 * Given fns = [f1, f2, ..., fn], the array will be sort by f1, then by f2, etc.
 * 
 * This is a stable sort.
 * 
 * Example:
 *
 * Given an array of objects
 * users = [
 *  {name: "John von Neumann", dateOfBirth: "1957-03-15" },
 *  {name: "Isaac Newton", dateOfBirth: "1643-01-04" },
 * ];
 *
 * We want to sort by age (descending) and then by name
 * sortMultiBy(users, [
 *  (a,b) => b.dateOfBirth.localeCompare(a.dateOfBirth), // reverse
 *  (a,b) => a.name.localeCompare(b.name)
 * ]);
 *
 * Equivalent using built-in sort method
 * users.sort((a,b) => {
 *  // compare age first
 *  if (a.dateOfBirth !== b.dateOfBirth) {
 *    return b.dateOfBirth.localeCompare(a.dateOfBirth); // reverse
 *  }
 *
 *  // compare name second
 *  return a.name.localCompare(b.name);
 * });
 */
const sortMultiBy = (arr, fns) => {
  return [...arr].sort((a, b) => {
    for (const fn of fns) {
      const c = fn(a, b);
      if (c !== 0) {
        return c;
      }
    }
    return 0;
  });
};

export { sortMultiBy };
