<template>
  <div class="qr-code"></div>
</template>

<script>
import QRCode from 'qrcode-js-package';

export default {
  name: 'QRCode',
  props: {
    url: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    }
  },
  data() {
    return {
      qrCode: null,

    };
  },
  mounted() {
    const width = this.width;
    const height = this.height;
    this.qrCode = new QRCode(this.$el, {
      text: this.url,
      width: width,
      height: height,
    });
  },
};
</script>
