<template>
  <base-loader v-if="loading" />
  <discount-form
    v-else
    :credit="isCredit"
    :title="$t(`monetization.discounts.${type}.add`)"
    :packages="packagesForCycle"
    :pricingPackage="selectedPricingPackage"
    :effectivePricing="effectivePricing"
    :minStartDate="minStartDateValue"
    :isCustomDiscount="true"
    @submit="addDiscount"
    @startUpdated="fetchEffectiveCyclesAndPricings"
    @packageUpdated="updatePackage"
  ></discount-form>
</template>

<script>

import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { getDate, getMin, getNow, startOfDay } from '@/utils/dates';
import { pricingMixin } from '@/mixins/pricing';
import DiscountForm from '@/app/Main/Administration/monetization/pricingPackage/DiscountForm.vue';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';

export default {
  name: 'AddOrganizationDiscount',
  components: { DiscountForm },
  mixins: [pricingMixin, billingCycleMixin],
  data() {
    return {
      loading: false,
      billingCycle: {},
      effectivePricing: {},
      selectedPricingPackageId: null,
      selectedPricingPackage: {},
      packagesForCycle: [],
      allCyclesForOrg: [],
    };
  },
  computed: {
    ...mapGetters(['locale']),
    pricingPackagesForRange() {
      return this.billingCycles ?
        new Set(this.billingCycles.flatMap(cycle => cycle.pricingPackages)
          .map(pp => pp.packageId)) : [];
    },
    firstPackageId() {
      return this.pricingPackagesForRange && this.pricingPackagesForRange.size > 0 ?
        [...this.pricingPackagesForRange][0] : {};
    },
    organizationId() {
      return this.$route.params.id;
    },
    isCredit() {
      return this.$route.path.includes('credit');
    },
    type() {
      return this.isCredit ? 'credit' : 'percentage';
    },
    minStartDateValue() {
      return this.allCyclesForOrg.reduce((earliestStartDate, currentCycle) => {
        if (this.isOngoing(currentCycle)) {
          const cycleStart = getDate(currentCycle.startDate, true);
          return getMin(cycleStart, earliestStartDate);
        }
        return earliestStartDate;
      }, startOfDay(getNow(true), true));
    },
  },
  async created() {
    const today = startOfDay(getNow(true), true);
    await this.fetchEffectiveBillingCycles(today);
    await this.fetchPricingPackages();
    await this.fetchEffectivePricing(today);
  },
  methods: {
    async fetchPricingPackages() {
      const resp = await apis.organizations
        .fetchPackagesForBillingCycle(this.organizationId, this.billingCycle.id);
      if (resp && resp.status !== 200) {
        notify.error('monetization.error_list_pricing_package');
        return;
      }
      const pricingPackages = resp.data.map(pp => ({
        ...pp,
        display: pp.name[this.locale],
        value: pp.id,
      }));
      this.packagesForCycle = pricingPackages;
      if (this.packagesForCycle.length > 0) {
        this.selectedPricingPackage = this.packagesForCycle[0];
      }
    },
    async fetchEffectiveBillingCycles(start) {
      const resp = await apis.billable.getBillingCycles(this.organizationId, true);
      if (resp && resp.status !== 200) {
        notify.error(this.$t('error_fetching_cycles'));
        return;
      }
      this.allCyclesForOrg = resp.data;
      this.billingCycle = this.getCycleWithinRangeOrLatest(this.allCyclesForOrg, start);
    },
    async addDiscount(discount) {
      const resp = await apis.organizations.addDiscount(this.organizationId, discount);
      if (resp && resp.status === 200) {
        notify.success(this.$t('monetization.discounts.'.concat(this.type, '.added'), { name: discount.name[this.locale] }));
      } else if (this.credit) {
        notify.error(this.$t('monetization.discounts.errors.create_credit'));
      } else {
        notify.error(this.$t('monetization.discounts.errors.create_discount'));
      }
      this.$router.navigateBackOrDefault({ name: 'org-billing' });
    },
    async updatePackage(pricingPackage, effectiveStartDate) {
      this.selectedPricingPackageId = pricingPackage;
      const selectedPackage = this.packagesForCycle
        .find(pp => pp.id === this.selectedPricingPackageId);
      this.selectedPricingPackage = selectedPackage;
      await this.fetchEffectivePricing(effectiveStartDate);
    },
    async fetchEffectivePricing(date) {
      this.effectivePricing = await this.fetchEffectivePricingForDate(date,
        this.selectedPricingPackage, 'org-billing');
    },
    async fetchEffectiveCyclesAndPricings(start) {
      await this.fetchEffectiveBillingCycles(getDate(start, true));
      await this.fetchPricingPackages();
      await this.fetchEffectivePricing(start);
    },
  },
};
</script>
