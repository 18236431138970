<template>
  <div v-if="commitment.terminated == false">
    <div class="action-container">
      <action-icon
        v-if="commitment.actionsMetadata.updatable"
        icon="fa fa-edit"
        expandOnHover
        tooltipLabel="commitment_actions.edit_commitment"
        :to="`/admin/organizations/${organization.id}/billing/overview/commitments/${commitment.id}/details/edit`"
      />
      <action-dropdown
        :hideIcon="hideIcon"
        :open="open"
        :items="actions"
        @select="confirmAction"
        @close="$emit('close')"
      />
    </div>
    <confirm-modal
      class="confirm-modal"
      headerIcon="fa fa-check-circle"
      :open="openConfirmDelete"
      :headerLabel="actionContext.title"
      :headerLabelInterpolation="commitmentName"
      :detailsLabel="actionContext.label"
      :detailsInterpolation="commitmentDetails"
      :executing="executing"
      :confirmLabel="actionContext.submitLabel"
      @ok="actionContext.action"
      @cancel="openConfirmDelete = false"
    />
    <confirm-modal
      class="confirm-modal"
      headerIcon="fa fa-check-circle"
      :open="openConfirmTerminate"
      :headerLabel="actionContext.title"
      :headerLabelInterpolation="commitmentName"
      :confirmLabel="actionContext.submitLabel"
      :executing="executing"
      @ok="actionContext.action"
      @cancel="openConfirmTerminate = false"
    >
      <alert-box
        :label="actionContext.label"
        :interpolation="commitmentDetails"
      />
      <base-select
        v-if="mustSelectBillingCycle"
        id="select"
        :items="selectItems"
        :modelValue="selectedBillingCycleId"
        @update:modelValue="selectedBillingCycleId = $event"
      />
      <alert-box
        v-if="reprocessNecessary"
        label="commitment_actions.terminate_commitment_reprocess_message"
        :interpolation="getTerminateInterpolation"
      />
    </confirm-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AlertBoxVue from '@/components/AlertBox.vue';
import BaseSelect from '@/components/BaseSelect.vue';

import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { getNow, isAfter, isBefore } from '@/utils/dates';
import { sortBy } from '@/utils';
import { fetchCycles } from "@/utils/commitments";

import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { commitmentMixin } from '@/mixins/commitmentMixin';

const DELETE = 'delete';
const TERMINATE = 'terminate';

export default {
  name: 'CommitmentActions',
  components: [BaseSelect, AlertBoxVue],
  mixins: [billingCycleMixin, commitmentMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    commitment: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    shouldRedirect: {
      type: Boolean,
    },
    fromGlobalView: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      executing: false,
      openConfirmDelete: false,
      openConfirmTerminate: false,
      actionContext: {
        title: '',
        label: '',
        action: () => null,
        submitLabel: '',
      },
      selectedBillingCycleId: undefined,
      billingCycles: []
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    actions() {
      const actions = [
        {
          value: TERMINATE,
          label: 'commitment_actions.terminate_commitment',
          icon: 'fa fa-ban',
          permission: 'reseller:billing',
          disabled: !this.commitment.actionsMetadata.terminable,
        },
        {
          value: DELETE,
          label: 'commitment_actions.delete_commitment',
          icon: 'fa fa-trash',
          permission: 'reseller:billing',
          disabled: !this.commitment.actionsMetadata.deletable,
        },
      ].filter(a => !a.hide);

      // If all actions are disabled, show nothing.
      if (actions.length === actions.filter(a => a.disabled).length) {
        return [];
      }
      return actions;
    },
    commitmentName() {
      return { commitment: this.commitment.name };
    },
    commitmentDetails() {
      return {
        reprocessStart: this.reprocessStartDate,
        reprocessEnd: this.reprocessEndDate,
        commitment: this.commitment.name,
        organization: this.organization.name,
      };
    },
    filteredBillingCycles() {
      return [...this.billingCycles]
        .filter(bc => (bc.commitments || []).some(bcc => bcc.commitment.id === this.commitment.id))
        .sort((a, b) => isBefore(a, b));
    },
    ongoingBillingCycles() {
      return this.filteredBillingCycles.filter((cycle) => this.isOngoing(cycle))
    },
    closedBillingCycles() {
      return this.filteredBillingCycles.filter((cycle) => !this.isOngoing(cycle));
    },
    reprocessStartDate() {
      const bc = this.ongoingBillingCycles[0] || { startDate: '' };
      if (isBefore(bc.startDate, this.commitment.startDate)) {
        return this.commitment.startDate;
      }
      return bc.startDate
    },
    reprocessEndDate() {
      const bc = this.ongoingBillingCycles[0] || { endDate: '' };
      return bc.endDate
    },
    mustSelectBillingCycle() {
      return this.selectableBillingCycles.length > 0;
    },
    confirmModalMessage() {
      if (this.reprocessStartDate && this.commitment.status !== 'UPCOMING') {
        return 'commitment_actions.delete_commitment_confirmation_message_reprocess'
      }
      else {
        return 'commitment_actions.delete_commitment_confirmation_message'
      }
    },
    selectableBillingCycles() {
      const ced = this.commitment.endDate || "9999-12-31";
      return this.ongoingBillingCycles
        .filter(bc => !isBefore(ced, bc.endDate))
        .sort(sortBy(bc => bc.startDate));
    },
    selectItems() {
      return this.selectableBillingCycles.reduce((acc, cur) => {
          acc.push({
            label: this.endDate(cur),
            value: cur.id,
          });

          return acc;
        }, []);
    },
    selectedBillingCycle() {
      if (!this.mustSelectBillingCycle){
        return undefined;
      }
      return this.selectableBillingCycles.find(bc=> bc.id === this.selectedBillingCycleId);
    },
    lastDayOfBillingCycles() {
      const bc = this.ongoingBillingCycles[this.ongoingBillingCycles.length - 1] || { endDate: ''};
      return bc.endDate;
    },
    getTerminateInterpolation() {
      return {
        startDate: this.selectedBillingCycle.startDate,
        endDate: this.selectedBillingCycle.endDate,
        organization: this.organization.name,
      }
    },
    reprocessNecessary() {
      return this.selectedBillingCycle && isAfter(getNow(), this.selectedBillingCycle.endDate)
    },
    redirectRoute() {
      return this.fromGlobalView ? 'global-commitment-list' : 'org-billing';
    }
  },
  methods: {
    async deleteCommitment() {
      this.executing = true;
      const resp = await apis.commitments.remove(this.commitment.id);

      if (!resp.ok) {
        notify.error(this.$t('commitment_actions.delete_commitment_failure', this.commitment));
      } else {
        notify.success(this.$t('commitment_actions.delete_commitment_successs', this.commitment));
        if (this.shouldRedirect) {
          this.$router.navigateBackOrDefault({ name: this.redirectRoute });
        }
      }

      this.openConfirmDelete = false;
      this.executing = false;
    },
    async terminateCommitment() {
      this.executing = true;
      const resp = await apis.commitments.terminate(this.commitment.id, this.selectedBillingCycle.endDate);
      if (!resp.ok) {
        notify.error(this.$t('commitment_actions.terminate_commitment_failure', this.commitment));
      } else {
        notify.success(this.$t('commitment_actions.terminate_commitment_success', this.commitment));
        if (this.shouldRedirect) {
          this.$router.navigateBackOrDefault({ name: this.redirectRoute });
        }
      }
      this.openConfirmTerminate = false;
      this.executing = false;
    },
    async confirmAction(action) {
      this.billingCycles = await fetchCycles(this.commitment.organization);
      switch (action) {
        case DELETE:
          this.openConfirmDelete = true;
          this.actionContext = {
            title: 'commitment_actions.delete_commitment_confirmation_title',
            label: this.confirmModalMessage,
            action: this.deleteCommitment,
            submitLabel: 'delete',
          };
          break;
        case TERMINATE: {
            this.actionContext = {
              title: 'commitment_actions.terminate_commitment_confirmation_title',
              label: 'commitment_actions.terminate_commitment_confirmation_message_select_billing_cycle',
              action: this.terminateCommitment,
              submitLabel: 'terminate',
            }
            this.openConfirmTerminate = true;
          }
          break;
        default:
          break;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.action-container {
  display: flex;
  flex-direction: row;
}
</style>