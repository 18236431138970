<template>
  <div class="users">
    <base-avatar 
      v-for="user in shownUsers" 
      :key="user.id" 
      class="avatar" 
      :size="50" 
      :user="user" 
      :tooltip="`${user.firstName} ${user.lastName}${user.roleName ? '\n' + user.roleName : ''}`"
    />
    <action-text 
      v-if="usersLeft" 
      class="more-users" 
      :label="$t('n_more',{n: usersLeft})"
      @click="showMore" 
    />
  </div>
</template>
<script>
const INIT_MAX_USERS = 10;
export default {
  name: 'OrganizationUsers',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      maxUsers: INIT_MAX_USERS,
    };
  },
  computed: {
    shownUsers() {
      return this.users.filter((u, idx) => idx < this.maxUsers);
    },
    usersLeft() {
      return Math.max(this.users.length - this.maxUsers, 0);
    },
  },
  methods: {
    showMore() {
      this.maxUsers = Math.min(this.maxUsers * 2, this.users.length);
    },
  },
};
</script>
<style scoped lang="scss">
.users {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.avatar, .more-users{
  margin-right: 10px;
  margin-bottom: 10px;
}
.more-users {
  text-decoration: underline;
}
</style>
