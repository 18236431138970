import { CrudApi } from './api';

class FeedbackSettings extends CrudApi {
  constructor() {
    super('/feedback');
  }

  getOrganizationFeedbackSettings(orgId) {
    return this.get('feedback/find', { qs: {
      organizationId: orgId,
    } })
  } 

  feedbackSettingsExist(orgId) {
    return this.get('feedback/exists', { qs: {
      organizationId: orgId,
    } })
  }

  getZendeskSettings(orgId) {
    return this.get('feedback/zendesk', { qs: {
      organizationId: orgId,
    } })
  }

  getTrackingSettings() {
    return this.get('feedback/tracking')
  }

  getGtmSettings() {
    return this.get('feedback/gtm')
  } 

  createOrganizationFeedbackSettings(feedbackSetting) {
    return this.create(feedbackSetting)
  }

  updateOrganizationFeedbackSettings(id, feedbackSetting) {
    return this.update(id, feedbackSetting)
  }
}
const feedbackSettings = new FeedbackSettings();
export default feedbackSettings;
