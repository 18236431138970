import {mapGetters} from 'vuex';

export const customFieldMixin = {
  computed: {
    ...mapGetters([
      'locale',
    ]),
  },
  methods: {
    /**
     * Given a list of custom fields, as well as a key-value pair for a custom field attribute,
     * returns the translated value(s) to display for the custom field, intended to be used as
     * the value in an attribute passed into the user/organization detail view base-attributes list
     * @param customFields  - a list of custom fields valid for the entity
     * @param fieldKey      - the field of the custom field
     * @param fieldValue    - the value(s) of the custom field to format + translate
     * @returns in the case of field mode:
     * - PREDEFINED_LIST: returns the translation for the chosen locale for the fieldValue
     * - MULTISELECT_WITH_COUNT: returns a list of tags of the format 'fieldTranslation: countAmount'
     * - MULTISELECT: returns the values as a list of translated strings for the chosen locale
    */
    getCustomFieldValue(customFields, fieldKey, fieldValue) {
      const matchingCustomField = customFields.filter(field => field.field === fieldKey)[0];
      if (matchingCustomField) {
        if (matchingCustomField.mode === 'MULTISELECT_WITH_COUNT') {
          return this.getMultiselectFieldValueAsTags(matchingCustomField, fieldValue);
        } else if (matchingCustomField.mode === 'PREDEFINED_LIST') {
          let predefinedValue = matchingCustomField.values.filter(val => val.value === fieldValue)[0];
          return predefinedValue?.translationMap?.[this.locale] || '';
        } else if (matchingCustomField.mode === 'MULTISELECT') {
          let translatedValues = fieldValue.map(v => matchingCustomField.values.filter(val => val.value === v)[0]?.translationMap?.[this.locale]);
          return translatedValues.join(', ');
        }
      }
      return fieldValue;
    },
    /**
     * Given a custom field of type MULTISELECT_WITH_COUNT, with values of format:
     * values: [
     *    {value: "licenseA", translationMap: { 'en': "License A" } },
     *    {value: "licenseB", translationMap: { 'en': "License B" } }
     * ]
     * and a fieldValue object of format [ { 'licenseA': COUNT_A }, { 'licenseB': COUNT_B } ]
     * @returns a list of tags formatted as:
     * tags: [
     *    { id: 'licenseA', name: 'License A: COUNT_A', ... },
     *    { id: 'licenseB', name: 'License B: COUNT_B', ... },
     * ]
     */
    getMultiselectFieldValueAsTags(matchingCustomField, fieldValue) {
      let tags = [];
      fieldValue.forEach((v) => {
        let matchingValue = matchingCustomField.values.filter(val => val.value === Object.keys(v).at(0))[0];
        let matchingValueTranslation = matchingValue?.translationMap?.[this.locale];
        tags.push({
          id: matchingValue.value,
          name: matchingValueTranslation + ": " + v[Object.keys(v).at(0)],
          sizeClass: 'sm',
          colorClass: 'readonly'
        });
      })
      return tags;
    },
    /**
     * Returns whether the given custom field 'fieldKey' has a corresponding custom field in the
     * customFields list, and if that custom field is of mode MULTISELECT_WITH_COUNT
     * @param customFields  - a list of custom fields for this resource type
     * @param fieldKey      - a custom field 'field'
     * @returns true/false whether the custom fields list contains a field of type
     * MULTISELECT_WITH_COUNT with field matching the fieldKey
     */
    isMultiselectField(customFields, fieldKey) {
      const matchingCustomField = (customFields.filter(field => field.field === fieldKey))[0];
      return matchingCustomField && matchingCustomField.mode == 'MULTISELECT_WITH_COUNT';
    },
  }
}
