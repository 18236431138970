import BaseRoute from '@/app/components/BaseRoute';

const ResourceCommitmentsList = () => import(/* webpackChunkName: "resourcecommitments" */ './ResourceCommitmentsList');
const ResourceCommitmentAddForm = () => import(/* webpackChunkName: "resourcecommitments" */ './form/ResourceCommitmentAddForm');
const ResourceCommitmentEditForm = () => import(/* webpackChunkName: "resourcecommitments" */ './form/ResourceCommitmentEditForm');

export default [
  {
    path: 'resource-commitments',
    component: BaseRoute,
    meta: {
      title: () => 'resource_commitments',
      protected: ['reseller:commitments', 'admin:usage'],
      isProtectUnion: false,
    },
    children: [
      {
        component: ResourceCommitmentsList,
        name: 'resourceCommitments',
        path: '',
        meta: {
          protected: ['admin:usage', 'reseller:commitments'],
          isProtectUnion: false,
        },
      },
      {
        component: ResourceCommitmentAddForm,
        name: 'addResourceCommitment',
        path: 'add',
        props: route => ({ id: route.params.id, switchOrgRouteName: 'resourceCommitments' }),
        meta: {
          protected: ['reseller:commitments'],
        },
      },
      {
        component: ResourceCommitmentEditForm,
        name: 'editResourceCommitment',
        path: ':id/edit',
        props: route => ({ id: route.params.id, switchOrgRouteName: 'resourceCommitments' }),
        meta: {
          protected: ['reseller:commitments'],
        },
      },
    ],
  },
];
