import { CrudApi } from './api';

class BrandingApi extends CrudApi {
  constructor() {
    super('/brandings');
  }

  getOrganizationBranding(orgId) {
    return this.get(`brandings/organization/${orgId}`, {});
  }

  getEffective(orgId) {
    return this.get('brandings/effective', { qs: {
      organization_id: orgId,
    } });
  }
}
const brandingApi = new BrandingApi();
export default brandingApi;
