import { CrudApi } from './api';

class ApiKeysApi extends CrudApi {
  constructor() {
    super('apikeys');
  }
}

const apiKeysApi = new ApiKeysApi();

export default apiKeysApi;
