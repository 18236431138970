<template>
  <div>
    <div 
      class="breadcrumb" 
      :class="{ 'has-drilldown': breadcrumb.length }"
    >
      <action-icon 
        v-if="breadcrumb.length" 
        tooltipLabel="back" 
        icon="fa fa-arrow-left" 
        color="unset" 
        size="small" 
        @click="back()" 
      />
      <base-icon 
        class="arrow" 
        icon="sitemap"
      />
      <base-icon 
        v-if="breadcrumb.length" 
        class="arrow" 
        icon="caret-right"
      />
      <span v-else>{{ $t('top_level') }}</span>
      <span 
        v-for="(b, $index) in breadcrumb" 
        :key="b.name" 
      >
        <action-text 
          :label="b.name" 
          @click="breadcrumbSelect(b)" 
        />
        <base-icon 
          v-if="$index !== breadcrumb.length - 1" 
          icon="caret-right" 
          class="arrow"
        />
      </span>
    </div>
    <hierarchical-picker ref="picker">
      <base-list 
        ariaLabel="organizations" 
        :ariaRowCount="totalRows"
      >
        <div 
          v-for="orgGroup in groupedOrgs"
          :key="orgGroup.headerLabel" 
        >
          <list-header 
            v-if="groupedOrgs.length > 0 && breadcrumb.length === 0 "
            :mobile="mobile" 
          >
            {{ $t(orgGroup.headerLabel) }}
          </list-header>
          <list-row 
            v-for="org in orgGroup.organizations" 
            :key="org.id" 
            :to="`/admin/organizations/${org.id}`" 
            class="clickable" 
            noActions
          >
            <list-column :size="4">
              <div class="org-name">
                <base-icon 
                  v-if="selectedOrganization.id === org.id"
                  icon="fa fa-check-circle green" 
                />
                {{ org.name }}
              </div>
              <div 
                v-if="search" 
                class="lineage"
              >
                <span 
                  v-for="(l, $index) in org.orgPath"
                  :key="l" 
                >{{ l }}<span 
                  v-if="$index !== org.orgPath.length - 1"
                  class="separator" 
                >/</span></span>
              </div>
            </list-column>
            <list-column>
              <base-button 
                v-if="org.suborgs.length"
                @click.stop="enterSubs(org)" 
              >
                {{ $t('sub_orgs_small') }} <base-icon icon="fa fa-angle-right" />
              </base-button>
            </list-column>
          </list-row>
        </div>
      </base-list>
      <base-loader v-if="loading" />
    </hierarchical-picker>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isMobileMode } from '@/utils';
import OrganizationPicker from '../components/OrganizationPicker';

export default {
  name: 'OrganizationNavigator',
  extends: OrganizationPicker,
  props: {
    orgSearch: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'myOrganization',
    ]),
    mobile() {
      return isMobileMode();
    },
    filteredOrganizations() {
      if (!this.orgSearch) {
        return this.organizations;
      }
      return this.organizations.filter(o => `${o.name.toLowerCase()}}`.includes(this.orgSearch.toLowerCase()));
    },
    groupedOrgs() {
      // org list api not returned yet
      if (!this.filteredOrganizations.length && !this.breadcrumb.length) {
        return [{
          headerLabel: 'my_organization',
          organizations: [{
            ...this.myOrganization,
            suborgs: [],
          }],
        }];
      }

      const groups = [];
      if (this.currSelectedOrg.headerLabel) groups.push(this.currSelectedOrg);
      if (this.myOrgGroup.headerLabel) groups.push(this.myOrgGroup);
      if (this.otherOrgsGroup.headerLabel) groups.push(this.otherOrgsGroup);

      return groups;
    },
    currSelectedOrg() {
      const currOrg = this.allOrganizations.find(o => o.id === this.selectedOrganization.id);
      if (currOrg && this.breadcrumb.length === 0 &&
        this.myOrganization.id !== this.selectedOrganization.id) {
        return {
          headerLabel: 'selected_organization',
          organizations: [currOrg],
        };
      }
      return {};
    },
    totalRows() {
      return this.groupedOrgs.map(g => g.organizations.length)
        .reduce((a, b) => a + b, 0);
    },
  },
};
</script>
<style scoped="scss">

.breadcrumb {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--link-text);
}

.arrow {
  padding-right: 8px;
}

</style>
