<template>
  <div class="multi-step">
    <div 
      v-if="!v2 && steps.length > 1"
      class="steps-breadcrumb"
    >
      <div 
        v-for="(step, $index) in steps"
        :key="step.name" 
        class="steps-progress" 
        :class="{ active: $index <= completedStep + 1, current: $index === completedStep + 1 }" 
      >
        <div 
          class="step-bubble state" 
          :class="{ green: $index <= completedStep, current: $index === completedStep + 1 }"
        ></div>
        <div class="step-line">
          <div 
            class="step-line-inner state" 
            :class="{ green: $index <= completedStep } "
          ></div>
        </div>
        <div 
          class="step-label" 
          :class="{ green: $index <= completedStep + 1, state: $index === completedStep + 1 }"
        >
          <base-icon 
            v-if="$index <= completedStep" 
            icon="fa fa-check-circle"
          /> {{ ($index + 1) + '. ' + $t(step.label || operationName) }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="step-header">
        <div
          v-for="(step, idx) in steps"
          :key="step.label"
          class="step-indicator"
        >
          <div
            class="step-number"
            :class="{ 'active': idx === currentStep }"
          >
            {{ idx + 1 }}
          </div>
          <div class="step-label">
            {{ $t(step.label || operationName) }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="v2" 
      class="step-container"
    >
      <div 
        v-if="v2"
        class="secondary-title step-label" 
      >
        <h2>{{ currentStep + 1 }}. {{ $t(steps[currentStep].label || operationName) }}</h2>
      </div>
      <div class="description">
        {{ $t(steps[currentStep].descriptionLabel) }}
      </div>
      <base-loader 
        v-if="executing"
        :delay="0"
        type="cube"
      ></base-loader>
      <slot v-else></slot>
    </div>
    <slot v-else-if="!executing" />
    <base-loader v-else></base-loader>
  </div>
</template>

<script>
export default {
  name: 'MultiStep',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      default: -1,
    },
    executing: {
      type: Boolean,
      default: false,
    },
    v2: {
      type: Boolean,
    },
    operationName: {
      type: String
    },
  },
  computed: {
    completedStep() {
      return this.currentStep - 1;
    },
  },
};
</script>

<style lang="scss">
.multi-step-fade-enter-active {
  transition: opacity 0.2s 0.2s;
}

.multi-step-fade-leave-active {
  transition: opacity 0.2s;
  position: absolute;
  width: 100%;
}

.multi-step-fade-leave-to {
  opacity: 0;
}
.multi-step-fade-enter-from {
  opacity: 0;
}
</style>

<style scoped lang="scss">
@import '@/styles/mixins';

.multi-step {
  position: relative;
}
.steps-breadcrumb {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 30px 60px 0px;
  max-width: 650px;
  margin-bottom: 20px;
  .steps-progress {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:last-child {
      background-color: transparent;
    }

    .step-label {
      position: absolute;
      bottom: -40px;
      font-size: 12px;
      left: -40px;
      white-space: nowrap;
      transform: translateY(0);
      transition: transform 0.5s, background-color 0.5s;
      border-radius: 10px;
      padding: 8px;
    }

    &:not(.active) {
      .step-label {
        color: var(--gray);
      }
      .step-bubble:not(.current) {
        background-color: #e0e0e0;
      }
      &:first-child {
        .step-label {
          left: -10px;
        }
      }
    }

    .step-bubble {
      display: block;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      z-index: 1;
      transition: background-color 0.5s ease;
      &.current {
        &:after {
          content: '';
          display: block;
          height: 20px;
          width: 20px;
          background-color: white;
          transform: scale(0.6);
          border-radius: 100%;
        }
      }
    }
    .step-line {
      background-color: #e0e0e0;
      height: 5px;
      flex: 1;
      margin-left: -5px;
      margin-right: -5px;
      .step-line-inner {
        transition: transform 0.5s;
        display: block;
        height: 5px;
        width: 100%;
        transform: scaleX(0);
        transform-origin: left;
      }
    }
    &.active:not(.current) {
      .step-bubble {
        &:after {
          display: none;
        }
      }
      .step-line {
        .step-line-inner {
          transform: scaleX(1);
        }
      }
    }
    &.current {
      .step-label {
        transform: translateY(5px);
        font-size: 0.7rem;
        &:after {
          content: '';
          height: 10px;
          background: inherit;
          display: inline-block;
          position: absolute;
          top: 0px;
          left: 45px;
          transform: translateY(-30%) rotate(-45deg);
          width: 10px;
        }
      }
    }
    &:last-child {
      flex: none;
      .step-line {
        display: none;
      }
    }
    height: 8px;
    flex: 1;
    transition: background-color 0.5s ease;
  }
}
@include phone {
  .steps-breadcrumb {
    display: none;
  }
}

:deep(.form-page) {
  padding: 0 !important;
  .main-title {
    margin-top: 0;
  }
}

.step-header {
  display: flex;
  @include phone {
    flex-direction: column;
  }
  @include not-phone {
    width: 80%;
  }

  @include not-large-desktop {
    width: 100%;
  }


  justify-content: space-between;
}
.step-indicator {
  display: flex;
  align-items: center;
  @include phone {
    margin-bottom: 10px;
  }
}
.step-number {
  border-radius: 50%;
  @include not-phone {
    width: 0px;
    height: 0px;
    padding: 18px;
    font-size: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  @include phone {
    width: 15px;
    height: 15px;
    padding: 9px;
    font-size: 17px;
  }

  background-color: var(--plain-dark);
  font-weight: bold;
  color: #000;
  text-align: center;

  @include not-phone {
    margin-right: 20px;
  }

  @include phone {
    margin-right: 10px;
  }

  &.active {
    background: var(--primary);
    color: var(--on-primary);
  }
}

.step-label {
  font-weight: bold;
  &.secondary-title {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @include phone {
    margin-right: 10px;
  }
}

.step-container {
  position: relative;
  @include phone {
    margin-top: 20px;
  }
  margin-top: 50px;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px var(--plain-dark);
}
</style>
