<script>
import { use } from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import Chart from './Chart';

use([GaugeChart]);

const GAUGE_POINTER_ICON = 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z';

export default {
  name: 'GaugeChart',
  extends: Chart,
  props: {
    max: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  computed: {
    legendData() {
      return { show: false };
    },
    tooltipData() {
      return {
        confine: true,
        formatter: (params) => {
          const defaultSeriesName = params.seriesName.startsWith('series');
          if (!defaultSeriesName && params.name) {
            return `${params.seriesName} <br/>${params.name} : ${params.value} ${this.unit}`;
          } else if (!defaultSeriesName || params.name) {
            const name = (!defaultSeriesName ? params.seriesName : false) || params.name;
            return `${name} : ${params.value} ${this.unit}`;
          }
          return `${params.value} ${this.unit}`;
        },
      };
    },
    seriesData() {
      return this.series.map(s => ({
        name: s.name,
        type: 'gauge',
        radius: '100%',
        center: ['50%', '75%'],
        startAngle: 180,
        endAngle: 0,
        max: this.max,
        min: this.min,
        splitNumber: 2,
        pointer: {
          icon: GAUGE_POINTER_ICON,
          width: 10,
          length: '45%',
          offsetCenter: [0, '5%'],
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
        },
        title: { show: false },
        detail: { show: false },
        splitLine: {
          width: 2,
          distance: 3,
        },
        axisTick: {
          splitNumber: 5,
          distance: 3,
        },
        axisLabel: {
          distance: 25,
        },
        axisLine: {
          lineStyle: {
            width: 20,
          },
        },
        data: s.data,
      }));
    },
  },
};
</script>
