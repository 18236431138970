import { CrudApi } from './api';

class ServiceConnectionApi extends CrudApi {
  constructor() {
    super('services/connections');
  }

  listForOrg(orgId) {
    return this.list({ qs: { organizationId: orgId } });
  }

  generateServiceCode(type) {
    return this.get(`services/connections/generateCode/${type}`);
  }
    
  testConnection(connection) {
    return this.post('services/connections/test', { body: connection });
  }
    
  getConnection(connectionId) {
    return this.get(`services/connections/${connectionId}`);
  }

  getConnectionsOfType(serviceType) {
    return this.get('services/connections', { qs: { type: serviceType } });
  }
    
  getConnectionParameters(connectionId) {
    return this.get(`services/connections/${connectionId}/parameters`);
  }
    
  getAssignedOrgs(connectionId, organizationId) {
    return this.get(`services/connections/${connectionId}/assigned_organizations`, {
      qs: {
        organizationId: organizationId,
      }
    });
  }
    
  deleteConnection(connection) {
    return this.del(`services/connections/${connection}`);
  }
    
  descriptors(connectionId, section) {
    return this.get(`services/connections/${connectionId}/policies/descriptors`, {
      qs: { section },
    });
  }

  updatePolicies(connectionId, policies) {
    return this.put(`services/connections/${connectionId}/policies`, {
      body: policies,
    });
  }

  getApiInfo(serviceConnectionId, environmentId) {
    return this.get(`${this.path}/${serviceConnectionId}/apiInfo`, {
      qs: { environmentId },
    }).then((resp) => {
      if (!resp.ok) {
        return false;
      }
      return resp;
    });
  }
  regenerateApiCredentials(serviceConnectionId, environmentId) {
    return this.get(`${this.path}/${serviceConnectionId}/regenerateApiCredentials`, {
      qs: { environmentId },
    });
  }

  getConnectionPricings(connectionId, resCommEligibleOnly, startDate) {
    return this.get(`${this.path}/${connectionId}/pricings/date`, {
      qs: { resourceCommitmentEligibleOnly: resCommEligibleOnly, startDate },
    });
  }
}


const serviceConnectionApi = new ServiceConnectionApi();
export default serviceConnectionApi;
