<template>
  <div class="base-calendar">
    <div class="calendar-header plain">
      <div>
        <action-icon 
          v-if="!showPresets" 
          icon="fa fa-caret-left" 
          size="small" 
          color="on-primary" 
          tooltipLabel="previous"
          @click="previousMonth" 
        />
      </div>
      <div 
        v-if="!showPresets" 
        class="current-month"
      >
        <base-icon icon="calendar" /> {{ $date(date, 'MMMM YYYY', utc) }}
      </div>
      <div v-if="showPresets">
        {{ $t('presets') }}
      </div>
      <div>
        <action-icon 
          v-if="!showPresets" 
          icon="fa fa-caret-right" 
          size="small" 
          color="on-primary" 
          tooltipLabel="next"
          @click="nextMonth" 
        />
        <div 
          v-if="ranged && presetsEnabled" 
          class="ranged-presets clickable" 
          role="button"
          tabindex="0" 
          @keyup.self.enter="showPresets = !showPresets" 
          @click="showPresets = !showPresets"
        >
          {{ $t(showPresets ? 'calendar' : 'presets') }}
        </div>
      </div>
    </div>
    <div 
      v-show="!showPresets" 
      class="weekdays light-primary"
    >
      <div 
        v-for="dayOfTheWeek in daysOfTheWeek" 
        :key="dayOfTheWeek.value"
      >
        {{ $date(dayOfTheWeek,'ddd') }}
      </div>
    </div>
    <div role="grid">
      <div 
        v-for="week in daysInMonth" 
        v-show="!showPresets" 
        :key="week[0].value + '-' + week[week.length - 1].value"
        class="week" 
      >
        <div 
          v-for="day in week" 
          :key="day.value"
          class="day"
          :class="{'light-secondary-text': day.inside, 'other-text': !day.inside, 'calendar-selection': day.selected, start: day.start, end: day.end || !end}"
        >
          <div class="calendar-selected range"></div>
          <div 
            role="button"
            :tabindex="day.disabled ? -1 : 0" 
            class="value-holder"
            @keyup.self.enter="select(day)" 
            @click="select(day)" 
          >
            <div 
              v-if="day.disabled" 
              class="disabled"
            ></div>
            <div 
              class="calendar-selected value clickable very-light-primary-on-hover" 
              :class="{other: day.now}"
            >
              {{ day.value }}
            </div>
          </div>
          <div class="calendar-selected range"></div>
        </div>
      </div>
    </div>
    <div 
      v-show="showPresets"
      class="presets" 
    >
      <div 
        v-for="preset in presets" 
        :key="preset.name" 
        role="button"
        tabindex="0" 
        :class="{'very-light-primary-text': selectedPresetName === preset.name, 'light-secondary-text clickable': selectedPresetName !== preset.name}"
        @keyup.self.enter="select(preset)" 
        @click="selectPreset(preset)"
      >
        {{ preset.label }}
      </div>
    </div>
    <div 
      v-if="showMessage" 
      class="message"
    >
      {{ $t('select_end_date') }}
    </div>
  </div>
</template>

<script>
import { addMonths, startOfDay, startOfMonth, endOfDay, endOfMonth, isAfter, isBefore } from '@/utils/dates';
import calendar from './calendar';
import ActionIcon from '../ActionIcon';
import BaseIcon from '../BaseIcon';

export default {
  name: 'BaseCalendar',
  components: { ActionIcon, BaseIcon },
  props: {
    ranged: {
      type: Boolean,
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    presetsEnabled: {
      type: Boolean,
      default: true,
    },
    start: {
      type: [Date, String],
    },
    end: {
      type: [Date, String],
    },
    utc: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'select'],
  data() {
    return {
      date: this.getInitialDate(),
      showPresets: false,
      selectedPresetName: undefined,
      daysOfTheWeek: calendar.getDaysOfTheWeek(),
      presets: calendar.getPresets(),
      endDateSelected: true,
      updatedStart: this.start,
      updatedEnd: this.end,
    };
  },
  computed: {
    daysInMonth() {
      const month = calendar.buildMonth(this.date, this.minDate, this.maxDate, this.utc);
      const startDate = startOfDay(this.updatedStart, this.utc);
      const endDate = this.updatedEnd ? startOfDay(this.updatedEnd, this.utc) : null;
      calendar.setSelected(month, startDate, endDate);
      return month;
    },
    startOfNextMonth() {
      return addMonths(this.date, 1, this.utc);
    },
    startOfPreviousMonth() {
      return addMonths(this.date, -1, this.utc);
    },
    endOfPreviousMonth() {
      return endOfMonth(addMonths(this.date, -1, this.utc), this.utc);
    },
    showMessage() {
      return !this.endDateSelected;
    },
  },
  watch: {
    start(newUpdatedStart) {
      this.updatedStart = newUpdatedStart;
    },
    end(newUpdatedEnd) {
      this.updatedEnd = newUpdatedEnd;
    }
  },
  methods: {
    getInitialDate() {
      if (this.updatedEnd) {
        return startOfMonth(this.updatedEnd, this.utc);
      } else if (this.updatedStart) {
        return startOfMonth(this.updatedStart, this.utc);
      }
      return startOfMonth(this.start, this.utc);
    },
    nextMonth() {
      if (!this.maxDate || isBefore(this.startOfNextMonth, this.maxDate, this.utc)) {
        this.date = this.startOfNextMonth;
      }
    },
    previousMonth() {
      if (!this.minDate || isAfter(this.endOfPreviousMonth, this.minDate, this.utc)) {
        this.date = this.startOfPreviousMonth;
      }
    },
    select(d) {
      let start = this.updatedStart;
      let end;

      if (!d.disabled) {
        if (!this.ranged) {
          start = startOfDay(d.date, this.utc);
          end = endOfDay(d.date, this.utc);
          this.endDateSelected = false;
        } else if (!start || this.isNotSameDay() || isBefore(d.date, start)) {
          start = d.date;
          end = endOfDay(start, this.utc);
          this.endDateSelected = false;
        } else {
          end = endOfDay(d.date, this.utc);
          this.endDateSelected = true;
        }
        this.$emit('update:modelValue', { start, end });
        if (this.ranged) {
          this.$emit('select', { start, end });
        } else {
          this.$emit('select', start);
        }
        this.selectedPresetName = calendar.getPresetNameWithDates(start, end);
        this.updatedStart = start;
        this.updatedEnd = end;
      }
    },
    isNotSameDay() {
      return this.updatedEnd && this.updatedEnd.getTime() !== endOfDay(this.updatedStart, this.utc).getTime();
    },
    jumpTo(date) {
      this.date = date;
    },
    selectPreset(preset) {
      this.updatedStart = startOfDay(preset.updatedStart, this.utc);
      this.updatedEnd = startOfDay(preset.end, this.utc);
      if (this.minDate && isBefore(this.updatedStart, this.minDate)) {
        this.updatedStart = startOfDay(this.minDate, this.utc);
      }
      if (this.maxDate && isAfter(this.updatedEnd, this.maxDate)) {
        this.updatedEnd = startOfDay(this.maxDate, this.utc);
      }
      this.showPresets = false;
      this.selectedPresetName = preset.name;
      const monthYearMatch = (d1, d2) => d1.getMonth() === d2.getMonth() &&
        d1.getYear() === d2.getYear();
      if (!monthYearMatch(this.date, this.updatedStart) && !monthYearMatch(this.date, this.updatedEnd)) {
        this.date = this.updatedStart;
      }
    },
  },
};
</script>


<style scoped lang="scss">
$textColor: #6B6B6B;
* {
  user-select: none;
}
.calendar-header,
.weekdays {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;

  .current-month {
    min-width: 150px;
  }

  > div {
    flex: 1;
  }
}

.calendar-header {
  height: 35px;
  .fa {
    padding: 5px;
  }
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    position: relative;
  }
}

.weekdays {
  margin-bottom: 5px;
  padding: 2px 0px;
}

.week {
  display: flex;
  flex-direction: row;
}

.day {
  flex: 1;
  padding: 2px 0px;
  display: flex;
  flex-direction: row;

  .range {
    height: 100%;
    flex: 1;
  }

  .value-holder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value {
    padding: 5px;
    border-radius: 50%;
    height: 1.2em;
    width: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.calendar-selection {
    &.start {
      .range:first-child {
        background-color: transparent;
      }
      .value {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
    &.end {
      .range:last-child {
        background-color: transparent;
      }
      .value {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
    .value {
      border-radius: 0;
      font-weight: 600;
    }
  }
}
.disabled {
  position: absolute;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: repeating-linear-gradient(45deg,#cecece,#cecece 5px, transparent 5px, transparent 10px);
}

.presets {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  div {
    padding: 12px;
    box-sizing: border-box;
    flex-basis: 50%;
    font-weight: bold;
    border-bottom: 1px solid #f0f0f0;
  }
}


.light-secondary-text {
  color: $textColor;
}

.other-text {
  color: lighten($textColor, 30%);
}

.ranged-presets {
  position:absolute;
  right:10px;
  top:10px;
  font-size:12px;
}

.message {
  color: -var(--detail-text);
  text-align: center;
}
</style>
