<template>
  <advanced-details 
    v-if="organization" 
    :loading="loading" 
    titleLabel="ldap_auth"
  >
    <template 
      v-if="!permissionDenied"
      #operations 
    >
      <action-icon 
        v-show="disabled" 
        icon="fa fa-edit" 
        tooltipLabel="edit" 
        expandOnHover 
        clickable 
        @click="disabled = false"
      ></action-icon>
    </template>
    <empty-message 
      v-if="permissionDenied" 
      icon="fa fa-exclamation-triangle" 
      textLabel="permission_denied"
    ></empty-message>
    <form-page 
      v-else 
      :disabled="disabled" 
      :executing="executing" 
      :hideCancel="disabled" 
      :hideSubmit="disabled" 
      @submit="submit" 
      @cancel="cancel"
    >
      <form-row>
        <base-checkbox 
          v-model="isLdapAuthentication"
          :disabled="disabled" 
          :modelValue="isLdapAuthentication" 
          label="ldap" 
        />
        <LdapConfiguration 
          v-if="isLdapAuthentication" 
          :ldap="ldap" 
          :disabled="disabled"
          @updateLdapField="updateLdapField" 
        />
      </form-row>
    </form-page>
  </advanced-details>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import errors from '@/utils/errors';
import LdapConfiguration from './LdapConfiguration';

export default {
  name: "LdapConfigurationView",
  components: {
    LdapConfiguration,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['refreshOrg'],
  data() {
    return {
      loading: false,
      permissionDenied: false,
      disabled: true,
      executing: false,
      isLdapAuthentication: !!this.organization.isLdapAuthentication,
      ldap: this.organization.ldap || {},
      originallyLdap: this.organization.isLdapAuthentication,
      securitySettings: {
        verifiedDomains: [],
        defaultRole: {},
      },
    };
  },
  async created() {
    this.loading = true;
    await this.fetchOrgSecuritySettings();
    this.loading = false;
  },
  methods: {
    cancel() {
      this.disabled = true;
      this.isLdapAuthentication = this.originallyLdap;
    },
    updateLdapField(field, value) {
      this.ldap[field] = value;
    },
    async submitLdap() {
      const ldapCopy = { ...this.ldap };
      delete ldapCopy.version;
      const orgCopy = {
        isLdapAuthentication: this.isLdapAuthentication,
        isDbAuthentication: !this.isLdapAuthentication,
        ...(this.isLdapAuthentication && { ldap: { ...ldapCopy } }),
      };
      const resp = await apis.organizations.update(this.organization.id, orgCopy);
      if (resp && resp.errors) {
        errors.parse(this.$t.bind(this), resp, this.errors);
        notify.error(this.$t('org_update_failure'));
      } else {
        this.$emit('refreshOrg');
      }
      this.originallyLdap = this.isLdapAuthentication;
    },
    async submit() {
      this.executing = true;

      if (this.isLdapAuthentication || this.originallyLdap) {
        await this.submitLdap();
      }

      await this.fetchOrgSecuritySettings();
      this.executing = false;
      this.disabled = true;
    },
    async fetchOrgSecuritySettings() {
      const resp = await apis.organizations.getSecuritySettings(this.organization.id);
      if (resp.status === 403) {
        this.permissionDenied = true;
      } else if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.securitySettings = resp.data;
      }
    },
  },
};
</script>
