import Api from './api';

class UserWorkspacePreferenceApi extends Api {
  getPreferences(entryPoint, serviceCode, envName) {
    const path = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/preferences/`;
    return this.get(path);
  }
  getPreference(entryPoint, serviceCode, envName, key) {
    const path = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/preferences/${key}`;
    return this.get(path);
  }
  setPreference(entryPoint, serviceCode, envName, key, value) {
    const path = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/preferences/${key}`;
    return this.put(path, {body: value});
  }
  createPreferences(entryPoint, serviceCode, envName, keyValues) {
    const path = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/preferences/`;
    return this.post(path, {body: keyValues})
  }
  updateOrCreatePreferences(entryPoint, serviceCode, envName, keyValues) {
    const path = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/preferences/`;
    return this.put(path, {body: keyValues})
  }
}

const userWorkspacePreference = new UserWorkspacePreferenceApi();
export default userWorkspacePreference;
