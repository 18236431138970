import { ref, computed, onMounted, onUnmounted } from 'vue';

type ScreenSize = 'sm' | 'md' | 'lg';

export default function useResponsive() {
  const screenWidth = ref(window.innerWidth);
  const fontSizeInPixels = parseInt(getComputedStyle(document.body).fontSize) || 1;

  const screenSize = computed<ScreenSize>(() => {
    const isSmScreen = screenWidth.value <= 48 * fontSizeInPixels;
    const isMdScreen = screenWidth.value > 48 * fontSizeInPixels && screenWidth.value <= 64 * fontSizeInPixels;
    const isLgScreen = screenWidth.value > 64 * fontSizeInPixels;
    if (isSmScreen) return 'sm';
    else if (isMdScreen) return 'md';
    else if (isLgScreen) return 'lg';
    return 'lg';
  })
  

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  function handleResize() {
    screenWidth.value = window.innerWidth;
  }

  return { screenSize };
}
