<template>
  <div>
    <QuotaTabs :quotaDetails="enhancedQuotaDetails">
      <template #default="{ groupedDetails }">
        <base-list 
          ariaLabel="quotas.name" 
          :ariaRowCount="groupedDetails.length"
        >
          <list-header>
            <list-column>
              {{ $t('quotas.quota_details.metric') }}
            </list-column>
            <list-column :size="2">
              {{ $t('quotas.quota_details.value') }}
            </list-column>
          </list-header>
          <list-row 
            v-for="quotaDetail in groupedDetails"
            :key="quotaDetail.metricIdentifier" 
          >
            <list-column>
              {{ $t(quotaDetail.nameLabelKey) }}
              <u class="description">
                <action-text 
                  v-if="changed(quotaDetail)" 
                  label="quotas.reset_limit" 
                  @click="resetQuotaLimit(quotaDetail)"
                />
              </u>
            </list-column>
            <list-column>
              <base-input 
                type="number" 
                :disabled="quotaDetail.ceiling === null" 
                :modelValue="quotaDetail.ceiling" 
                :suffix="getUnit(quotaDetail)" 
                @blur="onBlur(quotaDetail, $event)" 
                @update:modelValue="onQuotaDetailChanged(quotaDetail, $event)" 
              />
            </list-column>
            <list-column class="unlimited-checkbox">
              <base-checkbox 
                :modelValue="quotaDetail.ceiling == null ? true : false" 
                :label="$t('unlimited')" 
                @update:modelValue="setUnlimitedChanged(quotaDetail, $event)"
              ></base-checkbox>
            </list-column>
          </list-row>
        </base-list>
        <div class="alerts">
          <alert-box 
            v-if="error" 
            border 
            alertType="ERROR" 
            :label="error"
          />
          <alert-box 
            v-else-if="shrinkingQuotaLimitLabel" 
            border 
            alertType="WARNING"
            :label="shrinkingQuotaLimitLabel"
          />
        </div>
      </template>
    </QuotaTabs>
  </div>
</template>
<script>
import { sortBy } from '@/utils';
import ListColumn from '@/components/list/ListColumn';
import ListHeader from '@/components/list/ListHeader';
import QuotaTabs from './QuotaTabs';

export default {
  name: 'EditQuotaDetails',
  components: { ListHeader, ListColumn, QuotaTabs },
  props: {
    metricDescriptors: {
      type: Array,
      required: true,
    },
    quotaDetails: {
      type: Array,
      required: true,
    },
    initialQuotaDetails: {
      type: Array,
      required: true,
    },
    shrinkingQuotaLimitLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['form-error', 'setUnlimitedChanged', 'quotaDetailChanged'],
  data() {
    return {
      error: '',
    };
  },
  computed: {
    enhancedQuotaDetails() {
      const self = this;
      return this.quotaDetails.map(details => ({
        ...details,
        ...self.findMetricDescriptor(details.metricIdentifier),
      })).sort(sortBy(d => this.$t(d.nameLabelKey)));
    },
  },
  methods: {
    resetQuotaLimit(quotaDetail) {
      const initial = this.initialQuotaDetails
        .find(qd => qd.metricIdentifier === quotaDetail.metricIdentifier);
      if (initial.ceiling === -1) {
        this.setUnlimitedChanged(quotaDetail, true);
      } else {
        this.onQuotaDetailChanged(quotaDetail, initial.ceiling);
      }
    },
    changed(quotaDetail) {
      if (this.initialQuotaDetails.length < 1) {
        return false;
      }
      const initial = this.initialQuotaDetails
        .find(qd => qd.metricIdentifier === quotaDetail.metricIdentifier);
      const initialCeiling = initial.ceiling === -1 ? null : initial.ceiling;
      if (quotaDetail.ceiling !== initialCeiling) {
        return true;
      }
      return false;
    },
    onBlur(quotaDetail, value) {
      if (value.length < 1) {
        this.onQuotaDetailChanged(quotaDetail, '0');
      }
    },
    onQuotaDetailChanged(quotaDetail, e) {
      if (!e) {
        return;
      }
      if (e >= 0) {
        this.$emit('form-error', false);
        this.error = '';
        this.$emit('quotaDetailChanged', quotaDetail.metricIdentifier, Number(e));
      }
      if (e < 0) {
        this.$emit('form-error', true);
        this.error = this.$t('quotas.quota_limit_lt_0');
      }
    },
    setUnlimitedChanged(quotaDetail, e) {
      this.error = '';
      this.$emit('form-error', false);
      this.$emit('setUnlimitedChanged', quotaDetail.metricIdentifier, e);
    },
    findMetricDescriptor(id) {
      return this.metricDescriptors.find(md => md.metricIdentifier === id);
    },
    getUnit(quotaDetail) {
      return this.$t(quotaDetail.unitLabelKey);
    },
  },
};
</script>
<style lang="scss" scoped>
.alerts {
  margin-top: 10px;
}
.unlimited-checkbox {
  &.list-column {
    justify-content: center;
  }
}
</style>
