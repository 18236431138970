import { CrudApi } from './api';

class PaymentsApi extends CrudApi {
  constructor() {
    super('/payments');
  }

  getPaymentsForOrganization(orgId) {
    return this.get('/payments', {
      qs: {
        organization_id: orgId,
      },
    });
  }
}
const paymentsApi = new PaymentsApi();
export default paymentsApi;
