<template>
  <div>
    <base-menu-page
      :header="header"
      :operations="operations"
      :sections="sections"
      @search="search = $event"
    />
    <router-view :search="search"></router-view>
  </div>
</template>

<script>
export default {
  name: 'BrandingSection',
  data() {
    return {
      search: '',
    };
  },
  computed: {
    header() {
      return {
        logo: 'paint-brush',
        title: this.$t('branding.name'),
        subtitle: this.$t('branding.description'),
      };
    },
    sections() {
      return [

      ];
    },
    operations() {
      return [];
    },
  },
};
</script>
