import { CrudApi } from './api';

class ServiceProvidersApi extends CrudApi {
  constructor() {
    super('service_providers');
  }

  listForOrg(orgId) {
    return this.list({ qs: { organization_id: orgId } });
  }

  orgFields() {
    return this.get('service_providers/ORGANIZATION/fields', {});
  }
  userFields() {
    return this.get('service_providers/USER/fields', {});
  }
}

const serviceProvidersAPI = new ServiceProvidersApi();

export default serviceProvidersAPI;
