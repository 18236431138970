import store from '@/store';
import apis from '@/utils/apis';
import socket from '@/socket';
import { loadLanguageAsync } from '@/i18n';

import { routes as Home } from './Home';
import { routes as Services } from './Services';
import { routes as Activity } from './Activity';
import { routes as Administration } from './Administration';
import { routes as SystemSection } from './System';
import { routes as Reporting } from './Reporting';
import { routes as Organization } from './Organizations';

import PageNotFound from './components/PageNotFound';

const TheMain = () => import(/* webpackChunkName: "mainApp" */ './TheMain');
const LoginCompletePayment = () => import(/* webpackChunkName: "mainApp" */ './LoginCompletePayment');

const PageNotFoundRoute = {
  path: '/:catchAll(.*)',
  component: PageNotFound,
};

const MainRoutes = [
  {
    path: '',
    name: 'Main',
    component: TheMain,
    children: [
      ...Home,
      ...Services,
      ...Activity,
      ...Administration,
      ...SystemSection,
      ...Reporting,
      ...Organization,
      PageNotFoundRoute,
    ],
    async beforeEnter(to, from, next) {
      const isWaitingForBilling = () => (store.getters.userContext || {}).hasToCaptureBillingDetails;
      const paymentMethodResponse = () => (store.getters.userContext || {}).paymentMethod;
      if (to.path === '/' || to.path === '') {
        next({ path: '/home', query: to.query });
        return;
      }

      if (store.getters.loggedIn) {
        const entryPoint = store.getters.myOrganization.entryPoint;
        const userLang = store.getters.userContext.locale;
        socket.init();
        store.dispatch('loadServiceDescriptors');
        store.dispatch('loadBillingSettings', store.getters.selectedOrganization.id);
        await Promise.all([
          loadLanguageAsync('events', userLang),
          loadLanguageAsync('main', userLang),
          loadLanguageAsync('roles', userLang),
          loadLanguageAsync('plugins', userLang),
          loadLanguageAsync('login', userLang),
          loadLanguageAsync('notifications', userLang),
        ]);

        if (to.query.org && entryPoint !== to.query.org) {
          const resp = await apis.organizations.getOrgListWithServiceConnections({ qs: { entry_point: to.query.org } });
          await store.dispatch('changeOrganization', resp.data.find(o => o.entryPoint === to.query.org));
        }

        if (isWaitingForBilling()
          && to.name !== 'loginCompletePayment') {
          next({ name: 'loginCompletePayment', params: {paymentMethod: paymentMethodResponse}});
          return;
        }
        next();
      } else {
        next({ path: `/login${to.path}`, query: to.query, replace: true });
      }
    },
  },
  {
    path: 'completePayment',
    component: LoginCompletePayment,
    name: 'loginCompletePayment',
    props: true,
    meta: { protected: ['admin:billing'] },
    async beforeEnter(to, from, next) {
      const paymentMethodResponse = () => (store.getters.userContext || {}).paymentMethod;
      to.params.paymentMethod = paymentMethodResponse();
      await loadLanguageAsync('main');
      await loadLanguageAsync('trial');
      next();
    },
  },
];

export default MainRoutes;
