<template>
  <div class="dashboard-section">
    <base-panel :border="border">
      <template 
        v-if="$slots.header"
        #header 
      >
        <slot name="header">
        </slot>
      </template>
      <slot></slot>
    </base-panel>
  </div>
</template>

<script>
export default {
  name: 'DashboardSection',
  props: {
    border: {
      type: Boolean,
      default: true,
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/animations';

.base-panel {
  height: 100%;
}

.dashboard-section {
  animation: fadein .5s;
  margin-right: 20px;
}

</style>
