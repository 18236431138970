<template>
  <tree-node 
    v-for="node in nodes" 
    :key="node.id" 
    :node="node" 
    :icon="icon" 
    isTopLevel 
    :valueSet="valueSet" 
    @update:modelValue="input"
  /> 
</template>
    
<script>
export default {
    name: 'TreeSelect',
    props: {
        nodes: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
        }
    } ,
    computed: {
        valueSet() {
            return new Set(this.modelValue);
        },
    },
    methods: {
        input(checked, value) {
            if (checked) {
                this.valueSet.add(value);
            } else {
                this.valueSet.delete(value);
            }
            const newValue = Array.from(this.valueSet);
            this.$emit('update:modelValue', newValue);
        },
    },
}
</script>