<template>
  <div class="radio-button clickable">
    <input 
      :id="labelId" 
      type="radio" 
      :class="{ 'clickable': !disabled }" 
      :name="name" 
      :checked="modelValue" 
      :tabindex="disabled ? -1 : 0" 
      :aria-checked="modelValue ? modelValue : null" 
      :aria-label="ariaLabel"
      :disabled="disabled"
      @change="change" 
    >
    <div 
      class="radio" 
      :class="{ disabled }"
    ></div><label :for="labelId">{{ label }}</label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

 
export default {
  name: 'RadioButton',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      labelId: `label-${uuidv4()}`,
    };
  },
  methods: {
    change(e) {
      this.$emit('update:modelValue', e.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
.radio-button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
  }

  .radio {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: #efefef;
    border-radius: 50%;
    margin-right: 15px;

    &.disabled {
      filter: grayscale(1);
      opacity: 0.35;
    }
  }

  input[type=radio]:focus + .radio {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
  }

  input[type=radio]:focus-visible + .radio {
    outline: var(--blue) solid medium;
    border: 1px solid;
    box-sizing: border-box;
  }

}
</style>
