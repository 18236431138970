import {ComponentInternalInstance, computed} from "vue";

/**
 * Represents the event listeners supported by this composable
 */
export type CmcChartEventListeners = {
    handleClick: Function,
    handleLegendSelectChanged: Function
}

/**
 * Composable allowing you to listen to events on the chart and report event data
 * @param instance The component instance that uses the composable
 * @param emit The event emission function for the component that uses this composable
 */
export default function useCmcChartEventListeners(instance: ComponentInternalInstance | null, emit: Function): CmcChartEventListeners {
    const hasClickEventListener = computed(() => !!instance?.vnode.props?.onClick);
    const hasLegendSelectChangedListener = computed(() => !!instance?.vnode.props?.onLegendSelectChanged);

    const handleClick = (eventMetadata: any) => {
        if (hasClickEventListener.value) {
            const clickedValue = eventMetadata.componentType === 'series' ?  eventMetadata.name : eventMetadata.value
            emit('click', {value: clickedValue});
        }
    };

    const handleLegendSelectChanged = (eventMetadata: any) => {
        if (hasLegendSelectChangedListener.value) {
            emit('legendSelectChanged', eventMetadata);
        }
    }

    return {
        handleClick,
        handleLegendSelectChanged
    }
}