<template>
  <div class="column-text">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ColumnText',
};
</script>

<style lang="scss" scoped>
.column-text, .column-text > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
