<template>
  <base-loader v-if="loading"></base-loader>
  <div v-else>
    <section-header 
      :noTitle="!!breadcrumb" 
      :searchEnabled="searchEnabled" 
      @search="$emit('search', $event)"
    >
      <base-breadcrumb 
        v-if="breadcrumb" 
        :items="breadcrumb"
      />
      <template #logo>
        <base-icon 
          v-if="!breadcrumb" 
          :icon="header.logo"
        />
      </template>
      <template #title>
        <div v-if="!breadcrumb">
          {{ header.title }}
        </div>
      </template>
      <template #subtitle>
        <div v-if="!breadcrumb">
          {{ header.subtitle }}
        </div>
      </template>
      <template #rhs>
        <div>
          <base-button 
            v-for="operation in operations" 
            :key="operation.label" 
            v-permission="operation.permission" 
            :href="operation.href" 
            class="action" 
            type="button" 
            :rounded="true"
            :disabled="operation.disabled"
            :tooltip-label="operation.tooltipLabel"
            size="sm" 
            @click="operation.action ? operation.action() : null"
          >
            <base-icon :icon="operation.icon" />
            {{ $tn(operation.label) }}
          </base-button>
        </div>
      </template>
      <template 
        v-if="optionKey && options && options.length > 0" 
        #sort
      >
        <div>
          <base-select 
            v-model="selectedOption"
            :items="options"
            @update:modelValue="setOptionQuery"
          />
        </div>
      </template>
    </section-header>
    <base-dashboard 
      v-if="multiSection || !noPadding" 
      :hideLhs="!multiSection"
    >
      <template #lhs>
        <dashboard-section class="nav">
          <div v-if="toNavigation">
            <panel-item 
              v-for="section in sections"  
              :key="section.icon" 
              v-protected="section.to" 
              v-permission="section.permission" 
              :children="section.children" 
              :to="section.to" 
              :displayAllChildren="displayAllChildren"
            >
              <div class="panel-text">
                {{ section.title }}
              </div>
            </panel-item>
          </div>
          <div v-else>
            <panel-item 
              v-for="section in sections" 
              :key="section.icon" 
              v-permission="section.permission" 
              :children="section.children" 
              :active="active(section)" 
              :displayAllChildren="displayAllChildren"
              @click="switchTab(section.name)" 
            >
              <div class="panel-text">
                {{ section.title }}
              </div>
            </panel-item>
          </div>
        </dashboard-section>
      </template>
      <template #rhs>
        <div class="content">
          <slot></slot>
        </div>
      </template>
    </base-dashboard>
    <template v-else-if="noPadding">
      <div class="content">
        <slot></slot>
      </div>
    </template>
  </div>
</template>
<script>
import authz from '@/authz';

export default {
  name: 'BaseMenuPage',
  props: {
    header: {
      type: Object,
      /* {
        title:  ...
        subtitle:  ...
        logo: ...
      } */
    },
    loading: {
      type: Boolean,
    },
    noPadding: {
      default: false,
      type: Boolean,
    },
    breadcrumb: {
      type: Array,
    },
    sections: {
      type: Array,
      default: () => [],
    },
    searchEnabled: {
      type: Boolean,
      default: false,
    },
    stickyTab: {
      type: String,
    },
    /* {
      label: ...
      action: ...
      icon: ...
    } */
    operations: {
      type: Array,
    },
    displayAllChildren: {
      type: Boolean,
      default: false,
    },
    optionKey: {
      type: String,
    },
    options: {
      type: Array,
    }
  },
  emits: ['search'],
  data() {
    return {
      selectedOption: this.optionKey ? this.$route.query[this.optionKey] : "ALL",
    };
  },
  computed: {
    toNavigation() {
      return this.sections.every(s => s.to);
    },
    activeTab() {
      return this.$route.query.menu_tab || this.stickyTab;
    },
    permittedSections() {
      return this.sections.filter(s => !s.permission || authz.hasPermission(s.permission));
    },
    multiSection() {
      return this.permittedSections.length > 1;
    },
  },
  methods: {
    active(section) {
      if (this.activeTab && this.activeTab === section.name) {
        return true;
      }
      if (section.children) {
        return !!section.children.find(c => (c.name && c.name === this.activeTab)
          || (!this.activeTab && c.to && c.to.path && this.$route.path.startsWith(c.to.path)));
      }
      return false;
    },
    switchTab(t) {
      this.$router.push({ path: this.$route.path, query: { menu_tab: t } });
    },
    setOptionQuery() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [this.optionKey]: this.selectedOption,
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  :deep(.form-page) {
    padding: 0;
    .main-title {
      margin-top: 0;
    }
  }
}
</style>
