<template>
  <div 
    class="radio-group" 
    role="radiogroup"
  >
    <radio-button 
      v-for="option in options" 
      :key="option.name" 
      :name="name" 
      :label="option.is18n ? $t(option.name) : option.name" 
      :modelValue="selected === option.value" 
      :ariaLabel="option.is18n ? $t(option.name) : option.name"
      :disabled="disabled"
      @update:modelValue="input(option.value)" 
    />
  </div>
</template>

<script>
export default {
  name: 'RadioGroup',
  props: {
    modelValue: {
      type: [ String, Number, Boolean ],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
    },
    disabled : {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: null,
    };
  },
  created() {
    if (!this.modelValue && this.options.length) {
      this.input(this.options[0].value);
    } else {
      this.input(this.modelValue);
    }
  },
  methods: {
    input(value) {
      this.selected = value;
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped lang="scss">
.radio-button {
    margin-top: 5px;
}
</style>
