import { ReadOnlyApi } from './api';

class InfraApi extends ReadOnlyApi {
  constructor() {
    super('/infra/services');
  }

  findInfra(serviceCode, view, entityType, options) {
    return this.get(`${this.path}/${serviceCode}/views/${view}/${entityType}`, options);
  }
}

const infraApi = new InfraApi();

export default infraApi;
