import { ReadOnlyApi } from './api';

class ActivityApi extends ReadOnlyApi {
  constructor() {
    super('activity_log');
  }

  getCodes() {
    return this.get('/activity_log/codes');
  }
}

const activityApi = new ActivityApi();

export default activityApi;
