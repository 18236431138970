<!-- eslint-disable vue/no-v-html -->
<template>
  <base-form
    v-if="organization"
    :defaultBack="{ name: 'organizationDetails' }"
    :disabled="invalid"
    @submit="submit"
    @cancel="cancel"
  >
    <form-row
      label="billable"
      descriptionLabel="non_billable_invoice_consumption"
    >
      <div class="billing">
        <base-toggle
          v-model="isBillable"
          class="toggle"
        ></base-toggle>
      </div>
    </form-row>

    <div v-if="isBillable">
      <div class="row">
        <form-row
          class="col-lg-4 billable-config-column"
          label="billable_start_date"
        >
          <date-input
            :modelValue="modifiedBillableOrgInfo.billableStart"
            @update:modelValue="setBillableStart($event)"
          />
        </form-row>

        <form-row
          class="col-lg-4 billable-config-column"
          label="pricing_package"
        >
          <div>
            <base-select
              v-model="modifiedBillableOrgInfo.pricingPackage"
              :items="getPricingPackages"
              @update:modelValue="updateEffectivePricing(false)"
            />
          </div>
        </form-row>

        <form-row
          v-if="hasPricingPackages"
          class="col-lg-4"
          label="currency"
        >
          <base-select
            v-model="modifiedBillableOrgInfo.currency"
            :placeholder="$t('select_currency')"
            :items="orgCurrencyOptions"
          ></base-select>
        </form-row>
      </div>

      <div class="main-title">
        <h2>{{ $t('billing_settings.address') }}</h2>
        <span class="optional">({{ $t('optional') }})</span>
      </div>
      <AddressFormRow
        v-model="address"
        requireFields
      />

      <div class="main-title">
        <h2>{{ $t('billing_mode') }}</h2>
      </div>

      <form-row label="billing_mode">
        <base-select
          v-model="modifiedBillableOrgInfo.billingMode"
          :items="getBillingModes"
        ></base-select>
      </form-row>
    </div>
  </base-form>
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { getNow } from '@/utils/dates';
import AddressFormRow from '@/components/AddressFormRow.vue';
import {pricingMixin} from "@/mixins/pricing";

export default {
  name: 'OrganizationBillingForm',
  components: {
    AddressFormRow,
  },
  mixins: [pricingMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      isBillable: false,
      modifiedBillableOrgInfo: {
        billingMode: null,
        pricingPackage: null,
        billableStart: getNow(),
      },
      pricingPackages: [],
      dateFormat: 'YYYY-MM-DD',
      billingModes: [{
        label: 'manual',
        value: 'MANUAL',
      },
        {
          label: 'credit_card',
          value: 'CREDIT_CARD',
        },
      ],
      address: {
        lineOne: null,
        lineTwo: null,
        city: null,
        province: null,
        country: null,
        postalCode: null,
      },
      effectivePricing: undefined,
      supportedBillingCurrencies: undefined
    };
  },
  computed: {
    invalid() {
      return (this.isBillable && !this.modifiedBillableOrgInfo.billableStart) || !this.validAddress;
    },
    validAddress(){
      const addressHasFields =  !!this.address.lineOne || !!this.address.lineTwo
        || !!this.address.city || !!this.address.province
        || !!this.address.postalCode || !!this.address.country;
      const hasRequiredFields = !!this.address.lineOne
        && !!this.address.city && !!this.address.province
        && !!this.address.postalCode && !!this.address.country
      return addressHasFields ? hasRequiredFields : true;
    },
    getBillingModes() {
      return this.billingModes;
    },
    hasPricingPackages() {
      return this.pricingPackages.length !== 0;
    },
    updatedBillableOrgInfo() {
      return {
        id: this.modifiedBillableOrgInfo.id ? this.modifiedBillableOrgInfo.id : null,
        billableStart: this.$date(this.modifiedBillableOrgInfo.billableStart, this.dateFormat),
        pricingPackage: {
          id: this.modifiedBillableOrgInfo.pricingPackage,
        },
        address: {
          ...this.address,
        },
        billingMode: this.modifiedBillableOrgInfo.billingMode,
        currency: this.modifiedBillableOrgInfo.currency
      };
    },
    getPricingPackages() {
      return this.pricingPackages.length === 0 ? [] :
        this.pricingPackages.filter(pp => pp.status !== 'EXPIRED').map(pp => (
          {
            label: pp.name[this.locale],
            value: pp.id,
          }
        ));
    },
    ...mapGetters([
      'myOrganization',
      'globalProperties',
      'selectedOrganization',
      'locale',
      'userContext',
    ]),
    orgCurrencyOptions() {
      let curr = []

      if (this.effectivePricing != undefined) {
        curr = this.supportedBillingCurrencies
          .filter(c => this.effectivePricing.supportedCurrencies.includes(c))
          .reduce((obj, code) => {
            obj.push({
              label: code,
              value: code
            });
            return obj;
          }, [])
      }

      return curr;
    },
  },
  async created() {
    await this.fetchBillableOrganizationInfo();
    await this.fetchPricingPackages();
    this.supportedBillingCurrencies = await this.fetchBillingSupportedCurrencies()
  },
  methods: {
    setBillableStart(date) {
      if (this.hasPricingPackages) {
        this.modifiedBillableOrgInfo.billableStart = date;
        this.updateEffectivePricing(true);
      }
    },
    async submit() {
      if (this.isBillable
        && (!this.modifiedBillableOrgInfo.pricingPackage || !this.modifiedBillableOrgInfo.currency)
        && this.hasPricingPackages
      ) {
        notify.error(this.$t('invalid_billable_config_error'));
      } else {
        if (this.isBillable) {
          this.$emit('submit', this.updatedBillableOrgInfo);
        } else {
          this.cancel();
        }
      }
    },
    async fetchPricingPackages() {
      const parentId = this.organization.parent && this.organization.parent.id !== undefined ?
        this.organization.parent.id : this.selectedOrganization.id;
      const resp = await apis.pricingPackages.list({
        qs: {
          organization_id: this.organization.id ? this.organization.id : parentId,
          applicable: true
        },
      });
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.pricingPackages = resp.data;
      }
    },
    async fetchBillableOrganizationInfo() {
      const resp = await apis.billable.getBillableOrgInfo(this.organization.id);
      if (resp.status !== 200) {
        if (resp.status !== 404) {
          notify.error(this.$t('unexpected_error'));
        } else {
          this.modifiedBillableOrgInfo = null;
        }
      } else if (resp.data) {
        this.modifiedBillableOrgInfo = { ...resp.data };
        this.modifiedBillableOrgInfo.pricingPackage = (resp.data.pricingPackage || {}).id;
      }
    },
    cancel() {
      this.$router.navigateBackOrDefault('organizationDetails');
    },
    async updateEffectivePricing(keepCurrencyIfAvailable = false) {
      if (!this.modifiedBillableOrgInfo.pricingPackage) {
        this.effectivePricing = undefined;
        return;
      }

      const pricingPackageEntity = this.pricingPackages.find(p => p.id == this.modifiedBillableOrgInfo.pricingPackage)
      if (!pricingPackageEntity) {
        this.effectivePricing = undefined;
        return;
      }

      const effective = await apis.pricings.getEffectivePricing(
        pricingPackageEntity.pricingDefinition.id,
        this.$date(this.modifiedBillableOrgInfo.billableStart, "YYYY-MM-DD", true)
      );

      if (effective && effective.status !== 200) {
        notify.error(this.$t('monetization.error_fetching_pricing'));
        return null;
      }
      this.effectivePricing = effective.data
      this.updateCurrencySelection(keepCurrencyIfAvailable);
    },
    // Updates/resets the selected currency base on the options available
    updateCurrencySelection(keepCurrencyIfAvailable) {
      if (!keepCurrencyIfAvailable) {
        this.modifiedBillableOrgInfo.currency = undefined;
        return;
      }

      if (!this.orgCurrencyOptions.find(o => o.value == this.modifiedBillableOrgInfo.currency)) {
        this.modifiedBillableOrgInfo.currency = undefined
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.billing {
  display: flex;
  flex-direction: column;

  .toggle {
    margin-bottom: 10px;
  }
}

.billable-config-column {
   padding-right: 10px;
}

.main-title {
  margin-bottom: 10px;
}
</style>
