import { ReadOnlyApi } from './api';

class Insights extends ReadOnlyApi {
  constructor() {
    super('monetization/insights');
  }

  invoicing(organizationId) {
    return this.get('/monetization/insights/invoicing', {
      qs: {
        organization_id: organizationId
      }
    });
  }
}

const insights = new Insights();

export default insights;
