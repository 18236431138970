import { ReadOnlyApi } from './api';

class SupportApi extends ReadOnlyApi {
  constructor() {
    super('support');
  }

  sendJiraServiceDeskEmail(email, orgId) {
    return this.post(`${this.path}/send`, {
      body: email,
      qs: {
        organizationId: orgId,
      },
    });
  }

  isSupportEnabled() {
    return this.get(`${this.path}/anon/configured`);
  }
}

const supportApi = new SupportApi();

export default supportApi;
