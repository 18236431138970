<template>
  <div 
    class="time-filter" 
    :class="{ 'boxed-filter': !simple }"
  >
    <div 
      v-if="!simple" 
      class="clickable" 
      role="button" 
      tabindex="0" 
      @keyup.enter="opened = true" 
      @click="opened = true"
    >
      <div class="start-end-container">
        <div class="date-block">
          <div>{{ displayStart }}</div>
          <div class="description">
            {{ startTime }}
          </div>
        </div>
        <div class="fa fa-long-arrow-right" />
        <div class="date-block">
          <div>{{ displayEnd }}</div>
          <div class="description">
            {{ endTime }}
          </div>
        </div>
      </div>
    </div>
    <a 
      v-else 
      class="simple-filter clickable" 
      role="button" 
      tabindex="0" 
      @keyup.enter="opened = true" 
      @click="opened = true"
    >{{ $('time_filters.select_range') }}</a>
    <base-dropdown
      class="time-filter-dropdown"
      position="left"
      :opened="opened"
      @close="closeModal"
    >
      <div class="filter-container">
        <base-calendar 
          v-if="showCalendar"
          ref="calendar" 
          ranged
          :start="draftStart"
          :end="draftEnd"
          :maxDate="maxDate"
          :presetsEnabled="false"
          @select="setDraftDates"
        />
        <div 
          v-else 
          class="time-picker"
        >
          <action-icon 
            icon="fa fa-times" 
            tooltipLabel="close" 
            class="close-modal" 
            size="small" 
            @click="closeTime"
          />
          <span class="time-picker-date"> <span class="date-text-format">{{ $t('start_date') }}</span> {{ draftStartDisplay }}</span>
          <base-time-picker
            :date="draftStart"
            :standalone="false"
            @clearError="error=undefined"
            @timeUpdate="updateStartTime"
          />
          <span class="time-picker-date"> <span class="date-text-format">{{ $t('end_date') }}</span> {{ draftEndDisplay }}</span>
          <base-time-picker
            :date="draftEnd"
            :standalone="false"
            :error="error"
            @clearError="error=undefined"
            @timeUpdate="updateEndTime"
          />
          <div>
            <div class="choose-time">
              <base-button 
                :disabled="!!error" 
                class="submit-time" 
                size="xs" 
                type="submit"
                @click="setDateFilter" 
              >
                {{ $t('apply') }}
              </base-button>
            </div>
          </div>
        </div>
        <div class="interval-options">
          <time-filter-option 
            v-for="(filterOption, idx) in predefinedOptions" 
            :key="idx" 
            :filterOption="filterOption" 
            :active="activeFilterOption === idx" 
            @optionSelect="onDateChange($event, idx)"
          />
        </div>
      </div>
    </base-dropdown>
  </div>
</template>
<script>
import { getDate, getNow, startOfDay, subHours, diff, format, setTime, isBefore, HOUR_MIN_IN_24_FORMAT, SIMPLE_DATE_FORMAT } from '@/utils/dates';
import TimeFilterOption from './TimeFilterOption';
import BaseViewfilter from './BaseViewFilter';
import ActionIcon from '../ActionIcon';


const DATE_TIME_COMPARE_FORMATTER = 'yyyy-MM-Do';
const CALENDAR_MODE = 'calendar';
const TIME_MODE = 'time';

export default {
  name: 'TimeFilter',
  components: { TimeFilterOption, ActionIcon },
  extends: BaseViewfilter,
  emits: ['change'],
  data() {
    return {
      opened: false,
      selectedStart: null,
      selectedEnd: null,
      predefinedOption: null,
      activeFilterOption: null,
      mode: CALENDAR_MODE,
      error: undefined,
      draftStart: null,
      draftEnd: null,
    };
  },
  computed: {
    showCalendar() {
      return this.mode === CALENDAR_MODE;
    },
    showTime() {
      return this.mode === TIME_MODE;
    },
    maxDate() {
      return startOfDay(getNow());
    },
    draftStartDisplay() {
      return format(this.draftStart, SIMPLE_DATE_FORMAT);
    },
    displayStart() {
      return format(this.selectedStart, SIMPLE_DATE_FORMAT);
    },
    draftEndDisplay() {
      return format(this.draftEnd, SIMPLE_DATE_FORMAT);
    },
    displayEnd() {
      return format(this.selectedEnd, SIMPLE_DATE_FORMAT);
    },
    startTime() {
      return format(this.selectedStart, HOUR_MIN_IN_24_FORMAT);
    },
    endTime() {
      return format(this.selectedEnd, HOUR_MIN_IN_24_FORMAT);
    },
    draftStartTime() {
      return format(this.draftStart, HOUR_MIN_IN_24_FORMAT);
    },
    draftEndTime() {
      return format(this.draftEnd, HOUR_MIN_IN_24_FORMAT);
    },
    days() {
      return diff(this.selectedEnd, this.selectedStart, 'days') + 1;
    },
    predefinedOptions() {
      return (this.filter.filterOptions || []).map((o) => {
        if (o.value) {
          const [start, end] = o.value.split(',');
          return { name: o.name, start, end };
        }
        return { start: '', end: '' };
      }).map(({ name, start, end }) => ({
        name,
        start: getDate(start),
        end: getDate(end),
      }));
    },
  },
  created() {
    this.checkQueryForDates();
    this.setPredefinedFilter();
    this.onDateChange({
      start: this.selectedStart,
      end: this.selectedEnd,
    }, this.activeFilterOption);
  },
  methods: {
    onDateChange(e, idx) {
      this.activeFilterOption = idx;
      this.predefinedOption = e.predefinedOption;
      this.selectedStart = e.start;
      this.selectedEnd = e.end;
      this.draftStart = this.selectedStart;
      this.draftEnd = this.selectedEnd;
      const formattedStart = format(this.selectedStart);
      const formattedEnd = format(this.selectedEnd);
      const queryKey = this.filter.queryKey;
      this.$emit('change', {
        start: formattedStart,
        end: formattedEnd,
        ...(queryKey ? { [queryKey]: `${formattedStart}:${formattedEnd}` } : {}),
      });
      this.closeTime();
    },
    dateError() {
      return isBefore(this.draftEnd, this.draftStart) ? 'end_before_start_error' : undefined;
    },
    setDraftDates(e, idx) {
      this.activeFilterOption = idx;
      this.predefinedOption = e.predefinedOption;
      this.draftStart = e.start;
      this.draftEnd = e.end;

      if (!e.predefinedOption && this.$refs.calendar && this.$refs.calendar.endDateSelected) {
        this.mode = TIME_MODE;
      }
      this.error = this.dateError();
    },
    closeModal() {
      this.closeTime();
      this.opened = false;
      this.draftStart = this.selectedStart;
      this.draftEnd = this.selectedEnd;
    },
    setDateFilter() {
      this.selectedStart = this.draftStart;
      this.selectedEnd = this.draftEnd;
      const formattedStart = format(this.selectedStart);
      const formattedEnd = format(this.selectedEnd);
      const queryKey = this.filter.queryKey;
      this.$emit('change', {
        start: formattedStart,
        end: formattedEnd,
        ...(queryKey ? { [queryKey]: `${formattedStart}:${formattedEnd}` } : {}),
      });
      this.closeTime();
      this.opened = false;
    },
    updateStartTime(time) {
      this.draftStart = setTime(this.draftStart, time.hours, time.minutes, 0);
      this.error = this.dateError();
    },
    updateEndTime(time) {
      this.draftEnd = setTime(this.draftEnd, time.hours, time.minutes, 0);
      this.error = this.dateError();
    },
    closeTime() {
      this.mode = CALENDAR_MODE;
    },
    setPredefinedFilter() {
      const activeIdx = this.predefinedOptions
        .findIndex(
          o => format(o.start, DATE_TIME_COMPARE_FORMATTER)
            === format(this.selectedStart, DATE_TIME_COMPARE_FORMATTER)
          && format(o.end, DATE_TIME_COMPARE_FORMATTER)
            === format(this.selectedEnd, DATE_TIME_COMPARE_FORMATTER),
        );
      if (activeIdx >= 0) {
        this.activeFilterOption = activeIdx;
      }
    },
    checkQueryForDates() {
      if (this.$route && this.$route.query['global.start'] && this.$route.query['global.end']) {
        this.selectedStart = getDate(this.$route.query['global.start']);
        this.selectedEnd = getDate(this.$route.query['global.end']);
      } else {
        this.selectedStart = subHours(getNow(), 24);
        this.selectedEnd = getNow();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixins";
.time-filter {
  position: relative;

  &.boxed-filter {
    border: 1px var(--border-plus-ten) solid;
    padding: 15px;
    min-width: 180px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;

  .time-filter-dropdown {
    position: absolute;
    float: right;
    min-width: 300px;
    right: 0;
    bottom: 0;

    :deep(.dropdown) {
      min-width: 400px;
    }
  }
  .period-indicator {
    margin-bottom: 5px;
  }

  .start-end-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .date-block {
      .description {
        text-align: center;
        margin: 0;
      }
    }
  }
}

.filter-container {
  display: flex;
  width: 100%;
  .interval-options {
    border-left: 1px solid var(--border-plus-ten);
    :deep(.option) {
      white-space: nowrap;
    }
  }
  :deep(.base-calendar) {
    @include not-phone {
      width: 400px;
    }
    @include phone {
      width: 100%;
      flex: 2;
    }

  }
  .time-picker {
    @include not-phone {
      width: 300px;
    }
    @include phone {
      width: 100%;
      flex: 2;
    }
  }
}


.date-text-format {
  font-weight: 500;
}

.time-picker {
  padding: 10px;
  position: relative;
  .close-modal {
    position: absolute;
    top: 13px;
    right: 13px;

    :deep(div) {
      display: flex;
    }
  }
}

.choose-time {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  .submit-time {
    margin-right: 14px;
  }
}
</style>
