import apis from "../apis";


export const waitForCompletion = async (taskId: string, maxAttempts: number = 12, waitInMs: number = 5000) => {
    let pollingStatus;
    for (let attempts = maxAttempts; attempts >= 0; attempts--) {
      const res = await apis.tasks.find(taskId);
      if (res.ok || res.status === 200) {
        pollingStatus = res.data.status
      } else {
        pollingStatus = 'FAILURE'
        break;
      }
      if (pollingStatus === 'SUCCESS' || pollingStatus === 'PARTIAL_SUCCESS') {
        break;
      }
      if (pollingStatus === 'FAILURE') {
        break;
      }
      await new Promise(resolve => setTimeout(resolve, waitInMs));
    }
    return pollingStatus;
}
  