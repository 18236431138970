const OPERATION = {
  DENY: { name: 'deny', icon: 'fa fa-ban' },
  REMOVE: { name: 'remove', icon: 'fa fa-trash-o' },
  TERMINATE: { name: 'terminate', icon: 'fa fa-times' },
  EXTEND: { name: 'extend', icon: 'fa fa-repeat' },
  CONVERT: { name: 'convert', icon: 'fa fa-dollar' },
  APPROVE: { name: 'approve', icon: 'fa fa-check' },
  RESEND_VALIDATION: { name: 'resend_validation', icon: 'fa fa-envelope-o' },
  RESEND_ACTIVATION: { name: 'resend_activation', icon: 'fa fa-envelope-o' },
};

const STATUS = {
  ONGOING: { name: 'ongoing', operations: [OPERATION.EXTEND, OPERATION.CONVERT, OPERATION.TERMINATE, OPERATION.RESEND_ACTIVATION] },
  EXPIRED: { name: 'expired', operations: [OPERATION.EXTEND, OPERATION.CONVERT] },
  PENDING: { name: 'pending', operations: [OPERATION.APPROVE, OPERATION.DENY, OPERATION.REMOVE] },
  SUBMITTED: { name: 'submitted', operations: [OPERATION.DENY, OPERATION.REMOVE, OPERATION.RESEND_VALIDATION] },
  CONVERTED: { name: 'converted', operations: [OPERATION.REMOVE] },
  DENIED: { name: 'denied', operations: [OPERATION.APPROVE, OPERATION.REMOVE] },
  PURGED: { name: 'purged', operations: [OPERATION.REMOVE] },
};

const ERROR = {
  ENDPOINT_NOT_FOUND: { default: 'trials.trial_not_found' },
  NOT_FOUND: { default: 'trials.trial_not_found' },
  INVALID_TRIAL_STATE: {
    deny: 'trials.deny_invalid_trial_state',
    remove: 'trials.remove_invalid_trial_state',
    terminate: 'trials.terminate_invalid_trial_state',
    extend: 'trials.extend_invalid_trial_state',
    convert: 'trials.convert_invalid_trial_state',
    approve: 'trials.approve_invalid_trial_state',
    resend_validation: 'trials.resend_validation_invalid_trial_state',
    resend_activation: 'trials.resend_activation_invalid_trial_state',
    default: 'trials.default_invalid_trial_state' },
  INVALID_FIELD: {
    deny: 'trials.deny_reason_too_long',
    convert: 'trials.convert_start_date_required',
    default: 'trials.default_invalid_field' },
};

export {
  STATUS,
  OPERATION,
  ERROR,
};
