<template>
  <form-page 
    hideCancel 
    hideSubmit 
    :title="$t('quotas.add_quota')"
  >
    <template #description>
      <base-breadcrumb :items="breadcrumb" />
    </template>
    <MultiStep 
      :currentStep="stepNumber" 
      :steps="steps"
    >
      <transition 
        name="multi-step-fade" 
        mode="out-in"
      >
        <QuotaForm 
          v-if="stepNumber == 0" 
          :errors="errors" 
          submitLabel="next" 
          @submit="createQuota"
        />
        <AssignQuotaForm 
          v-else-if="stepNumber == 1" 
          :connection="connection" 
          switchOrgRouteName="quotasList" 
          creationMode
          @assigned="onQuotaAssigned" 
        />
      </transition>
    </MultiStep>
  </form-page>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseBreadcrumb from '@/components/BaseBreadcrumb';
import MultiStep from '@/app/Main/components/MultiStep';
import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import QuotaForm from './QuotaForm';
import AssignQuotaForm from './AssignQuotaForm';

export default {
  name: 'CreateQuotaForm',
  components: { QuotaForm, AssignQuotaForm, MultiStep, BaseBreadcrumb },

  mixins: [resellerOrgNavigationMixin],
  data() {
    return {
      stepNumber: 0,
      connection: {},
      quota: {},
      errors: {
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'managedServiceConnections',
    ]),
    breadcrumb() {
      const breadcrumbs = [
        {
          name: this.selectedOrganization.name,
        },
      ];
      if (this.connection && this.connection.name) {
        breadcrumbs.push({ name: `${this.connection.name}` });
      }
      if (this.quota && this.quota.name) {
        breadcrumbs.push({ name: `${this.quota.name}` });
      }
      return breadcrumbs;
    },
    steps() {
      return [
        {
          label: this.$t('quotas.limits'),
          successLabel: this.$t('quotas.limits'),
          name: 'create',
        },
        {
          label: this.$t('organizations'),
          successLabel: this.$t('organizations'),
          name: 'organizations',
        },
      ];
    },
  },
  methods: {
    async createQuota(quota) {
      this.errors.name = '';
      const response = await apis.quotas.createQuota(quota);
      if (response.status === 403) {
        notify.error(this.$t('quotas.not_authorized_to_add_quota'));
      } else if (response.errors) {
        if (response.errors.map(c => c.errorCode).includes('ALREADY_EXISTS')) {
          this.errors.name = this.$t('name_already_exists');
        }
        notify.error(this.$t('quotas.create_quota_error'));
      } else {
        this.onQuotaCreated({ quota: response.data });
      }
    },
    onQuotaCreated(event) {
      this.quota = event.quota;
      this.connection = this.managedServiceConnections
        .find(sc => sc.id === this.quota.serviceConnection.id);
      this.connection = {
        ...(this.connection),
        organization: {
          id: this.connection.organizationId,
        },
      };
      this.stepNumber = 1;
    },
    onQuotaAssigned() {
      this.$router.push({ name: 'quotas-owned' });
    },
  },
};
</script>
