<template>
  <list-column 
    nonClickable 
    allowOverflow
  >
    <base-select
      v-model="selectedQuotaId"
      class="quota-list"
      :disabled="managingMyOwnOrganization"
      :items="items"
      @update:modelValue="setQuota"
      @click.stop
    />
  </list-column>
</template>

<script>
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import { mapGetters } from 'vuex';
import ListColumn from '@/components/list/ListColumn';

const PARENT_MANAGED_QUOTA = '_parent_managed_quota';

export default {
  name: 'AssignableQuotaRow',
  components: { ListColumn },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    quotas: {
      type: Array,
      required: true,
    },
    connection: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh'],
  data() {
    return {
      selectedQuotaId: null,
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
    managingMyOwnOrganization() {
      return !this.connectionOwner && this.selectedOrganization.id === this.organization.id;
    },
    connectionOwner() {
      return this.connection.organization.id === this.organization.id;
    },
    quotaAssignedToOrganization() {
      const quotaAssignedToOrg = this.quotas.find(q =>
        q.organizations.map(o => o.id).includes(this.organization.id),
      );
      if (!quotaAssignedToOrg) {
        // the current reseller doesn't have visibility on the quota assigned to the current org.
        return PARENT_MANAGED_QUOTA;
      }
      return quotaAssignedToOrg.id;
    },
    items() {
      let ownedQuotas = this.quotas.map(q => ({ label: q.name, value: q.id }));
      if (this.quotaAssignedToOrganization === PARENT_MANAGED_QUOTA) {
        ownedQuotas = [{ label: 'quotas.managed_by_parent', value: PARENT_MANAGED_QUOTA }, ...ownedQuotas];
      }
      return ownedQuotas;
    },
  },
  async created() {
    this.selectedQuotaId = this.quotaAssignedToOrganization;
  },
  methods: {
    async setQuota(id) {
      if (this.quotaAssignedToOrganization === id) {
        // the quota assignment hasn't changed.
        return;
      }
      this.loading = true;
      const quotaToAssign = this.quotas.find(q => q.id === id);
      const response = await apis.quotas.assignQuota(quotaToAssign.id, this.organization.id);
      if (response && response.status !== 200) {
        const message = response.status === 403 ? 'quotas.not_authorized_to_list_quotas' : 'unexpected_error';
        this.selectedQuotaId = this.quotas
          .find(q => q.organizations.map(o => o.id).includes(this.organization.id)).id;
        notify.error(this.$t(message));
      } else {
        notify.success(this.$t('quotas.assign_successful', { name: quotaToAssign.name }));
        this.$emit('refresh', this.organization.id);
      }

      this.loading = false;
    },
    refresh(organizationId) {
      this.$emit('refresh', organizationId);
    },

  },
};
</script>
<style scoped lang="scss">
.quota-list {
  min-width: 280px;
}
</style>
