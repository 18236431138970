<template>
  <div>
    <base-message 
      class="error-msg" 
      :icon="icon" 
      :textLabel="textLabel"
    />
    <slot></slot>
  </div>
</template>

<script>
import BaseMessage from './BaseMessage';

export default {
  name: 'ErrorMessage',
  components: { BaseMessage },
  extends: BaseMessage,
};
</script>

<style scoped lang="scss">
.error-msg {
  color: #D53F3F;
  text-align: center;
}
</style>
