<template>
  <base-form
    hideCancel
    submitLabel="continue"
    :disabled="incompleteForm"
    :executing="executing"
    @submit="submit"
  >
    <div class="contact-row enterprise-form-row">
      <form-row
        label="enterprise_registration.first_name"
      >
        <base-input
          v-model="registration.contactFirstName"
          class="registration-form-input"
        />
      </form-row>
      <form-row
        label="enterprise_registration.last_name"
        class="enterprise-form-row-right"
      >
        <base-input
          v-model="registration.contactLastName"
          class="registration-form-input"
        />
      </form-row>
    </div>
    <div class="enterprise-form-row">
      <form-row label="enterprise_registration.email">
        <base-input
          v-model="registration.contactEmail"
          class="registration-form-input"
          disabled
        />
      </form-row>
      <form-row
        label="enterprise_registration.organization_name"
        class="enterprise-form-row-right"
      >
        <base-input
          v-model="registration.organizationName"
          class="registration-form-input"
        />
      </form-row>
    </div>
    <LanguagePicker class="language-picker" />
  </base-form>
</template>

<script>

import {mapGetters} from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import LanguagePicker from '../components/LanguagePicker.vue';
import BaseForm from "@/components/form/BaseForm.vue";
import FormRow from "@/components/form/FormRow.vue";
import BaseInput from "@/components/input/BaseInput.vue";

export default {
  name: "EnterpriseRegistrationForm",
  components: {
    BaseInput, FormRow, BaseForm, LanguagePicker
  },
  data() {
    return {
      registrationToken: '',
      registration: {},
      executing: false,
      user: {},
    }
  },
  computed: {
    ...mapGetters(['locale']),
    incompleteForm() {
      return !this.registration
        || !this.registration.contactFirstName
        || !this.registration.contactLastName
        || !this.registration.contactEmail
        || !this.registration.organizationName;
    },
  },
  async created() {
    this.registrationToken = this.$route.query.registrationToken;
    const resp = await this.fetchRegistration();
    if (resp && resp.ok) {
      this.registration = resp.data;
    }
  },
  methods: {
    async fetchRegistration() {
      return await apis.enterpriseRegistrations.getRegistration(this.registrationToken);
    },
    async submit() {
      this.executing = true;
      const resp = await apis.enterpriseRegistrations.updateWithToken(this.registration, this.registrationToken);
      if (resp && resp.ok) {
        await this.$router.push({name: 'Login', query: {registrationToken: this.registrationToken}});
      } else {
        notify.error(this.$t("enterprise_registration.error_completing_registration"));
      }
      this.executing = false;
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.registration-form {
  position: relative;
  margin: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  background-color: var(--background);
}

.contact-row {
  padding-top: 20px;
}

.registration-form-input {
  width: 250px;
}

.enterprise-form-row {
  display: flex;
  justify-content: flex-start;
  padding-left: 62.5px;

  @include phone {
    flex-direction: column;
  }
}

.enterprise-form-row-right {
  padding-left: 62.5px;

  @include phone {
    padding-left: 0;
  }
}

.language-picker {
  position: absolute;
  right: 66px;
  bottom: 55px;

  @include phone {
    bottom: 60px;
  }
}

:deep(#submit) {
  margin-left: 62.5px;
}
</style>