const BillingSection = () => import(/* webpackChunkName: "admin" */ './BillingSection');
const BillingSettingsView = () => import(/* webpackChunkName: "admin" */ './settings/BillingSettingsView');
const BillingProviderView = () => import(/* webpackChunkName: "admin" */ './provider/BillingProviderView');
const BillingProviderForm = () => import(/* webpackChunkName: "admin" */ './provider/BillingProviderForm');
const InvoiceSettingsView = () => import(/* webpackChunkName: "admin" */ './invoice-config/InvoiceSettingsView');
const TaxProviderList = () => import(/* webpackChunkName: "admin" */ './taxes/TaxProviderList');
const ConfigureTaxProvider = () => import(/* webpackChunkName: "admin" */ './taxes/ConfigureTaxProvider');

export default [
  {
    path: 'billing',
    component: BillingSection,
    meta: {
      protected: ['reseller:billing'],
      title: () => 'billing',
    },
    children: [
      {
        path: '',
        redirect: { name: 'billing-settings-view' },
      },
      {
        name: 'billing-settings-view',
        path: 'settings',
        component: BillingSettingsView,
      },
      {
        name: 'billing-provider-view',
        path: 'providers',
        component: BillingProviderView,
      },
      {
        name: 'create-billing-provider',
        path: 'providers/configure',
        component: BillingProviderForm,
      },
      {
        name: 'update-billing-provider',
        path: 'providers/configure/:id',
        component: BillingProviderForm,
        props: route => ({ id: route.params.id }),
      },
      {
        name: 'tax-provider-list',
        path: 'taxes',
        component: TaxProviderList,
      },
      {
        name: 'configure-tax-provider',
        path: 'taxes/configure',
        component: ConfigureTaxProvider,
        props: route => ({
          address: route.params.address,
          configurationAttributes: route.params.configurationAttributes,
          providerType: route.params.providerType,
          inEdit: route.params.inEdit,
        }),
      },
      {
        name: 'edit-tax-provider',
        path: 'taxes/:id/edit',
        component: ConfigureTaxProvider,
        props: route => ({
          id: route.params.id,
        }),
      },
      {
        name: 'invoice-settings-view',
        path: 'invoice-settings',
        component: InvoiceSettingsView,
      },
    ],
  },
];
