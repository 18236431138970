<template>
  <div class="state-container">
    <base-loader
      v-if="showLoader"
      class="small"
      :delay="0"
      type="cube"
    />
    <base-message
      :icon="stateMessage.icon"
      :textLabel="stateMessage.actionMessage"
    >
      <base-button
        v-if="!hideLeftButton"
        class="button"
        size="sm"
        type="button"
        :rounded="true"
        @click="$emit('left');"
      >
        {{ $t(leftButtonLabel) }}
      </base-button>
      <base-button
        v-if="!hideMiddleButton"
        class="button"
        size="sm"
        type="button"
        :rounded="true"
        @click="$emit('middle');"
      >
        {{ $t(middleButtonLabel) }}
      </base-button>
      <base-button
        v-if="!hideRightButton"
        class="button"
        size="sm"
        type="button"
        :rounded="true"
        @click="$emit('right');"
      >
        {{ $t(rightButtonLabel) }}
      </base-button>
    </base-message>
  </div>
</template>

<script>
import { ERROR_PROVISIONING, PROVISIONING, PURGING, ERROR_PURGING, PENDING, PENDING_PURGE, PURGED } from '@/utils/states';

export default {
  name: 'BaseStateContainer',
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ['user', 'environment', 'connection', 'organization', 'branding', 'enterprise'].indexOf(value) !== -1;
      },
    },
    hideLeftButton: {
      type: Boolean,
      default: false,
    },
    hideMiddleButton: {
      type: Boolean,
      default: false,
    },
    hideRightButton: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    actionMessage: {
      type: String,
      default: '',
    },
    infoMessage: {
      type: String,
      default: '',
    },
    forceShowLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['middle', 'left', 'right'],
  computed: {
    showLoader() {
      return this.provisioning || this.purging || this.forceShowLoader;
    },
    state() {
      return this.item ? this.item.state : null;
    },
    provisioning() {
      return this.state === PROVISIONING || this.state === PENDING;
    },
    purging() {
      return this.state === PURGING || this.state === PENDING_PURGE;
    },
    error() {
      return this.state === ERROR_PROVISIONING || ERROR_PURGING;
    },
    leftButtonLabel() {
      return this.$t(`states.${this.type}.leftButtonLabel`);
    },
    middleButtonLabel() {
      return this.$t(`states.${this.type}.middleButtonLabel`);
    },
    rightButtonLabel() {
      return this.$t(`states.${this.type}.rightButtonLabel`);
    },
    stateMessage() {
      switch (this.state) {
        case PENDING:
        case PROVISIONING: {
          return {
            icon: '',
            actionMessage: this.$t(`states.${this.type}.provisioning`),
          };
        }
        case ERROR_PROVISIONING: {
          return {
            icon: 'fa fa-exclamation-triangle yellow',
            actionMessage: this.$t(`states.${this.type}.provisioning_failed`, { name: this.item.name }),
          };
        }
        case PENDING_PURGE:
        case PURGING: {
          return {
            icon: '',
            actionMessage: this.$t(`states.${this.type}.purging`, { name: this.item.name }),
          };
        }
        case ERROR_PURGING: {
          return {
            icon: 'fa fa-exclamation-triangle yellow',
            actionMessage: this.$t(`states.${this.type}.purging_failed`, { name: this.item.name }),
          };
        }
        case PURGED: {
          return {
            actionMessage: this.$t(`states.${this.type}.purged`, { name: this.item.name }),
          };
        }
        default: {
          return {
            icon: this.icon,
            actionMessage: this.$t(this.actionMessage),
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.state-container {
  text-align: center;
  display: block;
}
</style>
