<template>
  <autocomplete-input
    icon="fa fa-search-plus"
    style="width: 100%;"
    activateSlot
    :allItems="sortedProducts"
    :items="filteredProducts"
    :loading="searching"
    :placeholder="$t('monetization.commitments.search_placeholder')"
    :searchTerm="search"
    :error="error"
    @search="search = $event"
    @select="selectItem"
  >
    <template #default="{ item }">
      <div class="product-container">
        <div>{{ item.name[locale] }}</div>
        <div class="details">
          {{ item.sku }}
        </div>
      </div>
    </template>
  </autocomplete-input>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CommittedProductDropdown',
  props: {
    products: {
      type: Array,
      required: true,
    },
    error: {
      type: [String, Boolean]
    }
  },
  emits: ['addProduct'],
  data() {
    return {
      selectedItem: undefined,
      searching: false,
      search: null,
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    sortedProducts() {
      return [...this.products].sort((a, b) => {
        // sort by group
        const ga = a.group.toLocaleLowerCase();
        const gb = b.group.toLocaleLowerCase();
        if (ga.localeCompare(gb) !== 0) {
          return ga.localeCompare(gb);
        }

        // sort by name
        const na = a.name[this.locale].toLocaleLowerCase();
        const nb = b.name[this.locale].toLocaleLowerCase();
        return na.localeCompare(nb);
      });
    },
    productStringMap() {
      return this.sortedProducts.reduce((acc, curr) => ({
        ...acc,
        [curr.id]: JSON.stringify(curr).toLocaleLowerCase(),
      }), {});
    },
    filteredProducts() {
      if (!this.search) {
        return this.sortedProducts;
      }
      return this.sortedProducts.filter(p => this.productStringMap[p.id].includes(this.search));
    },
  },
  methods: {
    selectItem(item) {
      if (item) {
        this.$emit('addProduct', this.products.find(u => u.id === item));
      }
      this.search = null;
    },
  },
};
</script>

<style scoped lang="scss">
.product-container {
  padding: 15px;
  border-bottom: 4px solid #f0f0f0;
}

.details {
  font-size: 12px;
  color: var(--detail-text);
}
</style>
