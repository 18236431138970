<template>
  <div
    v-for="(selectedField, index) in selections"
    :key="index"
    class="field-wrapper"
  >
    <cmc-text
      v-if="index === 0"
      class="title-label"
      asLabel
      :withI18n="nameI18n"
      :withTooltipI18n="descriptionI18n"
      :text="name"
      :withTooltip="description"
    />
    <cmc-text
      v-if="index === 0"
      class="count-label"
      :text="countLabel"
      withI18n
      asLabel
    />
    <base-select
      v-model="selectedField.value"
      :disabled="disabled"
      :placeholder="placeholderLabel"
      :items="selectableItems(selectedField.value)"
      class="field-select"
      clearable
      :error="fieldHasError(index) || hasGeneralError"
      @update:modelValue="updateFieldValue(index, $event)"
    />
    <CounterComponent
      :count="selectedField.count"
      :disabled="disabled"
      class="field-count"
      :error="fieldHasError(index) || hasGeneralError"
      @updateCount="updateCountForItem(index, $event)"
      @increaseCount="increaseCountForItem(index, $event)"
      @decreaseCount="decreaseCountForItem(index, $event)"
    />
    <div class="add-remove-toggle-wrapper">
      <base-button
        v-if="selections.length !== 1"
        :disabled="disabled"
        :rounded="false"
        :aria-label="$t('remove')"
        class="add-remove-toggle"
        @click="removeFieldValue(index)"
      >
        <base-icon icon="fa fa-minus" />
      </base-button>
      <base-button
        v-if="canAddField(index)"
        :disabled="disabled"
        :rounded="false"
        :aria-label="$t('add')"
        class="add-remove-toggle"
        @click="addFieldValue()"
      >
        <base-icon icon="fa fa-plus" />
      </base-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CounterComponent from './CounterComponent';

export default ({
  name: 'MultiSelectWithCount',
  components: { CounterComponent },
  props: {
    modelValue: {
      type: Array,
    },
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholderLabel: {
      type: String,
      default: 'select_value',
    },
    name: {
      type: String,
      required: true,
    },
    nameI18n: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: '',
    },
    descriptionI18n: {
      type: Boolean,
      default: true,
    },
    countLabel: {
      type: String,
      default: 'units.count',
    },
    errors: {
      type: Object,
      default: () => { },
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selections: [],
    }
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    hasGeneralError() {
      if (!this.errors) {
        return false;
      }
      const generalError = this.errors
        .filter(err => err.context?.field?.length <= 1);
      return !!generalError && generalError.length != 0;
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (oldVal === undefined && newVal.length) {
        this.updateSelections();
      }
    },
  },
  mounted() {
    this.updateSelections();
  },
  methods: {
    updateSelections() {
      if (this.modelValue?.length) {
        let updateSelect = [];
        this.modelValue.forEach(element => {
          let key = Object.keys(element)[0];
          updateSelect.push({
            value: key,
            count: element[key],
          });
        });
        this.selections = updateSelect;
      } else {
        this.addFieldValue();
      }
    },
    addFieldValue() {
      this.selections.push({
        value: "",
        count: 0,
      });
    },
    canAddField(index) {
      return index === this.selections.length - 1
        && this.selectableItems.length !== 0
        && this.selections.length !== this.items.length;
    },
    selectableItems(selectedValue){
      const usedItems = this.selections.map(selectedItem => selectedItem.value);
      return this.items.filter(item => item.value === selectedValue
        || !usedItems.includes(item.value)).map(item => ({
          display: item.name,
          value: item.value,
        }));
    },
    updateCountForItem(index, value) {
      this.selections[index].count = value;
      this.updateList();
    },
    removeFieldValue(index) {
      this.selections.splice(index, 1);
      this.updateList();
    },
    updateFieldValue(index, value) {
      this.selections[index].value = value;
      this.updateList();
    },
    updateList() {
      const updatedModelValue = [];
      this.selections.filter(s => s.value).forEach(s => {
        const entry = {};
        entry[s.value] = s.count;
        updatedModelValue.push(entry);
      });
      // if the updated model value is empty, remove the custom field from the model
      if (updatedModelValue.length === 0) {
        this.$emit('update:modelValue', undefined);
        return;
      }
      this.$emit('update:modelValue', updatedModelValue);
    },
    fieldHasError(index) {
      if (!this.errors) {
        return false;
      }
      const fieldError = this.errors
        .filter(err => err.context?.field?.length > 1)
        .filter(err => err.context.field[1] === index);
      return !!fieldError && fieldError.length !== 0;
    },
  },
});

</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.field-wrapper {
  padding-bottom: 4px;
  max-width: 600px;
  display: grid;
  grid-template-columns: auto min-content min-content;
  grid-template-rows: min-content min-content;
  column-gap: 4px;

  >.count-label {
    grid-row: 1;
    grid-column: 2;
    justify-self: center;
  }
  >.title-label {
    grid-row: 1;
    grid-column: 1;
  }

  >.field-select {
    grid-row: 2;
    grid-column: 1;
  }
  >.field-count {
    grid-row: 2;
    grid-column: 2;
    :deep(.button) {
      min-width: 3.25rem !important;
    }
  }

  >.add-remove-toggle-wrapper {
    grid-row: 2;
    grid-column: 3;
    min-width: 7rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    >.add-remove-toggle {
      min-width: 3.25rem !important;
    }
  }
}
</style>
