import { CrudApi } from './api';

class PricingV1Api extends CrudApi {
  list(serviceConnectionId) {
    return this.get(`/services/connections/${serviceConnectionId}/pricings`);
  }
  refreshPricings(serviceConnectionId) {
    return this.put(`/services/connections/${serviceConnectionId}/pricings`);
  }
  getNewPricing(serviceConnectionId) {
    return this.get(`/services/connections/${serviceConnectionId}/pricings/new`);
  }
  getPricing(serviceConnectionId, pricingId) {
    return this.get(`/services/connections/${serviceConnectionId}/pricings/${pricingId}`);
  }
  updatePricing(serviceConnectionId, pricingId, pricing) {
    return this.put(`/services/connections/${serviceConnectionId}/pricings/${pricingId}`, {
      body: pricing,
    });
  }
  postNewPricing(serviceConnectionId, pricing) {
    return this.post(`/services/connections/${serviceConnectionId}/pricings`, {
      body: pricing,
    });
  }
}

const pricingV1Api = new PricingV1Api();
export default pricingV1Api;
