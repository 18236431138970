import { CrudApi } from './api';

class Disclaimers extends CrudApi {
  constructor() {
    super('/legal/disclaimer');
  }
}

const disclaimers = new Disclaimers();
export default disclaimers;
