import apis from '@/utils/apis';
import socket from '@/socket';
import {
  CHANGE_ORGANIZATION,
  CHANGE_ORGANIZATION_FEATURES,
  LOAD_BILLING_SETTINGS,
  LOAD_ORG_BRANDING_INFO,
  LOAD_USER_BRANDING_INFO,
  LOGIN,
  LOGOUT,
  RESET_LOGIN,
  RESET_SESSION_TIMEOUT,
  SESSION_TIMED_OUT,
  SET_BRANDING_RELOAD_ID,
  SET_DEFAULT_LOCALE,
  SET_FEEDBACK_SETTINGS,
  SET_GLOBAL_PROPERTIES,
  SET_IDENTITY_PROVIDER,
  SET_LOCALE,
  SET_PERMISSIONS_ON_ORG,
  SET_PREVIOUS_ROUTE,
  SET_SERVICE_SUB_MENU,
  SET_USER_CONTEXT,
  UPDATE_CURRENT_ORG_ATTRIBUTES,
  LOAD_MANAGED_CONNECTIONS,
} from './mutations';

export default {
  login: async ({ commit, dispatch }, userContext) => {
    commit(LOGIN);
    commit(RESET_SESSION_TIMEOUT);
    commit(SET_USER_CONTEXT, userContext);
    dispatch('loadManagedConnections', userContext.org.id);
    const resp = await apis.auth.getIdentityProvider();
    commit(SET_IDENTITY_PROVIDER, resp.data);
    const organizationFeatures = await apis.organizations.getEnabledFeature();
    commit(CHANGE_ORGANIZATION_FEATURES, organizationFeatures.data);
    const feedbackSettings = await apis.feedbackSettings
      .getOrganizationFeedbackSettings(userContext.org.id);
    commit(SET_FEEDBACK_SETTINGS, feedbackSettings.data);
  },
  logout: ({ commit }) => {
    commit(LOGOUT);
    commit(RESET_SESSION_TIMEOUT);
    commit(SET_USER_CONTEXT, undefined);
    commit(SET_IDENTITY_PROVIDER, undefined);
    commit(CHANGE_ORGANIZATION, null);
    commit(CHANGE_ORGANIZATION_FEATURES, null);
    commit(LOAD_MANAGED_CONNECTIONS, null);
  },
  reloadUserContext: async ({ commit }) => {
    const resp = await apis.auth.getUserContext();
    commit(SET_USER_CONTEXT, resp.data);
  },
  reloadIdentityProvider: async ({ commit }) => {
    const resp = await apis.auth.getIdentityProvider();
    commit(SET_IDENTITY_PROVIDER, resp.data);
  },
  updateCurrentOrgAttributes: ({ commit, dispatch }, attributes) => {
    dispatch('resetFilters');
    commit(UPDATE_CURRENT_ORG_ATTRIBUTES, attributes);
  },
  reloadOrgPermissions: async ({ commit, dispatch, state }, organization) => {
    dispatch('resetFilters');
    const orgPermissions = await apis.users.getEffectivePermissions(organization.id);
    if (state.userContext.org.id === organization.id) {
      commit(SET_USER_CONTEXT, { ...state.userContext, myOrgPermissions: orgPermissions });
    } else {
      commit(SET_PERMISSIONS_ON_ORG, { orgId: organization.id, orgPermissions });
    }
  },
  changeOrganization: async ({ commit, state, dispatch }, organization) => {
    if (organization && !state.permissionsPerOrg[organization.id] && state.loggedIn) {
      socket.subscribeToOrg(organization.id);
      const orgPermissions = await apis.users.getEffectivePermissions(organization.id);
      commit(SET_PERMISSIONS_ON_ORG, { orgId: organization.id, orgPermissions });
    }
    if (organization) {
      const brandingInfo = await apis.hidden.getEffectiveBrandingInfo(organization.entryPoint);
      commit(LOAD_ORG_BRANDING_INFO, brandingInfo.data);

      dispatch('loadManagedConnections', organization.id);
      if (!organization.serviceConnections) {
        const resp = await apis.organizations.find(organization.id);
        if (resp.ok) {
          organization.serviceConnections = (resp.data && resp.data.serviceConnections) ? resp.data.serviceConnections : [];
        }
      }
      if (state.loggedIn) {
        dispatch('loadAllEnvironments', { orgId: organization.id } );
        
        const feedbackSettings = await apis.feedbackSettings
          .getOrganizationFeedbackSettings(organization.id);
        commit(SET_FEEDBACK_SETTINGS, feedbackSettings.data);

        const billingSettings = await apis.billingSettings.search(organization.id);
        commit(LOAD_BILLING_SETTINGS, billingSettings.data);
      }
    }
    commit(CHANGE_ORGANIZATION, organization);
  },
  backToMyOrganization: ({ commit, dispatch }) => {
    dispatch('resetFilters');
    commit(CHANGE_ORGANIZATION, null);
    commit(LOAD_MANAGED_CONNECTIONS, null);
  },
  resetAuthentication: ({ commit }) => {
    commit(RESET_LOGIN);
    commit(RESET_SESSION_TIMEOUT);
    commit(SET_USER_CONTEXT, undefined);
  },
  sessionTimedOut: ({ commit }) => {
    commit(SESSION_TIMED_OUT);
    commit(RESET_LOGIN);
  },
  setLocale: ({ commit }, locale) => {
    commit(SET_LOCALE, locale);
  },
  setDefaultLocale: ({ commit }, defaultLocale) => {
    commit(SET_DEFAULT_LOCALE, defaultLocale);
  },
  loadOrgBrandingInfo({ commit }, entryPoint) {
    return apis.hidden.getEffectiveBrandingInfo(entryPoint)
      .then(resp => commit(LOAD_ORG_BRANDING_INFO, resp.data));
  },
  loadUserBrandingInfo({ commit }) {
    return apis.hidden.getBrandingInfo()
      .then(resp => commit(LOAD_USER_BRANDING_INFO, resp.data));
  },
  loadFeedbackSettings({ commit }, id) {
    return apis.feedbackSettings.getOrganizationFeedbackSettings(id)
      .then(resp => commit(SET_FEEDBACK_SETTINGS, resp.data));
  },
  loadGlobalProperties({ commit }) {
    return apis.properties.getWhiteListedProperties()
      .then(resp => commit(SET_GLOBAL_PROPERTIES, resp.data));
  },
  loadBillingSettings({ commit }, organizationId) {
    return apis.billingSettings.search(organizationId)
      .then(resp => commit(LOAD_BILLING_SETTINGS, resp.data));
  },
  setPreviousRoute: ({ commit }, route) => {
    commit(SET_PREVIOUS_ROUTE, route);
  },
  setBrandingReloadId: ({ commit }, id) => {
    commit(SET_BRANDING_RELOAD_ID, id);
  },
  setServiceSubMenu: ({ commit }, serviceSubMenu) => {
    commit(SET_SERVICE_SUB_MENU, serviceSubMenu);
  },
  setOrganizationFeatures: ({ commit }, organizationFeatures) => {
    commit(CHANGE_ORGANIZATION_FEATURES, organizationFeatures);
  },
  loadManagedConnections: async ({ commit }, organizationId) => {
    const managedServiceConnections = await apis.organizations.getManagedServiceConnections(organizationId);
    commit(LOAD_MANAGED_CONNECTIONS, managedServiceConnections.data);
  },
};
