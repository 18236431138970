import OnboardingSection from './OnboardingSection';

const TrialList = () => import(/* webpackChunkName: "system" */ './trials/TrialList');
const TrialDetails = () => import(/* webpackChunkName: "system" */ './trials/TrialDetails');
const TrialPage = () => import(/* webpackChunkName: "system" */ './trials/TrialPage');
const TrialsSettings = () => import(/* webpackChunkName: "system" */ './trials/TrialsSettings');
const AddTrial = () => import(/* webpackChunkName: "system" */ './trials/AddTrial');

const AddEnterpriseRegistration = () => import(/* webpackChunkName: "system" */ './enterpriseregistration/AddEnterpriseRegistrationForm');
const EnterpriseRegistrationPage = () => import(/* webpackChunkName: "system" */ './enterpriseregistration/EnterpriseRegistrationPage');
const EnterpriseRegistrationList = () => import(/* webpackChunkName: "system" */ './enterpriseregistration/EnterpriseRegistrationList');


export default [
  {
    path: 'onboarding',
    component: OnboardingSection,
    meta: {
      protected: ['reseller:trials'],
      title: () => 'onboarding_title',
    },
    children: [
      {
        path: '',
        redirect: { name: 'trials' },
      },
      {
        name: 'trials',
        path: 'trials',
        component: TrialList,
      },
      {
        name: 'enterpriseRegistrations',
        path: 'enterprise',
        component: EnterpriseRegistrationList,
      },
    ],
  },
  {
    path: 'onboarding/enterprise',
    component: EnterpriseRegistrationPage,
    props: true,
    meta: {
      protected: ['reseller:trials'],
    },
    children: [
      {
        name: 'addEnterpriseRegistration',
        path: 'add',
        component: AddEnterpriseRegistration,
      },
    ]
  },
  {
    path: 'onboarding/trials',
    component: TrialPage,
    props: true,
    meta: {
      protected: ['reseller:trials'],
    },
    children: [
      {
        name: 'trialsSettings',
        path: 'configure',
        component: TrialsSettings,
      },
      {
        name: 'addTrial',
        path: 'add',
        component: AddTrial,
      },
      {
        path: ':id',
        redirect: { name: 'trialDetails' },
      },
      {
        name: 'trialDetails',
        path: ':id/details',
        component: TrialDetails,
        meta: {
          changeOrgRedirect: '/system/onboarding/trials',
          title: () => 'trial_details',
        },
        props: true,
      },
    ]
  },
];
