<template>
  <div :class="{'tree-node': !isTopLevel}">
    <base-checkbox 
      v-if="isLeaf" 
      class="checkbox" 
      :disabled="disabled" 
      :modelValue="modelValue" 
      sm
      @update:modelValue="inputFromCheckBox" 
    >
      <div class="checkbox-text">
        {{ name }}
      </div>
    </base-checkbox>
    <div
      v-else
      class="branch" 
    >
      <base-icon 
        class="expand-icon" 
        :class="{'open': !collapsed}" 
        :icon="caret"
      />
      <collapsible-section 
        class="collapsible" 
        :title="name" 
        :icons="[icon, icon]" 
        :open="!collapsed" 
        @toggle="collapse"
      >
        <tree-node 
          v-for="child in children" 
          :key="child.id" 
          :disabled="disabled" 
          :node="child" 
          :icon="icon" 
          :valueSet="valueSet" 
          @update:modelValue="input"
        ></tree-node>
      </collapsible-section>
    </div>
  </div>
</template>
    
<script>
export default {
    name: 'TreeNode',
    props: {
        node: {
            type: Object,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        isTopLevel: {
            type: Boolean,
            required: false,
        },
        valueSet: {
            type: Set,
            required : false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            collapsed: false,
        }
    } ,
    computed: {
        disabled() {
            return this.node.disabled;
        },
        isLeaf() {
            return this.node.isLeaf;
        },
        children() {
            return this.node.children;
        },
        value() {
            return this.node.value;
        },
        caret() {
            return this.collapsed ? 'caret-right' : 'caret-down';
        },
        modelValue() {
            return this.valueSet.has(this.value);
        },
        name() {
            return this.node.is18n ? this.$t(this.node.name) : this.node.name;
        },
    },

    methods: {
        collapse() {
            this.collapsed = !this.collapsed;
        },
        input(checked, value) {
            this.$emit('update:modelValue', checked, value);
        },
        inputFromCheckBox(checked) {
            if (!this.disabled) {
                this.$emit('update:modelValue', checked, this.value);
            }
        },
    }
}
</script>

<style scoped lang="scss">
.tree-node {
    padding: 2px;
    padding-left: 6px;
}
.branch {
    padding-top: 4px;
    display: flex;
    flex: 1;
    flex-direction: row;
}
.checkbox {
    margin-left: 20px;
}
.expand-icon {
    padding-right: 10px;
    width: 10px;
    min-width: 10px;
    max-width: 10px;
}
.checkbox-text{
    margin-left: -5px;
}
</style>