import { ReadOnlyApi } from './api';

class AccountsApi extends ReadOnlyApi {
  constructor() {
    super('services/accounts');
  }
}

const accountsApi = new AccountsApi();

export default accountsApi;
