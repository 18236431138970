<template>
  <ConfirmModal
    :open="open"
    :headerLabel="headerLabel"
    :showCloseIcon="true"
    @cancel="onCancel"
    @ok="onSubmit"
  >
    <div class="detail-container alert">
      <div>
        <alert-box
          class="warning-alert-box"
          border
          label="organization_currency_change.currency_change_modal.description"
          alert-type="WARNING"
        />
      </div>

      <br />
      <org-currency-change-commitment-list
        v-if="loaded"
        :orgId="orgId"
        :lastClosedCycle="lastClosedCycle"
        :currentOpenCycle="currentOpenCycle"
      />

      <br />
      <org-currency-change-credit-list
        v-if="loaded"
        :orgId="orgId"
        :currentOpenCycle="currentOpenCycle"
        :currency="currency"
      />

      <br />
      <span>
        {{ $t('organization_currency_change.currency_change_modal.confirmation') }}
      </span>
    </div>
  </ConfirmModal>
</template>
<script>

import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/ConfirmModal.vue';
import apis from "@/utils/apis";
import OrgCurrencyChangeCreditList from "@/app/Main/Organizations/currency-change/OrgCurrencyChangeCreditList.vue";
import OrgCurrencyChangeCommitmentList
  from "@/app/Main/Organizations/currency-change/OrgCurrencyChangeCommitmentList.vue";

export default {
  name: 'OrgCurrencyChangeModal',
  components: {ConfirmModal, OrgCurrencyChangeCreditList, OrgCurrencyChangeCommitmentList },
  mixins: [],
  props: {
      open: {
          type: Boolean,
          required: true,
          default: false,
      },
      orgId: {
        type: String,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
  },
  emits: ['close', 'submit'],
  data() {
    return {
      loaded: false,
      lastClosedCycle: undefined,
      currentOpenCycle: undefined,
      commitments: undefined
    };
  },
  computed: {
      ...mapGetters(['userBrandingInfo', 'locale', 'selectedOrganization']),
      headerLabel() {
          return 'organization_currency_change.currency_change_modal.header';
      }
  },
  async created() {
      await this.loadBillingCycles();
  },
  methods: {
      onSubmit() {
          this.$emit('submit');
      },
      onCancel() {
          this.$emit('close');
      },
      async loadBillingCycles() {
        const resp = await apis.billable.getBillingCycles(this.orgId, true);
        if (!resp || resp.status !== 200) {
          this.hasError = true;
          return;
        }

        let cycles = resp.data
        cycles.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

        for (let c of cycles) {
          if (!['WAITING_PAYMENT', 'PAID'].includes(c.state)) {
            if (this.currentOpenCycle == undefined) {
              this.currentOpenCycle = c;
            }
          } else {
            this.lastClosedCycle = c;
            this.currentOpenCycle = undefined;
          }
        }

        this.loaded = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "@/styles/mixins";
</style>
