<template>
  <base-loader v-if="loading"></base-loader>
  <div v-else>
    <div 
      v-if="titleLabel"
      class="top" 
    >
      <div class="main-title">
        <div class="main-title-header">
          <h1 :id="titleLabel">
            {{ $t(titleLabel) }}
          </h1>
          <div class="details-status">
            <base-badge 
              v-if="state" 
              :state="state.label" 
              :color="state.color"
            />
          </div>
          <div class="operations">
            <slot name="operations"></slot>
          </div>
        </div>
        <div class="main-title-description">
          <div 
            v-if="descriptionLabel" 
            :id="descriptionLabel"
            class="description" 
          >
            {{ $t(descriptionLabel) }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdvancedDetails',
  props: {
    titleLabel: {
      type: String,
    },
    descriptionLabel: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    state: {
      type: Object,
    },
  },
};
</script>
<style scoped lang="scss">
.top {
  .info {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    .main-title-header {
      display: flex;
      width: 100%;
      margin: 0;
      h1 {
        font-size: inherit;
      }
      .details-status {
        align-self: flex-start;
        padding-left: 30px;
      }
      .operations {
        align-self: flex-end;
        margin-left: auto;
      }
    }
    .main-title-description {
      .description {
        font-size: initial;
        margin: 0;
      }
    }
  }
}
.content {
  margin-top: 20px;
}
</style>
