import { CrudApi } from './api';

class RollbackTriggerApi extends CrudApi {
  constructor() {
    super('/rollbacks');
  }

  getMetadata(rollbackTrigger) {
    return this.post(`rollbacks/metadata`, { body: rollbackTrigger });
  }
}

const rollbackTriggerApi = new RollbackTriggerApi();
export default rollbackTriggerApi;
