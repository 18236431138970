<template>
  <div 
    class="modal-container" 
    :class="{'no-op': noOp}"
  >
    <transition name="fade">
      <div 
        v-if="open" 
        class="modal-bg" 
        @click="close" 
        @keypress.esc="close"
      >
      </div>
    </transition>
    <transition name="drop">
      <div 
        v-if="open" 
        class="modal"
      >
        <div>
          <div 
            v-if="header || searchable" 
            class="header"
          >
            <base-icon 
              v-if="headerIcon" 
              class="icon" 
              :icon="headerIcon"
            />
            <span v-if="header"><h3>{{ $t(header) }}</h3></span>
            <slot name="input"></slot>
            <BaseIcon
              v-if="!preventClose" 
              class="close-modal clickable" 
              icon="fa fa-times-circle" 
              @click="close"
            />
          </div>
          <div 
            v-if="!header && showCloseIcon" 
            class="close" 
            @click="close"
          >
            <BaseIcon icon="fa fa-times-circle" />
          </div>
          <div class="modal-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import KeyListener from '@/utils/listeners/keyListener';
import BaseIcon from './BaseIcon.vue';

export default {
  name: 'BaseModal',
  components: { BaseIcon },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
    },
    headerIcon: {
      type: String,
    },
    noOp: {
      type: Boolean,
      default: false,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      keyListener: new KeyListener(),
    };
  },

  watch: {
    open(newValue) {
      const main = document.getElementsByClassName('main')[0];
      if (main) {
        if (newValue) {
          main.classList.add('noscroll');
        } else {
          main.classList.remove('noscroll');
        }
      }
    },
  },

  created() {
    this.keyListener.esc(() => this.close());
  },

  beforeUnmount() {
    const main = document.getElementsByClassName('main')[0];
    if (main) {
      main.classList.remove('noscroll');
    }
    this.keyListener.clear();
  },

  methods: {
    close() {
      if (!this.noOp && !this.preventClose) {
        this.$emit('close');
      }
    },
  },

};
</script>


<style scoped lang="scss">
@import '@/styles/mixins.scss';

.modal-container:not(.no-op) {
  .modal-bg {
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .modal {
    max-width: 650px;
    max-height: calc(100% - 100px);
    background: white;
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    top: 60px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px #888888;
    border-radius: 10px;
    transform: translateY(0);
    display: flex;
    flex-direction: column;

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    .header {
      font-weight: bold;
      min-height: 50px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 3px 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .close-modal {
        margin-left: 15px;
      }
    }

    .modal-content {
      display: flex;
      flex: 1;
    }

    :deep(.dropdown) {
      position: fixed;
    }

    @include phone {
      top: 0;
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  }
}

.drop-enter-active, .drop-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.drop-enter-from, .drop-leave-to {
  transform: translateY(-600px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.back {
  position: absolute;
  right: 10px;
}

.modal-container:not(.no-op) {
  :deep(.buttons-container) {
    text-align: right;
    padding-bottom: 10px !important;
  }
  .modal-content {
    justify-content: center;
  }
}

.close {
  position: absolute;
  top: 1.5em;
  right: 1em;
  color: lightgrey;
  cursor: pointer;

  &:hover {
    color: grey;
  }
}
</style>
