<template>
  <advanced-details 
    v-if="organization" 
    :loading="loading" 
    titleLabel="verified_domains"
  >
    <system-activity-listener 
      :codes="codes" 
      :currentOrganization="false"
      @receive="fetchDomains" 
    />
    <template 
      v-if="!permissionDenied"
      #operations 
    >
      <base-button @click="open = true">
        <base-icon icon="fa fa-plus" /> {{ $t('add_verified_domain') }}
      </base-button>
    </template>
    <div class="alert-message">
      <alert-box
        v-if="hasPending"
        border
        alertType="INFO"
      >
        {{ $t("tx_record_info") }}
      </alert-box>
    </div>
    <base-list 
      aria-labelledby="verified_domains" 
      :ariaRowCount="domains.length"
    >
      <list-header>
        <list-column :size="3">
          {{ $t('domain') }}
        </list-column>
        <list-column :size="1">
          {{ $t('state') }}
        </list-column>
      </list-header>
      <empty-message 
        v-if="!hasVerifiedDomains" 
        :icon="!permissionDenied ? 'fa fa-cube' : 'fa fa-exclamation-triangle'" 
        :textLabel="getEmptyListLabel"
      />
      <template v-else>
        <list-row 
          v-for="d in domains"
          :key="d.id" 
        >
          <list-column :size="3">
            <div class="row">
              <div class="primary">
                {{ d.domain }}
              </div>
            </div>
            <copyable-field 
              class="description" 
              :rawValue="true" 
              :modelValue="d.verificationCode" 
              :onHover="true"
            />
          </list-column>
          <list-column :size="1">
            <base-badge 
              :state="getStateLabel(d.status)" 
              :color="getStateColor(d.status)"
            />
          </list-column>
          <template #actions>
            <action-icon
              icon="fa fa-trash" 
              tooltipLabel="delete"
              @click="removeDomainConfirmation(d)" 
            >
            </action-icon>
          </template>
        </list-row>
      </template>
    </base-list>
    <AddVerifiedDomain 
      :organization="organization" 
      :open="open" 
      @close="open = false"
    />
    <confirm-modal
      headerIcon="fa fa-times"
      :open="confirmRemove"
      headerLabel="remove_domain"
      detailsLabel="remove_domain_confirm"
      :detailsInterpolation="{ domain: domainToRemove.domain }"
      confirmLabel="discard"
      @ok="removeDomain"
      @cancel="confirmRemove = false"
    />
  </advanced-details>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import SystemActivityListener from '@/events/SystemActivityListener';
import AddVerifiedDomain from './AddVerifiedDomain';

export default {
  name: 'ManageVerifiedDomains',
  components: { SystemActivityListener, AddVerifiedDomain },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      domains: [],
      loading: true,
      confirmRemove: false,
      domainToRemove: {},
      permissionDenied: false,
      codes: ['verified_domain.created',
        'verified_domain.verified',
        'verified_domain.deleted'],
    };
  },
  computed: {
    hasVerifiedDomains() {
      return this.domains ? this.domains.length : 0;
    },
    getEmptyListLabel() {
      return this.permissionDenied ? 'permission_denied' : 'no_verified_domains';
    },
    hasPending() {
      return this.domains.some(d => d.status === 'PENDING');
    },
  },
  async created() {
    this.loading = true;
    await this.fetchDomains();
    this.loading = false;
  },
  methods: {
    async fetchDomains() {
      const resp = await apis.organizations.getVerifiedDomains(this.organization.id);
      if (resp.status === 403) {
        this.permissionDenied = true;
        this.domains = [];
      } else if (resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.domains = resp.data;
      }
    },
    getStateLabel(status) {
      return `domain_status.${status.toLowerCase()}`;
    },
    getStateColor(status) {
      return {
        VERIFIED: 'green',
        ERROR: 'red',
        PENDING: 'blue',
      }[status];
    },
    removeDomainConfirmation(domain) {
      this.domainToRemove = domain;
      this.confirmRemove = true;
    },
    async removeDomain() {
      const resp = await apis.organizations
        .deleteVerifiedDomain(this.organization.id, this.domainToRemove.id);
      if (resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
      } else {
        notify.success(this.$t('events.system.verified_domain.deleted.success', { domain: this.domainToRemove.domain }));
      }
      this.domainToRemove = {};
      this.confirmRemove = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-message {
  padding-bottom: 20px;
}
</style>
