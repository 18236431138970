<template>
  <form-row label="credits">
    <template #titleRight>
      <div
        v-if="showCreateButton"
        class="push"
      >
        <app-link
          v-protected="toAddCredit"
          :to="toAddCredit"
        >
          <base-button
            :preventDefault="false"
            :rounded="true"
          >
            <base-icon icon="fa fa-plus-circle" />
            {{ $t('monetization.discounts.credit.add') }}
          </base-button>
        </app-link>
      </div>
    </template>
    <base-list
      aria-labelledby="credits"
      :aria-describedby="description"
      :list="credits"
      :ariaRowCount="credits.length"
    >
      <list-header>
        <list-column>{{ $t('credit') }}</list-column>
        <list-column>{{ $t('monetization.discounts.credit.amount') }}</list-column>
        <list-column v-if="!multi">
          {{ $t('monetization.discounts.credit.remaining') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('status') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.start_date') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.end_date') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.discounts.duration') }}
        </list-column>
      </list-header>
      <list-row
        v-for="credit in paginatedCredits"
        :key="credit.id"
      >
        <list-column allowWrapping>
          <div><span>{{ credit.name[locale] }}</span></div>
          <div
            v-for="(v, k) in findOrigins(credit)"
            :key="k"
          >
            <span class="description">{{ v }}</span>
          </div>
        </list-column>

        <!-- amount -->
        <list-column>
          <template v-if="credit.packageDiscount">
            <div>
              <span><strong>{{ $t('monetization.discounts.scopes.all_products') }}: </strong></span>
              <span>{{ formatShortCurrency(credit.packageDiscount || 0, currency) }}</span>
            </div>
          </template>
          <template v-else-if="Object.keys(products(credit)).length !== 0">
            <div
              v-for="(v, k) in products(credit)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ formatShortCurrency(v || 0, currency) }}</span>
            </div>
            <span v-if="hasMoreProducts(credit)">
              <strong>{{ $t('monetization.discounts.preview_extra', { number: extraProducts(credit) }) }}</strong>
            </span>
          </template>
          <template v-else-if="Object.keys(categories(credit)).length !== 0">
            <div
              v-for="(v, k) in categories(credit)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ formatShortCurrency(v || 0, currency) }}</span>
            </div>
            <span v-if="hasMoreCategories(credit)">
              <strong>{{ $t('monetization.discounts.preview_extra', { number: extraCategories(credit) }) }}</strong>
            </span>
          </template>
        </list-column>

        <!-- remaining -->
        <list-column v-if="!multi">
          <template v-if="!credit.remaining">
            {{ $t('not_available') }}
          </template>
          <template v-else-if="credit.remaining.packageDiscount != null">
            <div>
              <span><strong>{{ $t('monetization.discounts.scopes.all_products') }}: </strong></span>
              <span>{{ formatShortCurrency(credit.remaining.packageDiscount || 0, currency) }}</span>
            </div>
          </template>
          <template v-else-if="Object.keys(products(credit.remaining)).length !== 0">
            <div
              v-for="(v, k) in products(credit.remaining)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ formatShortCurrency(v || 0, currency) }}</span>
            </div>
            <span v-if="hasMoreProducts(credit.remaining)">
              <strong>{{
                $t('monetization.discounts.preview_extra', { number: extraProducts(credit.remaining) })
              }}</strong>
            </span>
          </template>
          <template v-else-if="Object.keys(categories(credit.remaining)).length !== 0">
            <div
              v-for="(v, k) in categories(credit.remaining)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ formatShortCurrency(v || 0, currency) }}</span>
            </div>
            <span v-if="hasMoreCategories(credit.remaining)">
              <strong>{{
                $t('monetization.discounts.preview_extra', { number: extraCategories(credit.remaining) })
              }}</strong>
            </span>
          </template>
        </list-column>

        <list-column v-if="multi">
          <base-badge
            :state="getStatus(credit).label"
            :color="getStatus(credit).color"
          />
        </list-column>

        <list-column v-if="multi">
          {{ discountStartDate(credit) }}
        </list-column>
        <list-column v-if="multi">
          {{ discountEndDate(credit) }}
        </list-column>
        <list-column v-if="multi">
          {{ durationFormatting(credit) }}
        </list-column>

        <template #actions>
          <action-icon
            v-if="credit.status !== 'ENDED' && !credit.isDeactivated"
            :to="{ name: 'edit-organization-credit', params: { id: organization.id, creditId: credit.id } }"
            tooltipLabel="edit"
            icon="fa fa-edit"
            expandOnHover
          ></action-icon>
          <DiscountActions
            :discount="credit"
            :organizationId="organization.id"
            :locale="locale"
            :credit="true"
            @deleted="emitUpdateDiscounts"
          />
        </template>
      </list-row>
      <pagination-component
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :pageNumber="pageNumber"
        @pageSelected="pageNumber = $event"
      />
      <template #empty>
        <empty-message
          icon="fa fa-money"
          textLabel="monetization.no_credits"
        />
      </template>
    </base-list>
  </form-row>
</template>
<script>

import authz from '@/authz';
import { mapGetters } from 'vuex';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { currencyMixin } from '@/mixins/currencyMixin';
import { discountMixin } from '@/mixins/discountMixin';

import PaginationComponent from '@/components/list/PaginationComponent';
import DiscountActions from '@/app/Main/Administration/monetization/pricingPackage/DiscountActions.vue';

const MAX_SIZE = 5;
const PAGE_SIZE = 3;

export default {
  name: 'CreditList',
  components: { DiscountActions, PaginationComponent },
  mixins: [billingCycleMixin, currencyMixin, discountMixin],
  props: {
    credits: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    organization: {
      type: Object,
    },
    // true if credits span multiple billing cycles
    multi: {
      type: Boolean,
      default: false,
    },
    disableCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateDiscountList'],
  data() {
    return {
      pageNumber: 1,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    totalPages() {
      return Math.ceil(this.credits.length / PAGE_SIZE);
    },
    paginatedCredits() {
      return this.credits.slice(
        (this.pageNumber - 1) * PAGE_SIZE,
        this.pageNumber * PAGE_SIZE);
    },
    toAddCredit() {
      return `/admin/organizations/${this.organization.id}/billing/overview/credits/add`;
    },
    description() {
      const suffix = !this.multi ? 'credits_for_cycle_desc' : 'credits_for_org_desc';
      return `monetization.${suffix}`;
    },
    labels() {
      return this.credits.reduce((acc, c) => ({ ...acc, ...c.discountedLabels }), {});
    },
    showCreateButton() {
      return authz.hasPermission('reseller:organizationBilling') && !this.disableCreate;
    },
  },
  methods: {
    products(credit) {
      const ids = Object.keys(credit.discountedProducts).slice(0, MAX_SIZE);
      return ids.reduce((acc, k) => ({
        ...acc,
        [k]: credit.discountedProducts[k],
      }), {});
    },
    categories(credit) {
      const ids = Object.keys(credit.discountedCategories).slice(0, MAX_SIZE);
      return ids.reduce((acc, k) => ({
        ...acc,
        [k]: credit.discountedCategories[k],
      }), {});
    },
    hasMoreProducts(credit) {
      return Object.keys(credit.discountedProducts).length > MAX_SIZE;
    },
    extraProducts(credit) {
      return Object.keys(credit.discountedProducts).length - MAX_SIZE;
    },
    hasMoreCategories(credit) {
      return Object.keys(credit.discountedCategories).length > MAX_SIZE;
    },
    extraCategories(credit) {
      return Object.keys(credit.discountedCategories).length - MAX_SIZE;
    },
    emitUpdateDiscounts() {
      this.$emit('updateDiscountList');
    }
  },
};
</script>

<style scoped lang="scss">
.push {
  margin-left: auto;
  padding: 20px 20px 10px 0px;
}

:deep(.label-row) {
  max-width: 100% !important;
}
</style>
