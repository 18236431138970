import authz from '../authz';

function getRoute(binding) {
  const to = binding.value;
  if (!to) {
    throw new Error("Cannot use v-protected without a value set");
  }
  return to;
}

function hideElement(el) {
  el.style.display = 'none';
}

function showElement(el) {
  el.style.display = null;
}

function base(el, binding) {
  const route = getRoute(binding);
  if (!authz.isPermittedRoute(route)) {
    hideElement(el);
  } else {
    showElement(el);
  }
}

/**
 * e.g. <my-link :to="/configuration/users" v-protected="/configuration/users"></my-link>
 */
export default {
  name: 'protected',
  beforeMount(el, binding) {
    base(el, binding);
  },

  updated(el, binding) {
    base(el, binding);
  },
};
