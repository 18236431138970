<template>
  <form-row
    :label="label" 
    :descriptionLabel="descriptionLabel"
  >
    <div class="row">
      <div class="col-lg-6 billing-column">
        <form-row
          label="org_billing.billing_address_line1"
        >
          <template #titleInfo>
            <div
              v-if="requireFields && addressHasValues"
              class="required red"
            >
              *
            </div>
          </template>
          <base-input
            v-model="address.lineOne"
            :disabled="disabled"
            :maxLength="100"
            @update:modelValue="update('lineOne', $event)"
          />
        </form-row>

        <form-row
          label="org_billing.billing_address_line2"
          optional
        >
          <base-input
            v-model="address.lineTwo"
            :disabled="disabled"
            :maxLength="100"
            @update:modelValue="update('lineTwo', $event)"
          />
        </form-row>

        <form-row
          label="org_billing.billing_address_city"
        >
          <template #titleInfo>
            <div
              v-if="requireFields && addressHasValues"
              class="required red"
            >
              *
            </div>
          </template>
          <base-input
            v-model="address.city"
            :disabled="disabled"
            :maxLength="100"
            @update:modelValue="update('city', $event)"
          />
        </form-row>
      </div>

      <div class="col-lg-6 billing-column">
        <form-row
          label="org_billing.billing_address_country"
        >
          <template #titleInfo>
            <div
              v-if="requireFields && addressHasValues"
              class="required red"
            >
              *
            </div>
          </template>
          <base-input
            v-model="address.country"
            :disabled="disabled"
            :maxLength="50"
            @update:modelValue="update('country', $event)"
          />
        </form-row>

        <form-row
          label="org_billing.billing_address_province"
        >
          <template #titleInfo>
            <div
              v-if="requireFields && addressHasValues"
              class="required red"
            >
              *
            </div>
          </template>
          <base-input
            v-model="address.province"
            :disabled="disabled"
            :maxLength="50"
            @update:modelValue="update('province', $event)"
          />
        </form-row>

        <form-row
          label="org_billing.billing_address_postalcode"
        >
          <template #titleInfo>
            <div
              v-if="requireFields && addressHasValues"
              class="required red"
            >
              *
            </div>
          </template>
          <base-input
            v-model="address.postalCode"
            :disabled="disabled"
            :maxLength="10"
            @update:modelValue="update('postalCode', $event)"
          />
        </form-row>
      </div>
    </div>
  </form-row>
</template>

<script>
export default {
  name: 'AddressFormRow',
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    descriptionLabel: {
      type: String,
    },
    requireFields: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    addressHasValues() {
      return !!this.address.lineOne || !!this.address.lineTwo
        || !!this.address.city || !!this.address.province
        || !!this.address.postalCode || !!this.address.country;
    },
    address: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    update(key, value) {
      this.address = {
        ...this.address,
        [key]: value,
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.billing-column {
  padding-right: 10px;
}

.required {
  padding-left: 2px;
}
</style>