import { CrudApi } from './api';

class SshKeysAPI extends CrudApi {
  constructor() {
    super('ssh_keys');
  }

  validateSSHKey(sshPublicKey) {
    return this.get(`${this.path}/check`, { qs: { sshKeyValue: sshPublicKey } });
  }
}

const sshKeyApi = new SshKeysAPI();

export default sshKeyApi;
