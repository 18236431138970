import { Store, useStore } from "vuex";

export type Styles = {
    graph0: GraphColor;
    graph1: GraphColor;
    graph2: GraphColor;
    graph3: GraphColor;
    graph4: GraphColor;
    graph5: GraphColor;
    graph6: GraphColor;
    graph7: GraphColor;
}

export function useBrandingStyles(): Styles{
    const store: Store<any> = useStore();
    const styles: Styles = store?.getters?.orgBrandingInfo?.styles || {};
    return styles;
}

export type EChartStyleOptions = 'color' | 'areaStyle' | 'itemStyle' | 'colorLayer' | 'lineStyle' | 'textStyle' |
                                     'backgroundColor' | 'borderColor' | 'shadowColor' | 'iconStyle' | 'nameTextStyle';

export type GraphColor = 'graph0' | 'graph1' | 'graph2' | 'graph3' | 'graph4' | 'graph5' | 'graph6' | 'graph7';

export const GRAPH_COLORS = ['graph0', 'graph1', 'graph2', 'graph3', 'graph4', 'graph5', 'graph6', 'graph7'] as GraphColor[]

export function getColorHex(color: GraphColor, styles: Styles) {
    switch (color) {
        case 'graph0': return styles?.graph0 || '#78AAF0';
        case 'graph1': return styles?.graph1 || '#E696AF';
        case 'graph2': return styles?.graph2 || '#A5D273';
        case 'graph3': return styles?.graph3 || '#9B78BE';
        case 'graph4': return styles?.graph4 || '#F5D78C';
        case 'graph5': return styles?.graph5 || '#9B7D5A';
        case 'graph6': return styles?.graph6 || '#BEF0E6';
        case 'graph7': return styles?.graph7 || '#C8C8C8';
        default: return '#F5F5DC';
    }
}

const BODY_3 = {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 15,
    color: '#000'
};

const BODY_3_B = {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 15,
    color: '#000'
};

export type GraphTextStyle = 'body-3' | 'body-3-b'

export function getTextStyle(style: GraphTextStyle) {
    switch (style) {
        case 'body-3': return BODY_3;
        case 'body-3-b': return BODY_3_B;
        default: return {};
    }
}