<template>
  <div v-if="!loading && !hasError && invoiceable">
    <system-activity-listener
      :codes="codes"
      @receive="refresh"
    />
    <advanced-details :titleLabel="$t('monetization.billing_overview')" />
    <BillingPricingPackage
      :pricingPackage="pricingPackage"
      isEditMode
    >
      <template #actions>
        <action-icon
          expandOnHover
          icon="fa fa-edit"
          tooltipLabel="monetization.edit_billing_cycle_package"
          :to="{ name: 'billable_org_info_edit', params: { cycleId: cycleId} }"
        />
      </template>
    </BillingPricingPackage>
    <BillingCycleList
      :cycles="cycles"
      :invoices="invoices"
    />
    <commitment-list
      :commitments="commitments"
      :organization="organization"
      :billingCycles="cycles"
      :hasTaxProvider="hasTaxProvider"
    />
    <DiscountList
      :discounts="applicableDiscounts"
      :organization="organization"
      multi
      @updateDiscountList="fetchDiscounts"
    />
    <CreditList
      :credits="applicableCredits"
      :currency="currentCycle.currency"
      :organization="organization"
      multi
      @updateDiscountList="fetchDiscounts"
    />
  </div>
  <div v-else-if="!loading && !invoiceable">
    <alert-box
      label="monetization.not_invoiceable"
      alertType="INFO"
      border
    ></alert-box>
  </div>
  <div v-else-if="!loading && invoiceable">
    <alert-box
      label="monetization.no_billing_cycle"
      alertType="INFO"
      border
    ></alert-box>
  </div>
  <base-loader v-else></base-loader>
</template>

<script>
import apis from '@/utils/apis';
import SystemActivityListener from '@/events/SystemActivityListener';
import {mapGetters} from 'vuex';
import {getDate, getMax, getMin, getNow, isAfter, isBefore, isEqual} from '@/utils/dates';
import {sortBy} from '@/utils';
import {billingCycleMixin} from '@/mixins/billingCycleMixin';
import {currencyMixin} from '@/mixins/currencyMixin';
import {taxMixin} from "@/mixins/taxMixin";
import {organizationFeatures} from '@/mixins/organizationFeatures';

import BillingCycleList from '@/app/Main/components/billing_cycles/BillingCycleList';
import BillingPricingPackage from '@/app/Main/components/billing_cycles/BillingPricingPackage';

import CommitmentList from '@/app/Main/components/commitments/CommitmentList';

import CreditList from '@/app/Main/components/discounts/CreditList';
import DiscountList from '@/app/Main/components/discounts/DiscountList';

export default {
  name: 'BillingOverview',
  components: {
    SystemActivityListener,
    BillingCycleList,
    BillingPricingPackage,
    CommitmentList,
    CreditList,
    DiscountList,
  },
  mixins: [currencyMixin, billingCycleMixin, organizationFeatures, taxMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cycles: [],
      packages: [],
      invoices: [],
      discounts: [],
      discountMetadata: {},
      info: {},
      loading: true,
      hasError: false,
      currentCycle: null,
      commitments: [],
      invoiceable: true,
      hasTaxProvider: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'selectedOrganization']),
    codes() {
      return [
        'commitments.create',
        'commitments.update',
        'commitments.delete',
        'commitments.terminate',
      ];
    },
    cycleOptions() {
      return this.info.billingCycles.map(bc => ({
        label: this.$date(bc.startDate, 'MMMM YYYY', true),
        value: bc.id,
      }));
    },
    billingCycleDiscounts() {
      return this.cycles
        .filter(c => c.discounts && c.discounts.length)
        .reduce((acc, c) => acc.concat(c.discounts), []);
    },
    mergedDiscounts() {
      return this.billingCycleDiscounts.map(d => ({
        ...this.discounts[d.discountId],
        ...d,
        appliedPricing: this.getAppliedPricing(d),
      }));
    },
    effectiveDiscounts() {
      // discounts can contain the same discount, but applied to a different billing cycle
      // this method merges "duplicate" discounts without losing the start/end date
      // all other properties **should** be the same

      // group by discount id
      // id -> array[discount]
      const idToDiscounts = this.mergedDiscounts.reduce((acc, d) => ({
        ...acc,
        [d.discountId]: [...(acc[d.discountId] || []), d],
      }), {});

      // end-date computation
      const calculateEndDate = (acc, curr) => {
        if (!curr || !acc || !curr.durationDays || curr.endDate == null) {
          return null;
        }
        return getMax(acc.endDate, curr.endDate);
      };

      // merge credits
      const ans = [];
      Object.keys(idToDiscounts).forEach((k) => {
        const discount = idToDiscounts[k].reduce((acc, curr) => ({
          ...acc,
          ...curr,
          startDate: getMin(acc.startDate, curr.startDate),
          endDate: calculateEndDate(acc, curr),
        }), {
          startDate: '9999-12-31',
          endDate: '1900-01-01',
        });
        ans.push(discount);
      });

      return ans;
    },
    applicableDiscounts() {
      return this.effectiveDiscounts.filter(d => d.type === 'PERCENTAGE');
    },
    applicableCredits() {
      return this.effectiveDiscounts.filter(d => d.type === 'CREDIT');
    },
    pricingPackage() {
      return this.currentCycle.pricingPackages[0];
    },
    summaryColumns() {
      return [];
    },
    startDate() {
      return (this.cycles || [])
        .map(c => c.startDate)
        .reduce((acc, d) => (isBefore(acc, d) ? acc : d), getNow());
    },
    endDate() {
      return this.cycles
        .map(c => c.endDate)
        .reduce((acc, d) => (isAfter(acc, d) ? acc : d), getNow());
    },
    cycleId() {
      return (this.getCurrentCycle() || '');
    },
  },
  async created() {
    await Promise.all([
      this.fetchInfo(),
      this.fetchCycles(),
      this.fetchPackages(),
      this.fetchInvoices(),
      this.fetchDiscounts(),
      this.fetchCommitments(),
      this.fetchOrganizationInvoiceable(),
      this.checkForTaxConfig()
    ]);

    if (this.hasError) {
      this.loading = false;
      return;
    }

    await Promise.all([
      this.fetchCurrentCycle(),
      this.fetchDiscountMetadata(),
    ]);

    this.loading = false;
  },
  methods: {
    refresh() {
      this.fetchCommitments();
    },
    getAppliedPricing(d) {
      if (d && d.discountId) {
        const meta = this.discountMetadata[d.discountId];
        if (meta) {
          const pid = meta.packageId;
          return this.packages[pid];
        }
      }
      return null;
    },
    getCurrentCycle() {
      const cycleList = ((this.info || {}).billingCycles || []);
      const current = cycleList.find(bc => bc.state === 'IN_PROGRESS');
      let ans = null;
      if (current) {
        ans = current.id;
      } else if (cycleList.length > 0) {
        const now = getNow();
        const nextCycles = cycleList.filter(c => isAfter(getDate(c.startDate), now)
          || isEqual(getDate(c.startDate), now))
          .sort(sortBy(c => c.startDate));
        const previousCycles = cycleList.filter(c => isBefore(getDate(c.startDate), now))
          .sort(sortBy(c => c.startDate));
        if (nextCycles.length > 0) {
          ans = nextCycles[0].id;
        } else {
          ans = previousCycles[previousCycles.length - 1].id;
        }
      }
      return ans;
    },
    async fetchInfo() {
      const resp = await apis.billable.getBillableOrgInfo(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.info = resp.data;
    },
    async fetchCycles() {
      const resp = await apis.billable.getBillingCycles(this.organization.id, true);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.cycles = resp.data;
    },
    async fetchCurrentCycle() {
      const cycle = this.getCurrentCycle();
      if (!cycle) {
        this.hasError = true;
        return;
      }

      const resp = await apis.billable.getBillingCycle(this.organization.id, cycle);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }

      this.currentCycle = resp.data;
    },
    async fetchPackages() {
      const resp = await apis.organizations.fetchApplicablePricingPackages(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.packages = resp.data.reduce((acc, d) => ({...acc, [d.id]: d}), {});
    },
    async fetchInvoices() {
      const resp = await apis.invoices.list({qs: {organization_id: this.organization.id}});
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.invoices = resp.data;
    },
    async fetchDiscounts() {
      const resp = await apis.discounts.list({qs: {organization_id: this.organization.id}});
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.discounts = resp.data.reduce((acc, d) => ({...acc, [d.id]: d}), {});
    },
    async fetchDiscountMetadata() {
      const resp = await apis.discounts.fetchDiscountMetadata(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.discountMetadata = resp.data;
    },
    async fetchCommitments() {
      const resp = await apis.commitments.list({qs: {organization_id: this.organization.id}});
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.commitments = resp.data;
    },
    async fetchOrganizationInvoiceable() {
      const resp = await apis.organizations.validateIsInvoiceable(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.invoiceable = resp.data;
    },
    async checkForTaxConfig() {
      this.hasTaxProvider = await this.hasTaxProviderConfigured(this.selectedOrganization.id);
    }
  },
};
</script>

<style scoped lang="scss">
.base-select {
  min-width: 200px;
}

.no-results {
  padding-bottom: 25px;
}
</style>
