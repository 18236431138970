export const beforeEachNav = (to, from, next, storeInst) => {
  if (to.query?.secondary || from.query?.secondary || from.fullPath === to.fullPath) {
    return next();
  }

  storeInst.dispatch('setPreviousRoute', from);

  if (storeInst.getters.userContext
    && storeInst.getters.userContext.hasToCaptureBillingDetails
    && to.name !== 'loginCompletePayment') {
    return next({ name: 'loginCompletePayment' });
  }

  return next();
};
