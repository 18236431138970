import {sortBy} from '@/utils';

function getCurrentOrgPermissions(state) {
  if (!state.userContext || !state.userContext.org) {
    return {};
  }
  const currentOrganization = state.currentOrganization || state.userContext.org;
  const myOrganization = state.userContext.org;
  if (!currentOrganization || currentOrganization.id === myOrganization.id) {
    return state.userContext.myOrgPermissions || {};
  }
  return state.permissionsPerOrg[currentOrganization.id] || {};
}

export default {
  loggedIn: state => state.loggedIn === true,
  loggedOut: state => state.loggedIn === false,
  username: (state) => {
    if (state.userContext) {
      return state.userContext.username;
    }
    return '';
  },
  previousRoute: state => state.previousRoute,
  userId: state => state.userContext.id,
  email: state => state.userContext.email,
  sessionTimeout: state => state.sessionTimeout,
  userContext: state => (Object.keys(state.userContext).length ? state.userContext : null),
  identityProvider: state => state.identityProvider,
  hasOtherOrgAccess: state => (Object.keys(state.userContext).length
    ? state.userContext.myOrgPermissions.otherOrgAccess
    : null),
  firstName: state => state.userContext.firstName,
  lastName: state => state.userContext.lastName,
  fullName: (state) => {
    if (state.userContext) {
      return `${state.userContext.firstName} ${state.userContext.lastName}`;
    }
    return '';
  },
  organizationName: (state) => {
    if (state.userContext && state.userContext.org) {
      return state.userContext.org.name;
    }
    return '';
  },
  myOrganization: (state) => {
    if (state.userContext && state.userContext.org) {
      return {
        ...state.userContext.org,
        serviceConnections: state.userContext.serviceConnections,
      };
    }
    return null;
  },
  organizationFeatures: state => state.organizationFeatures || [],
  selectedOrganization: (state) => {
    if (state.userContext) {
      return state.currentOrganization ? state.currentOrganization : state.userContext.org;
    }
    return state.currentOrganization;
  },
  /**
   * Returns connections that are
   * 1. assigned to the current user and organization
   * 2. or owned by the current organization, but scoped to customers
   * @param {*} state
   * @returns assigned or owned service connections
   */
  managedServiceConnections: (state) => {
    return state.managedServiceConnections
      ? (state.managedServiceConnections || []).concat()
        .sort(sortBy(sc => sc.name.toLowerCase()))
      : [];
    },
    /**
     * Returns connections assigned to the current user and organization.
     * @param {*} state
     * @returns assigned service connections
     */
    serviceConnections: (state) => {
      if (!state.currentOrganization) {
        return state.userContext.serviceConnections
          ? state.userContext.serviceConnections.concat()
            .sort(sortBy(sc => sc.name.toLowerCase()))
          : [];
      }
      return state.userContext.serviceConnections
        ? (state.currentOrganization.serviceConnections || []).concat()
          .sort(sortBy(sc => sc.name.toLowerCase()))
        : [];
    },
  locale: state => state.locale,
  defaultLocale: state => state.defaultLocale,
  userBrandingInfo: state => state.userBrandingInfo,
  orgBrandingInfo: state => state.orgBrandingInfo,
  serviceSubMenu: state => state.serviceSubMenu,
  windowId: state => state.windowId,
  loginViaUsernameEnabled: state => state.globalProperties['login.via_username_enabled'] === 'true',
  loginViaEmailEnabled: state => state.globalProperties['login.via_email_enabled'] === 'true',
  systemPermissions: state => getCurrentOrgPermissions(state).system || [],
  servicePermissions: state => getCurrentOrgPermissions(state).service || [],
  globalPermissions: state => (getCurrentOrgPermissions(state).global || []).map(p => `global:${p}`),
  subsPermissions: state => (getCurrentOrgPermissions(state).subs || []).map(p => `subs:${p}`),
  isSingleEntrypointLogin: state => state.globalProperties['login.single_entrypoint_enabled'] === 'true',
  globalProperties: state => state.globalProperties,
  baseUrl: (state) => {
    const url = `${state.globalProperties.protocol}://${window.location.host}`;
    const port = state.globalProperties.port;
    if (!port || port === '80' || port === '443') {
      return url;
    }
    return `${url}:${port}`;
  },
  feedbackSettings: state => state.feedbackSettings,
  billingSettings: state => state.billingSettings,
};
