<template>
  <div 
    :id="`editor-${id}`" 
    :class="{'focused': focus}"
  ></div>
</template>

<script>
import Editor from '@toast-ui/editor';
import { v4 as uuidv4 } from 'uuid';
import '@toast-ui/editor/dist/toastui-editor.css';

export default {
  name: 'MarkdownEditor',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    previewStyle: {
      type: String,
      validator: s => ['vertical', 'tab'].includes(s),
      default: 'vertical',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: uuidv4(),
      focus: false,
      innerValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      if (newVal !== this.innerValue) {
        this.innerValue = newVal || '';
        this.editor.setMarkdown(this.innerValue);
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      el: document.querySelector(`#editor-${this.id}`),
      initialEditType: 'markdown',
      initialValue: this.modelValue,
      previewStyle: this.previewStyle,
      autofocus: false,
      events: {
        change: this.setValue,
        focus: () => this.focus = true,
        blur: () => this.focus = false,
      },
    });
  },
  methods: {
    setValue() {
      this.innerValue = this.editor.getMarkdown();
      this.$emit('update:modelValue', this.innerValue);
    },
  },
};
</script>

<style scoped lang="scss">

/* fix outline WYSIWYG */
:deep(.te-md-container) {
  overflow: visible !important;
}

.focused {
  :deep(.te-ww-container) {
    outline: auto;
    overflow: visible;
  }
}

/* fix outline Markdown */
:deep(.CodeMirror) {
  box-sizing: border-box;
}
:deep(.CodeMirror-focused) {
  outline: auto;
  z-index: 2;
}
</style>
