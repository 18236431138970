<template>
  <div>
    <base-menu-page
      searchEnabled
      :header="header"
      :operations="operations"
      :sections="sections"
      :optionKey="optionKey"
      :options="filters"
      @search="search = $event"
    >
      <router-view></router-view>
    </base-menu-page>
  </div>
</template>

<script>

import { STATUS } from './trials/trials';

const ALL = 'ALL';

export default {
    name: 'OnboardingSection',
    data() {
        return {
            search: '',
        };
    },
    computed: {
        header() {
            return {
                logo: 'address-card',
                subtitle: this.$t('onboarding.description'),
                title: this.$t('onboarding.name'),
            };
        },
        sections() {
            return [
                {
                    title: this.$t('trials_title'),
                    to: { name: 'trials' },
                },
                {
                    title: this.$t('enterprise_registration_tab_title'),
                    to: { name: 'enterpriseRegistrations' },
                },
            ];
        },
        operations() {
            return [
                {
                    route: 'trials',
                    href: '/system/onboarding/trials/configure',
                    action: () => this.$router.push({ name: 'trialsSettings' }),
                    label: 'trials.configure_trials',
                    icon: 'fa fa-cog',
                },
                {
                  route: 'trials',
                  href: '/system/onboarding/trials/add',
                  action: () => this.$router.push({ name: 'addTrial' }),
                  label: 'trials.add.title',
                  icon: 'fa fa-plus-circle',
                },
                {
                    route: 'enterpriseRegistrations',
                    href: '/system/onboarding/enterprise/add',
                    action: () => this.$router.push({ name: 'addEnterpriseRegistration' }),
                    label: 'enterprise_registrations.add_enterprise_registration',
                    icon: 'fa fa-plus-circle',
                },
            ].filter(o => this.$route.name === o.route);
        },
        filters() {
            if (this.$route.name === 'trials') {
                return Object.keys(STATUS).reduce((prev, next) => {
                    prev.push({
                        id: STATUS[next].name,
                        value: STATUS[next].name,
                        label: this.$t(`trials.${STATUS[next].name}`)
                    });
                    return prev;
                }, [{ id: ALL, value: ALL, label: this.$t('trials.all_statuses') }]);
            }
            return [];
        },
        optionKey() {
            if (this.$route.name === 'trials') {
                return 'status';
            }
            return null;
        }
    },
};
</script>
