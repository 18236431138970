<template>
  <div>
    <base-menu-page
      searchEnabled
      :header="header"
      :operations="operations"
      :sections="sections"
      @search="search = $event"
    >
      <router-view :search="search"></router-view>
    </base-menu-page>
  </div>
</template>

<script>
export default {
  name: 'ContentSection',
  data() {
    return {
      search: '',
    };
  },
  computed: {
    header() {
      return {
        logo: 'book',
        subtitle: this.$t('admin_content.description'),
        title: this.$t('admin_content.name'),
      };
    },
    sections() {
      return [

      ];
    },
    operations() {
      return [];
    },
  },
};
</script>
