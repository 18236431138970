import { unflattenFormFields } from '@/utils/operation';
import Api from './api';

class ViewsApi extends Api {
  
  find(entryPoint, serviceCode, envName, path, qs) {
    return this.get(`/orgs/${entryPoint}/services/${serviceCode}/${envName}/views/${path || ''}`, { qs });
  }


  reloadView(entryPoint, serviceCode, envName, path, entity, qs) { 
    return this.post(`/orgs/${entryPoint}/services/${serviceCode}/${envName}/views/${path || ''}`, {
      body: entity || undefined,
      qs,
    })
  } 

  operation(entryPoint, serviceCode, envName,
    entityType, opName, entityId, entity, queryParam, checkPrecondition = false,
    sections = [], context) { 
    let endpoint = `/orgs/${entryPoint}/services/${serviceCode}/${envName}/${entityType}`;
    if (entityId) {
      endpoint = `${endpoint}/${entityId}`;
    }
    return this.post(endpoint, {
      body: unflattenFormFields(entity) || undefined,
      qs: { ...context,
        operation: opName,
        precondition: checkPrecondition,
        ...queryParam,
        sections: sections ? sections.join(',') : null },
    });
  }

  getWorkspace(serviceType, workspace = undefined, query = undefined) {
    let baseUrl = `/views/services/${serviceType}/workspace`;
    if (workspace) {
      baseUrl += `/${workspace}`;
    }
    return this.get(baseUrl, { qs: query});
  }
}

const viewsApi = new ViewsApi();

export default viewsApi;
