export { default as routes } from './routes';
export { default as vuex } from './vuex';

const LANGUAGE_ENTRY_POINT = {
  help: 'en',
  aide: 'fr',
  ayuda: 'es',
};

export { LANGUAGE_ENTRY_POINT };
