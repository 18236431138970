import { CrudApi } from './api';

const ROOT_CONTEXT = '/content/categories';
class CategoriesApi extends CrudApi {
  constructor() {
    super(ROOT_CONTEXT);
  }

  getCategories(currentLocale) {
    const qs = { langauge: currentLocale };
    return this.get(`${ROOT_CONTEXT}/live`, { qs });
  }
}
const categories = new CategoriesApi();
export default categories;
