import Api, { logout as doLogout } from './api';

// The target param is set by a custom ui to indicate what path should be
// appended to the custom-ui endpoint on redirect post-login
const setTargetParam = (options) => {
  const targetParam = new URLSearchParams(window.location.search).get('target');
  if (targetParam) {
    options.qs = {
      target: targetParam,
      ...(options.qs || {}),
    };
  }
};

class AuthApi extends Api {

  constructor() {
    super()
    this.logout = doLogout
  }

  getUserContext() {
    return this.get('users/context', {
      headers: {
        'Cache-Control': 'no-store',
      },
    }, false);
  }

  getIdentityProvider() {
    return this.get('users/idp', {
      headers: {
        'Cache-Control': 'no-store',
      },
    }, false);
  }

  login(username, password) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        username,
        password,
      },
    };
    setTargetParam(options);
    return this.sfetch('/rest/auth/login', options, false);
  }

  twoFactorAuthentication(code, isBackupCode = false) {
    const req = isBackupCode ? { backupCode: code } : { code };
    const options = {
      body: JSON.stringify(req),
    };
    setTargetParam(options);
    return this.post('/auth/2fa', options, false);
  }
}

export default new AuthApi();
