<template>
  <base-message 
    class="no-results" 
    :class="{ 'inline': !buttonLabel }" 
    :icon="icon" 
    :textLabel="textLabel"
  >
    <app-link 
      v-if="buttonLabel" 
      v-protected="buttonRoute" 
      :to="buttonRoute"
    >
      <base-button 
        v-if="buttonLabel" 
        :preventDefault="false" 
        :rounded="true"
      >
        <base-icon 
          v-if="buttonIcon" 
          :icon="buttonIcon"
        />
        {{ $t(buttonLabel) }}
      </base-button>
    </app-link>
  </base-message>
</template>

<script>
import BaseMessage from './BaseMessage';

export default {
  name: 'EmptyMessage',
  components: { BaseMessage },
  extends: BaseMessage,
  props: {
    buttonIcon: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    buttonRoute: {
      type: [Object, String],
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.message.inline {
    :deep(.text) {
      font-size: unset;
      margin-bottom: 2px;
      color: var(--list-header-text);
    }
    :deep(.icon-container) {
      font-size: unset;
      margin-top: unset;
      margin-right: 10px;
      color: var(--list-header-text);
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

}
</style>
