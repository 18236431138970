<template>
  <div class="login-error-message-container">
    <div class="message-container">
      <h1 class="title">
        {{ $t(`login_error.${type}_title`) }}
      </h1>
      <div class="body">
        {{ $t(`login_error.${type}_description`) }}
      </div>
    </div>
    <div class="link-container">
      <action-text 
        class="action-text-hoverable" 
        label="back_to_login" 
        @keyup.enter="returnToLogin" 
        @click="returnToLogin"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginErrorMessage',
  props: {
    type: {
      type: String,
      default: 'unexpected',
    },
  },
  emits: ['returnToLogin'],
  methods: {
    returnToLogin() {
      this.$router.replace('/login');
      this.$emit('returnToLogin');
    },
  },
};
</script>


<style scoped lang="scss">
.login-error-message-container {
    margin: -20px;
}

</style>
