<template>
  <div class="row">
    <action-dropdown
      :items="actions"
      @select="confirmDelete=true"
    />
    <confirm-modal
      v-if="confirmDelete"
      :open="confirmDelete"
      :headerLabel="getHeaderLabel()"
      :detailsLabel="getDetailsLabel()"
      :detailsInterpolation="nameInterpolation"
      @ok="confirm"
      @cancel="confirmDelete=false"
    />
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';

export default {
  name: 'DiscountActions',
  props: {
    discount: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    credit: {
      type: Boolean,
    },
    pricingPackageId: {
      type: String,
    },
    organizationId: {
      type: String,
    },
  },
  emits: ['deleted'],
  data() {
    return {
      confirmDelete: false,
      name: this.discount.name[this.locale],
    };
  },
  computed: {
    type() {
      return this.credit ? 'credit' : 'percentage';
    },
    nameInterpolation() {
      return {
        name: this.discount.name[this.locale],
      };
    },
    actions() {
      if (this.discount.isDeactivated || this.discount.status === 'ENDED') {
        return [];
      } else if (this.discount.status === 'UPCOMING') {
        return [
          {
            value: 'delete',
            label: 'delete',
            icon: 'fa fa-trash',
            permission: 'reseller:monetization',
          },
        ];
      }
      return [
        {
          value: 'deactivate',
          label: 'deactivate',
          icon: 'fa fa-trash',
          permission: 'reseller:monetization',
        },
      ];
    },
  },
  methods: {
    getHeaderLabel() {
      if (this.discount.status === 'UPCOMING') {
        return `monetization.discounts.${this.type}.delete_header`;
      }
      return `monetization.discounts.${this.type}.deactivate_header`;
    },
    getDetailsLabel() {
      if (this.discount.status === 'UPCOMING') {
        return `monetization.discounts.${this.type}.delete_confirm`;
      }
      return `monetization.discounts.${this.type}.deactivate_confirm`;
    },
    async confirm() {
      return this.discount.status === 'UPCOMING' ? this.performDelete() : this.performDeactivate();
    },
    async performDeactivate() {
      this.confirmDelete = false;

      const resp = null;

      if (this.pricingPackageId) {
        await apis.pricingPackages
          .deactivateDiscount(this.pricingPackageId, this.discount.id);
      } else {
        await apis.organizations
          .deactivateDiscount(this.organizationId, this.discount.id);
      }

      if (resp && resp.status !== 200) {
        notify.error(this.$t(`monetization.discounts.${this.type}.error_deactivating`, this.nameInterpolation));
      } else {
        notify.success(this.$t(`monetization.discounts.${this.type}.deactivated`, this.nameInterpolation));
        this.$emit('deleted');
      }
      this.confirmDelete = false;
    },
    async performDelete() {
      this.confirmDelete = false;

      const resp = null;

      if (this.pricingPackageId) {
        await apis.pricingPackages
          .deleteDiscount(this.pricingPackageId, this.discount.id);
      } else {
        await apis.organizations
          .deleteDiscount(this.organizationId, this.discount.id);
      }

      if (resp && resp.status !== 200) {
        notify.error(this.$t(`monetization.discounts.${this.type}.error_deleting`, this.nameInterpolation));
      } else {
        notify.success(this.$t(`monetization.discounts.${this.type}.deleted`, this.nameInterpolation));
        this.$emit('deleted');
      }
      this.confirmDelete = false;
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>
