<template>
  <advanced-details 
    titleLabel="billing_preferences" 
    :loading="!loaded"
  >
    <BillingAddressForm
      :organization="organization"
    />

    <NotificationEmailForm 
      class="emailSection" 
      :organization="organization"
    />

    <CreditCardInfo
      v-if="creditCardMode"
      :creditCard="creditCard"
      :organization="organization"
    />

    <TransactionList
      :payments="payments"
      :organization="organization"
    />
  </advanced-details>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import NotificationEmailForm from './NotificationEmailForm';
import TransactionList from './TransactionList';
import CreditCardInfo from './CreditCardInfo';
import BillingAddressForm from './BillingAddressForm';

export default {
  name: 'OrgBillingDetails',
  components: {
    NotificationEmailForm,
    TransactionList,
    CreditCardInfo,
    BillingAddressForm,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      creditCard: {},
      payments: [],
    };
  },
  computed: {
    creditCardMode() {
      return this.organization.billingMode == 'CREDIT_CARD';
    },
  },
  created() {
    this.loaded = false;
    this.loadPaymentInfo();
    this.loadPayments();
    this.loaded = true;
  },
  methods: {
    async loadPaymentInfo() {
      const resp = await apis.organizations.getBillingInfo(this.organization.id);
      if (resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return;
      }
      this.creditCard = resp.data;
    },
    async loadPayments() {
      const resp = await apis.payments.getPaymentsForOrganization(this.organization.id);
      if (resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return;
      }
      this.payments = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.emailSection {
  padding-bottom: 20px;
}
</style>
