import CmcAlign from "./CmcAlign.vue"
import CmcBlock from "./CmcBlock.vue"
import CmcGrid from "./CmcGrid.vue"
import CmcGridCol from "./CmcGridCol.vue"
import CmcGroup from "./CmcGroup.vue"
import CmcLayout from "./CmcLayout.vue"
import CmcPair from "./CmcPair.vue"
import CmcStack from "./CmcStack.vue"

export default {
  CmcAlign,
  CmcBlock,
  CmcGrid,
  CmcGridCol,
  CmcGroup,
  CmcLayout,
  CmcPair,
  CmcStack,
}