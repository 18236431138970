import { CrudApi } from './api';

class EmailSettingsApi extends CrudApi {
  constructor() {
    super('email_settings');
  }
}

const emailSettingsApi = new EmailSettingsApi();

export default emailSettingsApi;
