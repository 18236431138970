import { CrudApi } from './api';

class FeaturesApi extends CrudApi {
  constructor() {
    super('organization_features');
  }
}

const featuresApi = new FeaturesApi();

export default featuresApi;
