<template>
  <div>
    <div class="title-container">
      <div
        id="notification_emails"
        class="secondary-title"
      >
        <div>{{ $t('notification_emails') }}</div>
        <span class="optional">({{ $t('optional') }})</span>
      </div>
      <div class="edit">
        <action-icon
          v-if="hasEditPermission"
          icon="fa fa-edit"
          tooltipLabel="edit"
          expandOnHover
          clickable
          @click="editNotificationEmail"
        />
      </div>
    </div>
    <div
      id="notification_emails_desc"
      class="description"
    >
      {{ $t('notification_emails_desc') }}
    </div>
    <base-list
      aria-labelledby="notification_emails"
      aria-describedby="notification_emails_desc"
      :loading="loading"
      :ariaRowCount="emails.length"
    >
      <list-header>
        <list-column>{{ $t('email') }}</list-column>
        <list-column>{{ $t('name') }}</list-column>
      </list-header>
      <div v-if="!editingEmail">
        <empty-message
          v-if="emails.length == 0"
          textLabel="no_emails_configured"
        />
        <list-row
          v-for="entry in emails"
          :key="entry.email"
        >
          <list-column>
            {{ entry.email }}
          </list-column>
          <list-column>
            {{ entry.name }}
          </list-column>
        </list-row>
      </div>
      <div v-else>
        <base-form
          submitLabel="submit"
          @submit="submit"
          @cancel="cancel"
        >
          <div
            v-for="(entry, index) in emails"
            :key="index"
            class="email-details-row"
          >
            <form-row class="flex-1 email-details-form">
              <base-input
                v-model="entry.email"
                type="email"
                :error="entry.errors.email"
              ></base-input>
            </form-row>
            <form-row class="flex-1 email-details-form">
              <base-input
                v-model="entry.name"
                :error="entry.errors.name"
              ></base-input>
            </form-row>
            <action-icon
              class="email-details-button"
              icon="fa fa-trash"
              tooltipLabel="delete"
              @click="removeEmailEntry(index)"
            />
          </div>
          <form-row>
            <div class="button-row">
              <base-button
                v-if="emails.length < 3"
                @click="addEmail"
              >
                <base-icon icon="fa fa-plus" /> {{ $t('add_email') }}
              </base-button>
            </div>
          </form-row>
        </base-form>
      </div>
    </base-list>
  </div>
</template>
<script>

import authz from '@/authz';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import validators from '@/utils/validators';

export default {
  name: 'NotificationEmailForm',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editingEmail: false,
      emails: [],
      loading: true,
      billingEmails: [],
    };
  },
  computed: {
    hasEditPermission() {
      return !this.editingEmail && authz.hasPermission({
        permissions: ["admin:billing", "reseller:organizationBilling"],
        or: true
      });
    },
  },
  watch: {
    billingEmails: {
      handler() {
        this.emails = this.billingEmails.slice();
      },
      deep: true,
    },
  },
  created() {
    this.loading = true;
    this.loadBillingEmails();
    this.loading = false;
  },
  methods: {
    formatEmails(emails) {
      const formattedList = [];
      const names = Object.values(emails || {});
      Object.keys(emails || {})
        .forEach((cur, index) => {
          formattedList.push({
            email: cur,
            name: names[index],
            errors: {
              email: '',
              name: '',
            },
          });
        });
      return formattedList;
    },
    editNotificationEmail() {
      this.editingEmail = !this.editingEmail;
    },
    addEmail() {
      this.emails.push({
        email: '',
        name: '',
        errors: {
          email: '',
          name: '',
        },
      });
    },
    removeEmailEntry(index) {
      this.emails.splice(index, 1);
    },
    validateEmails() {
      this.emails.forEach((emailEntry) => {
        emailEntry.errors.name = !emailEntry.name ? this.$t('missing_form_field') : '';
        if (!emailEntry.email) {
          emailEntry.errors.email = this.$t('missing_form_field');
        } else if (!validators.validEmail(emailEntry.email)) {
          emailEntry.errors.email = this.$t('invalid_email_address');
        } else if (this.emails.filter(e => e.email === emailEntry.email).length > 1) {
          emailEntry.errors.email = this.$t('duplicate_email');
        } else {
          emailEntry.errors.email = '';
        }
      });
    },
    hasErrors() {
      return this.emails.filter(emailEntry =>
        !!emailEntry.errors.name || !!emailEntry.errors.email)
        .length > 0;
    },
    async submit() {
      this.validateEmails();
      if (!this.hasErrors()) {
        const body = {
          billingEmails: {},
        };
        this.emails.forEach((emailEntry) => {
          body.billingEmails[emailEntry.email] = emailEntry.name;
        });
        const resp = await apis.organizations.updateBillingEmails(this.organization.id, body);
        if (resp && resp.errors) {
          notify.error(this.$t('emails_updated_error'));
          this.emails = this.billingEmails.slice();
        } else {
          notify.success(this.$t('emails_updated_success'));
          this.billingEmails = this.emails;
        }
        this.editingEmail = !this.editingEmail;
      }
    },
    cancel() {
      this.emails = this.billingEmails.slice();
      this.editingEmail = !this.editingEmail;
    },
    async loadBillingEmails() {
      const resp = await apis.organizations.getBillingEmail(this.organization.id);
      if (resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return;
      }
      this.billingEmails = resp.data ? this.formatEmails(resp.data.billingEmails) : [];
    },
  },
};
</script>
<style lang="scss" scoped>
.title-container {
  display:flex;
  justify-content: space-between;
}
.edit {
  float: right;
}
.email-details-row {
  display: flex;
}
.secondary-title {
  margin: 0;
}
.email-details-form {
  padding-top: 5px;
  padding-bottom: 5px;
}
.email-details-button {
  padding-top: 15px;
}
.form-row {
    &:not(:first-child):not(:last-child) {
        margin-left: 5px;
        margin-right: 5px;
    }
    &:first-child:last-child {
        margin-left: 0px;
        margin-right: 0px;
    }
    &:first-child {
        margin-right: 5px;
    }
    &:last-child {
        margin-left: 5px;
    }
}
.button-row {
  padding-top: 10px;
}
</style>
