import Api from './api';

class DashboardApi extends Api {
  render(orgId, queryParams = {}) {
    return this.get(`/organizations/${orgId}/renderDashboard`, {
      qs: {
        ...queryParams,
      },
    });
  }
}

const dashboardApi = new DashboardApi();

export default dashboardApi;
