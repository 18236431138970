<template>
  <div
    id="container"
    class="container"
  >
    <div 
      v-for="(filter, index) in selectedFilters"
      :key="filter.key"
      class="filter-row"
    >
      <div
        :class="['row', index === 0 ? 'indent-left': '']"
      >
        <p
          v-if="index > 0"
          class="linking-indicator"
        >
          {{ $t('and') }}
        </p>
        <base-select
          placeholder="select"
          :softBorder="true"
          :items="getAvailableKeys(filter)"
          :modelValue="filter.key.value"
          @update:modelValue="onKeySelected($event, filter, index)"
        >
        </base-select>
        <p class="connection-indicator">
          {{ $t(filter.connectionWords ? filter.connectionWords : 'is' ) }}
        </p>
        <base-multi-select
          ref="baseMultiSelect"
          :disabled="!filter.key.value"
          :items="getAvailableValuesBy(filter.key.value)"
          :filter="filter"
          :selectedPlaceholder="'selected_tags'"
          @apply="onApply($event, filter)"
          @clear="onClear($event, filter)"
          @update:modelValue="onValueUpdated($event, filter)"
        >
        </base-multi-select>
        <div
          v-if="shouldDisplayRemoveButton(index)"
          id="remove-filter-button"
          class="button"
          tabindex="0"
          :aria-label="$t('remove_filter', { key: filter.key.value })"
          @click="onRemoveButton(index)"
          @keyup.enter="onRemoveButton(index)"
        >
          <i class="fa fa-minus" />       
        </div>
        <div
          v-if="shouldDisplayAddButton(index)"
          id="add-filter-button"
          class="button"
          tabindex="0"
          :aria-label="$t('add_filter')"
          @click="onAddButton"
          @keyup.enter="onAddButton"
        >
          <i class="fa fa-plus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FILTER_TYPE } from '@/utils/components/filters';

export default {
    name: 'MultiCriteriaFilter',
    props: {
        filters: {
            type: Array,
            required: true,
        },
    },
    emits: ['refresh'],
    data() {
        return {
            selectedFilters: []
        }
    },
    computed: {
        ...mapGetters([
            'locale',
            'orgBrandingInfo',
        ]),
    },
    created () {
        if (this.$route.query.filter && this.$route.query.filter.length > 0) {
            // Initialize the filters from the query params
            this.loadFilters();
        } else if (this.filters.length > 0) {
            // Initialize the default selected filters, if any.
            this.loadDefaultFilterValues(this.filters[0]);
        }
    },
    methods: {
        /**
         * Event triggered whenever the user selects a key.
         * 
         * @param {string} $event The selected key
         * @param {Filter} filter The filter
         * @param {number} index The position in the filters array
         */
        onKeySelected($event, filter, index) {
            const selectedFilter = this.filters.find(x => x.key.value === $event);

            // Update the filter with the information of the selected filter.
            filter.key = {
                value: $event,
                label: $event
            };
            filter.connectionWords = FILTER_TYPE.DATE_PRESET === selectedFilter.type ? 'matches' : 'is';
            filter.values = new Set();
            filter.type = selectedFilter.type;
            filter.singleOption = selectedFilter.singleOption;

            this.$refs.baseMultiSelect[index].reset();
            this.$emit('refresh', this.selectedFilters);
        },
        /**
         * Event triggered whenever the user selects a value, without necessary applying it.
         * 
         * @param {Set} $event The set of values
         * @param {Filter} filter The filter
         */
        onValueUpdated($event, filter) {
            filter.connectionWords = this.getLogicalOperations($event, filter);
        },
        /**
         * Event triggered whenever the user applies its selection of values
         * 
         * This method emit a 'reload' event
         * 
         * @param {Set} $event The set of values
         * @param {Filter} filter The filter
         */
        onApply($event, filter) {
            filter.values = $event;
            filter.connectionWords = this.getLogicalOperations($event, filter);
            filter.hasSelectedValue = true;

            // Format the filters into a query params format
            this.pushFilteringQueryParameter();

            this.$emit('refresh', this.selectedFilters);
        },
        /**
         * Event triggered whenever the user applies its selection of values
         * 
         * @param {Set} $event The set of values
         * @param {Filter} filter The filter
         */
        onClear($event, filter) {
            filter.values = $event;
            filter.connectionWords = this.getLogicalOperations($event, filter);
        },
        /**
         * Get the appropriate connecting words from a set of values
         * 
         * @param {Set} values The set of values
         * @param {Filter} filter The filter
         */
        getLogicalOperations(values, filter) {
            if (filter.type === FILTER_TYPE.DATE_PRESET) {
                return 'matches';
            }
            if (values.size > 1 || values.length > 1) {
                return 'any_of';
            }
            return 'is';
        },
        /**
         * Get available keys based on the filter selected.
         * @param {filter} filter The filter
         */
        getAvailableKeys(filter) {
            // Fetch what keys is currently selected.
            const selectedKeys = this.selectedFilters.filter(x => x.key.value !== filter.key.value).map(x => x.key.value);

            // Filter out already selected keys.
            return this.filters.filter(filter => !selectedKeys.includes(filter.key.value)).map(filter => filter.key);
        },
        /**
         * Get the all values available from a key.
         * @param {string} key The key
         */
        getAvailableValuesBy(key) {
            // The key has not yet been selected, simply return empty.
            if (key === undefined || key === '') {
                return [];
            }
            return this.filters.find(filter => filter.key.value === key).values || [];
        },
        /**
         * Determine if the Add button should be displayed for this row
         * 
         * @param {number} index The index of the row
         */
        shouldDisplayAddButton(index) {
            const isLast =  this.selectedFilters.length - 1 === index;
            const canAdd = this.filters.length - 1 > index;

            // If it has selected value or has default value
            const hasSelectedValue = this.selectedFilters[index].hasSelectedValue || this.selectedFilters[index].loadDefaultValue;

            return isLast && canAdd && hasSelectedValue;
        },
        /**
         * Determine if the Remove button should be displayed for this row.
         * 
         * @param {number} index The index of the row
         */
        shouldDisplayRemoveButton(index) {
            const isNotFirst = this.selectedFilters.length !== 1;
            const canRemove = this.selectedFilters.length >= index + 1;
            return isNotFirst && canRemove;
        },
        /**
         * On Add button event, add a row filter
         */
        onAddButton() {
            // Fetch what keys is currently selected.
            const currentSelectedKeys = this.selectedFilters.map(x => x.key.value);

            // Filter out those selected keys
            const availableFilters = this.filters.filter(x => !currentSelectedKeys.includes(x.key.value));

            // Insert the first possible filters.
            if (availableFilters.length > 0) {
                this.loadDefaultFilterValues(availableFilters[0]);
            }
        },
        /**
         * On Remove button event, remove a row filter at index.
         * @param {number} index The index of the row to remove
         */
        onRemoveButton(index) {
            this.selectedFilters.splice(index, 1);

            this.pushFilteringQueryParameter();
            
            this.$emit('refresh', this.selectedFilters);
        },
        /**
         * Method to load the default values of a filter, if required. Else, empty values.
         * 
         * @param {filter} filter The filter
         */
        loadDefaultFilterValues(filter) {
            const length = this.selectedFilters.push(JSON.parse(JSON.stringify(filter)));

            // Fetch the newly added filter
            const addedFilter = this.selectedFilters[length - 1];
            if (addedFilter.loadDefaultValue) {
                this.onApply([addedFilter.values[0] || {}], addedFilter);
            } else {
                this.onApply([], addedFilter);
            }
        },
        /**
         * Refresh the query params in the URL
         */
        pushFilteringQueryParameter() {
            this.$router.replace({
                path: this.$route.path,
                query: { ...this.$route.query, filter: this.formatFilters(this.selectedFilters)}
            });
        },
        /**
         * Load pre-existing filters from the query params.
         * 
         * This method emits a refresh event.
         */
        loadFilters() {
            const params = this.$route.query.filter;

            let filters = [];
            if (typeof params === 'string') {
                filters = [this.parseFilter(params)];
            } else {
                filters = params.map(param => this.parseFilter(param));
            }

            for (const filter of filters) {
                // Find the "template" filters based on the key.
                const templateFilter = this.filters.find(x => x.key.value === filter.key);

                // If it isn't found, simply ignore this params.
                if (!templateFilter) {
                    continue;
                }

                // Fetch the list of values that are selected.
                const selectedValues = templateFilter.values.filter(x => filter.values.includes(x.value));
            
                // Take a copy of the template filter.
                const copy = JSON.parse(JSON.stringify(templateFilter));

                // Set its fields.
                copy.values = selectedValues;
                copy.connectionWords = this.getLogicalOperations(selectedValues, copy);
                copy.hasSelectedValue = true;

                this.selectedFilters.push(copy);
            }

            // Emit an event if you have any selected filters.
            if (this.selectedFilters.length > 0) {
                this.$emit('refresh', this.selectedFilters);
            }
        },
        /**
         * Parse the filter from a known format back to a usable format
         * 
         * e.g: key:value1,value2 => { key: key, values: [value1, value2] }
         * 
         * @param {filter} filter The filter
         */
        parseFilter(filter) {
            return { 
                key: filter.substring(0, filter.indexOf(":")),
                values: (filter.substring(filter.indexOf(":") + 1, filter.length)).split(",")
            }
        },
        /**
         * Format the filters into a format encoded to be stored in query params
         * 
         * e.g: { key: key, values: [value1, value2] } => key:value1,value2
         * 
         * Note: Filter with empty values are ignored.
         * @param {array} filters The array of filters
         */
        formatFilters(filters) {
            return filters.map(filter => {
                // Format the values into a comma-separated list.
                const values = [...filter.values].map(x => x.value).join(",");
                if (values === '') {
                    return '';
                }
                // Format the filter into a key:value1,value2
                return filter.key.value + ":" + values;
            }).filter(x => !!x)
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    width: inherit;

    .filter-row {
        margin-top: 4px;
        width: fit-content;
    }
}

.indent-left {
    margin-left: 50px
}

.linking-indicator {
    width: 50px;
    text-align: center;
    margin-top: 13px;
}

.row {
    height: 45px;
    border-radius: 3px;
    background-color: var(--section-header-bg-dark);

    .base-select {
        height: fit-content;
        min-width: 200px;
        margin: 2px;
    }

    #base-multi-select {
        height: 40px;
        width: 200px;
        margin: 2px;
    }

    .connection-indicator {
        min-width: 60px;
        text-align: center;
        margin-top: 13px;
    }

    .button {
        width: 38px;
        height: 38px;
        margin: 2px;
        padding: 0px;
        background-color: var(--background);
        border: 1px solid var(--plain-dark);
        border-radius: 3px;
        cursor: pointer;

        i {
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
}
</style>
