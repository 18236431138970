<template>
  <div class="invoice-summary-container">
    <span class="invoice-details-summary">{{ $t('summary') }}</span>
    <div class="invoice-details-summary-body">
      <base-list
        aria-labelledby="invoice-details-summary"
        class="summary-list"
        :ariaRowCount="totalRows"
      >
        <list-row
          v-if="hasPreTaxDiscounts || hasPreTaxAccountCredits"
          noActions
        >
          <list-column>{{ $t('subtotal') }}</list-column>
          <list-column alignRight>
            {{ formatCurrency(invoiceTotalBeforeDiscount, currency) }}
          </list-column>
        </list-row>
        <InvoiceDiscountRow
          v-if="hasPreTaxDiscounts && preTaxDiscountList.length === 1"
          :discount="preTaxDiscountList[0]"
          :billingCycle="billingCycle"
          :currency="currency"
        />
        <list-row
          v-else-if="hasPreTaxDiscounts"
          noActions
          @click="expandPreTaxDiscounts = !expandPreTaxDiscounts"
        >
          <list-column class="col-nowrap">
            <div class="caret-container">
              <base-icon
                :icon="`fa ${expandDiscountClass} logoPlaceholder`"
              />
            </div>
            {{ $t(preTaxDiscountLabel) }}
          </list-column>
          <list-column alignRight>
            {{ formatCurrency(preTaxInvoiceDiscountTotal, currency) }}
          </list-column>
        </list-row>
        <list-row
          v-if="expandPreTaxDiscounts"
          noActions
        >
          <list-column :size="2">
            <AdjustmentList
              v-if="hasPreTaxDiscounts"
              type="discounts"
              :adjustmentList="preTaxDiscountList"
              :currency="currency"
              :billingCycle="billingCycle"
              class="adjustmentList"
            />
          </list-column>
        </list-row>
        <list-row
          v-if="hasPreTaxAccountCredits"
          noActions
          @click="expandPreTaxCredits = !expandPreTaxCredits"
        >
          <list-column class="col-nowrap">
            <div class="caret-container">
              <base-icon
                :icon="`fa ${expandPreTaxCreditClass} logoPlaceholder`"
              />
            </div>
            {{ $t(preTaxCreditLabel) }}
          </list-column>
          <list-column alignRight>
            {{ formatCurrency(totalPreTaxCreditAmount, currency) }}
          </list-column>
        </list-row>
        <list-row
          v-if="hasPreTaxAccountCredits && expandPreTaxCredits"
          noActions
        >
          <list-column :size="2">
            <AdjustmentList
              v-if="hasPreTaxAccountCredits"
              :adjustmentList="preTaxCreditList"
              :currency="currency"
              :billingCycle="billingCycle"
              class="adjustmentList"
            />
          </list-column>
        </list-row>
        <list-row noActions>
          <list-column>
            {{ hasPreTaxDiscounts || hasPreTaxAccountCredits ? $t(subTotalAfterAdjustmentsLabel) :
              $t('subtotal') }}
          </list-column>
          <list-column alignRight>
            {{ formatCurrency(invoiceDetail.subTotal, currency) }}
          </list-column>
        </list-row>
        <template v-if="incompleteTaxes">
          <list-row noActions>
            <list-column> {{ $t('taxes') }} </list-column>
            <list-column alignRight>
              <div class="description">
                <base-icon icon="fa fa-warning yellow" />
                {{ $t('unset_customer_address') }}
              </div>
            </list-column>
          </list-row>
        </template>
        <template v-else-if="taxesProcessed">
          <list-row
            v-for="tax in sortedTaxAmount"
            :key="tax[0]"
            noActions
          >
            <list-column> {{ tax[0] }} </list-column>
            <list-column alignRight>
              {{ formatCurrency(tax[1], currency) }}
            </list-column>
          </list-row>
        </template>
        <InvoiceDiscountRow
          v-if="hasPostTaxDiscounts && postTaxDiscountList.length === 1"
          :discount="postTaxDiscountList[0]"
          :billingCycle="billingCycle"
          :currency="currency"
        />
        <list-row
          v-else-if="hasPostTaxDiscounts"
          noActions
          @click="expandPostTaxDiscounts = !expandPostTaxDiscounts"
        >
          <list-column class="col-nowrap">
            <div class="caret-container">
              <base-icon
                :icon="`fa ${expandPostTaxDiscountClass} logoPlaceholder`"
              />
            </div>
            {{ $t(afterTaxDiscountLabel) }}
          </list-column>
          <list-column alignRight>
            {{ formatCurrency(postTaxDiscountTotal, currency) }}
          </list-column>
        </list-row>
        <list-row
          v-if="expandPostTaxDiscounts"
          noActions
        >
          <list-column :size="2">
            <AdjustmentList
              v-if="hasPostTaxDiscounts"
              type="discounts"
              :adjustmentList="postTaxDiscountList"
              :currency="currency"
              :billingCycle="billingCycle"
              class="adjustmentList"
            />
          </list-column>
        </list-row>
        <list-row
          v-if="hasCredits"
          noActions
          @click="expandCredits = !expandCredits"
        >
          <list-column class="col-nowrap">
            <div class="caret-container">
              <base-icon
                :icon="`fa ${expandCreditClass} logoPlaceholder`"
              />
            </div>
            {{ $t(afterTaxCreditLabel) }}
          </list-column>
          <list-column alignRight>
            {{ formatCurrency(totalCreditAmount, currency) }}
          </list-column>
        </list-row>
        <list-row
          v-if="hasCredits && expandCredits"
          noActions
        >
          <list-column :size="2">
            <AdjustmentList
              v-if="hasCredits"
              :adjustmentList="creditList"
              :currency="currency"
              :billingCycle="billingCycle"
              class="adjustmentList"
            />
          </list-column>
        </list-row>
        <list-row
          class="bold"
          noActions
        >
          <list-column> {{ $t('total') }} </list-column>
          <list-column alignRight>
            {{ formatCurrency(invoiceDetail.total, currency) }} {{ currency }}
          </list-column>
        </list-row>
      </base-list>
    </div>
  </div>
</template>

<script>
import ListColumn from '@/components/list/ListColumn';
import { isMobileMode } from '@/utils';
import { comparatorMixin } from '@/mixins/comparator';
import { currencyMixin } from '@/mixins/currencyMixin';
import { taxMixin } from '@/mixins/taxMixin';
import { adjustmentMixin } from '../OrgPricingReport/adjustmentMixin';
import AdjustmentList from './AdjustmentList';
import InvoiceDiscountRow from './InvoiceDiscountRow';

export default {
  name: 'InvoiceDetailsSummary',
  components: { ListColumn, AdjustmentList, InvoiceDiscountRow },
  mixins: [comparatorMixin, currencyMixin, adjustmentMixin, taxMixin],
  props: {
    billingCycle: {
      type: Object,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandPreTaxCredits: false,
      expandCredits: false,
      expandPreTaxDiscounts: false,
      expandPostTaxDiscounts: false,
      taxProviderConfigured: false,
    };
  },
  computed: {
    preTaxDiscountLabel() {
      if (this.hasPreTaxDiscounts && this.hasPostTaxDiscounts) {
        return 'pretax_discounts';
      }
      return 'account_discounts';
    },
    preTaxCreditLabel() {
      if (this.hasPreTaxAccountCredits && this.hasCredits) {
        return 'pretax_credits';
      }
      return 'account_credits';
    },
    subTotalAfterAdjustmentsLabel() {
      if (this.hasPreTaxDiscounts && !this.hasPreTaxAccountCredits) {
        return 'subtotal_after_discounts';
      }
      if (!this.hasPreTaxDiscounts && this.hasPreTaxAccountCredits) {
        return 'subtotal_after_credits';
      }
      return 'subtotal_after_adjustments';
    },
    afterTaxDiscountLabel() {
      if (this.hasPreTaxDiscounts && this.hasPostTaxDiscounts) {
        return 'aftertax_discounts';
      }
      if (this.postTaxDiscountDetails.every(d => d.source.scope === 'ALL_PRODUCTS')) {
        return 'account_discounts';
      }
      return 'discounts';
    },
    afterTaxCreditLabel() {
      if (this.hasPreTaxAccountCredits && this.hasCredits) {
        return 'aftertax_credits';
      }
      if (this.creditsDetails.every(d => d.source.scope === 'ALL_PRODUCTS')) {
        return 'account_credits';
      }
      return 'credits';
    },
    inProgress() {
      return this.status === 'IN_PROGRESS';
    },
    taxesProcessed() {
      return this.hasPreTaxAggregatedAdjustments(this.invoiceDetail, this.Type.TAX);
    },
    hasCredits() {
      return this.hasAfterTaxAdjustments(this.Type.CREDIT)
        && this.invoiceDetail.adjustmentAggregations
          .find(adjustment => adjustment.type === this.Type.CREDIT).cumulativeAmount !== 0;
    },
    hasPreTaxAccountCredits() {
      return (this.invoiceDetail.preTaxAdjustments || []).some(a => a.type === this.Type.CREDIT);
    },
    hasPreTaxDiscounts() {
      return (this.invoiceDetail.preTaxAdjustments || [])
        .some(a => a.type === this.Type.PERCENTAGE);
    },
    hasPostTaxDiscounts() {
      return this.postTaxDiscountDetails && this.postTaxDiscountDetails.length > 0;
    },
    taxAmountBySubType() {
      return this.invoiceDetail.preTaxAdjustmentAggregations
        .filter(agg => agg.type === this.Type.TAX && agg.subtype)
        .reduce((acc, t) => ({
          ...acc,
          [t.subtype]: t.cumulativeAmount,
        }), {});
    },
    sortedTaxAmount() {
      return Object.entries(this.taxAmountBySubType)
        .sort((a, b) => this.stringCompare(a[0], b[0]));
    },
    preTaxInvoiceDiscounts() {
      return this.invoiceDetail.preTaxAdjustments.filter(t => t.type === this.Type.PERCENTAGE);
    },
    preTaxInvoiceDiscountTotal() {
      const preTaxDiscount = this.invoiceDetail.preTaxAdjustmentAggregations
        .find(t => t.type === this.Type.PERCENTAGE);
      return preTaxDiscount.scopedAmount;
    },
    postTaxDiscountTotal() {
      const preTaxDiscount = this.invoiceDetail.adjustmentAggregations
        .find(t => t.type === this.Type.PERCENTAGE);
      return preTaxDiscount.cumulativeAmount;
    },
    preTaxInvoiceDetailDiscount() {
      return (this.invoiceDetail.preTaxAdjustments || [])
        .find(agg => agg.type === this.Type.PERCENTAGE) || {};
    },
    preTaxInvoiceDetailCredit() {
      return (this.invoiceDetail.preTaxAdjustments || [])
        .find(agg => agg.type === this.Type.CREDIT) || {};
    },
    invoiceTotalBeforeDiscount() {
      return this.preTaxInvoiceDetailDiscount.before || this.preTaxInvoiceDetailCredit.before;
    },
    totalCreditAmount() {
      return (this.invoiceDetail.adjustmentAggregations
        .find(agg => agg.type === this.Type.CREDIT) || {}).cumulativeAmount;
    },
    totalPreTaxCreditAmount() {
      return (this.invoiceDetail.preTaxAdjustmentAggregations
        .find(agg => agg.type === this.Type.CREDIT) || {}).scopedAmount;
    },
    currency() {
      return this.invoiceDetail.currency;
    },
    expandCreditClass() {
      if (this.expandCredits) {
        return 'fa-chevron-down';
      }
      return isMobileMode() ? 'fa-chevron-left' : 'fa-chevron-right';
    },
    expandPreTaxCreditClass() {
      if (this.expandPreTaxCredits) {
        return 'fa-chevron-down';
      }
      return isMobileMode() ? 'fa-chevron-left' : 'fa-chevron-right';
    },
    expandDiscountClass() {
      if (this.expandPreTaxDiscounts) {
        return 'fa-chevron-down';
      }
      return isMobileMode() ? 'fa-chevron-left' : 'fa-chevron-right';
    },
    expandPostTaxDiscountClass() {
      if (this.expandPostTaxDiscounts) {
        return 'fa-chevron-down';
      }
      return isMobileMode() ? 'fa-chevron-left' : 'fa-chevron-right';
    },
    totalRows() {
      // total and sub-total rows always present
      let count = 2;
      if (this.incompleteTaxes) {
        // warning for the tax incomplete
        count += 1;
      }
      if (this.taxesProcessed) {
        // lines for the displayed taxes
        count += this.sortedTaxAmount.length;
      }
      if (this.hasCredits) {
        // line for the credits applied
        // (expandable, but does not affect the count)
        count += 1;
      }
      return count;
    },
    invoiceDetail() {
      return this.invoice.detail;
    },
    status() {
      return this.invoice.status;
    },
    incompleteTaxes() {
      return this.taxProviderConfigured && !this.invoice.detail.billingAddress;
    },
    cycleCredits() {
      return ((this.billingCycle || {}).discounts || [])
        .filter(d => d.typeString === this.Type.CREDIT || d.type === this.Type.CREDIT) || [];
    },
    creditsDetails() {
      const categoriesCredits = (this.invoice.detail.groups || []).flatMap(g => ((g.categories || []).concat(g.prepaidCommitments || []))).flatMap(c => [
        ...(c.products || []).flatMap(p =>
          this.getAdjustments(p, this.Type.CREDIT)
            .map(a => ({ ...a, targetName: p.name, sku: p.sku }))),
        ...this.getAdjustments(c, this.Type.CREDIT).map(a => ({ ...a, targetName: c.name })),
      ]);
      const globalCredits = this.getAdjustments(this.invoice.detail, this.Type.CREDIT);
      return [...categoriesCredits, ...globalCredits];
    },
    creditList() {
      return this.mapToAdjustmentList(this.creditsDetails);
    },
    preTaxInvoiceCredits() {
      return this.invoiceDetail.preTaxAdjustments.filter(t => t.type === this.Type.CREDIT);
    },
    preTaxCreditList() {
      return this.mapToAdjustmentList(this.preTaxInvoiceCredits);
    },
    postTaxDiscountDetails() {
      const categoriesCredits = (this.invoice.detail.groups || []).flatMap(g => g.categories || []).flatMap(c => [
        ...(c.products || []).flatMap(p => this.getAdjustments(p, this.Type.PERCENTAGE)
          .map(a => ({ ...a, targetName: p.name, sku: p.sku }))),
        ...this.getAdjustments(c, this.Type.PERCENTAGE).map(a => ({ ...a, targetName: c.name })),
      ]);
      const globalCredits = this.getAdjustments(this.invoice.detail, this.Type.PERCENTAGE);
      return [...categoriesCredits, ...globalCredits];
    },
    postTaxDiscountList() {
      return this.postTaxDiscountDetails.map(d => ({
        id: d.source.discount.id,
        name: d.source.discount.name,
        itemName: d.targetName,
        scope: d.source.discount.scope,
        sku: d.sku,
        startDate: d.source.startDate,
        endDate: d.source.endDate,
        discount: this.getDiscountPercentage(d.source.discount, d.itemId),
        amount: d.amount,
      }));
    },
    preTaxDiscountDetails() {
      return this.invoiceDetail.preTaxAdjustments.filter(t => t.type === this.Type.PERCENTAGE);
    },
    preTaxDiscountList() {
      return this.preTaxDiscountDetails.map(d => ({
        id: d.source.discount.id,
        name: d.source.discount.name,
        itemName: d.targetName,
        scope: d.source.discount.scope,
        sku: d.sku,
        startDate: d.source.startDate,
        endDate: d.source.endDate,
        discount: `${d.source.discount.packageDiscount}%`,
        amount: d.amount,
      }));
    },
  },
  async created() {
    this.taxProviderConfigured = await this.hasTaxProviderConfigured(this.invoice.organization.id);
  },
  methods: {
    mapToAdjustmentList(adjustments) {
      return adjustments.filter(a => a.amount != 0).map(a => ({
        id: a.source.discount.id,
        name: a.source.discount.name,
        itemId: a.itemId,
        itemName: a.targetName,
        scope: a.source.discount.scope,
        sku: a.sku,
        startDate: this.getStartDate(a.source.discount),
        endDate: this.getEndDate(a.source.discount),
        available: this.getAvailable(a.itemId, a.source),
        amount: a.amount,
        remaining: (this.getAvailable(a.itemId, a.source) || 0) + a.amount,
      }));
    },
    formatCurrency(amount, currency) {
      return this.formatShortLocaleCurrency(amount, currency, navigator.language);
    },
    getAvailable(id, source) {
      const cycleDiscount = this.cycleCredits.find(cd => cd.discountId === source.discount.id);
      const remaining = (cycleDiscount || {}).remaining || {};
      switch (source.scope) {
        case 'PRODUCTS':
          return (remaining.discountedProducts || {})[id];
        case 'CATEGORIES':
          return (remaining.discountedCategories || {})[id];
        case 'ALL_PRODUCTS':
          return remaining.packageDiscount;
        default:
          return undefined;
      }
    },
    getEndDate(discount) {
      const cycleDiscount = this.cycleCredits.find(cd => cd.discountId === discount.id);
      return (cycleDiscount || {}).endDate;
    },
    getStartDate(discount) {
      const cycleDiscount = this.cycleCredits.find(cd => cd.discountId === discount.id);
      return (cycleDiscount || {}).startDate;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/styles/mixins.scss';
  .invoice-details-summary-header {
    @include boldText;
  }
  .invoice-details-summary-body * {
    .list.summary-list {
      margin-top: 15px !important;
    }
    .description {
      margin-bottom: 0px;
    }
  }
  :deep(.list-row) {
    background-color: white;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .bold {
    font-weight: bold;
    font-size: 30px;
  }
  .invoice-details-summary-body .list.summary-list {
    margin-top: 15px !important;
    border-radius: 10px;
  }
  h2 {
    font-weight: normal;
    color: var(--text);
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .col-nowrap {
    flex-direction: row;
  }
  .caret-container {
    position: absolute;
    left: 0px;
  }
  .adjustmentList {
    width: 100%;
  }
</style>
