const ContentSection = () => import(/* webpackChunkName: "system" */ './ContentSection');
const NotificationsList = () => import(/* webpackChunkName: "system" */ './notifications/NotificationsList');
const AddNotification = () => import(/* webpackChunkName: "system" */ './notifications/AddNotification');
const EditNotification = () => import(/* webpackChunkName: "system" */ './notifications/EditNotification');
const CategoriesList = () => import(/* webpackChunkName: "system" */'./categories/CategoriesList');
const AddCategory = () => import(/* webpackChunkName: "system" */ './categories/AddCategory');
const EditCategory = () => import(/* webpackChunkName: "system" */ './categories/EditCategory');

export default [
  {
    path: 'content',
    component: ContentSection,
    meta: { protected: ['reseller:content'] },
    children: [
      {
        path: '',
        redirect: { name: 'notificationsList' },
      },
      {
        name: 'notificationsList',
        path: 'notifications',
        component: NotificationsList,
        meta: {
          title: () => 'content.announcements',
        },
      },
      {
        name: 'categoriesList',
        path: 'categories',
        component: CategoriesList,
      },
      {
        name: 'addNotification',
        path: 'notifications/add',
        component: AddNotification,
        props: { switchOrgRouteName: 'notificationsList' },
        meta: {
          title: () => 'content.announcement.add',
        },
      },
      {
        name: 'addCategory',
        path: 'categories/add',
        component: AddCategory,
        props: { switchOrgRouteName: 'categoriesList' },
        meta: {
          title: () => 'content.category.add',
        },
      },
      {
        name: 'editNotification',
        path: 'notifications/:id/edit',
        component: EditNotification,
        props: { switchOrgRouteName: 'notificationsList' },
        meta: {
          title: () => 'content.announcement.edit',
        },
      },
      {
        name: 'editCategory',
        path: 'categories/:id/edit',
        component: EditCategory,
        props: { switchOrgRouteName: 'categoriesList' },
        meta: {
          title: () => 'content.category.edit',
        },
      },
    ],
  },
];
