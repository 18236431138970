<template>
  <vue-recaptcha 
    v-if="loaded" 
    class="recaptcha-padding" 
    :sitekey="sitekey"
    @verify="onVerify" 
    @expired="onExpired" 
    @error="onError" 
  />
</template>

<script>
import { mapGetters } from 'vuex';
import  { VueRecaptcha } from 'vue-recaptcha';
import apis from '@/utils/apis';
import notify from '@/utils/notify';

export default {
  name: 'TrialRecaptcha',
  components: { VueRecaptcha },
  props: {
    sitekey: {
      type: String,
    },

  },
  emits: ['recaptchaVerified', 'recaptchaError'],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
  },
  mounted() {
    setTimeout(() => { this.loaded = true; }, 500);
  },
  async created() {
    const recaptchaScript = window.document.createElement('script');
    recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?hl=${this.locale}`);
    window.document.head.appendChild(recaptchaScript);
  },
  async unmounted() {
    const scripts = window.document.head.getElementsByTagName('script');
    const scriptsArray = [...scripts];
    scriptsArray.filter(s => s.src.includes('recaptcha')).forEach(s => window.document.head.removeChild(s));
  },
  methods: {
    async onVerify(response) {
      if (response) {
        const resp = await apis.trials.isValidRecaptchaResponse(response);
        if (!resp || resp.status !== 200) {
          notify.error(this.$t('error_recaptcha'));
          return;
        }
        if (!resp.data) {
          notify.error(this.$t('error_recaptcha'));
        }
        this.$emit('recaptchaVerified', resp.data);
      }
    },
    onExpired() {
      this.$emit('recaptchaVerified', false);
    },
    onError() {
      this.$emit('recaptchaError');
    },
  },
};
</script>

<style scoped lang="scss">
  .recaptcha-padding {
    padding-top: 10px;
  }
</style>
