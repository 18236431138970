import store from "@/store";

const CustomFieldsList = () => import(/* webpackChunkName: "system" */ './CustomFieldsList');
const CustomFieldSection = () => import(/* webpackChunkName: "system" */ './CustomFieldsSection');
const AddCustomField = () => import(/* webpackChunkName: "system" */ './AddCustomField');
const EditCustomField = () => import(/* webpackChunkName: "system" */ './EditCustomField');

export default [
  {
    path: 'custom-fields',
    component: CustomFieldSection,
    props: true,
    meta: {
      protected: ['reseller:customFields'],
      title: () => 'custom_fields_name',
    },
    children: [
      {
        path: '',
        redirect: { name: 'customFields', params: { fieldType: 'organization' } },
      },
      {
        name: 'customFields',
        path: ':fieldType',
        component: CustomFieldsList,
        props: true,
      },
      {
        name: 'addCustomField',
        path: ':fieldType/add',
        component: AddCustomField,
        props: true,
        beforeEnter(to, from, next) {
          if (to.params.fieldType === 'service_entity' && store.getters.managedServiceConnections.length === 0) {
            // We prevent access to the add form if the custom field is of type service_entity
            // and the organization has no managed connections
            return next({
              path: to.href.replace(/\/service_entity\/add/, '/service_entity'),
              query: to.query
            });
          }
          return next()
        }
      },
      {
        name: 'editCustomField',
        path: ':fieldType/:id/edit',
        component: EditCustomField,
        props: true,
      },
    ],
  },
];
