import BaseRoute from '@/app/components/BaseRoute';

const ScheduledJobsList = () => import(/* webpackChunkName: "scheduled-jobs" */ './ScheduledJobsList');

export default [
  {
    path: 'scheduled-jobs',
    component: BaseRoute,
    meta: {
      protected: ['root:sys:scheduledJobs'],
      title: () => 'scheduled_jobs_name',
    },
    children: [
      {
        name: 'scheduledJobs',
        path: '',
        component: ScheduledJobsList,
      },
    ],
  },
];
