import LegalSection from './LegalSection.vue';
const LegalDisclaimer = () => import(/* webpackChunkName: "legal" */ './disclaimer/LegalDisclaimer');
const TermsOfServiceList = () => import(/* webpackChunkName: "legal" */ '../legal/terms-of-service/TermsOfServiceList.vue');
const TermsOfServicePage = () => import(/* webpackChunkName: "legal" */ './terms-of-service/TermsOfServicePage');
const AddTermOfService = () => import(/* webpackChunkName: "legal" */ './terms-of-service/AddTermOfService');
const UpdateTermsOfService = () => import(/* webpackChunkName: "legal" */ './terms-of-service/update/UpdateTermsOfService');

export default [
  {
    path: 'legal',
    component: LegalSection,
    meta: {
      protected: ['reseller:legal'],
      title: () => 'legal.name',
    },
    children: [
      {
        path: '',
        redirect: { name: 'legalDisclaimer' },
      },
      {
        name: 'legalDisclaimer',
        path: 'disclaimer',
        component: LegalDisclaimer,
      },
      {
        name: 'terms-of-service',
        path: 'terms-of-service',
        component: TermsOfServiceList,
      },
    ],
  },
  {
    path: 'legal/terms-of-service',
    component: TermsOfServicePage,
    props: true,
    meta: {
      protected: ['reseller:trials'],
    },
    children: [
      {
        name: 'addTermOfService',
        path: 'add',
        component: AddTermOfService,
        meta: {
          changeOrgRedirect: '/admin/legal/terms-of-service',
          title: () => 'terms_of_service.add'
        },
        props: true,
      },
      {
        path: ':id',
        meta: {
          changeOrgRedirect: '/admin/legal/terms-of-service',
        },
        props: route => ({ id: route.params.id }),
        children: [
          {
            name: 'updateTermsOfService',
            path: 'edit',
            component: UpdateTermsOfService,
          },
        ],
      },
    ]
  }
];
