import ActionDropdown from './ActionDropdown';
import ActionIcon from './ActionIcon';
import ActionText from './ActionText';
import AdvancedDetails from './AdvancedDetails';
import AlertBox from './AlertBox';
import AppLink from './AppLink';
import AppLogo from './AppLogo';
import AutocompleteInput from './input/AutocompleteInput';
import BarChart from './echart/BarChart';
import BaseAttributes from './BaseAttributes';
import BaseAvatar from './BaseAvatar';
import BaseBadge from './BaseBadge';
import BaseBreadcrumb from './BaseBreadcrumb';
import BaseButton from './BaseButton';
import BaseCalendar from './calendar/BaseCalendar';
import BaseCheckbox from './BaseCheckbox';
import BaseCheckboxes from './BaseCheckboxes';
import BaseColumnLayout from './input/BaseColumnLayout';
import BaseDashboard from './dashboard/BaseDashboard';
import BaseDetails from './BaseDetails';
import BaseDropdown from './dropdown/BaseDropdown';
import BaseForm from './form/BaseForm';
import BaseIcon from './BaseIcon';
import BaseIconPicker from './BaseIconPicker';
import BaseImage from './BaseImage';
import BaseIndicator from './BaseIndicator';
import BaseInput from './input/BaseInput';
import BaseItem from './dropdown/BaseItem';
import BaseList from './list/BaseList';
import BaseLoader from './BaseLoader';
import BaseMap from './map/BaseMap';
import BaseMenuPage from './BaseMenuPage';
import BaseMessage from './BaseMessage';
import BaseModal from './BaseModal';
import BaseMultiSelect from './BaseMultiSelect.vue';
import BaseNavigator from './BaseNavigator';
import BasePanel from './panel/BasePanel';
import BaseSelect from './BaseSelect';
import BaseSlider from './BaseSlider';
import BaseStateBadge from './BaseStateBadge';
import BaseTabs from './BaseTabs';
import BaseTag from './BaseTag';
import BaseTags from './BaseTags';
import BaseTile from './BaseTile';
import BaseTimePicker from './calendar/BaseTimePicker';
import BaseToggle from './BaseToggle';
import BaseToolTip from './BaseTooltip';
import BrandingListener from './BrandingListener';
import ChartIntervalSelector from './echart/ChartIntervalSelector';
import Chart from './echart/Chart';
import CheckboxDropdown from './dropdown/CheckboxDropdown';
import ChoosePassword from './ChoosePassword';
import CodeEditor from './CodeEditor';
import CollapsibleSection from './CollapsibleSection';
import ColumnText from './list/ColumnText';
import CompositeFormElement from './form/CompositeFormElement';
import ConfirmModal from './ConfirmModal';
import CopyableField from './CopyableField';
import DashboardSection from './dashboard/DashboardSection';
import DateInput from './input/DateInput';
import EmptyMessage from './EmptyMessage';
import ErrorMessage from './ErrorMessage';
import ExpandableComposite from './form/ExpandableComposite';
import FeatureListener from './FeatureListener';
import FilterTabs from './filters/FilterTabs';
import FixedElement from './FixedElement';
import FormElement from './form/FormElement';
import FormPage from './form/FormPage';
import FormRow from './form/FormRow';
import GaugeChart from './echart/GaugeChart';
import GoogleTagManager from './GoogleTagManager';
import I18nToggle from './I18nToggle';
import IDPLoginButton from './login/IDPLoginButton';
import InfiniteScroll from './InfiniteScroll';
import ItemsDropdown from './dropdown/ItemsDropdown';
import LanguageIndicator from './LanguageIndicator';
import LineChart from './echart/LineChart';
import ListColumn from './list/ListColumn';
import ListHeader from './list/ListHeader';
import ListRow from './list/ListRow';
import LongString from './LongString';
import MapMarker from './map/MapMarker';
import MarkdownEditor from './MarkdownEditor';
import MarkdownViewer from './MarkdownViewer';
import MobileStatus from './list/MobileStatus';
import MultiCriteriaFilter from './filters/MultiCriteriaFilter';
import MultiSelectInput from './input/MultiSelectInput';
import NoResultsMessage from './NoResultsMessage';
import OIDCLoginButton from './login/OIDCLoginButton';
import OptionTabs from './OptionTabs';
import PanelItem from './panel/PanelItem';
import PasswordStrength from './PasswordStrength';
import PieChart from './echart/PieChart';
import QRCode from './QRCode';
import RadioButton from './RadioButton';
import RadioGroup from './RadioGroup';
import RepeatableFormElement from './form/RepeatableFormElement';
import ResourceCard from './ResourceCard';
import SectionHeader from './SectionHeader';
import SelectableBox from './SelectableBox';
import ServiceLabel from './ServiceLabel';
import SparklineChart from './echart/SparklineChart';
import SvgIcon from './SvgIcon.vue';
import TabbedComposite from './form/TabbedComposite';
import TagSelect from './TagSelect';
import TextArea from './BaseTextArea';
import TileSelect from './TileSelect';
import ToggleButton from './ToggleButton';
import TransitionRoute from './TransitionRoute';
import TreeNode from './TreeNode';
import TreeSelect from './TreeSelect';
import UIValue from './UIValue';
import UploadLogo from './UploadLogo';
import ViewFilters from './filters/ViewFilters';

export default {
  install(app) {
    [
      ActionDropdown,
      ActionIcon,
      ActionText,
      AdvancedDetails,
      AlertBox,
      AppLink,
      AppLogo,
      AutocompleteInput,
      BarChart,
      BaseAttributes,
      BaseAvatar,
      BaseBadge,
      BaseBreadcrumb,
      BaseButton,
      BaseCalendar,
      BaseCheckbox,
      BaseCheckboxes,
      BaseColumnLayout,
      BaseDashboard,
      BaseDetails,
      BaseDropdown,
      BaseForm,
      BaseIcon,
      BaseIconPicker,
      BaseImage,
      BaseIndicator,
      BaseInput,
      BaseItem,
      BaseList,
      BaseLoader,
      BaseMap,
      BaseMenuPage,
      BaseMessage,
      BaseModal,
      BaseMultiSelect,
      BaseNavigator,
      BasePanel,
      BaseSelect,
      BaseSlider,
      BaseStateBadge,
      BaseTabs,
      BaseTag,
      BaseTags,
      BaseTile,
      BaseTimePicker,
      BaseToggle,
      BaseToolTip,
      BrandingListener,
      ChartIntervalSelector,
      CheckboxDropdown,
      ChoosePassword,
      CodeEditor,
      CollapsibleSection,
      ColumnText,
      CompositeFormElement,
      ConfirmModal,
      CopyableField,
      DashboardSection,
      DateInput,
      EmptyMessage,
      ErrorMessage,
      ExpandableComposite,
      FeatureListener,
      FilterTabs,
      FixedElement,
      FormElement,
      FormPage,
      FormRow,
      GaugeChart,
      GoogleTagManager,
      I18nToggle,
      IDPLoginButton,
      InfiniteScroll,
      ItemsDropdown,
      LanguageIndicator,
      LineChart,
      ListColumn,
      ListHeader,
      ListRow,
      LongString,
      MapMarker,
      MarkdownEditor,
      MarkdownViewer,
      MobileStatus,
      MultiCriteriaFilter,
      MultiSelectInput,
      NoResultsMessage,
      OIDCLoginButton,
      OptionTabs,
      PanelItem,
      PasswordStrength,
      PieChart,
      Chart,
      QRCode,
      RadioButton,
      RadioGroup,
      RepeatableFormElement,
      ResourceCard,
      SectionHeader,
      SelectableBox,
      ServiceLabel,
      SparklineChart,
      SvgIcon,
      TabbedComposite,
      TagSelect,
      TextArea,
      TileSelect,
      ToggleButton,
      TransitionRoute,
      TreeNode,
      TreeSelect,
      UIValue,
      UploadLogo,
      ViewFilters,
    ].forEach(component => app.component(component.name || component.__name, component));
  },
};
