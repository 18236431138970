<template>
  <div>
    <base-form 
      :fullWidth="true"
      :hideCancel="hideCancel" 
      :hideSubmit="hideSubmit"
      submitLabel="continue"
      cancelLabel="cancel_two_factor_authentication"
      @submit="twoFactorLogin(true)" 
      @cancel="goBack"
    >
      <div>
        {{ $t(isBackupCode?'enter_code_from_backup':'enter_code_from_phone') }}
      </div>
      <div class="input-container">
        <base-input 
          v-model="verificationCode" 
          type="text" 
          name="2fa" 
          :error="errors.verificationCode" 
          :placeholder="isBackupCode ? 'backup_code_placeholder' : 'two_factor_code_placeholder'" 
          icon="fa fa-key" 
          size="lg" 
          :rounded="true"
        />
        <a 
          class="bottom-link" 
          href="#" 
          @click="switchToBackupCode"
        >{{ $t(isBackupCode?'use_code_from_phone':'use_backup_codes') }}</a>
      </div>
    </base-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';

export default {
  name: 'TwoFactorAuthenticationForm',
  components: { },
  props: {
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancelTwoFactorAuthentication'],
  data() {
    return {
      verificationCode: '',
      isBackupCode: false,
      errors: {
        verificationCode: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    samlEnabled() {
      return !!this.$route.query.SAMLRequest;
    },
  },
  methods: {
    ...mapActions([
      'resetAuthentication',
    ]),
    validate() {
      if (this.verificationCode === '') {
        notify.error(this.$t('missing_two_factor_verification_code'));
        this.errors.verificationCode = true;
        return false;
      }
      return true;
    },
    switchToBackupCode() {
      this.isBackupCode = !this.isBackupCode;
    },
    goBack() {
      this.verificationCode = undefined;
      this.resetAuthentication();
      this.$emit('cancelTwoFactorAuthentication');
    },
    async twoFactorLogin(fromSessionTimeout = false) {
      notify.destroy();
      if (!this.validate()) {
        return;
      }
      let destination = '/home';
      if (this.samlEnabled) {
        destination = this.$route.fullPath.split('/login')[1] || '';
      } else if (this.$route.query.path) {
        destination = this.$route.query.path.replace('//', '/');
      } else if (fromSessionTimeout) {
        destination = this.$route.fullPath ? this.$route.fullPath.replace('//', '/') : '';
      }
      destination = destination.replace('twoFa=true', '');
      const res = await apis.auth.twoFactorAuthentication(
        this.verificationCode, this.isBackupCode,
      );
      if (res.ok) {
        if (!fromSessionTimeout) {
          localStorage.setItem('localeBeforeLogin', this.locale);
        }
        window.location.assign(destination);
      } else {
        this.errors.verificationCode = true;
        notify.error(this.$t('wrong_two_factor_verification_code'));
      }
    },
  },
};
</script>


<style scoped lang="scss">
.input-container {
    margin-top: 5px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.input-container .input-control {
   flex: 1;
}

.bottom-link {
  margin-top: 12px;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
}
</style>
