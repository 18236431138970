<template>
  <div>
    <base-menu-page
      :searchEnabled="searchEnabled"
      :header="header"
      :operations="operations"
      :sections="sections"
      :displayAllChildren="true"
      @search="search = $event"
    >
      <router-view :key="($route.query || {}).org"></router-view>
    </base-menu-page>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AuthenticationSection',
  data() {
    return {
      search: '',
    };
  },
  computed: {
    searchEnabled() {
      return this.$route.name === 'providers-list' ||
          this.$route.name === 'service-providers';
    },
    header() {
      return {
        logo: 'id-badge',
        subtitle: this.$t('authentication.desc'),
        title: this.$t('authentication.title'),
      };
    },
    sections() {
      return [
        {
          title: this.$t('authentication.external_sso'),
          name: 'external-sso',
          to: {
            path: '/system/authentication/external-sso',
            name: 'providers-list',
          },
          children: [
            {
              name: 'providers-list',
              label: this.$t('authentication.identity_providers'),
              to: {
                path: '/system/authentication/external-sso',
                name: 'providers-list',
              },
            },
          ],
        },
        {
          title: this.$t('authentication.acting_sso'),
          name: 'as-sso',
          to: {
            path: '/system/authentication/as-sso/service-providers',
            name: 'service-providers',
          },
          children: [
            {
              name: 'service-providers',
              label: this.$t('service_providers.title'),
              to: {
                path: '/system/authentication/as-sso/service-providers',
                name: 'service-providers',
              },
            },
            {
              name: 'saml-settings',
              label: this.$t('saml_settings.title'),
              to: {
                path: '/system/authentication/as-sso/saml-settings',
                name: 'saml-settings',
              },
            },
          ],
        },
      ];
    },
    operations() {
      return [
        {
          route: 'providers-list',
          href: '/system/authentication/external-sso/create',
          action: () => this.$router.push({ name: 'create-provider' }),
          label: 'authentication.create',
          icon: 'fa fa-plus-circle',
        },
        {
          route: 'service-providers',
          href: '/system/authentication/as-sso/service-providers/create',
          action: () => this.$router.push({ name: 'create-service-provider' }),
          label: 'service_providers.create',
          icon: 'fa fa-plus-circle',
        },
      ].filter(o => this.$route.name === o.route);
    },
  },
};
</script>
