import { CrudApi } from './api';

class UsageGenerationApi extends CrudApi {
    constructor() {
        super('usage_generations');
    }

    download(usageGenerationId) {
        return this.get(`${this.path}/${usageGenerationId}/download`, { responseType: 'blob' });
    }

    createFromDto(dto) {
      return this.post(`/usage_generations/dto`, { body: dto });
    }
}

const usageGenerations = new UsageGenerationApi();
export default usageGenerations;
