import { mapGetters } from 'vuex';

export const pricingUnitFormatterMixin = {
  computed: {
    ...mapGetters(['locale']),
  },
  methods: {
    formatProductUnit(product, cur) {
      const unitTranslated = this.productUnitLabel(product);
      if (product.period) {
        const periodTranslated = this.$t(
          `period.${product.period.toLowerCase()}`,
        );
        const unitWithPeriod = unitTranslated.concat('-', periodTranslated);
        return cur ? cur.concat(' / ', unitWithPeriod) : unitWithPeriod;
      }
      return cur ? cur.concat(' / ', unitTranslated) : unitTranslated;
    },
    productUnitLabel(product) {
      return (product.unit || {}).unit
        ? this.$t(`units.${product.unit.unit.toLowerCase()}`)
        : product.unit.name[this.locale];
    },
  },
};
