import BaseRoute from '@/app/components/BaseRoute';

const FeaturesList = () => import(/* webpackChunkName: "features" */ './FeaturesList');

export default [
  {
    path: 'features',
    component: BaseRoute,
    meta: {
      protected: ['global:sys:internal'],
      title: () => 'features_name',
    },
    children: [
      {
        name: 'features',
        path: '',
        component: FeaturesList,
      },
    ],
  },
];
