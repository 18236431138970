import apis from '@/utils/apis';

const SET_USER = 'SET_USER';

const initialState = {
  user: null,
};

const getters = {
  user: state => state.user,
};

const mutations = {
  [SET_USER](state, user) {
    state.user = user;
  },
};

const actions = {
  async loadUser({ commit }, id) {
    const user = await apis.users.find(id);
    if (user.data) {
      commit(SET_USER, user.data);
    }
    return user;
  },
};

export default {
  state: initialState,
  mutations,
  getters,
  actions,
};
