<template>
  <BaseLoader v-if="loading" />
  <div 
    v-else
    class="registration-box"
  >
    <InvalidRegistrationTokenMessage v-if="!isValidToken" />
    <EnterpriseRegistrationForm v-else />
  </div>
</template>

<script>

import BaseLoader from '@/components/BaseLoader.vue';
import apis from '@/utils/apis';
import EnterpriseRegistrationForm from './EnterpriseRegistrationForm.vue';
import InvalidRegistrationTokenMessage from './InvalidRegistrationTokenMessage.vue';

export default {
    name: 'EnterpriseRegistrationBox',
    components: { EnterpriseRegistrationForm, InvalidRegistrationTokenMessage, BaseLoader },
    emits: ['isValidToken'],
    data() {
        return {
            isValidToken: false,
            loading: false,
        }
    },
    computed: {
        registrationToken() {
            return this.$route.query.registrationToken;
        }
    },
    async created() {
        this.loading = true;
        if (!this.registrationToken) {
            this.isValidToken = false;
        } else {
            const resp = await this.validateToken();
            if (!resp || !resp.ok) {
                this.isValidToken = false;
            } else {
                this.isValidToken = resp.data;
            }
        }
        this.$emit('isValidToken', this.isValidToken);
        this.loading = false;

    },
    methods: {
        async validateToken() {
            return await apis.enterpriseRegistrations.validateRegistrationToken(this.registrationToken);
        }
    }

}

</script>

<style scoped lang="scss">
.registration-box {
    position: relative;
    min-height: 200px;
    width: 700px;
    margin: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: var(--background);
}
</style>