<template>
  <advanced-details titleLabel="authentication.identity_providers">
    <base-loader v-if="loading"></base-loader>
    <div v-else>
      <base-list 
        aria-labelledby="authentication.identity_providers" 
        ariaDescription="authentication.identity_providers_desc" 
        :ariaRowCount="filteredIdentityProviders.length"
      >
        <list-header>
          <list-column :size="2">
            {{ $t('authentication.display_name') }}
          </list-column>
          <list-column :size="1">
            {{ $t('authentication.type') }}
          </list-column>
          <list-column :size="1">
            {{ $t('authentication.associated_users') }}
          </list-column>
        </list-header>
        <list-row 
          v-for="provider in filteredIdentityProviders"
          :key="provider.id" 
        >
          <list-column :size="2">
            <div class="name-plus-logo">
              <base-image 
                class="logo" 
                :src="provider.logo" 
                :altText="provider.displayName"
              />
              <span class="display-name">{{ provider.displayName }}</span>
            </div>
          </list-column>
          <list-column :size="1">
            <div>{{ provider.type }}</div>
          </list-column>
          <list-column :size="1">
            <div>{{ provider.identityProviderUsers.length }}</div>
          </list-column>
          <template #actions>
            <action-icon 
              icon="fa fa-edit" 
              :to="{name:'edit-provider', params: {id: provider.id}}"
            />
            <action-dropdown
              :items="actions"
              @select="confirmAction(provider.id, $event)"
              @close="$emit('close')"
            />
            <div v-tooltip.top="{ content: $t('copied'), shown: provider.id === copiedIdp, triggers: ['manual']}" />
          </template>
        </list-row>
      </base-list>
      <no-results-message v-if="filteredIdentityProviders.length === 0 && !loading && search" />
      <empty-message
        v-else-if="filteredIdentityProviders.length === 0 && !loading"
        icon="fa fa-cube"
        textLabel="authentication.no_providers"
      ></empty-message>
      <confirm-modal
        headerIcon="fa fa-check-circle"
        :open="openConfirmDialog"
        :headerLabel="actionContext.title"
        :headerLabelInterpolation="actionContext.interpolation"
        :detailsLabel="$tc('authentication.delete_idp_n_users', actionContext.interpolation.numUsers, actionContext.interpolation)"
        :confirmLabel="actionContext.submitLabel"
        @ok="deleteProvider"
        @cancel="close"
      ></confirm-modal>
    </div>
  </advanced-details>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { searchFilter } from '@/utils';
import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';

import { mapGetters } from 'vuex';

export default {
  name: 'IdentityProviderList',
  components: {},
  mixins: [resellerOrgNavigationMixin],
  emits: ['close'],
  data() {
    return {
      timeout: '',
      copiedIdp: null,
      identityProviders: [],
      loading: false,
      openConfirmDialog: false,
      actionContext: {
        id: '',
        interpolation: { name: '', numUsers: '' },
        title: 'authentication.delete_title',
        message: 'authentication.delete_message',
        submitLabel: 'confirm',
      },
    };
  },
  computed: {
    ...mapGetters(['globalProperties', 'selectedOrganization']),
    search() {
      return this.getParameterValue(this.$route.query.q);
    },
    filteredIdentityProviders() {
      return this.identityProviders.filter(
        searchFilter(this.search, ip => [ip.displayName, ip.type]),
      );
    },
    actions() {
      return [
        {
          value: 'MANAGER_USER_CREATE',
          icon: 'fa fa-user-plus',
          label: 'authentication.new_user_config.manage_user_create',
        },
        {
          value: 'COPY',
          icon: 'fa fa-clipboard',
          label: 'authentication.copy_callback_url',
        },
        {
          value: 'DELETE',
          icon: 'fa fa-trash',
          label: 'authentication.delete_idp',
        },
      ];
    },
  },
  watch: {
    selectedOrganization() {
      this.fetchProviders();
    },
  },
  async created() {
    await this.fetchProviders();
  },
  methods: {
    async fetchProviders() {
      this.loading = true;
      const providers = await apis.identityProviders.list({
        qs: {
          organization_id: this.selectedOrganization.id,
        },
      });
      if (providers.data) {
        this.identityProviders = providers.data;
      } else {
        notify.error(this.$t('unexpected_error'));
        this.$router.push({ path: '/home' });
      }
      this.loading = false;
    },
    confirmAction(idp, action) {
      switch (action) {
        case 'COPY':
          this.copyCallbackURL(idp);
          break;
        case 'DELETE':
          this.removeProvider(idp);
          break;
        case 'MANAGER_USER_CREATE':
          this.$router.push({name: 'provider-user-config', params: {id : idp}})
          break;
        default:
          break;
      }
    },
    getParameterValue(param) {
      if (Array.isArray(param)) {
        return param[0];
      }
      return param;
    },
    async deleteProvider() {
      const idpName = this.actionContext.interpolation.name;
      const response = await apis.identityProviders.remove(
        this.actionContext.id,
        {},
      );
      if (!response) {
        notify.error(this.$t('unexpected_error'));
      } else if (response.status === 400 && response.errors) {
        response.errors.forEach(err => notify.error(this.$t(err.message)));
      } else if (response.status !== 200) {
        notify.error(
          this.$t('authentication.error_codes.delete_api_error', idpName),
        );
      } else {
        notify.success(this.$t('authentication.deleted', { name: idpName }));
        this.fetchProviders();
      }
      this.close();
    },
    buildCallbackUrl(providerId) {
      const idp = this.identityProviders.find(i => i.id === providerId);
      const host = (idp.organization.customDomain || {}).domain || 'localhost';
      const portProperty = this.globalProperties['public.port'];
      const port = (portProperty && portProperty !== '80' && portProperty !== '443')
        ? `:${portProperty}`
        : '';
      return `${this.globalProperties.protocol}://${host}${port}/rest/auth/oidc/${providerId}/callback`;
    },
    removeProvider(idpId) {
      const idProvider = this.identityProviders.find(idp => idp.id === idpId);
      const idpName = idProvider ? idProvider.displayName : null;
      this.actionContext.id = idpId;
      this.actionContext.interpolation.name = idpName;
      this.actionContext.interpolation.numUsers =
        idProvider.identityProviderUsers.length;
      this.openConfirmDialog = true;
    },
    copyCallbackURL(idpId) {
      navigator.clipboard.writeText(this.buildCallbackUrl(idpId));
      this.copiedIdp = idpId;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.copiedIdp = null;
        this.timeout = null;
      }, 1000);
    },
    close() {
      this.actionContext.id = null;
      this.actionContext.interpolation.name = null;
      this.openConfirmDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  max-height: 30px;
}

.name-plus-logo {
  display: flex;
  align-items: center;
}

.display-name {
  padding-left: 10px;
}
</style>
