import { ref } from 'vue'

export namespace Direction {
  export const ASCENDING = false
  export const DESCENDING = true
}
export type DirectionType = (typeof Direction)[keyof typeof Direction];

export interface Sortable {
  /**
   * Whether this column should be the default-sorted column in this list, a list can have no default-sorted columns
   */
  default: boolean

  /**
   * Sort order, the initial direction to offer sorting
   */
  sortOrder: DirectionType

  /**
   * Comparator function
   */
  comparator: (a: any, b: any) => number

  /**
   * Unique column identifier, used to notify other columns which column called the sort
   */
  columnId: string

  /**
   * Unique table identifier, used to prevent columns from different tables talking to each other
   */
  tableId: string
}

/**
 * Whatever field this list was last sorted by
 */
export const lastSortedBy = ref <Sortable | undefined>(undefined)

/**
 * Sort a list of data with a Sortable object within front end memory
 * @param data List of data to sort
 * @param sortable Sorting instructions using the Sortable interface
 */
export function sortInMemory(data: any[], sortable: Sortable) {
  data.sort(sortable.comparator)
  if (sortable.sortOrder == Direction.DESCENDING) {
    data.reverse()
  }
  
  lastSortedBy.value = sortable
}