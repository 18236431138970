<template>
  <div 
    class="avatar" 
    :class="{ loading: loading && user, disabled }" 
    :title="tooltip"
  >
    <div>
      <div 
        v-if="!user" 
        class="default default-primary" 
        :style="getStyleAttributes"
      >
        <base-icon :icon="icon || 'fa fa-user'" />
      </div>
      <template v-else-if="!loading">
        <base-image 
          v-if="!showDefault" 
          :src="avatarUrl" 
          :width="size" 
          :height="size" 
          :altText="altText"
        />
        <div 
          v-else 
          class="default" 
          :class="`default-color-${colorCode}`" 
          :style="getStyleAttributes"
        >
          {{ initials }}
        </div>
      </template>
      <div 
        v-if="disclaimer" 
        class="disclaimer"
      >
        {{ $t(showDefault ? 'default_avatar' : 'source_gravatar') }}
      </div>
    </div>
  </div>
</template>

<script>
import { gravatarUrl } from '@/utils';
import { NUM_DEFAULT_COLORS } from '@/config';

const MAX_RATIO_SIZE_TO_DIM = 5;

export default {
  name: 'BaseAvatar',
  props: {
    user: {
      type: Object,
    },
    size: {
      type: Number,
      required: true,
    },
    disclaimer: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['loaded'],
  data() {
    return {
      showDefault: false,
      loading: true,
    };
  },
  computed: {
    firstName() {
      return this.user.firstName;
    },
    lastName() {
      return this.user.lastName;
    },
    email() {
      return this.user.email || '';
    },
    altText() {
      return (this.user.email || this.user.username) || '';
    },
    avatarUrl() {
      return gravatarUrl(this.email, 2 * this.size);
    },
    initials() {
      const firstChar = s => s.charAt(0).toUpperCase();
      return `${firstChar(this.firstName)}${firstChar(this.lastName)}`;
    },
    colorCode() {
      const codeStr = `${this.email}:${this.firstName} ${this.lastName}`;
      return codeStr.split('').map(c => c.charCodeAt(0)).reduce((acc, c) => acc + c) % NUM_DEFAULT_COLORS;
    },
    getStyleAttributes() {
      return { width: `${this.size}px`, height: `${this.size}px`, 'font-size': `${Math.floor(this.size / 2)}px` };
    },
  },
  async created() {
    if (this.user) {
      const resp = await fetch(this.avatarUrl);
      const blob = await resp.blob();
      this.showDefault = blob.size / this.size <= MAX_RATIO_SIZE_TO_DIM;
    }
    this.loading = false;
    this.$emit('loaded');
  },
};
</script>


<style scoped lang="scss">
.avatar {
  border-radius: 50%;
  opacity: 1;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
  &.disabled {
    filter: grayscale(100%);
  }
  &.loading {
    opacity: 0;
  }

  img {
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
  }

  .default {
    display: flex;
    color: white;
    border-radius: 50%;
    font-family: Helvetica, sans-serif;
    align-items: center;
    justify-content: center;
    transition: font-size 0.5s, width 0.5s, height 0.5s;
  }

  .disclaimer {
    position: absolute;
    bottom: -15px;
    font-size: 9px;
    color: #949494;
    right: 0px;
  }
}
</style>
