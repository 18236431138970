import Api from './api';

class BillableApi extends Api {
  getBillableOrgInfo(orgId) {
    return this.get(`/organizations/${orgId}/billable_info`);
  }

  getBillingCycles(orgId, allState) {
    return this.get(`/organizations/${orgId}/billing_cycles`, { qs: { allState } });
  }

  getBillingCycle(orgId, id) {
    return this.get(`/organizations/${orgId}/billing_cycles/${id}`);
  }

  getChildrenBillingCycles(orgId, qs) {
    return this.get(`/organizations/${orgId}/children/billing_cycles`, { qs });
  }

  updateBillableOrgInfo(orgId, body) {
    return this.put(`/organizations/${orgId}/billable_info`, { body });
  }

  getSubOrganizationCurrencies(orgId) {
    return this.get(`/organizations/${orgId}/sub_organizations_currencies`);
  }

}

export default new BillableApi();
