<template>
  <div class="input-control">
    <div class="input">
      <textarea 
        ref="textArea" 
        :maxlength="maxCharacters" 
        :style="{ 'max-height': `${maxHeight}px`, 'min-height': `${minHeight}px`, 'resize': `${resize}` }" 
        :disabled="disabled" 
        :rows="rows" 
        :value="modelValue" 
        :placeholder="placeholder ? $t(placeholder) : ''" 
        :class="{ error, 'has-value': modelValue, 'disabled': disabled }"
        @focus="onFocus" 
        @input="change" 
      />
      <div 
        v-if="maxCharacters" 
        class="maxCharacters"
      >
        {{ $t('characters_remaining', { count: remainingCharacters }) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
    },
    rows: {
      type: Number,
      default: 8,
    },
    error: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
    },
    maxHeight: {
      type: Number,
      default: 100,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    maxCharacters: {
      type: Number,
    },
    resize: {
      type: String,
      validator: r => ['vertical', 'horizontal', 'none', 'both'].includes(r),
      default: 'both',
    },
  },
  emits: ['update:modelValue', 'focus'],
  computed: {
    remainingCharacters() {
      return this.maxCharacters - (this.modelValue?.length || 0);
    }
  },
  methods: {
    change(e) {
      this.$emit('update:modelValue', e.target.value);
    },
    onFocus() {
      this.$emit('focus');
    },
    focus() {
      setTimeout(() => {
        this.$refs.textArea.focus();
      }, 0);
    },
  },
};
</script>


<style scoped lang="scss">
textarea {
  padding: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  width: calc(100% - 20px);
  transition: border-color 1s;
  min-height: 18px;
}
</style>
