<template>
  <l-marker 
    :key="name"
    ref="marker"
    if="ready"
    :lat-lng="location"
    :icon="iconContainerHTML"
    :alt="altText"
    :onpopupopen="focusChild"
    :onpopupclose="focusSelf"
  >
    <l-popup 
      v-if="usePopup" 
      ref="popup"
      :options="{ minWidth: '0px' }" 
    >
      <slot></slot>
    </l-popup>
    <l-tooltip 
      v-else-if="useTooltip" 
      :options="{ offset: tooltipOffset }"
    >
      <slot name="tooltip"></slot>
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LMarker, LPopup, LTooltip } from '@vue-leaflet/vue-leaflet';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_ICON = 'fa fa-map-marker';

export default {
  name: 'MapMarker',
  components: { LMarker, LPopup, LTooltip },
  props: {
    name: {
      type: String,
      required: true,
    },
    location: {
      type: Array,
      required: true,
    },
    markIndex: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      default: DEFAULT_ICON,
    },
    iconColor: {
      type: String,
    },
    state: {
      type: Object,
    },
    stickyMarkerLabel: {
      type: Boolean,
    },
    noClick: {
      type: Boolean,
    },
    useTooltip: {
      type: Boolean,
      default: false,
    },
    altText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconContainerHTML: undefined,
      tooltipOffset: undefined,
      observer: null,
      labelId: `marker-label-id-${uuidv4()}`,
      ready: false,
    };
  },
  computed: {
    stateColor() {
      return (this.state || {}).color;
    },
    usePopup() {
      return !this.noClick && !this.useTooltip;
    },
    animated() {
      return (this.state || {}).animated;
    },
    iconHTML() {
      if (this.icon.startsWith('http') || this.icon.includes('/')) {
        return `<img class="map-marker-icon" src="${this.icon}" alt="${this.altText}"/>`;
      }
      return `<em class="fa map-marker-icon ${this.icon || DEFAULT_ICON} ${(this.iconColor || '')}" aria-labelledby="${this.labelId}" />`;
    },

  },
  watch: {
    stickyMarkerLabel(){
      this.updateTooltipOffset();
    },
    icon(){
      this.updateIconContainerHTML();
    },
    labelId(){
      this.updateIconContainerHTML();
    },
    name(){
      this.updateIconContainerHTML();
    },
    state(){
      this.updateIconContainerHTML();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.updateTooltipOffset();
      await this.updateIconContainerHTML();
      this.ready=true;
    });
  },
  methods: {
    async updateIconContainerHTML() {
      const { divIcon } = await import('leaflet/dist/leaflet-src.esm');

      const useTooltip = this.useTooltip;
      this.iconContainerHTML = !this.stickyMarkerLabel ? divIcon({
        html: `
          <div class="map-marker light">
            ${this.iconHTML}
            ${useTooltip ? '' : `<div class="map-marker-label" id="${this.labelId}">${this.name}</div>`}
          </div>`,
        className: 'light-map-marker',
        // Forcing div of the icon to be large enough for the selection
        // of the outline
        iconSize: [20, 35],
        iconAnchor: [10, 30],
        popupAnchor: [1, -30],
      }) : divIcon({
        html: `${useTooltip ? `<div class="map-marker map-marker-no-text"><em class="fa fa-circle ${this.stateColor} map-marker-state map-marker-no-text"></em></div>` :
          `<div class="map-marker ${this.stateColor}-border ${this.animated ? 'animated' : ''}">
            ${this.state ? `<em class="fa fa-circle ${this.stateColor} map-marker-state"></em>` : ''}
            ${this.iconHTML}<div class="map-marker-label" id="${this.labelId}">${this.name}</div>`}</div>`,
        className: 'myDivIcon',
        iconSize: 'auto',
        popupAnchor: [15, 0],
      });
    },
    async updateTooltipOffset() {
      const { point } = await import('leaflet/dist/leaflet-src.esm');
      this.tooltipOffset = this.stickyMarkerLabel ? point(0, 0) : point(12, -12);
    },
    focusChild() {
      let childElement = this.usePopup ? (this.$refs.popup || {}).$el.querySelector('[tabindex="0"], a:not([tabindex="-1"])') : null;

      if (!childElement) {
        // Need to get 2 level up because of the structure
        childElement = this.$refs.marker.$parent.$parent
          .$el.querySelector('a.leaflet-popup-close-button:not([display="hidden"])');
      }
      if (childElement) {
        childElement.focus();
      }
    },
    focusSelf() {
      // Needs to get to the parent to get the list of markers
      const listMarks = this.$refs.marker.$parent.$parent
        .$el.querySelectorAll('div.leaflet-marker-icon');
      if (listMarks && listMarks[this.markIndex]) {
        listMarks[this.markIndex].focus();
      }
    },
  },
  
};
</script>
