import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { LIST_SIMPLE_DATE_FORMAT, format } from '@/utils/dates';

export const pricingMixin = {
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
  },
  methods: {
    findMetricType(product, activeCatalogs, sources) {
      let metricType = '';
      activeCatalogs.forEach((catalog) => {
        if (catalog.products.some(p => p.id === product.id)) {
          const source = sources[catalog.serviceType].find(s =>
            s.id === product.source);
          if (source) metricType = source.metricType;
        }
      });
      return metricType;
    },
    async fetchEffectivePricingForDate(date, pricingPackage, navigateBackView) {
      const effectivePricingId = pricingPackage.pricingDefinition.id;
      const resp = await apis.pricings.getEffectivePricing(effectivePricingId,
        date ? format(date, LIST_SIMPLE_DATE_FORMAT, true) : null);

      if (resp && resp.status !== 200) {
        notify.error(this.$t('monetization.discounts.errors.pricing_info'));
        this.$router.navigateBackOrDefault({ name: navigateBackView });
        return {};
      }
      return resp.data;
    },
    openModal(activeModals, index) {
      activeModals[index] = true;
    },
    closeModal(activeModals, index) {
      activeModals[index] = false;
    },
    /**
     * Returns the supported currencies setup in the billing settings of the selected org (reseller!).
     * @returns {Promise<*>}
     */
    async fetchBillingSupportedCurrencies() {
      const resp = await apis.billingSettings.search(this.selectedOrganization.id);
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('billing_settings.error_fetch_settings'));
        return;
      }
      return resp.data.supportedCurrencies;
    },
    /**
     * Returns a grouping function that can be used for TagSelect. Currencies will be grouped
     * between from a reseller supported currencies (billing settings) and unsupported ones.
     *
     * @param billingSupportedCurrencies A list of supported billing currencies.
     * @returns {function(*): {}}
     */
    buildCurrencyGroupingFn(billingSupportedCurrencies) {
      const groupSupported = this.$t('pricing.setup.billing_settings.supported_currencies');
      const groupUnsupported = this.$t('pricing.setup.billing_settings.unsupported_currencies');

      return (currencies) => {
        const currGroup = currencies
          .reduce((acc, cur) => {
            const groupName = (billingSupportedCurrencies || []).includes(cur.value)
              ? groupSupported
              : groupUnsupported

            if (acc[groupName] == undefined) {
              acc[groupName] = [cur]
            } else {
              acc[groupName].push(cur);
            }

            return acc;
          }, {})

        // Supported should always be the first group.
        return {
          ...(currGroup[groupSupported] ? {[groupSupported]: currGroup[groupSupported]} : {}),
          ...(currGroup[groupUnsupported] ? {[groupUnsupported]: currGroup[groupUnsupported]} : {})
        }
      }
    },
  },
};
