import { CrudApi } from './api';

class DashboardSettings extends CrudApi {
    constructor() {
        super('/reseller/settings/dashboard');
    }

    search(organizationId, includeHierarchy) {
        return this.get('reseller/settings/dashboard/search', {
            qs: {
                organizationId,
                includeHierarchy,
            },
        });
    }

}

const dashboardSettings = new DashboardSettings();
export default dashboardSettings;
