<template>
  <div :class="['counter-component-toggle-wrapper', error && 'error']">
    <base-button
      :rounded="false"
      :aria-label="$t('decrease')"
      :disabled="disabled"
      @click="updateCounter(-1)"
    >
      <cmc-icon
        size="s"
        icon="chevron-bold-down"
        svg
        :color="countIsZero ? 'text-light' : undefined"
        :withClickable="!countIsZero"
      />
    </base-button>
    <div class="count">
      {{ count }}
    </div>
    <base-button
      :rounded="false"
      :aria-label="$t('increase')"
      :disabled="disabled"
      @click="updateCounter(1)"
    >
      <cmc-icon
        size="s"
        icon="chevron-bold-up"
        svg
      />
    </base-button>
  </div>
</template>

<script>
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";

export default {
  name: 'CounterComponent',
  components: {CmcIcon},
  props: {
    count: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateCount'],
  computed: {
    countIsZero() {
      return this.count === 0;
    }
  },
  methods: {
    updateCounter(i) {
      const updatedCount = this.count + i;
      if (updatedCount >= 0) {
        this.$emit('updateCount', updatedCount);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.counter-component-toggle-wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid;
  width: fit-content;
  border-radius: 5px;
  border-color: var(--input-placeholder-text);

  button {
    border-color: transparent;
    height: unset;
    padding: 10px;

    content {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  button:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-color: var(--input-placeholder-text);
  }
  button:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-color: var(--input-placeholder-text);
  }

  .count {
    padding: 0.5rem 1.5rem;
    margin: auto;
  }
}
.error {
  border: 1px solid var(--input-error-border);
}
</style>
