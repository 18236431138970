<template>
  <div>
    <base-tabs 
      :tabs="tabs" 
      :selected="selectedTab" 
      light 
      hideIfOne
      @tabChange="currentlySelectedTab=$event.value" 
    />
    <slot :groupedDetails="sortedGroupedDetails" />
  </div>
</template>
<script>
import { sortBy } from '@/utils';

export default {
  name: 'QuotaTabs',
  props: {
    quotaDetails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentlySelectedTab: null,
    };
  },
  computed: {
    tabs() {
      return [
        ...(Object.keys(this.groupedQuotas).filter(category => category !== 'quotas.others')).map(category => ({
          value: category,
          label: category,
        })).sort(sortBy(c => this.$t(c.label))),
        ...(Object.keys(this.groupedQuotas).includes('quotas.others') ? [{
          value: 'quotas.others',
          label: 'quotas.others',
        }] : []),
      ];
    },
    selectedTab() {
      if (!this.groupedQuotas[this.currentlySelectedTab]) {
        return this.tabs.length > 0 ? this.tabs[0].value : null;
      }
      return this.currentlySelectedTab;
    },
    sortedGroupedDetails() {
      return [...this.groupedQuotas[this.selectedTab]].sort(sortBy(c => this.$t(c.labelKey)));
    },
    groupedQuotas() {
      return this.quotaDetails.reduce((acc, qd) => {
        const category = qd.categoryLabelKey || 'quotas.others';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category] = [...acc[category], qd];
        return acc;
      }, {});
    },
  },
};
</script>
