import BaseRoute from '@/app/components/BaseRoute';
import FeedbackSupportView from './FeedbackSupportView';
import FeedbackSupportSettings from './FeedbackSupportSettings';

export default [
  {
    path: 'feedback-support',
    component: BaseRoute,
    meta: {
      protected: ['reseller:feedback'],
      title: () => 'feedback_and_support_title',
    },
    children: [
      {
        name: 'feedbackSupportView',
        path: '',
        component: FeedbackSupportView,
      },
      {
        name: 'feedbackSupportSettings',
        path: 'edit',
        component: FeedbackSupportSettings,
        props: { switchOrgRouteName: 'feedbackSupportView' },
      },
    ],
  },
];
