<script>
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import BrandingForm from './BrandingForm';

export default {
  name: 'CreateBrandingView',
  extends: BrandingForm,
  mixins: [orgSwitchNavigationMixin],
};
</script>
