const ServiceAccountsList = () => import(/* webpackChunkName: "system" */ './ServiceAccountsList');
const ServiceAccountDetails = () => import(/* webpackChunkName: "system" */ './ServiceAccountDetails');

export default [
  {
    path: 'accounts',
    name: 'account-list',
    component: ServiceAccountsList,
    meta: {
      protected: ['reseller:accounts'],
      title: () => 'accounts_title',
    },
  },
  {
    path: 'accounts/:accountId',
    name: 'account-details',
    component: ServiceAccountDetails,
    meta: {
      protected: ['reseller:accounts'],
      title: () => 'accounts_title',
    },
  },
];
