<template>
  <app-link 
    v-if="to" 
    :to="to"
  >
    <slot></slot>
  </app-link>
  <div
    v-else-if="hasClickEvent"
    class="clickable" 
    @click="$emit('click')" 
    @keyup.self.enter="$emit('click')" 
  >
    <slot></slot>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TransitionRoute',
  props: {
    to: {
      type: [Object, String],
    },
    onClick: {
      type: Function,
    },
  },
  emits: ['click'],
  computed: {
    hasClickEvent() {
      return !!this.onClick;
    }
  }
};
</script>
