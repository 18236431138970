import apis from '@/utils/apis';
import store from '@/store';
import { ImpliedRole } from '../Administration/roles/constants';

const KEEP_ALIVE_PING_INTERVAL = 600000; // 10 minutes in milliseconds
const SET_ENVIRONMENTS = 'SET_ENVIRONMENTS';
const SET_ENVIRONMENT_OPTIONS = 'SET_ENVIRONMENT_OPTIONS';
const LOADING_ENVIRONMENTS = 'LOADING_ENVIRONMENTS';
const DONE_LOADING_ENVIRONMENTS = 'DONE_LOADING_ENVIRONMENTS';
const SET_ALL_ENVIRONMENTS = 'SET_ALL_ENVIRONMENTS';
const ADD_ENVIRONMENT = 'ADD_ENVIRONMENT';
const EDIT_ENVIRONMENT = 'EDIT_ENVIRONMENT';
const REMOVE_ENVIRONMENT = 'REMOVE_ENVIRONMENT';
const SET_ENVIRONMENT_ROLES = 'SET_ENVIRONMENT_ROLES';
const ADD_FILE_UPLOAD = 'ADD_FILE_UPLOAD';
const REMOVE_FILE_UPLOAD = 'REMOVE_FILE_UPLOAD';
const TEST_AND_SET_SERVER_PING = 'TEST_AND_SET_SERVER_PING';
const SET_ADMIN_FULL_ACCESS = 'SET_ADMIN_FULL_ACCESS';
const SET_ADMIN_ENVS_READ_ONLY_ACCESS = 'SET_ADMIN_ENVS_READ_ONLY_ACCESS';
const SET_WORKSPACE_USER_PREFERENCE_FIELDS = 'SET_WORKSPACE_USER_PREFERENCE_FIELDS';
const SET_WORKSPACE_USER_PREFERENCE_FIELDS_AND_VALUES = 'SET_WORKSPACE_USER_PREFERENCE_FIELDS_AND_VALUES';

// initial state
const intialState = {
  environments: null,
  loadingEnvs: false,
  envOptions: {
    connId: null,
    orgId: null,
  },
  allEnvironments: null,
  envRolesByServiceType: {},
  fileUploads: {},
  serverPing: false,
  adminFullAccess: false,
  adminEnvsReadOnlyAccess: false,
  workspaceUserPreferenceFields: [],
  workspaceUserPreferenceValues: {},
};

// getters
const getters = {
  environments: state => state.environments || [],
  loadingEnvs: state => state.loadingEnvs,
  allEnvironments: state => state.allEnvironments || [],
  envOptions: state => state.envOptions,
  envRolesByServiceType: state => state.envRolesByServiceType,
  fileUploads: state => state.fileUploads,
  adminFullAccess: state => state.adminFullAccess,
  adminEnvsReadOnlyAccess: state => state.adminEnvsReadOnlyAccess,
  workspaceUserPreferenceFields: state => state.workspaceUserPreferenceFields,
  workspaceUserPreferenceValues: state => state.workspaceUserPreferenceValues,
};

// actions
const actions = {
  loadEnvironmentsForOrgAndService: async ({ commit, state }, { orgId, connId }) => {
    if (state.envOptions.orgId === orgId
        && state.envOptions.connId === connId) {
      return;
    }
    commit(SET_ENVIRONMENT_OPTIONS, { orgId, connId });
    commit(LOADING_ENVIRONMENTS);
    const resp = await apis.environments.listForConnectionAndOrganization(connId, orgId);
    if (state.envOptions.orgId === orgId
        && state.envOptions.connId === connId) {
      commit(SET_ENVIRONMENTS, resp.data);
      commit(DONE_LOADING_ENVIRONMENTS);
    }
  },
  reloadEnvironmentsForOrgAndService: async ({ commit, state }, { orgId, connId }) => {
    commit(SET_ENVIRONMENT_OPTIONS, { orgId, connId });
    const resp = await apis.environments.listForConnectionAndOrganization(connId, orgId);
    if (state.envOptions.orgId === orgId
        && state.envOptions.connId === connId) {
      commit(SET_ENVIRONMENTS, resp.data);
    }
  },
  clearEnvironments({ commit }) {
    commit(SET_ENVIRONMENT_OPTIONS, {});
  },
  cancelLoadEnvironments({ commit }) {
    commit(DONE_LOADING_ENVIRONMENTS);
  },
  loadAllEnvironments: async ({ commit }, { orgId }) => {
    const resp = await apis.environments.list({ qs: { organization_id: orgId } });
    commit(SET_ALL_ENVIRONMENTS, resp.data);
  },
  addEnvironment: async ({ commit }, { environment }) => {
    commit(ADD_ENVIRONMENT, environment);
  },
  editEnvironment: async ({ commit }, { environment }) => {
    commit(EDIT_ENVIRONMENT, environment);
  },
  removeEnvironment: async ({ commit }, { environmentId }) => {
    commit(REMOVE_ENVIRONMENT, environmentId);
  },

  loadEnvRolesByServiceType: ({ commit, state }) => {
    const uniqueServiceTypes = [...new Set((state.allEnvironments || [])
      .map(env => env.serviceConnection.type))];

    uniqueServiceTypes.map(async (type) => {
      if (!state.envRolesByServiceType[type]) {
        const res = await apis.roles.list(
          {
            qs: {
              service_type: type,
              default_scope: 'ENV',
            },
          },
        );
        commit(SET_ENVIRONMENT_ROLES, { serviceType: type, envRoles: res.data });
      }
    });
  },

  addFileUpload: ({ commit, state }, file) => {
    const cur = state.fileUploads[`${file.route}/${file.name}`];
    if (cur && file.progress && cur.progress >= file.progress) {
      return;
    }
    commit(ADD_FILE_UPLOAD, file);
  },

  removeFileUpload: ({ commit }, filename) => {
    commit(REMOVE_FILE_UPLOAD, filename);
    commit(TEST_AND_SET_SERVER_PING);
  },

  testAndSetServerPing: ({ commit }) => {
    commit(TEST_AND_SET_SERVER_PING);
  },

  async checkImpliedRolesAccess({ commit }, connId) {
    try {
      const resp = await apis.roles.impliedEnvRolesEnabledStatus(connId, store.getters.selectedOrganization.id);
      commit(SET_ADMIN_FULL_ACCESS, resp.data[ImpliedRole.FULL_ACCESS.name]);
      commit(SET_ADMIN_ENVS_READ_ONLY_ACCESS, resp.data[ImpliedRole.READ_ONLY_ACCESS.name]);
    } catch (error) {
      commit(SET_ADMIN_FULL_ACCESS, false);
      commit(SET_ADMIN_ENVS_READ_ONLY_ACCESS, false);
    }
  },

  async loadWorkspaceUserPreferenceValues({ commit }, { entryPoint, serviceCode, env }) {
    const workspaceResp = await apis.userWorkspacePreference.getPreferences(
      entryPoint, serviceCode, env
    );
    let workspaceUserPreferenceValues = {};
    if (workspaceResp?.status == 200) {
      workspaceResp.data.forEach((preference) => {
        workspaceUserPreferenceValues[preference.key] = preference.value;
      }) 
    }
    commit(SET_WORKSPACE_USER_PREFERENCE_FIELDS_AND_VALUES, workspaceUserPreferenceValues)
  },
  async updateOrCreateWorkspaceUserPreferenceValues({ commit }, { entryPoint, serviceCode, env, values }) {
    await apis.userWorkspacePreference.updateOrCreatePreferences(
      entryPoint, serviceCode, env, values
    );
    commit(SET_WORKSPACE_USER_PREFERENCE_FIELDS_AND_VALUES, values)
  },
  setWorkspaceUserPreferenceFields: ({ commit }, userPreferenceFields) => {
    commit(SET_WORKSPACE_USER_PREFERENCE_FIELDS, userPreferenceFields);
  },
};

// mutations
const mutations = {
  [SET_ENVIRONMENTS](state, environments) {
    state.environments = environments;
  },
  [SET_ENVIRONMENT_OPTIONS](state, envOptions) {
    state.envOptions = envOptions;
  },
  [LOADING_ENVIRONMENTS](state) {
    state.loadingEnvs = true;
  },
  [DONE_LOADING_ENVIRONMENTS](state) {
    state.loadingEnvs = false;
  },
  [SET_ALL_ENVIRONMENTS](state, environments) {
    state.allEnvironments = environments;
  },
  [ADD_ENVIRONMENT](state, environment) {
    state.environments.push(environment);
  },
  [EDIT_ENVIRONMENT](state, environment) {
    const index = state.environments.findIndex(env => env.id === environment.id);
    if (index > -1) {
      state.environments.splice(index, 1, environment);
    }
  },
  [REMOVE_ENVIRONMENT](state, environmentId) {
    const index = state.environments.findIndex(env => env.id === environmentId);
    if (index > -1) {
      state.environments.splice(index, 1);
    }
  },
  [SET_ENVIRONMENT_ROLES](state, { serviceType, envRoles }) {
    state.envRolesByServiceType = {
      ...state.envRolesByServiceType,
      [serviceType]: envRoles,
    };
  },
  [ADD_FILE_UPLOAD](state, file) {
    state.fileUploads = {
      ...state.fileUploads,
      [`${file.route}/${file.name}`]: file,
    };
    const ongoinginUploads = Object.values(state.fileUploads)
      .filter(fu => fu.uploading)
      .filter(fu => fu.progress < 100).length;
    if (!state.serverPing && ongoinginUploads > 0) {
      state.serverPing = setInterval(() => {
        apis.healthCheck.keepSessionAlive();
        store.dispatch('testAndSetServerPing');
      }, KEEP_ALIVE_PING_INTERVAL);
    }
  },
  [REMOVE_FILE_UPLOAD](state, filepath) {
    delete state.fileUploads[filepath];
    state.fileUploads = { ...state.fileUploads };
  },
  [TEST_AND_SET_SERVER_PING](state) {
    const ongoinginUploads = Object.values(state.fileUploads)
      .filter(fu => fu.uploading)
      .filter(fu => fu.progress < 100).length;
    if (!ongoinginUploads) {
      clearInterval(state.serverPing);
      state.serverPing = false;
    }
  },
  [SET_ADMIN_FULL_ACCESS](state, hasAdminFullAccess) {
    state.adminFullAccess = hasAdminFullAccess;
  },
  [SET_ADMIN_ENVS_READ_ONLY_ACCESS](state, hasAdminEnvsReadOnlyAccess) {
    state.adminEnvsReadOnlyAccess = hasAdminEnvsReadOnlyAccess;
  },
  [SET_WORKSPACE_USER_PREFERENCE_FIELDS](state, fields) {
    state.workspaceUserPreferenceFields = fields;
  },
  [SET_WORKSPACE_USER_PREFERENCE_FIELDS_AND_VALUES](state, values) {
    state.workspaceUserPreferenceValues = values;
    Object.entries(values).forEach(entry => {
      const [key, value] = entry;
      (state.workspaceUserPreferenceFields.find(p => p.formElement.field === key) || {}).value = value;
    });
  }
};

export default {
  state: intialState,
  getters,
  actions,
  mutations,
};
