import { CrudApi } from './api';

class CustomFieldsApi extends CrudApi {
  constructor() {
    super('custom_fields');
  }
  associatedIdps(customFieldId){
    return this.get(`/custom_fields/${customFieldId}/associatedIdps`, {});
  }

  associatedProductCatalogs(orgId, customFieldId) {
    return this.get(`/custom_fields/service_entity/associated_product_catalogs/${orgId}`,{
      qs: {
        custom_field_id: customFieldId,
      },
    });
  }

}


const customFieldsApi = new CustomFieldsApi();
export default customFieldsApi;
