import { ReadOnlyApi } from './api';

class TasksApi extends ReadOnlyApi {
  constructor() {
    super('tasks');
  }
}

const tasks = new TasksApi();
export default tasks;
