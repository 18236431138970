<template>
  <base-loader v-if="loading" />
  <form-page
    v-else
    :title="title"
    :defaultBack="defaultBack"
    :executing="executing && !showRollbackWarning"
    :disabled="disabled"
    @submit="submitForm"
  >
    <form-row label="name">
      <base-input
        v-model="commitment.name"
        :error="errors.name"
        @focus="errors.name = null"
      />
    </form-row>
    <form-row
      v-if="isGlobalView"
      label="organization"
    >
      <base-select
        v-model="commitment.organization.id"
        :items="organizationItems"
        @update:modelValue="onUpdateOrg"
      />
    </form-row>
    <form-row label="monetization.commitments.scopes.title">
      <base-select
        v-model="commitment.scope"
        :items="commitmentScopes"
        :disabled="editing"
      />
    </form-row>
    <form-row
      v-if="commitment.scope == 'SERVICE_CONNECTION'"
      label="service_connection"
    >
      <base-select
        v-model="commitment.serviceConnection.id"
        :error="errors.serviceConnection"
        :items="serviceConnectionItems"
        :disabled="editing"
        @update:modelValue="onUpdateServiceConnection"
      />
    </form-row>
    <form-row
      v-if="commitment.scope == 'ENVIRONMENT'"
      label="environment"
    >
      <base-select
        v-model="commitment.environment.id"
        :error="errors.environment"
        :items="environmentItems"
        :disabled="editing"
        @update:modelValue="onUpdateEnvironment"
      />
    </form-row>
    <form-row label="start_date">
      <date-input
        v-model="commitment.startDate"
        utc
        :minDate="minCycleStartDate"
        :error="errors.startDate"
        :disabled="isTerminatable"
        @update:modelValue="changeStartDate"
      />
    </form-row>
    <form-row
      v-if="hasEndDate"
      label="end_date"
    >
      <date-input
        v-model="commitment.endDate"
        utc
        :minDate="minCycleEndDate"
        :error="errors.endDate"
        @update:modelValue="changeEndDate"
      /> <!-- minDate means not selectable -->
    </form-row>
    <form-row>
      <base-checkbox
        v-model="hasEndDate"
        sm
        label="monetization.commitments.with_end_date"
        @update:modelValue="toggleWithEndDate"
      />
    </form-row>
    <form-row label="monetization.commitments.types.title">
      <base-select
        v-model="commitment.type"
        disabled
        :items="commitmentTypes"
      />
    </form-row>
    <form-row label="monetization.commitments.pricing_methods.title">
      <base-select
        v-model="commitment.pricingMethod"
        :items="pricingMethods"
        @update:modelValue="onUpdatePricingMethod"
      />
    </form-row>
    <form-row
      v-if="isUtilityDiscount"
      label="monetization.commitments.rate_types.title"
    >
      <base-select
        v-model="commitment.rateType"
        :items="rateTypes"
      />
    </form-row>
    <form-row
      v-if="!isUtilityDiscount"
      label="monetization.price"
    >
      <base-input
        v-model="commitment.fixedPrice"
        type="number"
        :suffix="priceSuffix"
        :error="errors.fixedPrice"
        @focus="errors.fixedPrice = null"
      />
    </form-row>
    <form-row
      v-if="!isUtilityDiscount && hasTaxProvider"
      label="monetization.tax_code"
    >
      <base-select
        class="select-tax-codes"
        :items="taxCodes"
        :modelValue="commitment.fixedPriceTaxCode ? commitment.fixedPriceTaxCode.code : undefined"
        :placeholder="$t('select_tax_code')"
        :error="errors.fixedPriceTaxCode"
        @update:modelValue="setFixedPriceTaxCode"
      />
    </form-row>
    <form-row label="monetization.products">
      <committed-product-dropdown
        :products="filteredProducts"
        :error="errors.invalidProducts"
        @addProduct="addProduct"
      />
      <div
        v-if="commitment.committedProducts.length === 0"
        class="no-products-message input-control"
      >
        <alert-box
          border
          :alertType="errors.committedProducts ? 'ERROR' : 'INFO'"
          label="monetization.commitments.products.no_products"
        />
      </div>
      <div v-else>
        <base-tabs
          v-if="categoryTabs.length > 0"
          light
          :tabs="categoryTabs"
          :selected="selectedCategoryId"
          @tabChange="changeCategory($event.value)"
        />
        <commitment-form-committed-product-list
          ref="committedProductListComponent"
          :pricingMethod="commitment.pricingMethod"
          :committed-products="filteredCommittedProducts"
          :productIdToEstimate="productIdToEstimate"
          :currency="currency"
          @remove-product="removeProduct"
        />
      </div>
    </form-row>
    <alert-box
      v-if="isUtilityDiscount && commitment.committedProducts.length !== 0"
      class="input-control no-products-message"
      alertType="INFO"
      label="monetization.commitments.estimated_price"
      :interpolation="estimatedDiscountCost"
      border
    />
    <confirm-modal
      v-if="showRollbackWarning"
      headerIcon="fa fa-edit"
      :confirmLabel="modalConfirmLabel"
      :detailsLabel="modalDetailsLabel"
      :headerLabel="modalHeaderLabel"
      :open="showRollbackWarning"
      :detailsInterpolation="{ commitmentDate: commitmentDate, organization: organizationName, reprocessDate: reprocessDate,}"
      :executing="executing"
      @cancel="showRollbackWarning = false"
      @ok="submitForm(true)"
    />
  </form-page>
</template>

<script>
import { mapGetters } from 'vuex';

import { binarySearch, sortBy, isEqual } from '@/utils';
import { currencyFormatters } from '@/utils/currency';
import { computeUsageInTier } from '@/utils/usage';
import apis from '@/utils/apis';
import { addDays, getDate, getMin, getNow, isBefore, LIST_SIMPLE_DATE_FORMAT } from '@/utils/dates';

import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { commitmentMixin } from '@/mixins/commitmentMixin';
import { pricingUnitFormatterMixin } from '@/mixins/pricingUnitFormatter';

import notify from '@/utils/notify';

import CommittedProductDropdown from './form/CommittedProductDropdown';
import CommitmentFormCommittedProductList from "@/app/Main/components/commitments/form/CommitmentFormCommittedProductList";

import { taxMixin } from '@/mixins/taxMixin';

const HOURS_IN_MONTH = 24 * 30;

const compareByName = (cp, product, locale) => {
  const name1 = cp.product.name[locale].toLocaleLowerCase();
  const name2 = product.name[locale].toLocaleLowerCase();
  return name1.localeCompare(name2);
};

export default {
  name: 'CommitmentForm',
  components: {
    CommitmentFormCommittedProductList,
    CommittedProductDropdown,
  },
  mixins: [pricingUnitFormatterMixin, taxMixin, commitmentMixin],
  props: {
    sourceCommitment: {
      type: Object,
      required: false
    },
    organizations: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      editing: false,
      isTerminatable: false,
      disabled: false,
      loading: true,
      executing: false,
      showRollbackWarning: false,
      modalConfirmLabel: undefined,
      modalDetailsLabel: undefined,
      modalHeaderLabel: undefined,
      organizationId: undefined,
      cycles: [],
      pricingPackage: {},
      pricingDefinition: {},
      taxCodes: [],
      hasTaxProvider: false,
      serviceConnections: [],
      environments: [],
      commitment: {
        scope: 'ORGANIZATION',
        type: 'RESOURCE',
        name: undefined,
        organization: {
          id: undefined,
        },
        serviceConnection: {
          id: undefined,
        },
        environment: {
          id: undefined,
        },
        startDate: getNow(),
        endDate: undefined,
        pricingMethod: undefined,
        rateType: undefined,
        fixedPrice: undefined,
        committedProducts: [],
      },
      selectedCategoryId: undefined,
      hasEndDate: false,
      errors: {
        name: null,
        startDate: null,
        endDate: null,
        fixedPrice: null,
        committedProducts: null,
        fixedPriceTaxCode: null,
        environment: null
      },
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    isGlobalView() {
      return !this.organizationId;
    },
    defaultBack() {
      return this.isGlobalView ? { name: 'global-commitment-list' } :{ name: 'org-billing' };
    },
    organizationName() {
      return this.commitment.organization.name;
    },
    organizationItems() {
      return this.organizations.map(o => ({ display: o.name, value: o.id }));
    },
    serviceConnectionItems() {
      return this.serviceConnections.map(o => ({ display: o.name, value: o.id }));
    },
    environmentItems() {
      return this.environments.map(e => ({ display: e.name, value: e.id }));
    },
    title() {
      return this.commitment.id ?
        this.$t('monetization.commitments.operations.edit.title') :
        this.$t('monetization.commitments.operations.create.title');
    },
    commitmentScopes() { 
      return [
        { display: this.$t('monetization.commitments.scopes.organization'), value: 'ORGANIZATION' },
        { display: this.$t('monetization.commitments.scopes.service_connection'), value: 'SERVICE_CONNECTION' },
        { display: this.$t('monetization.commitments.scopes.environment'), value: 'ENVIRONMENT' },
      ]
    },
    commitmentTypes() {
      return [
        { display: this.$t('monetization.commitments.types.resource'), value: 'RESOURCE' },
      ]
    },
    pricingMethods() {
      return [
        { display: this.$t('monetization.commitments.pricing_methods.fixed_price'), value: 'FIXED_PRICE' },
        { display: this.$t('monetization.commitments.pricing_methods.utility_discount'), value: 'UTILITY_DISCOUNT' },
      ];
    },
    isUtilityDiscount() {
      return this.commitment.pricingMethod === 'UTILITY_DISCOUNT';
    },
    rateTypes() {
      return [
        { display: this.$t('monetization.commitments.rate_types.fixed_rate'), value: 'FIXED_RATE' },
        { display: this.$t('monetization.commitments.rate_types.variable_rate'), value: 'VARIABLE_RATE' },
      ];
    },
    currency() {
      return (this.cycle || {}).currency;
    },
    priceSuffix() {
      return this.currency;
    },
    cycle() {
      const startDate = getDate(this.commitment.startDate);
      const cycle = this.cycles.find(c => getDate(c.startDate) <= startDate);
      return cycle || this.cycles.find(c => getDate(c.startDate) <= getNow());
    },
    reprocessDate() {
      let reprocessStartDate = commitmentMixin.methods.computeReprocessStartDate(this.minCycleStartDate, this.commitment.startDate);
      return this.$date(reprocessStartDate, LIST_SIMPLE_DATE_FORMAT, true);
    },
    minCycleStartDate() {
      return this.minStartDate(this.cycles);
    },
    minCycleEndDate() {
      if (this.editing && this.isTerminatable) {
        return getDate(this.cycles
          .filter(c => this.isOngoing(c))
          .sort(sortBy(c => getDate(c.endDate)))[0]
          .endDate, true);
      }
      return getDate(this.commitment.startDate,true);
    },
    productCatalogs() {
      return this.pricingDefinition.productCatalogs.filter(pc => pc.productType != "NATIVE");
    },
    categories() {
      return (this.productCatalogs || []).flatMap(pc => pc.categories);
    },
    filteredCategories() {
      return Object.values(this.commitment.committedProducts
        .map(cp => cp.product)
        .map(p => p.category)
        .reduce((acc, curr) => ({
          ...acc,
          [curr.id]: {
            ...curr,
            count: 1 + ((acc[curr.id] || {}).count || 0),
          },
        }), {}));
    },
    categoryTabs() {
      return [...this.filteredCategories].sort((a, b) => {
        const name1 = a.name[this.locale].toLocaleLowerCase();
        const name2 = b.name[this.locale].toLocaleLowerCase();
        return name1.localeCompare(name2);
      }).map(c => ({
        value: c.id,
        label: `${c.name[this.locale]} (${c.count})`,
      }));
    },
    filteredProducts() {
      if (!this.pricingDefinition || !this.pricingDefinition.pricingProducts) {
        return [];
      }

      const nativeCategoryIds = this.pricingDefinition.productCatalogs
        .filter(pc => pc.productType == "NATIVE")
        .map(pc=> pc.categories[0].id);

      const products = this.pricingDefinition.pricingProducts
        .filter(pp => !pp.deprecated)
        .filter(pp => !nativeCategoryIds.includes(pp.product.categoryId))
        .filter(this.selectableProduct)
        .map(pp => pp.product)
        .map(p => {
          const category = this.categories.find(c => c.id === p.categoryId);
          return {
            ...p,
            value: p.id,
            group: category.name[this.locale],
            category: category,
          }
        });

      const ids = this.commitment.committedProducts.map(cp => cp.product.id);
      return products.filter((p) => {
        return !ids.includes(p.id);
      });
    },
    filteredCommittedProducts() {
      if (!this.selectedCategoryId) {
        return this.commitment.committedProducts;
      }
      return this.commitment.committedProducts
        .filter(cp => cp.product.category.id === this.selectedCategoryId);
    },
    targetDate() {
      // earliest ongoing billing cycle end date OR now
      const d = this.cycles
        .filter(c => billingCycleMixin.methods.isOngoing(c))
        .reduce((acc, c) => getMin(acc, c.endDate), getNow());
      return this.$date(d, LIST_SIMPLE_DATE_FORMAT, true);
    },
    commitmentDate() {
      return this.$date(this.commitment.startDate, LIST_SIMPLE_DATE_FORMAT, true);
    },
    productIdToPricedProduct() {
      const pricedProducts = (this.pricingDefinition.pricingProducts || []).filter(pp => !pp.deprecated);
      return pricedProducts.reduce((acc, cur) => ({
        ...acc,
        [cur.product.id]: cur,
      }), {});
    },
    productIdToEstimate() {
      // tiers = YES | NONE
      //   | -> pricing mode = UNIT_PRICE | FLAT_FEE
      // period = HOUR | MONTHLY | NONE
      const currency = this.currency;
      return this.commitment.committedProducts.map(cp => {
        const pricedProduct = this.productIdToPricedProduct[cp.product.id];
        if (!pricedProduct) {
          return {
            id: cp.product.id,
            unitPrice: 0,
            tiers: [],
          };
        }

        // cache value to avoid recomputing in loop
        let periodFactor = this.computePeriodFactor(cp);
        const committedAmount = parseFloat(cp.committedAmount);
        const discountFactor = parseFloat(cp.discountAmount) / 100;

        // pricing tiers
        let price = 0; // cumulative tier price
        const hasTiers = pricedProduct.pricingTiers && pricedProduct.pricingTiers.length > 0;
        const fakeTier = [{
          lowerBound: 0,
          price: { [currency]: pricedProduct.unitPrice[currency] },
        }];
        const pricingTiers = hasTiers ? pricedProduct.pricingTiers : fakeTier;

        // pricing mode
        const pricingMode = (pricingTiers[0] || {}).pricingMode;

        // tier breakdown
        const tiers = pricingTiers.reduce((acc, cur) => {
          const lowerBound = cur.lowerBound;
          const upperBound = cur.upperBound;
          const usage = computeUsageInTier(committedAmount, cur);

          // tier-level prices
          const unitPrice = cur.price[currency];
          const tierPrice = usage * unitPrice;

          // product-level price
          price += tierPrice;

          acc.push({
            id: cur.id,
            lowerBound,
            upperBound,
            usage,
            chunkSize: cur.chunkSize,
            unitPrice,
            tierPrice,
            cost: periodFactor * tierPrice,
            discountUnitPrice: (1 - discountFactor) * unitPrice,
            discountPrice: (1 - discountFactor) * tierPrice,
            discountCost: (1 - discountFactor) * periodFactor * tierPrice,
          });
          return acc;
        }, []);

        // product-level prices
        const discountPrice = (1 - discountFactor) * price;

        // weighted average unit price
        const unitPrice = price / committedAmount;

        return {
          id: cp.product.id,
          pricingMode,
          currency,
          unit: this.computeUnit(cp),
          unitPrice,
          discountUnitPrice: (1 - discountFactor) * unitPrice,
          price,
          cost: periodFactor * price,
          discountPrice,
          discountCost: periodFactor * discountPrice,
          hasTiers,
          tiers,
        };
      }).reduce((acc, curr) => ({
        ...acc,
        [curr.id]: curr
      }), {});
    },
    estimatedDiscountCost() {
      const price = this.commitment.committedProducts.reduce((acc, cur) => {
        const pricing = this.productIdToEstimate[cur.product.id] || { discountCost: 0 };
        const price = pricing.discountCost || 0;
        return acc + price;
      }, 0) || 0;

      const formattedPrice = currencyFormatters[this.locale]({
        style: "currency",
        currency: this.currency,
      }).format(price);

      return {
        price: formattedPrice
      };
    },
  },
  async created() {
    this.loading = true;

    // the route will not contain the organization id
    // when coming from the global commitment listing
    if (this.$route.params.id) {
      this.organizationId = this.$route.params.id;
      const organization = this.organizations.filter(o => o.id === this.organizationId)[0];
      if (organization) {
        this.commitment.organization.id = organization.id;
        this.commitment.organization.name = organization.name;
        await this.fetchCycles(organization.id);
        await this.fetchBillingInfo();
        await this.fetchServiceConnections(organization.id);
        await this.fetchEnvironments(organization.id);
      }
    }

    this.hasTaxProvider = await this.hasTaxProviderConfigured(this.selectedOrganization.id);
    if (this.hasTaxProvider) {
      this.taxCodes = await this.fetchTaxCodes();
    }

    // If there is a source commitment, update the data with it.
    if (this.sourceCommitment) {
      this.editing = true;
      // Add the necessary product category based on the category id.
      this.sourceCommitment.committedProducts.forEach(committedProduct => {
        committedProduct.product.category = this.categories.filter(category => category.id === committedProduct.product.categoryId)[0];
      });

      // Copy the source commitment into the commitment
      this.commitment = JSON.parse(JSON.stringify(this.sourceCommitment));

      // Set the first category as selected tab
      if (!this.selectedCategoryId) {
        this.selectedCategoryId = this.categoryTabs[0]?.value
      }

      if (this.commitment.endDate !== undefined) {
        this.hasEndDate = true;
      }

      // Calculate if the commitment is terminatable
      this.isTerminatable = this.canTerminate(this.commitment, this.cycles);
    }

    this.loading = false;
  },
  methods: {
    // api calls
    async fetchCycles(organizationId) {
      const resp = await apis.billable.getBillingCycles(organizationId, true);
      if (resp.ok) {
        this.cycles = resp.data.sort(sortBy(c => getDate(c.startDate))).reverse();
      } else {
        notify.error(this.$t('monetization.error_fetching_billing_info'));
      }
    },
    async fetchPricingPackage(id) {
      const resp = await apis.pricingPackages.find(id);
      if (resp.ok) {
        this.pricingPackage = resp.data;
      } else {
        notify.error(this.$t('monetization.error_fetching_billing_info'));
      }
    },
    async fetchEffectivePricing(id, date) {
      const resp = await apis.pricings.getEffectivePricing(id, date);
      if (resp.ok) {
        this.pricingDefinition = resp.data;
      } else {
        notify.error(this.$t('monetization.error_fetching_billing_info'));
      }
    },
    async fetchBillingInfo() {
      if (this.cycle && this.cycle.pricingPackages) {
        await this.fetchPricingPackage(this.cycle.pricingPackages[0].packageId);
      }

      if (this.pricingPackage && this.pricingPackage.pricingDefinition) {
        await this.fetchEffectivePricing(
          this.pricingPackage.pricingDefinition.id,
          this.$date(this.commitment.startDate, LIST_SIMPLE_DATE_FORMAT, true),
        );
      }
    },
    async fetchServiceConnections(orgId) {
      const resp = await apis.organizations.findServiceConnections(orgId, false);

      // If there is network delay and the organization has changed in the meanwhile, ignore the response.
      if (orgId !== this.commitment.organization.id) {
        return;
      }

      if (resp.ok) {
        this.serviceConnections = resp.data.sort(sortBy(c => c.name));
      } else {
        notify.error(this.$t('monetization.error_fetching_connections'));
      }
      // Clear the errors in the form, if any.
      delete this.errors.serviceConnection;
    },
    async fetchEnvironments(orgId) { 
      const resp = await apis.environments.listForOrganization(orgId);
      if (resp.ok) {
        this.environments = resp.data.sort(sortBy(e => e.name));
      } else { 
        notify.error(this.$t('monetization.error_fetching_environments'));
      }
      // Clear the errors in the form, if any.
      delete this.errors.environment;
    },
    // form actions
    addProduct(product) {
      this.errors.committedProducts = null;

      // search for the product in the committed products list
      const idx = binarySearch(
        this.commitment.committedProducts,
        cp => compareByName(cp, product, this.locale));

      // product not found, add it to the list
      if (idx < 0) {
        this.commitment.committedProducts.splice(-1 * (idx + 1), 0, { product });
        // switch to the added product's category tab
        this.selectedCategoryId = product.category.id;
      }
    },
    removeProduct(commitmentProduct) {
      const product = commitmentProduct.product;

      // search for the product in the committed products list
      const idx = this.commitment.committedProducts.findIndex(cp => cp.product.id === product.id)

      // product found, remove it from the list
      if (idx >= 0) {
        this.commitment.committedProducts.splice(idx, 1);
        // switch to the first category tab when there are no more
        // products left in the removed product's category
        if (!this.filteredCategories.some(c => c.id === product.category.id)) {
          // but only when there are other tabs
          if (this.categoryTabs[0]) {
            this.changeCategory(this.categoryTabs[0].value);
          }
        }
      }
    },
    changeCategory(categoryId) {
      this.selectedCategoryId = categoryId;
    },
    async onUpdateOrg(orgId) {
      await this.fetchCycles(orgId);
      await this.fetchBillingInfo();
      await this.fetchServiceConnections(orgId);
      await this.fetchEnvironments(orgId);
    },
    onUpdateServiceConnection() {
      delete this.errors.serviceConnection;
    },
    onUpdateEnvironment() { 
      delete this.errors.environment;
    },
    onUpdatePricingMethod() {
      this.commitment.fixedPrice = null;
      this.commitment.rateType = null;
      this.commitment.committedProducts.forEach(cp => {
        cp.discountAmount = null;
      })
    },
    toggleWithEndDate() {
      if (this.commitment.endDate) {
        this.commitment.endDate = null;
        this.errors.endDate = null;
      } else {
        this.resetEndDate();
      }
    },
    async changeStartDate() {
      this.errors.startDate = null;
      if (this.commitment.endDate < this.commitment.startDate) {
        this.resetEndDate();
      }
      await this.fetchBillingInfo();
    },
    resetEndDate() {
      this.commitment.endDate = (this.editing) ? this.sourceCommitment.endDate: addDays(this.minCycleEndDate, 1, true);
      this.errors.endDate = null;
    },
    changeEndDate() {
      this.errors.endDate = null;
    },
    validateStartDate() {
      if (this.editing && this.isTerminatable) {
          return false;
      }
      return isBefore(this.commitment.startDate, this.minCycleStartDate);
    },
    validateEndDate() {
      if (this.editing && this.isTerminatable) {
        if (this.$date(this.minCycleEndDate,'YYYY-MM-DD',true) === this.sourceCommitment.endDate && this.sourceCommitment.endDate === this.commitment.endDate) {
          return true;
        }
      }
      return this.commitment.endDate && isBefore(this.commitment.endDate, this.$date(this.minCycleEndDate,'YYYY-MM-DD',true));
    },
    validateServiceConnection() {
      if (this.commitment.scope == "ORGANIZATION") { 
        return false;
      }

      if (this.commitment.scope == "ENVIRONMENT") {
        return false;
      }

      // If the user has selected connection scope, but without a service connection id.
      if (this.commitment.scope == "SERVICE_CONNECTION" && this.commitment.serviceConnection.id === undefined) { 
        return true;
      }

      // Validate that there is no mismatch between the fetched connection and the selected connection.
      const selectedServiceConnection = this.serviceConnections.find(connection => connection.id === this.commitment.serviceConnection.id);
      if (!selectedServiceConnection) { 
        return true;
      }

      return false;
    },
    validateEnvironment() {
      if (this.commitment.scope == "ORGANIZATION") { 
        return false;
      }

      if (this.commitment.scope == "SERVICE_CONNECTION") {
        return false;
      }

      // If the user has selected environment, but without an environment id.
      if (this.commitment.scope == "ENVIRONMENT" && this.commitment.environment.id === undefined) { 
        return true;
      }

      // Validate that there is no mismatch between the fetched environment and the selected environment.
      const selectedEnvironment = this.environments.find(env => env.id === this.commitment.environment.id);
      if (!selectedEnvironment) { 
        return true;
      }

      // Validate that the environment is owned by the selected organization id.
      if (selectedEnvironment.organization.id !== this.commitment.organization.id) { 
        return true;
      }

      return false;
    },
    validateCommitment() {
      const labelPrefix = "monetization.commitments.operations.create.errors";
      const hasMissingNameError = !this.commitment.name ? this.$t(`${labelPrefix}.missing_name`) : null;
      const hasStartDateBeforeMin = this.validateStartDate();
      const hasEndDateBeforeMin = this.validateEndDate();
      const hasInvalidServiceConnection = !this.validateServiceConnection();
      const hasInvalidEnvironment = !this.validateEnvironment();
      const hasInvalidPrice = !this.isUtilityDiscount && (!this.commitment.fixedPrice || parseFloat(this.commitment.fixedPrice) < 0);
      const hasMissingProducts = this.commitment.committedProducts.length === 0;
      const hasMissingTaxCode = this.hasTaxProvider && this.commitment.pricingMethod === 'FIXED_PRICE' && !this.commitment.fixedPriceTaxCode;
      const invalidProducts = this.commitment.committedProducts
        .map(cp => this.pricingDefinition.pricingProducts.find(pp => pp.product.id === cp.product.id))
        .filter(p => !this.selectableProduct(p));

      return {
        name: hasMissingNameError,
        environment: hasInvalidEnvironment ? null : this.$t(`${labelPrefix}.invalid_environment`),
        serviceConnection: hasInvalidServiceConnection ? null : this.$t(`${labelPrefix}.invalid_service_connection`),
        startDate: hasStartDateBeforeMin ? this.$t(`${labelPrefix}.min_start_date`, {
          date: this.$date(this.minCycleStartDate, LIST_SIMPLE_DATE_FORMAT, true)
        }) : null,
        endDate: hasEndDateBeforeMin ? this.$t(`${labelPrefix}.min_end_date`, {
          date: this.$date(addDays(this.minCycleEndDate, 1, true), LIST_SIMPLE_DATE_FORMAT, true)
        }) : null,
        fixedPrice: hasInvalidPrice ? this.$t(`${labelPrefix}.invalid_price`) : null,
        committedProducts: hasMissingProducts ? this.$t(`${labelPrefix}.no_products`) : null,
        fixedPriceTaxCode: hasMissingTaxCode ? this.$t(`${labelPrefix}.missing_tax_code`) : null,
        invalidProducts: invalidProducts.map(p => this.$t(`${labelPrefix}.invalid_product`, {
          product: p.product.name[this.locale]
        })).join('\n')
      };
    },
    detectIfReprocessIsRequired() {
      if (this.editing) {
        // If the commitment is already started.
        if (new Date(this.commitment.startDate) <= getNow(true) && new Date(this.sourceCommitment.startDate) <= getNow(true)) {
          // You need to verify if any of the fields that trigger a reprocess has been modified.
          if (this.sourceCommitment.endDate !== this.commitment.endDate
          || this.sourceCommitment.startDate !== this.commitment.startDate
          || this.sourceCommitment.pricingMethod !== this.commitment.pricingMethod
          || this.sourceCommitment.rateType !== this.commitment.rateType
          || this.sourceCommitment.fixedPrice !== this.commitment.fixedPrice
          || this.sourceCommitment.fixedPriceTaxCode !== this.commitment.fixedPriceTaxCode
          || !isEqual(this.sourceCommitment['committedProducts'], this.commitment['committedProducts'])) {
            return true;
          }
        }
      }
      return false;
    },
    async submitForm(overrideModal) {
      this.executing = true;
      // form validation
      this.errors = this.validateCommitment();
      const hasErrors = Object.values(this.errors).filter(a => a).length > 0;
      const hasChildErrors = this.$refs.committedProductListComponent && this.$refs.committedProductListComponent.hasErrors();
      if (hasErrors || hasChildErrors) {
        notify.error(this.$t('monetization.commitments.operations.create.error_create_validation'));
        this.executing = false;
        return;
      }

      if (this.editing) {
        if (!overrideModal && this.detectIfReprocessIsRequired()) {
          this.modalConfirmLabel = 'edit';
          this.modalDetailsLabel = 'monetization.commitments.operations.edit.rollback_warning';
          this.modalHeaderLabel = 'monetization.commitments.operations.edit.title';
          this.showRollbackWarning = true;
          this.executing = false;
          return;
        }
      } else if (!overrideModal && isBefore(this.commitment.startDate, this.targetDate)) {
        this.modalConfirmLabel = 'create';
        this.modalDetailsLabel = 'monetization.commitments.operations.create.rollback_warning';
        this.modalHeaderLabel = 'monetization.commitments.operations.create.title';
        this.showRollbackWarning = true;
        this.executing = false;
        return;
      }

      this.disabled = true;

      const commitment = {
        id: this.$route.params.commitmentId,
        name: this.commitment.name,
        scope: this.commitment.scope,
        organization: {
          id: this.commitment.organization.id,
        },
        environment: this.commitment.scope == "ENVIRONMENT" ? { id: this.commitment.environment.id } : undefined,
        serviceConnection: this.commitment.scope == "SERVICE_CONNECTION" ? { id: this.commitment.serviceConnection.id } : undefined,
        startDate: this.$date(this.commitment.startDate, LIST_SIMPLE_DATE_FORMAT, true),
        endDate: this.$date(this.commitment.endDate, LIST_SIMPLE_DATE_FORMAT, true) || null,
        type: this.commitment.type,
        pricingMethod: this.commitment.pricingMethod,
        fixedPrice: this.commitment.pricingMethod === 'FIXED_PRICE' ? parseFloat(this.commitment.fixedPrice) : undefined,
        fixedPriceTaxCode: this.commitment.pricingMethod === 'FIXED_PRICE' ? this.commitment.fixedPriceTaxCode : undefined,
        rateType: this.commitment.rateType,
        committedProducts: this.commitment.committedProducts.map((cp) => {
          return {
            id: cp.id,
            product: { id: cp.product.id },
            committedAmount: parseFloat(cp.committedAmount),
            discountAmount:  parseFloat(cp.discountAmount)
          }
        }),
      };

      let resp
      let successLabel
      let errorLabel
      if (this.editing) {
        resp = await apis.commitments.update(this.sourceCommitment.id, commitment, {});
        successLabel = 'monetization.commitments.operations.edit.success'
        errorLabel = 'monetization.commitments.operations.edit.error_editing_commitment'
      } else {
        resp = await apis.commitments.create(commitment);
        successLabel = 'monetization.commitments.operations.create.success';
        errorLabel = 'monetization.commitments.operations.create.error_adding_commitment';
      }

      if (resp.ok) {
        notify.success(this.$t(successLabel, {
          commitmentName: this.commitment.name,
          organizationName: this.commitment.organization.name
        }));
        const routeName = this.isGlobalView ? 'global-commitment-list' : 'org-billing';
        this.$router.navigateBackOrDefault({ name: routeName });
      } else {
        notify.error(this.$t(errorLabel));
      }

      this.disabled = false;
      this.executing = false;
    },
    // template labels
    computeUnit(cp) {
      return this.formatProductUnit(cp.product);
    },
    computePeriodFactor(cp) {
      return (cp.product.period || "").toLocaleLowerCase() === "hour" ? HOURS_IN_MONTH : 1;
    },
    setFixedPriceTaxCode(event) {
      const taxCodeDescription = this.taxCodes.filter(t => t.value === event);
      if (!Array.isArray(taxCodeDescription) || taxCodeDescription.length === 0) {
        return;
      }
      this.commitment.fixedPriceTaxCode = {
        code: event/*,
        description: taxCodeDescription[0].description,*/
      }
    },
    selectableProduct(product) {
      const scope = this.commitment.scope;
      if (scope === 'ORGANIZATION') {
        return true;
      }

      let serviceConnection;
      if (scope === 'ENVIRONMENT') {
        const environment = this.environments.find(e => e.id == this.commitment.environment.id);
        if (!environment) {
          return false;
        }
        serviceConnection = environment.serviceConnection
      } else if (scope === 'SERVICE_CONNECTION') {
        serviceConnection = this.serviceConnections.find(connection => connection.id == this.commitment.serviceConnection.id);
      }

      if (!serviceConnection) {
        return false;
      }

      const catalog = this.productCatalogs.find(catalog => {
        return catalog.categories.some(category => category.id === product.product.categoryId);
      });
      
      let belongs = false;
      if (catalog.mode === 'SPECIFIC_CONNECTIONS') {
        belongs = catalog.connectionIds.some(connectionId => connectionId === serviceConnection.id);
      } else if (catalog.mode === 'ALL_CONNECTIONS_OF_TYPE') {
        belongs = serviceConnection.type == catalog.serviceType;
      }
      if (!belongs) {
        return false;
      }

      return (product.unitPrice || {})[this.currency] !== undefined;
    }
  },
};
</script>

<style lang="scss" scoped>
.no-products-message {
  padding-top: 20px;
}
</style>
