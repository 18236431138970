import {loadLanguageAsync, init} from '@/i18n';
import EnterpriseRegistration from './EnterpriseRegistration';
import EnterpriseRegistrationBox from './EnterpriseRegistrationBox';

export default [
  {
    path: '/enterprise-registration',
    component: EnterpriseRegistration,
    children: [
      {
        path: '',
        component: EnterpriseRegistrationBox,
      }
    ],
    async beforeEnter(to, from, next) {
      await init();
      await loadLanguageAsync('trial');
      await loadLanguageAsync('login');
      await loadLanguageAsync('notifications');
      next();
    },
  }
]
