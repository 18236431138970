import { ReadOnlyApi } from './api';

class CustomRoleMetadata extends ReadOnlyApi {
  constructor() {
    super('roles');
  }
  fetchMetadata(serviceScopeId){
    return this.get(`roles/${serviceScopeId}/metadata`);
  }
}

const permissionApi = new CustomRoleMetadata();

export default permissionApi;
