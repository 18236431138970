import { CrudApi } from './api';

class TrialsSettingsApi extends CrudApi {
  constructor() {
    super('trials_settings');
  }
}

const trialsSettingsApi = new TrialsSettingsApi();

export default trialsSettingsApi;
