<template>
  <span></span>
</template>
<script>
export default {
  name: 'BaseViewFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    simple: {
      type: Boolean,
    },
  },
};
</script>
