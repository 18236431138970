<template>
  <div
    class="tag-select"
    :class="[{ disabled, 'hide-dropdown': hideEmptyList }, size]"
  >
    <multiselect
      ref="multi"
      :disabled="disabled"
      :taggable="true"
      :modelValue="tags"
      :multiple="true"
      :selected-label="$t('selected')"
      :deselect-label="$t('deselect_tag')"
      :select-label="$t('select_tag')"
      :placeholder="$t(placeholderLabel) || $t('add_new_tag')"
      :tag-placeholder="$t(tagPlaceholderLabel)"
      label="name"
      :custom-label="getLabel"
      track-by="value"
      :class="{'error': error}"
      :options="groupedShownOptions"
      :group-values="isGrouped ? groupValues : undefined"
      :group-label="isGrouped ? groupName : undefined"
      :group-select="isGrouped && groupSelect"
      @open="setExpanded(true)"
      @close="setExpanded(false)"
      @select="addTag"
      @tag="addTag"
      @remove="removeTag"
      @search-change="search = $event"
    >
      <template #tag="props">
        <div
          class="multiselect__tag"
          :class="{ readonly: isDisabled(props.option.value) }"
        >
          {{ props.option.name }}
          <em
            v-if="!isDisabled(props.option.value)"
            aria-hidden="true"
            tabindex="0"
            class="multiselect__tag-icon"
            :class="{ system: isDisabled(props.option.value) }"
            @click="props.remove(props.option)"
          />
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'TagSelect',
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'md',
      validator: v => ['sm', 'md'].includes(v),
    },
    placeholderLabel: {
      type: String,
    },
    tagPlaceholderLabel: {
      type: String,
      default: 'create_tag',
    },
    showEmptyList: {
      type: Boolean,
      default: true,
    },
    groupSelect: {
      type: Boolean,
      default: true,
    },
    // function should group options by their label, e.g. { 'Active orgs': [org1, org2], 'Deleted orgs': [org3] }
    groupFn: {
      type: Function,
    },
  },
  emits: ['addTag', 'removeTag'],
  data() {
    return {
      search: '',
      listId: `multiselect-list-${Math.random()}`,
      observer: null,
      groupName: 'groupName',
      groupValues: 'groupValues',
    };
  },
  computed: {
    isGrouped() {
      return !!this.groupFn;
    },
    groupedShownOptions() {
      if (!this.isGrouped) {
        return this.shownOptions;
      }
      const grouped = this.groupFn(this.options);
      return Object.entries(grouped)
        .map(([groupKey, values]) => ({
          [this.groupName]: groupKey,
          [this.groupValues]: values,
        }));
    },
    shownOptions() {
      const allValues = [...this.tags, ...this.options];
      const mergedDuplicates = allValues.reduce((acc, tag) => (acc[tag.value] ?
        { ...acc, [tag.value]: { ...acc[tag.value], ...tag } } :
        { ...acc, [tag.value]: tag }), {});
      return Object.values(mergedDuplicates).filter(t => !t.disabled);
    },
    tags() {
      return (this.modelValue || [])
        .map(value => ({ value,
          name: this.getOptionLabelForValue(value) }));
    },
    hideEmptyList() {
      if (this.showEmptyList) {
        return false;
      }
      return this.options.length < 1 && this.tags.length < 1;
    },
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
  mounted() {
    const multiSelect = this.$refs.multi;
    this.setAttributesOnList();
    this.setAttributesOnListItems();
    this.setAttributesOnInput();

    const mObserver = new MutationObserver(this.handleChange);
    const list = multiSelect.$refs.list.querySelector('.multiselect__content');
    mObserver.observe(list, { childList: true, subtree: true, attributes: true, attributeFilter: ['class'] });
    this.observer = mObserver;
  },
  methods: {
    isDisabled(tag) {
      return (this.options.find(o => o.value === tag) || {}).disabled;
    },
    addTag(event) {
      return this.handleTag(event, 'addTag');
    },
    removeTag(event) {
      return this.handleTag(event, 'removeTag');
    },
    handleTag(event, eventName) {
      if (Array.isArray(event)) {
        event.forEach(t => this.$emit(eventName, t.value));
        return;
      }
      this.$emit(eventName, event.value || event);
    },
    getLabel(key) {
      if (key.is18n && key.name) {
        return this.$t(key.name);
      }
      return key.name || key.value;
    },
    getOptionLabelForValue(optionValue) {
      const option = this.options.find(o => o.value === optionValue);
      if (option) {
        return this.getLabel(option);
      }
      return optionValue;
    },
    setExpanded(isExpanded) {
      this.$refs.multi.$refs.search.setAttribute('aria-expanded', isExpanded);
    },
    setAttributesOnList() {
      const htmlList = this.$refs.multi.$refs.list.children[0];
      htmlList.setAttribute('id', this.listId);
      htmlList.setAttribute('role', 'listbox');
    },
    setAttributesOnListItems() {
      const listItems = Array.from(this.$refs.multi.$refs.list.children[0].children);
      listItems.forEach((element, idx) => {
        element.setAttribute('id', `multiselect-list-item-${idx}`);
        element.setAttribute('role', 'option');
        element.setAttribute('aria-selected', false);
      });
    },
    setAttributesOnInput() {
      const multiSelectInput = this.$refs.multi.$refs.search;
      multiSelectInput.setAttribute('role', 'combobox');
      multiSelectInput.setAttribute('aria-autocomplete', 'list');
      multiSelectInput.setAttribute('aria-controls', this.listId);
      multiSelectInput.setAttribute('aria-expanded', false);
      multiSelectInput.setAttribute('aria-aria-haspopup', 'listbox');
    },
    handleChange() {
      const multiSelect = this.$refs.multi;
      const selected = multiSelect.$el.querySelector('.multiselect__option--highlight');
      this.setAttributesOnListItems();
      if (selected) {
        selected.parentElement.setAttribute('aria-selected', true);
        multiSelect.$refs.search.setAttribute('aria-activedescendant', `${selected.parentElement.attributes.id.value}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tag-select {
  max-width: 600px;
  background-color: transparent;

  &.sm {
    :deep(.multiselect__tags) {
      font-size: 14px;
    }
  }

  &.md {
    :deep(.multiselect__tags) {
      font-size: 16px;
    }
  }

  :deep(.multiselect--disabled) {
    opacity: 1;
    background-color: transparent;
  }

  &.hide-dropdown {
    :deep(.multiselect__content-wrapper) {
      display: none;
    }
  }

  :deep(.multiselect__select) {
    border-color: var(--plain);
    background-color: transparent;
    height: 95%;
    width: 38px;

    &:before {
      border-top-color: var(--text);
    }
  }

  :deep(.multiselect__tag) {
    background: var(--green) !important;
  }

  :deep(.multiselect__tags) {
    border: 1px solid var(--input-placeholder-text);
    background-color: transparent;

    .multiselect__input {
      padding: 0 0 0 5px !important;
      position: relative !important;

      &.focus-visible {
        padding-top: 1px !important;
      }
    }

    .multiselect__placeholder {
      color: var(--input-placeholder-text);
      margin-bottom: 8px !important;
      padding-top: 0px !important;
    }

    :deep(.multiselect__tag) {
      &.readonly {
        padding: 4px 10px 4px 10px;
      }
    }
  }

  &.disabled {
    :deep(.multiselect__select) {
      border-color: var(--plain);
      background-color: var(--plain);
      border-radius: 4px;

      &:before {
        border-top-color: var(--input-placeholder-text);
      }
    }

    :deep(.multiselect__tags) {
      border: 1px solid var(--input-placeholder-text);
      background-color: var(--plain);
    }
  }
}

</style>
