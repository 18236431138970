import store from '@/store';
import Authz from '@/authz';

const match = Authz.match;

function directive(el, binding) {
  let permissions = binding.value;
  if (!permissions) {
    return;
  } else if (!(permissions instanceof Array)) {
    permissions = [permissions];
  }

  const grantsNeeded = binding.modifiers.or ? 1 : permissions.length;

  if (binding.modifiers.global) {
    permissions = permissions.map(p => `global:${p}`);
  } else if (binding.modifiers.subs) {
    permissions = permissions.map(p => `subs:${p}`);
  } else if (binding.modifiers.root) {
    permissions = permissions.map(p => `root:${p}`);
  }

  const currentPermissions = [
    ...store.getters.systemPermissions,
    ...store.getters.servicePermissions,
    ...store.getters.globalPermissions,
    ...store.getters.subsPermissions,
  ];
  if (!currentPermissions) {
    el.style.display = 'none';
    return;
  }

  const permissionsGranted = permissions
    .filter(p => match(currentPermissions, p)).length;

  if (permissionsGranted < grantsNeeded) {
    el.style.display = 'none';
  }
}

export default {
  name: 'permission',
  directive,
  beforeMount(el, binding) {
    directive(el, binding);
  },

  updated(el, binding) {
    directive(el, binding);
  },
  match,
};
