import Api from './api';

class MetricsApi extends Api {
  listMetrics(envId) {
    return this.get(`metrics/environments/${envId}`);
  }

  getCurrentMetricsData(serviceCode, environment, entityType, entityId, orgId, metricName) {
    return this.get(
      `metrics/current/${serviceCode}/${environment}/${entityType}/${entityId}`,
      { qs: { orgId, metricName } });
  }

  getMetricsDataForInterval(serviceCode,
    environment,
    entityType,
    entityId,
    metricName,
    startDate,
    endDate,
    orgId,
    size,
    unit,
    aggregationType,
    groupBy) {
    return this.get(
      `metrics/${serviceCode}/${metricName}`,
      { qs: {
        orgId,
        environment,
        entityId,
        entityType,
        startDate,
        endDate,
        size,
        unit,
        aggregationType,
        groupBy,
      },
      });
  }
}

const metricsApi = new MetricsApi();

export default metricsApi;
