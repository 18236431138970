<template>
  <ul 
    v-if="totalPages && totalPages !== 1" 
    class="pagination dashboard-section border"
  >
    <li
      class="fa fa-arrow-left"
      role="button"
      tabindex="0"
      :aria-label="$t('previous_page')"
      :class="firstPageSelected ? 'disabled' : 'clickable'"
      @keyup.enter="firstPageSelected ? undefined : pageSelected(currentPage - 1)"
      @click="firstPageSelected ? undefined : pageSelected(currentPage - 1)"
    />

    <li 
      v-if="!paginationOptions.includes(1)"
      role="button"
      tabindex="0"
      class="clickable"
      @keyup.enter="pageSelected(1)"
      @click="pageSelected(1)" 
    >
      1
    </li>
    <li v-if="!paginationOptions.includes(2)">
      ...
    </li>
    <li
      v-for="opt in paginationOptions"
      :key="opt"
      role="button"
      tabindex="0"
      :class="{ active: opt === currentPage}"
      class="clickable"
      @keyup.enter="pageSelected(opt)"
      @click="pageSelected(opt)"
    >
      {{ opt }}
    </li>
    <li v-if="!paginationOptions.includes(lastPage - 1)">
      ... 
    </li>
    <li
      v-if="!paginationOptions.includes(lastPage)"
      role="button"
      tabindex="0"
      class="clickable"
      @keyup.enter="pageSelected(lastPage)"
      @click="pageSelected(lastPage)"
    >
      {{ totalPages }}
    </li>
    <li
      class="fa fa-arrow-right"
      role="button"
      tabindex="0"
      :aria-label="$t('next_page')"
      :class="lastPageSelected ? 'disabled' : 'clickable'"
      @keyup.enter="lastPageSelected ? undefined : pageSelected(currentPage + 1)"
      @click="lastPageSelected ? undefined : pageSelected(currentPage + 1)"
    />
  </ul>
</template>

<script>
const PAGE_SET_SIZE = 5;

export default {
  name: 'PaginationBar',
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
  },
  emits: ['pageSelected'],
  computed: {
    firstPageSelected() {
      return this.pageNumber === 1;
    },
    lastPageSelected() {
      return this.pageNumber === this.totalPages;
    },
    currentPage() {
      return this.pageNumber;
    },
    lastPage() {
      return this.totalPages;
    },
    paginationOptions() {
      const number = this.pageNumber;
      const total = this.totalPages;
      const mod = number % PAGE_SET_SIZE;

      if (number < PAGE_SET_SIZE) {
        return this.firstSet(total);
      }
      if (total - PAGE_SET_SIZE > number) {
        return this.fullPageSet(number - mod);
      }
      return this.lastSet(total);
    },
  },
  methods: {
    firstSet(finalIndex) {
      const arr = [];
      for (let i = 1; i < PAGE_SET_SIZE + 1 && i <= finalIndex; i += 1) {
        arr.push(i);
      }
      return arr;
    },
    fullPageSet(startingIndex) {
      const arr = [];
      for (let i = 0; i !== PAGE_SET_SIZE; i += 1) {
        arr.push(startingIndex + i);
      }
      return arr;
    },
    lastSet(finalIndex) {
      const arr = [];
      for (let i = PAGE_SET_SIZE; i > 0; i -= 1) {
        const decrementedIndex = finalIndex - i;
        if (decrementedIndex < 0) {
          break;
        }
        arr.push(decrementedIndex + 1);
      }
      return arr;
    },
    pageSelected(page) {
      this.$emit('pageSelected', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  float: right;
  display: inline-block;
  padding: 0;
  margin: 8px 0;
  margin-top: 15px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.active {
  color: var(--panel-active-text);
}

.border {
  border: 1px solid var(--border);
  box-shadow: 3px 2px 3px -1px var(--border-shadow);
}

.pagination li {
  display: inline;
  float: left;
  padding: 8px 16px;
}
</style>
