<template>
  <base-form
    :submitLabel="submitLabel"
    :disabled="missingFields || quotaDetailsFormError"
    :defaultBack="quota.id ? {name: 'quotaDetails', params: { id: quota.id }} : { name: 'quotasList' }"
    @submit="onSubmit"
  >
    <form-row 
      label="name" 
      :error="errors.name"
    >
      <base-input v-model="quota.name" />
    </form-row>
    <form-row label="description">
      <base-input v-model="quota.description" />
    </form-row>
    <form-row label="service_connection">
      <base-select 
        :disabled="quotaInitialized" 
        :items="serviceConnectionsList" 
        :modelValue="selectedConnectionName" 
        :placeholder="$t('quotas.select_service_connection')"
        @update:modelValue="setServiceConnection" 
      />
    </form-row>
    <form-row 
      v-if="displayedQuotaDetails.length > 0" 
      label="metrics_quota_form_label"
    >
      <EditQuotaDetails 
        :metric-descriptors="metricDescriptors" 
        :quota-details="displayedQuotaDetails" 
        :initialQuotaDetails="quotaInitialized ? initialQuota.quotaDetails : []" 
        :shrinkingQuotaLimitLabel="shrinkingQuotaLimit" 
        @quotaDetailChanged="updateQuotaDetail" 
        @setUnlimitedChanged="setUnlimitedChanged" 
        @form-error="quotaDetailsFormError=$event"
      />
    </form-row>
  </base-form>
</template>
<script>

import { getConnectionIconUrl } from '@/utils/service';
import { sortBy } from '@/utils';
import { mapGetters } from 'vuex';
import EditQuotaDetails from './EditQuotaDetails';

export default {
  name: 'QuotaForm',
  components: { EditQuotaDetails },
  props: {
    initialQuota: {
      type: Object,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    submitLabel: {
      type: String,
      default: 'next',
    },
  },
  emits: ['submit'],
  data() {
    return {
      selectedConnectionName: null,
      quota: {
        name: null,
        description: null,
        ownerOrganization: {
          id: null,
        },
        serviceConnection: {
          id: null,
        },
        defaultForService: false,
        defaultForTrial: false,
        quotaDetails: [],
      },
      quotaDetailsFormError: false,
      displayedQuotaDetails: [],
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'managedServiceConnections',
      'serviceDescriptors',
    ]),
    quotaInitialized() {
      return !!this.initialQuota;
    },
    missingFields() {
      const name = this.quota.name;
      return (name == null || name.trim().length === 0 || this.serviceConnection == null);
    },
    serviceConnectionsList() {
      return this.managedServiceConnections
        .filter(
          sc => this.serviceDescriptors[sc.type] &&
                this.serviceDescriptors[sc.type].supportsQuota &&
                this.serviceDescriptors[sc.type].quotaMetricIdentifiers.length > 0,
        )
        .map(sc => ({
          id: sc.id,
          name: sc.name,
          value: sc.name,
          display: sc.name,
          image: getConnectionIconUrl(sc),
        }))
        .sort(sortBy(conn => conn.name));
    },
    serviceConnection() {
      return this.managedServiceConnections.find(sc => sc.name === this.selectedConnectionName) || null;
    },
    serviceDescriptor() {
      return this.serviceConnection ?
        this.serviceDescriptors[this.serviceConnection.type] :
        { metricDescriptors: [] };
    },
    metricDescriptors() {
      return this.serviceDescriptor.metricDescriptors.filter(md =>
        this.serviceDescriptor.quotaMetricIdentifiers.includes(md.metricIdentifier),
      );
    },
    quotaDetails() {
      return this.displayedQuotaDetails;
    },
    initialQuotaCeilingByMetricId() {
      return (this.initialQuota || { quotaDetails: [] }).quotaDetails.reduce((acc, qd) => {
        acc[qd.metricIdentifier] = qd.ceiling;
        return acc;
      }, {});
    },
    shrinkingQuotaLimit() {
      const quotaShrinking = this.quotaDetails.some((qd) => {
        const ceilingBefore = this.initialQuotaCeilingByMetricId[qd.metricIdentifier] === -1 ?
          Number.MAX_VALUE :
          this.initialQuotaCeilingByMetricId[qd.metricIdentifier];

        const ceilingAfter = qd.ceiling === null ? Number.MAX_VALUE : qd.ceiling;
        return ceilingBefore > ceilingAfter;
      });
      if (quotaShrinking) {
        return 'quotas.shrinking_quota_limit';
      }
      return '';
    },
  },
  async created() {
    if (this.initialQuota) {
      Object.assign(this.quota, this.initialQuota);
      this.quota.quotaDetails = this.quota.quotaDetails.map(qd => ({
        id: qd.id,
        ceiling: qd.ceiling === -1 ? null : qd.ceiling,
        metricIdentifier: qd.metricIdentifier,
      }));
      this.setServiceConnection(this.quota.serviceConnection.name);
    }
  },
  methods: {
    onSubmit() {
      this.quota.serviceConnection.id = this.serviceConnection.id;
      this.quota.ownerOrganization.id = this.selectedOrganization.id;
      this.quota.quotaDetails = this.displayedQuotaDetails;
      this.$emit('submit', this.quota);
    },
    async setServiceConnection(newSelectedConnectionName) {
      this.selectedConnectionName = newSelectedConnectionName;
      this.displayedQuotaDetails = this.createQuotaDetails();
    },
    createQuotaDetails() {
      return this.quota.quotaDetails.length > 0 ? this.quota.quotaDetails :
        this.metricDescriptors.map(md => ({
          metricIdentifier: md.metricIdentifier,
          ceiling: null,
        }),
        );
    },
    updateQuotaDetail(metricIdentifier, value) {
      const qd = this.quotaDetails.find(q => q.metricIdentifier === metricIdentifier);
      qd.ceiling = value || 0;
    },
    setUnlimitedChanged(metricIdentifier, unlimited) {
      const qd = this.quotaDetails.find(q => q.metricIdentifier === metricIdentifier);
      qd.ceiling = unlimited ? null : 0;
    },
  },
};
</script>
