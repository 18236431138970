<template>
  <base-loader v-if="loading"></base-loader>
  <div 
    v-else 
    class="box-container"
  >
    <base-details 
      :summaryColumns="summaryColumns" 
      name=""
    >
      <template #identity>
        <app-logo 
          class="rectangular-logo checkered-bg" 
          :src="logoSrc" 
          :altText="$t('branding.full_logo')"
        />
      </template>
      <template #rhs>
        <action-icon 
          v-protected="toEdit"
          icon="fa fa-edit" 
          :expandOnHover="true" 
          tooltipLabel="edit branding"
          :to="toEdit"
        />
        <BrandingActions
          :branding="branding" 
          @deleted="$emit('deleted')"
        />
      </template>
      <div>
        <language-indicator></language-indicator>
      </div>
      <template #details>
        <form-row>
          <div class="label">
            {{ $t("branding.colors_css") }}
          </div>
          <pre 
            v-if="colors"
            v-highlightjs 
          >
            <code class="css">{{ colors }}</code>
          </pre>
          <pre v-else>{{ $t('branding.colors_css_not_configured') }}</pre>
        </form-row>
        <form-row
          label="branding.graph_colors"
        >
          <ColorSwatch
            ref="graphColors"
            v-model="readGraphColors"
            :readonly="true"
          />
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("branding.custom_css") }}
          </div>
          <pre
            v-if="customCss"
            v-highlightjs
          >
            <code class="css">{{ customCss }}</code>
          </pre>
          <pre v-else>{{ $t('branding.css_not_configured') }}</pre>
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("branding.custom_icons") }}
          </div>
          <pre 
            v-if="branding.customIcons"
            v-highlightjs 
          >
            <code class="json">{{ branding.customIcons }}</code>
          </pre>
          <pre v-else>{{ $t('branding.custom_icons_not_configured') }}</pre>
        </form-row>
      </template>
    </base-details> 
  </div>
</template>

<script>
import { getLanguages } from '@/i18n';
import 'highlight.js/styles/github.css';
import { mapGetters } from 'vuex';
import { DEFAULT_DATE_FORMAT, format, getNow } from '@/utils/dates';
import AppLogo from '@/components/AppLogo';
import BrandingActions from './BrandingActions';
import ColorSwatch from './ColorSwatch';
import {sortBy} from "@/utils";
import {getColorHex, GRAPH_COLORS } from "@/components/nextgen/charts/branding";

export default {
  name: 'BrandingDetail',
  components: { BrandingActions, ColorSwatch, AppLogo },
  props: {
    branding: Object,
  },
  emits: ['deleted'],
  data() {
    return {
      loading: false,
      activeLanguages: this.branding ? this.branding.activeLanguages.split(',') : null,
      defaultLanguage: this.branding ? this.branding.defaultLanguage : '',
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization', 'userContext']),
    summaryColumns() {
      return [{
        label: 'branding.compact_logo',
        logo: this.squareLogoSrc,
      },
      {
        label: 'branding.application_name',
        value: this.branding.applicationName
      },
      {
        label: 'branding.languages',
        value: this.activeLanguagesLabel,
      },
      {
        label: 'branding.default_language',
        value: this.defaultLanguageLabel,
      }];
    },
    logoSrc() {
      return `/rest/appearance/logo?org=${this.selectedOrganization.entryPoint}&t=${format(getNow(), DEFAULT_DATE_FORMAT)}`;
    },
    toEdit() {
      return { name: 'edit', params: { branding: this.branding } };
    },
    squareLogoSrc() {
      return `/rest/appearance/logo_square?org=${this.selectedOrganization.entryPoint}&t=${format(getNow(), DEFAULT_DATE_FORMAT)}`;
    },
    langs() {
      return this.languages.map(l => l.value)
        .reduce((acc, curr) => {
          acc[curr] = curr;
          return acc;
        }, {});
    },
    defaultLanguageLabel() {
      return this.languages.filter(l => l.value === this.defaultLanguage).map(l => l.name)[0];
    },
    activeLanguagesLabel() {
      return this.languages.filter(l => this.activeLanguages.includes(l.value)).map(l => l.name).join(", ");
    },
    artifacts() {
      return (((this.branding || {}).artifacts) || []);
    },
    colors() {
      const colors = (this.artifacts.find(a => a.name === 'colors.css') || {}).content;
      const decodedColors = !colors ? '' : atob(colors);
      const rootSelector = ':root{';
      let trimmedColor = decodedColors.trim();
      if (trimmedColor.startsWith(rootSelector)) {
        // We remove an additional character at the end to remove the closing bracket
        trimmedColor = trimmedColor
          .substring(rootSelector.length, trimmedColor.length - 2)
          .trim();
      }
      return trimmedColor;
    },
    readGraphColors() {
      let graphColors = this.branding?.styles?.filter(style => style.name.startsWith('graph')).sort(sortBy(s => s.name)).map(s => s.value) || [];
      if (graphColors.length === 0) {
        graphColors = GRAPH_COLORS.map(c => getColorHex(c))
      }
      return graphColors;
    },
    customCss() {
      const custom = (this.artifacts.find(a => a.name === 'custom.css') || {}).content;
      return !custom ? null : atob(custom);
    },
  },
  async created() {
    this.loading = true;
    await this.loadLanguages();
    this.loading = false;
  },
  methods: {
    async loadLanguages() {
      this.languages = getLanguages().map(l => ({
        value: l,
        label: this.$t(`languages.${l}.long`),
        name: this.$t(`languages.${l}.long`),
      }));
    },
  },
};

</script>
<style lang="scss" scoped>

.rectangular-logo {
    max-width: 250px;
    max-height: 100px;
    height: auto;
    width: auto;
    display: flex;
}

.square-logo {
  max-height: 40px;
}

.inlinify > * {
  display: inline;
}

.box-container {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}

</style>
