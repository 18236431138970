import Api from './api';

class HiddenApi extends Api {
  getOrganizationInfo() {
    return this.get('hidden/organizationinfo');
  }

  getOrganizationInfoForEntryPoint(entryPoint) {
    return this.get(`hidden/organizationinfo/${entryPoint}`);
  }

  getBrandingInfo() {
    return this.get('brandinginfo');
  }

  getEffectiveBrandingInfo(entryPoint) {
    return this.get('brandinginfo', { qs: { org: entryPoint } });
  }

  getLanguages() {
    return this.get('languages');
  }

  getJsonAsset(file) {
    return this.sfetch(`/assets/json/${file}.json`, { method: 'GET' });
  }

  getTimezones() {
    return this.getJsonAsset('TimeZones');
  }

  getTimezoneCountries() {
    return this.getJsonAsset('TzCountries');
  }

  getChasePaymentMetadata(org) {
    return this.get('hidden/chasepaymenttech/metadata', { qs: { organization_id: org } });
  }

  getChasePaymentForm(org, locale, isEdit) {
    return this.get('hidden/chasepaymenttech', { qs: { organization_id: org, locale, is_edit: isEdit } });
  }

  registerCreditCard(org, uid) {
    return this.get('hidden/chasepaymenttech/register', { qs: { organization_id: org, uid } });
  }

  updateCreditCard(org, uid) {
    return this.get('hidden/chasepaymenttech/update', { qs: { organization_id: org, uid } });
  }

  getChasePaymentMetadataFromToken(token) {
    return this.get('hidden/chasepaymenttech/metadataFromToken', { qs: { token } });
  }

  getChasePaymentFormFromToken(token, locale) {
    return this.get('hidden/chasepaymenttech/formFromToken', { qs: { token, locale } });
  }

  registerCreditCardFromToken(token, uid) {
    return this.get('hidden/chasepaymenttech/registerFromToken', { qs: { token, uid } });
  }

  search(organizationId, includeHierarchy = false) {
    return this.get('reseller/settings/top_navigation/search', {
      qs: {
        organizationId,
        includeHierarchy,
      },
    });
  }
  
  getStripeCheckoutUrl(orgId, isEdit) {
    return this.get('hidden/stripe/checkout_session', { qs: { organization_id: orgId, is_edit: isEdit}});
  }

  getStripeCheckoutUrlFromToken(token) {
    return this.get('hidden/stripe/checkout_session_from_token', { qs: { token }});
  }
}

const hiddenApi = new HiddenApi();

export default hiddenApi;
