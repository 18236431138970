<template>
  <base-loader v-if="loading" />
  <commitment-form
    v-else
    :sourceCommitment="commitment"
    :organizations="filteredOrganizations"
  />
</template>

<script>
import apis from '@/utils/apis';
import { sortBy } from '@/utils';
import CommitmentForm from '@/app/Main/components/commitments/CommitmentForm';

export default {
  name: 'EditCommitment',
  components: { CommitmentForm },
  data() {
    return {
      commitment: {},
      organizations: [],
      administrableOrgs: [],
      loading: true,
    };
  },
  computed: {
    filteredOrganizations() {
      return this.organizations
        .filter(o => o.isBillable)
        .filter(o => Object.keys(this.administrableOrgs).includes(o.id))
        .sort(sortBy(q => q.name));
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.fetchCommitment(),
      this.fetchOrganizations(),
      this.fetchAdministableOrgs(),
    ]);
    this.loading = false;
  },
  methods: {
    async fetchCommitment() {
      const resp = await apis.commitments.find(this.$route.params.commitmentId);
      if (resp.ok) {
        this.commitment = resp.data
      }
    },
    async fetchOrganizations() {
      const resp = await apis.organizations.list({ qs: { detailed: false } });
      if (resp.ok) {
        this.organizations = resp.data;
      }
    },
    async fetchAdministableOrgs() {
      const resp = await apis.organizations.administrableOrgs();
      if (resp.ok) {
        this.administrableOrgs = resp.data;
      }
    },
  },
};
</script>
