<template>
  <div class="redirecting-to-stripe">
    <h1>{{ $t('stripe_checkout.redirect') }}</h1>
    <base-loader></base-loader>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import notify from '@/utils/notify';
import apis from '@/utils/apis';
export default {
  name: 'RedirectingPage',
  props: {
    trialToken: {
      type: String,
    },
    getCheckoutFromToken: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
  },
  async created() {
    await this.getCheckoutSession();
  },
  methods: {
    async getCheckoutSession() {
      const response = this.getCheckoutFromToken ? await apis.hidden.getStripeCheckoutUrlFromToken(this.trialToken) : 
        await apis.hidden.getStripeCheckoutUrl(this.selectedOrganization.id, this.isEdit);

      if (response && response.ok && response.status === 200) {
        window.location.href = response.data;
      } else {
        notify.error(this.$t('stripe_checkout.error_get_checkout_session'));
      }
    },
  },
};
</script>
  
  
<style lang="scss" scoped>
@import '@/styles/mixins';

.redirecting-to-stripe {
  padding: 20px;
}

</style>
