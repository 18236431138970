import md5 from 'blueimp-md5';
import rfdc from 'rfdc';
const absoluteUrlRegex = /^https?:\/\//i;
const ASCII_PERIOD = '%2E';
const PERIOD = '.';
const gravatarUrl = (email, size, gravatarDefault = 'blank') => `https://www.gravatar.com/avatar/${md5(email)}?s=${size}&d=${gravatarDefault}`;
const clone = rfdc();

function flatten(obj, path = '') {
  if (!obj || Object.keys(obj).length === 0) {
    return {};
  }
  return Object.keys(obj)
    .reduce((memo, key) => {
      const newPath = path ? `${path}.${key}` : key;
      if (typeof obj[key] === 'object') {
        Object.assign(memo, flatten(obj[key], newPath));
      } else {
        memo[newPath] = obj[key];
      }
      return memo;
    }, {});
}

const makeSafe = (unsafe) => {
  if (unsafe || unsafe === 0) {
    return unsafe.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }
  return '';
};

const boldify = obj => Object.assign({}, ...Object.keys(obj).map(k => ({ [k]: `<strong>${makeSafe(obj[k])}</strong>` })));

const localeCompare = fn => (e1, e2) => fn(e1).localeCompare(fn(e2));

const optionalCompare = (fn, inv) => (e1, e2) => {
  if (!e1 || !e2) {
    return 0;
  }

  const c1 = fn(e1);
  const c2 = fn(e2);
  if (!c1 || !c2) {
    return 0;
  }

  return inv * c1.localeCompare(c2);
};

const desc = fn => optionalCompare(fn, -1);
const asc = fn => optionalCompare(fn, +1);

const isAbsoluteUrl = url => absoluteUrlRegex.test(url);

function sortBy(transform, sortOrder = 'asc') {
  const orderFactor = sortOrder === 'desc' ? -1 : 1; 
  return (a, b) => {
    const aVal = transform(a);
    const bVal = transform(b);
    if (aVal < bVal) return -1 * orderFactor;
    if (aVal > bVal) return 1 * orderFactor;
    return 0;
  };
}

function debounce(fn, waitTimeMillis) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), waitTimeMillis);
  };
}

/*
 * A delayTimer holds a promise which resolves after holdTime.
 * It also holds a cancel function. Cancelling a delay timer causes
 * rejection of the promise.
 */

function delayTimer(holdTime) {
  let handle;

  const promise = new Promise((resolve, reject) => {
    const timeout = setTimeout(() => resolve(), holdTime);
    handle = {
      timeout,
      cancel() {
        clearTimeout(timeout);
        reject();
      },
    };
  });

  handle.promise = promise;
  return handle;
}

// Replaces an async function with a version which debounces multiple calls
// made within a brief period of time.
// Note that calls with different arguments are considered to still be the same thing.
function debounceAsync(fn, holdTimeMillis) {
  // The timer which holds the currently waiting call.
  // This variable is trapped by the returned closure, to persist it between calls to
  // the debounced function.
  let timer = { cancel: () => null };

  return (...fnArgs) => {
    // Cancel the previous timer.
    // The previous call now aborts without having done anything.
    // If there wasn't a previous promise, or the previous promise is already over,
    // JS just ignores the irrelevant rejection and proceeds.
    timer.cancel();

    // Create a delayTimer to delay execution by holdTimeMillis.
    // During this time, another call to the debounced function will abort this call.
    timer = delayTimer(holdTimeMillis);

    // If the timer resolves (we were the most recent call), call the requested function.
    // If the timer rejects (we were aborted), the request was unnecessary.
    return timer.promise.then(() => fn(...fnArgs), () => Promise.resolve());
  };
}

const randomString = () => Math.random().toString(36).substr(2, 8);

const randomBetween = (min, max) => Math.floor((Math.random() * ((max - min) + 1)) + min);

// Taken from https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const searchFilter = (term, fn) => {
  const lcTerm = removeAccents(term || '').toLowerCase();
  return entry => !!fn(entry)
    .map(v => `${v || ''}`)
    .map(removeAccents)
    .filter(v => v.toLowerCase().includes(lcTerm)).length;
};

// getQueryFrom map expects an object with string values or array of strings
// In the case of array of strings the query string will have multiple query arguments
// with the same key.
// Eg. { 'foo' : ['bar', 'baz']} will result in foo=bar&foo=baz
const getQueryFromMap = (queryMap) => {
  const qs = Object.assign({}, queryMap);

  const getQueryFromArray = (queryKey, values) => values.map(v => `${queryKey}=${v}`).join('&');

  const encodeValueToURI = (k) => {
    if (qs[k] instanceof Array) {
      return getQueryFromArray(k, qs[k]);
    }
    return `${encodeURIComponent(k)}=${encodeURIComponent(qs[k])}`;
  };

  return Object.keys(qs)
    .filter(k => qs[k] !== undefined && qs[k] !== null && qs[k] !== '')
    .map(k => encodeValueToURI(k))
    .join('&');
};

const getQueryFromTuples = (queryTuples) => {
  const qs = queryTuples.slice();
  return qs
    .filter(([, v]) => v !== undefined && v !== null && v !== '')
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');
};

const getPathWithQueryParams = (path, qs) => {
  let query = '';

  if (qs instanceof Array) {
    query = getQueryFromTuples(qs);
  } else {
    query = getQueryFromMap(qs);
  }

  const queryStr = query.length > 0 ? `?${query}` : '';
  return `${path}${queryStr}`;
};

const isMobileMode = () => document.documentElement.clientWidth <= 480;

const isTabletMode = () => document.documentElement.clientWidth > 480 && document.documentElement.clientWidth <= 1152;

const buildUrlWithEntryPoint = (entryPoint) => {
  const domains = window.location.host.split('.');
  domains[0] = entryPoint;
  return `${window.location.protocol}//${domains.join('.')}`;
};

function getArrayIndexFromString(fieldsString) {
  const startIdx = fieldsString.indexOf('[');
  const endIdx = fieldsString.indexOf(']');
  let objectIndex = -1;
  if (startIdx !== -1) {
    objectIndex = fieldsString.substring((startIdx + 1), endIdx);
    return objectIndex;
  }
  return undefined;
}

/**
 * Takes in an object which has array elements and ensures the fields in the originalObject match
 * the array fields of this object.
 * @param {object} ob the object to clean
 * @param {object} originalObject the original entity from which redundant fields are to be removed
 */
function getCleanedObject(ob, originalObject) {
  const originalEntityFields = Object.keys(originalObject);
  const entityKeys = Object.keys(ob);
  entityKeys.forEach((i) => {
    if (typeof ob[i] === 'object') {
      if (Array.isArray(ob[i])) {
        const arrayObj = ob[i];
        const arraySize = arrayObj.length;
        const toFind = `${i}[`;
        const hasArrFields = [];
        originalEntityFields.forEach((field) => {
          if (field.includes(toFind)) {
            hasArrFields.push(field);
          }
        });
        if (hasArrFields.length > 0) {
          /**
           * this means that there are fields matching an array attribute of the entity
           * need to ensure that when flattened there are only as many array-fields as the array
           * size, hence removing redundant fields
           *  */
          hasArrFields.forEach((matchEntry) => {
            const matchIdx = getArrayIndexFromString(matchEntry);
            if (matchIdx >= arraySize) {
              delete originalObject[matchEntry];
            }
          });
        }
      } else {
        getCleanedObject(ob[i], originalObject);
      }
    }
  });
  return originalObject;
}

/**
 * Between reload events the entity might hold flat-fields (representing form-elements to
 * entity-attributes mapping) from the previous state. Thus, this method cleans up the redundant
 * dangling fields that dont match any attribute of the entity. This happens only for fields
 * denoting array elements (ex: vnfr.connections[0].name). Thus, they are the ones to be examined.
 * The other fields (on-array) are cleaned by the watch Vue event.
 * @param {object} ob the entity that needs to be cleaned
 */
const recalculateEntityFieldsOnReload = ob => getCleanedObject(ob, ob);

const arrayContainsPrimitives = arr => !arr.find(a => a instanceof Object || Array.isArray(a));

/**
 * Given an object we want to encode all keys it contains
 * as well as all keys in any objects or arrays of objects
 * that are values of the given object.
 *
 * @param {object} obj
 * @param {String} replaceKey The string to be replaced in the object's keys
 * @param {String} replacementKey The string to substitute in the object's keys
 */
function replaceInObjectKeys(obj, replaceKey, replacementKey) {
  const toReturn = {};
  Object.keys(obj).forEach((key) => {
    const encodedKey = key.split(replaceKey).join(replacementKey);

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // If the key's corresponding value is an object encode the keys of that object as well
      const flatObject = replaceInObjectKeys(obj[key], replaceKey, replacementKey);
      toReturn[encodedKey] = flatObject;
    } else if (Array.isArray(obj[key]) && !arrayContainsPrimitives(obj[key])) {
      const arr = obj[key];
      const isEdgeArray = (typeof arr[0] !== 'object');
      if (isEdgeArray) {
        // If there is no nested object just return the array as is
        toReturn[encodedKey] = arr;
      } else {
        // If the array is an array of objects encode all the keys of the objects contained in
        // the array entries
        const objArray = [];
        for (let idx = 0; idx < arr.length; idx += 1) {
          objArray.push(replaceInObjectKeys(arr[idx], replaceKey, replacementKey));
        }
        toReturn[encodedKey] = objArray;
      }
    } else { // Value does not contain an array or object, return the value as is
      toReturn[encodedKey] = obj[key];
    }
  });
  return toReturn;
}

/**
 * Given an object this method flattens all its nested inner objects into first-class keys.
 * The returned object will only have <key>:<value> combinations where no value will be an object.
 * @param {Object} ob         the entity object to be flattened
 * @param {Array} exceptions  list of first-class fields to be excluded from flattening
 */
const flattenObjWithPeriodDelimiter = (ob, exceptions = []) => {
  const toReturn = {};
  Object.keys(ob).forEach((i) => {
    if (exceptions.includes(i)) {
      toReturn[i] = ob[i];
      return;
    }
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const flatObject = flattenObjWithPeriodDelimiter(ob[i]);
      Object.keys(flatObject).forEach((x) => {
        toReturn[`${i}.${x}`] = flatObject[x];
      });
    } else if (Array.isArray(ob[i]) && !arrayContainsPrimitives(ob[i])) {
      const arr = ob[i];
      const isEdgeArray = (typeof arr[0] !== 'object');
      for (let idx = 0; idx < arr.length; idx += 1) {
        if (isEdgeArray) {
          toReturn[`${i}[${idx}]`] = arr[idx];
        } else {
          const flatObject = flattenObjWithPeriodDelimiter(arr[idx]);
          Object.keys(flatObject).forEach((x) => {
            toReturn[`${i}[${idx}].${x}`] = flatObject[x];
          });
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  });
  return toReturn;
};

/**
 * We replace all period with their ASCII representation, %2E
 *
 * @param {Object} obj Object to be encoded
 */
function encodeObjectKeys(obj) {
  return replaceInObjectKeys(obj, PERIOD, ASCII_PERIOD);
}

/**
 * Flatten object first takes an object, encodes all the periods in its' keys
 * to their ascii representation %2E. It does this because periods are used as
 * the delimiters for flattened fields on the subsequent flattening and on unflattenning
 * it is impossible to distinguish what is a period that was originally contained
 * in an object key and could warrant in the wrong object structure being reconstructed.
 *
 * Eg: An object with structure
 * { "example.com": "website" }
 * would be reconstructed as the following if not encoding was done:
 * {
 *  "example":
 *   {"ok": "website"}
 * }
 *
 * After the object keys have been encoded then we proceed to flatten the object to
 * an object with a single level of keys/values.
 *
 * @param {Object} ob         Object to be flattened
 * @param {Array} exceptions  list of first-class fields to be excluded from flattening
 */
const flattenObject = (ob, exceptions = []) => {
  const encodedObj = encodeObjectKeys(ob);
  return flattenObjWithPeriodDelimiter(encodedObj, exceptions);
};

/**
 * We replace all %2E found in the object and its descendants
 * with .
 *
 * @param {Object} obj Object to be decoded
 */
function decodeObjectKeys(obj) {
  return replaceInObjectKeys(obj, ASCII_PERIOD, PERIOD);
}

/**
 * returnNestedObjectField takes in a path to a field on an object as well as an
 * object and returns that field if present. If not present it returns null.
 *
 * The fields can be specified as arrays or as string with fields seperated with periods.
 * If the path is neither a string or array null is returned immediately.
 *
 * Eg: If you want to return the object's "labels" map nested inside metadata
 * you can pass as an array ["metadata", "labels"] or the string "metadata.labels".
 * {
 * "metadata": {
 *   "labels": {
 *     "foo": "bar",
 *     "baz": "buzz"
 *    }
 *  }
 *}
 *
 * @param {*} pathInObj The path to the target value in the object
 * @param {*} obj The object you want to extract the value from.
 */
function returnNestedObjectField(pathInObj, obj) {
  let arrayPath = pathInObj;
  if (typeof pathInObj === 'string') {
    arrayPath = pathInObj.split('.');
  }
  if (!Array.isArray(arrayPath)) {
    return null;
  }
  const get = (p, o) =>
    p.reduce((xs, x) => ((xs && (xs[x] || xs[x] === 0 || xs[x] === false)) ? xs[x] : null), o);
  return get(arrayPath, obj);
}

/**
 * Given an object and its flattened version, this method fills the flattened object with the keys
 * from the original object which are objects/arrays themselves. This is necessary since some of
 * these fileds are used as properties passed into inner vue components.
 * @param {object} flatOb A flat object which was returned from the {@method flattenObject} method
 * @param {object} originalOb The original object which was flattened to the above {@param flatOb}
 */
const fillOriginalFields = (flatOb, originalOb) => {
  Object.keys(originalOb).forEach((key) => {
    if (typeof originalOb[key] === 'object' || Array.isArray(originalOb[key])) {
      flatOb[key] = originalOb[key];
    }
  });
  return flatOb;
};

function getParentScrollElement(elm) {
  if (['scroll', 'auto'].includes(getComputedStyle(elm).overflowY)) {
    return elm;
  } else if (elm.parentNode) {
    return getParentScrollElement(elm.parentNode);
  }
  return window;
}

/**
 * Checks if two Arrays or Objects are equal in terms of the items they have
 * @param {Array | Object} value an array or object that needs to be compared to the other
 * @param {Array | Object} other an array or object that needs to be compared to the other
 */
function isEqual(value, other) {
  const type = Object.prototype.toString.call(value);
  if (type !== Object.prototype.toString.call(other)) return false;
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  function compare(item1, item2) {
    const itemType = Object.prototype.toString.call(item1);
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) {
        return false;
      }
    }
    return true;
  }

  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i += 1) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        if (!compare(value[key], other[key])) {
          return false;
        }
      }
    }
  }
  return true;
}

function copy(obj) {
  return clone(obj);
}

/**
 * Searches the specified array with the supplied comparator using the
 * binary search algorithm.
 *
 * The array must be sorted prior to making this call. If it
 * is not sorted, the results are undefined.
 *
 * If the array contains multiple elements with the specified value,
 * there is no guarantee which one will be found.
 *
 * @return index of the search key, if it is contained in the array;
 *         otherwise, (-(insertion point) - 1).
 *         The insertion point is defined as the point at which the
 *         key would be inserted into the array: the index of the first
 *         element greater than the key, or a.length if all
 *         elements in the array are less than the specified key. Note
 *         that this guarantees that the return value will be >=0 if
 *         and only if the key is found.
 */
const binarySearch0 = (a, fromIndex, toIndex, c) => {
  let low = fromIndex;
  let high = toIndex - 1;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const cmp = c(a[mid]);
    if (cmp < 0) {
      low = mid + 1;
    } else if (cmp > 0) {
      high = mid - 1;
    } else {
      return mid; // key found
    }
  }
  return -(low + 1); // key not found.
};


/**
 * With Vue 3, the use of $el is recommended against
 * Also in Vue 3, components are no longer limited at only 1 root element.
 * Implicitly, this means you no longer have an $el.
 * We need to get the nextElementSibling of the $el instead.
 */
const getElement = (element) => {
  if (element.$el instanceof Text) {
    return element.$el.nextElementSibling
  }
  return element.$el;
}

const binarySearch = (a, c) => binarySearch0(a, 0, a.length, c);

const encodeSpecialCharacters = (s) => {
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
  if(s && format.test(s)){
   return encodeURIComponent(s);
  }
  return s;
}

export {
  gravatarUrl,
  boldify,
  flatten,
  localeCompare,
  desc,
  asc,
  sortBy,
  copy,
  isAbsoluteUrl,
  debounce,
  debounceAsync,
  randomString,
  randomBetween,
  searchFilter,
  encodeObjectKeys,
  decodeObjectKeys,
  getPathWithQueryParams,
  isMobileMode,
  isTabletMode,
  getQueryFromMap,
  buildUrlWithEntryPoint,
  recalculateEntityFieldsOnReload,
  flattenObject,
  returnNestedObjectField,
  fillOriginalFields,
  getParentScrollElement,
  isEqual,
  binarySearch,
  getElement,
  encodeSpecialCharacters
};
