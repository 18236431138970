<template>
  <p>
    {{ $t('organization_currency_change.currency_change_modal.commitments.description') }}
  </p>

  <base-loader v-if="!commitments"></base-loader>
  <p v-else-if="commitments.length == 0">
    <b>{{ $t('organization_currency_change.currency_change_modal.commitments.no_commitment_affected') }}</b>
  </p>
  <base-list
    v-else
    aria-labelledby="commitments"
    :ariaRowCount="commitments.length"
    :list="commitments"
  >
    <list-header>
      <list-column>{{ $t('commitment') }}</list-column>
      <list-column>{{ $t('monetization.start_date') }}</list-column>
      <list-column>{{ $t('monetization.end_date') }}</list-column>
      <list-column>{{ $t('organization_currency_change.currency_change_modal.commitments.action.title') }}</list-column>
      <list-column>{{ $t('organization_currency_change.currency_change_modal.commitments.action.termination_date') }}</list-column>
    </list-header>
    <list-row
      v-for="commitment in commitments"
      :key="commitment.id"
    >
      <list-column>{{ commitment.name }}</list-column>
      <list-column>{{ $date((commitment || {}).startDate, 'YYYY-MM-DD', true) }}</list-column>
      <list-column>{{ commitmentEndDate(commitment) }}</list-column>
      <list-column>{{ commitmentAction(commitment) }}</list-column>
      <list-column>{{ commitmentActionEffectiveDate(commitment) }}</list-column>
    </list-row>
  </base-list>
</template>
<script>

import { mapGetters } from 'vuex';
import BaseLoader from "@/components/BaseLoader.vue";
import apis from "@/utils/apis";
import {subDays} from "@/utils/dates";

export default {
  name: 'OrgCurrencyChangeCommitmentList',
  components: {BaseLoader},
  mixins: [],
  props: {
    orgId: {
      type: String,
      required: true
    },
    lastClosedCycle: {
      type: Object,
      required: false,
      default: undefined
    },
    currentOpenCycle: {
      type: Object,
      required: false,
      default: undefined
    },
  },
  data() {
    return {
      commitments: undefined
    };
  },
  computed: {
      ...mapGetters(['userBrandingInfo', 'locale', 'selectedOrganization'])
  },
  async created() {
      await this.loadCommitments();
  },
  methods: {
    async loadCommitments() {
      const resp = await apis.commitments.list({ qs: { organization_id: this.orgId } });
      if (resp.data) {
        this.commitments = resp.data
          .filter(c => !c.terminated)
          // Ignore commitments that already ended.
          .filter(c => {
            const commitmentEndTime = c.endDate ? new Date(c.endDate).getTime() : undefined;
            const lastClosedCycleEndTime = this.lastClosedCycle ? new Date(this.lastClosedCycle.endDate).getTime() : undefined;

            return commitmentEndTime == undefined
              || lastClosedCycleEndTime == undefined
              || commitmentEndTime > lastClosedCycleEndTime
          })
      }
    },
    commitmentEndDate(commitment) {
      if (!commitment || !commitment.endDate) {
        return this.$t("monetization.none");
      }
      const ed = subDays(commitment.endDate, 1);
      return this.$date(ed, 'YYYY-MM-DD', true);
    },
    canDeleteCommitment(commitment) {
      return this.lastClosedCycle == undefined || !this.wasCommitmentInvoicedInBillingCycle(commitment, this.lastClosedCycle);
    },
    commitmentAction(commitment) {
      return this.canDeleteCommitment(commitment)
        ? this.$t("organization_currency_change.currency_change_modal.commitments.action.delete")
        : this.$t("organization_currency_change.currency_change_modal.commitments.action.terminate")
    },
    commitmentActionEffectiveDate(commitment) {
      if (this.canDeleteCommitment(commitment)) {
        return '-'
      }

      // If there is no current open billing cycle, we can terminate the commitment at the last closed billing cycle end date.
      if (this.currentOpenCycle == undefined) {
        return this.$date(this.lastClosedCycle.endDate, 'YYYY-MM-DD', true);
      }

      // We have to decide if we terminate the commitment at its end date or and the end date of the open billing cycle.
      const commitmentEndTime = commitment.endDate ? new Date(commitment.endDate).getTime() : undefined;
      const openCycleEndTime = new Date(this.currentOpenCycle.endDate).getTime()
      if (commitmentEndTime == undefined || commitmentEndTime > openCycleEndTime) {
        return this.$date(new Date(this.currentOpenCycle.endDate) - 1, 'YYYY-MM-DD', true);
      } else {
        return this.$date(new Date(commitment.endDate) - 1, 'YYYY-MM-DD', true);
      }
    },
    wasCommitmentInvoicedInBillingCycle(commitment, billingCycle) {
      if (billingCycle.commitments == undefined) {
        return false;
      }

      return billingCycle.commitments
        .filter(c => c.scope == 'INVOICE')
        .filter(c => c.commitment.id == commitment.id)
        .length > 0
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/styles/mixins";
</style>
