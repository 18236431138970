<!-- eslint-disable vue/no-template-shadow -->
<template>
  <base-list 
    ariaLabel="organizations" 
    :errorLabel="errorLabel" 
    :ariaRowCount="!!search ? filteredOrgs.length : organizations.length"
  >
    <slot></slot>
    <template v-if="!errorLabel">
      <template v-if="!!search || flat">
        <OrganizationHierarchyRow
          v-for="org in filteredOrgs" 
          :key="org.id" 
          :organization="org" 
          flat 
          shown 
          :navigation="navigation"
          :organizationIdToOrganization="organizationIdToOrganization"
          @onMouseOver="onMouseOver"
          @onMouseExit="onMouseExit"
        >
          <template #columns="{ org }">
            <slot 
              name="columns" 
              :org="org"
            ></slot>
          </template>
          <template #actions="{ org }">
            <slot 
              name="actions" 
              :org="org"
            ></slot>
          </template>
        </OrganizationHierarchyRow>
      </template>
      <OrganizationHierarchyRow
        v-else 
        :organization="currentOrg" 
        :organizationIdToOrganization="organizationIdToOrganization"
        shown 
        :navigation="navigation"
        @onMouseOver="onMouseOver"
        @onMouseExit="onMouseExit"
      >
        <template #columns="{ org }">
          <slot 
            name="columns" 
            :org="org"
          ></slot>
        </template>
        <template #actions="{ org }">
          <slot 
            name="actions" 
            :org="org"
          ></slot>
        </template>
      </OrganizationHierarchyRow>
    </template>
  </base-list>
</template>
<script>
import { searchFilter, sortBy } from '@/utils';
import { getDate } from '@/utils/dates';
import { mapGetters } from 'vuex';
import OrganizationHierarchyRow from './OrganizationHierarchyRow';

const NAME_ASC = 'name:asc';
const NAME_DESC = 'name:desc';
const AGE_ASC = 'age:asc';
const AGE_DESC = 'age:desc';
const SINCE_ASC = 'last_status_change:asc';
const SINCE_DESC = 'last_status_change:desc';
const STATUS_ASC = 'status:asc';
const STATUS_DESC = 'status:desc';
const ACTIVITIES_ASC = 'activities:asc';
const ACTIVITIES_DESC = 'activities:desc';

export default {
  name: 'OrganizationHierarchyList',
  components: { OrganizationHierarchyRow },
  props: {
    organizations: {
      type: Array,
      required: true,
    },
    errorLabel: {
      type: String,
    },
    search: {
      type: String,
    },
    flat: {
      type: Boolean,
      default: false
    },
    sort: {
      type: String,
    },
    navigation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onMouseOver', 'onMouseExit'],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'myOrganization'
    ]),
    sortedOrgs() {
      return this.search ?
        this.sortOrgs(this.filteredOrgs) :
        this.sortOrgs(this.enhancedOrgs.filter(o => o.id !== this.selectedOrganization.id));
    },
    filteredOrgs() {
      return this.adoptedChildren.filter(
        searchFilter(
          this.search,
          o => [o.name, o.entryPoint, o.id, ...(o.tags || []).map(t => t.name),
          ...(o.customFields ? Object.values(o.customFields) : [])],
        ),
      );
    },
    organizationIdToOrganization() {
      return (this.organizations || []).reduce((acc, cur) => ({ ...acc, [cur.id]: cur, }), {});
    },
    currentOrg() {
      return this.enhancedOrgs.find(o => o.id === this.selectedOrganization.id);
    },
    adoptedChildren() {
      return this.sortOrgs(this.organizations).map(o => ({
        ...o,
        lineage: o.lineage.split(','),
      })).map(o => ({
        ...o,
        parent: this.closestParent(o),
      })).map(o => ({
        ...o,
        adopted: this.isAdopted(o),
      }));
    },
    enhancedOrgs() {
      return this.adoptedChildren.map(o => ({
        ...o,
        children: this.children(o, 1),
        indent: 0,
      }));
    },
    orgIds() {
      return this.organizations.reduce((acc, o) => {
        acc[o.id] = o;
        return acc;
      }, {});
    },
  },
  methods: {
    onMouseOver(organizationId) {
      this.$emit('onMouseOver', organizationId);
    },
    onMouseExit(organizationId) {
      this.$emit('onMouseExit', organizationId);
    },
    isAdopted(o) {
      if (o.parent) {
        return o.parent.id !== o.lineage[o.lineage.length - 1];
      }
      return false;
    },

    // Additional roles from RBAC could result in having visibility
    // on orgs without full view of the orgs subtree.
    // The organizations are "adopted" on to the closest parent
    // and a message is displayed as a tooltip next to the orgs name.
    closestParent(child) {
      // removes the current org's id from the lineage
      child.lineage.pop();
      for (let i = child.lineage.length - 1; i > -1; i -= 1) {
        if (this.orgIds[child.lineage[i]]) {
          return this.orgIds[child.lineage[i]];
        }
      }
      return null;
    },
    children(org, indent) {
      return this.adoptedChildren.filter(child => child.parent && child.parent.id === org.id)
        .map(child => ({
          ...child,
          children: this.children(child, indent + 1),
          indent,
        }));
    },
    sortOrgs(orgs) {
      switch (this.sort) {
        case NAME_ASC:
          return orgs.sort(sortBy(o => o.name.toLowerCase()));
        case NAME_DESC:
          return orgs.sort(sortBy(o => o.name.toLowerCase())).reverse();
        case AGE_ASC:
          return orgs.sort(sortBy(o => getDate(o.creationDate))).reverse();
        case AGE_DESC:
          return orgs.sort(sortBy(o => getDate(o.creationDate)));
        case SINCE_ASC:
          return orgs.sort(sortBy(o => getDate(o.statusUpdatedAt))).reverse();
        case SINCE_DESC:
          return orgs.sort(sortBy(o => getDate(o.statusUpdatedAt)));
        case STATUS_ASC:
          return orgs.sort(sortBy(o => o.status.toLowerCase()));
        case STATUS_DESC:
          return orgs.sort(sortBy(o => o.status.toLowerCase())).reverse();
        case ACTIVITIES_ASC:
          return orgs.sort(sortBy(o => o.numActivities));
        case ACTIVITIES_DESC:
          return orgs.sort(sortBy(o => o.numActivities)).reverse();
        default:
          return orgs;
      }
    },
  },
};
</script>
