<template>
  <div>
    <div class="description message-column">
      <base-icon 
        icon="info-circle" 
        :customizable="false"
      />
      <div class="message message-container">
        {{ $t('allow_auto_account_creation_desc') }}
      </div>
    </div>
    <div>
      <form-row label="associated_domains">
        <div 
          v-if="!hasDomains" 
          class="description message-column"
        >
          <base-icon icon="exclamation-triangle" />
          <div class="message message-container">
            {{ $t('no_associated_domains') }}
          </div>
        </div>
        <form-row 
          v-for="d in domains" 
          :key="d.id"
          direction="horizontal" 
          class="domain-row" 
        >
          <base-checkbox
            v-model="d.checked"
            class="domain-checkbox"
            :disabled="!isVerified(d.status) || disabled"
            :label="d.domain"
            @update:modelValue="checked" 
          />
          <div class="state-badge">
            <base-badge 
              :state="getStateLabel(d.status)" 
              :color="getStateColor(d.status)"
            />
          </div>
        </form-row>
      </form-row>
      <form-row label="primary_role">
        <base-select
          :items="assignableFixedSystemRoles"
          :modelValue="(modelValue || {}).id"
          @update:modelValue="setDefaultRole" 
        />
      </form-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "AutoAccountCreationConfiguration",
  props: {
    disabled: {
      type: Boolean,
    },
    organization: {
      type: Object,
      required: true,
    },
    domains: {
      type: Array,
    },
    assignableRoles: {
      type: Array,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue','updateDomains'],
  computed: {
    hasDomains() {
      return this.domains ? this.domains.length : 0;
    },
    assignableFixedSystemRoles() {
      return this.assignableRoles
        .map(role => ({
          value: role.id,
          label: `fixed_roles.${role.name}.name`,
          descriptionLabel: `fixed_roles.${role.name}.description`,
        }));
    },
  },
  methods: {
    checked() {
      this.$emit('updateDomains');
    },
    setDefaultRole(id) {
      const name = this.assignableRoles
        .filter(r => r.id === id)[0].name;
      this.$emit('update:modelValue', { id, name });
    },
    getStateLabel(status) {
      return `domain_status.${status.toLowerCase()}`;
    },
    getStateColor(status) {
      return {
        VERIFIED: 'green',
        ERROR: 'red',
        PENDING: 'blue',
      }[status];
    },
    isVerified(status) {
      return status === 'VERIFIED';
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/mixins';

em.fa-info-circle, em.fa-exclamation-triangle {
  font-size: 22px;
  padding-right: 10px;
}

.message-column {
  display: flex;
  .message-container {
    text-align:left;
  }
  align-items: center;
}

.description {
  padding-top: 10px;
  padding-left: 10px;
}

.domain-row {
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
}

.state-badge {
  padding-left: 10px;
  align-content: center;
}

.domain-checkbox {
  width: 300px;
  word-break: break-all;
  @include phone {

  }
}
</style>
