<template>
  <base-tooltip
    v-if="tiers.length > 0"
    overflow
    toggleOnFocus
    :direction="direction"
  >
    <base-icon icon="fa fa-info-circle" />
    <template #popover>
      <base-list
        ariaLabel="monetization.pricing_tiers"
        :ariaRowCount="tiers.length"
        noActions
      >
        <list-header>
          <list-column>{{ boundsLabel }}</list-column>
          <list-column>{{ tierUnitPriceLabel }}</list-column>
          <list-column>{{ tierCostLabel }}</list-column>
        </list-header>
        <list-row
          v-for="tier in tiers"
          :key="tier.id"
        >
          <list-column>{{ getBounds(tier) }} </list-column>
          <list-column>{{ getTierUnitPrice(tier) }}</list-column>
          <list-column>{{ getTierCost(tier) }}</list-column>
        </list-row>
        <list-row>
          <list-column />
          <list-column>{{ $t('total') }}</list-column>
          <list-column>{{ cost }}</list-column>
        </list-row>
      </base-list>
    </template>
  </base-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

import { currencyFormatters } from '@/utils/currency';

export default {
  name: 'CommittedProductTierTooltip',
  props: {
    estimate: {
      type: Object,
      required: true,
    },
    isDiscount: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    direction() {
      return this.isDiscount ? 'left' : 'right';
    },
    tiers() {
      return (this.estimate || {}).tiers || [];
    },
    currency() {
      return (this.estimate || {}).currency;
    },
    cost() {
      const estimate = this.estimate || { cost: 0, discountCost: 0 };
      const price = (this.isDiscount ? estimate.discountCost : estimate.cost) || 0;
      return currencyFormatters[this.locale]({
        style: "currency",
        currency: this.currency,
      }).format(price);
    },
    unit() {
      return (this.estimate || {}).unit; // already in the right format
    },
    boundsLabel() {
      const lb = this.$t('monetization.lower_bound');
      const ub = this.$t('monetization.upper_bound');
      const unit = this.unit;
      return `${lb} - ${ub} (${unit})`;
    },
    tierUnitPriceLabel() {
      const heading = this.$t('monetization.commitments.price');
      const currency = this.currency;
      const unit = this.unit;
      return `${heading} (${currency} / ${unit})`;
    },
    tierCostLabel() {
      const t = this.$t('monetization.commitments.product_estimate_price');
      const currency = this.currency;
      return `${t} (${currency})`;
    },
  },
  methods: {
    getBounds(tier) {
      if (!tier.upperBound) {
        return `${tier.lowerBound} +`;
      }
      if (tier.lowerBound === 0) {
        return `< ${tier.upperBound}`;
      }
      return `${tier.lowerBound} - ${tier.upperBound}`;
    },
    getTierUnitPrice(tier) {
      const unitPrice = ((this.isDiscount ? tier.discountUnitPrice : tier.unitPrice) || 0).toFixed(4);
      if (tier.chunkSize) {
        return `${unitPrice} per ${tier.chunkSize} ${this.unit}`;
      }
      return unitPrice;
    },
    getTierCost(tier) {
      const cost = (this.isDiscount ? tier.discountCost : tier.cost) || 0;
      return currencyFormatters[this.locale]({
        style: "currency",
        currency: this.currency,
      }).format(cost);
    },
  }
}
</script>
<style lang="scss" scoped>
:deep(.popover) {
  min-width: 450px;
}
</style>
