<template>
  <div class="base-icon-picker">
    <div 
      class="drop-down-picker" 
      role="button" 
      tabindex="0" 
      @keyup.enter="handleOpen" 
      @click="handleOpen"
    >
      <base-icon 
        :ariaLabel="iconPickerLabel + trimLabel(selected)" 
        :icon="selected" 
        class="picked" 
        :customizable="false"
      />
      <base-icon 
        :icon="`fa fa-caret-down`" 
        :customizable="false" 
      />
    </div>
    <base-dropdown 
      :opened="opened" 
      direction="down" 
      position="right" 
      @close="handleClose"
    >
      <div class="search">
        <input 
          v-model="search" 
          class="search-input" 
          :placeholder="$t('icon_placeholder')" 
        />
      </div>
      <div class="wrapper-picker">
        <div
          v-for="(icon,x) in filteredIcons"
          :key="`icon-${x}`"
          class="cell-picker"
          tabindex="0"
          role="button"
          @keyup.enter="handleClose(icon)"
          @click="handleClose(icon)"
        >
          <base-icon 
            :ariaLabel="trimLabel(icon)" 
            :icon="icon" 
            role="option" 
            class="picker-icon" 
            :customizable="false"
          />
        </div>
      </div>
    </base-dropdown>
  </div>
</template>
<script>
import BaseIcon from '@/components/BaseIcon';
import ICONS from './Icons';

export default {
  name: 'BaseIconPicker',
  components: { BaseIcon },
  props: {
    initialIcon: {
      type: String,
      required: false,
    },
    iconPickerLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['open', 'close', 'pick'],
  data() {
    return {
      defaultIcon: 'fa fa-envelope',
      opened: false,
      search: '',
      icons: ICONS.ICONS,
      selected: '',
    };
  },
  computed: {
    filteredIcons() {
      return !this.search
        ? this.icons
        : this.icons.filter(icon => icon.includes(this.search.toLowerCase()));
    },
  },
  created() {
    this.selected = this.initialIcon ? this.initialIcon : this.defaultIcon;
  },
  methods: {
    handleOpen() {
      this.$emit('open');
      this.opened = true;
    },
    handleClose(event) {
      this.opened = false;
      this.$emit('close', event);
      if (event) {
        this.selected = event;
        this.$emit('pick', this.selected);
      }
    },
    trimLabel(icon) {
      return icon.replace('fa fa-', '');
    },
  },
};
</script>
<style lang="scss">
  .base-icon-picker {
    position: relative;
    max-width: 70%;

    .wrapper-picker {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-height: 400px;
      height: max-content;
      overflow: auto;
      width: 100%;
      align-content: flex-start;

      .picker-icon {
        font-size: 20px;
        margin: 4px;
      }

      .cell-picker {
        border-radius: 6px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
        flex-grow: 1;
        height: 30px;
        margin: 5px;
        padding: 4px;
        text-align: center;
        transition: ease-in-out all 0.25s;
        width: 30px;
      }
    }

    .drop-down-picker {
      width: fit-content;

      .picked {
        font-size: 40px;
        margin: 4px 4px 10px 4px;
      }
    }
    .search {

      width: 100%;
      height: fit-content;

      .search-input {
        width: -webkit-fill-available;
        margin: 10px;
        font-size: larger;
        font-weight: normal;
        padding: 10px;
        padding-left: 20px;
        border-style: hidden;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
    }
    .dropdown{
      margin-bottom: 30px;
    }
  }
</style>
