import Api from './api';

class EntitiesApi extends Api {
  list(serviceCode, environment, entityType, queryParams = {}) {
    return this.get(`/services/${serviceCode}/${environment}/${entityType}`, {
      qs: {
        ...queryParams,
      },
    });
  }

  find(serviceCode, environment, entityType, id, queryParams = {}) {
    return this.get(`/services/${serviceCode}/${environment}/${entityType}/${id}`, {
      qs: {
        ...queryParams,
      },
    });
  }

  getCustomFieldsByUsageType(orgId, serviceType) {
    return this.get(`/custom_fields/service_entity/by_usage_types/${orgId}`,{
      qs: {
        service_type: serviceType,
      },
    });
  }

}

const entitiesApi = new EntitiesApi();

export default entitiesApi;
