<template>
  <span 
    v-if="!disabled && !!modelValue && !showButton" 
    :class="[ ellipsis ? 'display-ellipsis' : 'copyable-field', { clickable: !disabled, 'on-hover': onHover }]"
    @mouseover="init = true" 
    @focus="init = true" 
  >
    <div v-if="!init">
      <span 
        v-if="rawValue && !link" 
        class="data"
      >{{ displayValue }}</span>
      <span 
        v-if="rawValue && link" 
        class="data"
      >
        <a 
          :href="modelValue" 
          target="_blank"
        >{{ displayValue }}</a>
      </span>
      <slot v-else></slot>
    </div>
    <div 
      v-else 
      v-tooltip.top="{ content: $t('copied'), shown: isOpen, triggers: ['manual'] }"
    >
      <span 
        v-if="rawValue && !link" 
        class="data"
      >{{ displayValue }}</span>
      <span 
        v-if="rawValue && link" 
        class="data"
      >
        <a 
          :href="modelValue" 
          target="_blank"
        >{{ displayValue }}</a>
      </span>
      <slot v-else></slot>
    </div>
    <div class="icons-container">
      <action-icon 
        v-if="!disabled && sensitive" 
        class="sensitive-toggle" 
        :icon="isUnlocked ? 'fa fa-eye' : 'fa fa-eye-slash'"
        :tooltipLabel="sensitiveIconLabel"
        @click="toggleUnlock" 
      />
      <action-icon 
        v-if="!disabled" 
        icon="fa fa-clipboard" 
        tooltipLabel="copy"
        @click="copyToClipboard($event, modelValue)" 
      />
    </div>
  </span>
  <base-button 
    v-else-if="showButton" 
    class="copy-button" 
    :rounded="false" 
    @click="copyToClipboard($event, modelValue)"
  >
    <div v-tooltip.top="{ content: $t('copied'), shown: isOpen, triggers: ['manual'] }"></div>
    {{ $t('copy') }}
  </base-button>
  <span v-else>
    <slot></slot>
  </span>
</template>

<script>

export default {
  name: 'CopyableField',
  props: {
    modelValue: {
      type: [Object, String, Number, Date, Boolean]
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
    rawValue: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    maxDots: {
      type: Number,
      default: 60,
    },
    onHover: {
      type: Boolean,
      default: false,
    },
    transformation: {
      type: Function,
      default: v => v,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show', 'hide'],
  data() {
    return {
      isOpen: false,
      timeout: null,
      isUnlocked: false,
      init: false,
      sensitiveIconLabels: ['reveal', 'hide'],
    };
  },
  computed: {
    displayValue() {
      let dVal = '';
      if (this.sensitive && !this.isUnlocked) {
        const chars = this.modelValue.split('');
        if (chars.length > this.maxDots) {
          dVal = [...Array(this.maxDots).keys()].map(() => '•').join('');
        } else {
          dVal = chars.map(() => '•').join('');
        }
      } else {
        dVal = this.modelValue;
      }
      return this.transformation(dVal);
    },
    sensitiveIconLabel() {
      return this.sensitiveIconLabels[this.isUnlocked ? 1 : 0];
    },
  },
  methods: {
    toggleUnlock() {
      this.isUnlocked = !this.isUnlocked;
      this.$emit(this.isUnlocked ? 'show' : 'hide');
    },
    copyToClipboard($event, value, noop = false) {
      if (this.disabled || noop) {
        return;
      }
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
      this.isOpen = true;

      // sometimes value is a string
      // sometimes value is an object
      // either way, we want to copy it appropiately
      let str = '';
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          str = value.map(val => ((typeof val === 'string') ? val : JSON.stringify(val))).join(', ');
        } else {
          str = JSON.stringify(value);
        }
      } else { str = value; }
      navigator.clipboard.writeText(str);

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.isOpen = false;
        this.timeout = null;
      }, 1000);
    },
  },
};
</script>


<style scoped lang="scss">
:deep(em.fa-clipboard) {
  margin-left: 5px;
  margin-left: 5px;
  font-size: 14px;
  transition: color 0.5s;
}

.copy-button {
  margin-left: 5px;
  min-width: fit-content;
}

.on-hover {
  :deep(em.fa-clipboard) {
    opacity: 0;
    &:focus {
      opacity: 1;
    }

    &:focus-visible {
      opacity: 1;
    }
  }
  &:hover {
    :deep(em.fa-clipboard) {
      opacity: 1;
    }
  }
}

.data {
  font-size: 12px;
  font-family: monospace;
  font-weight: bold;
}

.copyable-field {
  > div {
    display: inline-block;

    > div {
      display: inline-block;
    }
  }
}

.display-ellipsis {
  > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
