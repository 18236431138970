<template>
  <div class="trial page">
    <div class="top logo">
      <app-logo 
        class="app-logo" 
        src="/rest/appearance/logo"
      />
    </div>
    <div class="trial-container">
      <div class="trial-box">
        <div class="trial-icon top-icon">
          <base-icon 
            v-if="trialQueue" 
            icon="fa fa-hourglass-1"
          />
          <base-icon 
            v-if="errorMode" 
            icon="fa fa-exclamation-triangle"
          />
          <base-icon 
            v-if="mode === 'trial_success'" 
            icon="fa fa-hourglass-2"
          />
        </div>
        <div class="message-container">
          <h1>{{ title }}</h1>
          <div class="body-layout">
            <p>{{ body }}</p>
            <p v-show="showPhoneAndEmail">
              {{ $t(msg) }}<br>
              {{ phone }}<br><a :href="emailUrl">{{ email }}</a>
            </p>
            <base-button 
              v-show="showBackHomeButton"
              @click="goHome" 
            >
              <base-icon icon="fa fa-sign-in" />{{ $t('go_back') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import { mapGetters } from 'vuex';
import browserHelper from '@/utils/browser';
import AppLogo from '@/components/AppLogo';

export default {
  name: 'TrialActivateExtendByEmail',
  components: { AppLogo },
  props: {
    trialId: {
      type: String,
      required: true,
    },
    trialToken: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      utc: true,
      dateFormat: 'YYYY-MM-DD',
      status: '',
      msg: 'contact_us',
      showPhoneAndEmail: true,
      showBackHomeButton: false,
      successData: {},
      polling: null,
    };
  },
  computed: {
    ...mapGetters([
      'globalProperties',
    ]),
    trialQueue() {
      return this.mode === 'trial_already_pending' || this.mode === 'trial_submitted';
    },
    errorMode() {
      return this.mode === 'trial_already_activating'
        || this.mode === 'over_extension_count'
        || this.mode === 'trial_not_ongoing'
        || this.mode === 'not_found'
        || this.mode === 'token_invalid';
    },
    mode() {
      return this.status || this.successData;
    },
    emailUrl() {
      return `mailto: ${this.email}}`;
    },
    email() {
      return this.globalProperties['trial.contact_us_email'];
    },
    phone() {
      return this.globalProperties['trial.contact_us_phone'];
    },
    title() {
      if (this.action && this.status) {
        return this.$t(`${this.action}_${this.status}.title`) || '';
      }
      return '';
    },
    body() {
      if (this.action && this.status) {
        return this.$t(`${this.action}_${this.status}.body`, {
          date: this.getFormattedDate(),
        });
      }
      return '';
    },
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  created() {
    this.callAction();
  },
  methods: {
    async callAction() {
      let res = '';
      /**
       * Call different api based on the value (activation and extension only) from router
       */
      if (this.action === 'activation') {
        res = await apis.trials.activateTrialByEmail(this.trialId, this.trialToken);
      } else {
        res = await apis.trials.extendTrialByEmail(this.trialId, this.trialToken);
      }
      /**
       * If 'errors' responses, concatenate the error code in lower case
       * to be action_error_code.body/title
       * eg: activation_unauthorized, activation_not_found ...
       *
       * If a 'data' is returned. Two success status:
       * 1: Added in queue
       * 2: Auto Approved (data contains extra values: 'activating : true', 'taskId : any id')
       * For more info: core/TrialResource.java/activateTrial
       */
      if (res.errors) {
        this.status = res.errors[0].errorCode.toLowerCase();
        if (this.mode === 'trial_already_activating') {
          this.pollVerifyTrialStatus();
        }
      } else if (res.data) {
        this.successData = res.data;
        if (this.action === 'activation') {
          if (!res.data.activating) {
            this.status = 'trial_submitted';
          } else {
            this.status = 'trial_success';
            this.showPhoneAndEmail = false;
            this.pollVerifyTrialStatus();
          }
        } else {
          this.status = 'trial_success';
          this.msg = 'contact_us_long';
          this.showBackHomeButton = true;
        }
      }
    },
    goHome() {
      browserHelper.goTo(this.successData.entryPoint);
    },
    getFormattedDate() {
      return this.$date(this.successData.expiryDate, this.dateFormat, this.utc);
    },
    goToSetPassword() {
      this.$router.replace('/login?trialToken='.concat(this.trialToken));
    },
    pollVerifyTrialStatus() {
      this.polling = setInterval(async () => {
        const resp = await apis.trials.trialStatus(this.trialToken);
        if (resp.status === 200) {
          if (resp.data.userCreated && !resp.data.passwordProvided) {
            this.goToSetPassword();
          } else if (resp.data.userCreated && resp.data.passwordProvided) {
            this.$router.replace('/login');
          }
        } else {
          this.$router.replace('/login');
        }
      }, 3000);
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins';
@import '@/styles/animations';

.trial-container {
  @include phone {
    width: 90%;
  }
}

.trial-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  @include phone {
    padding: 20px 10px;
  }
  @include not-phone {
    padding: 60px;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  animation: fadein .5s;
}

.top {
  img.app-logo {
    max-height: 100px;
    max-width: 250px;
    margin-top: 50px;
    @include phone {
      margin-bottom: 10px;
    }
    @include not-phone {
      margin-bottom: 20px;
    }
  }
}

.message-container {
  max-width: 600px;
  @include phone {
    max-width: 90%;
  }
  border-radius: 5px;
}

.main-title {
  margin-top: 0;
  text-align: center;
}

.body-layout {
  text-align: left;
  font-size: 16px;
}

.top {
  height: 160px;
  img.app-logo {
    max-height: 100px;
    width: 250px;
    margin-top: 50px;
    margin-bottom: 50px;

    @include phone {
      margin-bottom: 45px;
      margin-top: 20px;
    }
  }
}

.top-icon {
  position:absolute;
  right:-15px;
  top:-15px;
  width:65px;
  height:65px;
  font-size:35px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;

  @include phone {
    right: 0;
    left: 0;
    top: -30px;
    font-size: 30px;
    height: 55px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
