<template>
  <img 
    class="clickable" 
    :src="src" 
    :alt="logoText"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppLogo',
  props: {
    src: {
      type: String,
      required: true,
    },
    altText: {
      required: false,
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'orgBrandingInfo',
    ]),
    logoText() {
      if (!this.altText) {
        return this.orgBrandingInfo && this.orgBrandingInfo.applicationName ?
          `${this.orgBrandingInfo.applicationName} ${this.$t('logo')}` : this.$t('logo');
      }
      return this.altText;
    },
  },
};
</script>

