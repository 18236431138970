
const ComponentsSection = () => import(/* webpackChunkName: "system" */ './ComponentsSection');

const BuiltinList = () => import(/* webpackChunkName: "system" */ './builtin/BuiltinList');
const JarPluginList = () => import(/* webpackChunkName: "system" */ './jars/JarPluginList');

const BuiltinPage = () => import(/* webpackChunkName: "system" */ './builtin/BuiltinPage');
const JarPluginPage = () => import(/* webpackChunkName: "system" */ './jars/JarPluginPage');

const BuiltinDetails = () => import(/* webpackChunkName: "system" */ './builtin/BuiltinDetails');
const JarPluginDetails = () => import(/* webpackChunkName: "system" */ './jars/JarPluginDetails');

const ComponentReleaseNotes = () => import(/* webpackChunkName: "system" */ './ComponentReleaseNotes');

const FeaturesSection = () => import(/* webpackChunkName: "system" */ './features/FeaturesSection');
const ServiceFeatures = () => import(/* webpackChunkName: "system" */ './features/ServiceFeatures');
const EntitiesFeatures = () => import(/* webpackChunkName: "system" */ './features/EntitiesFeatures');
const EnvRolesFeatures = () => import(/* webpackChunkName: "system" */ './features/EnvRolesFeatures');
const JobsFeatures = () => import(/* webpackChunkName: "system" */ './features/JobsFeatures');
const SupportedLanguagesFeatures = () => import(/* webpackChunkName: "system" */ './features/SupportedLanguagesFeatures');
const DependencyFeatures = () => import(/* webpackChunkName: "system" */ './features/DependencyFeatures');

const CompareComponents = () => import(/* webpackChunkName: "system" */ './CompareComponents');
const RemotePluginList = () => import(/* webpackChunkName: "system" */ './remoteplugins/RemotePluginList');

const RemotePluginPage = () => import(/* webpackChunkName: "system" */ './remoteplugins/RemotePluginPage');
const RemotePluginDetails = () => import(/* webpackChunkName: "system" */ './remoteplugins/RemotePluginDetails');


const AddRemotePlugin = () => import(/* webpackChunkName: "system" */ './remoteplugins/AddRemotePlugin');

const RemotePluginAgents = () => import(/* webpackChunkName: "system" */ './remoteplugins/RemotePluginAgents');

export default [
  {
    path: 'components',
    meta: {
      protected: ['reseller:components'],
      title: () => 'components_title',
    },
    children: [
      {
        name: 'componentsSection',
        path: '',
        component: ComponentsSection,
        children: [
          {
            path: '',
            name: 'componentsSectionDefault',
            redirect: { name: 'builtinList' },
          },
          {
            name: 'builtinList',
            path: 'builtin',
            component: BuiltinList,
            meta: {
              title: () => 'components.builtin'
            },
          },
          {
            name: 'jarPluginList',
            path: 'jar-plugins',
            component: JarPluginList,
            meta: {
              title: () => 'components.jars'
            },
          },
          {
            path: 'compare',
            name: 'compareComponents',
            component: CompareComponents,
            meta: {
              title: () => 'components.compare'
            },
          },
          {
            name: 'remotePluginList',
            path: 'remote-plugins',
            component: RemotePluginList,
            meta: {
              protected: ['sys:integrations'],
            }
          }
        ],
      },
      {
        name: 'builtinDetails',
        path: 'builtin/:id',
        component: BuiltinDetails,
      },
      {
        name: 'builtinPage',
        path: 'builtin/:id',
        component: BuiltinPage,
        meta: {
          title: () => 'components.builtin'
        },
        children: [
          {
            path: '',
            name: 'builtinPageDefault',
            redirect: { name: 'builtinDetails' },
          }, {
            name: 'builtinDetails',
            path: 'details',
            component: BuiltinDetails,
          },
          {
            name: 'builtinReleaseNotes',
            path: 'release_notes',
            component: ComponentReleaseNotes,
          },
        ],
      },
      {
        name: 'jarPluginPage',
        path: 'jar-plugins/:id',
        component: JarPluginPage,
        meta: {
          title: () => 'components.jars'
        },
        children: [
          {
            path: '',
            name: 'jarPluginsPageDefault',
            redirect: { name: 'jarPluginDetails' },
          }, {
            name: 'jarPluginDetails',
            path: 'details',
            component: JarPluginDetails,
          },
          {
            name: 'jarPluginReleaseNotes',
            path: 'release_notes',
            component: ComponentReleaseNotes,
          },
          {
            name: 'jarPluginFeatures',
            path: 'features',
            component: FeaturesSection,
            children: [
              {
                path: '',
                name: 'jarFeaturesSectionDefault',
                redirect: { name: 'jarServiceFeaturesList' },
              },
              {
                path: 'service',
                name: 'jarServiceFeaturesList',
                component: ServiceFeatures,
              },
              {
                path: 'dependencies',
                name: 'jarDependencyFeatures',
                component: DependencyFeatures,
              },
              {
                path: 'entities',
                name: 'jarEntitiesFeaturesList',
                component: EntitiesFeatures,
              },
              {
                path: 'env_roles',
                name: 'jarEnvRolesFeaturesList',
                component: EnvRolesFeatures,
              },
              {
                path: 'jobs',
                name: 'jarJobsFeaturesList',
                component: JobsFeatures,
              },
              {
                path: 'supported_languages',
                name: 'jarSupportedLanguagesFeaturesList',
                component: SupportedLanguagesFeatures,
              },
            ],
          },
        ],
      },
      {
        name: 'addRemotePlugin',
        path: 'remote-plugins/add',
        component: AddRemotePlugin,
        meta: {
          protected: ['sys:integrations'],
        }
      },
      {
        name: 'remotePluginPage',
        path: 'remote-plugins/:id',
        component: RemotePluginPage,
        meta: {
          protected: ['sys:integrations'],
        },
        children: [{
          path: '',
          name: 'remotePluginPageDefault',
          redirect: { name: 'remotePluginDetails' },
        }, {
          name: 'remotePluginDetails',
          path: 'details',
          component: RemotePluginDetails,
        }, {
          name: 'remotePluginReleaseNotes',
          path: 'release_notes',
          component: ComponentReleaseNotes,
        },
        {
          name: 'remotePluginAgents',
          path: 'agents',
          component: RemotePluginAgents,
        }]
      },
    ],
  },
];
