import { CrudApi } from './api';

class ConfigPropertyApi extends CrudApi {
  constructor() {
    super('config_properties');
  }
}

const configPropertyApi = new ConfigPropertyApi();
export default configPropertyApi;
