<template>
  <div class="box-container">
    <div 
      v-if="showTop" 
      class="row top-title"
    >
      <slot name="top-title"></slot>
    </div>
    <div 
      v-if="showTop"
      class="row top" 
    >
      <div class="top-panel">
        <slot name="top-left"></slot>
      </div>
      <div class="top-panel">
        <slot name="top-right"></slot>
      </div>
    </div>
    <div>
      <hr 
        v-if="showTop" 
        class="divider row"
      />
    </div>
    <div 
      v-if="showTop" 
      class="top-title default-title-section"
    >
      <slot name="default-title"></slot>
    </div>
    <div 
      class="row" 
    >
      <div 
        v-if="!hideLhs && hasLhsSlot" 
        :class="['col-xs-12', 'col-sm-12', 'col-lg-3', showTop ? '' : 'lhs']"
      >
        <slot name="lhs"></slot>
      </div>
      <div :class="['rhs', 'col-xs-12', 'col-sm-12', ...(!hideLhs && hasLhsSlot ? ['col-lg-9'] : ['col-md-12', 'col-lg-12'])]">
        <slot name="rhs"></slot>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'BaseDashboard',
  props: {
    center: {
      type: Boolean,
      default: true,
    },
    hideLhs: {
      type: Boolean,
      default: false,
    },
    showTop: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasLhsSlot() {
      return !!this.$slots.lhs;
    }
  }
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins';
.box-container {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}

.rhs, .lhs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

:deep(.dashboard-section) {
  margin-bottom: 25px;
}

.rhs {
  @include phone {
    margin-top: 40px;
  }
}

.lhs {
  @media only screen and (min-width: 80em) {
    max-width: 350px;
  }
}

.rhs {
  display: flex;
  flex-direction: column;
}

.top {
  justify-content: center;
  flex-flow: row wrap;
}

.top-panel {
  flex-basis: 49.5%;
}

.top-title {
  justify-content: flex-start;
  padding-left: 0.5rem;
}

.divider {
  border-top: 3px solid var(--panel-header-bg);
  margin: auto;
  width: 98%;
}

.default-title-section {
  margin-block-end: 1.5em;
}

</style>
