<template>
  <div 
    v-if="isTextValue"
    class="ui-value" 
  >
    <base-badge 
      v-if="hasState" 
      :state="text" 
      :color="state"
    />
    <div 
      v-else 
      class="text"
    >
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiValue',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isTextValue() {
      return !!this.modelValue.text;
    },
    hasState() {
      return !!this.modelValue.stateColor;
    },
    state() {
      return (this.modelValue.stateColor || '').toLowerCase();
    },
    text() {
      return this.modelValue.isI18n ?
        this.$t(this.modelValue.text, this.modelValue.interpolation) :
        this.modelValue.text;
    },
  },
};
</script>
