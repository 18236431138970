<template>
  <div :class="['collapse', boxed? 'box-border' :'',boxed ? 'boxedSection': '']">
    <div 
      :class="['titleSection', boxed ? 'boxedTitle': '', open ? 'open': '', 'clickable']" 
      :tabindex="0" 
      :aria-label="tooltipLabel"
      @click="toggle" 
      @keyup.enter="toggle" 
    >
      <base-icon 
        v-if="iconClass" 
        class="logoPlaceholder" 
        :class="[{closed: !open}, 'topAligned', description ? '':'marginBottom']" 
        :icon="`${iconClass}`"
      />
      <div class="collapsible-section-header">
        <template v-if="!hasHeaderSlot">
          <h4>{{ $t(titleLabel) }}</h4>
          <div 
            v-if="description" 
            :class="['description', description ? 'marginBottom':'']"
          >
            {{ description }}
          </div>
        </template>
        <slot 
          v-else 
          name="header"
        >
          <h4>{{ $t(titleLabel) }}</h4>
          <div 
            v-if="description" 
            :class="['description', description ? 'marginBottom':'']"
          >
            {{ description }}
          </div>
        </slot>
      </div>
    </div>
    <slot v-if="open"></slot>
  </div>
</template>
<script>

export default {
  name: 'CollapsibleSection',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: [String, Array],
      required: true,
    },
    description: {
      type: String,
    },
    icons: {
      type: Array,
      default: () => ['fa-plus-circle', 'fa-minus-circle'],
    },
    boxed: {
      type: Boolean,
      default: false,
    },
    tooltips: {
      type: Array,
      default: () => ['expand', 'collapse'],
    },
  },
  emits: ['toggle', 'close', 'open'],
  computed: {
    titleLabel() {
      if (typeof this.title === 'string') {
        return this.title;
      }
      return this.title[this.open ? 1 : 0];
    },
    iconClass() {
      return this.icons[this.open ? 1 : 0];
    },
    tooltip() {
      return this.tooltips[this.open ? 1 : 0];
    },
    tooltipLabel() {
      return this.$te(this.tooltip) ? this.$t(this.tooltip) : this.tooltip
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
      this.$emit(this.open ? 'close' : 'open');
    },
  },
};
</script>
<style lang="scss" scoped>
.collapsible-section-header {
  width: 100%;
}

.titleSection {
  display: flex;
  align-items: flex-start;
}

.boxedSection {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow:1px 1px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin-bottom: 20px;
}

.boxedTitle {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
  &.open {
    border-bottom: 1px solid var(--list-header-border);
  }
}

.logoPlaceholder {
  min-width:16px;
  margin-right: 10px;
  text-align:center;
  margin-top: 2px;
}

h4 {
  margin-top: 0;
  margin-bottom: 0px;
}
</style>
