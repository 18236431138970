import { init, loadLanguageAsync } from '@/i18n';
import { LANGUAGE_ENTRY_POINT } from '.';

import { routes as SupportRoute } from './Support';

const HelpCenter = () => import('./HelpCenter');
const PageNotFound = () => import('./components/PageNotFound');
const HelpCenterHome = () => import('./Content/HelpCenterHome');
const ContentTopic = () => import('./Content/components/topics/ContentTopic');
const ArticlePage = () => import('./Content/components/articles/ArticlePage');
const SearchResults = () => import('./Content/components/articles/SearchResults');

const pageNotFoundRoute = {
  path: '/hc/notFound',
  component: PageNotFound,
};

const HelpCenterRoutes = [{
  path: '/hc/',
  component: HelpCenter,
  meta: {
    title: () => 'help_center_title',
  },
  children: [
    {
      path: '',
      component: HelpCenterHome,
    },
    pageNotFoundRoute,
    ...SupportRoute,
    {
      path: 'search',
      component: SearchResults,
    },
    {
      path: ':topicSlug',
      component: ContentTopic,
      props: true,
    },
    {
      path: ':topicSlug/:articleSlug',
      component: ArticlePage,
      props: true,
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ],

  async beforeEnter(to, from, next) {
    const host = window.location.host.split('.');
    const language = LANGUAGE_ENTRY_POINT[host[0]];
    await init(language);
    await loadLanguageAsync('help');
    await loadLanguageAsync('support');
    return next();
  },

}];

export default HelpCenterRoutes;

export {
  pageNotFoundRoute,
};
