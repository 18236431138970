<template>
  <div>
    <div v-if="!compactLayout">
      <div 
        v-for="(fe, index) in children" 
        :key="fe.field" 
        :class="['gray-border', { 'no-border': noBorder }]"
      >
        <div>
          <div :class="['remove-button-container', { 'remove-button-height': heightForRemoveButton(fe) }]">
            <div class="remove-button">
              <base-button 
                v-if="!hideRemove" 
                type="repeat-delete" 
                :aria-label="$t('remove')" 
                :disabled="disableRemove" 
                :class="['remove-button-border', disableRemove ? 'disabled-composite ':'']"
                @click="removeFormElement(index)" 
              >
                <div :class="[disableRemove ? 'disabled-composite ':'']">
                  <base-icon icon="fa fa-minus" /> {{ $t('remove') }}
                </div>
              </base-button>
            </div>
          </div>
          <form-element
            :ref="getRef(index)"
            v-model="computedVal[fe.field]"
            :formElement="fe"
            :disabled="fe.disabled"
            :error="getErrorsForField(fe.field)"
            :imageResolver="fe.imageResolver"
            @change="($event) => setRepeatableField(fe.field, $event)"
            @reload="$emit('reload', $event)"
            @updateRepeatableError="updateRepeatableError"
          ></form-element>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="rows"
    >
      <div 
        v-for="(fe, index) in children" 
        :key="fe.field" 
        class="row"
      >
        <form-element
          :ref="getRef(index)"
          v-model="computedVal[fe.field]"
          :formElement="fe"
          :disabled="disabled"
          :error="getErrorsForField(fe.field)"
          :imageResolver="fe.imageResolver"
          @change="($event) => setRepeatableField(fe.field, $event)"
          @reload="$emit('reload', $event)"
          @updateRepeatableError="updateRepeatableError"
        ></form-element>
        <div class="add-remove-toggle-wrapper">
          <base-button 
            v-if="!hideRemove" 
            :rounded="false" 
            :aria-label="$t('remove')" 
            :disabled="disableRemove" 
            class="add-remove-toggle"
            @click="removeFormElement(index)" 
          >
            <base-icon icon="fa fa-minus" />
          </base-button>
          <base-button 
            v-if="!hideAdd" 
            :rounded="false" 
            :aria-label="$t('add')" 
            :disabled="disabled || preventAdd" 
            class="add-remove-toggle"
            @click="addFormElement(index + 1)" 
          >
            <base-icon icon="fa fa-plus" />
          </base-button>
        </div>
      </div>
    </div>
    <div 
      v-if="showEmptyMessage" 
      class="optional"
    >
      {{ emptyMessage ? $t(emptyMessage) : $t("none") }}
    </div>
  </div>
</template>

<script>
import { compactLayout, compositeWithoutLabelsInFirstRow } from './repeatable';

export default {
  name: 'RepeatableFormElement',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: true,
    },
    error: {
      type: [Array, Object],
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    emptyMessage: {
      type: String,
    },
    preventAdd: {
      type: Boolean,
    },
    hideAdd: {
      type: Boolean,
    },
    hideRemove: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    reloadOnRemove: {
      type: Boolean,
    },
    reloadOnAdd: {
      type: Boolean,
    },
    sectionsToReloadOnAdd: {
      type: Array,
      default: () => [],
    },
    sectionsToReloadOnRemove: {
      type: Array,
      default: () => [],
    },
    optional: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
    },
  },
  emits: ['change', 'reload', 'updateRepeatableError'],
  computed: {
    computedVal: {
      get() {
        return this.modelValue || [];
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    disableRemove() {
      return this.disabled ||
        (this.required && !!this.children && this.children.length < 2);
    },
    showEmptyMessage() {
      return this.children && this.children.length === 0;
    },
    compactLayout() {
      return compactLayout(this.default.type, this.default.children);
    },
  },
  created() {
    if ((!this.disabled) && (!!this.children) && (!this.children.length) && (!this.optional)) {
      this.addFormElement(0, false);
    }
  },
  methods: {
    setRepeatableField(field, val) {
      const copyComputed = [...this.computedVal];
      copyComputed[field] = val;
      this.computedVal = copyComputed;
    },
    heightForRemoveButton(fe) {
      return compositeWithoutLabelsInFirstRow(fe.type, fe.children);
    },
    addFormElement(index = this.children.length, addFocus = true) {
      const newFe = { ...this.default };
      newFe.field = index.toString();
      const inAddRange = fieldIndex => fieldIndex >= index;
      const getNewFieldIndex = fieldIndex => fieldIndex + 1;
      const errorsToRemove = this.getErrorToRemove(newFe.field);
      const errorsToAdd = this.getErrorToAdd(inAddRange, getNewFieldIndex);
      this.updateRepeatableError(errorsToRemove, errorsToAdd);
      this.children.forEach((fe) => {
        const fieldIndex = parseInt(fe.field, 10);
        if (fieldIndex >= index) {
          fe.field = (fieldIndex + 1).toString();
        }
      });
      this.computedVal.splice(index, 0,
        this.isComposite(newFe) ? this.getDefaultValueForComposite(newFe) : undefined);
      if (this.reloadOnAdd) {
        this.$emit('reload', { value: this.modelValue, sections: this.sectionsToReloadOnAdd });
      }
      // eslint-disable-next-line
      this.children.splice(index, 0, newFe);
      if (addFocus) {
        this.focusOnChildFe(index);
      }
    },
    focusOnChildFe(index) {
      // wait for the child element to be rendered
      // before extracting the reference and focussing on it
      this.$nextTick(() => {
        const childArray = this.$refs[this.getRef(index)];
        if (childArray) {
          const childFe = childArray[0];
          if (!!childFe && !!childFe.$el && !!childFe.$el.querySelector('input')) {
            childFe.$el.querySelector('input').focus();
          }
        }
      });
    },
    getRef(index) {
      return `${this.field}.${index}`;
    },
    getDefaultValueForComposite(compositeFe) {
      return compositeFe.children
        .filter(fe => fe.type === 'repeatable')
        .reduce((value, fe) => {
          value[fe.field] = [];
          return value;
        }, {});
    },
    removeFormElement(index) {
      if (this.required && this.children.length === 1) {
        return;
      }
      const inAddRange = fieldIndex => fieldIndex > index;
      const getNewFieldIndex = fieldIndex => fieldIndex - 1;
      const errorsToRemove = this.getErrorToRemove(index);
      const errorsToAdd = this.getErrorToAdd(inAddRange, getNewFieldIndex);
      // eslint-disable-next-line
      this.children.splice(index, 1);
      this.computedVal.splice(index, 1);
      this.children.forEach((fe) => {
        const fieldIndex = parseInt(fe.field, 10);
        if (fieldIndex > index) {
          fe.field = (fieldIndex - 1).toString();
        }
      });
      if (this.reloadOnRemove) {
        this.$emit('reload', { value: this.modelValue, sections: this.sectionsToReloadOnRemove });
      }
      // force update of the errors
      this.updateRepeatableError(errorsToRemove, errorsToAdd);
      this.$emit('change', this.modelValue);
    },
    getErrorToRemove(index) {
      // Gettings the errors to remove.
      // We need to remove all errors for the fields being remove
      // and after since we need to recompute them
      const setToRemove = [];

      this.getErrorsList(index.toString()).forEach(e => setToRemove.push(e.context.fieldPath));
      this.children.forEach((fe) => {
        const fieldIndex = parseInt(fe.field, 10);
        if (fieldIndex > index) {
          const oldErrors = this.getErrorsList(fe.field);
          oldErrors.forEach(e => setToRemove.push(e.context.fieldPath));
        }
      });
      return setToRemove;
    },
    getErrorToAdd(inAddRange, getNewFieldIndex) {
      // Gettings the errors to add back.
      // Since all the fields will change id, we need to recompute
      // them with the correct one and add them back.
      const setToAdd = [];

      this.children.forEach((fe) => {
        const fieldIndex = parseInt(fe.field, 10);
        if (inAddRange(fieldIndex)) {
          this.getErrorsList(fe.field).forEach((e) => {
            // Rebuilding the Error field path and field to have the same value that in OperatonView
            const newFieldPath = this.getNewErrorFieldPath(e.context.fieldPath,
              e.context.field, getNewFieldIndex(fieldIndex));
            const newFullField = newFieldPath.split('.');
            setToAdd.push({
              ...e,
              context: {
                ...e.context,
                fieldPath: newFieldPath,
                field: newFullField,
              },
            });
          });
        }
      });
      return setToAdd;
    },
    getNewErrorFieldPath(fieldPath, field, newIndex) {
      const newPath = fieldPath.substr(0, fieldPath.length - field.join('.').length);
      const tempField = [...field];
      tempField.shift();
      tempField.unshift(newIndex.toString());
      return newPath + tempField.join('.');
    },
    getErrorsList(feField) {
      if (!this.error) {
        return [];
      }
      return this.error.filter(e => e.context.field[0] === feField);
    },
    getErrorsForField(feField) {
      const associatedErrors = this.getErrorsList(feField);
      return associatedErrors.length === 0 ? undefined : associatedErrors;
    },
    isComposite(formElement) {
      return formElement && formElement.type === 'composite';
    },
    updateRepeatableError(setToRemove, setToAdd) {
      this.$emit('updateRepeatableError', setToRemove, setToAdd);
    },
  },
};
</script>

<style scoped lang="scss">
.rows {
  display: flex;
  flex-direction: column;
}

.gray-border {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  padding: 12px 12px 0px 12px;
  border-radius: 10px;
  border:1px solid var(--border-plus-ten);
  &.no-border {
    border:none;
  }
}
.add-remove-toggle-wrapper {
  margin: 0 10px 0 15px;
  height: 40px;
  display: flex;
  button:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  button:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.add-remove-toggle {
  min-width: 52px;
}

.disabled-composite {
  background-color: transparent;
  color: gray;
}

.remove-button-container {
  height: 0px;
  display: flex;
}

.remove-button-height {
  height: 20px;
}

.remove-button {
  margin-left: auto;
  margin-right: -20px;
  margin-top: -15px;
}

.remove-button-border {
  border: 0;
}

</style>
