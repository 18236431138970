import apis from '@/utils/apis';
import { fetchAccessToken } from '@/utils/apis/api';
import store from '@/store';
import { init } from '@/i18n';
import authz from '@/authz';
import BaseRoute from '@/app/components/BaseRoute';

import { routes as Login } from './Login';
import { routes as Trial } from './Trial';
import { routes as EnterpriseRegistration } from './EnterpriseRegistration';
import { routes as ApiDocs } from './ApiDocs';
import { routes as Main } from './Main';
import { routes as HelpCenter, LANGUAGE_ENTRY_POINT } from './HelpCenter';
import { routes as OAuth } from './OAuth';

const AuthEnabledRoutes = [
  {
    path: '/',
    component: BaseRoute,
    children: [
      ...Login,
      ...Main,
    ],

    async beforeEnter(to, from, next) {
      const i18nInit = init;
      if (!store.getters.userContext) {
        const result = await fetchAccessToken();
        if (result.logout) {
          store.dispatch('logout');
          await i18nInit();
          return next();
        }
        const resp = await apis.auth.getUserContext();
        if (resp.status >= 300) {
          store.dispatch('logout');
          await i18nInit();
          return next();
        }
        await i18nInit(resp.data.locale);
        store.dispatch('login', resp.data);
      } else {
        await i18nInit(store.getters.userContext.locale);
      }
      if (!authz.isPermittedRoute(to)) {
        return next('/');
      }
      return next();
    },
  },
];

const HOST_PREFIX = window.location.host.split('.')[0];
const IS_TRIAL_URL = (HOST_PREFIX === 'trial') || (HOST_PREFIX === 'essai') || (HOST_PREFIX === 'prueba');

const BaseRoutes = [
  {
    path: '/',
    component: BaseRoute,
    children: IS_TRIAL_URL ? [...Trial] : [
      {
        path: 'trial',
        component: BaseRoute,
        children: [...Trial],
      },
      ...HelpCenter,
      ...EnterpriseRegistration,
      ...ApiDocs,
      ...AuthEnabledRoutes, // should be at the end of the list
      ...OAuth,
    ],

    async beforeEnter(to, from, next) {
      // redirect if the browser has exactly one valid cookie
      await store.dispatch('loadGlobalProperties');
      const host = window.location.host.split('.');
      const language = LANGUAGE_ENTRY_POINT[host[0]];
      if (language && !to.path.startsWith('/hc')) {
        return next({ path: '/hc' });
      }
      return next();
    },
  },
];

export default BaseRoutes;
