import { CrudApi } from './api';

class QuotaApi extends CrudApi {
  constructor() {
    super('quotas');
  }

  deleteQuota(quotaId, replacementQuotaId) {
    return this.del(`quotas/${quotaId}`, replacementQuotaId);
  }

  createQuota(content) {
    return this.post('/quotas', { body: content });
  }

  assignQuota(quotaId, organizationId) {
    return this.post(`/quotas/${quotaId}/apply`, { body: { organizations: [{ id: organizationId }] } });
  }

  setDefault(quotaId, qs) {
    return this.post(`/quotas/${quotaId}/default`, qs);
  }
}

const quotaApi = new QuotaApi();

export default quotaApi;
