import { ReadOnlyApi } from './api';

class HtmlSnippetsApi extends ReadOnlyApi {
  constructor() {
    super('htmlsnippets');
  }

  getContent(lang, key) {
    return this.get(`/htmlsnippets/${lang}/${key}`).then(resp => resp.body);
  }
}

const helpCenterApi = new HtmlSnippetsApi();

export default helpCenterApi;
