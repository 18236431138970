<template>
  <div class="service-label">
    <base-image 
      class="logo" 
      :src="getConnectionIconUrl(connection)" 
      :altText="connection.type"
    />
    <div class="service-text">
      <div 
        v-if="environmentName" 
        class="env-name"
      >
        {{ environmentName }}
      </div>
      <div 
        v-if="connection.name" 
        :class="{'detail-text': environmentName}"
      >
        {{ connection.name }}
      </div>
      <div 
        v-if="showType" 
        class="detail-text"
      >
        <span v-if="$te(pluginLabel)">{{ $t(pluginLabel) }}</span>
        <span v-else>{{ display }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getConnectionIconUrl } from '@/utils/service';
import { mapGetters } from 'vuex';

export default {
  name: 'ServiceLabel',
  props: {
    environmentName: {
      type: String,
    },
    connection: {
      type: Object,
      required: true,
    },
    showType: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'serviceDescriptors'
    ]),
    serviceDescriptor() {
      return this.serviceDescriptors[this.connection.type];
    },
    pluginLabel() {
      return `${this.connection.type.toLowerCase()}.label.service_provider_name`;
    },
    display() {
      const displayName = this.serviceDescriptor?.displayName;
      return displayName || this.connection.type;
    }
  },
  methods: {
    getConnectionIconUrl,
  },
};
</script>

<style scoped lang="scss">

.service-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .logo {
    height: 22px;
    width: 22px;
  }

  .logo-icon {
    margin-right: 14px;
    font-size: 1.2em;
  }

  .service-text {
    padding-left: 5px;
    text-align: left;
  }

  .env-name {
    font-weight: bold;
  }
}
</style>
