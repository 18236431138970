<template>
  <base-list
    class="products"
    ariaLabel="monetization.products"
    :ariaRowCount="committedProducts.length"
  >
    <list-header>
      <list-column>{{ $t('monetization.product') }}</list-column>
      <list-column>{{ $t('monetization.commitments.committed_amount') }}</list-column>
      <list-column v-if="isUtilityDiscount">
        {{ $t('monetization.commitments.price') }}
      </list-column>
      <list-column v-if="isUtilityDiscount">
        {{ $t('monetization.commitments.discount') }}
      </list-column>
      <list-column v-if="isUtilityDiscount">
        {{ $t('monetization.commitments.discounted_price') }}
      </list-column>
      <list-column v-if="isUtilityDiscount">
        {{ $t('monetization.commitments.product_estimate_price') }}
      </list-column>
    </list-header>
    <list-row
      v-for="cp in committedProducts"
      :key="cp.product.id"
    >
      <list-column>
        <div>{{ cp.product.name[locale] }}</div>
        <div class="details">
          {{ cp.product.sku }}
        </div>
      </list-column>
      <list-column>
        <div
          class="committed-amount price-container"
        >
          <base-input
            v-model="cp.committedAmount"
            type="number"
            :error="(errors[cp.product.id] || {}).committedAmount"
            @focus="(errors[cp.product.id] || {}).committedAmount = null"
          />
        </div>
      </list-column>
      <list-column v-if="isUtilityDiscount">
        <div class="price-container">
          {{ getUnitPrice(cp) }}
          <committed-product-tier-tooltip
            v-if="hasTiers(cp)"
            class="tier-tooltip"
            :estimate="getEstimate(cp)"
          />
        </div>
      </list-column>
      <list-column v-if="isUtilityDiscount">
        <div class="committed-amount price-container">
          <base-input
            v-model="cp.discountAmount"
            type="number"
            :error="(errors[cp.product.id] || {}).discountAmount"
            suffix="%"
            @focus="(errors[cp.product.id] || {}).discountAmount = null"
          />
        </div>
      </list-column>
      <list-column v-if="isUtilityDiscount">
        <div class="price-container">
          {{ getDiscountUnitPrice(cp) }}
          <committed-product-tier-tooltip
            v-if="hasTiers(cp)"
            class="tier-tooltip"
            isDiscount
            :estimate="getEstimate(cp)"
          />
        </div>
      </list-column>
      <list-column v-if="isUtilityDiscount">
        <div class="price-container">
          {{ getEstimatedCost(cp) }}
        </div>
      </list-column>
      <template #actions>
        <action-icon
          class="action-item"
          tooltipLabel="delete"
          icon="fa fa-times"
          @click="$emit('removeProduct', cp)"
        />
      </template>
    </list-row>
  </base-list>
</template>
<script>
import CommittedProductTierTooltip from './CommittedProductTierTooltip'
import { currencyFormatters } from "@/utils/currency";
import { mapGetters } from "vuex";

export default {
  name: 'CommitmentFormCommittedProductList',
  components: { CommittedProductTierTooltip },
  props: {
    currency: {
      type: String,
      required: true,
    },
    pricingMethod: {
      type: String,
      required: true,
    },
    committedProducts: {
      type: Array,
      required: true,
    },
    productIdToEstimate: {
      type: Object,
      required: true,
    },
  },
  emits: ['removeProduct'],
  data() {
    return {
      errors: {},
    }
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    isUtilityDiscount() {
      return this.pricingMethod === 'UTILITY_DISCOUNT';
    },
    pendingErrors() {
      const labelPrefix = "monetization.commitments.operations.create.errors";
      return this.committedProducts.map(cp => {
        const error = {};
        const committedAmount = cp.committedAmount;
        if (committedAmount == null || (typeof(committedAmount) === 'string' && committedAmount.trim() === "") || parseFloat(committedAmount) < 0) {
          error.committedAmount = this.$t(`${labelPrefix}.invalid_commitment_amount`);
        }
        const discountAmount = cp.discountAmount;
        if (this.isUtilityDiscount && (discountAmount == null || (typeof(discountAmount) === 'string' && discountAmount.trim() === "") || 
            parseFloat(discountAmount) < 0 || 
            parseFloat(discountAmount) > 100)) {
          error.discountAmount = this.$t(`${labelPrefix}.invalid_discount_amount`);
        }
        if (!error.committedAmount && !error.discountAmount) {
          return null;
        }
        return {
          id: cp.product.id,
          ...error,
        };
      }).filter(o => o)
        .reduce((acc, cur) => ({
          ...acc,
          [cur.id]: { ...cur },
        }), {});
    },
  },
  methods: {
    hasErrors() {
      this.errors = JSON.parse(JSON.stringify(this.pendingErrors));
      return Object.values(this.errors || {}).length !== 0;
    },
    getEstimate(cp) {
      return this.productIdToEstimate[cp.product.id] || {
        unitPrice: 0,
        discountCost: 0,
        discountUnitPrice: 0,
      };
    },
    getUnit(cp) {
      const estimate = this.productIdToEstimate[cp.product.id] || {};
      return estimate.unit; // already in the right format
    },
    getUnitPrice(cp) {
      const estimate = this.getEstimate(cp);
      const unitPrice = (estimate.unitPrice || 0).toFixed(4);
      const currency = this.currency;
      const unit = this.getUnit(cp);
      return `${unitPrice} ${currency} / ${unit}`;
    },
    hasTiers(cp) {
      const estimate = this.productIdToEstimate[cp.product.id] || {};
      return estimate.hasTiers;
    },
    getDiscountUnitPrice(cp) {
      const estimate = this.getEstimate(cp);
      const unitPrice = (estimate.discountUnitPrice || 0).toFixed(4);
      const currency = this.currency;
      const unit = this.getUnit(cp);
      return `${unitPrice} ${currency} / ${unit}`;
    },
    getEstimatedCost(cp) {
      const estimate = this.getEstimate(cp);
      return currencyFormatters[this.locale]({
        style: "currency",
        currency: this.currency,
      }).format(estimate.discountCost || 0);
    },
  }
}
</script>
<style lang="scss" scoped>
.products {
  padding-top: 20px;
}

.details {
  font-size: 12px;
  color: var(--detail-text);
}

.committed-amount {
  padding-right: 2rem;
  :deep(.input-control) {
    input {
      padding: 10px 10px;
      width: 95%;
      flex-basis: 12rem;
      flex-grow: 0;
      flex-shrink: 1;
      box-sizing: border-box;
    }
    .has-suffix {
      flex-grow: 1;
    }
  }
}

.action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 95%;
}

.tier-tooltip {
  padding-left: 4px;
}
</style>
