<template>
  <advanced-details 
    :loading="loading" 
    :titleLabel="creationMode? titleLabel : ''"
  >
    <base-form
      :hideCancel="creationMode"
      :hideSubmit="!creationMode"
      :submitLabel="$t('done')"
      :cancelLabel="$t(cancelLabel)"
      :disabled="loading"
      @submit="$emit('assigned')"
      @cancel="$emit('cancel')"
    >
      <base-loader v-if="loading"></base-loader>
      <OrganizationHierarchyList
        v-else
        :organizations="filteredOrganizations"
        :navigation="false"
      >
        <template #columns="{ org }">
          <template v-if="org">
            <AssignableQuotaRow
              :organization="org"
              :quotas="quotas"
              :connection="connection"
              @refresh="refresh"
            />
          </template>
        </template>
      </OrganizationHierarchyList>
    </base-form>
  </advanced-details>
</template>

<script>
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import { sortBy } from '@/utils';
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import { mapGetters } from 'vuex';
import OrganizationHierarchyList from '@/app/Main/components/OrganizationHierarchyList';
import AssignableQuotaRow from './AssignableQuotaRow';

export default {
  name: 'AssignQuotaForm',
  components: { AssignableQuotaRow, OrganizationHierarchyList },
  mixins: [orgSwitchNavigationMixin],
  props: {
    connection: {
      type: Object,
      required: true,
    },
    creationMode: {
      type: Boolean,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'cancel'
    }
  },
  emits: ['assigned', 'cancel'],
  data() {
    return {
      loading: false,
      administrableOrgs: [],
      organizations: [],
      quotas: [],
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
    currentOrganization() {
      return this.selectedOrganization;
    },
    filteredOrganizations() {
      return this.organizations
        .filter(o => Object.keys(this.administrableOrgs).includes(o.id))
        .filter(o => o.serviceConnections.map(sc => sc.id).includes(this.connection.id));
    },
    titleLabel() {
      return this.creationMode
        ? this.$t('quotas.assign_to_organizations')
        : this.$t('assigned_organizations');
    },
  },
  async created() {
    this.loading = true;
    await this.refresh();
    this.loading = false;
  },
  methods: {
    async fetchOrganizations() {
      const orgListResp = await apis.organizations.getOrgListWithServiceConnections();
      if (orgListResp.ok) {
        this.organizations = orgListResp.data.sort(sortBy(o => o.name));
      } else {
        this.error = true;
      }
    },
    async fetchAdministableOrgs() {
      const administableOrgsResp = await apis.organizations.administrableOrgs();
      if (administableOrgsResp.ok) {
        this.administrableOrgs = administableOrgsResp.data;
      }
    },
    async fetchOwnedQuotas() {
      const qs = {
        qs: {
          type: 'owned',
          organization_id: this.selectedOrganization.id,
          connection_id: this.connection.id,
        },
      };
      const response = await apis.quotas.list(qs);
      if (response && response.status !== 200) {
        const message =
          response.status === 403
            ? 'quotas.not_authorized_to_list_quotas'
            : 'unexpected_error';
        notify.error(this.$t(message));
        return;
      }

      this.quotas = response.data.sort(sortBy(q => q.name));
    },
    async refresh() {
      await Promise.all([
        this.fetchAdministableOrgs(),
        this.fetchOrganizations(),
        this.fetchOwnedQuotas(),
      ]);
    },
  },
};
</script>
