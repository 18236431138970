<template>
  <div 
    v-if="contact.email || contact.phone" 
    class="contact-links"
  >
    <p>
      {{ $t(loginPage ? 'login.contact_us' : 'enterprise_registration.contact_us') }}
      <a
        v-if="contact.email"
        class="link"
        :href="`mailto:${contact.email}`"
      >{{ contact.email }}</a>
      <a 
        v-if="contact.phone"
        class="link"
        :href="`tel:${contact.phone}`"
      >{{ contact.phone }}</a>
    </p>
  </div>
</template>

<script>
export default {
    name: 'ContactUs',
    props: {
      contact: {
        type: Object,
        required: true,
      },
      loginPage: {
        type: Boolean,
        required: true,
      },
    }
}
</script>

<style scoped lang="scss">
.contact-links {
  display: flex;
  padding-bottom: 20px;
  font-size: 14px;
  gap: 20px;
}

.link {
  margin: 5px;
}
</style>