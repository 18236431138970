<script>
import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import Chart from './Chart';

use([LineChart]);

export default {
  name: 'SparklineChart',
  extends: Chart,
  computed: {
    toolboxData() { return {}; },
    seriesData() {
      return [
        {
          type: 'line',
          symbol: 'none',
          itemStyle: {
            color: 'rgb(255, 0, 0)',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0.01, color: 'rgba(253, 156, 166, 0.5)' },
                { offset: 1, color: 'rgba(253, 97, 112, 0.5)' },
              ],
            },
          },
          data: this.series,
          smooth: true,
        },
      ];
    },
    gridData() {
      return {
        containLabel: false,
        left: '0%',
        right: '0%',
        top: '0%',
        bottom: '0%',
      };
    },
    chartSpecificProps() {
      const showAxis = this.series.length !== 0;
      return {
        xAxis: {
          type: 'category',
          axisLine: {
            show: showAxis,
            lineStyle: {
              width: 3,
            },
          },
          axisLabel: {
            show: false,
          },
          show: true,
        },
        yAxis: {
          show: false,
          type: 'value',
          axisLabel: {
            show: false,
          },
          data: this.series,
          smooth: true,
        },
      };
    },
  },
};
</script>
