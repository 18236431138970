<script>
import merge from 'deepmerge';
import { use } from 'echarts/core';
import { BarChart } from 'echarts/charts';
import Chart from './Chart';

use([BarChart]);

export default {
  name: 'BarChart',
  extends: Chart,
  props: {
    switchAxis: {
      type: Boolean,
      default: false,
    },
    showLegends: {
      type: Boolean,
      default: true,
    },
    customLegendData: {
      type: Array,
      default: undefined,
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  emits:['mouseClicked', 'legendSelectChanged'],
  computed: {
    tooltipData: () => ({
      trigger: 'axis',
      confine: true,
      axisPointer: {
        type: 'shadow',
      },
    }),
    gridData: () => ({
      left: '0%',
      bottom: '0%',
    }),
    legendData() {
      return {
        data: this.customLegendData || this.series.map(e => ({ name: e.name, icon: e.icon})),
        show: this.showLegends,
        selected: this.selectedLegends,
        height: 100,
        left: '10%',
        padding: 0,
        itemGap: 50,
        textStyle: {
          maxWidth: 500,
          overflow: "break",
          fontSize: "12"
        }
    }},
    toolboxData() {
      return {
        show: 'false',
        feature: {
          saveAsImage: {
            show: false,
            title: 'save',
          },
        },
      }

    },
    selectedLegends() {
      return this.series.reduce((obj, e) => Object.assign(obj, { [e.name]: e.selected }), {})
    },
    seriesData() {
      const seriesStyle = {
        type: 'bar',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        selectedMode: 'multiple',
      };
      return this.series.map(e => merge(seriesStyle, e));
    },
    chartSpecificProps() {
      const xAxisData = {
        type: 'category',
        data: this.labels,
        axisLabel: {
          interval: '0',
        },
      };
      const yAxisData = {
        type: 'value',
      };
      const chartPropsToOverride = {
        xAxis: this.switchAxis ? yAxisData : xAxisData,
        yAxis: this.switchAxis ? xAxisData : yAxisData,
      };
      return this.customChartProp ?
        merge(chartPropsToOverride, this.customChartProp) :
        chartPropsToOverride;
    },
  },
  methods: {
    updateLegends(selectedLegends) {
      this.$refs.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: selectedLegends.name,
      });
    },
    toggleAllLegends(allLegends, enable) {
      allLegends.forEach((legend) => {
        this.$refs.chart.dispatchAction({
          type: enable ? 'legendSelect' : 'legendUnSelect',
          name: legend,
        });
      });
    },
    handleClick( eventMetadata ) {
      if (this.handlesMouseClick) {
        let clickedValue;
        if (eventMetadata.componentType === 'series') {
          clickedValue = eventMetadata.name
        } else {
          clickedValue = eventMetadata.value
        }
        this.$emit('mouseClicked', {
          daySelected: clickedValue
        });
      }
    },
    handleLegendSelectChanged(eventMetadata) {
      this.$emit('legendSelectChanged', eventMetadata);
    },
  }
};
</script>
