<template>
  <div>
    <div class="action-container">
      <action-dropdown 
        :items="actions" 
        @select="handleSelect"
      />
    </div>
    <confirm-modal 
      :open="confirmDelete && !setDefault"
      headerLabel="quotas.delete_quotas_header"
      :headerLabelInterpolation="{ name: selectedQuota.name }"
      :detailsLabel="quotaDeletionMessage"
      :executing="executing"
      :detailsInterpolation="{ name: selectedQuota.name, numberOfOrgs: selectedQuota.numberOfOrganizationsAppliedTo }"
      :disabled="isDefaultQuota"
      @ok="performDelete" 
      @cancel="confirmDelete=false"
    >
      <base-select 
        v-if="isAppliedToOrgs && !isDefaultQuota && hasQuotaReplacements" 
        v-model="selectedQuotaReplacement"
        :items="quotaReplacementCandidates" 
      ></base-select>
    </confirm-modal>
    <confirm-modal 
      :open="!confirmDelete && setDefault"
      headerLabel="quotas.set_default"
      :headerLabelInterpolation="{ name: selectedQuota.name }"
      detailsLabel="quotas.set_default_message"
      :executing="executing"
      :detailsInterpolation="{ numberOfOrgs: selectedQuota.numberOfOrganizationsAppliedTo }"
      @ok="handleSetDefaultAction" 
      @cancel="confirmDelete=false; setDefault=false"
    >
      <base-select 
        v-model="selectedDefaultAction"
        :items="setDefaultOptions" 
      ></base-select>
    </confirm-modal>
  </div>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import BaseSelect from '@/components/BaseSelect';
import { mapGetters } from 'vuex';
import authz from '@/authz';

const DEFAULT_FOR_SERVICE_LABEL = 'quotas.default_for_service';
const OTHER_QUOTAS_LABEL = 'quotas.other_quotas';
const SET_DEFAULT_ACTION = 'setDefault';
const SET_DEFAULT_FOR_SERVICE = 'service';
const SET_DEFAULT_FOR_TRIAL = 'trial';
const SET_DEFAULT_FOR_BOTH = 'both';
const MANAGE_ORGS = 'manage';
const DELETE_ACTION = 'delete';
export default {
  name: 'QuotasListActions',
  components: { BaseSelect },

  props: {
    selectedQuota: {
      type: Object,
      required: true,
    },
    quotas: {
      type: Array,
      required: true,
    },
  },
  emits: ['refresh'],
  data() {
    return {
      confirmDelete: false,
      setDefault: false,
      manageOrgs: false,
      executing: false,
      selectedQuotaReplacement: '',
      selectedDefaultAction: '',
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    actions() {
      if (!this.hasActionPermission()){
        return []
      }
      return [
        ...(this.canBeSetAsDefault ? [{
          value: SET_DEFAULT_ACTION,
          label: 'quotas.set_default',
          icon: 'fa fa-cog',
        }] : []),
        {
          value: MANAGE_ORGS,
          label: 'quotas.manage_assignment',
          icon: 'fa fa-thumb-tack',
          permission: 'reseller:quotas',
        },
        {
          value: DELETE_ACTION,
          label: 'delete',
          icon: 'fa fa-trash',
          permission: 'reseller:quotas',
        },
      ];
    },
    canBeSetAsDefault() {
      return (this.selectedOrganization.id === this.selectedQuota.serviceConnection.organization.id)
              && !(this.selectedQuota.defaultForTrial && this.selectedQuota.defaultForService);
    },
    isDefaultQuota() {
      return !!(this.selectedQuota.defaultForTrial || this.selectedQuota.defaultForService);
    },
    isAppliedToOrgs() {
      return !!this.selectedQuota.numberOfOrganizationsAppliedTo;
    },
    quotaDeletionMessage() {
      if (this.isDefaultQuota) {
        return 'quotas.delete_default_quota';
      } else if (this.isAppliedToOrgs) {
        if (!this.hasQuotaReplacements) {
          return 'quotas.delete_quotas_defaults_applied';
        }
        return 'quotas.delete_quotas_assigned';
      }
      return 'quotas.delete_quotas_details';
    },
    hasQuotaReplacements() {
      return this.quotaReplacementCandidates.length > 0;
    },
    quotaReplacementCandidates() {
      const eligibileQuotas = this.quotas
        .filter(q => this.selectedQuota.serviceConnection.id === q.serviceConnection.id)
        .filter(q => this.selectedQuota.id !== q.id)
        .map(q => ({
          ...q,
          value: q.id,
          label: q.name,
          group: this.labelQuotas(q),
        }));
      return this.sortQuotas(eligibileQuotas);
    },
    setDefaultOptions() {
      return [...(!this.selectedQuota.defaultForService ? [{
        value: SET_DEFAULT_FOR_SERVICE,
        label: 'quotas.default_for_service',
      }] : []), ...(!this.selectedQuota.defaultForTrial ? [{
        value: SET_DEFAULT_FOR_TRIAL,
        label: 'quotas.default_for_trial',
      }] : []), {
        value: SET_DEFAULT_FOR_BOTH,
        label: 'quotas.default_for_both',
      }];
    },
  },
  methods: {
    hasActionPermission() {
      return authz.hasPermission("reseller:quotas");
    },
    handleSetDefaultAction() {
      if (this.selectedDefaultAction === SET_DEFAULT_FOR_SERVICE) {
        this.performSetDefault(true, false);
      } else if (this.selectedDefaultAction === SET_DEFAULT_FOR_TRIAL) {
        this.performSetDefault(false, true);
      } else {
        this.performSetDefault(true, true);
      }
    },
    handleSelect(action) {
      if (action === DELETE_ACTION) {
        this.confirmDelete = true;
      }
      if (action === SET_DEFAULT_ACTION) {
        this.setDefault = true;
      }
      if (action === MANAGE_ORGS) {
        this.goToAssignQuotas();
      }
    },
    goToAssignQuotas() {
      this.$router.push({ name: 'quotaAssignedOrganizations',
        path: `/admin/quotas/${this.selectedQuota.id}/details/assigned_organizations`,
        params: { id: this.selectedQuota.id }, query: { edit: true} });
    },
    sortQuotas(quotas) {
      const sortOrder = { [DEFAULT_FOR_SERVICE_LABEL]: 0, [OTHER_QUOTAS_LABEL]: 1 };
      const sortedQuotas = quotas.sort(
        (a, b) => sortOrder[a.group] - sortOrder[b.group],
      );
      return sortedQuotas;
    },
    labelQuotas(q) {
      return (q.defaultForService ? DEFAULT_FOR_SERVICE_LABEL : OTHER_QUOTAS_LABEL);
    },
    async performSetDefault(service, trial) {
      this.executing = true;
      const qs = {
        qs: { service, trial },
      };
      const resp = await apis.quotas.setDefault(this.selectedQuota.id, qs);
      if (resp.ok) {
        notify.success(this.$t('quotas.set_default_success'));
        this.$emit('refresh');
      } else {
        notify.error(this.$t('quotas.set_default_error'));
      }
      this.setDefault = false;
      this.executing = false;
    },
    async performDelete() {
      this.executing = true;
      const qs = this.isAppliedToOrgs
        ? { qs: { quota_id: this.selectedQuotaReplacement } }
        : null;
      const resp = await apis.quotas.deleteQuota(this.selectedQuota.id, qs);
      if (resp && resp.status !== 200) {
        notify.error(this.$t('quotas.delete_quota_error', { name: this.selectedQuota.name }));
      } else {
        notify.success(this.$t('quotas.delete_quota_success', { name: this.selectedQuota.name }));
        this.$emit('refresh', this.selectedQuota.id);
      }
      this.executing = false;
      this.confirmDelete = false;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.base-select) {
    .base-dropdown {
        .dropdown.left {
            right: 25px;
            width: 600px;
        }
    }
}
</style>
