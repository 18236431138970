<template>
  <confirm-modal 
    :open="open" 
    headerLabel="add_verified_domain" 
    :disabled="!domainURL"
    @ok="execute" 
    @close="close" 
    @cancel="close" 
  >
    <div class="description">
      {{ $t('domain') }}
    </div>
    <base-input 
      v-model="domainURL"
      :error="error" 
    ></base-input>
  </confirm-modal>
</template>


<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';

export default {
  name: 'AddVerifiedDomain',
  components: { },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      domainURL: '',
      error: '',
    };
  },
  methods: {
    async execute() {
      const resp = await apis.organizations.createVerifiedDomain(
        this.organization.id, this.domainURL);
      if (resp.status !== 200 || !resp.data) {
        if (resp.errors && resp.errors[0].errorCode === 'ALREADY_EXISTS') {
          this.error = this.$t('already_claimed');
        } else {
          notify.error(this.$t('unexpected_error'));
        }
      } else {
        notify.success(this.$t('events.system.verified_domain.created.success', { domain: this.domainURL }));
        notify.sensitive(this.$store, {
          label: 'events.system.verified_domain.created.success',
          key: 'verification_code',
          value: resp.data.verificationCode,
          sensitive: false,
          context: {
            domain: name,
          },
        });
        this.domainURL = '';
        this.$emit('close');
      }
    },
    close() {
      this.error = '';
      this.domainURL = '';
      this.$emit('close');
    },
  },
};
</script>
