<template>
  <div class="secondary-title">
    <div id="transactions">
      {{ $t('transactions') }}
    </div>
  </div>
  <base-list
    aria-labelledby="transactions"
    :ariaRowCount="payments.length"
  >
    <list-header>
      <list-column>{{ $t('reports.invoice_id') }}</list-column>
      <list-column>{{ $t('status') }}</list-column>
      <list-column>{{ $t('payment.date_time') }}</list-column>
      <list-column>{{ $t('payment.confirmation_number') }}</list-column>
      <list-column>{{ $t('amount') }}</list-column>
    </list-header>
    <list-row
      v-for="payment in payments"
      :key="payment.id"
    >
      <list-column>
        <copyable-field
          :modelValue="payment.invoice.invoiceId"
          onHover
        >
          {{ payment.invoice.invoiceId }}
        </copyable-field>
      </list-column>
      <list-column>
        <base-tooltip
          v-if="payment.status === 'FAIL'"
          direction="bottom"
          overflow
          :message="payment.errorMsg"
        >
          <base-badge
            :state="getStatus(payment.status).label"
            :color="getStatus(payment.status).color"
          ></base-badge>
        </base-tooltip>
        <base-badge
          v-else
          :state="getStatus(payment.status).label"
          :color="getStatus(payment.status).color"
        />
      </list-column>
      <list-column>
        {{ getDateFormatted(payment.paymentDate) }}
      </list-column>
      <list-column class="confirmationCode-column">
        <base-tooltip
          direction="bottom"
          overflow
          :message="payment.confirmationNumber"
        >
          <copyable-field
            :modelValue="payment.confirmationNumber"
            onHover
            ellipsis
          >
            {{ payment.confirmationNumber }}
          </copyable-field>
        </base-tooltip>
      </list-column>
      <list-column>
        {{ formatShortCurrency(payment.totalAmount, payment.currencyCode) }}
      </list-column>
      <template #actions>
        <action-dropdown
          :items="actions"
          @select="onAction(payment.invoice, $event)"
        />
      </template>
    </list-row>
    <template #empty>
      <empty-message
        v-if="!payments.length"
        icon="fa fa-exchange"
        textLabel="payment.no_transactions"
      />
    </template>
  </base-list>
</template>

<script>
import { DEFAULT_DATE_FORMAT } from '@/utils/dates';
import { mapGetters } from 'vuex';
import { currencyMixin } from '@/mixins/currencyMixin';
import { invoiceMixin } from '@/mixins/invoiceMixin';

const DOWNLOAD_INVOICE = 'download_invoice';

export default{
  name: "TransactionList",
  mixins: [currencyMixin, invoiceMixin],
  props: {
    payments: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['locale']),
    actions() {
      return [
        {
          value: DOWNLOAD_INVOICE,
          label: 'reports.download_pdf',
          icon: 'fa fa-download',
        },
      ];
    },
  },
  methods: {
    getDateFormatted(date) {
      return this.$date(date, DEFAULT_DATE_FORMAT, true);
    },
    getStatus(status) {
      if (status === 'PENDING') {
        return {
          color: 'gray',
          label: 'payment.pending',
        };
      } else if (status === 'FAIL') {
        return {
          color: 'red',
          label: 'payment.fail',
        };
      }
      return {
        color: 'green',
        label: 'payment.success',
      };
    },
    async onAction(invoice, event) {
      if (event === DOWNLOAD_INVOICE) {
        invoice.organization = this.organization;
        this.downloadInvoice(invoice, this.locale);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.confirmationCode-column {
  display: block !important;
}
</style>