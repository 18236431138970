export const ALL_CONNECTIONS_OF_TYPE: string = "ALL_CONNECTIONS_OF_TYPE";
export const SPECIFIC_CONNECTIONS = "SPECIFIC_CONNECTIONS";
export const NOT_APPLICABLE: string = "N/A";
export const READ_ACCESS_LABEL: string = "role_form.read_access_permission";

// Source of truth: Core -> mc/model/configuration/Role.java
export enum EnvRoleTypeName {
  BUILT_IN = 'BUILT_IN',
  IMPLIED = 'IMPLIED',
  PLUGIN_DEFINED = 'PLUGIN_DEFINED',
  CUSTOM = 'CUSTOM'
}

export enum Tabs {
  SYSTEM_TAB = "systemTab",
  ENVIRONMENT_TAB = "environmentTab"
}

// Actions for the service scope
export enum ServiceScopeActions {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const ImpliedRole = {
  FULL_ACCESS: {
    name: 'Full-access (Admin)',
    label: 'role_list.env_roles.full_access.name',
    description: 'role_list.env_roles.full_access.description'
  },
  READ_ONLY_ACCESS: {
    name: 'Read-only-access (Admin)',
    label: 'role_list.env_roles.read_only_access.name',
    description: 'role_list.env_roles.read_only_access.description'
  }
}