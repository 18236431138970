import CmcBadge from "./CmcBadge.vue"
import CmcCard from "./CmcCard.vue"
import CmcCount from "./CmcCount.vue"
import CmcAccordion from "./CmcAccordion.vue"
import CmcAlert from "./CmcAlert.vue"

export default {
  CmcBadge,
  CmcCard,
  CmcCount,
  CmcAccordion,
  CmcAlert
}