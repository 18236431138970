<template>
  <div class="password-strength">
    <div 
      v-for="i in [0,1,2,3,4]" 
      :key="i" 
      class="strength-bar" 
      :class="[{ active: modelValue >= i }, `num-${i}`, direction]"
    >
      <div 
        class="fill state" 
        :class="color"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrength',
  props: {
    modelValue: {
      type: Number,
    },
  },
  data() {
    return {
      direction: 'right',
      colors: ['red', 'red', 'yellow', 'yellow', 'green'],
    };
  },
  computed: {
    color() {
      return this.colors[this.modelValue];
    },
  },
  watch: {
    modelValue(n, o) {
      if (n < o) {
        this.direction = 'left';
      } else {
        this.direction = 'right';
      }
    },
  },
};
</script>


<style scoped lang="scss">
$height: 12px;
$radius: 5px;

.password-strength {
    margin-top: 10px;
    display: flex;

    .strength-bar {
        flex: 1;
        height: $height;
        margin-right: 2px;
        background-color: #e5e5e5;

        &:first-child {
            &, .fill {
                border-top-left-radius: #{$radius};
                border-bottom-left-radius: #{$radius};
            }
        }

        &:last-child {
            &, .fill {
                border-top-right-radius: #{$radius};
                border-bottom-right-radius: #{$radius};
            }
        }

        .fill {
            height: $height;
            transform: scaleX(0);
            transition: transform 0.1s, background-color 0.5s 0.5s;
            transform-origin: left;
        }
        &.active {
            .fill {
                transform: scaleX(1);
            }
        }

        &.right {
            @for $i from 1 through 5 {
                &.num-#{$i} {
                    .fill {
                        transition-delay: #{$i * 0.1}s;
                    }
                }
            }
        }
        &.left {
            @for $i from 1 through 5 {
                &.num-#{5 - $i} {
                    .fill {
                        transition-delay: #{$i * 0.1}s;
                    }
                }
            }
        }
    }
}
</style>
