import { ReadOnlyApi } from './api';

class EmailTemplatesApi extends ReadOnlyApi {
  constructor() {
    super('content/emailTemplates');
  }
  
  preview(id, orgId) {
    return this.post(`/content/emailTemplates/${id}/preview`, { qs: { org_id: orgId } })
  }

  send(id, lang, orgId) {
    return this.post(`/content/emailTemplates/${id}/${lang}/send`, { qs: { org_id: orgId } })
  }
}

const emailTemplatesApi = new EmailTemplatesApi();

export default emailTemplatesApi;
