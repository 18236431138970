<template>
  <div>
    <h1>{{ $t('set_password_header') }}</h1>
    <p>{{ $t('reset_password_step2') }}</p>

    <base-form 
      :fullWidth="true" 
      :hideCancel="true" 
      :disabled="!isValid" 
      submitLabel="save_password"
      @submit="callReset" 
    >
      <form-row label="password">
        <div class="input-container">
          <choose-password 
            id="password" 
            v-model="password" 
            :isTrial="true" 
            :error="errors.password" 
            :disallowedStrings="orgInfo" 
            size="lg" 
            :rounded="true" 
            :placeholder="$t('password')" 
            @valid-new-password="validPassword" 
          />
        </div>
      </form-row>
      <form-row label="confirm_new_password">
        <div class="input-container">
          <base-input 
            id="confirmPassword" 
            v-model="passwordConfirm" 
            type="password" 
            :error="errors.passwordConfirm" 
            :placeholder="$t('reenter_password')" 
            icon="fa fa-lock" 
            size="lg" 
            :rounded="true" 
            required
          />
        </div>
      </form-row>
    </base-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import notify from '@/utils/notify';
import errors from '@/utils/errors';
import apis from '@/utils/apis';

export default {
  name: 'SetPasswordForm',
  props: {
    orgInfo: {
      type: Array,
      required: true,
    },
  },
  emits: ['rememberedPassword'],
  data() {
    return {
      password: '',
      passwordConfirm: '',
      errors: {
        password: false,
        passwordConfirm: false,
      },
      newPasswordValid: false,
    };
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    isValid() {
      return this.newPasswordValid;
    },
  },
  methods: {
    validate() {
      if (!this.password) {
        notify.error(this.$t('missing_password'));
      } else if (!this.passwordConfirm) {
        notify.error(this.$t('missing_password_confirm'));
      } else if (this.password !== this.passwordConfirm) {
        notify.error(this.$t('password_confirm_error'));
      }
      this.errors.password = !this.password;
      this.errors.passwordConfirm = !this.passwordConfirm
        || (this.password !== this.passwordConfirm);

      return !(this.errors.password || this.errors.passwordConfirm);
    },
    validPassword(value) {
      this.newPasswordValid = value;
    },
    async callReset() {
      notify.destroy();
      if (!this.validate()) {
        return;
      }

      const res = await apis.trials.setTrialPassword(this.$route.query.trialToken, this.password);
      const status = res.status;

      if (status === 200) {
        notify.success(this.$t('password_was_reset'));
        this.$router.replace('/home');
        this.$emit('rememberedPassword');
      } else if (res.errors.find(e => e.errorCode === 'NATIVE_AUTHENTICATION_NOT_SUPPORTED')) {
        notify.error(this.$t('native_authentication_disabled'));
      } else if (status === 400) {
        errors.parse(this.$t.bind(this), res, null).forEach(notify.error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/login-form';

#set-password-form {
  :deep(.form-row .label-row) {
    display: none;
  }
}
</style>
