import BaseRoute from '@/app/components/BaseRoute';

const UserList = () => import(/* webpackChunkName: "admin" */ './UserList');
const AddUser = () => import(/* webpackChunkName: "admin" */ './add/AddUser');
const UpdateUser = () => import(/* webpackChunkName: "admin" */ './update/UpdateUser');
const UserDetails = () => import(/* webpackChunkName: "admin" */ './user/UserDetails');
const UserServiceConnections = () => import(/* webpackChunkName: "admin" */ './user/UserServiceConnections');
const UpdateScope = () => import(/* webpackChunkName: "admin" */ './update/UpdateScope');
const UpdateUserEnvironments = () => import(/* webpackChunkName: "admin" */ './update/UpdateUserEnvironments');
const UserPage = () => import(/* webpackChunkName: "admin" */ './user/UserPage');
const UserExternalAuthentication = () => import(/* webpackChunkName: "admin" */ './user/UserExternalAuthentication');

export default [
  {
    path: 'users',
    component: BaseRoute,
    meta: {
      protected: ['admin:users'],
      title: () => 'users',
    },
    children: [
      {
        name: 'usersList',
        path: '',
        component: UserList,
      },
      {
        name: 'addUser',
        path: 'add',
        component: AddUser,
        props: { switchOrgRouteName: 'usersList' },
      },
      {
        path: ':id',
        component: UserPage,
        meta: {
          changeOrgRedirect: '/admin/users',
        },
        props: route => ({ id: route.params.id, switchOrgRouteName: 'usersList' }),
        children: [
          {
            path: '',
            redirect: { name: 'userDetails' },
          },
          {
            name: 'userDetails',
            path: 'details',
            component: UserDetails,
            meta: {
              title: () => 'user_details',
            },
          },
          {
            name: 'updateUser',
            path: 'details/edit',
            component: UpdateUser,
          },
          {
            name: 'updateAdditionalRoles',
            path: 'additional-roles',
            component: UpdateScope,
            meta: {
              title: () => 'user_additional_roles',
            },
          },
          {
            name: 'updateUserEnvironments',
            path: 'environment-membership',
            component: UpdateUserEnvironments,
            meta: {
              title: () => 'user_environments',
            },
          },
          {
            name: 'userExternalAuthentication',
            path: 'external_authentication',
            component: UserExternalAuthentication,
            meta: {
              title: () => 'external_authentication',
            },
          },
          {
            name: 'viewAssignedServices',
            path: 'assigned-services',
            component: UserServiceConnections,
            meta: {
              title: () => 'assigned_services',
            },
          },
        ],
      },
    ],
  },
];
