// Offset factor for nested sub-lists and rows
const NESTED_OFFSET_FACTOR = 1.5;
const NESTED_OFFSET_UNITY = 'rem';
/**
 * Allows you to build the css to be applied to the first column of a nested row
 * which will create the offset (padding-left)
 * @param nestedLevel The nesting level of the line/sub-list
 * @param isCollapsibleRow If the column is in a collapsible
 */
export function getColOffsetStyle(nestedLevel: number, isCollapsibleRow: boolean = false) {
    // If it is in a collapsible, we will reduce the offset by one unit so that the arrow is displayed before the content of the row
    // and that the content of the line is aligned with that of other lines of the same level
    let adjustment = isCollapsibleRow ? -1 : 0;

    // We reduce by one unit to start the offset from the second nesting level
    adjustment += nestedLevel >= 1 ? -1 : 0;
    const offset = (nestedLevel + adjustment) * NESTED_OFFSET_FACTOR;
    const offsetWithUnity = `${offset}${NESTED_OFFSET_UNITY}`;
    return  offset > 0 ? { 'padding-left': offsetWithUnity } : { 'margin-left': offsetWithUnity};
}
