<template>
  <transition-group :name="right ? 'slide-right' : 'slide-left'">
    <template v-for="i in [0, 1]">
      <div 
        v-if="slideIdx === i" 
        :key="i"
        class="transition-container" 
      >
        <slot></slot>
      </div>
    </template>
  </transition-group>
</template>

<script>
export default {
  data() {
    return {
      slideIdx: 0,
      right: false,
    };
  },
  methods: {
    slideRight() {
      this.right = true;
      this.slideIdx = (this.slideIdx + 1) % 2;
    },
    slideLeft() {
      this.right = false;
      this.slideIdx = (this.slideIdx + 1) % 2;
    },
  },
};
</script>

<style scoped lang="scss">
.slide-right-enter-from {
    transform: translateX(100%);
    opacity: 0;
    position: absolute;
    width: 100%;
}

.slide-right-leave-to {
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
    width: 100%;
}

.slide-left-enter-from {
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
    width: 100%;
}

.slide-left-leave-to {
    transform: translateX(100%);
    opacity: 0;
    position: absolute;
    width: 100%;
}
.transition-container {
  transition: transform 1s, opacity 1s;
  overflow-x: hidden;
  flex: 1;
  overflow-y: auto;
}

</style>
