import { CrudApi } from './api';

const ROOT_CONTEXT = '/content/notifications';
class NotificationsApi extends CrudApi {
  constructor() {
    super(ROOT_CONTEXT);
  }
  getNotifications(currentLocale) {
    const qs = { language: currentLocale };
    return this.get(`${ROOT_CONTEXT}/live`, { qs });
  }
  readNotifications(id) {
    return this.put(`${ROOT_CONTEXT}/readNotifications`, { body: id });
  }
}
const notifications = new NotificationsApi();
export default notifications;
