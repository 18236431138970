const cf = (locale, options = {}) => new Intl.NumberFormat(locale, {
  ...options
});

const currencyFormatters = {
  "en": (options = {}) => cf('en-US', options),
  "fr": (options = {}) => cf('fr-CA', options),
  "es": (options = {}) => cf('es', options)
}

export {
  currencyFormatters,
}
