import { subDays, daysBetweenDates, getDate, isAfter, isBefore, getNow, addMonths } from '@/utils/dates';
import { computeUsageInTier } from '@/utils/usage';
import { pricingUnitFormatterMixin } from '@/mixins/pricingUnitFormatter';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';

export const commitmentMixin = {
  mixins: [pricingUnitFormatterMixin, billingCycleMixin],
  methods: {
    canDelete(commitment, billingCycles) {
      return billingCycles
        .filter(bc => !this.isOngoing(bc))
        .flatMap(bc => bc.commitments || [])
        .map(bcc => bcc.commitment.id)
        .every(cid => cid !== commitment.id);
    },
    canTerminate(commitment, billingCycles) {
      const ced = commitment.endDate || "9999-12-31";
      // not deletable implies paid in previous billing cycle
      return !this.canDelete(commitment, billingCycles) &&
        billingCycles
          .filter(bc => this.isOngoing(bc))
          .filter(bc => !isBefore(ced, bc.endDate)) // filters commitments set to expire in cycle
          .flatMap(bc => bc.commitments || [])
          .map(bcc => bcc.commitment.id)
          .some(cid => cid === commitment.id);
    },
    canUpdate(commitment, billingCycles) {
      return commitment.status === "UPCOMING" ||
        billingCycles
          .filter(bc => this.isOngoing(bc))
          .flatMap(bc => bc.commitments || [])
          .filter(bcc => bcc.commitment.id === commitment.id)
          .filter(bcc => bcc.scope === "INVOICE")
          .length > 0
    },
    getOrgIdToCycles(billingCycles) {
      return billingCycles
        .reduce((acc, cur) => {
          if (!cur.billableOrganizationInfo) {
            return acc;
          }
          const bcs = acc[cur.billableOrganizationInfo.organization.id] || [];
          return {
            ...acc,
            [cur.billableOrganizationInfo.organization.id]: [...bcs, cur]
          };
        }, {});
    },
    getOrgIdToCommitments(commitments) {
      return commitments
        .reduce((acc, cur) => {
          return {
            ...acc,
            [cur.organization.id]: [...(acc[cur.organization.id] || []), cur]
          }
        }, {});
    },
    getCommitmentIdToBillingCycles(billingCycles) {
      return billingCycles
        .reduce((acc, cur) => {
          const commitments = cur.commitments || [];
          commitments.forEach(bcc => {
            if (!acc[bcc.commitment.id]) {
              acc[bcc.commitment.id] = [];
            }
            const cycles = acc[bcc.commitment.id];
            if (!cycles.some(c => c.id === cur.id)) {
              cycles.push(cur);
            }
          });
          return acc;
        }, {});
    },
    commitmentStates() {
      return {
        IN_PROGRESS: {
          label: "monetization.commitments.status.in_progress",
          color: "green",
        },
        UPCOMING: {
          label: "monetization.commitments.status.upcoming",
          color: "yellow",
        },
        EXPIRED: {
          label: "monetization.commitments.status.expired",
          color: "grey",
        },
        TERMINATED: {
          label: "monetization.commitments.status.terminated",
          color: "orange",
        },
      };
    },
    calculateCommitmentState(commitment) {
      if (commitment && commitment.terminated) {
        // In case the Terminated commitment is past its time
        if (isBefore(commitment.endDate, getNow(true))) {
          return this.commitmentStates()[commitment.status];
        }
        return this.commitmentStates()["TERMINATED"];
      }
      if (commitment && commitment.status && this.commitmentStates()[commitment.status] !== undefined) {
        return this.commitmentStates()[commitment.status];
      }
      return { color: "", label: "" };
    },
    getCommitmentState(commitment) {
      return this.calculateCommitmentState(commitment).label;
    },
    getCommitmentColor(commitment) {
      return this.calculateCommitmentState(commitment).color;
    },
    startDate(commitment) {
      return this.$date((commitment || {}).startDate, 'YYYY-MM-DD', true);
    },
    endDate(commitment) {
      if (!commitment || !commitment.endDate) {
        return this.$t("monetization.none");
      }
      const ed = subDays(commitment.endDate, 1);
      return this.$date(ed, 'YYYY-MM-DD', true);
    },
    computeCommittedProductEstimate(commitment, billingCycle, pricedProductMap) {
      // tiers = YES | NONE
      //   | -> pricing mode = UNIT_PRICE | FLAT_FEE
      // period = HOUR | MONTHLY | NONE
      const currency = billingCycle.currency;
      const effectiveBillingCycleStart = this.getEffectiveBillingCycleStart(commitment, billingCycle);
      const effectiveBillingCycleEnd = addMonths(effectiveBillingCycleStart);
      const commitmentFactor = this.computeCommitmentFactor(commitment, effectiveBillingCycleStart, effectiveBillingCycleEnd);
      return commitment.committedProducts.map(cp => {
        const pricedProduct = this.getPricedProduct(commitment, cp, billingCycle, pricedProductMap);
        if (!pricedProduct) {
          return {
            id: cp.product.id,
            unitPrice: 0,
            tiers: [],
          };
        }

        // cache value to avoid recomputing in loop
        let periodFactor = this.computePeriodFactor(cp, effectiveBillingCycleStart, effectiveBillingCycleEnd);
        const committedAmount = parseFloat(cp.committedAmount);
        const discountFactor = parseFloat(cp.discountAmount) / 100;
        const costFactor = periodFactor * commitmentFactor;

        // pricing tiers
        let price = 0; // cumulative tier price
        const hasTiers = pricedProduct.pricingTiers && pricedProduct.pricingTiers.length > 0;
        const pricingTiers = this.getTiers(currency, pricedProduct);

        // pricing mode
        const pricingMode = (pricingTiers[0] || {}).pricingMode;

        // tier breakdown
        const tiers = pricingTiers.reduce((acc, cur) => {
          const usage = computeUsageInTier(committedAmount, cur);

          // tier-level prices
          const unitPrice = cur.price[currency];
          const tierPrice = usage * unitPrice;

          // product-level price
          price += tierPrice;

          acc.push({
            id: cur.id,
            lowerBound: cur.lowerBound,
            upperBound: cur.upperBound,
            usage,
            chunkSize: cur.chunkSize,
            unitPrice,
            tierPrice,
            cost: tierPrice * costFactor,
            discountUnitPrice: (1 - discountFactor) * unitPrice,
            discountPrice: (1 - discountFactor) * tierPrice,
            discountCost: (1 - discountFactor) * tierPrice * costFactor,
          });
          return acc;
        }, []);

        // product-level prices
        const discountPrice = (1 - discountFactor) * price;

        // weighted average unit price
        const unitPrice = price / committedAmount;

        return {
          id: cp.product.id,
          pricingMode,
          currency,
          deprecated: pricedProduct.deprecated,
          unit: this.formatProductUnit(cp.product),
          unitPrice,
          discountUnitPrice: (1 - discountFactor) * unitPrice,
          price,
          cost: price * costFactor,
          discountPrice,
          discountCost: discountPrice * costFactor,
          hasTiers,
          tiers,
        };
      }).reduce((acc, curr) => ({
        ...acc,
        [curr.id]: curr
      }), {});
    },
    getPricedProduct(commitment, cp, billingCycle, pricedProductMap) {
      if (commitment.rateType === 'FIXED_RATE') {
        return pricedProductMap[cp.id];
      }

      const commitmentStart = getDate(commitment.startDate);
      const billingCycleStart = getDate(billingCycle.startDate);
      const dateForEstimation = isAfter(commitmentStart, billingCycleStart) ? commitment.startDate : billingCycle.startDate;

      return pricedProductMap[cp.id] ? pricedProductMap[cp.id][dateForEstimation] : null;
    },
    getTiers(currency, pricedProduct) {
      const hasTiers = pricedProduct.pricingTiers && pricedProduct.pricingTiers.length > 0;
      const fakeTier = [{
        lowerBound: 0,
        price: { [currency]: pricedProduct.unitPrice[currency] },
      }];
      return hasTiers ? pricedProduct.pricingTiers : fakeTier;
    },
    getEffectiveBillingCycleStart(commitment, billingCycle) {
      let bcStart = getDate(billingCycle.startDate);
      let bcEnd = getDate(billingCycle.endDate);
      const commitmentStart = getDate(commitment.startDate);
      if (isAfter(commitmentStart, subDays(bcEnd)))  {
        bcStart = new Date(commitmentStart.getUTCFullYear(), commitmentStart.getUTCMonth(), bcStart.getUTCDate());
      }
      return bcStart;
    },
    computePeriodFactor(cp, start, end) {
      const hoursInMonth = daysBetweenDates(start, end) * 24;
      return (cp.product.period || "").toLocaleLowerCase() === "hour" ? hoursInMonth : 1;
    },
    computeCommitmentFactor(commitment, billingCycleStart, billingCycleEnd) {
      const commitmentStart = getDate(commitment.startDate);
      const commitmentEnd = commitment.endDate ? getDate(commitment.endDate) : null;

      const trueStart = isAfter(commitmentStart, billingCycleStart) ? commitmentStart : billingCycleStart;
      const trueEnd = commitmentEnd && (isBefore(commitmentEnd, billingCycleEnd)) ? commitmentEnd : billingCycleEnd;

      const daysInCycle = daysBetweenDates(billingCycleStart.toString(), billingCycleEnd.toString());
      const daysInCommitmentCycle = daysBetweenDates(trueStart, trueEnd);

      return daysInCommitmentCycle / daysInCycle;
    },
    computeReprocessStartDate(minBillingCycleStartDate, commitmentStartDate) {
      if (isBefore(minBillingCycleStartDate, commitmentStartDate)) {
        return commitmentStartDate;
      }
      return minBillingCycleStartDate
    },
  },
};
