import { CrudApi } from './api';

class TaxProvidersApi extends CrudApi {
  constructor() {
    super('/tax_providers');
  }

  configuration() {
    return this.get('tax_providers/configuration');
  }

  validateAddress(type, addressValidation) {
    return this.post(`tax_providers/${type}/validate-address`, { body: addressValidation });
  }

  reattemptConfiguration(id) {
    return this.post(`tax_providers/${id}/reattempt-configuration`);
  }

  testConfiguration(type, configuration) {
    return this.post(`tax_providers/${type}/test-configuration`, { body: configuration });
  }

  listTaxCodes(orgId) {
    return this.get(`tax_providers/${orgId}/tax-codes`);
  }

  getTaxProviderConfigured(orgId) {
    return this.get(`tax_providers/${orgId}/tax-provider-configured`);
  }
}
const taxProvidersApi = new TaxProvidersApi();
export default taxProvidersApi;
