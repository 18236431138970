function stopProp(event) {
  event.stopPropagation();
}

/**
 * Does not currently support nested elements that both contain v-click-outside
 * since the outer handler will prevent propagation on the element which encompases
 * the inner element.
 */
export default {
  name: 'click-outside',
  beforeMount(el, binding) {
    if (binding.value) {
      el.addEventListener('click', stopProp);
      setTimeout(() => {
        document.body.addEventListener('click', binding.value);
      });
    }
  },

  unmounted(el, binding) {
    if (binding.value) {
      el.removeEventListener('click', stopProp);
      document.body.removeEventListener('click', binding.value);
    }
  },
};
