<template>
  <base-loader v-if="loading" />
  <commitment-details
    v-else
    :commitment="commitment"
    :cycle="cycle"
    :pricing="pricing"
    :billingCycles="billingCycles"
  />
</template>

<script>
import { fetchCommitment, fetchCycles, findCycleForCommitment, fetchPricing } from "@/utils/commitments";
import { cycleDate } from "@/utils/billing_cycles";
import CommitmentDetails from "./CommitmentDetails";

export default {
  name: "CommitmentDetailsWrapper",
  components: { CommitmentDetails },
  beforeRouteLeave(_to, _from, next) {
    this.$emit('addToBreadcrumb', []);
    next();
  },
  props: {
    commitmentId: {
      type: String,
      required: true,
    },
    cycleId: {
      type: String,
      required: true,
    },
  },
  emits: ['addToBreadcrumb'],
  data() {
    return {
      commitment: undefined,
      pricing: undefined,
      cycle: undefined,
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    
    this.commitment = await fetchCommitment(this.commitmentId);
    this.billingCycles = await fetchCycles(this.commitment.organization);
    this.cycle = await findCycleForCommitment(this.commitment, this.billingCycles, this.cycleId);
    this.pricing = await fetchPricing(this.cycle);    

    // add to breadcrumb
    this.$emit("addToBreadcrumb", [
      {
        label: "billing",
        withI18n: true,
        path: "/admin/organizations/" + this.commitment.organization.id + '/billing/overview',
      },
      {
        label: cycleDate(this.cycle),
        withI18n: false,
      },
      {
        label: "monetization.commitments.title",
        withI18n: true,
      },
      {
        label: this.commitment.name,
        withI18n: false,
      },
    ]);

    this.loading = false;
  },
};
</script>
