<template>
  <div 
    v-if="items.length"
    class="breadcrumb" 
  >
    <div 
      v-for="(b, $index) in reducedItems" 
      :key="b.name" 
      class="breadcrumb-item"
    >
      <slot 
        :item="b" 
        :index="$index" 
        :last="$index === reducedItems.length - 1"
      >
        <div 
          class="breadcrumb-text" 
          :class="[isFocused(b), { last: $index === reducedItems.length - 1 }]"
          @mousedown="clicked = true" 
          @focus.capture="focusHandler(true)" 
          @blur.capture="focusHandler(false)" 
        >
          <base-icon 
            v-if="b.logo" 
            :icon="b.logo" 
            color="white"
          />
          <app-link 
            v-if="b.to" 
            :to="toWithPreviousQuery(b.to)"
          >
            {{ b.label ? $t(b.label) : b.name }}
          </app-link>
          <div 
            v-else 
            :class="{ 'clickable': b.cancelable }" 
            :role="b.cancelable? 'button' : ''" 
            :tabindex="b.cancelable? 0 : -1" 
            @click="cancel(b.cancelable)" 
            @keyup.enter="cancel(b.cancelable)"
          >
            <base-image 
              v-if="b.icon" 
              :src="b.icon" 
              :altText="breadcrumbText(b)"
            />
            {{ breadcrumbText(b) }}
          </div>
        </div>
      </slot>
      <template v-if="hasMoreThanMax && $index === 1">
        <div class="caret">
          <base-icon icon="fa fa-caret-right" />
        </div>
        <div class="dots">
          ...
        </div>
      </template>
      <div class="caret">
        <base-icon icon="fa fa-caret-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const MAX_LENGTH = 4;

export default {
  name: 'BaseBreadcrumb',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['cancel'],
  data() {
    return {
      isChildFocused: false,
      clicked: false,
    };
  },
  computed: {
    ...mapGetters(['previousRoute']),
    reducedItems() {
      if (this.hasMoreThanMax) {
        const skipIdx = (this.items.length + 2) - MAX_LENGTH;
        return [this.items[0], this.items[1], ...this.items.slice(skipIdx)];
      }
      return this.items;
    },
    hasMoreThanMax() {
      return this.items.length > (MAX_LENGTH + 1);
    },
  },
  methods: {
    toWithPreviousQuery(initialTo) {
      if (initialTo.name && initialTo.name === (this.previousRoute || {}).name) {
        initialTo.query = {
          ...this.previousRoute.query,
          ...initialTo.query,
        };
      }
      return initialTo;
    },
    cancel(cancelable) {
      if (cancelable) {
        this.$emit('cancel');
      }
    },
    focusHandler(focusGained) {
      if (focusGained) {
        this.isChildFocused = true;
      } else {
        this.isChildFocused = false;
        this.clicked = false;
      }
    }, 
    isFocused(curItem) {
      if (this.isChildFocused && !this.clicked && curItem.to) {
        return "focused";
      }
    },
    breadcrumbText(bc) {
      const name = bc.label ? this.$tn(bc.label) : bc.name;
      return `${name}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.breadcrumb {
  text-align:left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include phone {
    margin-left: 5px;
  }

  img {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .breadcrumb-item {
    .breadcrumb-text {
      display: flex;
      align-items: center;
      &.focused {
        outline: var(--blue) solid medium;
      }
    }

    :deep(*) {
      max-width: 80vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    white-space: nowrap;
    &:last-child {
      font-weight: bold;
      .caret {
        display: none;
      }
    }
  }

  .caret {
    margin-left:8px;
    margin-right:8px;
    justify-content: center;
    align-items: center;
    .fa {
      font-size: 0.9rem;
    }
  }

  .dots {
      justify-content: center;
      align-items: center;
      font-weight: bold;
  }

  div {
    display: flex;
    flex-direction: row;
  }
}
</style>
