<template>
  <template v-if="!loading">
    <api-docs-header :docs="docs"></api-docs-header>
    <router-view 
      :key="`${serviceType}:${mobile}`" 
      :docs="docs"
      :mobile="mobile"
    ></router-view>
  </template>
</template>

<script>
import apis from '@/utils/apis';
import { sortBy, isMobileMode } from '@/utils';
import ApiDocsHeader from './components/ApiDocsHeader';

export default {
  components: { ApiDocsHeader },
  data() {
    return {
      serviceTypes: [],
      loading: true,
      mobile: isMobileMode(),
    };
  },
  computed: {
    serviceType() {
      return this.$route.params.serviceType;
    },
  },
  mounted() {
    this.$nextTick(() =>
      window.addEventListener('resize', () => {
        this.mobile = isMobileMode();
      }));
  },
  async created() {
    const resp = await apis.docs.list();
    this.docs = resp.data.sort(sortBy(d => d.serviceType));
    this.serviceTypes = this.docs.map(d => d.serviceType);
    const cur = this.$route.params.serviceType;
    if (this.serviceType && !this.serviceTypes.includes(cur)) {
      this.$router.replace('/api-docs/' + this.serviceTypes[0]);
    }
    this.loading = false;
  },
};
</script>