import store from '@/store';
import notify from '@/utils/notify';
import * as utils from '@/utils/activity';
import i18n from '@/i18n';
import { isEqual } from '@/utils';
import useEvents from '@/utils/events/useEvents';
import apis from '@/utils/apis';

const processedEvents = {};

const NOTIFICATION_DEBOUNCE = 300;
let eventsToNotify = [];

const notifyEvent = async (auditEvent) => {
  const label = utils.getActivityLabel(auditEvent);
  const eventCtx = utils.getEventContext(auditEvent);
  const message = i18n.global.t(label, eventCtx);
  
  // Comment out title as part of MC-25380. Should remove it completely, if we like not having title.
  // const title = utils.buildTitle(auditEvent.status);
  const title = '';
  const btn = utils.buildButton(auditEvent, eventCtx);

  if (auditEvent.status === 'PENDING') {
    notify.pending(message, title);
  } else if (auditEvent.status === 'SUCCESS') {
    notify.success(message, title, btn);
  } else if (auditEvent.status === 'FAILURE') {
    if (Object.prototype.hasOwnProperty.call(auditEvent, 'serviceError')) {
      const task = await apis.tasks.find(auditEvent.correlationId, {});
      if (task.data && task.data.customErrorLabel) {
        const customError = i18n.global.t(task.data.customErrorLabel,
          task.data.customErrorLabelContext || {});
        notify.error(customError, title, btn);
        return;
      }
    }
    notify.error(message, title, btn);
  } else if (auditEvent.status === 'PARTIAL_SUCCESS') {
    notify.warning(message, title);
  }
};

const dequeueEvents = () => {
  // eslint-disable-next-line
  const now = new Date();
  const dequeued = [];
  while (eventsToNotify.length) {
    if ((now - eventsToNotify[0].capturedDate) > NOTIFICATION_DEBOUNCE) {
      dequeued.push(eventsToNotify.shift());
    } else {
      break;
    }
  }
  return dequeued;
};

setInterval(() => {
  dequeueEvents().map(e => e.auditEvent).forEach(notifyEvent);
}, NOTIFICATION_DEBOUNCE);

const addToNotifyQueue = (auditEvent) => {
  eventsToNotify = eventsToNotify.filter(e => e.auditEvent.id !== auditEvent.id);
  // eslint-disable-next-line
  eventsToNotify.push({ capturedDate: new Date(), auditEvent });
};

const isSystemEvent = data => data.category === 'SYSTEM';
const ALLOWED_SYSTEM_NOTIFICATIONS = [
  'organizations.provision', 'organizations.create', 'organizations.update', 'organizations.deleted',
  'environments.create', 'environments.delete', 'environments.update', 'environments.link_operation', 'environments.link',
  'environments.unlink', 'users.purge', 'users.created', 'users.deleted', 'users.updated',
  'environment_members.create', 'environment_members.delete', 'environment_members.update', 'environment_members.provision',
  'environment_members.auto_membership_enable', 'environment_members.auto_membership_disable',
  'knowledgebase.synched', 'knowledgebase.created', 'knowledgebase.updated', 'knowledgebase.deleted',
  'brandings.deleted', 'authentication.created', 'verified_domain.created', 'verified_domain.verified',
  'verified_domain.deleted', 'quotas.set_default', 'billing_provider.deleted', 'tax_providers.create',
  'tax_providers.deleted', 'tax_providers.retry, tax_providers.updated', 'organization_features.delete',
  'organization_features.create', 'organization_features.update', 'batch.operation'
];
const shouldNotifySystemEvent = data => !isSystemEvent(data)
                                        || ALLOWED_SYSTEM_NOTIFICATIONS.includes(data.eventCode);

const handle = (data) => {
  if (processedEvents[data.id] &&
    processedEvents[data.id].status === data.status &&
    (isEqual(processedEvents[data.id].eventContext, data.eventContext))) {
    return;
  }
  processedEvents[data.id] = data;
  if (isSystemEvent(data)) {
    const { postSystemActivity } = useEvents();
    postSystemActivity(data.eventCode, data);
  }

  if (!data.eventContext || (data.parent && data.parent.id)) {
    return;
  }

  if (store.getters.userId === data.userId && shouldNotifySystemEvent(data)) {
    if (data.windowId === store.getters.windowId) {
      addToNotifyQueue(data);
    }


    if (data.status === 'PENDING' || (data.status === 'SUCCESS' && data.eventCode === 'batch.operation')) {
      store.dispatch('addNotification', data);
    } else {
      store.dispatch('updateNotification', data);
    }
  }
};

export default {
  handle,
};
