const ConfigPropertiesView = () => import(/* webpackChunkName: "system" */ './ConfigPropertiesView');

export default [
  {
    path: 'properties',
    component: ConfigPropertiesView,
    meta: {
      protected: ['global:sys:configProperties'],
      title: () => 'properties',
    },
  },
];
