import {loadLanguageAsync, init} from '@/i18n';
import OAuthLanding from './OAuthLanding.vue';
import OAuthResult from './OAuthResult.vue';

export default [
  {
    path: '/oauth/landing',
    component: OAuthLanding,
    children: [
        {
            path: 'success',
            component: OAuthResult
        },
        {
            path: 'failure',
            component: OAuthResult
        }
    ],
    async beforeEnter(to, from, next) {
      await init();
      await Promise.all([
        loadLanguageAsync('login'),
        loadLanguageAsync('plugins')
      ]);
      next();
    },
  }
]
