<template>
  <router-link 
    v-if="!isExternal && !plain" 
    :tabindex="tabbable ? 0 : -1" 
    :to="routeObject" 
    :target="target" 
    :active-class="activeClass" 
    :aria-labelledby="ariaLabelledBy"
    @keypress.enter.self="goTo(routeObject)" 
  >
    <slot></slot>
  </router-link>
  <div 
    v-else-if="!isExternal && plain" 
    :tabindex="tabbable ? 0 : -1"
  >
    <router-link 
      v-slot="{ navigate, isActive }" 
      custom 
      :to="routeObject"
    >
      <div 
        :class="[{ [activeClass]: isActive }, $attrs.class, 'custom-link']" 
        :aria-labelledby="ariaLabelledBy"
        @keypress.enter.self="goTo(routeObject)" 
        @click="navigate" 
      >
        <slot></slot>
      </div>
    </router-link>
  </div>
  <a 
    v-else 
    :tabindex="tabbable ? 0 : -1" 
    :href="to" 
    :target="target" 
    :aria-labelledby="ariaLabelledBy"
  >
    <slot></slot>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppLink',
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      required: false,
    },
    target: {
      type: String,
    },
    isExternal: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabbable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'myOrganization',
    ]),
    routeObject() {
      if (this.to instanceof Object) {
        return { ...this.to, query: this.adaptedQuery};
      }
      return {
        path: this.to,
        query: this.adaptedQuery
      };
    },
    selectedOrgCode() {
      return (this.selectedOrganization || {}).entryPoint;
    },
    ariaLabelledBy() {
      return (this.$attrs || {})['aria-labelledby'];
    },
    adaptedQuery() {
      let query = this.queryAsMap || {};
      if (this.shouldAddOrgQueryParam && !query.org) {
        query = { ...query, org: this.selectedOrgCode};
      }
      if (this.workspaceQuery && this.inheritWorkspaceQuery(this.to)) {
        query = { ...query, workspace: this.workspaceQuery};
      }
      return query;
    },
    /**
     * If the type of to is a string, we need to extract the query params as a map
     * if not we can just get them directly from the query field
     */
    queryAsMap() {
      if (this.to instanceof Object) {
        return (this.to || {}).query;
      }
      return this.extractQueryParams(this.to);
    },
    shouldAddOrgQueryParam() {
      if (!this.selectedOrganization) {
        return false;
      }
      return !(this.myOrganization && this.myOrganization.id === this.selectedOrganization.id);
    },
    workspaceQuery() {
      return (this.$route.query || {}).workspace;
    },
  },
  methods: {
    extractQueryParams(path) {
      let url = (path || "").split("?");
      if (url.length < 2) {
        return;
      }
      return this.URLSearchParamsToObject(new URLSearchParams(url[1]));
    },
    URLSearchParamsToObject(urlSearchParams) {
      const result = {}
      for (const [key, value] of urlSearchParams) {
        result[key] = value;
      }
      return result;
    },
    inheritWorkspaceQuery(toRoute) {
      if (this.toService(toRoute) === false) {
        return false;
      }
      const record = this.$router.resolve(toRoute);
      return record.path.startsWith('/services')
        && !(record.meta || {}).requiresWorkspaceQuery;
    },
    toService(toRoute) {
      if (typeof toRoute === 'string') {
        return toRoute.startsWith('/services');
      }
      if (typeof toRoute === 'object' && toRoute.path) {
        return toRoute.path.startsWith('/services');
      }
      return null;
    },
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-link {
  width: 100%;
}
</style>