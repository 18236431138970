import { CrudApi } from './api';

class TagsApi extends CrudApi {
  constructor() {
    super('tags');
  }
}

const tags = new TagsApi();
export default tags;
