import { mapGetters } from 'vuex';

export const resellerOrgNavigationMixin = {
  computed: {
    ...mapGetters(['selectedOrganization']),
  },
  created() {
    if (!this.selectedOrganization.isReseller) {
      this.$router.replace('/home');
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.selectedOrganization.isReseller) {
      const backToHome = { path: '/home', query: {} };
      if (this.selectedOrganization.entryPoint) {
        backToHome.query.org = this.selectedOrganization.entryPoint;
      }
      next(backToHome);
    }
    next();
  },
};
