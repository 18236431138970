<template>
  <base-select 
    class="datasource-select" 
    size="sm" 
    :modelValue="value" 
    :items="selectOptions" 
    @update:modelValue="handleInput"
  />
</template>
<script>
import BaseViewFilter from './BaseViewFilter';

export default {
  name: 'DatasourceFilter',
  extends: BaseViewFilter,
  emits: ['change'],
  data() {
    return {
      value: (this.filter.filterOptions || [{ value: '' }])[0].value,
    };
  },
  computed: {
    selectOptions() {
      return this.filter.filterOptions.map(fo => ({
        value: fo.value,
        label: fo.name.text,
      }));
    },
  },
  created() {
    this.handleInput(this.value);
  },
  methods: {
    handleInput(e) {
      this.value = e;
      this.$emit('change', {
        [`${this.filter.queryKey ? this.filter.queryKey : 'datasource'}`]: e,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.datasource-select {
  max-width: 300px;
  min-width: 150px;
}
</style>
