import { datesForPresets, isSameDay, getMilliseconds, getDate, getNow } from '@/utils/dates';
import { debounceAsync } from '@/utils';

/**
  Wraps mapGetters to simplify working with filters. The current filterset
  is read from the component's props, and the component's store is then used
  to get the filters corresponding to the requested filterset and keys.
  The same trick can't be done for actions, unfortunately.
* */
const mapFilterGetters = (keys, filterset) => keys.reduce((acc, k) => {
  acc[k] = (vm) => {
    const filters = vm.$store.getters.filters;
    const fs = filterset || vm.$props.filterset;
    if (filters[fs]) {
      return filters[fs][k];
    }
    return null;
  };
  return acc;
}, {});

const FilterType = {
  USER: 'userId',
  SERVICE: 'connectionId',
  ORGANIZATION: 'organizationId',
  ENV: 'environmentId',
  REQUESTER_IP: 'requesterIp',
  API_KEY_NAME: 'apiKeyName',
  DATES: 'dates',
  ORG_SCOPE: 'orgScope',
  ACTIVITY_CODES: 'codes',
  SHOW_ZERO_COST: 'showZeroCost',
  STATUS: 'status',
  BILLING_CYCLE: 'billingCycle',
  INCLUDE_ALL_SUB_ORGS: 'includeAllSubOrgs',
  INCLUDE_ORGS_WITHOUT_COST: 'includeOrgsWithoutCost',
  INCLUDE_VOIDED_INVOICES: 'show_voided_invoices',
  INCLUDE_OBSOLETED_INVOICES: 'show_obsoleted_invoices',
  USAGE_FOR: 'usage_for',
  SELECTED_PERIOD: 'selected_period',
  COMPARED_TO: 'compared_to',
  INCLUDE_NO_USAGE_INVOICES: 'include_no_usage_invoices',
  GROUP_BY: 'group_by',
};

const rawPill = (type, value, display) => ({ type, key: type, value, display });

const createPresetDatePill = (preset, start, end) => {
  const javaDates = { start: getMilliseconds(start), end: getMilliseconds(end) };

  const common = {
    type: FilterType.DATES,
    key: FilterType.DATES,
    value: javaDates,
    data: { start, end, preset, serialized: javaDates },
  };

  const dynamicPresets = ['month-0', 'month-1', 'month-2', 'month-3', 'year-0', 'year-1'];
  if (dynamicPresets.includes(preset)) {
    common.display = start;
    if (preset.startsWith('month')) {
      common.disposition = 'month';
    } else if (preset.startsWith('year')) {
      common.disposition = 'year';
    }
  } else {
    common.label = `date_ranges_long.${preset}`;
  }

  return common;
};

const createCustomDatePill = (start, end) => {
  const correctedDates = {
    start: getMilliseconds(start),
    end: end ? getMilliseconds(end) : undefined,
  };

  const common = {
    type: FilterType.DATES,
    key: FilterType.DATES,
    value: correctedDates,
    data: { start, end, serialized: correctedDates },
  };

  if (isSameDay(correctedDates.start, correctedDates.end)) {
    common.display = start;
    common.disposition = 'date';
  } else {
    common.display = [start, end];
    common.disposition = 'range';
  }

  return common;
};

const getPresetMonth = (months, start, end) => {
  const now = getNow();
  return months.filter((m) => {
    const monthDates = datesForPresets(true)[m](now);
    return monthDates[0].getTime() === Number(start) &&
    monthDates[1].getTime() === Number(end);
  });
};

const genPill = {
  [FilterType.DATES](preset, start, end, utc = false) {
    if (preset) {
      return createPresetDatePill(preset, start, end);
    } else if (typeof start === 'string') {
      const months = ['month-0', 'month-1', 'month-2'];
      const presetMonth = getPresetMonth(months, start, end);
      return (presetMonth === undefined || presetMonth.length === 0) ?
        createCustomDatePill(getDate(Number(start)), getDate(Number(end))) :
        createPresetDatePill(presetMonth[0], getDate(Number(start)), getDate(Number(end)));
    }
    return createCustomDatePill(start, end, utc);
  },

  [FilterType.ORG_SCOPE](flag) {
    const labels = { ALL: 'all_orgs', SUB: 'include_subs' };
    return {
      type: FilterType.ORG_SCOPE,
      key: FilterType.ORG_SCOPE,
      value: flag,
      label:
        labels[flag],
    };
  },

  [FilterType.ACTIVITY_CODES](fqCode, label) {
    // Recover the actual service code to send to the server
    const code = fqCode.split('~')[1];

    return {
      type: FilterType.ACTIVITY_CODES,
      key: fqCode,
      value: code,
      label,
      data: fqCode,
    };
  },

  [FilterType.ENV]: (id, name) => rawPill(FilterType.ENV, id, name),
  [FilterType.USAGE_FOR]: (id, name) => rawPill(FilterType.USAGE_FOR, id, name),
  [FilterType.SELECTED_PERIOD]: (date, formattedDate) => rawPill(FilterType.SELECTED_PERIOD, date, formattedDate),
  [FilterType.COMPARED_TO]: (date, formattedDate) => rawPill(FilterType.COMPARED_TO, date, formattedDate),
  [FilterType.SERVICE]: (id, name) => rawPill(FilterType.SERVICE, id, name),
  [FilterType.USER]: (id, name) => rawPill(FilterType.USER, id, name),
  [FilterType.API_KEY_NAME]: match => rawPill(FilterType.API_KEY_NAME, match, match),
  [FilterType.REQUESTER_IP]: match => rawPill(FilterType.REQUESTER_IP, match, match),
  [FilterType.SHOW_ZERO_COST]: (include) => {
    const boolean = typeof include === 'boolean' ? include : include.toLowerCase() === 'true';
    return rawPill(FilterType.SHOW_ZERO_COST, boolean, boolean);
  },
  [FilterType.STATUS]: (id, name) => {
    const result = rawPill(FilterType.STATUS, id, name);
    result.label = `reports.invoice_status.${id}`;
    return result;
  },
  [FilterType.BILLING_CYCLE]: (id, name) => {
    const [month, year] = name.split('-');
    // eslint-disable-next-line
    const result = rawPill(FilterType.BILLING_CYCLE, id, new Date(year, Number(month) - 1));
    result.disposition = 'monthYear';
    return result;
  },
  [FilterType.INCLUDE_ALL_SUB_ORGS]: (id, name) => {
    const result = rawPill(FilterType.INCLUDE_ALL_SUB_ORGS, id, name);
    result.disposition = 'boolean';
    return result;
  },
  [FilterType.INCLUDE_ORGS_WITHOUT_COST]: (id, name) => {
    const result = rawPill(FilterType.INCLUDE_ORGS_WITHOUT_COST, id, name);
    result.disposition = 'boolean';
    return result;
  },
  [FilterType.INCLUDE_VOIDED_INVOICES]: (id, name) => {
    const result = rawPill(FilterType.INCLUDE_VOIDED_INVOICES, id, name);
    result.disposition = 'boolean';
    return result;
  },
  [FilterType.INCLUDE_OBSOLETED_INVOICES]: (id, name) => {
    const result = rawPill(FilterType.INCLUDE_OBSOLETED_INVOICES, id, name);
    result.disposition = 'boolean';
    return result;
  },
  [FilterType.INCLUDE_NO_USAGE_INVOICES]: (id, name) => {
    const result = rawPill(FilterType.INCLUDE_NO_USAGE_INVOICES, id, name);
    result.disposition = 'boolean';
    return result;
  },
  [FilterType.ORGANIZATION]: (id, name) => rawPill(FilterType.ORGANIZATION, id, name),

  [FilterType.GROUP_BY]: (groupingType) => {
    const labels = { PLAN_TYPE: 'plan_type', PRODUCT_CATEGORY: 'product_category' };
    return {
      type: FilterType.GROUP_BY,
      key: FilterType.GROUP_BY,
      value: groupingType,
      label:
        labels[groupingType],
    };
  },
};

const getQueryFromPills = filters => Object.entries(filters).reduce((acc, [k, v]) => {
  if (v instanceof Object && v.constructor === Object) {
    // Do one level of flattening
    if (v.preset && !v.preset.startsWith('month') && !v.preset.startsWith('year')) {
      acc[`${k}.preset`] = v.preset;
      return acc;
    }
    return Object.entries(v.serialized || v).reduce((acc1, [k1, v1]) => {
      acc1[`${k}.${k1}`] = v1;
      return acc1;
    }, acc);
  }
  acc[k] = v;
  return acc;
}, {});

const pillMutations = new Set(['SET_PILLS', 'REMOVE_PILL', 'REMOVE_PILLS_OF_TYPE', 'ADD_PILL', 'REPLACE_PILL']);


function subscriber(store, filterset, fn, includeFn) {
  const debouncedFn = debounceAsync(fn, 500);

  return store.subscribe(({ type, payload }) => {
    if (pillMutations.has(type) && payload.filterset === filterset) {
      if (includeFn && !includeFn(payload)) {
        return;
      }
      debouncedFn();
    }
  });
}


export {
  mapFilterGetters,
  FilterType,
  genPill,
  getQueryFromPills,
  subscriber,
  rawPill,
};
