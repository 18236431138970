import { ref, watchEffect } from 'vue';
import moment from 'moment-timezone';

import { DEFAULT_DATE_FORMAT, format } from '.';

const locale = ref('en');

const setDateLocale = (l) => locale.value = l;

/** 
 * Options API implementation of useDate
 */
 const DateFormatter = {
  install(app) {
    app.config.globalProperties.$date = (date, template = DEFAULT_DATE_FORMAT, utc = false) => {
      if (!date || !locale.value) {
        return '';
      }
      return format(date, template, utc);
    };
    watchEffect(() => moment.locale(locale.value));
  },
};

export {
  DateFormatter,
  setDateLocale,
};