<template>
  <div 
    :id="labelId" 
    :title="$t(tooltipLabel)" 
    class="action-icon" 
    :tabindex="disabled || isNavigation ? -1 : 0" 
    :role="isNavigation ? null : 'button'" 
    :class="{ 'expand-on-hover': expandOnHover && !disabled }"  
    :aria-label="clickable ? label : null"
    @click="click($event, operation)" 
    @keyup.enter="click($event, operation)" 
  >
    <app-link 
      v-if="!disabled && to" 
      v-protected="to" 
      :to="to" 
      class="fake-a" 
      :aria-labelledby="labelId"
    >
      <em 
        :class="[color, icon]" 
        class="action clickable"
      />
    </app-link>
    <a 
      v-else-if="link && !disabled" 
      :href="link" 
      :target="newTab ? '_blank' : undefined" 
      class="fake-a" 
      :aria-labelledby="labelId"
    >
      <em 
        :class="[color, icon, size]" 
        class="action clickable"
      />
    </a>
    <div v-else>
      <em 
        :class="[[color, icon, size], { clickable, disabled }]" 
        class="action fa"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ActionIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltipLabel: {
      type: String,
    },
    size: {
      type: String,
      required: false,
      validator: val => ['small', 'large', 'extra-large'].indexOf(val) !== -1,
    },
    color: {
      type: String,
      required: false,
      validator: val => ['red', 'on-primary', 'unset'].indexOf(val) !== -1,
    },
    to: {
      type: [Object, String],
    },
    href: {
      type: String,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    operation: {
      type: Object,
    },
    expandOnHover: {
      type: Boolean,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      labelId: `label-${uuidv4()}`,
    };
  },
  computed: {
    isNavigation() {
      return this.link || this.to;
    },
    link() {
      return this.href ? this.href : '';
    },
    label() {
      return this.tooltipLabel ? this.$t(this.tooltipLabel) : this.$t(this.determineLabel());
    },
  },
  methods: {
    click(event, operation) {
      if (this.newTab) {
        event.stopPropagation();
        return;
      }
      event.preventDefault();

      if (!this.disabled) {
        this.$emit('click', event, operation);
      }
    },
    determineLabel() {
      const fontAwesomeRegex = new RegExp('fa-([a-zA-Z-]*)');
      switch (fontAwesomeRegex.exec(this.icon)[1]) {
        case 'times':
        case 'trash':
        case 'minus-circle':
        case 'close':
          return 'delete';
        case 'plus':
        case 'plus-circle':
        case 'plus-square':
          return 'add';
        case 'edit':
        case 'pencil':
          return 'edit';
        case 'undo':
          return 'undo';
        case 'elipsis-v':
          return 'more_actions';
        default:
          return '';
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins.scss';

.expand-on-hover {
  .action {
    transform: scale(0.85);
    transition: transform 0.1s;
    &:hover {
      transform: scale(1);
    }
  }
}


.action-icon {
  font-size: 18px;
  em {
    min-width: 18px;
    text-align: center;
  }

  .red {
    color: var(--red);
  }

  .on-primary {
    color: var(--on-primary);
  }

  .unset {
    color: unset;
  }

  .small {
    font-size: 14px;
  }

  .large {
    font-size: 22px;
  }

  .extra-large {
    font-size: 28px;
  }

}
.action {
  &.disabled {
    transform: scale(0.85);
  }
  color: #505050;

  @include phone {
    font-size: 20px;
    height: 20px;
    width: 30px;
  }
}

.disabled {
  opacity: 0.7;
  filter: grayscale(1);
  cursor: not-allowed;
}
</style>
