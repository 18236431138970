import { localeCompare } from '@/utils';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SET_USER_CONTEXT = 'SET_USER_CONTEXT';
const SET_IDENTITY_PROVIDER = 'SET_IDENTITY_PROVIDER';
const RESET_LOGIN = 'RESET_LOGIN';
const SESSION_TIMED_OUT = 'SESSION_TIMED_OUT';
const RESET_SESSION_TIMEOUT = 'RESET_SESSION_TIMEOUT';
const SET_LOCALE = 'SET_LOCALE';
const SET_DEFAULT_LOCALE = 'SET_DEFAULT_LOCALE';
const LOAD_USER_BRANDING_INFO = 'LOAD_USER_BRANDING_INFO';
const LOAD_ORG_BRANDING_INFO = 'LOAD_ORG_BRANDING_INFO';
const CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION';
const CHANGE_ORGANIZATION_FEATURES = 'CHANGE_ORGANIZATION_FEATURES';
const UPDATE_CURRENT_ORG_ATTRIBUTES = 'UPDATE_CURRENT_ORG_ATTRIBUTES';
const SET_GLOBAL_PROPERTIES = 'SET_GLOBAL_PROPERTIES';
const SET_PERMISSIONS_ON_ORG = 'SET_PERMISSIONS_ON_ORG';
const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE';
const SET_BRANDING_RELOAD_ID = 'SET_BRANDING_RELOAD_ID';
const SET_SERVICE_SUB_MENU = 'SET_SERVICE_SUB_MENU';
const SET_FEEDBACK_SETTINGS = 'SET_FEEDBACK_SETTINGS';
const LOAD_BILLING_SETTINGS = 'LOAD_BILLING_SETTINGS';
const LOAD_MANAGED_CONNECTIONS = 'LOAD_MANAGED_CONNECTIONS';

export { LOGIN, LOGOUT, SET_USER_CONTEXT, SET_IDENTITY_PROVIDER, RESET_LOGIN, SESSION_TIMED_OUT,
  RESET_SESSION_TIMEOUT,
  SET_LOCALE, SET_DEFAULT_LOCALE, LOAD_USER_BRANDING_INFO, LOAD_ORG_BRANDING_INFO,
  CHANGE_ORGANIZATION, UPDATE_CURRENT_ORG_ATTRIBUTES,
  SET_GLOBAL_PROPERTIES, SET_PERMISSIONS_ON_ORG, SET_PREVIOUS_ROUTE, SET_BRANDING_RELOAD_ID,
  SET_SERVICE_SUB_MENU, SET_FEEDBACK_SETTINGS, LOAD_BILLING_SETTINGS,
  CHANGE_ORGANIZATION_FEATURES, LOAD_MANAGED_CONNECTIONS };

// mutations
export default {
  [LOGIN](state) {
    state.loggedIn = true;
  },
  [LOGOUT](state) {
    state.loggedIn = false;
  },
  [RESET_LOGIN](state) {
    state.loggedIn = undefined;
  },
  [SET_USER_CONTEXT](state, userContext) {
    state.userContext = userContext || {};
    if (userContext) {
      state.userContext.serviceConnections = state.userContext.serviceConnections
        .sort(localeCompare(sc => sc.name))
        .sort(localeCompare(sc => sc.type));
    }
  },
  [SET_IDENTITY_PROVIDER](state, identityProvider) {
    state.identityProvider = identityProvider;
  },
  [SESSION_TIMED_OUT](state) {
    state.sessionTimeout = true;
  },
  [RESET_SESSION_TIMEOUT](state) {
    state.sessionTimeout = false;
  },
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },
  [SET_DEFAULT_LOCALE](state, defaultLocale) {
    state.defaultLocale = defaultLocale;
  },
  [LOAD_ORG_BRANDING_INFO](state, orgBrandingInfo) {
    state.orgBrandingInfo = orgBrandingInfo;
  },
  [LOAD_USER_BRANDING_INFO](state, userBrandingInfo) {
    state.userBrandingInfo = userBrandingInfo;
  },
  [CHANGE_ORGANIZATION](state, organization) {
    state.currentOrganization = organization;
  },
  [CHANGE_ORGANIZATION_FEATURES](state, organizationFeatures) {
    state.organizationFeatures = organizationFeatures;
  },
  [UPDATE_CURRENT_ORG_ATTRIBUTES](state, attributes) {
    if (state.currentOrganization) {
      state.currentOrganization = Object.assign(state.currentOrganization, { ...attributes });
    } else {
      state.userContext.org = Object.assign(state.userContext.org, { ...attributes });
      if (attributes.serviceConnections) {
        state.userContext.serviceConnections = [...attributes.serviceConnections];
      }
    }
  },
  [SET_GLOBAL_PROPERTIES](state, properties) {
    state.globalProperties = properties;
  },
  [SET_PERMISSIONS_ON_ORG](state, { orgId, orgPermissions }) {
    state.permissionsPerOrg = { ...state.permissionsPerOrg, [orgId]: orgPermissions };
  },
  [SET_PREVIOUS_ROUTE](state, route) {
    state.previousRoute = route;
  },
  [SET_SERVICE_SUB_MENU](state, serviceSubMenu) {
    state.serviceSubMenu = serviceSubMenu;
  },
  [SET_FEEDBACK_SETTINGS](state, feedbackSettings) {
    state.feedbackSettings = feedbackSettings;
  },
  [LOAD_BILLING_SETTINGS](state, billingSettings) {
    state.billingSettings = billingSettings;
  },
  [LOAD_MANAGED_CONNECTIONS](state, managedServiceConnections) {
    state.managedServiceConnections = managedServiceConnections;
  }
};
