import { CrudApi } from './api';

class PropertyApi extends CrudApi {
  constructor() {
    super('properties');
  }

  getWhiteListedProperties() {
    return this.get(`${this.path}/whitelisted`);
  } 
}

const propertyApi = new PropertyApi();

export default propertyApi;
