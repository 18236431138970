<template>
  <codemirror
    :modelValue="modelValue"
    :tab-size="tabSize"
    :extensions="extensions"
    :disabled="readOnly"
    :class="{ 'read-only': readOnly } "
    @update:modelValue="input"
  />
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';

import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { StreamLanguage } from "@codemirror/language";

const EditorLanguages = {
  yaml: () => StreamLanguage.define(yaml),
  json: json,
  xml: xml,
  javascript: javascript,
  css: css,
  html: html,
};

export default {
  name: 'CodeEditor',
  components: { Codemirror },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    language: {
      type: String,
      default: "yaml",
      validator(value) {
        return Object.keys(EditorLanguages).indexOf(value.toLowerCase()) > -1;
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tabSize: 2,
    };
  },
  computed: {
    extensions() {
      if (EditorLanguages[this.language.toLowerCase()]) {
        return [EditorLanguages[this.language.toLowerCase()]()];
      }
      return [];
    },
  },
  methods: {
    input(e) {
      this.$emit('update:modelValue', e);
    },
  },
};
</script>

<style scoped lang="scss">
/* fix outline */
:deep(.cm-editor) {
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
}

:deep(.cm-wrap) { height: 200px;}
:deep(.cm-scroller) { overflow: auto; }

:deep(.cm-gutters), :deep(.cm-content) {
  min-height: 200px !important;
}

:deep(.cm-gutters) {
  padding-bottom: 4px;
}

:deep(.cm-focused) {
  outline: auto;
  z-index: 2;
}
</style>
