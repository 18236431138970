<template>
  <div 
    class="summary-box" 
    :class="{ selected }" 
    tabindex="0" 
    role="button"
    @keyup.enter="$emit('click')" 
    @click="$emit('click')"
  >
    <div class="selected-icon">
      <base-icon icon="fa fa-check-square" />
    </div>
    <div class="summary-info">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectableBox',
  props: {
    selected: {
      type: Boolean,
    },
  },
  emits: ['click'],
};
</script>
<style lang="scss" scoped>
.summary-box {
  position: relative;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  box-sizing: border-box;

  border: 1px solid var(--selectable-box-border);
  box-shadow: 2px 2px 4px -1px var(--border-shadow);
  border-radius: 12px;

  transition: border-width 0.3s, border-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: var(--plain);
    border-width: 2px;
  }

  &.selected {
    border-color: var(--primary);
    border-width: 2px;

    .selected-icon {
      transform: scale(1);
    }
  }

  .summary-info {
    display:flex;
    max-width: 100%;
  }

  &:hover, &.selected {
    .summary-value {
      font-size: 1.7rem;
    }
  }

  .selected-icon {
    transform: scale(0);
    transform-origin: 30px 4px;
    transition: transform 0.3s;
    background-color: var(--primary);
    color: var(--on-primary);
    position:absolute;
    top: -2px;
    right: -2px;
    padding: 5px 10px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
</style>
