<template>
  <main class="trial page">
    <div class="top logo">
      <app-logo 
        class="app-logo" 
        :src="`/rest/appearance/logo`"
      />
    </div>
    <TrialRegistrationBox />
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import apis from '@/utils/apis';
import AppLogo from '@/components/AppLogo';
import TrialRegistrationBox from './components/TrialRegistrationBox';

let targetOrg;

export default {
  name: 'TrialRegistration',
  components: { TrialRegistrationBox, AppLogo },
  async beforeRouteEnter(to, from, next) {
    targetOrg = await apis.hidden.getOrganizationInfo();
    next();
  },
  async created() {
    await this.changeOrganization(targetOrg);
  },
  methods: {
    ...mapActions([
      'changeOrganization',
    ]),
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins';
@import '@/styles/animations';

.page {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  animation: fadein .5s;
}

.top {
  height: 160px;
  img.app-logo {
    max-height: 100px;
    max-width: 250px;
    margin-top: 50px;
    margin-bottom: 50px;

    @include phone {
      margin-bottom: 45px;
      margin-top: 20px;
    }
  }
}
</style>
