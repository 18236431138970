import SessionTimeoutHandler from './sessionTimeout';
import AuditEventChangeHandler from './auditEventChange';
import SensitiveNotificationHandler from './sensitiveNotification';
import OpenUrlHandler from './openUrl';
import EntityChangeHandler from './entityChange';

const handlers = {
  sessionTimeout: SessionTimeoutHandler,
  auditEventChange: AuditEventChangeHandler,
  sensitiveDataNotification: SensitiveNotificationHandler,
  openUrl: OpenUrlHandler,
  entityChanged: EntityChangeHandler,
};

export default handlers;