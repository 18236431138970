import izitoast from 'izitoast';

let toastQueue = [];

const DISPLAY_CHARACTER_LIMIT = 250;

const TOAST_OPTIONS = {
  position: 'bottomCenter',
  transitionIn: 'fadeInUp',
  transitionInMobile: 'fadeInDown',
  transitionOutMobile: 'fadeOutUp',
  theme: 'light',
  layout: 1,
  close: true,
  closeOnEscape: true,
  maxWidth: 672,
  progressBar: true,
  animateInside: false,
  backgroundColor: 'white',
  titleColor: `var(--text)`,
  messageColor: `var(--text)`,
  titleSize: 16,
  titleLineHeight: 30,
  messageSize: 16,
  messageLineHeight: 20,
  onOpened(_, el) {
    el.setAttribute('role', 'alert');
  },
};

// The alert should be visible for 4 seconds + 1 second for each 120 words in the message
function timeoutLength(message) {
  const messageDisplayLength = message ? (message.split(' ').length / 120) * 1000 : 0;
  return messageDisplayLength + 4000;
}

function hasToast() {
  return toastQueue.length;
}

function popToast() {
  return toastQueue.shift();
}

function toast(msg, title, color, type, icon, btn) {
  function btnCallback(instance, toast) {
    instance.hide({
      onClosing: () => {
        btn.callback();
      }
    }, toast, btn.name)
  }

  function toastButton() {
    return btn == null
      ? []
      : [[`<button>${btn.label}</button>`, btnCallback, toast, btn.name]];
  }

  toastQueue.push(Object.assign({
    title: title || '',
    message: msg,
    class: type,
    icon: icon,
    iconColor: color,
    progressBarColor: color,
    timeout: timeoutLength(msg),
    buttons: toastButton(),
  }, TOAST_OPTIONS));
}

const warning = (msg, title) => toast(msg, title, 'var(--ng-primary-yellow)', 'warning', 'cmc-icon-warning');
const error = (msg, title, btn) => toast(msg, title, 'var(--ng-primary-red)', 'error', 'cmc-icon-failure', btn);
const success = (msg, title, btn) => toast(msg, title, 'var(--ng-primary-green)', 'success', 'cmc-icon-success', btn);
const pending = (msg, title) => toast(msg, title, 'var(--ng-primary-blue)', 'pending', 'loading-spinner');
const destroy = () => izitoast.destroy();

function sensitive(store, msg, openRightDrawer = true) {
  const correlationId = `_SYNTHETIC${Math.floor(Math.random() * 1000000)}`;

  store.dispatch('addNotification', {
    syntheticLabel: msg.label,
    id: correlationId,
    correlationId,
    // eslint-disable-next-line
    created: new Date(),
    status: 'SUCCESS',
    serviceConnectionName: msg.cat1,
    environmentName: msg.cat2,
    eventContext: JSON.stringify(msg.context),
  });


  store.dispatch('addSensitiveNotificationData', {
    correlationId,
    data: [
      {
        label: msg.key,
        value: msg.value,
        sensitive: msg.sensitive === null || msg.sensitive === undefined || msg.sensitive,
      },
    ],
  });

  if (openRightDrawer) {
    store.dispatch("openSecondaryNav", { secondary: 'notifications' });
  }
}

const notify = {
  warning,
  error,
  success,
  destroy,
  pending,
  sensitive,
  hasToast,
  popToast,
  DISPLAY_CHARACTER_LIMIT,
};

export default notify;
