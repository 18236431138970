/**
* Reads errors from the JSON response specified by body, and places field errors
* in the object specified by messages. Only messages for fields which correspond to
* existing keys in messages are produced, allowing a component to choose which errors
* are relevant to it.
*
* Messages for general errors, which don't apply to a specific field are returned in a list.
*/
const parse = (t, body, messages) => {
  if (!body || !body.errors) {
    return [];
  }

  const general = [];

  body.errors.forEach((err) => {
    if (!err.ref) { // We only handle errors intended for the UI
      return;
    }

    const ref = err.ref;
    const label = ref.code.split('.').pop(); // The first part is the module name (this is currently hacky)

    // If a component wishes to attach errors to fields, and this error is field-scoped
    if (ref.scope && messages) {
      messages[ref.scope] = t(label, err.context);
    } else {
      // Unscoped (general) errors are saved in a list
      general.push(t(label, err.context));
    }
  });
  return general;
};

const errors = {
  parse,
};

export default errors;
