<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    v-if="tag" 
    class="base-tag" 
    :class="[ colorClass, sizeClass ]"
  >
    <div class="text">
      <div 
        v-if="isHtml" 
        v-html="tag"
      ></div>
      <div v-else>
        {{ tag }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseTag',
  props: {
    tag: {
      type: String,
    },
    colorClass: {
      type: String,
      validator: c => ['green', 'blue', 'grey', 'gray', 'yellow', 'red', 'light_grey', 'readonly'].includes(c) || c.startsWith('default-color-'),
    },
    sizeClass: {
      type: String,
    },
    isHtml: {
      type: Boolean,
    },
  },
};
</script>
<style scoped lang="scss">
.base-tag {
  font-size: 14px;
  border-radius: 5px;
  display: block;
  &.sm {
    font-size: 12px;
  }
  .text {
    padding: 3px 10px;
    color: white;
  }
}
</style>
