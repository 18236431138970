<script>
import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import Chart from './Chart';

use([PieChart]);

export default {
  name: 'PieChart',
  extends: Chart,
  props: {
    showLabelLine: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    labels: {
      type: Array,
      default: () => [],
    },
    showLegends: {
      type: Boolean,
      default: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    tooltipData: () => ({
      trigger: 'item',
      // these variables (a, b, c and d) are defined in EChart library and can't be renamed or
      // modified.Details can be found on https://echarts.apache.org/en/option.html#tooltip.formatter
      formatter: '{a} <br/>{b} : {c} ({d}%)',
      confine: true,
    }),
    legendData() {
      return {
        left: 'left',
        top: '10%',
        data: this.labels,
        show: this.showLegends,
      };
    },
    seriesData() {
      return this.series.map(e => ({
        name: e.name,
        type: 'pie',
        radius: e.radius || "65%",
        center: ['50%', '58%'],
        data: e.value,
        itemStyle: e.itemStyle,
        emphasis: e.emphasis || {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        selectedMode: e.selectedMode,
        labelLine: {
          show: this.showLabelLine,
          length: 10,
        },
        label: {
          show: this.showLabel
        },
      }));
    },
    toolboxData() {
      return {
        show: 'true',
        feature: {
        saveAsImage: {
          show: this.showSaveButton,
          title: 'save',
        },
      },
      }
    },
  },
  methods: {
    updateLegends(selectedLegends) {
      this.$refs.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: selectedLegends.name,
      });
    },
    toggleAllLegends(allLegends, enable) {
      allLegends.forEach((legend) => {
        this.$refs.chart.dispatchAction({
          type: enable ? 'legendSelect' : 'legendUnSelect',
          name: legend,
        });
      });
    },
  },
};
</script>
