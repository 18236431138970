import { getDay, isSameDay, startOfDay, startOfWeek, startOfMonth,
  addYears, endOfYear, addMonths, endOfMonth, endOfWeek, startOfYear,
  getMilliseconds, getNow, isEqual, isBefore, isAfter, isWithinRangeInclusiveStart, addDays, subDays } from '@/utils/dates';

const getAdjustedDay = (date, utc) => (getDay(date, utc) + 6) % 7;

const buildMonth = (date, minDate, maxDate, utc) => {
  const today = startOfDay(getNow(), utc);
  const startOfMonthDate = startOfMonth(date, utc);
  const endOfMonthDate = endOfMonth(date, utc);

  const firstDayInCalendar = subDays(startOfMonthDate, getAdjustedDay(startOfMonthDate, utc), utc);
  const lastDayInCalendar = addDays(endOfMonthDate, 6 - getAdjustedDay(endOfMonthDate, utc), utc);

  let cur = firstDayInCalendar;
  const allDays = [];
  while (cur.getTime() <= lastDayInCalendar.getTime()) {
    const disabled = (minDate && isBefore(cur, minDate, utc)) ||
                      (maxDate && isAfter(cur, maxDate, utc));
    allDays.push({
      date: cur,
      value: utc ? cur.getUTCDate() : cur.getDate(),
      inside: isWithinRangeInclusiveStart(cur, startOfMonthDate, endOfMonthDate),
      disabled,
      now: isEqual(cur, today),
    });
    cur = addDays(cur, 1, utc);
  }
  const month = [];
  for (let i = 0; i < allDays.length; i += 7) {
    month.push(allDays.slice(i, i + 7));
  }
  return month;
};

const setSelected = (month, start, end) => {
  for (let i = 0; i < month.length; i += 1) {
    const week = month[i];
    for (let j = 0; j < week.length; j += 1) {
      const day = week[j];
      let between = start && end;
      between = between && !isBefore(day.date, start) && !isAfter(day.date, end);
      day.selected = isSameDay(day.date, start) || between;
      day.start = isSameDay(day.date, start);
      day.end = isSameDay(day.date, end);
    }
  }
};

const getPresets = (utc) => {
  const today = startOfDay(getNow(), utc);
  return [
    {
      name: 'lastSevenDays',
      label: 'Last 7 days',
      start: addDays(today, -7, utc),
      end: addDays(today, -1, utc),
    },
    {
      name: 'lastThirtyDays',
      label: 'Last 30 days',
      start: addDays(today, -30, utc),
      end: addDays(today, -1, utc),
    },
    {
      name: 'currentWeek',
      label: 'Current week',
      start: addDays(startOfWeek(today, utc), 1, utc),
      end: addDays(endOfWeek(today, utc), -1, utc),
    },
    {
      name: 'lastWeek',
      label: 'Last week',
      start: addDays(startOfWeek(addDays(today, -7, utc), utc), 1, utc),
      end: addDays(endOfWeek(addDays(today, -7, utc), utc), -1, utc),
    },
    {
      name: 'currentMonth',
      label: 'Current month',
      start: startOfMonth(today, utc),
      end: endOfMonth(today, utc),
    },
    {
      name: 'lastMonth',
      label: 'Last month',
      start: startOfMonth(addMonths(today, -1, utc), utc),
      end: endOfMonth(addMonths(today, -1, utc), utc),
    },
    {
      name: 'currentYear',
      label: 'Current year',
      start: startOfYear(today, utc),
      end: endOfYear(today, utc),
    },
    {
      name: 'lastYear',
      label: 'Last year',
      start: startOfYear(addYears(today, -1, utc), utc),
      end: endOfYear(addYears(today, -1, utc), utc),
    },
  ];
};

const getPresetNameWithDates = (start, end, utc = false) => {
  const presets = getPresets();
  for (let i = 0; i < presets.length; i += 1) {
    const p = presets[i];
    if (isEqual(startOfDay(p.start, utc), start) &&
        isEqual(startOfDay(p.end, utc), end)) {
      return p.name;
    }
  }
  return undefined;
};

const getDaysOfTheWeek = (utc = false) => {
  const monday = addDays(startOfWeek(getNow(), utc), 1, utc);
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  return [...Array(7).keys()].map(i => getMilliseconds(monday) + (i * dayInMilliseconds));
};


export default {
  buildMonth, setSelected, getPresets, getPresetNameWithDates, getDaysOfTheWeek,
};
