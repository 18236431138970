<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    v-if="viewType == 'PARAGRAPH'" 
    class="alert-box alert" 
    :class="[alertType.toLowerCase(), {'is-alert': alertType !== 'NONE', 'single-alert': adaptedLabels.length === 1}, {'border' : border}, `border-${alertType.toLowerCase()}`]"
  >
    <base-icon 
      v-if="alertType != 'NONE'" 
      :icon="iconMap[alertType]"
    />
    <div>
      <span 
        v-if="headerLabel" 
        class="header-label" 
        v-html="$t(headerLabel, headerInterpolation)"
      ></span>
      <ul>
        <li 
          v-for="l in adaptedLabels" 
          :key="l" 
          v-html="$t(l, boldInterpolation)"
        ></li>
      </ul>
      <slot></slot>
    </div>
  </div>
  <div 
    v-else-if="viewType == 'TILE'" 
    class="alert-box-tile" 
    :class="[alertType.toLowerCase()]"
  >
    <span 
      v-if="headerLabel" 
      class="header-label" 
      v-html="$t(headerLabel, headerInterpolation)"
    ></span>
    <span 
      class="content" 
      v-html="$t(label, boldInterpolation)"
    ></span>
  </div>
</template>

<script>
import { boldify } from '@/utils';

const iconMap = {
  WARNING: 'fa fa-exclamation-triangle yellow',
  ERROR: 'fa fa-exclamation-triangle red',
  DANGER: 'fa fa-exclamation-triangle red',
  INFO: 'fa fa-info-circle blue',
};

export default {
  name: 'AlertBox',
  props: {
    label: {
      type: [String, Array],
    },
    alertType: {
      type: String,
      default: 'NONE',
    },
    border: {
      type: Boolean,
      default: false,
    },
    interpolation: {
      type: Object,
      default: () => ({}),
    },
    viewType: {
      type: String,
      default: 'PARAGRAPH'
    },
    headerLabel: {
      type: String,
      default: null
    },
    headerInterpolation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      iconMap,
    };
  },
  computed: {
    boldInterpolation() {
      return boldify(this.interpolation);
    },
    adaptedLabels() {
      if (Array.isArray(this.label)) {
        return this.label;
      }
      return [this.label];
    },
  },
};
</script>

<style scoped lang="scss">
.alert-box {
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 1em;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    white-space: pre-line; // preserve new lines

    &.none {
        padding: 10px 0px;
    }

    .fa {
        font-size: 25px;
        padding-right: 20px;
    }
}

.border {
  border-width: 1px;
  border-style: solid;
  padding: 15px;
}

ul {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  li:not(:first-child) {
    margin-top: 10px;
  }
}

.single-alert {
  ul {
    padding-left: 0px;
    list-style: none;
  }
}

.header-label {
  display: block;
  font-weight: bold;
}

.alert-box-tile {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light);
  padding: .8em;
  box-sizing: border-box;
  border-radius: 1em;

  span.content {
    display: block;
    width: 100%;
    font-size: 400%;
    text-align: center;
  }

  &.info {
    background-color: var(--blue);
  }
  &.warning {
    background-color: var(--yellow);
  }
  &.success {
    background-color: var(--green);
  }
  &.error, &.danger {
    background-color: var(--red);
  }
}
</style>
