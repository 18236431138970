import Api from './api';

const ROOT_CONTEXT = '/health';
class HealthCheck extends Api {
  constructor() {
    super(ROOT_CONTEXT);
  }
  keepSessionAlive() {
    return this.head(`${ROOT_CONTEXT}/keep-alive`);
  }
}
const healthCheck = new HealthCheck();
export default healthCheck;
