import BaseRoute from '@/app/components/BaseRoute';

const EmailTemplatesList = () => import(/* webpackChunkName: "system" */ './EmailTemplatesList');
const EmailTemplatePreview = () => import(/* webpackChunkName: "system" */ './EmailTemplatePreview');
const EmailSettings = () => import(/* webpackChunkName: "system" */ './emailsettings/EmailSettings');

export default [
  {
    path: 'email-templates',
    component: BaseRoute,
    meta: {
      protected: ['reseller:email'],
      title: () => 'email_templates.title',
    },
    children: [
      {
        name: 'emailTemplates',
        path: '',
        component: EmailTemplatesList,
      },
      {
        name: 'preview',
        path: ':id/preview',
        component: EmailTemplatePreview,
      },
      {
        name: 'emailSettings',
        path: 'email_settings',
        component: EmailSettings,
        props: { switchOrgRouteName: 'emailTemplates' },
      },
    ],
  },
];
