import ApiDocs from './ApiDocs.vue';
import ElementsUi from './components/ElementsUi';

export default [{
  path: '/api-docs',
  component: ApiDocs,
  children: [{
    path: '',
    component: ElementsUi,
  },
  {
    path: ':serviceType',
    component: ElementsUi,
  }],
}];
