import i18n from '@/i18n';

const Units = {
  B: 'byte',
  KB: 'kilobyte',
  MB: 'megabyte',
  GB: 'gigabyte',
  TB: 'terabyte',
};

function bytesToSizeObj(bytes) {
  if (bytes === 0) {
    return {
      size: 0,
      unitLabel: i18n.global.t('units.byte')
    }
  }

  const exp = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  const sizeMinInBytes = 1024 ** exp;
  return {
    size: (bytes / sizeMinInBytes).toFixed(2),
    unitLabel: i18n.global.t(`units.${Object.values(Units)[exp]}`)
  }
}

function bytesToSize(bytes) {
  const converted = bytesToSizeObj(bytes);
  return `${converted.size} ${converted.unitLabel}`;
}

function sizeToBytes(size, unit) {
  const exp = Object.values(Units).indexOf(unit);
  if (exp < 0) {
    return `unit '${unit}' not available`;
  }
  return size * (1024 ** exp);
}

export {
  Units,
  bytesToSizeObj,
  bytesToSize,
  sizeToBytes,
};
