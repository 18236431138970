import { mapGetters } from 'vuex';

export const orgSwitchNavigationMixin = {
  data() {
    return {
      forceLeave: false,
    };
  },
  props: {
    switchOrgRouteName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
  },
  watch: {
    selectedOrganization(newOrg, oldOrg) {
      if (newOrg.id !== oldOrg.id) {
        this.forceLeave = true;
        this.$router.push({ name: this.switchOrgRouteName });
      }
    },
  },
};
