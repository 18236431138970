<template>
  <div :class="{ collapsible: children && !!children.length }">
    <div v-if="to">
      <app-link 
        :to="to" 
        class="item clickable fake-a" 
        :class="{ active: (children && activeChild) || isActiveRoute }"
        active-class="active"
      >
        <div class="content">
          <slot></slot>
        </div>
      </app-link>
    </div>

    <div 
      v-else-if="children && children.length == 1 && !displayAllChildren"
      class="item clickable"
      :class="{ active }"
      @click="click"
      @keyup.enter="click"
    >
      <app-link
        v-if="children[0].to"
        tabindex="0"
        role="button"
        class="item clickable fulllink"
        :plain="true"
        active-class="active-child"
        :to="children[0].to"
      >
        <div class="content">
          <slot></slot>
        </div>
      </app-link>
      <div 
        v-else 
        class="content"
      >
        <slot></slot>
      </div>
    </div>

    <div 
      v-else 
      class="item fake-a"
      :class="{ active, clickable }"
      role="button"
      tabindex="0"
      @keyup.enter="click"
      @click="click"
    >
      <div class="content">
        <slot></slot>
      </div>
    </div>

    <template v-if="(active || activeChild) && (children.length > 1 || displayAllChildren)">
      <div 
        v-for="item in children"
        :key="item.name" 
      >
        <app-link 
          v-if="item.to" 
          v-protected="item.to"
          tabindex="0"
          role="button"
          class="item clickable"
          :plain="true"
          active-class="active-child"
          :to="item.to"
        >
          <div :class="{'parent-with-icon':parentIcon}">
            <div class="content child">
              <base-icon 
                v-if="item.icon" 
                :icon="`${item.icon} fa-fw`"
              />
              <div :class="[{'align-no-icon-child': !item.icon && containsIcon}]">
                {{ $t(item.label || item.labelKey) }}
              </div>
            </div>
          </div>
        </app-link>
        <div 
          v-else 
          class="item clickable fake-a" 
          :class="{ 'active-child': selectedChild(item) }" 
          role="button" 
          tabindex="0" 
          @keyup.enter="$emit('updateChild', item.name)" 
          @click="$emit('updateChild', item.name)"
        >
          <div :class="{'parent-with-icon':parentIcon}">
            <div class="content child">
              <base-icon 
                v-if="item.icon" 
                :icon="`${item.icon} fa-fw`"
              />
              <div :class="[{'align-no-icon-child': !item.icon && containsIcon}]">
                {{ $t(item.label || item.labelKey) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PanelItem',
  props: {
    active: {
      type: Boolean,
    },
    to: {
      type: [Object, String],
    },
    children: {
      type: Array,
      default: () => [],
    },
    displayAllChildren: {
      type: Boolean,
      default: false,
    },
    parentIcon: {
      type: Boolean,
      required: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click', 'updateChild'],
  computed: {
    activeChild() {
      return !!this.children.find(c => c.to && (
        // the starts with is to ensure that children pages from the child are marking
        // The item as selected
        (c.to.path && (c.to.path === this.$route.path ||
          this.$route.path.startsWith(c.to.path))) ||
        (c.to.name && c.to.name === this.$route.name)));
    },
    containsIcon() {
      return !!this.children.some(view => view.icon);
    },
    isActiveRoute() {
      const r = this.$router.resolve(this.to);
      return this.$route.path.startsWith(r.fullPath);
    },
  },
  methods: {
    click(name) {
      this.$emit('click', name);
    },
    selectedChild(item) {
      return item.route === this.$route.fullPath;
    },
  },
};
</script>
<style scoped lang="scss">
.item {
    border-bottom: 1px solid #f0f0f0;
    &.active {
      border-left-width: 6px;
      border-left-style: solid;
    }

    font-size: 14px;
    display: flex;
    align-items: flex-start;
    &.collapsible {
      flex-direction: column;
    }
    justify-items: center;
    transition: border-left-width 0.5s, color 0.5s;

    .content {
      margin-left: 5px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.child {
        margin-left: 20px;
        font-size: 13.5px;
      }
    }
}
.fulllink {
  width: 100%;
}

em {
  display: inline-block;
  margin-right: 10px;
  max-width: 18px;
}

.align-no-icon-child {
  margin-left: 28px;
}

.parent-with-icon {
  margin-left: 18px;
}
</style>
