<template>
  <base-menu-page 
    :loading="loading" 
    :breadcrumb="breadcrumb" 
    :sections="sections"
  >
    <router-view 
      :quota="quota" 
      :connection="serviceConnection" 
      @updated-quota="quota = $event"
    ></router-view>
  </base-menu-page>
</template>
<script>
import apis from '@/utils/apis';

export default {
  name: 'QuotaPage',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      quota: {
        quotaDetails: [],
        serviceConnection: {},
      },
      loading: true,
      serviceConnection: null,
    };
  },
  computed: {
    sections() {
      return [{
        title: this.$t('details'),
        to: { name: 'quotaDetails' },
      }, {
        title: this.$t('assigned_organizations'),
        to: { name: 'quotaAssignedOrganizations' },
      }];
    },
    breadcrumb() {
      return [
        {
          label: 'quotas.name',
          to: { name: 'quotasList' },
        },
        ...(this.quota ? [{
          name: this.quota.name,
        }] : []),
      ];
    },
  },
  async created() {
    const quotaResp = await apis.quotas.find(this.id);
    if (quotaResp.ok) {
      this.quota = quotaResp.data;
      this.serviceConnection = quotaResp.data.serviceConnection;
    }
    this.loading = false;
  },
};
</script>
