export const comparatorMixin = {
  methods: {
    stringCompare(a, b) {
      const nameA = a.toLowerCase();
      const nameB = b.toLowerCase();
      return this.compare(nameA, nameB);
    },
    compare(a, b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    },
  },
};
