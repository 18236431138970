import { CrudApi } from './api';

class SAMLSettingsApi extends CrudApi {
  constructor() {
    super('saml_settings');
  }

  listForOrg(orgId) {
    return this.list({ qs: { organization_id: orgId } });
  }
}

const samlSettingsApi = new SAMLSettingsApi();

export default samlSettingsApi;
