<template>
  <base-modal 
    :open="open" 
    :preventClose="preventClose"
    :showCloseIcon="showCloseIcon"
    @close="$emit('cancel')" 
  >
    <div class="confirm-dialog operation">
      <div class="col-md-12">
        <div class="main-title title col-xs-12 col-md-12">
          {{ $t(headerLabel, headerLabelInterpolation) }}
        </div>
        <div class="col-xs-12 col-md-12">
          <base-form 
            :disabled="disabled" 
            :executing="executing" 
            :submitLabel="confirmLabel" 
            :cancelLabel="cancelLabel" 
            :hideSubmit="hideConfirm" 
            :hideCancel="hideCancel"
            @submit="$emit('ok')" 
            @cancel="$emit('cancel')" 
          >
            <form-row v-if="detailsLabel">
              <alert-box 
                v-if="detailsLabel" 
                :label="detailsLabel" 
                :interpolation="detailsInterpolation"
              />
            </form-row>
            <slot></slot>
          </base-form>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    headerLabel: {
      type: String,
      required: true,
    },
    headerLabelInterpolation: {
      type: Object,
      default: null,
    },
    detailsLabel: {
      type: [String, Array],
    },
    detailsInterpolation: {
      type: Object,
      default: () => ({}),
    },
    confirmLabel: {
      type: String,
      default: 'confirm',
    },
    executing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: 'cancel',
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['ok', 'cancel'],
};
</script>

<style scoped lang="scss">
.confirm-dialog {
  .base-form {
    :deep(.buttons-container) {
      margin-top: 10px;
      padding-bottom: 0px;
      text-align: right;
    }
  }
  :deep(.main-title) {
    margin: 0px;
  }
}

h1 {
  font-weight: 100;
}

.operation {
  text-align: left;
  padding: 25px;
  margin: 0px;
}

.confirm-dialog {
  width: 100%;
  max-height: 80%;
  .breadcrumb {
    display: none;
  }

  .main-title {
    margin-bottom: 5px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .buttons-container {
    margin-top: 30px;
    text-align: right;
  }

  .base-form {
    padding-bottom: 0px;
  }
}
</style>
