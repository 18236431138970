<template>
  <em
    :aria-label="ariaLabel" 
    :aria-hidden="ariaHidden"
    :class="brandedIcon"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
    },
    ariaHidden: {
      type: String,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    customizable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      'orgBrandingInfo',
    ]),
    brandedIcon() {
      if (this.customizable) {
        return ((this.orgBrandingInfo || {}).customIcons || {})[this.prefixedIcon]
        || this.prefixedIcon;
      }
      return this.prefixedIcon;
    },
    prefixedIcon() {
      if (this.custom) {
        return this.icon;
      }
      if (this.icon.includes('fa fa-')) {
        return this.icon;
      }
      return `fa fa-${this.icon}`;
    },
  },
};
</script>
