import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useResellerOrgNavigationGuard() {
  const router = useRouter();
  const store = useStore();

  const selectedOrganization = computed(() => store.getters.selectedOrganization);

  const checkAccess = () => {
    if (!selectedOrganization.value.isReseller) {
      const redirectToHome = { 
        path: '/home',
        query: selectedOrganization.value.entryPoint ? { org: selectedOrganization.value.entryPoint } : {}
      };
      router.replace(redirectToHome);
    }
  };

  return { checkAccess };
}
