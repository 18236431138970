<template>
  <form-page
    navbarForm
    :executing="executing"
    :title="'usage_generation.add.title'"
    :description="'usage_generation.add.description'"
    :defaultBack="{name:'usageGenerations'}"
    :disabled="hasErrors"
    @submit="submitForm"
  >
    <form-row
      label="organization"
    >
      <base-select
        v-model="selectedOrgId"
        :items="orgs"
        @update:modelValue="onUpdateOrg"
      />
    </form-row>

    <form-row
      label="environment"
    >
      <base-select
        v-model="selectedEnvId"
        :items="envs"
        @update:modelValue="validate"
      />
    </form-row>

    <form-row
      label="usage_generation.add.json_templates.title"
      descriptionLabel="usage_generation.add.json_templates.description"
      class="code-editor"
    >
      <code-editor
        v-model="usageGenerationTemplatesJson"
        language="JSON"
        class="codeEditor"
        @keyup="validate"
      />
    </form-row>

    <form-row
      class="validation"
      :class="{ error: hasErrors, ok: !hasErrors }"
    >
      <ul>
        <li
          v-for="error in errors.general"
          :key="error"
        >
          {{ $t(error) }}
        </li>
      </ul>
      <ul
        v-for="(templateErrors, index) in errors.templates"
        :key="index"
      >
        <li
          v-for="error in templateErrors"
          :key="error"
        >
          template {{ index }}: {{ $t(error) }}
        </li>
      </ul>
    </form-row>
  </form-page>
</template>

<script>

import notify from "@/utils/notify";
import apis from "@/utils/apis";
import FormRow from "@/components/form/FormRow.vue";
import BaseSelect from "@/components/BaseSelect.vue";

const DEFAULT_USAGE_TYPE = 'USAGE-TYPE-ID'

export default {
  name: "AddUsageGeneration",
  components: {BaseSelect, FormRow},
  props: {},
  data() {
    return {
      usageGenerationTemplatesJson: JSON.stringify(this.buildDefault(), null, 2),
      executing: false,
      hasErrors: false,
      orgs: [],
      selectedOrgId: undefined,
      envs: [],
      selectedEnvId: undefined,
      errors: {
        general: [],
        templates: []
      }
    }
  },
  computed: {
  },
  mounted() {
    this.validate();
  },
  async created() {
    this.loading = true;
    const resp = await apis.organizations.list({ qs: { include_deleted: false, detailed: false } });
    this.loading = false;
    if (resp?.ok) {
      this.orgs = resp.data.map(o => ({
        value: o.id,
        display: o.name
      }));
    }
  },
  methods: {
    async submitForm() {
      this.executing = true;
      this.validate();
      if (this.hasErrors) {
        notify.error(this.$t('usage_generation.operations.create_from_json.error_validation'));
        this.executing = false;
        return;
      }

      let resp = await apis.usageGenerations.createFromDto({
        environmentId: this.selectedEnvId,
        templates: JSON.parse(this.usageGenerationTemplatesJson)
      })

      if (!resp.ok) {
        this.executing = false;

        let apiErrors = resp.errors.map(e => e.context ? e.context.reason : e.message).join(" | ")

        notify.error(this.$t('usage_generation.operations.create_from_json.error_api') + ": " + apiErrors);
      } else {
        this.$router.navigateBackOrDefault({ name: 'usageGenerations' });
      }
    },
    async onUpdateOrg(orgId) {
      this.selectedEnvId = undefined
      this.envs = []
      this.validate()
      await this.fetchEnvironments(orgId)
    },
    async fetchEnvironments(orgId){
      let resp = await apis.environments.getAccessibleEnvironmentsInOrg(orgId).then()
      if (resp?.ok) {
        this.envs = resp.data.map(e => ({
          value: e.id,
          display: "[" + e.serviceConnection.name + "] " + e.name
        }));
      }
    },
    validate() {
      let templates
      try {
        templates = JSON.parse(this.usageGenerationTemplatesJson)
      } catch (err) {
        this.errors = {
          general: ['usage_generation.operations.create_from_json.error_validation.no_valid_json'],
          templates: []
        }
        this.hasErrors = true;
        return;
      }

      let errors = {
        general: [],
        templates: []
      }

      if (this.selectedEnvId == undefined) {
        errors.general.push("usage_generation.operations.create_from_json.error_validation.environmentId.not_set")
      }

      if (templates == undefined || templates.length == 0) {
        errors.general.push("usage_generation.operations.create_from_json.error_validation.templates.empty")
      } else {
        templates.forEach(t => {errors.templates.push(this.validateTemplate(t))})
      }

      this.errors = errors;
      this.hasErrors = this.errors.general.length > 0 || this.errors.templates.filter(t => t.length > 0).length > 0;
    },
    validateTemplate(t) {
      let templateErrors = []

      if (t.startDate == undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.start_date.not_set")
      } else if (!this.isValidDate(t.startDate)) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.start_date.invalid")
      }

      if (t.endDate == undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.end_date.not_set")
      } else if (!this.isValidDate(t.endDate)) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.end_date.invalid")
      }

      if (t.usageType == undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.usage_type.not_set")
      } else if (t.usageType == DEFAULT_USAGE_TYPE) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.usage_type.is_default")
      }

      if (t.rawUsage == undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.raw_usage.not_set")
      } else if (isNaN(t.rawUsage) || t.rawUsage < 0) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.raw_usage.invalid")
      }

      if (t.nativeBillingCost != undefined && isNaN(t.nativeBillingCost) || t.nativeBillingCost < 0) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.native_cost.invalid")
      }

      if (t.nativeBillingCurrency == undefined && t.nativeBillingCost != undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.native_currency.not_set")
      } else if (t.nativeBillingCost == undefined && t.nativeBillingCurrency != undefined) {
        templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.native_cost.not_set")
      }

      if (t.nativeType && t.nativeType.nativeBillingUnit) {
        if (!t.nativeType.nativeBillingPeriod) {
          templateErrors.push("usage_generation.operations.create_from_json.error_validation.templates.native_type.native_billing_period.not_set")
        }
      }

      return templateErrors
    },
    isValidDate(date) {
      return (new Date(date) != "Invalid Date") && !isNaN(new Date(date));
    },
    buildDefault() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];

      return [
        {
          "entityId": "61b8ed5b-8c7f-498a-84c0-03b3685992b8",
          "startDate": firstDay,
          "endDate": lastDay,
          "usageType": DEFAULT_USAGE_TYPE,
          "rawUsage": 1000,
          "fields": {
            "A-FIELD-KEY": "A-FIELD-VALUE"
          },
          "customFields": {
            "fieldName" : "fieldValue"
          },
          "nativeBillingCost": null,
          "nativeBillingCurrency": null,
          "nativeType": {
            "nativeBillingUnit": null,
            "nativeBillingPeriod": null,
            "nativeBillingCategory": null
          }
        }
      ]
    }
  }
}


</script>


<style lang="scss" scoped>
  div.form-row.code-editor {
    padding-bottom: 0;
  }

  div.form-row.validation {
    border: 1px solid lightgrey;
    border-top: none;
    font-size: 80%;

    &.error {
      background-color: #ffcccb;
    }

    &.ok {
      background-color: #d1ffbd;
    }
  }
</style>
