
<template>
  <div>
    <form-page
      :title="!!id ? 'authentication.edit': 'authentication.create'"
      :disabled="!canSubmit"
      :defaultBack="{ name: 'providers-list' }"
      @submit="submit"
    >
      <form-row label="authentication.type">
        <base-select 
          v-model="identityProvider.type" 
          :disabled="true" 
          :items="typeItems" 
        />
      </form-row>
      <form-row label="authentication.provider">
        <base-select
          v-model="identityProvider.provider"
          :items="providers"
          :disabled="!!id"
          :placeholder="$t('authentication.provider_placeholder')"
        />
      </form-row>
      <div v-if="identityProvider.provider">
        <div v-if="identityProvider.provider==='CUSTOM'">
          <form-row
            label="authentication.display_name"
            descriptionLabel="authentication.display_name_desc"
          >
            <base-input v-model="identityProvider.displayName" />
          </form-row>
        </div>
        <div 
          v-for="type in providerTypeFields" 
          :key="type"
        >
          <form-row 
            :label="getLabel(type)" 
            :descriptionLabel="getDescLabel(type)"
          >
            <base-input
              v-model="identityProvider.parameters[type]"
              :type="isSensitive(type)"
              :error="fieldErrors[`parameters.${type}`] ? $t('authentication.error_codes.' + fieldErrors[`parameters.${type}`]) : null"
            />
          </form-row>
        </div>
        <form-row 
          label="authentication.prompt" 
          descriptionLabel="authentication.prompt_desc" 
          optional
        >
          <base-input v-model="identityProvider.parameters['prompt']"></base-input>
        </form-row>
        <div v-if="identityProvider.provider==='CUSTOM'">
          <form-row
            label="authentication.compact_logo"
            descriptionLabel="authentication.compact_logo_desc"
          >
            <upload-logo
              class="logo"
              :fileExists="!!logoPreview"
              :previewUrl="logoPreview"
              @addedFiles="addedFiles"
            />
          </form-row>
          <form-row 
            label="authentication.email_field_name_override" 
            descriptionLabel="authentication.email_field_name_override_desc" 
            optional
          >
            <base-input v-model="identityProvider.parameters['emailFieldNameOverride']"></base-input>
          </form-row>
          <form-row 
            label="authentication.login_button_css" 
            :optional="true"
          >
            <code-editor 
              v-model="identityProvider.css" 
              class="css" 
              language="CSS" 
            />
          </form-row>
        </div>
        <form-row 
          label="authentication.display_order" 
          descriptionLabel="authentication.display_order_desc" 
          optional
        >
          <base-input 
            v-model="identityProvider.rank"
            type="number"
            :error="fieldErrors.rank ? $t(`authentication.error_codes.${fieldErrors.rank}`) : null"
          />
        </form-row>
        <form-row v-if="supportSingleLogout">
          <base-checkbox
            v-model="identityProvider.enableSingleLogout"
            label="authentication.single_logout"
            description="authentication.single_logout_desc"
          />
        </form-row>
        <form-row>
          <base-checkbox
            v-model="identityProvider.disableNativeAuthentication"
            label="authentication.disable_native_auth"
            description="authentication.disable_native_auth_desc"
          />
        </form-row>
      </div>
    </form-page>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import UploadLogo from '@/components/UploadLogo';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import { mapGetters } from 'vuex';

export default {
  name: 'IdentityProviderForm',
  components: {
    UploadLogo,
  },
  mixins: [orgSwitchNavigationMixin],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      identityProvider: {
        loading: true,
        id: null,
        type: 'OIDC',
        provider: '',
        css: '',
        parameters: {},
        displayName: '',
        rank: null,
        enableSingleLogout: false,
        disableNativeAuthentication: false,
      },
      logoFile: {},
      types: [],
      defaultProviders: {},
      fieldErrors: {},
      existingProviders: [],
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization']),
    provider() {
      return this.identityProvider.provider;
    },
    typeItems() {
      const typeLabels = {
        OIDC: 'OpenID Connect (OIDC)',
      };

      const values = [];
      Object.keys(this.types).forEach(key =>
        values.push({ value: key, label: typeLabels[key] }),
      );
      return values;
    },
    logoPreview() {
      if (this.identityProvider.logo) {
        return this.identityProvider.logo;
      }
      return null;
    },
    supportSingleLogout() {
      return this.provider === 'CUSTOM' || (this.defaultProviders[this.provider] || {}).supportSingleLogout;
    },
    canSubmit() {
      let canSubmit =
        this.identityProvider.provider === 'CUSTOM'
          ? !!this.identityProvider.displayName &&
            (this.logoFileSet || this.identityProvider.logo)
          : true;
      if (this.providerTypeFields) {
        const missingFields = this.providerTypeFields.filter(
          value => (!this.identityProvider.parameters[value]),
        );
        canSubmit = canSubmit && !missingFields.length;
      }
      return canSubmit;
    },
    providers() {
      const values = [];
      if (Object.keys(this.defaultProviders).length > 0) {
        Object.keys(this.defaultProviders).forEach(key =>
          values.push({
            disabled: this.providerTypeExists(key),
            value: key,
            image: this.defaultProviders[key].logo,
            display: this.defaultProviders[key].displayName,
            group: 'authentication.providers',
          }),
        );
        values.sort((x, y) => {
          if (x.disabled === y.disabled) {
            return 0;
          }
          return x.disabled ? 1 : -1;
        });
        values.push({
          value: 'CUSTOM',
          label: 'authentication.custom_providers_desc',
          group: 'authentication.custom_providers',
        });
      }
      return values;
    },
    providerTypeFields() {
      const typeFields = this.types[this.identityProvider.type] || [];
      const defaultProviderFields = (this.defaultProviders || {})[
        this.identityProvider.provider
      ];
      if (
        this.identityProvider.provider !== 'CUSTOM' &&
        defaultProviderFields
      ) {
        return typeFields.filter(
          val =>
            !Object.prototype.hasOwnProperty.call(defaultProviderFields, val) ||
            val === 'issuerURL',
        );
      }
      return typeFields;
    },
    logoFileSet() {
      return Object.keys(this.logoFile).length !== 0;
    },
  },
  watch: {
    async provider(val) {
      if (!this.id) {
        // If there's a default issuerURL as a parameter, we want to set it when
        // the provider changes and want to clear the previous parameters
        this.identityProvider.parameters = [];
        const defaultProviderFields = (this.defaultProviders || {})[val];
        if (defaultProviderFields) {
          this.identityProvider.parameters = {
            ...defaultProviderFields,
          };
        }
      }
    },
  },
  async created() {
    this.loading = true;
    await this.populateIdp();
    await this.populateTypes();
    await this.populateDefaultProviders();
    await this.populateExistingProviders();
    this.loading = false;
  },
  methods: {
    isSensitive(type) {
      return type === 'clientSecret' ? 'password' : 'text';
    },
    addedFiles(file) {
      this.logoFile = file;
    },
    getLabel(key) {
      return `authentication.${key.toLowerCase()}`;
    },
    getDescLabel(key) {
      const path = `${this.getLabel(key)}_desc`;
      const templateString = this.$t(path);
      return templateString !== path ? templateString : undefined;
    },
    async submit() {
      let resp = {};
      const idp = this.buildIdp();
      if (!this.id) {
        resp = await apis.identityProviders.create(idp);
      } else {
        resp = await apis.identityProviders.update(this.id, idp);
      }
      if (resp.status === 200) {
        const defaultDisplayName =
          (this.defaultProviders[this.identityProvider.provider] || {}).displayName;
        const displayName = this.identityProvider.displayName || defaultDisplayName;
        // Since there is not organization set on audit event, we need to manually emit the success
        // This should change in the future
        notify.success(
          this.id
            ? this.$t('authentication.idp_update_success', {
              name: displayName,
            })
            : this.$t('authentication.idp_add_success', { name: displayName }),
        );
        this.$router.push({ name: 'providers-list' });
      } else if (resp.errors) {
        notify.error(this.$t('unexpected_error'));
        resp.errors
          .filter(value => value.ref)
          .filter(value => value.ref.scope)
          .forEach(value =>
            this.fieldErrors[value.ref.scope] = value.ref.code
          );
      }
    },
    buildIdp() {
      const arrayParams = [];
      Object.keys(this.identityProvider.parameters).forEach(key =>
        arrayParams.push({
          parameter: key,
          value: this.identityProvider.parameters[key],
        }),
      );
      const idp = {
        provider: this.identityProvider.provider,
        type: this.identityProvider.type,
        displayName: this.identityProvider.displayName,
        css: this.identityProvider.css,
        rank: parseInt(this.identityProvider.rank, 10),
        enableSingleLogout: this.identityProvider.enableSingleLogout,
        parameters: arrayParams,
        organization: {
          id: this.selectedOrganization.id,
        },
        disableNativeAuthentication: this.identityProvider.disableNativeAuthentication,
      };

      if (this.id) {
        idp.id = this.id;
      }

      if (this.logoFileSet) {
        idp.logo = this.logoFile.dataURL;
      }
      return idp;
    },
    providerTypeExists(providerType) {
      if (this.existingProviders) {
        return (
          this.existingProviders.filter(
            value => value.provider === providerType,
          ).length > 0
        );
      }
      return false;
    },
    async populateIdp() {
      if (this.id) {
        const identityProvider = await apis.identityProviders.find(this.id);
        if (identityProvider.status !== 200 || !identityProvider.data) {
          notify.error(this.$t('unexpected_error'));
        } else {
          this.$data.identityProvider = { ...identityProvider.data };
          const params = {};
          if (identityProvider.data.parameters) {
            identityProvider.data.parameters.forEach((element) => {
              params[element.parameter] = element.value;
            });
            this.$data.identityProvider.parameters = { ...params };
          }
        }
      }
    },
    async populateTypes() {
      const types = await apis.identityProviders.types();
      if (types.status !== 200 || !types.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.types = { ...types.data };
      }
    },
    async populateDefaultProviders() {
      const defaultProviders = await apis.identityProviders.defaultProviders();
      if (defaultProviders.status !== 200 || !defaultProviders.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.defaultProviders = { ...defaultProviders.data };
      }
    },
    async populateExistingProviders() {
      const existing = await apis.identityProviders.list({
        qs: {
          organization_id: this.selectedOrganization.id,
        },
      });
      if (existing.status !== 200 || !existing.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.existingProviders = [...existing.data];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  max-width: 500px;
}

:deep(.cm-editor) {
  height: 100px;
  max-width: 500px;
}
</style>
