<template>
  <div class="upload-logo">
    <div
      ref="dropzoneElement"
      class="dropzone"
    >
      <div
        :id="id"
        class="dz-preview dz-image dz-image-preview dz-file-preview" 
        :class="[(fileExists || filesInDropzone) ? 'checkered-bg' : '']"
        clickable
        :style="{'width': prevWidth, 'height': prevHeight }"
      >
        <base-image 
          v-if="!filesInDropzone && fileExists" 
          class="thumbnail-preview" 
          :altText="altText" 
          :src="imagePreviewEndpoint"
        />
        <base-icon 
          v-else-if="!filesInDropzone" 
          icon="fa fa-image"
        />
      </div>
      <div 
        class="dz-message" 
        tabindex="0" 
        @keyup.enter="triggerInput"
      >
        <slot>{{ $t(customLabel) }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import { randomString } from '@/utils';
import notify from '@/utils/notify';
import i18n from '@/i18n';
import { DEFAULT_DATE_FORMAT, format, getNow } from '@/utils/dates';
import { Units, bytesToSize, sizeToBytes } from '@/utils/units';
import httputils from '@/utils/httputils';

export default {
  name: 'UploadLogo',
  props: {
    triggerUpload: {
      type: Boolean,
      default: false,
    },
    fileExists: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    maxSize: {
      type: Number,
      required: false,
      default: 256,
    },
    fileUploadEndpoint: {
      type: String,
      default: '/file-upload',
    },
    thumbWidth: {
      type: Number,
      required: false,
      default: 100,
    },
    previewUrl: {
      type: String,
      required: false,
    },
    thumbHeight: {
      type: Number,
      required: false,
      default: 100,
    },
    customLabel: {
      type: String,
      required: false,
      default: 'default_dropzone_message',
    },
    acceptedFileTypes: {
      type: String,
      required: false,
    },
    altTextLabel: {
      type: String,
      default: 'logo_preview',
    },
  },
  emits: ['addedFiles'],
  data() {
    return {
      maxNumberFiles: 1,
      numFiles: 0,
      id: `preview${randomString()}`,
      dz: {},
    };
  },
  computed: {
    dropzoneSettings() {
      const defaultValues = {
        maxFiles: this.maxNumber,
        maxFilesize: this.maxSize,
        thumbnailWidth: this.thumbWidth,
        thumbnailHeight: this.thumbHeight,
        uploadMultiple: false,
        acceptedFiles: this.acceptedFileTypes,
        previewsContainer: `#${this.id}`,
        url: this.fileUploadEndpoint,
        headers: { [httputils.CSRF_TOKEN_HEADER]: httputils.getCsrfToken() },
        previewTemplate: "<div class='dz-file-preview' style='display:flex;'><img data-dz-thumbnail class='thumbnail-preview'/></div>",
        // dont upload until accepted - submit
        autoProcessQueue: false,
        timeout: 10 * 60 * 60 * 100,
      };
      return defaultValues;
    },
    imagePreviewEndpoint() {
      if (this.previewUrl) {
        return this.previewUrl;
      }
      // The image preview endpoint is the same as the one we post to upload the logo artifact
      return `${this.fileUploadEndpoint}?t=${format(getNow(), DEFAULT_DATE_FORMAT)}`;
    },
    prevWidth() {
      return `${this.thumbWidth}px`;
    },
    prevHeight() {
      return `${this.thumbHeight}px`;
    },
    filesInDropzone() {
      return this.numFiles > 0;
    },
    altText() {
      return this.$t(this.altTextLabel);
    },
  },
  watch: {
    triggerUpload(val) {
      if (val) {
        this.dz.processQueue();
      }
    },
  },
  mounted() {
    this.maxNumberFiles = this.maxNumber;
    const newDz = new Dropzone(
      this.$refs.dropzoneElement,
      this.dropzoneSettings,
    );

    this.dz = newDz;
    const vm = this;

    this.dz.options.resize = function resizeImage(file) {
      const trgWidthToHeight = vm.thumbWidth / vm.thumbHeight;
      const fileWidthToHeigh = file.width / file.height;

      let adjustedheight;
      let adjustedWidth;

      if (fileWidthToHeigh > trgWidthToHeight) {
        adjustedWidth = vm.thumbWidth;
        const heightToWidth = file.height / file.width;
        adjustedheight = vm.thumbWidth * heightToWidth;
      } else {
        adjustedheight = vm.thumbHeight;
        const widthToHeight = file.width / file.height;
        adjustedWidth = vm.thumbHeight * widthToHeight;
      }

      return {
        srcWidth: file.width,
        srcHeight: file.height,
        trgWidth: adjustedWidth,
        trgHeight: adjustedheight,
      };
    };

    this.dz.on('addedfile', function addedFile(file) {
      // if the user adds another file when already at the maximum number of accepted
      // files, the new file overwrites the oldest
      if (this.files && this.files.length > vm.maxNumberFiles
        && this.files[vm.maxNumberFiles - 1]) {
        this.removeFile(this.files[0]);
      }
      vm.$emit('addedFiles', file);
      vm.numFiles = this.files ? this.files.length : 0;
    });

    // Required bc fileupload endpoint changes and reactivity does not place nice with dropzone
    // Eg: on create you don't know the upload endpoint until the branding is created.
    this.dz.on('processing', function proccessingFile() {
      this.options.url = vm.fileUploadEndpoint;
    });

    this.dz.on('complete', (file) => {
      vm.$emit('addedFiles', file);
    });

    // we want our own error messages
    this.dz.on('error', function error(file, errorMessage) {
      this.removeFile(file);

      // If there's an error message of type object, this is a server-side error
      // and not a dropzone error, we should emit it upwards to update
      // the status of the failed upload
      if (typeof errorMessage === 'object' && errorMessage !== null) {
        vm.$emit('addedFiles', file);
        return;
      }

      if (errorMessage.includes('File is too big ')) {
        notify.error(
          `${i18n.global.t('file_too_large', { filesize: bytesToSize(file.size) })} 
          ${i18n.global.t('max_file_size', { filesize: bytesToSize(sizeToBytes(vm.maxSize, Units.MB)) })}`);
      } else if (errorMessage.includes('You can\'t upload files of this type.')) {
        notify.error(
          `${i18n.global.t('wrong_file_type', { filetype: (file.type) })}
          ${i18n.global.t('required_file_types', { accFileTypes: (vm.acceptedFileTypes) })}`);
      } else if (errorMessage.includes('You can not upload any more files.')) {
        notify.error(
          `${i18n.global.t('maximum_files_reached', { filetype: (file.type) })}`);
      }
      vm.numFiles = this.files ? 0 : this.files.length;
    });
  },
  methods: {
    triggerInput() {
      this.dz.hiddenFileInput.click();
    },
  },
};

</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.upload-logo {
  position: relative;
}

.take-full-height {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dropzone {
  display: flex;
  flex-direction: row;
}

.thumbnail-preview {
  padding: 2px;
  max-height: 100%;
  max-width: 100%;
  display: flex;
}

.dz-message {
  cursor: pointer;
  border: 1px dashed var(--border-plus-ten);
  color: var(--detail-text);
  margin-left: 10px;
  word-wrap: normal;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.dz-preview {
  border: 1px solid var(--dark-border);
  color: var(--gray);
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dz-success-mark .dz-error-mark {
  display: none;
}
</style>
