import apis from '@/utils/apis';
import store from '@/store';

import ContactSupportPage from './ContactSupportPage';

const ContactSupportPageRoute = [{
  path: 'support',
  component: ContactSupportPage,
  name: 'support',

  async beforeEnter(to, from, next) {
    if (to.path === '/hc/support') {
      const resp = await apis.support.isSupportEnabled();
      if (!resp) {
        return next('/hc');
      } else if (!store.getters.loggedIn) {
        return next({ path: `/login${to.path}`, query: to.query });
      }
    }
    return next();
  },
}];

export default ContactSupportPageRoute;
