const CSRF_COOKIE = 'X-CSRF-TOKEN';
const CSRF_TOKEN_HEADER = 'X-CSRF-TOKEN';
const CONTENT_TYPE_HEADER = 'Content-Type';
const ACCEPT_HEADER = 'accept';
const APPLICATION_JSON = 'application/json';
const TEXT_CSV = 'text/csv';
const URL_ENCODED = 'application/x-www-form-urlencoded';
const UNIX_TIME_ZERO = 'Thu, 01 Jan 1970 00:00:00';
const ROOT_PATH = '/';
const COOKIE_DELIMITER = ';';

const LOGOUT_EP = '/rest/auth/tokens/clear';
const HTTP_HEAD = 'HEAD';
const HTTP_GET = 'GET';
const HTTP_POST = 'POST';
const HTTP_PUT = 'PUT';
const HTTP_DELETE = 'DELETE';
const HTTP_PATCH = 'PATCH';

const UNAUTHORIZED_ERROR = 'INVALID_CREDENTIALS';

/**
 * Given a cookie name find the value currently set for it.
 * The {@code #decodeURIComponent} method returns all the available cookies as
 * key-value pairs in one string (ex: random=value; X-CSRF-TOKEN=12345aCSRFtoken09876).
 * @param {String} cname the name of the cookie whose value is to be read
 */
function readCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(COOKIE_DELIMITER);
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    c = c.trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function set(cookieName, cookieValue, expireOn, path) {
  document.cookie = `${cookieName}=${cookieValue}; expires=${expireOn}; path=${path};`;
}

const getCookie = cookieName => readCookie(cookieName);
const clearCookie = cookieName => set(cookieName, '', UNIX_TIME_ZERO, ROOT_PATH);
const getCsrfToken = () => getCookie(CSRF_COOKIE);

export default {
  set,
  getCookie,
  clearCookie,
  getCsrfToken,
  CSRF_TOKEN_HEADER,
  ACCEPT_HEADER,
  CONTENT_TYPE_HEADER,
  APPLICATION_JSON,
  TEXT_CSV,
  URL_ENCODED,
  LOGOUT_EP,
  HTTP_HEAD,
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT,
  HTTP_DELETE,
  HTTP_PATCH,
  UNAUTHORIZED_ERROR,
};
