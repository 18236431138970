<template>
  <div>
    <base-loader v-if="loading" />
    <base-list 
      v-else
      ariaLabel="quotas.name" 
      enableInfiniteScroll 
      :loading="loading" 
      :list="quotas"
      :ariaRowCount="filteredQuotas.length"
    >
      <list-header>
        <list-column>{{ $t('name') }}</list-column>
        <list-column>{{ $t('service_connection') }}</list-column>
        <list-column v-if="ownedQuotas">
          {{ $t('default') }}
        </list-column>
        <list-column v-if="ownedQuotas">
          {{ $t('assigned_organizations') }}
        </list-column>
      </list-header>
      <list-row 
        v-for="quota in filteredQuotas"
        :key="quota.id" 
        :to="noDetails ? null : { name: 'quotaDetails' , params: { id: quota.id } }" 
      >
        <list-column>
          {{ upperFirstCharOnly(quota.name) }}
          <span class="description">{{ quota.description }}</span>
        </list-column>
        <list-column>
          <service-label :connection="quota.serviceConnection"></service-label>
        </list-column>
        <list-column v-if="ownedQuotas">
          <base-tags :tags="quota.tags"></base-tags>
        </list-column>
        <list-column v-if="ownedQuotas">
          {{ quota.numberOfOrganizationsAppliedTo }}
        </list-column>
        <template 
          v-if="ownedQuotas"
          #actions 
        >
          <action-icon 
            v-if="hasEditPermission && supportsManagement(quota)" 
            :to="`/admin/quotas/${quota.id}/details/edit`" 
            tooltipLabel="quotas.edit" 
            icon="fa fa-edit" 
            expandOnHover
          ></action-icon>
          <quotas-list-actions 
            :selectedQuota="quota" 
            :quotas="quotas" 
            @refresh="refresh"
          ></quotas-list-actions>
        </template>
      </list-row>
      <no-results-message v-if="quotas.length && !filteredQuotas.length" />
      <template #empty>
        <empty-message
          v-if="!quotas.length && !loading && !search"
          icon="fa fa-sliders"
          :textLabel="noQuotaLabel"
        />
      </template>
    </base-list>
    <infinite-scroll 
      :load="loadMore" 
      :loading="loading" 
      :hasMore="hasMore" 
      :loadOnInit="false"
    ></infinite-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import ServiceLabel from '@/components/ServiceLabel';
import QuotasListActions from './QuotasListActions';
import authz from '@/authz';
import { searchFilter, sortBy } from '@/utils';
import { upperFirstCharOnly } from '@/utils/label';

const PAGE_SIZE = 50;
const RESELLER_QUOTAS_PERMISSION = "reseller:quotas";

export default {
  name: 'QuotasList',
  components: { ServiceLabel, QuotasListActions },
  props: {
    type: {
      type: String,
    },
    pageSize: {
      type: Number,
      default: PAGE_SIZE,
    },
    organization: {
      type: Object,
    },
    noDetails: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      quotas: [],
      page: 0,
      total: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'locale',
      'serviceDescriptors',
    ]),
    hasEditPermission() {
      return authz.hasPermission(RESELLER_QUOTAS_PERMISSION);
    },
    ownedQuotas() {
      return this.type === 'owned';
    },
    fetchedQuotas() {
      return this.quotas;
    },
    hasMore() {
      if (this.total !== null) {
        return this.total > this.page * this.pageSize;
      }
      return true;
    },
    search() {
      return this.$route.query.q;
    },
    noQuotaLabel() {
      return `quotas.no_${this.type}_quotas`;
    },
    organizationId() {
      return this.organization && this.organization.id
        ? this.organization.id
        : this.selectedOrganization.id;
    },
    filteredQuotas() {
      return (this.quotas || [])
        .slice()
        .sort(sortBy(env => env.name.toLowerCase()))
        .filter(searchFilter(this.search, e => [e.name, e.description, e.id]));
    },
  },
  watch: {
    selectedOrganization() {
      this.refresh();
    },
    locale() {
      this.refresh();
    },
  },
  async created() {
    this.resetPaging();
    await this.fetchQuotas(1);
  },
  methods: {
    upperFirstCharOnly,
    supportsManagement(q) {
      const sc = q.serviceConnection;
      return this.serviceDescriptors[sc.type].supportsQuota &&
             this.serviceDescriptors[sc.type].quotaMetricIdentifiers.length > 0;
    },
    async fetchQuotas(page) {
      this.loading = true;
      const qs = {
        qs: {
          page_size: this.pageSize,
          page,
          type: this.type,
          organization_id: this.organizationId,
          order_by: 'serviceConnection.name, name',
        },
      };
      const response = await apis.quotas.list(qs);
      this.loading = false;
      if (response && response.status !== 200) {
        const message = response.status === 403 ? 'quotas.not_authorized_to_list_quotas' : 'unexpected_error';
        notify.error(this.$t(message));
        return;
      }

      // add tags
      const responseData = response.data;
      for (let i = 0; i < responseData.length; i += 1) {
        const responseQuota = responseData[i];
        responseQuota.tags = [];
        if (responseQuota.defaultForService) {
          responseQuota.tags.push({ id: 'quotas.default_for_service', name: this.$t('service') });
        }
        if (responseQuota.defaultForTrial) {
          responseQuota.tags.push({ id: 'quotas.default_for_trial', name: this.$t('pill_trial_status') });
        }
      }

      if (page === 1) {
        this.quotas = response.data;
      } else {
        this.quotas = this.quotas.concat(response.data);
      }
      this.total = response.metadata.recordCount;

      this.page = this.page + 1;
    },
    refresh() {
      this.loading = true;
      this.resetPaging();
      this.fetchQuotas(1);
      this.loading = false;
    },
    async loadMore() {
      await this.fetchQuotas(this.page + 1);
    },
    resetPaging() {
      this.page = 0;
      this.total = null;
    },
  },
};
</script>
