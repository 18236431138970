<template>
  <div class="package">
    <div>{{ $t('monetization.assigned_pricing_package') }}</div>
    <div>{{ packageName }}</div>
    <div v-if="showEdit">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import authz from '@/authz';
import { mapGetters } from 'vuex';

export default {
  name: 'BillingPricingPackage',
  props: {
    pricingPackage: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['locale']),
    packageName() {
      return (this.pricingPackage.name || {})[this.locale];
    },
    showEdit() {
      return this.isEditMode && authz.hasPermission('reseller:organizationBilling');
    },
  },
};
</script>

<style scoped lang="scss">
.package {
  display: flex;
  padding: 0px 0px 5px 0px;
  > div {
    padding-right: 10px;
  }
  > div:nth-child(2) {
    font-weight: bold;
  }
}
</style>
