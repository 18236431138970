<template>
  <empty-message 
    icon="fa fa-map-signs" 
    textLabel="not_found_error_header" 
    buttonIcon="fa fa-home" 
    buttonLabel="not_found_error_message" 
    buttonRoute="/home"
  />
</template>

<style scoped lang="scss">
em.fa-map-signs {
  font-size: 5rem;
}
</style>
