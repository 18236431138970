import {CrudApi} from './api';

class EnterpriseRegistrationApi extends CrudApi {
    constructor() {
        super('enterprise_registrations');
    }

    getRegistration(token) {
        return this.get(`${this.path}/registration`, { qs: { registrationToken: token } });
    }

    validateRegistrationToken(token) {
        return this.get(`${this.path}/check`, { qs: { registrationToken: token } });
    }

    register(password, token) {
        return this.post(`${this.path}/register`, { qs: { registrationToken: token }, body: password  });
    }

    resendUserRegistration(id) {
        return this.post(`${this.path}/resend_registration`, { qs: { enterpriseId: id } });
    }

    updateWithToken(registration, token) {
      return this.put(`${this.path}/update`, { qs: { registrationToken: token }, body: registration  });
    }

    getPasswordPolicy(token) {
      return this.get(`${this.path}/password_policy`, { qs: { registrationToken: token }});
    }
}

const enterpriseRegistrations = new EnterpriseRegistrationApi();
export default enterpriseRegistrations;
