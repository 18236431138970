import { createStore } from 'vuex';
import App from '@/app/vuex';
import apis from '@/utils/apis';
import { randomString } from '@/utils';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { refreshJob } from '../utils/apis/api';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  state: {
    loggedIn: null,
    userContext: {},
    identityProvider: null,
    sessionTimeout: false,
    locale: null,
    defaultLocale: null,
    currentOrganization: null,
    organizationFeatures: [],
    windowId: randomString(),
    globalProperties: {},
    permissionsPerOrg: {},
    previousRoute: {},
    orgBrandingInfo: {},
    userBrandingInfo: {},
    serviceSubMenu: {
      tabs: [],
      overridePanelMenu: false,
      showSubTabs: false,
    },
    feedbackSettings: {},
    billingSettings: {},
  },
  actions,
  getters,
  mutations,
  modules: {
    App,
  },
  strict: debug,
});

if (apis && apis.use) {
  apis.use(store);
  refreshJob(store);
}

export default store;
