<template>
  <list-row noActions>
    <list-column direction="horizontal">
      {{ discountName }} {{ `(${discountPercentage})` }}
      <base-tooltip 
        v-if="showDiscountRange(discount, billingCycle)"
        :message="getDiscountRangeForInvoice(discount, billingCycle)"
        direction="bottom" 
        class="margin-left" 
        overflow
      >
        <base-icon 
          class="description" 
          icon="info-circle" 
          :customizable="false"
        />
      </base-tooltip>
    </list-column>
    <list-column alignRight>
      {{ formatShortCurrency(adjustmentAmount, currency) }}
    </list-column>
  </list-row>
</template>
<script>
import { currencyMixin } from '@/mixins/currencyMixin';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'InvoiceDiscountRow',
  mixins: [currencyMixin, billingCycleMixin],
  props: {
    discount: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    billingCycle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['locale']),
    adjustmentAmount() {
      return this.discount.amount;
    },
    discountName() {
      return this.discount.name[this.locale];
    },
    discountPercentage() {
      return this.discount.discount;
    },
  },
};
</script>
<style lang="scss" scoped>
.margin-left {
  margin-left: 5px;
}
</style>
