<template>
  <div 
    :role="role" 
    class="date-input"
  >
    <base-input
      icon="fa fa-calendar"
      cursor="pointer"
      :class="{ 'error-border': invalidDate }"
      :modelValue="modifiedDate || dateFormat"
      :error="error"
      :disabled="disabled"
      @update:modelValue="tryApplyDate"
      @keydown.enter.prevent="handleClick"
      @keydown.shift.tab="closeNoUpdate"
      @focus.capture="handleFocus"
      @return="handleClick"
      @click="handleClick"
    />
    <base-dropdown 
      :opened="openCalendar" 
      direction="down" 
      @close="close"
    >
      <div 
        class="calendar-container" 
        @focus.capture="handleFocus" 
        @blur.capture="handleUnfocus"
      >
        <base-calendar 
          v-if="!showTimePicker" 
          ref="calendar"
          class="cal"
          :presetsEnabled="false"
          :utc="utc"
          :minDate="minDate"
          :maxDate="maxDate"
          :start="dateValue"
          :end="dateValue"
          @select="onChange"
        />
        <base-time-picker 
          v-if="showTimePicker"
          :date="timeValue"
          :utc="utc"
          :standalone="false"
          :hideControls="false"
          :hideNow="false"
          :hideAmPm="false"
          :is24Hour="false"
          @timeUpdate="timeUpdate"
          @back="backToCalendar"
          @close="close"
        />
      </div>
    </base-dropdown>
  </div>
</template>

<script>
import {
  getDate,
  setTime,
  DEFAULT_DATE_FORMAT,
  LIST_SIMPLE_DATE_FORMAT } from '@/utils/dates';

export default {
  name: 'DateInput',
  props: {
    modelValue: {
      type: [ String, Date ],
      required: false,
    },
    utc: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    includeTime: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: 'input',
    },
    error: {
      type: [String, Boolean],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      openCalendar: false,
      modifiedDate: null,
      showTimePicker: false,
      invalidDate: false,
      isOnTimePicker: false,
      reFocused: false,
    };
  },
  computed: {
    dateFormat() {
      const format = this.includeTime ? DEFAULT_DATE_FORMAT : LIST_SIMPLE_DATE_FORMAT;
      return this.$date(this.dateValue, format, this.utc);
    },
    dateValue() {
      return this.allowEmpty && !this.modelValue ? undefined : getDate(this.modelValue, this.utc);
    },
    timeValue() {
      return this.modelValue;
    },
  },
  watch: {
    openCalendar(newValue) {
      if (newValue) {
        this.modifiedDate = this.dateFormat;
      } else {
        this.modifiedDate = null;
      }
    },
    showTimePicker(newShowVal) {
      if (newShowVal) {
        this.modifiedDate = null;
      } else {
        this.modifiedDate = this.dateFormat;
      }
    },
  },
  methods: {
    onChange(val) {
      if (!val) {
        return;
      }
      // preserve time from previous selection
      const oldDate = getDate(this.modelValue, this.utc);
      const changedValue = (this.includeTime && this.modelValue) ?
        setTime(val, oldDate.getHours(), oldDate.getMinutes(), this.utc) :
        getDate(val, this.utc);
      this.$emit('update:modelValue', changedValue);
      if (this.includeTime) {
        this.showTimePicker = true;
      } else {
        this.close();
      }
    },
    tryApplyDate(val) {
      this.modifiedDate = val;
      const newDate = (this.allowEmpty && !val) ? undefined : getDate(val, this.utc);
      if (!this.allowEmpty && !newDate) {
        this.invalidDate = true;
        return;
      }
      this.invalidDate = false;
      this.$emit('update:modelValue', newDate);
      this.jumpToDate(newDate);
    },
    jumpToDate(newDate) {
      if (this.$refs.calendar) {
        this.$refs.calendar.jumpTo(newDate);
      }
    },
    handleClick(event) {
      if (!this.disabled) {
        if (this.openCalendar && event) {
          event.stopPropagation();
        }
        this.openCalendar = true;
      }
    },
    timeUpdate(time) {
      if (time) {
        this.$emit('update:modelValue', setTime(this.modelValue, time.hours, time.minutes, 0, this.utc));
      }
    },
    backToCalendar(time) {
      this.timeUpdate(time);
      this.showTimePicker = false;
    },
    close(time) {
      this.timeUpdate(time);
      this.showTimePicker = false;
      this.openCalendar = false;
    },
    closeNoUpdate() {
      this.showTimePicker = false;
      this.openCalendar = false;
    },
    handleFocus() {
      this.reFocused = true;
      this.isOnTimePicker = this.showTimePicker;
    },
    handleUnfocus() {
      this.reFocused = false;
      setTimeout(() => {
        if (!this.reFocused && !this.showTimePicker) {
          this.closeNoUpdate();
        }
        if (!this.reFocused && this.isOnTimePicker) {
          this.closeNoUpdate();
        }
      }, 250);
    },
  },
};
</script>

<style scoped lang="scss">
.date-input {
  position: relative;
  :deep(.dropdown) {
    position: absolute;
    right: auto;
    width: 350px
  }
  cursor:pointer;
}
.calendar-container {
  right: auto;
}
:deep(.input) {
  max-width: 350px;
}

.cal {
  margin: auto;
}


.error-border {
  :deep(input) {
    border-color: red;
  }
}
</style>

