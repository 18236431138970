import { reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';

const eventsQueues = reactive({
});

export default function () {
  const onEvent = (key, handler = null) => {
    if (handler) {
      return onEvent(key)(handler);
    }
    const lastEvents = computed(() => eventsQueues[key]);
    const clearEvents = () => eventsQueues[key] = [];
    return function(handle) {
      watch(lastEvents, (newVal) => {
        if (newVal && newVal.length) {
          newVal.forEach(v => handle(v.value));
          clearEvents();
        }
      }, { deep: true });
    }
  }

  const postEvent = (key, value) => {
    eventsQueues[key] = eventsQueues[key] || [];
    eventsQueues[key].push({ value, date: new Date() });
  }

  const entityChangeKey = (connectionId, environmentId, entityType) => ['entity_change', connectionId, environmentId || 'all', entityType].join(':');
  
  const onEntityChange = (connectionId, environmentId, entityType, entityId, handler = null) => {
    if (handler) {
      return onEntityChange(connectionId, environmentId, entityType, entityId)(handler);
    }
    const handlerFunc = onEvent(entityChangeKey(connectionId, environmentId, entityType));
    if (!entityId) {
      return handlerFunc;
    }
    return function(handle) {
      return handlerFunc(function(eId) {
        if (entityId === eId) {
          handle(eId);
        }
      });
    };
  };
  
  const postEntityChange = (connectionId, environmentId, entityType, entityId) => {
    const key = entityChangeKey(connectionId, environmentId, entityType);
    postEvent(key, entityId);
  }

  const systemActivityKey = (code) => `system_activity:${code}`;

  const onSystemActivity = (codes = [], currentOrganization = true, handler = null) => {
    if (handler) {
      return onEntityChange(codes, currentOrganization)(handler);
    }
    if (!Array.isArray(codes)) {
      codes = [codes];
    }
    const store = useStore();
    const shouldShowEvent = (event) => {
      const selectedOrgId = store.getters.selectedOrganization.id;
      const eventOrgId = event.organizationId || event.userOrganizationId;
      return (selectedOrgId === eventOrgId || !currentOrganization);
    };
    return function(handle) {
      codes.forEach(c => onEvent(systemActivityKey(c))((event) => {
        if (shouldShowEvent(event)) {
          handle(event);
        }
      }));
    };
  };

  const postSystemActivity = (code, data) => {
    postEvent(systemActivityKey(code), data);
  };

  return {
    entityChangeKey,
    onEntityChange,
    postEntityChange,
    systemActivityKey,
    postSystemActivity,
    onSystemActivity,
  };
}