import { CrudApi } from './api';

class UserApi extends CrudApi {
  constructor() {
    super('users');
  }

  updateLocale(lang) {
    if (this.$store.getters.loggedIn) {
      return this.post(`users/current/locale/${lang}`).then(resp => resp.status === 200);
    }
    return Promise.resolve(false);
  }

  forgotOrganization(email) {
    return this.post('/users/forgotorganization', { body: email });
  }

  forgotPassword(email) {
    return this.post('/users/forgotpassword', { body: email });
  }

  resetPassword(token, newPassword) {
    return this.post('/users/resetpassword', { qs: { resetToken: token }, body: newPassword });
  }

  getPasswordPolicy(token) {
    return this.get('users/passwordpolicy', { qs: { resetToken: token } });
  }

  getIsResetTokenValid(token) {
    return this.get('/users/isresettokenvalid', { qs: { resetToken: token } });
  }

  changePassword(oldPassword, newPassword) {
    return this.post(`users/${this.$store.getters.userId}/password`, { body: { old: oldPassword, new: newPassword } });
  }

  scoped(search) {
    return this.get('/users/scoped', {
      qs: {
        search,
      },
    });
  }

  find(userId) {
    return super.find(userId);
  }

  listRoleBindings(userId, scope) {
    return this.get(`users/${userId}/role_bindings`, { qs: { scope } });
  }

  update(user) {
    return super.update(user.id, user);
  }

  getVerificationToken(pass) {
    return this.post('/auth/login',
      { body: { id: this.$store.getters.userId, password: pass }, qs: { verify: true } });
  }

  createTfaSecret(verificationToken) {
    return this.post(`/users/${this.$store.getters.userId}/two_factor/key`, { headers: { 'MC-Access-Token': verificationToken } });
  }

  verifyTfaSetup(verificationToken, code) {
    return this.post(`/users/${this.$store.getters.userId}/two_factor/key/confirm`, {
      headers: { 'MC-Access-Token': verificationToken },
      body: { verificationCode: code },
    });
  }

  generateTfaBackupCodes(verificationToken) {
    return this.post(`/users/${this.$store.getters.userId}/two_factor/backup_codes`, { headers: { 'MC-Access-Token': verificationToken } });
  }

  disableTfa(userId) {
    return this.post(`/users/${userId}/disableTwoFactor`);
  }

  disableTfaSelf(verificationToken) {
    return this.del(`/users/${this.$store.getters.userId}/two_factor`, { headers: { 'MC-Access-Token': verificationToken } });
  }

  resendActivationEmail(userId) {
    return this.post(`/users/${userId}/email/user_account_created`);
  }

  unlockUser(userId) {
    return this.post(`/users/${userId}/unlock`);
  }

  listAdditionalRoles(userId) {
    return this.get(`/users/${userId}/additional_roles`);
  }

  addAdditionalRole(userId, roleId, scopeQualifier, organizationId, tags) {
    return this.post(`/users/${userId}/additional_roles`, {
      body: {
        user: { id: userId },
        role: { id: roleId },
        scopeQualifier,
        organization: { id: organizationId },
        tags,
      },
    });
  }

  removeAdditionalRole(userId, roleBindingId) {
    return this.del(`/users/${userId}/additional_roles/${roleBindingId}`);
  }

  getEffectivePermissions(orgId) {
    return this.get('/users/me/effective_permissions', {
      qs: {
        organization_id: orgId,
      },
    })
      .then(j => j.data);
  }

  getUserPreference(userId, preferenceKey) {
    return this.get(`/users/${userId}/preferences/${preferenceKey}`);
  }

  updateUserPreference(userId, preferenceKey, values) {
    return this.post(`/users/${userId}/preferences/${preferenceKey}/${values}`);
  }

  getCustomFields(orgId) {
    return this.get(`/custom_fields/user/${orgId}`);
  }
}

const userApi = new UserApi();

export default userApi;
