<template>
  <form-row>
    <div class="logo-container">
      <form-row label="branding.compact_logo">
        <div class="description">
          {{ $t('branding.compact_logo_description') }}
        </div>
        <upload-logo
          ref="logo"
          :fileExists="squareLogoExists"
          :fileUploadEndpoint="fileUploadSquare"
          :thumbWidth="100"
          :thumbHeight="100"
          :maxSize="maxSize"
          :triggerUpload="uploadImages"
          :maxNumber="1"
          customLabel="branding.dropzone_message"
          :acceptedFileTypes="acceptedFileTypes"
          @addedFiles="addedLogoSquare"
        ></upload-logo>
      </form-row>
      <form-row label="branding.full_logo">
        <div class="description">
          {{ $t('branding.full_logo_description') }}
        </div>
        <upload-logo
          :fileUploadEndpoint="fileUploadEndpoint"
          :fileExists="logoExists"
          :thumbWidth="170"
          :triggerUpload="uploadImages"
          :thumbHeight="100"
          :maxSize="maxSize"
          :maxNumber="1"
          customLabel="branding.dropzone_message"
          :acceptedFileTypes="acceptedFileTypes"
          :files="logo"
          @addedFiles="addedLogo"
        ></upload-logo>
      </form-row>
    </div>
  </form-row>
</template>

<script>
export default {
  name: 'BrandingFormLogoRow',
  props: {
    images: {
      type: Object,
      required: false,
    },
    uploadImages: {
      type: Boolean,
      default: false,
    },
    fileUploadEndpoint: {
      type: String,
      require: true,
    },
    fileUploadSquare: {
      type: String,
      require: false,
    },
    // used to check if we should put a placeholder in the logo upload preview
    logoExists: {
      type: Boolean,
      require: false,
    },
    squareLogoExists: {
      type: Boolean,
      require: false,
    },
  },
  emits: ['addedLogo', 'addedLogoSquare'],
  data() {
    return {
      logo: this.images ? this.images.logo : '',
      logoSquare: this.images ? this.images.logoSquare : '',
    };
  },
  computed: {
    acceptedFileTypes() {
      return '.png,.jpg,.jpeg';
    },
    maxSize() {
      return 500 * 0.001;
    },
  },
  methods: {
    addedLogo(files) {
      this.$emit('addedLogo', files);
    },
    addedLogoSquare(files) {
      this.$emit('addedLogoSquare', files);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.logo-container {
  @include large-desktop{
    flex-direction: row;
    > .form-row {
      min-width: 450px;
      padding-right: 10px;
    }
  }
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  > .form-row {
    flex-direction: row;
    flex: 1;
  }
}

</style>
