const EMAIL_REGEX = '^[_.+\\-A-Za-z0-9]+([_.\\-A-Za-z0-9]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
const validPhoneNumberLength = 10;
const validEmail = email => email.match(EMAIL_REGEX);
const validPhone = phoneNumber => phoneNumber.replace(/\D/g, '').length >= validPhoneNumberLength;
const validName = name => (name.length > 1);
const validCompanyName = companyName => (companyName.length < 30 && companyName.length > 0);
const validBlurb = blurb => (blurb.length < 250);
const cleanString = (input) => {
  if (input) {
    return input.toLowerCase()
    // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/[-_]+$/g, '') // Remove '-' and '_' from the end of the string
      .replace(/^-+/, '') // Trim - from the start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
  return input;
};

const cleanTag = (input) => {
  if (input) {
    return input.toLowerCase()
    // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '-')       // Remove all non-word chars
      .replace(/[-_]+$/g, '')
      .replace(/^[-]+/, ''); // Remove '-' and '_' from the end of the string
  }
  return input;
};

const slugifyUrl = (text) => {
  if (text == null) {
    return text;
  }
  return text.toLowerCase()
    .replace(/(\s+|_+)/g, '-')
    .replace(/--+/g, '-');
};

const validators = {
  validEmail,
  validPhone,
  cleanString,
  validName,
  validCompanyName,
  validBlurb,
  cleanTag,
  slugifyUrl,
};
export default validators;
