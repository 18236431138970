<template>
  <form-row label="discounts">
    <template #titleRight>
      <div
        v-if="showCreateButton"
        class="push"
      >
        <app-link
          v-protected="toAddDiscount"
          :to="toAddDiscount"
        >
          <base-button
            :preventDefault="false"
            :rounded="true"
          >
            <base-icon icon="fa fa-plus-circle" />
            {{ $t('monetization.discounts.percentage.add') }}
          </base-button>
        </app-link>
      </div>
    </template>
    <base-list
      aria-labelledby="discounts"
      :aria-describedby="description"
      :list="discounts"
      :ariaRowCount="discounts.length"
    >
      <list-header>
        <list-column>{{ $t('discount') }}</list-column>
        <list-column>{{ $t('details') }}</list-column>
        <list-column v-if="multi">
          {{ $t('status') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.start_date') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.end_date') }}
        </list-column>
        <list-column v-if="multi">
          {{ $t('monetization.discounts.duration') }}
        </list-column>
      </list-header>
      <list-row
        v-for="discount in paginatedDiscounts"
        :key="discount.id"
      >
        <list-column allowWrapping>
          <div><span>{{ discount.name[locale] }}</span></div>
          <div
            v-for="(v, k) in findOrigins(discount)"
            :key="k"
          >
            <span class="description">{{ v }}</span>
          </div>
        </list-column>
        <list-column>
          <template v-if="discount.packageDiscount">
            <div><strong>{{ packageDiscount(discount).label }}</strong>: {{ packageDiscount(discount).amount }}%</div>
          </template>
          <template v-else-if="Object.keys(discount.discountedProducts).length !== 0">
            <div
              v-for="(v, k) in products(discount)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ v }}%</span>
            </div>
            <span v-if="hasMoreProducts(discount)">
              <strong>{{ $t('monetization.discounts.preview_extra', { number: extraProducts(discount) }) }}</strong>
            </span>
          </template>
          <template v-else-if="Object.keys(discount.discountedCategories).length !== 0">
            <div
              v-for="(v, k) in categories(discount)"
              :key="k"
            >
              <span><strong>{{ labels[k][locale] }}: </strong></span>
              <span>{{ v }}%</span>
            </div>
            <span v-if="hasMoreCategories(discount)">
              <strong>{{ $t('monetization.discounts.preview_extra', { number: extraCategories(discount) }) }}</strong>
            </span>
          </template>
        </list-column>
        <list-column v-if="multi">
          <base-badge
            :state="getStatus(discount).label"
            :color="getStatus(discount).color"
          />
        </list-column>
        <list-column v-if="multi">
          {{ discountStartDate(discount) }}
        </list-column>
        <list-column v-if="multi">
          {{ discountEndDate(discount) }}
        </list-column>
        <list-column v-if="multi">
          {{ durationFormatting(discount) }}
        </list-column>

        <template #actions>
          <action-icon
            v-if="discount.status !== 'ENDED' && !discount.isDeactivated"
            :to="{ name: 'edit-organization-discount', params: { id: organization.id, discountId: discount.id } }"
            tooltipLabel="edit"
            icon="fa fa-edit"
            expandOnHover
          ></action-icon>
          <DiscountActions
            :discount="discount"
            :organizationId="organization.id"
            :locale="locale"
            :credit="credit"
            @deleted="emitUpdateDiscounts"
          />
        </template>
      </list-row>
      <pagination-component
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :pageNumber="pageNumber"
        @pageSelected="pageNumber = $event"
      />
      <template #empty>
        <empty-message
          icon="fa fa-money"
          textLabel="monetization.no_discounts"
        />
      </template>
    </base-list>
  </form-row>
</template>
<script>

import authz from '@/authz';
import { mapGetters } from 'vuex';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { currencyMixin } from '@/mixins/currencyMixin';
import { discountMixin } from '@/mixins/discountMixin';

import PaginationComponent from '@/components/list/PaginationComponent';
import DiscountActions from '@/app/Main/Administration/monetization/pricingPackage/DiscountActions.vue';

const MAX_SIZE = 5;
const PAGE_SIZE = 3;

export default {
  name: 'DiscountList',
  components: { PaginationComponent, DiscountActions },
  mixins: [billingCycleMixin, currencyMixin, discountMixin],
  props: {
    discounts: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
    },
    // true if discounts span multiple billing cycles
    multi: {
      type: Boolean,
      default: false,
    },
    disableCreate: {
      type: Boolean,
      default: false,
    },
    credit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateDiscountList'],
  data() {
    return {
      pageNumber: 1,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    totalPages() {
      return Math.ceil(this.discounts.length / PAGE_SIZE);
    },
    paginatedDiscounts() {
      return this.discounts.slice(
        (this.pageNumber - 1) * PAGE_SIZE,
        this.pageNumber * PAGE_SIZE);
    },
    toAddDiscount() {
      return `/admin/organizations/${this.organization.id}/billing/overview/discounts/add`;
    },
    description() {
      const suffix = !this.multi ? 'discounts_for_cycle_desc' : 'discounts_for_org_desc';
      return `monetization.${suffix}`;
    },
    labels() {
      return this.discounts.reduce((acc, c) => ({ ...acc, ...c.discountedLabels }), {});
    },
    showCreateButton() {
      return authz.hasPermission('reseller:organizationBilling') && !this.disableCreate;
    },
  },
  methods: {
    products(discount) {
      const ids = Object.keys(discount.discountedProducts).slice(0, MAX_SIZE);
      return ids.reduce((acc, k) => ({
        ...acc,
        [k]: discount.discountedProducts[k],
      }), {});
    },
    categories(discount) {
      const ids = Object.keys(discount.discountedCategories).slice(0, MAX_SIZE);
      return ids.reduce((acc, k) => ({
        ...acc,
        [k]: discount.discountedCategories[k],
      }), {});
    },
    hasMoreProducts(discount) {
      return Object.keys(discount.discountedProducts).length > MAX_SIZE;
    },
    extraProducts(discount) {
      return Object.keys(discount.discountedProducts).length - MAX_SIZE;
    },
    hasMoreCategories(discount) {
      return Object.keys(discount.discountedCategories).length > MAX_SIZE;
    },
    extraCategories(discount) {
      return Object.keys(discount.discountedCategories).length - MAX_SIZE;
    },
    emitUpdateDiscounts() {
      this.$emit('updateDiscountList');
    }
  },
};
</script>

<style scoped lang="scss">
.push {
  margin-left: auto;
  padding: 20px 20px 10px 0px;
}

:deep(.label-row) {
  max-width: 100% !important;
}
</style>
