import ClickOutside from './ClickOutside';
import Focus from './Focus';
import Protected from './Protected';
import Slugify from './Slugify';
import Permission from './Permission';

export default {
  install(app) {
    [
      ClickOutside,
      Focus,
      Protected,
      Permission,
      Slugify,
    ].forEach((dir) => {
      app.directive(dir.name, dir);
    });
  },
};
