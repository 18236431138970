const getParentDomain = () => {
  const full = window.location.host.split('.');
  return full.slice(1).join('.');
};

const goTo = (entryPoint) => {
  const parentDomain = getParentDomain();

  let newLocation = `${window.location.protocol}//${[entryPoint, parentDomain].join('.')}`;
  if (window.location.pathname) {
    newLocation += window.location.pathname;
  }
  if (window.location.search) {
    newLocation += window.location.search;
  }
  window.location = newLocation;
};

function browserDetection() {
  if (navigator.userAgent.search('Edge') >= 0) {
    return 'EDGE';
  }
  if (navigator.userAgent.search('MSIE') >= 0) { // Check if browser is IE
    return 'IE';
  } else if (navigator.userAgent.search('Chrome') >= 0) { // Check if browser is Chrome
    return 'CHROME';
  } else if (navigator.userAgent.search('Firefox') >= 0) { // Check if browser is Firefox
    return 'FIREFOX';
  } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) { // Check if browser is Safari
    return 'SAFARI';
  } else if (navigator.userAgent.search('Opera') >= 0) { // Check if browser is Opera
    return 'OPERA';
  }
  return null;
}

function isFirefox() {
  return browserDetection() === 'FIREFOX';
}

const helpers = {
  getParentDomain,
  goTo,
  browserDetection,
  isFirefox,
};

export default helpers;
