<template>
  <span></span>
</template>

<script>
import apis from '@/utils/apis';
import { localeCompare } from '@/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'BaseOrgPicker',
  data() {
    return {
      allOrganizations: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    topLevelOrganizations() {
      const orgIds = new Set(this.allOrganizations.map(o => o.id));
      return this.allOrganizations.filter(o => !o.parent || !orgIds.has(o.parent.id));
    },
  },
  async created() {
    const resp = await apis.organizations.list({qs: {detailed: false}});
    const orgs = resp.data;
    orgs.forEach((o) => {
      o.lineage = o.lineage.split(',');
      o.suborgs = [];
    });
    this.allOrganizations = this.buildOrganizationTree(orgs);
    this.loading = false;
  },
  methods: {
    buildOrganizationTree(orgs) {
      const orgTree = [...orgs].sort(localeCompare(o => o.name));
      const idToOrg = orgTree.reduce((acc, o) => {
        acc[o.id] = o;
        return acc;
      }, {});
      orgTree
        .sort((o1, o2) => o1.lineage.length - o2.lineage.length)
        .forEach((o) => {
          const parents = [...o.lineage].reverse()
            .filter(oId => oId !== o.id)
            .map(oId => idToOrg[oId])
            .filter(p => p);
          if (parents.length > 0) {
            o.parent = parents[0];
            parents[0].suborgs.push(o);
          }
          o.orgPath = parents.map(p => p.name).reverse();
          o.orgPath.push(o.name);
        });
      return orgTree;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
