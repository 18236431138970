<template>
  <div>
    <base-select 
      v-if="!loading"
      :modelValue="selectValue" 
      :items="selectOptions"
      :disabled="disabled"
      :error="selectFieldError"
      @update:modelValue="selectChange"
    />
    <form-row 
      v-if="displayCaptureKeyName"
      :label="newKeyNameLabel"
      :error="nameFieldErrors"
    > 
      <base-input
        v-model:modelValue="nameValue"
        :error="!!nameFieldErrors"
        :disabled="disabled"
        @update:modelValue="sshKeyChange" 
      />
    </form-row>
    <form-row 
      v-if="displayCaptureKey"
      :label="newKeyValueLabel"
      :error="keyFieldErrors"
    >
      <base-text-area
        v-model:modelValue="keyValue"
        :rows="2"
        :error="!!keyFieldErrors"
        :disabled="disabled"
        @update:modelValue="sshKeyChange" 
      />
    </form-row>
    <form-row 
      v-if="keyFieldIsList" 
      :label="otherKeyLabel"
      :error="keyFieldErrors"
    >
      <base-text-area
        v-model:modelValue="otherValues"
        :error="!!keyFieldErrors"
        :disabled="disabled"
        :placeholder="otherKeyPlaceholder"
        @update:modelValue="sshKeyChange" 
      />
    </form-row>
  </div>
</template>
<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';

const SERVICE_PREFIX = 'service#'
const PROFILE_PREFIX = 'profile#'
const NONE_OPTION = 'none';
const NEW_OPTION = 'new';

export default {
    name: "SSHKeyInput",
    props: {
        modelValue: {
            type: Object,
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        formElement: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => { },
        }
    },
    emits: ["update:modelValue"],
    data() {
        return {
            loading: true,  
            selectValue: undefined,
            nameValue: undefined,
            keyValue: undefined,
            profileKeys: [],
            otherValues: undefined,
        };
    },
    computed: {
        newKeyValueLabel() {
            return this.formElement?.newKeyValueLabel || "ssh_keys.new_key_value";
        },
        newKeyNameLabel() {
            return this.formElement?.newKeyNameLabel || "ssh_keys.new_key_name";
        },
        otherKeyLabel() {
            return this.formElement?.otherKeyLabel || "ssh_keys.other_values";
        },
        otherKeyPlaceholder() {
            return this.formElement?.otherKeyPlaceholder || "";
        },
        keyFieldIsList() {
            return this.formElement?.keyFieldIsList;
        },
        valueSeparator() {
            return this.formElement?.valueSeparator || "CSV";
        },
        isNativeList() {
            return this.formElement?.isNativeList;
        },
        keyValueField() {
            return this.formElement?.field;
        },
        keyNameField() {
            return this.formElement?.keyNameField;
        },
        displayCaptureKey() {
            return this.selectValue === NEW_OPTION;
        },
        captureKeyName() {
            return this.formElement?.captureKeyName;
        },
        displayCaptureKeyName() {
            return this.captureKeyName &&
                (this.displayCaptureKey || this.isProfileSelected(this.selectValue));
        },
        isRequired() {
            return this.formElement?.required;
        },
        allowNewKey() {
            return this.formElement?.captureNewKey;
        },
        serviceKeys() {
            return this.formElement?.serviceKeys || [];
        },
        disableProfileKeys() {
            return !!this.formElement?.disableProfileKeys;
        },
        onlyNameForServiceKeys() {
            return !!this.formElement?.onlyNameForServiceKeys;
        },
        filteredProfileKeys() {
            const keyFilters = this.formElement?.supportedKeyTypes;
            if (keyFilters && keyFilters.length !== 0) {
                return this.getProfileOptionValues(this.profileKeys.filter(key => (keyFilters.includes(this.getProfileEncodingValue(key)) || keyFilters.includes(key.sshKeyType))));
            }
            return this.getProfileOptionValues(this.profileKeys);
        },
        selectOptions() {
            let options = [];
            if (!this.isRequired) {
                options.push({ value: NONE_OPTION, label: "ssh_keys.none" });
            }
            if (this.allowNewKey) {
                options.push({ value: NEW_OPTION, label: "ssh_keys.new_key" });
            }
            if (!this.disableProfileKeys && this.filteredProfileKeys) {
                options = [...options, ...this.filteredProfileKeys];
              }
            if (this.serviceKeys) {
                this.serviceKeys.forEach(key => options.push({ value: SERVICE_PREFIX + key.name, display: key.name, group: "ssh_keys.service_keys" }));
            }
            return options;
        },
        separator() {
            return this.valueSeparator === "NEW_LINE" ? "\n" : ",";
        },
        nameFieldErrors() {
            if (!this.keyNameField || !this.captureKeyName || !this.errors) {
                return undefined;
            }
            return this.getErrorLabelValue(this.errors[this.keyNameField]);
        },
        keyFieldErrors() {
            if (!this.keyValueField || !this.errors) {
                return undefined;
            }
            return this.getErrorLabelValue(this.errors[this.keyValueField]);
        },
        valueFieldErrorSelectKeys() {
            return (!this.displayCaptureKey 
                        && !this.keyFieldIsList 
                        && !!this.errors && this.errors[this.keyValueField]);
        },
        nameFieldErrorSelectKeys() {
            return (!this.displayCaptureKeyName && !!this.errors && this.errors[this.keyNameField]);
        },
        selectFieldError() {
            if (this.nameFieldErrorSelectKeys) {
                return this.getErrorLabelValue(this.errors[this.keyNameField]);
            } else if (this.valueFieldErrorSelectKeys) {
                return this.getErrorLabelValue(this.errors[this.keyValueField]);
            }
            return false;
        },
    },
    async created() {
        await this.loadProfileKeys();
        await this.loadWithProvidedValues();
        this.loading = false;
    },
    methods: {
        getProfileOptionValues(arrayValues){
            return arrayValues.map(key => ({ value: PROFILE_PREFIX + key.sshKeyName, display: key.sshKeyName, group: "ssh_keys.profile_keys" }));
        },
        getProfileEncodingValue(key) {
            let value = key.sshKeyType;
            if (key.sshKeyLength) {
                value += '_' + key.sshKeyLength;
            }
            return value;
        },
        async loadProfileKeys() {
            const qs = { withKeyValue: true };
            const resp = await apis.sshkeys.list({ qs });
            if (resp?.ok) {
                this.profileKeys = resp.data;
            }
            else {
                notify.error(this.$t("ssh_keys.cannot_fetch_profile_keys"));
            }
        },
        async loadWithProvidedValues() {
            if (!!this.keyNameField && !this.keyFieldIsList && this.modelValue[this.keyNameField]) {
                this.nameValue = this.modelValue[this.keyNameField];
                this.loadSelectValueFromNameField();
            }
            if (!!this.keyValueField && this.modelValue[this.keyValueField]) {
                if (this.keyFieldIsList) {
                    this.updateKeyValuesFromArray();
                } else {
                  this.keyValue = this.modelValue[this.keyValueField];
                }
                this.loadSelectValueFromKeyField();
              }
        },
        loadSelectValueFromNameField(){
            if (this.serviceKeys) {
                const matchKey = this.serviceKeys.find(key => key.name === this.nameValue);
                if (matchKey) {
                    this.selectValue = SERVICE_PREFIX + this.nameValue;
                }
            }
            if (!this.selectValue && this.captureNewKey) {
                this.selectValue = NEW_OPTION;
            }
        },
        loadSelectValueFromKeyField() {
            if (this.serviceKeys) {
                let matchKey = this.serviceKeys.find(key => key.value === this.keyValue);
                this.updateWithMatchSelectValue(matchKey, SERVICE_PREFIX);
            }
            if (this.profileKeys) {
                let matchKey = this.profileKeys.find(key => key.sshKeyValue === this.keyValue);
                this.updateWithMatchSelectValue(matchKey, PROFILE_PREFIX);
            }
            if (!this.selectValue && this.keyValue){
                this.selectValue = NEW_OPTION;
            }
        },
        updateKeyValuesFromArray(){
            let rawValue = this.modelValue[this.keyValueField];
            let arrayValues;
            if (!Array.isArray(rawValue)) {
                arrayValues = (rawValue !== "") ? rawValue.split(this.separator) : [];
            } else  {
                arrayValues = [...rawValue];
            }
            if (arrayValues && arrayValues.length !== 0){
                this.keyValue = arrayValues.shift();
            }
            if (arrayValues && arrayValues.length !== 0){
                this.otherValues = arrayValues.join(this.separator);
            } else if(!this.isKeyPresentInSelectValues()) {
                this.otherValues = this.keyValue;
                this.keyValue = '';
            }
        },
        isKeyPresentInSelectValues() {
            let matchKey;
            if (this.serviceKeys && this.keyValue) {
                matchKey = this.serviceKeys.find(key => key.value === this.keyValue);
            }
            if (this.profileKeys && this.keyValue) {
                matchKey = this.profileKeys.find(key => key.sshKeyValue === this.keyValue);
            }
            return !!matchKey;
        },
        updateWithMatchSelectValue(matchKey,prefix){
            if (matchKey) {
                if (this.selectValue && this.captureNewKey && this.selectValue !== prefix + this.keyName) {
                    this.selectValue = NEW_OPTION;
                } else if (this.keyName) {
                    this.selectValue = prefix + this.keyName;
                } else if (matchKey.sshKeyName) {
                    this.selectValue = prefix + matchKey.sshKeyName;
                }
            }
        },
        getErrorLabelValue(error) {
            if (!error) {
                return undefined;
            }
            return this.$t(error[0].context.labelKey, (error[0].context || {}));
        },
        isProfileSelected(value) {
            return value?.startsWith(PROFILE_PREFIX);
        },
        isServiceSelected(value) {
            return value?.startsWith(SERVICE_PREFIX);
        },
        selectChange(newValue) {
            if (this.selectValue === newValue){
              return;
            }
            this.selectValue = newValue;
            if (newValue === NONE_OPTION || newValue === NEW_OPTION) {
                this.selectChangeNoneOrNew();
            }
            if (this.isServiceSelected(newValue)) {
                this.selectChangeServiceSSHKey(newValue);
            }
            if (this.isProfileSelected(newValue)) {
                this.selectChangeProfileSSHKey(newValue);
            }
            this.sshKeyChange();
        },
        selectChangeNoneOrNew(){
            if (this.keyNameField) {
                this.nameValue = undefined;
            }
            if (this.keyValueField) {
                this.keyValue = undefined;
            }
        },
        selectChangeServiceSSHKey(newValue){
            const matchingValue = this.serviceKeys.find(k => newValue.slice(SERVICE_PREFIX.length) === k.name);
            if (this.keyNameField) {
                this.nameValue = matchingValue?.name;
            } else {
                this.nameValue = undefined;
            }
            if (!this.onlyNameForServiceKeys && this.keyValueField) {
                this.keyValue = matchingValue?.value;
            } else {
                this.keyValue = undefined;
            }
        },
        selectChangeProfileSSHKey(newValue){
            const matchingValue = this.profileKeys.find(k => newValue.slice(PROFILE_PREFIX.length) === k.sshKeyName);
            if (this.keyNameField && this.displayCaptureKeyName) {
                this.nameValue = matchingValue?.sshKeyName;
            } else {
                this.nameValue = undefined;
            }
            if (this.keyValueField) {
                this.keyValue = matchingValue?.sshKeyValue;
            } else {
                this.keyValue = undefined;
            }
        },
        sshKeyChange() {
            if (this.keyFieldIsList) {
                const arrayValue = this.getArrayValue();
                this.$emit("update:modelValue", {
                    ...this.modelValue,
                    "ui-selectValue": this.selectValue,
                    [this.keyValueField]: arrayValue
                });
            }
            else {
                let newValueObject = {
                    ...this.modelValue,
                    "ui-selectValue": this.selectValue,
                };
                if (this.keyValueField) {
                    newValueObject[this.keyValueField] = this.keyValue;
                }
                if (this.keyNameField) {
                    newValueObject[this.keyNameField] = this.nameValue;
                }
                this.$emit("update:modelValue", newValueObject);
            }
        },
        getArrayValue(){
            let arrayValue = "";
            if (this.selectValue !== NONE_OPTION) {
                arrayValue = this.keyValue;
            }
            if (this.otherValues) {
                if (this.keyValue) {
                    arrayValue += this.separator;
                } 
                arrayValue += this.otherValues;
            }
            if (this.isNativeList) {
                arrayValue = (arrayValue !== "")? arrayValue.split(this.separator) : [];
            }
            return arrayValue;
        }
    },
};
</script>