import Api from './api';

class Scheduler extends Api {
  constructor() {
    super('scheduled_jobs');
  }

  jobs() {
    return this.get('scheduled_jobs');
  }

  job(id) {
    return this.get(`scheduled_jobs/${id}`);
  }

  invokeJob(id, instanceId) {
    return this.post(`scheduled_jobs/${id}/instances/${instanceId}/invoke`);
  }

  stopJob(id, instanceId) {
    return this.post(`scheduled_jobs/${id}/instances/${instanceId}/stop`);
  }
}

const scheduler = new Scheduler();
export default scheduler;
