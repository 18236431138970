<template>
  <OnboardingHeader
    id="enterprise-registration-header"
    :linkRegister="false"
    :noLogin="isValidToken"
  />
  <main class="registration-router-content">
    <div>
      <div class="registration-title">
        {{ $t('enterprise_registration.application_registration_title', { applicationName }) }}
      </div>
      <div class="registration-description">
        {{ $t('enterprise_registration.application_registration_description', { applicationName }) }}
      </div>
    </div>
    <div class="customer-onboarding-content">
      <router-view
        @isValidToken="getIsValidToken"
      />
    </div>
    <ContactUs
      class="contact-us"
      :contact="contact"
      :loginPage="false"
    />
  </main>
</template>

<script>

import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import OnboardingHeader from '../Onboarding/components/OnboardingHeader.vue';
import ContactUs from '../Login/components/ContactUs.vue';


export default {
    name: "EnterpriseRegistration",
    components: { OnboardingHeader, ContactUs },
    data() {
        return {
            contact: {},
            isValidToken: false,
        }
    },
    computed: {
        ...mapGetters([
            'selectedOrganization',
            'userBrandingInfo',
        ]),
        applicationName() {
            return this.userBrandingInfo.applicationName;
        },
    },
    async created() {
        const resp = await apis.hidden.getOrganizationInfo();
        if (resp && resp.ok) {
            this.contact.phone = resp.contactUsPhone;
            this.contact.email = resp.contactUsEmail;
        }
    },
    methods: {
        getIsValidToken(value) {
            this.isValidToken = value;
        }
    }
}

</script>

<style lang="scss" scoped>
.registration-router-content {
    height: calc(100% - 60px);
    overflow-y: scroll;
}

.registration-title {
    text-align: center;
    font-size: 1.8rem;
    padding-top: 30px;
}

.registration-description {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 1.2rem;
}

.contact-us {
    display: flex;
    justify-content: center;
}

.customer-onboarding-content {
    display: flex;
    justify-content: center;
    padding: 30px 30px 0px 30px;
}
</style>