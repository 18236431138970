<template>
  <CreditCard 
    v-if="defaultProviderType === 'chaseCreditCard'"
    :fromRegistration="false" 
    isEdit 
    :organization="organization" 
    @cancel="cancel" 
    @completed="complete"
  />
  <RedirectingToStripe 
    v-else-if="defaultProviderType === 'stripe'"
    isEdit
  />
</template>
<script>
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import CreditCard from '@/app/Main/components/billing/CreditCard';
import RedirectingToStripe from '@/app/Onboarding/components/RedirectingToStripe.vue';

export default {
  name: 'EditCreditCard',
  components: { CreditCard, RedirectingToStripe },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      providerType: '',
    }
  },
  computed: {
    defaultProviderType() {
      return this.providerType;
    },
  },
  async created() {
    await this.fetchBillingProviderType();
  },
  methods: {
    async fetchBillingProviderType() {
      const resp = await apis.organizations.getBillingProviderType(this.organization.id);
      if (resp && resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return;
      }
      this.providerType = resp.data.providerType;
    },
    complete() {
      notify.success(this.$t('cc_edit.success'));
      this.$router.push({ name: 'org-billing-details' });
    },
    cancel() {
      this.$router.push({ name: 'org-billing-details' });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
