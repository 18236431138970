
export default class KeyListener {
  constructor() {
    this.listerners = [];
  }

  keydown(handler) {
    document.addEventListener('keydown', handler);
    this.listerners.push(handler);
  }

  esc(handler) {
    this.keydown((event) => {
      if (event.keyCode === 27) {
        handler(event);
      }
    });
  }

  clear() {
    this.listerners.forEach(h => document.removeEventListener('keydown', h));
  }
}
