<template>
  <form-row label="commitments">
    <template #titleRight>
      <div
        v-if="showCreateButton"
        class="push"
      >
        <app-link
          v-protected="toAddCommitment"
          :to="toAddCommitment"
        >
          <base-button
            :preventDefault="false"
            :rounded="true"
          >
            <base-icon icon="fa fa-plus-circle" /> {{ $t('monetization.commitments.operations.create.title') }}
          </base-button>
        </app-link>
      </div>
    </template>
    <base-list
      aria-labelledby="commitments"
      :aria-describedby="description"
      :ariaRowCount="commitments.length"
      :list="commitments"
    >
      <list-header>
        <list-column>{{ $t('commitment') }}</list-column>
        <list-column>{{ $t('status') }}</list-column>
        <list-column>{{ $t('monetization.start_date') }}</list-column>
        <list-column>{{ $t('monetization.end_date') }}</list-column>
        <list-column v-if="isGlobalView">
          {{ $t('organization') }}
        </list-column>
        <list-column v-if="hasServiceConnectionScoped">
          {{ $t('service_connection') }}
        </list-column>
        <list-column v-if="hasEnvironmentScoped">
          {{ $t('environment') }}
        </list-column>
      </list-header>
      <list-row
        v-for="commitment in paginatedCommitments"
        :key="commitment.id"
        :to="toCommitmentDetails(commitment)"
      >
        <list-column>
          <div>
            {{ commitment.name }}
            <base-tooltip
              v-if="hasTaxProvider && commitment.pricingMethod === 'FIXED_PRICE' && !commitment.fixedPriceTaxCode"
              class="warning-tooltip"
              direction="top"
              :overflow="true"
              @click.prevent
            >
              <base-icon icon="fa fa-warning yellow" />
              <template #popover>
                <div>{{ $t('monetization.commitments.fixed_price.tax_code_warning') }}</div>
              </template>
            </base-tooltip>
          </div>
          <div class="description">
            {{ ctype(commitment) }}
          </div>
        </list-column>
        <list-column>
          <base-badge
            :state="getCommitmentState(commitment)"
            :color="getCommitmentColor(commitment)"
          />
        </list-column>
        <list-column>{{ startDate(commitment) }}</list-column>
        <list-column>{{ endDate(commitment) }}</list-column>
        <list-column v-if="isGlobalView">
          {{ commitment.organization.name }}
        </list-column>
        <list-column v-if="hasServiceConnectionScoped">
          {{ commitment.serviceConnection?.name }}
        </list-column>
        <list-column v-if="hasEnvironmentScoped">
          {{ commitment.environment?.name }}
        </list-column>
        <template #actions>
          <commitment-actions
            :commitment="commitment"
            :organization="commitment.organization"
            @clicked="actionsOpen = true"
            @close="actionsOpen = false"
          >
          </commitment-actions>
        </template>
      </list-row>
      <pagination-component
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :pageNumber="pageNumber"
        @pageSelected="pageNumber = $event"
      />
      <template #empty>
        <empty-message
          icon="fa fa-money"
          textLabel="monetization.commitments.no_commitments"
        />
      </template>
    </base-list>
  </form-row>
</template>

<script>
import { mapGetters } from 'vuex';
import authz from '@/authz';
import PaginationComponent from '@/components/list/PaginationComponent';
import { commitmentMixin } from '@/mixins/commitmentMixin';
import { sortMultiBy } from '@/utils/sort';
import {sortBy} from '@/utils';
import CommitmentActions from './CommitmentActions';


export default {
  name: 'CommitmentList',
  components: { PaginationComponent, CommitmentActions },
  mixins: [ commitmentMixin ],
  props: {
    commitments: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
    },
    disableCreate: {
      type: Boolean,
      default: false,
    },
    billingCycles: {
      type: Array,
      required: false,
    },
    hasTaxProvider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageNumber: 1,
      description: 'monetization.commitments.list_desc',
      actionsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    totalPages() {
      return Math.ceil(this.sortedCommitments.length / this.pageSize);
    },
    sortedCommitments() {
      function getEndDate(endDate) {
        return endDate || "9999-12-31";
      }
      return sortMultiBy(this.filteredCommitments, [
        (a,b) => a.organization.name.localeCompare(b.organization.name),
        (a,b) => b.startDate.localeCompare(a.startDate), // reverse
        (a,b) => getEndDate(b.endDate).localeCompare(getEndDate(a.endDate)), // reverse
        (a,b) => a.name.localeCompare(b.name)
      ]);
    },
    filteredCommitments() {
      return !this.isGlobalView ? this.commitments : this.commitments.filter(c => c.status !== "EXPIRED");
    },
    paginatedCommitments() {
      return this.sortedCommitments.slice(
        (this.pageNumber - 1) * this.pageSize,
        this.pageNumber * this.pageSize
      );
    },
    showCreateButton() {
      // in the global commitment list, the button is shown elsewhere
      return authz.hasPermission('reseller:organizationBilling') && !this.disableCreate && !this.isGlobalView;
    },
    toAddCommitment() {
      if (this.isGlobalView) {
        return {
          name: `/admin/monetization/commitments/add`,
          params: {
            organizationId: this.organization.id,
          },
        };
      }
      return `/admin/organizations/${this.organization.id}/billing/overview/commitments/add`;
    },
    pageSize() {
      return this.isGlobalView ? 10 : 3;
    },
    isGlobalView() {
      return !this.organization;
    },
    hasServiceConnectionScoped() {
      return (this.commitments || []).some(commitment => commitment.serviceConnection);
    },
    hasEnvironmentScoped() {
      return (this.commitments || []).some(commitment => commitment.environment);
    },
    commitmentIdToBillingCycles() {
      return this.getCommitmentIdToBillingCycles(this.billingCycles);
    }
  },
  methods: {
    toCommitmentDetails(commitment) {
      if (this.isGlobalView) {
        return {
          name: "global-commitment-details",
          params: {
            id: commitment.id,
          },
        };
      }
      return {
        name: "commitment-details-wrapper",
        params: {
          commitmentId: commitment.id,
          cycleId: this.returnMatchingCycle(commitment),
        },
      };
    },
    returnMatchingCycle(commitment) {
      const billingCyclesForOrg = this.billingCycles
        .filter(bc => bc.billableOrganizationInfo.organization.id === commitment.organization.id)
        .sort(sortBy(c => c.startDate));
      const cycles = this.commitmentIdToBillingCycles[commitment.id] || [billingCyclesForOrg[billingCyclesForOrg.length - 1]];
      const cyclesForCommitment = [...cycles].sort(sortBy(c => c.startDate));
      const inProgressCycleForCommitment = cyclesForCommitment.find(bc => bc.state === "IN_PROGRESS");
      const cycle = inProgressCycleForCommitment || cyclesForCommitment[cyclesForCommitment.length - 1];
      return cycle && cycle.id ? cycle.id : '';
    },
    ctype(commitment) {
      return this.$t(`monetization.commitments.types.${commitment.type.toLowerCase()}`);
    },
  }
};
</script>

<style scoped lang="scss">
.push {
  margin-left: auto;
  padding: 20px 20px 10px 0px;
}

:deep(.label-row) {
  max-width: 100% !important;
}

.warning-tooltip {
  display: inline-block;
}
</style>
