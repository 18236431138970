<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form-element">
    <base-layout 
      v-if="formElement.type === 'row'"
      class="element" 
      :modelValue="modelValue" 
      :children="formElement.formElements" 
      :errors="error" 
      :imageResolver="imageResolver"
      :disabled="disabled"
      @update:modelValue="inputChange" 
      @reload="$emit('reload', $event)" 
      @change="$emit('change', $event)" 
    />
    <form-row 
      v-else 
      :label="elemLabel" 
      :tooltip="formElement.tooltip"
      :disabled="disabled"
      :optional="!formElement.required || formElement.optional" 
      :descriptionLabel="elemDescLabel"
      :interpolation="formElement.interpolation" 
      :addPadding="addPadding"
    >
      <template 
        v-if="repeatAdd"
        #titleRight 
      >
        <base-button 
          v-if="!disabled && !formElement.hideAdd" 
          :disabled="formElement.preventAdd" 
          class="addButton"
          @click="triggerAddEnumeration()" 
        >
          <base-icon icon="fa fa-plus" /> <span>{{ $t('add') }}</span>
        </base-button>
      </template>
      <slot>
        <div 
          ref="element" 
          class="element"
        > 
          <cmc-sensitive-text-input
            v-if="isInput && formElement.sensitive"
            class="form-input"
            :obscure-initial-value="!formElement.viewable"
            :modelValue="modelValue"
            :placeholder="computedPlaceholderLabel"
            with-placeholder-i18n
            :disabled="formElement.disabled || disabled"
            @update:modelValue="inputChange"
          />
          <base-input 
            v-else-if="isInput"
            class="form-input" 
            :modelValue="modelValue" 
            :error="hasError" 
            :type="formElement.type" 
            :placeholder="computedPlaceholderLabel" 
            :prefix="formElement.prefixLabel" 
            :suffix="formElement.suffixLabel" 
            :disabled="formElement.disabled || disabled" 
            :decimal="decimal"
            @update:modelValue="inputChange"
          />
          <base-select 
            v-else-if="formElement.type === 'select'" 
            class="form-select" 
            :clearable="!formElement.required" 
            :placeholder="computedPlaceholderLabel" 
            :modelValue="modelValue === undefined || modelValue === null ? modelValue : modelValue.toString()" 
            :items="selectOptions" 
            :disabled="formElement.disabled || disabled" 
            @update:modelValue="inputChange"
          />
          <base-time-picker
            v-else-if="formElement.type === 'time-select'"
            elementType="time-select"
            :hideControls="false"
            :hideAmPm="false"
            :is24Hour="false"
            :intervalType="formElement.intervalType"
            :selectedPlaceholder="formElement.selectedPlaceHolderLabel"
            :modelValue="modelValue"
            @update:modelValue="timeChange"
          />
          <base-slider 
            v-else-if="isSlider" 
            :modelValue="modelValue" 
            :values="formElement.values" 
            :min="formElement.min" 
            :max="formElement.max" 
            :step="formElement.step" 
            :unitLabel="formElement.unitLabel" 
            @update:modelValue="inputChange" 
          />
          <base-checkboxes 
            v-else-if="formElement.type === 'checkboxes'" 
            :options="formElement.options" 
            :disabled="formElement.disabled || disabled" 
            :modelValue="modelValue" 
            @update:modelValue="inputChange"
          />
          <base-checkbox 
            v-else-if="formElement.type === 'checkbox'" 
            :modelValue="new String(modelValue).toLowerCase() === 'true'" 
            :description="formElement.descriptionLabel"  
            :disabled="formElement.disabled || disabled" 
            :label="$t(formElement.label)"
            @update:modelValue="inputChange" 
          />
          <radio-group 
            v-else-if="formElement.type === 'radio'" 
            :options="formElement.options" 
            :name="formElement.field" 
            :modelValue="radioValue" 
            @update:modelValue="inputChange"
          />
          <cmc-sensitive-text-area
            v-else-if="isTextarea && formElement.sensitive"
            :obscure-initial-value="!formElement.viewable"
            :model-value="modelValue"
            :with-placeholder="computedPlaceholderLabel"
            with-placeholder-i18n
            :disabled="formElement.disabled || disabled"
            @update:modelValue="inputChange"
          />
          <base-text-area 
            v-else-if="isTextarea" 
            :modelValue="modelValue" 
            :placeholder="computedPlaceholderLabel" 
            :rows="formElement.rows" 
            :error="hasError" 
            :disabled="formElement.disabled || disabled" 
            @update:modelValue="inputChange" 
          />
          <markdown-editor 
            v-else-if="formElement.type === 'markdownEditor' && formElement.viewOnlyMode === false" 
            :modelValue="modelValue" 
            @update:modelValue="inputChange"
          />
          <markdown-viewer 
            v-else-if="formElement.type === 'markdownEditor' && formElement.viewOnlyMode === true" 
            :modelValue="modelValue" 
            :boxed="true" 
          />
          <alert-box 
            v-else-if="formElement.type === 'message'" 
            :label="formElement.label" 
            :interpolation="formElement.interpolation" 
            :alertType="formElement.alertType" 
            :border="(!!formElement.alertType && formElement.alertType !== 'NONE')" 
            :viewType="formElement.viewType" 
            :headerLabel="formElement.headerLabel" 
            :headerInterpolation="formElement.headerInterpolation"
          />
          <tile-select 
            v-else-if="formElement.type === 'tiles'" 
            :modelValue="modelValue" 
            :options="formElement.options" 
            :imageResolver="imageResolver" 
            @update:modelValue="inputChange" 
          />
          <tag-select 
            v-else-if="formElement.type === 'tags'" 
            :modelValue="tagValue" 
            :error="hasError" 
            :disabled="formElement.disabled || disabled" 
            :placeholderLabel="computedPlaceholderLabel" 
            :options="formElement.options"
            @addTag="addTag" 
            @removeTag="removeTag" 
          />
          <code-editor 
            v-else-if="formElement.type === 'codeEditor'" 
            :language="formElement.language" 
            :modelValue="modelValue"
            :readOnly="formElement.disabled || disabled" 
            @update:modelValue="inputChange"
          />
          <repeatable-form-element 
            v-else-if="isRepeatable" 
            ref="enumeration" 
            :modelValue="modelValue" 
            :children="formElement.children" 
            :default="formElement.defaultFormElement" 
            :required="formElement.required"
            :disabled="formElement.disabled || disabled" 
            :emptyMessage="formElement.emptyMessageLabel" 
            :error="subFieldErrors" 
            :preventAdd="formElement.preventAdd" 
            :hideAdd="formElement.hideAdd" 
            :hideRemove="formElement.hideRemove" 
            :noBorder="formElement.noBorder"
            :reloadOnRemove="formElement.reloadOnRemove" 
            :reloadOnAdd="formElement.reloadOnAdd" 
            :sectionsToReloadOnAdd="formElement.sectionsToReloadOnAdd" 
            :sectionsToReloadOnRemove="formElement.sectionsToReloadOnRemove"
            :optional="!formElement.required"
            :field="formElement.field"
            @updateRepeatableError="updateRepeatableError" 
            @reload="$emit('reload', $event)" 
            @change="$emit('change', $event)" 
          />
          <collapsible-section 
            v-else-if="isComposite && formElement.collapsible"
            :title="$t(formElement.label)"
            :open="isOpen"
            :icons="['chevron-right', 'chevron-down']"
            boxed
            @toggle="isOpen = !isOpen"
          >
            <composite-form-element
              class="collapsible"
              :values="modelValue" 
              :elements="formElement.children" 
              :error="subFieldErrors" 
              :collapsible="formElement.collapsible" 
              :disabled="formElement.disabled || disabled"
              @updateRepeatableError="updateRepeatableError" 
              @reload="$emit('reload', $event)" 
              @change="$emit('change', $event)"
            />
          </collapsible-section> 
          <composite-form-element 
            v-else-if="isComposite" 
            :values="modelValue" 
            :elements="formElement.children" 
            :error="subFieldErrors" 
            :collapsible="formElement.collapsible" 
            :disabled="formElement.disabled || disabled"
            @updateRepeatableError="updateRepeatableError" 
            @reload="$emit('reload', $event)" 
            @change="$emit('change', $event)"
          />
          <tree-select 
            v-else-if="formElement.type === 'treeselect'" 
            :nodes="formElement.children" 
            :icon="formElement.icon" 
            :modelValue="modelValue" 
            @update:modelValue="inputChange"
          />
          <div 
            v-else-if="formElement.type === 'multi-select-checkbox'"
            class="multiselect-container"
          >
            <base-multi-select
              :items="multiSelectCheckBoxItems"
              :filter="multiSelectFilter"
              :disabled="disabled"
              :selectedPlaceholder="formElement.selectedPlaceholderLabel"
              :maxCharactersOnPreview="formElement.maxCharactersOnPreview"
              :maxCharactersOnCheckbox="formElement.maxCharactersOnCheckbox"
              :modelValue="modelValue === undefined || modelValue === null ? modelValue : multiSelectModelValue"
              @update:modelValue="multiSelectInputChange"
            />
          </div> 
          <SSHKeyInput
            v-else-if="formElement.type === 'sshKey'" 
            :modelValue="modelValue" 
            :formElement="formElement"
            :disabled="formElement.disabled || disabled" 
            :errors="error"
            @update:modelValue="inputChange"
          />
          <MultiselectCounterFormElement
            v-else-if="isMultiselectWithCount"
            :modelValue="modelValue"
            :formElement="formElement"
            :disabled="formElement.disabled || disabled"
            :errors="error"
            @update:modelValue="inputChange"
          />
          <div v-else>
            TODO: {{ formElement.type }}
          </div>
        </div>
        <div 
          ref="error" 
          :class="{'error-row': absoluteError}"
        >
          <div 
            v-if="showError" 
            class="error error-message red" 
            :class="{'error-absolute': absoluteError}"
          >
            <div 
              v-for="(err,index) in errorList"
              :key="err.context.fieldPath + index"
              v-html="formatError(err)"
            ></div>
          </div>
        </div>
      </slot>
    </form-row>
  </div>
</template>

<script>
import { compactLayout, hasPadding } from './repeatable';
import SSHKeyInput from '@/components/input/SSHKeyInput';
import MultiselectCounterFormElement from '../MultiselectCounterFormElement';

const DEBOUNCE_TIMEOUT = 800;

export default {
  name: 'FormElement',
  components: {SSHKeyInput, MultiselectCounterFormElement},
  props: {
    modelValue: {
      type: [Object, Date, String, Number, Boolean, Array],
      required: false,
    },
    formElement: {
      type: Object,
      required: true,
    },
    error: {
      type: [Array, Object],
    },
    imageResolver: {
      type: Function,
    },
    disabled: {
      type: Boolean,
    },
    absoluteError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change', 'reload', 'updateRepeatableError'],
  data() {
    return {
      debounce: null,
      isOpen: true,
      sliderFormatter: (v) => {
        if (this.formElement.unitLabel) {
          return `${v} ${this.$t(this.formElement.unitLabel)}`;
        }
        return v;
      },
    };
  },
  computed: {
    tagValue() {
      return (this.modelValue && typeof this.modelValue === 'string') ? this.modelValue.split(',') : this.modelValue || [];
    },
    radioValue() {
      let grpValue = this.modelValue;
      if (typeof this.modelValue === 'boolean') {
        grpValue = `${this.modelValue}`;
      }
      return grpValue || '';
    },
    selectOptions() {
      const optionConv = o => ({
        display: o.name,
        label: o.is18n ? o.name : null,
        value: o.value,
        group: o.group,
        interpolation: o.interpolation
      });
      return this.formElement.options.map((o) => {
        if (o.type === 'grouped') {
          const group = o.name;
          return o.options
            .map(optionConv)
            .map(oc => ({ ...oc, group }));
        }
        return [optionConv(o)];
      })
        .reduce((acc, g) => acc.concat(g), []);
    },
    hasError() {
      return !!this.error;
    },
    hasNestedError() {
      return this.hasError &&
        (this.isRepeatable || this.isComposite || this.isSshKey);
    },
    showError() {
      return this.formElement.field && this.error != null && !this.hasNestedError;
    },
    isRepeatable() {
      return this.formElement.type === 'repeatable';
    },
    isComposite() {
      return this.formElement.type === 'composite';
    },
    isSshKey() {
      return this.formElement.type === 'sshKey';
    },
    isSlider() {
      return this.formElement.type === 'slider';
    },
    isMultiselectWithCount() {
      return this.formElement.type === 'multi-select-count';
    },
    isInput() {
      return ['text', 'password', 'number'].includes(this.formElement.type);
    },
    decimal() {
      return this.formElement.type === 'number' ? this.formElement.decimal : true;
    },
    isTextarea() {
      return this.formElement.type === 'textarea';
    },
    addPadding() {
      return hasPadding(this.formElement.type, this.formElement.children);
    },
    repeatAdd() {
      if (this.formElement.type !== 'repeatable') {
        return false;
      }
      return (this.compactRepeatable &&
          (!this.formElement.children || this.formElement.children.length === 0))
        || !this.compactRepeatable;
    },
    compactRepeatable() {
      if (this.formElement.type !== 'repeatable') {
        return false;
      }
      const defaultFe = this.formElement.defaultFormElement;
      return compactLayout(defaultFe.type, defaultFe.children);
    },
    errorList() {
      if (!this.error || JSON.stringify(this.error) === '{}') {
        return [];
      }
      let errorArray;
      if (Array.isArray(this.error)) {
        errorArray = this.error;
      } else {
        errorArray = [this.error];
      }
      errorArray.forEach((e) => {
        if (e.context && !e.context.fieldPath) {
          e.context.fieldPath = Array.isArray(e.context.field) ? e.context.field.join('.') : e.context.field;
        }
      });

      return errorArray;
    },
    subFieldErrors() {
      // Get the list of errors associated to the element and strip the
      // field from the first element.
      // e.g. field: ['ports', '0'] will become field:['0'] since
      // the form element will know the field as '0'
      if (this.errorList.length === 0) {
        return undefined;
      }
      const subErrors = this.errorList.map((e) => {
        const newFieldValue = [...e.context.field];
        newFieldValue.splice(0, 1);
        return {
          ...e,
          context: {
            ...e.context,
            field: newFieldValue,
          },
        };
      });
      return subErrors;
    },
    elemLabel() {
      if (['message', 'checkbox', 'composite', 'multi-select-count'].includes(this.formElement.type)) {
        return '';
      }
      return this.formElement.label;
    },
    elemDescLabel() {
      if (this.formElement.type === 'checkbox' || this.formElement.type === 'multi-select-count') {
        return '';
      }
      return this.formElement.descriptionLabel;
    },
    computedPlaceholderLabel() {
      return !this.disabled ? this.formElement.placeholderLabel : '';
    },
    multiSelectCheckBoxItems() {
      return this.formElement.options.map(i => { 
        return(
            {
            ...i,
            label: i.is18n? i.name : '',
            display: !i.is18n? i.name : '',
          }
        ) 
     });
    },
    multiSelectFilter() {
      let modelValue = this.modelValue;
      if (typeof this.modelValue === 'string') { 
        modelValue = this.modelValue.split(',');
      }
      return {
        ...this.formElement.filter,
        hasSelectedValue: !!modelValue,
        values: modelValue ? this.multiSelectCheckBoxItems.filter(e => modelValue.some(m => m === e.value)) : []
      }
    },
  },
  mounted() {
    this.computeErrorMessageMaxWidth();
  },
  methods: {
    addTag(e) {
      this.inputChange([...(this.tagValue || []), e]);
    },
    removeTag(v) {
      this.inputChange([...this.tagValue.filter(t => t !== v)]);
    },
    doDebounce(func, timeout = DEBOUNCE_TIMEOUT) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        func();
        this.debounce = null;
      }, timeout);
    },
    shouldEmitReload(value) {
      // since the checkbox defaults to false, it would skip the first change to 'true'
      // checkboxes default to [], but this.modelValue is still undefined
      return (value !== undefined && value !== null)
        || this.formElement.type === 'checkbox' || this.formElement.type === 'checkboxes';
    },
    inputChange(v) {
      this.$emit('update:modelValue', v);
      this.$emit('change', v);

      if (this.formElement.reloadOnChange && this.shouldEmitReload(v)) {
        const emitChange = () => this.$emit('reload', { value: v, sections: this.formElement.sectionsToReload });
        if (this.isSlider || this.isInput || this.isTextarea) {
          this.doDebounce(emitChange);
        } else {
          emitChange();
        }
      }
    },
    timeChange(v) {
      let schedule = v.minutes + ":" + v.hours
      this.inputChange(schedule)
    },
    updateRepeatableError(setToRemove, setToAdd) {
      this.$emit('updateRepeatableError', setToRemove, setToAdd);
    },
    triggerAddEnumeration() {
      this.$refs.enumeration.addFormElement();
    },
    computeErrorMessageMaxWidth() {
      const element = this.$refs.element;
      if (element && element.children?.length) {
        const maxWidth = window.getComputedStyle(element.children[0]).getPropertyValue('max-width');
        const error = this.$refs.error;
        if (error) {
          error.style.maxWidth = maxWidth;
        }
      }
    },
    multiSelectModelValue(e) {
      const arr = [...e]
      return arr.reduce((a, curr) =>[...a, curr.value],[])
    },
    multiSelectInputChange(e) {
      const arr = [...e]
      const v = arr.reduce((a, curr) =>[...a, curr.value],[])
      this.inputChange(v);
    },
    formatError(err) {
      return this.$t(err.context.labelKey, err.context)?.replaceAll("\n", "<br>");
    }
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/variables.scss';
.form-element {
  flex: 1;
  .alert-box {
    margin-top: 25px;
  }
  .element {
    max-width: $max-form-width;
  }
}

h1, h3 {
  margin: 10px 0px;
}

h1, h2, h3, h4 {
  font-weight: 100;
  text-align: left;
}

.error-message {
  font-size: 0.8em;
  margin-top: 5px;
}

.base-slider {
  padding-top: 35px;
  padding-bottom: 15px;
}

.collapsible {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.label {
  margin-bottom: 10px;
}

.description {
  margin-top: 10px;
  font-size: 0.9em;
}

.base-checkbox {
  margin-top: 10px;
}

.description {
  color: var(--gray);
}

.optional {
  font-size: 0.8em;
  color: var(--gray);
}

.addButton {
  margin-bottom: 5px;
}

.multiselect-container {
  position: relative;
  height: 50px;
}
#base-multi-select {
  position: absolute;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  margin: inherit;
  border-color: var(--input-placeholder-text);
}
</style>
