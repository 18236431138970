<template>
  <div>
    <div 
      v-if="label" 
      class="label"
    >
      {{ $t(label) }}
    </div>
    <div 
      tabindex="0" 
      class="toggle clickable" 
      :class="{ active: modelValue, disabled }" 
      @click="toggle" 
      @keydown.space.prevent="toggle" 
      @keydown.enter.prevent="toggle"
    >
      <div class="ball"></div>
      <base-icon 
        id="icon" 
        :icon="executing ? 'spinner' :modelValue ? 'check' : 'times'" 
        :class="[executing? 'fa-spin':'']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    executing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  emits: ['click', 'update:modelValue'],
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue);
        this.$emit('click');
      }
    },
  },
};
</script>


<style scoped lang="scss">
$width: 50px;
$height: 25px;

.toggle {
    width: $width;
    height: $height;
    border-radius: $height;
    transform: background-color .2s;
    position: relative;
    #icon {
        text-align: center;
        position: absolute;
        right: 8px;
        top: calc($height / 2);
        width: 12px;
        font-size: 12px;
        transform: translateY(-50%);
        display: inline-block;
        &.fa-spin {
          top: calc($height / 4 + 1px);
        }
    }

    &.active {
        #icon {
            left: 8px;
        }

        .ball {
            transform: translateX(calc(50px - 100% - 1px));
        }
    }
    .ball {
        transition: transform .2s;
        transform: translateX(1px);
        height: $height;
        width: $height;
        &::before {
            content: "";
            display: inline-block;
            height: $height;
            width: $height;
            border-radius: 50%;
            transform: scale(.75);
        }
    }
}
</style>
