import izitoast from 'izitoast';
import notify from '@/utils/notify';

export function initializeNotificationWatcher() {
    setInterval(() => {
        while(notify.hasToast()) {
            let toast = notify.popToast();
            izitoast.show(toast);
        }
    }, 300);
}