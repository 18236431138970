import utils from '../httputils';
import { ReadOnlyApi } from './api';

class Invoices extends ReadOnlyApi {
  constructor() {
    super('invoices');
  }
  findInvoice(invoiceId) {
    return this.get(`/invoices/${invoiceId}`);
  }

  findCustomerInvoices(resellerId, billingCycle, includeAllSubOrgs, showObsoletedInvoices, includeNoUsageInvoices) {
    return this.get(`/invoices/find/${resellerId}/customer_invoices`, {
      qs: { billingCycle, includeAllSubOrgs, showObsoletedInvoices, includeNoUsageInvoices },
    });
  } 

  findOrganizationInvoices(organizationId, billingCycle, showObsoletedInvoices, includeNoUsageInvoices){
    return this.get('/invoices', {
      qs: { organization_id: organizationId, billingCycle, showObsoletedInvoices, includeNoUsageInvoices },
    });
  }

  approve(invoiceId) {
    return this.put(`/invoices/${invoiceId}/approve`);
  } 

  downloadInvoice(invoiceId, language, type) {
    if (!type || type === 'pdf') {
      return this.get('/invoices/download', {
        qs: { invoice_id: invoiceId, language },
        responseType: 'blob',
      });
    }
    return this.get(`/invoices/${invoiceId}`, {
      headers: { [utils.ACCEPT_HEADER]: utils.TEXT_CSV },
      responseType: 'blob',
      qs: { language },
    });
  }

  markAsPaid(invoiceId) {
    return this.put(`/invoices/${invoiceId}/mark_as_paid`);
  }
  
  markAsVoid(invoiceId, message) {
    return this.post(`/invoices/${invoiceId}/mark_as_void`, {
      body: JSON.stringify({ message }),
    })
  }

  flagInvoice(invoiceId, message) {
    return this.post(`/invoices/${invoiceId}/flag`, {
      body: JSON.stringify({ message }),
    })
  }
}

const invoices = new Invoices();

export default invoices;
