<template>
  <span 
    role="button" 
    class="clickable" 
    tabindex="0" 
    @click="$emit('click', $event)" 
    @keyup.enter="$emit('click', $event)"
  >
    {{ $t(label) }}
  </span>
</template>

<script>
export default {
  name: 'ActionText',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
};
</script>
