<template>
  <div class="message">
    <div class="icon-container">
      <base-icon 
        v-if="icon" 
        :icon="icon"
      />
    </div>
    <div class="text">
      {{ $t(textLabel || '') }}
    </div>
    <div class="detail">
      {{ $t(detail || '') }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseMessage',
  props: {
    icon: {
      type: String,
    },
    textLabel: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: false,
    },
  },
};
</script>


<style scoped lang="scss">
  .message {
    .icon-container {
      font-size: 50px;
      margin-top: 25px;
    }
    .text {
      font-size: 20px;
      margin-bottom: 5px;
    }
    .detail {
      margin-bottom: 16px;
    }
  }
</style>
