<template>
  <advanced-details titleLabel="quotas.assigned_quotas">
    <quotas-list 
      :organization="organization" 
      type="assigned" 
      noDetails
    ></quotas-list>
  </advanced-details>
</template>
<script>
import QuotasList from '@/app/Main/Administration/quotas/QuotasList';

export default {
  name: 'OrganizationAssignedQuotas',
  components: { QuotasList },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>
