import Api from './api';

class UsageSummaryApi extends Api {
  getSummary({ connectionId, startDate, endDate, organizationId, environmentId }) {
    if (!connectionId || !startDate || !endDate) {
      return Promise.reject();
    }
    return this.post(`/reports/service_usage/${connectionId}`, { body: { startDate, endDate }, qs: { organizationId, environmentId } });
  }
}
const usageSummary = new UsageSummaryApi();
export default usageSummary;
