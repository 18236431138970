<template>
  <div v-if="hasCustomSlot">
    <slot name="custom"></slot>
  </div>
  <div
    v-else
    class="row"
  >
    <section class="filter-tabs">
      <div class="adder">
        <base-tabs
          v-if="tabs.length > 1"
          :tabs="tabs"
          :selected="actualSelected"
          @tabChange="changeTab"
        />
        <div class="filter-controls">
          <div class="filter">
            <slot :selected="actualSelected"></slot>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { rawPill, getQueryFromPills } from '@/utils/filters';

export default {
  name: 'FilterTabs',
  components: { },
  props: {
    filterset: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    pillGenerators: {
      type: Object,
      required: true,
    },
    initialSelection: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['filter'],
  data() {
    return {
      selected: this.initialSelection,
    };
  },
  computed: {
    ...mapGetters([
      'filters',
    ]),
    actualSelected() {
      return this.selected || this.tabs[0].value;
    },
    currentFilters() {
      return this.filters[this.filterset];
    },
    filterQueryParams() {
      return getQueryFromPills(this.currentFilters || {});
    },
    hasCustomSlot() {
      return !!this.$slots.custom;
    },
  },
  watch: {
    async filterQueryParams(newFilter, oldFilter) {
      const queryMinusRemoved = Object.keys(this.$route.query).reduce((acc, key) => {
        const removedKey = Object.prototype.hasOwnProperty.call(oldFilter, key) &&
                          !Object.prototype.hasOwnProperty.call(newFilter, key);
        if (!removedKey) {
          acc[key] = this.$route.query[key];
        }
        return acc;
      }, {});

      await this.$router.replace({ query: { ...queryMinusRemoved, ...newFilter } });
      this.$emit('filter', newFilter);
    },
  },
  created() {
    this.restorePills();
  },
  methods: {
    ...mapActions([
      'removePill',
      'replacePill',
      'setPills',
    ]),
    getQueryData() {
      const query = this.$route.query;
      return Object.keys(query).reduce((acc, cur) => {
        const keys = cur.split('.');
        let inner = acc;
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          if (!inner[key]) {
            inner[key] = {};
          }
          if (i === keys.length - 1) {
            inner[key] = query[cur];
          } else {
            inner = inner[key];
          }
        }
        return acc;
      }, {});
    },
    removeFilter(key) {
      this.removePill({
        key,
        filterset: this.filterset,
      });
    },
    setFilter(key, value, label) {
      this.replacePill({
        filterset: this.filterset,
        pill: rawPill(key, value, label),
      });
    },
    setFilterPill(pill) {
      this.replacePill({
        filterset: this.filterset,
        pill,
      });
    },
    changeTab(n) {
      this.selected = n.value;
    },
    async restorePills() {
      if (this.$route.query) {
        const queryData = this.getQueryData();
        const pills = await Promise.all(Object.keys(queryData)
          .map(async (key) => {
            const value = queryData[key];
            if (!this.pillGenerators[key]) {
              return null;
            }
            const pill = await this.pillGenerators[key](value);
            if ((pill instanceof String) || (typeof pill ==  'string')) {
              return rawPill(key, value, pill);
            }
            return pill;
          }));
        this.setPills({
          filterset: this.filterset,
          pills: pills.filter(p => p),
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.filter-tabs {
  width: 100%;

  .filter-controls {
    padding: 20px 40px;
  }
}
.buttons {
  margin-top: 20px;
  text-align: right;
}
</style>
