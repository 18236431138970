<template>
  <advanced-details 
    v-if="organization" 
    :loading="loading" 
    titleLabel="oidc_auth"
  >
    <template 
      v-if="!permissionDenied"
      #operations 
    >
      <action-icon 
        v-show="!canEdit" 
        icon="fa fa-edit" 
        tooltipLabel="edit" 
        expandOnHover 
        clickable 
        @click="canEdit = true"
      ></action-icon>
    </template>
    <empty-message 
      v-if="permissionDenied" 
      icon="fa fa-exclamation-triangle" 
      textLabel="permission_denied"
    ></empty-message>
    <form-page 
      v-else 
      :disabled="submitDisabled" 
      :executing="executing" 
      :hideCancel="!canEdit" 
      :hideSubmit="!canEdit" 
      @submit="submit" 
      @cancel="cancel"
    >
      <form-row>
        <base-checkbox 
          v-model="allowAutoAccountCreation"
          :disabled="!canEdit || executing" 
          :value="allowAutoAccountCreation" 
          label="allow_auto_account_creation" 
        />
        <AutoAccountCreationConfiguration
          v-show="allowAutoAccountCreation"
          v-model="securitySettings.defaultRole"
          :disabled="!canEdit || primaryRoleDisabled || executing"
          :organization="organization"
          :domains="domains"
          :assignableRoles="assignableRoles"
          @updateDomains="updateDomains"
        />
      </form-row>
    </form-page>
  </advanced-details>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import errors from '@/utils/errors';
import AutoAccountCreationConfiguration from './AutoAccountCreationConfiguration';

export default {
  name: "AutoAccountCreationConfigView",
  components: {
    AutoAccountCreationConfiguration,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['refreshOrg'],  
  data() {
    return {
      loading: false,
      permissionDenied: false,
      canEdit: false,
      executing: false,
      allowAutoAccountCreation: false,
      originallyAllowAC: false,
      securitySettings: {
        verifiedDomains: [],
        defaultRole: {},
      },
      originalSecuritySettings: {
        verifiedDomains: [],
        defaultRole: {},
      },
      domains: [],
      originalDomains: [],
      assignableRoles: [],
    };
  },
  computed: {
    submitDisabled() {
      if (!this.allowAutoAccountCreation) {
        return this.allowAutoAccountCreation === this.originallyAllowAC;
      }
      return this.securitySettings.verifiedDomains.length === 0;
    },
    primaryRoleDisabled() {
      return !this.domains.some(d => d.status === 'VERIFIED');
    },
  },
  async created() {
    this.loading = true;
    await this.fetchAssignableRoles();
    await this.fetchOrgSecuritySettings();
    await this.fetchDomains();
    this.loading = false;
  },
  methods: {
    cancel() {
      this.canEdit = false;
      this.allowAutoAccountCreation = this.originallyAllowAC;
      this.securitySettings = {
        ...this.originalSecuritySettings,
        defaultRole: { ...this.originalSecuritySettings.defaultRole },
      };
      this.domains.forEach((_, index) => {
        this.domains.splice(index, 1, { ...this.originalDomains[index] });
      });
    },
    updateDomains() {
      this.securitySettings.verifiedDomains = this.domains.filter(d => d.checked === true);
    },
    updateRole(role) {
      this.securitySettings.defaultRole = role;
    },
    passwordPolicy() {
      return this.securitySettings.passwordPolicy.isParentPolicy
        ? null : this.securitySettings.passwordPolicy;
    },
    clearVerifiedDomainsAndRoles() {
      this.securitySettings = {
        ...this.securitySettings,
        verifiedDomains: [],
        defaultRole: this.assignableRoles.length > 0 ? { ...this.assignableRoles[0] } :
          this.securitySettings.defaultRole,
      };
    },
    async submitAutoAccountConfiguration() {
      const newSec = {
        ...this.securitySettings,
        organization: this.securitySettings.organization,
        autoCreationEnabled: this.allowAutoAccountCreation,
        verifiedDomains: this.securitySettings.verifiedDomains,
        defaultRole: this.securitySettings.defaultRole,
        passwordPolicy: this.passwordPolicy(),
      };
      const resp = await apis.organizations.updateSecuritySettings(this.organization.id, newSec);
      if (resp && resp.errors) {
        errors.parse(this.$t.bind(this), resp, this.errors);
        notify.error(this.$t('security_updated_error'));
      } else {
        notify.success(this.$t('security_updated_success'));
        await this.fetchAssignableRoles();
        await this.fetchOrgSecuritySettings();
        await this.fetchDomains();
        this.$emit('refreshOrg');
      }
    },
    async submit() {
      this.executing = true;
      const isAllowAutoAccountToggledOff = !this.allowAutoAccountCreation && this.originallyAllowAC;
      if (isAllowAutoAccountToggledOff) {
        this.clearVerifiedDomainsAndRoles();
      }
      await this.submitAutoAccountConfiguration();
      this.executing = false;
      this.canEdit = false;
    },
    async fetchOrgSecuritySettings() {
      const resp = await apis.organizations.getSecuritySettings(this.organization.id);
      if (resp.status === 403) {
        this.permissionDenied = true;
      } else if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.securitySettings = resp.data;
        this.originalSecuritySettings = {
          ...resp.data,
          defaultRole: { ...resp.data.defaultRole },
        };
        this.allowAutoAccountCreation = resp.data.autoCreationEnabled;
        this.originallyAllowAC = resp.data.autoCreationEnabled;
      }
    },
    async fetchDomains() {
      const resp = await apis.organizations.getVerifiedDomains(this.organization.id);
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.domains = [];
        this.originalDomains = [];
        resp.data.forEach((d) => {
          const domainObject = {
            ...d,
            checked: this.securitySettings.verifiedDomains.some(vd => vd.id === d.id),
          };
          this.domains.push(domainObject);
          this.originalDomains.push({ ...domainObject });
        });
      }
    },
    async fetchAssignableRoles() {
      const resp = await apis.roles.list({
        qs: {
          organization_id: this.organization.id,
          system_only: true,
          reverse_strength: true,
        },
      });
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.assignableRoles = resp.data.filter(ar => ar.defaultScope !== 'ENV' && ar.isFixed);
      }
    },
  },
};
</script>
