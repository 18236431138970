<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    v-if="!loading"
    class="knowledge-base" 
  >
    <div class="main-title">
      <h2 class="status">
        {{ $t('admin_content.knowledge_base') }}
        <base-badge 
          v-if="hasKb" 
          class="kb-state" 
          :state="stateLabel" 
          :color="stateColor" 
          :animate="showLoader"
        ></base-badge>
      </h2>
    </div>
    <template v-if="hasKb">
      <div 
        v-if="!showLoader"
        class="action-icons" 
      >
        <action-icon 
          icon="fa fa-edit" 
          @click="open = true"
        ></action-icon>
        <action-dropdown 
          :items="actions" 
          @select="onAction"
        ></action-dropdown>
      </div>
      <confirm-modal 
        :open="discardDialog" 
        headerLabel="admin_content.discard_kb_title"
        detailsLabel="admin_content.discard_kb_message" 
        headerIcon="fa fa-times"
        confirmLabel="discard_action"
        @ok="discardKb" 
        @cancel="discardDialog = false"
      >
      </confirm-modal>
      <div 
        v-for="source in kb.sources"
        :key="source.id" 
        class="source" 
      >
        <h4>{{ $t('admin_content.source_git_repo') }}</h4>
        <a :href="source.url">{{ source.url }}</a>
        <div class="description">
          {{ $t('admin_content.source_description') }}
        </div>
        <div class="description">
          {{ $t('admin_content.branch') }}: {{ source.branch }}
        </div>
        <div class="description">
          {{ $t('admin_content.latest_sync' ) }}: {{ $date(source.lastSyncDate || source.createdDate) }}
        </div>
        <hr />
      </div>
      <alert-box 
        v-if="kb.state === 'ERROR'" 
        border 
        alertType="ERROR"
      >
        {{ $t('admin_content.error_importing') }}
      </alert-box>
      <base-button 
        v-if="!showLoader" 
        class="sync-button" 
        @click="sync"
      >
        <base-icon icon="fa fa-refresh" /> {{ $t('admin_content.sync_from_repo') }}
      </base-button>
      <h3 id="admin_content.articles">
        {{ $t('admin_content.articles') }}
      </h3>
      <base-list 
        v-for="c in filteredCategories" 
        v-show="!showLoader" 
        :key="c.id" 
        ariaLabel="admin_content.articles" 
        class="article-list" 
        noActions 
        bordered 
        :ariaRowCount="c.articles.length"
      >
        <list-header :mobile="true">
          <list-column 
            class="category-header" 
            direction="horizontal"
          >
            <base-icon 
              v-if="!(c.title || {})[locale]" 
              :icon="(c.title || {})[locale] ? 'fa fa-asterisk' : 'fa fa-asterisk red'"
            />
            <base-icon :icon="c.icon" />
            <span v-html="(c.title || {})[locale] || $t('admin_content.missing_translation_for', boldify({ gitKey: c.gitKey }))"></span>
          </list-column>
        </list-header>
        <div v-if="c.externalDocumentation && c.externalDocumentation.length">
          <list-row class="article-header">
            <list-column>{{ $t('admin_content.external_documentation') }}</list-column>
            <list-column 
              class="article-url" 
              :size="2"
            >
              {{ getExternalDocumentationURL(c) }}
            </list-column>
          </list-row>
        </div>
        <div v-else>
          <list-row class="article-header">
            <list-column :size="2">
              {{ $t('title') }}
            </list-column>
            <list-column>{{ $t('state') }}</list-column>
          </list-row>
          <list-row 
            v-for="a in c.articles"
            :key="a.id" 
          >
            <list-column :size="2">
              <span>
                <base-icon 
                  v-if="!(a.title || {})[locale]" 
                  :icon="(a.title || {})[locale] ? 'fa fa-asterisk' : 'fa fa-asterisk red'"
                />
                <span v-html="(a.title || {})[locale] || $t('admin_content.missing_translation_for', boldify({ gitKey: a.gitKey }))"></span>
              </span>
              <language-indicator 
                :requiredLanguages="a.title" 
                size="sm"
              ></language-indicator>
            </list-column>
            <list-column>
              <base-badge 
                :color="a.published ? 'green' : 'blue'" 
                :state="a.published ? 'admin_content.published' : 'admin_content.draft'"
              ></base-badge>
            </list-column>
          </list-row>
        </div>
      </base-list>
      <!-- PUT LIST OF ARTICLES HERE -->
    </template>
    <template v-else>
      <div class="no-kb">
        <base-icon icon="fa fa-lightbulb-o icon" />
        <div class="message">
          {{ $t(emptyKbLabel) }}
        </div>
        <base-button @click="open = true">
          {{ $t('admin_content.create_kb') }}
        </base-button>
      </div>
    </template>
    <KnowledgeBaseForm 
      :open="open" 
      :defaultSource="defaultSource" 
      :kb="hasKb ? kb : null"
      @close="open = false" 
      @executing="loadEffective" 
    />
    <base-loader 
      v-if="showLoader" 
      class="small" 
      :delay="0" 
      type="cube"
    ></base-loader>
    <system-activity-listener 
      :codes="codes" 
      @receive="loadEffective" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import SystemActivityListener from '@/events/SystemActivityListener';
import { sortBy, searchFilter, boldify } from '@/utils';
import KnowledgeBaseForm from './KnowledgeBaseForm';

const DISCARD = 'discard';

export default {
  name: 'KnowledgeBaseTab',
  components: { KnowledgeBaseForm, SystemActivityListener },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kb: null,
      open: false,
      loading: true,
      discardDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'locale',
    ]),
    actions() {
      return [{
        value: DISCARD,
        label: 'discard_action',
        icon: 'fa fa-times',
      }];
    },
    hasKb() {
      return !!this.kb && this.kb.organizationId === this.selectedOrganization.id;
    },
    filteredCategories() {
      if (!this.kb) {
        return [];
      }
      return this.kb.categories
        .map(c => ({
          ...c,
          articles: c.articles
            .filter(searchFilter(this.search, a => [
              c.title[this.locale],
              a.title[this.locale],
              a.url_slug[this.locale],
            ])),
        }))
        .filter(c => c.articles.length || !this.search);
    },
    emptyKbLabel() {
      const parentOrg = this.selectedOrganization.parent;
      if (parentOrg && parentOrg.id) {
        return 'admin_content.no_knowledge_base';
      }
      return 'admin_content.no_default_knowledge_base';
    },
    stateLabel() {
      const state = this.kb.state.toLowerCase();
      return `admin_content.${state}`;
    },
    stateColor() {
      return {
        READY: 'green',
        ERROR: 'red',
        SYNCHING: 'blue',
        IMPORTING: 'blue',
      }[this.kb.state];
    },
    defaultSource() {
      if (this.kb) {
        return (this.kb.sources[0] || {}).url;
      }
      return null;
    },
    showLoader() {
      return this.kb && (this.kb.state === 'IMPORTING' || this.kb.state === 'SYNCHING');
    },
    codes() {
      return [
        'knowledgebase.created',
        'knowledgebase.updated',
        'knowledgebase.deleted',
        'knowledgebase.imported',
        'knowledgebase.synched',
      ];
    },
  },
  async created() {
    this.loading = true;
    await this.loadEffective();
    this.loading = false;
  },
  methods: {
    boldify,
    getExternalDocumentationURL(category) {
      return (category.externalDocumentation
        .find(a => a.language === this.locale) || {}).url;
    },
    onAction(event) {
      switch (event) {
        case DISCARD:
          this.discardDialog = true;
          break;
        default:
          break;
      }
    },
    async discardKb() {
      await apis.knowledgeBase.remove(this.kb.id);
      this.discardDialog = false;
      await this.loadEffective();
    },
    flattenAllTranslations(kb) {
      (kb.categories || []).forEach((c) => {
        this.flattenTranslations(c);
        (c.articles || []).forEach(this.flattenTranslations);
      });
    },
    flattenTranslations(obj) {
      obj.translations.reduce((acc, cur) => {
        const type = cur.type.toLowerCase();
        acc[type] = acc[type] || {};
        acc[type][cur.language] = cur.text;
        return obj;
      }, obj);
    },
    sortKb(kb) {
      (kb.categories || []).forEach((c) => {
        (c.articles || []).sort(sortBy(a => a.rank));
      });
      (kb.categories || []).sort(sortBy(c => c.rank));
    },
    async sync() {
      await apis.knowledgeBase.sync(this.kb.id);
      await this.loadEffective();
    },
    async loadEffective() {
      const resp = await apis.knowledgeBase.effective(this.selectedOrganization.id);
      this.kb = resp.data;
      if (this.kb) {
        this.flattenAllTranslations(this.kb);
        this.sortKb(this.kb);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.knowledge-base {
  text-align: left;

  .no-kb {
    text-align: center;
  }

  .article-url {
    font-weight: normal
  }

  .kb-state {
    margin-left: 10px;
  }

  .article-header {
    font-weight: bold;
    &:hover {
      background-color: white;
    }
  }

  .list-row, .list-header {
    padding-right: 5px;
    padding-left: 5px;
  }

  .article-list {
    margin-top: 20px;
  }

  h3 {
    margin-top: 5px;
  }

  .category-header {
    font-size: 1.05rem;
    em {
      margin-right: 10px;
    }
  }
}

.main-title {
  margin-top: 0px;
}

.status {
  display: flex;
}

h4 {
  margin-bottom: 10px;
}

hr {
  margin-top: 20px;
}

.action-icons {
  float: right;
  display: flex;
  flex-direction: horizontal;
  :deep(.action-icon) {
    margin-left: 10px;
  }
}

.description {
  margin-top: 4px;
}

.icon {
  font-size: 3rem;
}

.message {
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-container .description {
  margin-bottom: 30px;
}

.sync-button {
  float: right;
}
</style>
