<template>
  <base-badge 
    v-if="error" 
    :state="stateBadge.label" 
    :color="stateBadge.colour" 
    :animate="stateBadge.animated" 
    :tooltip="errorMsg"
  />
  <base-badge 
    v-else 
    :state="stateBadge.label" 
    :color="stateBadge.colour" 
    :animate="stateBadge.animated"
  />
</template>

<script>
import {
  ERROR_PROVISIONING,
  ERROR_PURGING,
  PENDING,
  PROVISIONING,
  PROVISIONED,
  PENDING_PURGE,
  PURGED,
  PURGING,
} from '@/utils/states';


export default {
  name: 'BaseStateBadge',
  props: {
    state: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ['user', 'environment', 'connection', 'organization'].indexOf(value) !== -1;
      },
    },
    entityName: {
      type: String,
      required: true,
    },
    overrideState: {
      type: String,
      required: false,
    },
    overrideStateColor: {
      type: String,
      required: false,
    }
  },
  computed: {
    error() {
      return this.state === ERROR_PURGING || this.state === ERROR_PROVISIONING;
    },
    errorMsg() {
      switch (this.state) {
        case ERROR_PROVISIONING:
          return this.$t(`states.${this.entityType}.provisioning_failed`, { name: this.entityName });
        case ERROR_PURGING:
          return this.$t(`states.${this.entityType}.purging_failed`, { name: this.entityName });
        default:
          return '';
      }
    },
    stateBadge() {
      const def = this.defaultStateBadge;
      if (this.overrideState || this.overrideStateColor) {
        return {
          colour: (this.overrideStateColor.toLocaleLowerCase()) || def.colour,
          label: (this.overrideState) || def.label,
        }
      }
      return def;
    },
    defaultStateBadge() {
      switch (this.state) {
        case PROVISIONED: {
          return {
            colour: 'green',
            label: PROVISIONED.toLocaleLowerCase(),
          };
        }
        case ERROR_PURGING:
        case ERROR_PROVISIONING: {
          return {
            colour: 'red',
            label: 'error',
          };
        }
        case PROVISIONING: {
          return {
            colour: 'blue',
            label: PROVISIONING.toLocaleLowerCase(),
            animated: true,
          };
        }
        case PENDING_PURGE:
        case PURGING: {
          return {
            colour: 'grey',
            label: PURGING.toLocaleLowerCase(),
            animated: true,
          };
        }
        case PURGED: {
          return {
            colour: 'grey',
            label: PURGED.toLocaleLowerCase(),
          };
        }
        case PENDING: {
          return {
            colour: 'grey',
            label: PENDING.toLocaleLowerCase(),
          };
        }
        default: {
          return {
            colour: 'grey',
            label: 'unknown',
          };
        }
      }
    },
  },
};
</script>
