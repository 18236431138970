import store from '@/store';
import { loadLanguageAsync } from '@/i18n';
import ScheduledJobsRoutes from './scheduledjobs/routes';
import FeaturesRoutes from './features/routes';
import ConnectionRoutes from './services/routes';
import PropertyRoutes from './properties/routes';
import OnboardingRoutes from './onboarding/routes';
import ComponentsRoutes from './components/routes';
import ContentRoutes from './content/routes';
import EmailTemplateRoutes from './emailtemplates/routes';
import AccountsRoutes from './accounts/routes';
import AuthenticationRoutes from './authentication/routes';
import UsageGenerationRoutes from './usagegeneration/routes'

const SystemSection = () => import(/* webpackChunkName: "system" */ './SystemSection');

export default [
  {
    path: 'system',
    component: SystemSection,
    children: [
      ...ComponentsRoutes,
      ...ScheduledJobsRoutes,
      ...ConnectionRoutes,
      ...PropertyRoutes,
      ...OnboardingRoutes,
      ...EmailTemplateRoutes,
      ...ContentRoutes,
      ...AccountsRoutes,
      ...AuthenticationRoutes,
      ...FeaturesRoutes,
      ...UsageGenerationRoutes
    ],
    async beforeEnter(to, from, next) {
      const userLang = store.getters.userContext.locale;
      await loadLanguageAsync('system', userLang);
      await loadLanguageAsync('label-system-permissions', userLang);
      next();
    },
  },
];
