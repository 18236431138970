<template>
  <div class="title-container">
    <div class="secondary-title">
      <div>{{ $t('billing_settings.address') }}</div>
    </div>
    <div class="edit">
      <action-icon
        v-if="hasEditPermission"
        icon="fa fa-edit"
        tooltipLabel="edit"
        expandOnHover
        clickable
        @click="editBillingAddress"
      />
    </div>
  </div>

  <div v-if="editing">
    <base-form
      :disabled="!canSubmit"
      @submit="submit"
      @cancel="cancel"
    >
      <AddressFormRow
        v-model="modifiedAddress"
        requireFields
      />
    </base-form>
  </div>
  <div v-else-if="addressHasValues">
    <base-attributes
      :attributes="billingAddressAttributes"
      noTitle
    >
      <template #default="slotProps">
        <div>
          <copyable-field
            :modelValue="slotProps.att.value"
            :disabled="!slotProps.att.copyable"
            :onHover="!mobile"
          >
            <div v-if="slotProps.att.i18n">
              {{ $t(slotProps.att.value) }}
            </div>
            <div v-else>
              {{ slotProps.att.value }}
            </div>
          </copyable-field>
        </div>
      </template>
    </base-attributes>
  </div>
  <div v-else>
    <empty-message
      class="no_address"
      textLabel="org_billing.no_billing_address_configured"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import authz from '@/authz';
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import { isMobileMode } from '@/utils';
import AddressFormRow from '@/components/AddressFormRow.vue';

export default {
  name: "BillingAddressForm",
  components: {
    AddressFormRow,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      loading: true,
      billingAddress: {},
      modifiedAddress: {
        lineOne: this.billingAddress?.lineOne,
        lineTwo: this.billingAddress?.lineTwo,
        city: this.billingAddress?.city,
        province: this.billingAddress?.province,
        country: this.billingAddress?.country,
        postalCode: this.billingAddress?.postalCode,
      },
    };
  },
  computed: {
    ...mapGetters(["myOrganization"]),
    mobile() {
      return isMobileMode();
    },
    hasEditPermission() {
      return authz.hasPermission({
        permissions: ["admin:billing", "reseller:organizationBilling"],
        or: true
      });
    },
    billingAddressAttributes() {
      return [
        { labelKey: "org_billing.billing_address_line1", value: this.billingAddress?.lineOne, copyable: true },
        { labelKey: "org_billing.billing_address_line2", value: this.billingAddress?.lineTwo, copyable: true },
        { labelKey: "org_billing.billing_address_city", value: this.billingAddress?.city, copyable: true },
        { labelKey: "org_billing.billing_address_province", value: this.billingAddress?.province, copyable: true },
        { labelKey: "org_billing.billing_address_postalcode", value: this.billingAddress?.postalCode, copyable: true },
        { labelKey: "org_billing.billing_address_country", value: this.billingAddress?.country, copyable: true },
      ].filter(attr => !!attr.value);
    },
    addressHasValues() {
      return !!this.billingAddress && (!!this.billingAddress.lineOne
        || !!this.billingAddress.city || !!this.billingAddress.province
        || !!this.billingAddress.postalCode || !!this.billingAddress.country);
    },
    canSubmit() {
      return !!this.modifiedAddress.lineOne
        && !!this.modifiedAddress.city && !!this.modifiedAddress.province
        && !!this.modifiedAddress.postalCode && !!this.modifiedAddress.country;
    },
  },
  created() {
    this.loading = true;
    this.loadBillingAddress();
    this.loading = false;
  },
  methods: {
    async submit() {
      const body = {
        address: { ...this.modifiedAddress },
      };
      const resp = await apis.organizations.updateBillingAddress(this.organization.id, body);
      if (resp && resp.errors) {
        notify.error(this.$t("address_updated_error"));
      } else {
        notify.success(this.$t("address_updated_success"));
        this.billingAddress = { ...this.modifiedAddress };
      }
      this.editing = false;
    },
    cancel() {
      this.modifiedAddress = { ...this.billingAddress };
      this.editing = false;
    },
    editBillingAddress() {
      this.modifiedAddress = { ...this.billingAddress };
      this.editing = true;
    },
    async loadBillingAddress() {
      const resp = await apis.organizations.getBillingAddress(this.organization.id);
      if (resp.status !== 200) {
        notify.error(this.$t("unexpected_error"));
        return;
      }
      this.billingAddress = resp.data.address;
    },
  },
}

</script>
<style lang="scss" scoped>
.title-container {
  display:flex;
  justify-content: space-between;
}
.secondary-title {
  margin: 0;
}
.edit {
  float: right;
}
.no_address {
  padding-bottom: 20px;
}

</style>
