import Api from './api';

class DocsApi extends Api {
  list() {
    return this.get(`docs`, {}, false, false, false);
  }
}

const docsApi = new DocsApi();

export default docsApi;
