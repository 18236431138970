<template>
  <system-activity-listener 
    :codes="codes" 
    @receive="updateOrgFeatures"
  />
</template>
<script>
import SystemActivityListener from '@/events/SystemActivityListener';
import apis from '@/utils/apis';
import { mapActions, mapGetters } from 'vuex';
import notify from '@/utils/notify';

export default {
  name: 'FeatureListener',
  components: { SystemActivityListener },
  data() {
    return {
      codes: ['organization_features.delete', 'organization_features.create', 'organization_features.update'],
    };
  },
  computed: {
    ...mapGetters(['organizationFeatures']),
  },
  methods: {
    ...mapActions([
      'setOrganizationFeatures',
    ]),
    async updateOrgFeatures() {
      const existingFeatures = this.organizationFeatures;
      const updatedFeaturesResp = await apis.organizations.getEnabledFeature();
      if (updatedFeaturesResp.ok) {
        const updatedFeatures = updatedFeaturesResp.data;
        this.setOrganizationFeatures(updatedFeatures);
        if (existingFeatures.length < updatedFeatures.length) {
          this.showNotification(updatedFeatures, existingFeatures);
        } else if (existingFeatures.length > updatedFeatures.length) {
          this.showNotification(existingFeatures, updatedFeatures, false);
        }
      }
    },
    showNotification(iterateOver, compareWith, enable = true) {
      const feature = iterateOver.find(f => !compareWith.includes(f));
      const notificationLabel = enable ? 'features.enable_label' : 'features.disable_label';
      notify.success(this.$t(notificationLabel, { feature: this.$t(`features.${feature.toLowerCase()}`) }));
    },
  },
};
</script>
