import store from '@/store';
import { loadLanguageAsync } from '@/i18n';

import Login from './Login';

export default [{
  path: '/login:rest(/.*)?',
  name: 'Login',
  component: Login,
  async beforeEnter(to, from, next) {
    if (store.getters.loggedIn) {
      let loggedInPath = to.params.rest;
      if (!loggedInPath) {
        loggedInPath = '/';
      }
      if (to.query.SAMLRequest) {
        window.location = to.fullPath.split('/login')[1] || '';
      } else {
        next({ path: loggedInPath, query: to.query, replace: true });
      }
    } else {
      await loadLanguageAsync('login');
      await loadLanguageAsync('notifications');
      next();
    }
  },

}];
