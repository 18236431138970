import { CrudApi } from './api';

const ROOT_CONTEXT = '/commitments';
class CommitmentApi extends CrudApi {
  constructor() {
    super(ROOT_CONTEXT);
  }
  terminate(commitmentId, endDate) {
    return this.post(`/commitments/${commitmentId}/terminate/`, { body: { endDate: endDate } });
  }

  findAll(resellerId) {
    return this.get('/commitments/find', { qs: { reseller_id: resellerId } });
  }

  fetchPricedProducts(commitmentId, startDate, endDate) {
    return this.get(`/commitments/${commitmentId}/products/price`, { qs: { start_date: startDate, end_date: endDate } });
  }
}
const commitments = new CommitmentApi();
export default commitments;
