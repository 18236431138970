<template>
  <div class="invalid-message-container">
    <div class="message-container">
      <h1 class="title">
        {{ $t('invalid_registration_token') }}
      </h1>
      <div class="body">
        {{ $t('invalid_registration_token_body') }}
      </div>
    </div>
  </div>
  <LanguagePicker class="language-picker" />
</template>

<script>
import LanguagePicker from '../components/LanguagePicker.vue';

export default {
    name: 'InvalidRegistrationTokenMessage',
    components: { LanguagePicker }
}
</script>

<style scoped lang="scss">
@import '@/styles/login-form';

.invalid-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 50px;
    position: relative;
}

.language-picker {
    position: absolute;
    right: 57px;
    bottom: 20px;
}
</style>