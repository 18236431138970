import { CrudApi } from './api';

class FeedbackApi extends CrudApi {
  constructor() {
    super('/feedback/send');
  }

  submit(f, orgId) {
    return this.create(f,
      { qs: {
        organizationId: orgId,
      } });
  } 
}

export default new FeedbackApi();
