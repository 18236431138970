<!-- eslint-disable no-debugger -->
<template>
  <div>
    <system-activity-listener
      :codes="codes"
      :currentOrganization="false"
      @receive="fetchAll"
    />
    <section-header
      :filters="allFilters"
      @search="search = $event"
      @refresh="refreshFilter"
    >
      <template #logo>
        <base-icon icon="fa fa-building" />
      </template>
      <template #title>
        <div>{{ $t('organizations') }}</div>
      </template>
      <template #subtitle>
        <div>{{ $t('organization_description') }}</div>
      </template>
      <template #rhs>
        <div>
          <base-button
            v-if="canAdministerSubs"
            type="button"
            :rounded="true"
            size="sm"
            @click="$router.push({ name: 'addOrganization' })"
          >
            <base-icon icon="fa fa-plus-circle" />
            {{ $t('add_organization') }}
          </base-button>
        </div>
      </template>
    </section-header>
    <OrganizationNavigator
      v-if="mobile"
      :orgSearch="search"
      open
    />
    <div v-else>
      <base-loader v-if="loading"></base-loader>
      <OrganizationHierarchyList
        v-else
        :sort="sortingKey"
        :organizations="filteredOrganizations"
        :search="search"
        :flat="flat"
        :errorLabel="errorLabel"
        @onMouseOver="onMouseOver"
        @onMouseExit="onMouseExit"
      >
        <list-header class="organization-list-header">
          <base-tooltip
            disableClick
            :message="$t('organization_hierarchy_toggle_action')"
          >
            <cmc-icon
              class="hierarchy-icon"
              :class="flat ? 'flat' : 'hierarchy'"
              with-clickable
              :svg="true"
              :icon="flat ? 'flat-list' : 'hierarchy-list'"
              :aria-label="$t('organization_hierarchy_toggle_action')"
              size="m"
              @keyup.enter="toggleHierarchy"
              @click="toggleHierarchy"
            />
          </base-tooltip>
          <list-column
            :size="2"
            sortableKey="name"
            :showSort="showSort('name')"
            @sortColumn="setSelectedSort"
          >
            {{ $t('name') }}
          </list-column>
          <list-column
            sortableKey="activities"
            :showSort="showSort('activities')"
            @sortColumn="setSelectedSort"
          >
            {{ $t('activity_graph_column_header') }}
          </list-column>
          <list-column
            sortableKey="status"
            :showSort="showSort('status')"
            @sortColumn="setSelectedSort"
          >
            {{ $t('status') }}
          </list-column>
          <list-column
            sortableKey="last_status_change"
            :showSort="showSort('last_status_change')"
            @sortColumn="setSelectedSort"
          >
            {{ $t('last_status_change') }}
          </list-column>
          <list-column
            sortableKey="age"
            :showSort="showSort('age')"
            @sortColumn="setSelectedSort"
          >
            {{ $t('age') }}
          </list-column>
          <list-column v-if="showTags">
            {{ $t('tags') }}
          </list-column>
        </list-header>
        <template #columns="{ org }">
          <OrganizationRow
            :organization="org"
            :activity="activityByOrg[org.id]"
          />
        </template>
        <template #actions="{ org }">
          <OrganizationActions
            :organization="org"
            :administrableOrgs="administrableOrgs"
            :showActions="showActions(org)"
            @deleted="removeOrg"
            @refresh="$emit('refresh', $event)"
          />
        </template>
      </OrganizationHierarchyList>
    </div>
    <no-results-message v-if="isEmpty" />
  </div>
</template>
<script>

import { isMobileMode, searchFilter, sortBy } from '@/utils';
import { buildDatePresetFilter, buildSimpleFilter } from '@/utils/components/filters';
import { isBefore } from '@/utils/dates';
import authz from '@/authz';
import apis from '@/utils/apis';
import OrganizationNavigator from '@/app/Main/Organizations/OrganizationNavigator';
import OrganizationActions from '@/app/Main/Organizations/OrganizationActions';
import OrganizationHierarchyList from '@/app/Main/components/OrganizationHierarchyList';
import SystemActivityListener from '@/events/SystemActivityListener';
import { mapGetters } from 'vuex';
import OrganizationRow from './OrganizationRow';
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";

const FILTERS = {
  STATUS: 'status',
  TAG: 'tags',
  AGE_PRESET: 'age_preset',
  LAST_STATUS_CHANGE: 'last_status_change'
};

export default {
  name: 'OrganizationList',
  components: {
    CmcIcon,
    OrganizationRow,
    OrganizationNavigator,
    OrganizationHierarchyList,
    OrganizationActions,
    SystemActivityListener,
  },
  emits: ['refresh'],
  data() {
    return {
      allFilters: [],
      appliedFilters: [],
      flat: false,
      filteredOrganizations: [],
      organizations: [],
      administrableOrgs: {},
      activityByOrg: {},
      loading: true,
      search: '',
      error: false,
      hover: {
        organizationId: ''
      },
      sortingKey: this.$route.query.sort || 'name:asc',
      FILTERS
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'myOrganization',
    ]),
    codes() {
      return [
        'organizations.create',
        'organizations.update',
        'organizations.delete',
        'organizations.purge',
      ]
    },
    canAdministerSubs() {
      return !!this.administrableOrgs[this.selectedOrganization.id]
        && this.administrableOrgs[this.selectedOrganization.id].includes('ORG_SUBS');
    },
    errorLabel() {
      return this.error ? 'unable_to_load' : '';
    },
    mobile() {
      return isMobileMode();
    },
    showTags() {
      return authz.hasPermission('reseller:orgsMeta');
    },
    showHierarchyToggle() {
      const filteredOrganizationIds = this.filteredOrganizations.map(org => org.id);
      const filteredOrgsIncludesSelectedOrg = this.filteredOrganizations.some(org => org.id === this.selectedOrganization.id);
      const filteredOrgsContainsParents = this.filteredOrganizations.filter(o => !!o.parent).map(o => o.parent.id).every(org => filteredOrganizationIds.includes(org));
      return filteredOrgsIncludesSelectedOrg && filteredOrgsContainsParents;
    },
    /**
     * Method to calculate whether the list of filtered organizations is empty or not.
     */
    isEmpty() {
      return (this.filteredOrganizations.length === 0 ||
          this.filteredOrganizations.filter(searchFilter(
            this.search,
            o => [o.name, o.entryPoint, o.id, ...(o.tags || []).map(t => t.name),
              ...(o.customFields ? Object.values(o.customFields) : [])],
          )).length === 0)
        && !this.loading;
    },
  },
  async created() {
    if (!this.mobile) {
      await this.refresh();
    }
  },
  methods: {
    showSort(key) {
      return (key === 'name' && !this.$route.query.sort) || this.sortingKey.includes(key);
    },
    toggleHierarchy() {
      if (this.showHierarchyToggle) {
        this.flat = !this.flat;
        if (!this.flat) {
          this.sortingKey = 'name:asc';
          this.pushSortingQueryParameter();
        }
      }
    },
    setSelectedSort($event) {
      const key = $event.sortKey;
      const sortSuffix = $event.sortAscending ? ':asc' : ':desc';
      this.sortingKey = key + sortSuffix;
      this.pushSortingQueryParameter();
    },
    pushSortingQueryParameter() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          sort: this.sortingKey,
        },
      });
    },
    showActions(organization) {
      return this.hover.organizationId === organization.id;
    },
    onMouseOver(organizationId) {
      this.hover = { organizationId: organizationId };
    },
    onMouseExit() {
      this.hover = { organizationId: undefined };
    },
    removeOrg(orgToDeleteId) {
      this.organizations = this.organizations.filter(o => o.id !== orgToDeleteId);
    },
    /**
     * The computed filters must support filtering on: Active, Tags.
     */
    computeFilters() {
      this.allFilters = [];

      const statusValues = ['active', 'inactive'];

      const uniqueTags = this.organizations
        .filter(x => x.lineage.includes(this.selectedOrganization.id))
        .filter(org => org.tags)
        .flatMap(org => org.tags)
        .map(tag => tag.name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
      const statusFilter = buildSimpleFilter(FILTERS.STATUS, statusValues, true);
      statusFilter.loadDefaultValue = this.isDefaultFilter();
      this.allFilters.push(statusFilter);
      if (uniqueTags.length > 0) {
        this.allFilters.push(buildSimpleFilter(FILTERS.TAG, uniqueTags));
      }
      this.allFilters.push(
        buildDatePresetFilter(FILTERS.AGE_PRESET),
        buildDatePresetFilter(FILTERS.LAST_STATUS_CHANGE)
      );
    },
    isDefaultFilter() {
      // add filter if there are no filters in the route and selected org is active
      const filterFromRoute = this.$route.query.filter;
      const selectedOrgStatus = this.selectedOrganization.status;
      return !filterFromRoute && (!selectedOrgStatus || selectedOrgStatus == 'ACTIVE')
    },
    refreshFilter($event) {
      this.loading = true;

      let pendingOrganizations = this.organizations;
      for (const currentFilter of $event) {
        const values = [...currentFilter.values].map(x => x.value);

        if (values.length === 0) {
          continue;
        }

        if (currentFilter.key.value === FILTERS.STATUS) {
          pendingOrganizations = this.filterByValues(pendingOrganizations, currentFilter.key.value, values);
        }

        if (currentFilter.key.value === FILTERS.TAG) {
          pendingOrganizations = this.filterByTags(pendingOrganizations, values);
        }

        if (currentFilter.key.value === FILTERS.AGE_PRESET) {
          pendingOrganizations = this.filterByDate(pendingOrganizations, values, 'creationDate');
        }

        if (currentFilter.key.value === FILTERS.LAST_STATUS_CHANGE) {
          pendingOrganizations = this.filterByDate(pendingOrganizations, values, 'statusUpdatedAt');
        }
      }
      this.setFilteredOrganizations(pendingOrganizations);
      this.appliedFilters = $event;
    },
    /**
     * Filter a list of organizations by list of values
     *
     * @param {Array} organizations list of organization
     * @param {string} key field to filter on
     * @param {Array} values list of values by
     */
    filterByValues(organizations, key, values) {
      return organizations.filter(org => values.includes(org[key]));
    },
    /**
     * Filter a list of organizations by a list of tags
     * @param {Array} organizations list of organization
     * @param {Array} tags list of tags
     */
    filterByTags(organizations, tags) {
      const orgs = [];
      for (let org of organizations) {
        for (let tag of org.tags) {
          if (tags.includes(tag.name)) {
            orgs.push(org);
          }
        }
      }
      return orgs;
    },
    filterByDate(organizations, ages, key) {
      if (ages.length > 0) {
        return organizations.filter(org => isBefore(ages[0], org[key]));
      }
      return organizations;
    },
    /**
     * Calculate whether the list of organizations contains the current organization or not
     *
     * @param {array} organizations The list of organizations
     */
    containsRootOrganization(organizations) {
      return organizations.some(org => org.id === this.selectedOrganization.id);
    },
    /**
     * Set the list of filtered organizations to be displayed
     *
     * Note: This method flattens the list if required.
     *
     * @param {array} organizations The list of organizations
     */
    setFilteredOrganizations(organizations) {
      // Ensure that all organizations are unique.
      const uniqueOrganizations = organizations
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter(x => x.lineage.includes(this.selectedOrganization.id));

      this.flat = !this.containsRootOrganization(uniqueOrganizations);
      this.filteredOrganizations = uniqueOrganizations;
      this.loading = false;
    },
    async fetchOrganizations() {
      const orgListResp = await apis.organizations.list({ qs: { orderby: 'name', detailed: false } });
      if (orgListResp.ok) {
        this.organizations = orgListResp.data.sort(sortBy(o => o.name.toLowerCase()));
        this.filteredOrganizations = this.organizations;
      } else {
        this.error = true;
      }
    },
    async fetchAdministableOrgs() {
      const administableOrgsResp = await apis.organizations.administrableOrgs();
      if (administableOrgsResp.ok) {
        this.administrableOrgs = administableOrgsResp.data;
      }
    },
    async fetchActivities() {
      const activityByOrg = await apis.organizations.activity();
      if (activityByOrg.ok) {
        this.activityByOrg = activityByOrg.data;
      }
    },
    decorateOrgsWithNumActivities() {
      this.organizations.forEach(org => {
        let numActivitiies = 0;
        if (this.activityByOrg[org.id]) {
          numActivitiies = this.activityByOrg[org.id].reduce((acc, cur) => acc + cur, 0);
        }
        org.numActivities = numActivitiies;
      });
    },
    async fetchAll() {
      await Promise.all([
          this.fetchActivities(),
          this.fetchAdministableOrgs(),
          this.fetchOrganizations(),
        ]);
        // Once the organizations are loaded:
        this.computeFilters();
        this.refreshFilter(this.appliedFilters);
        this.decorateOrgsWithNumActivities();
    },
    async refresh() {
      this.loading = true;
      if (!this.mobile) {
        await this.fetchAll();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-list-header {
  display: flex;
  align-items: center;
}
.hierarchy-icon {
  padding-top: 0.125rem;
  margin-right: .5rem;
}
</style>
