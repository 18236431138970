const download = (blob, name, type) => {
  try {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${name}.${type}`;
    link.click();
    URL.revokeObjectURL(link.href);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }
};

const downloadAsPdf = (blob, pdfName) => download(blob, pdfName, 'pdf');

const downloadAsCsv = (blob, name) => download(blob, name, 'csv');

const downloadAsJson = (blob, name) => download(blob, name, 'json');

export {
  downloadAsPdf,
  downloadAsCsv,
  downloadAsJson
};
