<template>
  <div>
    <div 
      class="base-checkbox" 
      :class="{ sm }"
    >
      <input 
        type="checkbox" 
        :class="{ 'clickable': !disabled }" 
        :disabled="disabled" 
        :value="modelValue" 
        :checked="modelValue" 
        @change="input" 
        @keyup.enter="input"
      />
      <div 
        class="checkbox" 
        :class="{ disabled, 'error-border': !!error }"
      >
        <base-icon icon="fa fa-check" />
      </div>
      <div class="text-container">
        <div
          v-if="toolTip && label"
          class="label"
        >
          <base-tooltip
            :message="label" 
            direction="right"
            overflow
            @click.prevent
          >
            <span>{{ getTruncatedValue(label) }}</span>
          </base-tooltip>
        </div> 
        <div 
          v-else-if="label"
          class="label" 
        >
          {{ $te(label) ? $t(label) : label }} <span 
            v-if="optional" 
            class="optional"
          >({{ $t('optional') }})</span>
        </div>

        <div 
          v-if="description"
          class="description" 
        >
          {{ $t(description) }}
        </div>
        <div class="html-label">
          <slot></slot>
        </div>
      </div>
    </div>
    <div 
      v-if="typeof error === 'string' && error"
      class="error-label red" 
    >
      {{ $te(error) ? $t(error) : error }}&nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Boolean],
    },
    optional: {
      type: Boolean,
      default: false,
    },
    toolTip: {
      type: Boolean,
      default: false,
    },
    maxCharacters: {
      type: Number,
      default: 15,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    input(e) {
      if (!this.disabled) {
        if (e.type === 'change') {
          this.$emit('update:modelValue', e.target.checked);
        } else {
          e.preventDefault();
          this.$emit('update:modelValue', !this.modelValue);
        }
      }
    },
    /**
     * Get the truncated value
     * 
     * @param {string} value The value to truncate
     * @returns The truncated value
     */
    getTruncatedValue(value) {
      return this.$t(value).substring(0, this.maxCharacters) + "...";
    },
  },
};
</script>

<style scoped lang="scss">
.base-checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .fa {
    font-size: 20px;
    display: none;
  }
  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 2px;
    margin-right: 15px;
    border: 1px solid var(--primary-light);
    &.disabled {
      background-color: var(--plain-dark);
    }
    &.error-border{
      border: 1px solid var(--input-error-border);
      box-sizing: border-box;
  }
  }

 input[type=checkbox]:focus + .checkbox {
   outline: var(--blue) solid medium;
   &:not(.error-border){
    border: 1px solid grey;
    box-sizing: border-box;
   }
  }
  &.sm {
    .checkbox {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
    .label, .description {
      font-size: 0.95rem;
    }
    .fa {
      font-size: 0.9rem;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
  }
  .description {
    margin: 0px
  }
}

.error-label {
  margin-top: 4px;
  margin-left: 35px;
}

.label {
  margin: 0;
}

.html-label {
  :deep(a) {
    position: relative;
  }
}
</style>
