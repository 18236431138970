import BrandingSection from './BrandingSection';
import BrandingView from './BrandingView';
import BrandingCreate from './BrandingCreate';

export default [
  {
    path: 'branding',
    component: BrandingSection,
    meta: {
      protected: ['reseller:branding'],
      title: () => 'branding_name',
    },
    children: [
      {
        name: 'brandingView',
        path: '',
        component: BrandingView,
      },
      {
        name: 'create',
        path: 'create',
        component: BrandingCreate,
        props: { switchOrgRouteName: 'brandingView' },
      },
      {
        name: 'edit',
        path: 'edit',
        component: BrandingCreate,
        props: { switchOrgRouteName: 'brandingView' },
      },
    ],
  },
];
