const MonetizationSection = () => import(/* webpackChunkName: "admin" */ './MonetizationSection');
const ProductCatalogList = () => import(/* webpackChunkName: "admin" */ './catalog/ProductCatalogList');
const ProductCatalogPage = () => import(/* webpackChunkName: "admin" */ './catalog/ProductCatalogPage');
const ProductCatalogDetails = () => import(/* webpackChunkName: "admin" */ './catalog/ProductCatalogDetails');
const AddProductCatalog = () => import(/* webpackChunkName: "admin" */ './catalog/AddProductCatalog');
const EditProductCatalog = () => import(/* webpackChunkName: "admin" */ './catalog/EditProductCatalog');
const PricingList = () => import(/* webpackChunkName: "admin" */ './pricing/PricingList');
const PricingDetails = () => import(/* webpackChunkName: "admin" */ './pricing/PricingDetails');
const PricingChanges = () => import(/* webpackChunkName: "admin" */ './pricing/PricingChanges');
const PricingChangeForm = () => import(/* webpackChunkName: "admin" */ './pricing/PricingChangeForm');
const PricingPage = () => import(/* webpackChunkName: "admin" */ './pricing/PricingPage');
const PricingForm = () => import(/* webpackChunkName: "admin" */ './pricing/PricingForm');
const PricingEdit = () => import(/* webpackChunkName: "admin" */ './pricing/PricingEdit');
const PricingPackageList = () => import(/* webpackChunkName: "admin" */ './pricingPackage/PricingPackageList');
const AddPricingPackage = () => import(/* webpackChunkName: "admin" */ './pricingPackage/AddPricingPackage');
const EditPricingPackage = () => import(/* webpackChunkName: "admin" */ './pricingPackage/EditPricingPackage');
const PricingPackagePage = () => import(/* webpackChunkName: "admin" */ './pricingPackage/PricingPackagePage');
const PricingPackageDetails = () => import(/* webpackChunkName: "admin" */ './pricingPackage/PricingPackageDetails');
const DiscountList = () => import(/* webpackChunkName: "admin" */ './pricingPackage/DiscountList');
const AddDiscount = () => import(/* webpackChunkName: "admin" */ './pricingPackage/AddDiscount');
const EditDiscount = () => import(/* webpackChunkName: "admin" */ './pricingPackage/EditDiscount');
const DiscountDetail = () => import(/* webpackChunkName: "admin" */ './pricingPackage/DiscountDetail');
const AssignedOrganizations = () => import(/* webpackChunkName: "admin" */ './pricingPackage/AssignedOrganizations');

const RollbackList = () => import(/* webpackChunkName: "admin" */ './rollback/RollbackList');
const RollbackDetails = () => import(/* webpackChunkName: "admin" */ './rollback/RollbackDetails');
const RollbackPage = () => import(/* webpackChunkName: "admin" */ './rollback/RollbackPage');
const AddRollback = () => import(/* webpackChunkName: "admin" */ './rollback/AddRollback');

const GlobalCommitmentList = () => import(/* webpackChunkName: "admin" */ './commitment/GlobalCommitmentList');
const CommitmentDetails = () => import(/* webpackChunkName: "admin" */ '../../components/commitments/CommitmentDetails');
const CommitmentPage = () => import(/* webpackChunkName: "admin" */ './commitment/CommitmentPage');
const AddCommitment = () => import(/* webpackChunkName: "admin" */ '../../components/commitments/AddCommitment');

const MonetizationInsights = () => import(/* webpackChunkName: "admin" */ './insights/MonetizationInsights');

export default [
  {
    path: 'monetization',
    component: MonetizationSection,
    meta: { protected: ['reseller:monetization'] },
    children: [
      {
        path: '',
        redirect: { name: 'monetization-insights' },
      },
      {
        name: 'monetization-insights',
        path: 'insights',
        component: MonetizationInsights,
        meta: {
          title: () => 'monetization.insights_header',
        },
      },
      {
        name: 'productCatalogList',
        path: 'product-catalogs',
        component: ProductCatalogList,
        meta: {
          title: () => 'monetization.product_catalogs',
        },
      },
      {
        name: 'pricingList',
        path: 'pricings',
        component: PricingList,
        meta: {
          title: () => 'monetization.pricings',
        },
      },
      {
        name: 'pricingPackageList',
        path: 'pricing-packages',
        component: PricingPackageList,
        meta: {
          title: () => 'monetization.pricing_packages',
        },
      },
      {
        name: 'rollbackList',
        path: 'rollbacks',
        component: RollbackList,
        meta: {
          title: () => 'monetization.rollbacks',
        },
      },
      {
        name: 'global-commitment-list',
        path: 'commitments',
        component: GlobalCommitmentList,
        meta: {
          title: () => 'monetization.commitments.list_desc',
        },
      },
    ],
  },
  {
    path: 'monetization/pricings',
    component: PricingPage,
    props: true,
    meta: { protected: ['reseller:monetization'] },
    children: [
      {
        name: 'addPricing',
        path: 'add',
        component: PricingForm,
        props: { switchOrgRouteName: 'pricingList' },
        meta: {
          title: () => 'monetization.add_pricing',
        },
      },
      {
        path: ':id',
        redirect: { name: 'pricingDetails' },
      },
      {
        name: 'pricingDetails',
        path: ':id/details',
        component: PricingDetails,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingList' }),
        meta: {
          title: () => 'monetization.pricing_details',
        },
      },
      {
        name: 'editPricing',
        path: ':id/edit',
        component: PricingEdit,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingList' }),
        meta: {
          title: () => 'monetization.edit_pricing',
        },
      },
      {
        name: 'pricingChangesAdd',
        path: 'pricings/:id/changes/add',
        component: PricingChangeForm,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingList' }),
        meta: {
          title: () => 'monetization.add_change',
        },
      },
      {
        name: 'pricingChangesUpdate',
        path: 'pricings/:id/changes/:pricingChangeId/edit',
        component: PricingChangeForm,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingList' }),
        meta: {
          title: () => 'monetization.update_change',
        },
      },
      {
        name: 'pricingChanges',
        path: ':id/changes',
        component: PricingChanges,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingList' }),
        meta: {
          title: () => 'monetization.pricing_changes',
        },
      },
    ],
  },
  {
    path: 'monetization/product-catalogs',
    component: ProductCatalogPage,
    meta: { protected: ['reseller:monetization'] },
    props: true,
    children: [
      {
        name: 'addProductCatalog',
        path: 'add',
        component: AddProductCatalog,
        props: { switchOrgRouteName: 'productCatalogList' },
        meta: {
          title: () => 'monetization.add_product_catalog',
        },
      },
      {
        path: ':id',
        redirect: { name: 'productCatalogDetails' },
      },
      {
        name: 'productCatalogDetails',
        path: ':id/details',
        component: ProductCatalogDetails,
        props: route => ({ ...route.params, switchOrgRouteName: 'productCatalogList' }),
        meta: {
          title: () => 'monetization.product_catalog_details',
        },
      },
      {
        name: 'editProductCatalog',
        path: ':id/edit',
        props: route => ({ ...route.params, switchOrgRouteName: 'productCatalogList' }),
        component: EditProductCatalog,
        meta: {
          title: () => 'monetization.edit_product_catalog',
        },
      },
    ],
  },
  {
    path: 'monetization/pricing-packages',
    component: PricingPackagePage,
    props: true,
    meta: { protected: ['reseller:monetization'] },
    children: [
      {
        name: 'addPricingPackage',
        path: 'add',
        component: AddPricingPackage,
        props: { switchOrgRouteName: 'pricingPackageList' },
        meta: {
          title: () => 'monetization.add_pricing_package',
        },
      },
      {
        path: ':id',
        redirect: { name: 'pricingPackageDetails' },
      },
      {
        name: 'pricingPackageDetails',
        path: ':id/details',
        component: PricingPackageDetails,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'monetization.pricing_package_details',
        },
      },
      {
        name: 'assignedOrganizations',
        path: ':id/organizations',
        component: AssignedOrganizations,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'assigned_organizations',
        },
      },
      {
        name: 'discountList',
        path: ':id/discounts',
        component: DiscountList,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'monetization.discounts.discount_list',
        },
      },
      {
        name: 'creditList',
        path: ':id/credits',
        component: DiscountList,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList', credit: true }),
        meta: {
          title: () => 'monetization.discounts.discount_list',
        },
      },
      {
        name: 'discountDetails',
        path: ':id/discounts/:discountId',
        component: DiscountDetail,
        props: route => ({ ...route.params, switchOrgRouteName: 'discountList' }),
        meta: {
          title: () => 'monetization.discounts.percentage.name',
        },
      },
      {
        name: 'creditDetails',
        path: ':id/credits/:discountId',
        component: DiscountDetail,
        props: route => ({ ...route.params, switchOrgRouteName: 'creditList', credit: true }),
        meta: {
          title: () => 'monetization.discounts.credit.name',
        },
      },
      {
        name: 'addCredit',
        path: ':id/credits/add',
        component: AddDiscount,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList', credit: true }),
        meta: {
          title: () => 'monetization.discounts.credit.add',
        },
      },
      {
        name: 'addDiscount',
        path: ':id/discounts/add',
        component: AddDiscount,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'monetization.discounts.percentage.add',
        },
      },
      {
        name: 'editCredit',
        path: ':pricingPackageId/credits/:id/edit',
        component: EditDiscount,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList', credit: true }),
        meta: {
          title: () => 'monetization.discounts.credit.edit',
        },
      },
      {
        name: 'editDiscount',
        path: ':pricingPackageId/discounts/:id/edit',
        component: EditDiscount,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'monetization.discounts.percentage.edit',
        },
      },
      {
        name: 'editPricingPackage',
        path: ':id/edit',
        component: EditPricingPackage,
        props: route => ({ ...route.params, switchOrgRouteName: 'pricingPackageList' }),
        meta: {
          title: () => 'monetization.edit_pricing_package',
        },
      },
    ],
  },
  {
    path: 'monetization/rollbacks',
    component: RollbackPage,
    meta: { protected: ['reseller:monetization'] },
    props: true,
    children: [
      {
        name: 'addRollback',
        path: 'add',
        component: AddRollback,
        props: { switchOrgRouteName: 'rollbackList' },
        meta: {
          title: () => 'monetization.add_rollback_trigger',
        },
      },
      {
        path: ':id',
        redirect: { name: 'rollbackDetails' },
      },
      {
        name: 'rollbackDetails',
        path: ':id/details',
        component: RollbackDetails,
        props: route => ({ ...route.params, switchOrgRouteName: 'rollbackList' }),
        meta: {
          title: () => 'monetization.rollback_details',
        },
      },
    ],
  },
  {
    path: 'monetization/commitments',
    component: CommitmentPage,
    meta: { protected: ['reseller:monetization'] },
    props: true,
    children: [
      {
        name: 'addCommitment',
        path: 'add',
        component: AddCommitment,
        props: { switchOrgRouteName: 'global-commitment-list' },
        meta: {
          title: () => 'monetization.commitments.operations.create.title',
        },
      },
      {
        name: 'global-commitment-details',
        path: ':id/details',
        component: CommitmentDetails,
        props: route => ({ ...route.params, switchOrgRouteName: 'global-commitment-list' }),
        meta: {
          title: () => 'monetization.commitments.commitment_details',
        },
      },
    ],
  },
];
