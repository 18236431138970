/* eslint-disable no-use-before-define */

/**
 * Returns true if a compact layout should be used for repeating a form element.
 * Will use a compact layout when elements being repeated are single form elements,
 * or when all form elements are on a single row, and none have labels or descriptions.
 *
 * @param {String}    type      the type of form element
 * @param {Object[]}  children  the form element children
 */
function compactLayout(type, children) {
  if (type === 'composite') {
    return isOnSingleRow(children);
  }
  return (type !== 'repeatable');
}

/**
 * Returns true if padding should be added to the form element.
 * All form elements should have padding, except for column layouts
 * and composites that are on a single row.
 *
 * @param {String}    type      the type of form element
 * @param {Object[]}  children  the form element children
 */
function hasPadding(type, children) {
  return !(type === 'row' || (type === 'composite' && isOnSingleRow(children)));
}

/**
 * Returns true if the form element is a composite
 * and does not have a label or description in its first row.
 *
 * @param {String}    type      the type of form element
 * @param {Object[]}  children  the form element children
 */
function compositeWithoutLabelsInFirstRow(type, children) {
  return (type === 'composite') && noLabelsInFirstRow(children);
}

function noLabelsInFirstRow(children) {
  if (!children || !children.length) {
    return false;
  }
  if (children[0].type === 'row') {
    const rowElements = children[0].formElements;
    return rowElements.every(element => !element.descriptionLabel && !element.label);
  }
  return !children[0].descriptionLabel && !children[0].label;
}

function isOnSingleRow(children) {
  const singleRow = (children && children.length === 1 && children[0].type === 'row');
  if (singleRow) {
    const rowElements = children[0].formElements;
    return rowElements.every(element => !element.descriptionLabel && !element.label);
  }
  return false;
}

export {
  compactLayout,
  hasPadding,
  compositeWithoutLabelsInFirstRow,
};
