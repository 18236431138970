<template>
  <base-details
    :name="quota.name || ''"
    :description="quota.description"
    :summaryColumns="summaryColumns"
    :errorLabel="errorLabel"
  >
    <template #rhs>
      <action-icon 
        v-if="hasEditPermission && supportsManagement"  
        :to="`/admin/quotas/${quota.id}/details/edit`" 
        tooltipLabel="quotas.edit" 
        icon="fa fa-edit" 
        expandOnHover
      ></action-icon>
    </template>
    <template #type="{ summary }">
      <div>
        <div class="default-for-indicator">
          <base-icon 
            class="default-for-icon" 
            :icon="getClass(summary.defaultForService)"
          />
          <div>{{ $t('quotas.default_for_service') }}</div>
        </div>
        <div class="default-for-indicator">
          <base-icon 
            class="default-for-icon" 
            :icon="getClass(summary.defaultForTrial)"
          />
          <div>{{ $t('quotas.default_for_trial') }}</div>
        </div>
      </div>
    </template>
    <template #details>
      <div class="secondary-title">
        <h2>{{ $t('details') }}</h2>
      </div>
      <QuotaTabs :quotaDetails="quotaDetails">
        <template #default="{ groupedDetails }">
          <base-attributes 
            noTitle 
            :attributes="groupedDetails"
          >
            <template #default="props">
              <div>
                <copyable-field 
                  rawValue 
                  onHover 
                  :modelValue="props.att.value"
                ></copyable-field>
              </div>
            </template>
          </base-attributes>
        </template>
      </QuotaTabs>
    </template>
  </base-details>
</template>
<script>

import { mapGetters } from 'vuex';
import { getConnectionIconUrl } from '@/utils/service';
import QuotaTabs from './QuotaTabs';
import authz from '@/authz';

export default {
  name: 'QuotaDetails',
  components: { QuotaTabs },
  props: {
    quota: {
      type: Object,
    },
  },
  data() {
    return {
      errorLabel: null,
    };
  },
  computed: {
    ...mapGetters([
      'serviceDescriptors',
    ]),
    hasEditPermission() {
      return authz.hasPermission("reseller:quotas");
    },
    supportsManagement() {
      const sc = this.quota.serviceConnection;
      return this.serviceDescriptors[sc.type].supportsQuota &&
             this.serviceDescriptors[sc.type].quotaMetricIdentifiers.length > 0;
    },
    summaryColumns() {
      return [{
        label: 'connection',
        logo: getConnectionIconUrl(this.quota.serviceConnection),
        value: this.quota.serviceConnection.name,
      }, {
        label: 'type',
        defaultForService: this.quota.defaultForService,
        defaultForTrial: this.quota.defaultForTrial,
      }];
    },
    quotaDetails() {
      return this.quota.quotaDetails.map(qd => ({
        value: this.quotaLimit(qd),
        labelKey: (qd.metricDescriptor ? qd.metricDescriptor.nameLabelKey : qd.labelKey),
        categoryLabelKey: qd.metricDescriptor ? qd.metricDescriptor.categoryLabelKey : null,
      }));
    },
  },
  created() {
    if (!this.quota.id) {
      this.errorLabel = 'quotas.error_fetching_quota';
    }
  },
  methods: {
    quotaLimit(qd) {
      return (qd.ceiling < 0 || qd.limitSize < 0) ? this.$t('unlimited') : this.quotaLimitValue(qd);
    },
    quotaLimitValue(qd) {
      return qd.metricDescriptor ? `${qd.ceiling} ${this.$t(qd.metricDescriptor.unitLabelKey)}` : `${qd.limitSize}`;
    },
    getClass(isDefault) {
      return isDefault ? 'fa fa-check-circle green' : 'fa fa-times-circle grey';
    },
  },
};
</script>
<style scoped lang="scss">
.default-for-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.default-for-icon {
  margin-right: 5px;
}
</style>
