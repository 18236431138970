import ContentSection from './ContentSection';
import KnowledgeBaseTab from './knowledgebase/KnowledgeBaseTab';

export default [
  {
    path: 'content',
    component: ContentSection,
    meta: { protected: ['reseller:knowledgeBase'] },
    children: [
      {
        path: '',
        redirect: { name: 'knowledge-base' },
      },
      {
        name: 'knowledge-base',
        path: 'knowledge-base',
        component: KnowledgeBaseTab,
        meta: {
          title: () => 'admin_content.knowledge_base',
        },
      },
    ],
  },
];
