import CmcDoughnutChart from "./CmcDoughnutChart.vue"
import CmcChart from "./CmcChart.vue";
import CmcBarChart from "./CmcBarChart.vue";
import CmcPieChart from "./CmcPieChart.vue";
import CmcLineChart from "./CmcLineChart.vue";
import CmcSparklineChart from "./CmcSparklineChart.vue";
import CmcGaugeChart from "./CmcGaugeChart.vue";

export default {
  CmcChart,
  CmcBarChart,
  CmcPieChart,
  CmcLineChart,
  CmcSparklineChart,
  CmcGaugeChart,
  CmcDoughnutChart
}