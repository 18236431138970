<template>
  <div class="infinite-scroll"></div>
</template>

<script>
import { getParentScrollElement } from '@/utils';

export default {
  name: 'InfiniteScroll',
  props: {
    loading: {
      type: Boolean,
    },
    load: {
      type: Function,
      required: true,
    },
    hasMore: {
      type: Boolean,
      required: true,
    },
    loadOnInit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollParent: null,
      scrollHandler: null,
      cwListener: null,
    };
  },
  beforeUnmount() {
    this.scrollParent.removeEventListener('scroll', this.scrollHandler);
    if (this.cwListener) {
      this.scrollParent.removeEventListener('mousewheel', this.cwListener);
    }
  },
  mounted() {
    this.scrollParent = this.getScrollElement();
    this.scrollHandler = () => {
      const shouldReload = this.getDistanceToElement() < 100 || !this.isScrolling();
      if (shouldReload && !this.loading && this.hasMore) {
        this.load();
      }
    };
    if (this.loadOnInit) {
      this.scrollHandler();
    }
    if (window.chrome) {
      /*
       * Related to:
       * - https://stackoverflow.com/questions/35024301/xhr-settimeout-promise-not-finishing-until-scrolling-stops-in-chrome
       * - https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
       * - https://github.com/TryGhost/Ghost/issues/7934
       */
      this.chromeWorkaround();
    }
    this.scrollParent.addEventListener('scroll', this.scrollHandler, { passive: true });
  },
  methods: {
    getScrollElement(elm = this.$el) {
      return getParentScrollElement(elm);
    },
    getDistanceToElement() {
      const elmPosition = this.$el.getBoundingClientRect().top;
      const scrollPosition = this.scrollParent.getBoundingClientRect().bottom;
      return elmPosition - scrollPosition;
    },
    isScrolling() {
      return this.$el.getBoundingClientRect().top > this.scrollParent.clientHeight;
    },
    chromeWorkaround() {
      this.cwListener = (e) => {
        if (e.deltaY === 1) {
          e.preventDefault();
        }
      };
      this.scrollParent.addEventListener('mousewheel', this.cwListener);
    },
  },
};
</script>


<style scoped lang="scss">
</style>
