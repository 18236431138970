import {
  getDate,
  getLastTwelveMonthDates,
  getMin,
  getNow,
  isEqual,
  isWithinRangeInclusiveStart,
  MONTH_DIGIT_YEAR_FORMAT,
  MONTH_NAME_YEAR_FORMAT,
  startOfDay,
  subDays,
} from '@/utils/dates';

const ONGOING_STATES = ['NONE', 'MANUAL', 'IN_PROGRESS', 'WAITING_INVOICE', 'FUTURE'];

export const billingCycleMixin = {
  computed: {
    billingCycleOptions() {
      return getLastTwelveMonthDates().map(date => ({
        label: this.$date(date, MONTH_NAME_YEAR_FORMAT),
        value: this.$date(date, MONTH_DIGIT_YEAR_FORMAT),
      }));
    },
  },
  methods: {
    /**
     * Returns true if the billing cycle discount does not span the entire billing cycle.
     * @param {Object} discount      the billing cycle discount
     * @param {Object} billingCycle  the biling cycle
     * @returns true if discount starts or ends mid-cycle
     */
    showDiscountRange(discount, billingCycle) {
      return billingCycle
        && (!isEqual(discount.startDate, billingCycle.startDate)
          || !isEqual(discount.endDate, billingCycle.endDate));
    },
    equalsEffectiveDate(discount, startDate, endDate) {
      return isEqual(discount.startDate, startDate) && isEqual(discount.endDate, endDate);
    },
    /**
     * Returns the billing cycle discount's date range.
     * @param {Object} discount the billing cycle discount
     * @returns formatted date range
     */
    getDiscountRange(discount) {
      const startDate = discount.startDate;
      const endDate = subDays(discount.endDate, 1, true);
      if (!endDate) {
        return `${this.$date(startDate, 'YYYY-MM-DD', true)} - ${this.$t('monetization.discounts.no_expiry_date')}`;
      }
      return `${this.$date(startDate, 'YYYY-MM-DD', true)} - ${this.$date(endDate, 'YYYY-MM-DD', true)}`;
    },
    /**
     * Returns the billing cycle discount's start and expiry dates.
     * @param {Object} discount     the billing cycle discount
     * @param {Object} billingCycle the billing cycle
     * @returns start and expiry dates
     */
    getDiscountRangeForInvoice(discount, billingCycle) {
      const showStartDate = !isEqual(discount.startDate, billingCycle.startDate);
      const showEndDate = !isEqual(discount.endDate, billingCycle.endDate);
      if (showStartDate && !showEndDate) {
        return this.$t('discount_started_on', { date: this.$date(discount.startDate, 'YYYY-MM-DD', true) });
      }
      if (showEndDate && !showStartDate) {
        const endDate = subDays(discount.endDate, 1, true)
        return this.$t('discount_expired_on', { date: this.$date(endDate, 'YYYY-MM-DD', true) });
      }
      return this.getDiscountRange(discount);
    },
    cycleDate(cycle) {
      const sd = this.$date(cycle.startDate, 'MMM DD YYYY', true);
      const ed = this.$date(subDays(cycle.endDate, 1, true), 'MMM DD YYYY', true);
      return `${sd} - ${ed}`;
    },
    cycleState(cycle) {
      let color;
      switch (cycle.state) {
        case 'NONE':
          color = 'red';
          break;
        case 'WAITING_INVOICE':
        case 'WAITING_PAYMENT':
        case 'IN_PROGRESS':
          color = 'blue';
          break;
        case 'PAID':
        case 'COMPLETE':
          color = 'green';
          break;
        case 'FUTURE':
        default:
          color = 'gray';
          break;
      }
      return {
        color,
        label: `monetization.billing_cycle.${cycle.state.toLowerCase()}`,
      };
    },
    isOngoing(cycle) {
      if (cycle && cycle.state) {
        return ONGOING_STATES.includes(cycle.state);
      }
      return false;
    },
    minStartDate(cycles) {
      return cycles.reduce((acc, curr) => {
        if (this.isOngoing(curr)) {
          const cycleStart = getDate(curr.startDate, true);
          return getMin(cycleStart, acc);
        }
        return acc;
      }, startOfDay(getNow(true), true));
    },
    /**
     * Return the billing cycle within the range of the given date,
     * if there is no billing cycle within the given date, return the latest billing cycle
     *
     * @param cycles The available billing cycles
     * @param date The date which will be used to check if the billing cycle is within the range
     * @returns {Object|null} The billingCycle
     */
    getCycleWithinRangeOrLatest(cycles, date) {
      let billingCycle = cycles.find((cycle) => {
        const cycleStart = getDate(cycle.startDate, true);
        const cycleEnd = getDate(cycle.endDate, true);
        return isWithinRangeInclusiveStart(date, cycleStart, cycleEnd);
      });

      if (!billingCycle) {
        billingCycle = this.getLatestBillingCycle(cycles);
      }

      return billingCycle;
    },
    /**
     * Return the latest billingCycle sorted by endDate
     *
     * @param cycles The available billing cycles
     * @returns {Object|null} The billingCycle
     */
    getLatestBillingCycle(cycles) {
      if (cycles && cycles.length > 1) {
        return cycles.sort((c1, c2) => c1.endDate - c2.endDate)[cycles.length - 1];
      } else if (cycles.length == 1) {
        return cycles[0]  
      } else {
        return null;
      }
    }
  },
};
