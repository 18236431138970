import apis from '@/utils/apis';
import { mapGetters } from 'vuex';

export const taxMixin = {
  computed: {
    ...mapGetters(['selectedOrganization']),
  },
  methods: {
    async hasTaxProviderConfigured(orgId) {
      const resp = await apis.taxProviders.getTaxProviderConfigured(orgId);
      return resp.status === 200 && resp.data;
    },
    verifyProductsHaveTaxCode(products) {
      return products.filter(p => !p.deprecated).every(p => p.taxCode);
    },
    async fetchTaxCodes() {
      const resp = await apis.taxProviders.listTaxCodes(this.selectedOrganization.id);
      return (resp.data || []).map(t => ({
        value: t.code,
        display: t.code,
        description: t.description,
      }));
    },
    async fetchHasTaxProvider() {
      return this.hasTaxProviderConfigured(this.selectedOrganization.id);
    },
  },
};
