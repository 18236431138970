<template>
  <div>
    <div
      v-if="isMobileMode || orgListFocused || showActions"
      class="action-container"
    >
      <action-icon 
        v-if="canAdminister" 
        icon="fa fa-edit" 
        expandOnHover 
        tooltipLabel="edit_organization" 
        :to="`/admin/organizations/${organization.id}/details/edit`"
      ></action-icon>
      <action-dropdown 
        class="dropdown" 
        :items="actions"
        @select="onAction"
      ></action-dropdown>
    </div>
    <confirm-modal
      :open="openReseller"
      headerLabel="org_mark_as_reseller"
      :headerLabelInterpolation="{ name: organization.name }"
      detailsLabel="org_mark_as_reseller_confirmation"
      :detailsInterpolation="{ name: organization.name }"
      @ok="markAsReseller"
      @cancel="clear"
    ></confirm-modal>
    <confirm-modal
      :open="openDelete"
      :headerLabelInterpolation="{ name: organization.name }"
      :disabled="invalid"
      :detailsLabel="orgDeletionMessage"
      :detailsInterpolation="{ name: organization.name }"
      headerLabel="org_delete_header"
      @ok="deleteOrg"
      @cancel="clear"
    >
      <template v-if="orgDeletionMessage === 'org_delete_message_warning'">
        <base-input 
          v-model="confirmationInput"
          placeholder="org_confirm_instruction" 
        ></base-input>
      </template>
      <div v-if="canManageTrials && orgDeletionMessage === 'org_delete_is_trial'">
        <alert-box 
          border 
          alertType="INFO"
        >
          <i18n-t 
            keypath="org_delete_manage_trial" 
            border 
            tag="div"
          >
            <template #trial_page>
              <app-link :to="{ name: 'trials' }">
                {{ $t('trial_page') }}
              </app-link>
            </template>
          </i18n-t>
        </alert-box>
      </div>
    </confirm-modal>
  </div>
</template>
<script>
import authz from '@/authz';
import { isMobileMode } from '@/utils';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { organizationSwitchMixin } from '@/mixins/organizationSwitchMixin';
import { mapGetters } from 'vuex';

const SWITCH_ORGANIZATION = 'switch_organization';
const VIEW_DETAILS = 'view_details';
const MANAGE_SERVICES = 'manage_services';
const MANAGE_SECURITY = 'manage_security';
const ADD_SUB_ORGS = 'add_sub_orgs';
const DELETE_ORGANIZATION = 'delete_organization';
const VIEW_DOMAINS = 'view_domains';
const MARK_RESELLER = 'mark_reseller';


export default {
  name: 'OrganizationActions',
  mixins: [organizationSwitchMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    administrableOrgs: {
      type: Object,
      required: true,
    },
    hideViewAction: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  emits: ['deleted', 'refresh'],
  
  data() {
    return {
      openDelete: false,
      openReseller: false,
      confirmationInput: '',
      isMobileMode: false,
      orgListFocused: false
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'myOrganization',
    ]),
    canMarkReseller() {
      return !(this.organization.isReseller || this.organization.isTrial);
    },
    invalid() {
      return this.organization.name !== this.confirmationInput;
    },
    canAdminister() {
      return !!this.administrableOrgs[this.organization.id]
      && this.administrableOrgs[this.organization.id].includes('ORG_BASE');
    },
    canAdministerSubs() {
      return !!this.administrableOrgs[this.organization.id]
      && this.administrableOrgs[this.organization.id].includes('ORG_SUBS');
    },
    canManageTrials() {
      return authz.hasPermission('reseller:trials');
    },
    canConfigureEmailSettings() {
      return authz.hasPermission('reseller:email');
    },
    orgDeletionMessage() {
      if (this.orgToDeleteHasChildren) {
        return 'org_delete_has_children';
      }
      if (this.organization.isTrial) {
        return 'org_delete_is_trial';
      }
      if (this.organization.locked) {
        return 'org_delete_is_locked';
      }
      return 'org_delete_message_warning';
    },
    orgToDeleteHasChildren() {
      return !this.organization ||
              (this.organization.children || []).length > 0;
    },
    isSelectedOrg() {
      return this.organization.id === this.selectedOrganization.id;
    },
    actions() {
      return [
        {
          value: SWITCH_ORGANIZATION,
          label: 'switch_organization',
          icon: 'fa fa-sign-in',
          hide: this.isSelectedOrg,
        },
        {
          value: VIEW_DETAILS,
          label: 'view_details',
          icon: 'fa fa-list',
          hide: this.hideViewAction,
        },
        {
          value: MARK_RESELLER,
          label: 'org_mark_as_reseller',
          icon: 'fa fa-dollar',
          permission: 'sys:orgMeta',
          hide: !this.canMarkReseller,
        },
        {
          value: VIEW_DOMAINS,
          label: 'view_domains',
          icon: 'fa fa-id-card',
          hide: this.hideViewAction,
        },
        {
          value: MANAGE_SECURITY,
          label: 'manage_security',
          icon: 'fa fa-lock',
          permission: 'sys:orgMeta',
          hide: this.hideViewAction,
        },
        {
          value: MANAGE_SERVICES,
          label: 'manage_assigned_services',
          icon: 'fa fa-plug',
          permission: 'reseller:connections',
          hide: this.hideViewAction,
        },
        {
          value: ADD_SUB_ORGS,
          label: 'add_sub_organization',
          icon: 'fa fa-plus',
          hide: !this.canAdministerSubs,
        },
        {
          value: DELETE_ORGANIZATION,
          label: 'delete',
          icon: 'fa fa-trash',
          hide: !this.canDeleteOrganization,
        },
      ].filter(a => !a.hide);
    },
    canDeleteOrganization() {
      return this.canAdminister &&
             this.organization.id !== this.myOrganization.id &&
             this.organization.id !== this.selectedOrganization.id;
    },
  },
  beforeMount(){
    // set default isMobileMode value
    this.mobile()
  },
  mounted() {
    window.addEventListener("resize", this.mobile);
    document.addEventListener('focusin', this.focusChanged);
    document.addEventListener('mouseup', this.resetFocus)
  },
  unmounted() {
    window.removeEventListener("resize", this.mobile);
    document.removeEventListener('focusin', this.focusChanged);
    document.removeEventListener('mouseup', this.resetFocus)
  },
  methods: {
    mobile() {
      this.isMobileMode = isMobileMode()
    },
    focusChanged() {
      // we only want to disable the Action Icon with a mouseup event
      if (!this.orgListFocused){
        const pattern = /(?:^|\s)list-row(?:\s|$)/
        this.orgListFocused = (document.activeElement.className.match(pattern))
      }
    },
    resetFocus(){
      this.orgListFocused = false
    },
    onAction(event) {
      switch (event) {
        case SWITCH_ORGANIZATION:
          this.changeOrgAndRedirect(this.organization);
          break;
        case VIEW_DETAILS:
          this.goToOrg();
          break;
        case MARK_RESELLER:
          this.confirmReseller();
          break;
        case MANAGE_SERVICES:
          this.goToOrgServices();
          break;
        case MANAGE_SECURITY:
          this.$router.push({ path: `/admin/organizations/${this.organization.id}/security/nativeConfiguration` });
          break;
        case ADD_SUB_ORGS:
          this.$router.push({ path: `/admin/organizations/${this.organization.id}/details/add`, props: { id: this.organization.id } });
          break;
        case DELETE_ORGANIZATION:
          this.confirmDelete();
          break;
        case VIEW_DOMAINS:
          this.$router.push({ path: `/admin/organizations/${this.organization.id}/domains` });
          break;
        default:
          break;
      }
    },
    goToOrg() {
      this.$router.push({ path: `/admin/organizations/${this.organization.id}` });
    },
    goToOrgServices() {
      this.$router.push({ path: `/admin/organizations/${this.organization.id}/services` });
    },
    async deleteOrg() {
      this.clear();
      const resp = await apis.organizations.remove(this.organization.id);
      if (!resp.ok) {
        notify.error(this.$t('org_delete_org_error'));
      } else {
        notify.success(this.$t('org_delete_org_success', { name: this.organization.name }));
        this.$emit('deleted', this.organization.id);
      }
    },
    async markAsReseller() {
      this.clear();
      const resp = await apis.organizations.markReseller(this.organization.id);
      if (!resp.ok) {
        notify.error(this.$t('org_mark_as_reseller_error'));
      } else {
        notify.success(this.$t('org_mark_as_reseller_success', { name: this.organization.name }));
        this.$emit('refresh', this.organization.id);
      }
    },
    clear() {
      this.openDelete = false;
      this.openReseller = false;
      this.confirmationInput = '';
    },
    confirmReseller() {
      this.openReseller = true;
    },
    confirmDelete() {
      this.openDelete = true;
    },
  },
};
</script>
<style scoped lang="scss">
.action-container {
  display: flex;
  flex-direction: row;
}
</style>
