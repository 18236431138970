<template>
  <div :title="hasMoreThanLength ? str : undefined">
    {{ adaptedStr }}
  </div>
</template>

<script>
export default {
  name: 'LongString',
  props: {
    str: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    adaptedStr() {
      if (this.hasMoreThanLength) {
        const cutstr = this.str.slice(0, this.maxLength);
        return `${cutstr}...`;
      }
      return this.str;
    },
    hasMoreThanLength() {
      return this.str.length > this.maxLength;
    },
  },
};
</script>

<style scoped lang="scss">
div {
  text-align: left;
}
</style>
