import BaseRoute from '@/app/components/BaseRoute';
import AddUsageGeneration from "@/app/Main/System/usagegeneration/AddUsageGeneration.vue";

const UsageGenerationList = () => import(/* webpackChunkName: "usage-generation" */ './UsageGenerationList');

export default [
    {
        path: 'usage-generation',
        component: BaseRoute,
        meta: {
            protected: ['root:sys:internal'],
            title: () => 'usage_generation_name',
        },
        children: [
            {
                name: 'usageGenerations',
                path: '',
                component: UsageGenerationList,
            },{
              name: 'addUsageGeneration',
              path: 'add',
              component: AddUsageGeneration,
          },
        ],
    },
];
