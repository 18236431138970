import { randomBetween } from '@/utils';
import store from '@/store';
import socket from 'atmosphere.js';
import httputils from '@/utils/httputils';

/*
* This counter keeps track of the number of errors that have occurred in a short period of time.
* This is a rolling counter, meaning that it will reduce the number of errors by 1 every 5 seconds.
*/
let errors = 0;

/**
 * Once it reaches this threshold on the number of errors, it'll prompt the user to login again.
 */
const MAX_ERROR_COUNT = 5;

let socketConn;
let handlers = {};

let subscribedEnv;
let subscribedOrg;
let reconnectAttempt = false;

const registerHandlers = (handlersToAdd) => {
  handlers = { ...handlers, ...handlersToAdd };
}

const dispatch = (message) => {
  const handler = handlers[message.type];
  if (handler) {
    handler.handle(message.data);
  }
};

/**
 * This method is called whenever there is a message from the socket.
 * @param {Object} response the request's last response.
 */
function onMessage(response) {
  if (response.responseBody) {
    try {
      const message = JSON.parse(response.responseBody);
      dispatch(message);
    } catch (e) {
      if (process.env.VUE_APP_VERSION.includes('SNAPSHOT')) {
        // eslint-disable-next-line
        console.log(e);
      }
      errors += 1;
    }
  }
}

/**
 * The callback method is called whenever the socket state changes.
 * 
 * This method is used to determine whether there was a series of error in a short period of time.
 * When it happens, it will unsubscribe and dispatch a sessionTimedOut action to show a login modal.
 * 
 * @param {Object} response the request's last response.
 */
function callback(response) {
  // check state so it doesn't create an unsubscribe loop
  if (errors >= MAX_ERROR_COUNT && !["unsubscribe", "closed"].includes(response.state)) {
    errors = 0;
    socket.unsubscribe();

    // Dispatch a sessionTimedOut action to show a login modal this will force a refresh.
    store.dispatch("sessionTimedOut");

    // Close this existing socket connection.
    if (socketConn) { 
      socketConn.close();
    }
  }
}
const subscribeToEnvironment = envId => {
  subscribedEnv = envId;
  (socketConn ? socketConn.push(JSON.stringify({ envId })) : null);
};
const subscribeToOrg = orgId => {
  subscribedOrg = orgId;
  (socketConn ? socketConn.push(JSON.stringify({ orgId })) : null);
};

const init = () => new Promise((resolve, reject) => {
  if (socketConn) {
    resolve();
    return;
  }
  let initializing = true;
  const request = {
    url: "/socket",
    transport: "websocket",
    contentType: "application/json",
    trackMessageLength: true,
    reconnectInterval: randomBetween(4000, 8000),
    maxReconnectOnClose: Number.MAX_SAFE_INTEGER, // To simulate Infinity
    timeout: 3600000 * 3, // 60 minutes * 3 = 3 hrs
    fallbackTransport: "websocket",
    withCredentials: true,
    headers: { [httputils.CSRF_TOKEN_HEADER]: httputils.getCsrfToken() },
    onMessage,
    /**
     * This is called when the socket is opened.
     */
    onOpen: () => {
      if (initializing) {
        resolve();
        return;
      }
      initializing = false;
      reconnectAttempt = true;
    },
    /**
     * This is called when the socket detects that Core has closed the connection.
     */
    onClose: () => {
      reconnectAttempt = true;
    },
    /**
     * This is called periodically when the socket is trying to reconnect.
     */
    onReconnect: () => {
      reconnectAttempt = true;
    },
    /**
     * This is called whenever the timeout value in the request has been reached.
     */
    onClientTimeout: () => {
      reconnectAttempt = true;
    },
    /**
     * This is called whenever there is an error with the socket configuration. (Does not usually happen.)
     */
    onError: () => {
      if (initializing) {
        reject();
      }
      initializing = false;
      reconnectAttempt = true;
    },
    callback: callback,
  };
  socketConn = socket.subscribe(request);
  setInterval(() => {
    // Whenever a message is received, we keep alive the connection by re-subscribing to the environment and organization.
    if (socketConn.response.state === "messageReceived") {
      if (subscribedEnv) {
        subscribeToEnvironment(subscribedEnv);
      }
      if (subscribedOrg) {
        subscribeToOrg(subscribedOrg);
      }
    }

    // Reduce the number of errors, up to 0.
    errors += errors > 0 ? -1 : 0;

    if (!reconnectAttempt) {
      return;
    }

    reconnectAttempt = false;

    // This is to handle the case where the socket is closed because of a Timeout.
    if (["closedByClient", "re-connecting"].includes(socketConn.response.state)) {
      // Close the previous connection and open a new one.
      socket.unsubscribe();
      socketConn.close();

      // Refresh the CSRF token
      request.headers = {
        [httputils.CSRF_TOKEN_HEADER]: httputils.getCsrfToken()
      };

      socketConn = socket.subscribe(request);
      return;
    }
  }, 5000);
});

export default {
  init,
  registerHandlers,
  subscribeToEnvironment,
  subscribeToOrg,
};