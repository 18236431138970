import { ComputedRef, computed, inject } from "vue";


export default function useReadOnly({ readOnly, inheritReadOnly }: { readOnly? : boolean, inheritReadOnly?: boolean }) {
  const parentReadOnly = inject('readOnly', undefined) as (ComputedRef<boolean> | undefined)
  return computed(() => {
    if (inheritReadOnly === false) {
      return readOnly;
    }
    return parentReadOnly?.value || readOnly
  })
}