import { ReadOnlyApi } from './api';

const RULES = {
  min_password_length(password, minLength) {
    return (password || '').length >= minLength;
  },
  min_lowercase_letters(password, minLowercase) {
    return password.replace(/[^a-z]/g, '').length >= minLowercase;
  },
  min_uppercase_letters(password, minUppercase) {
    return password.replace(/[^A-Z]/g, '').length >= minUppercase;
  },
  min_numbers(password, minNumbers) {
    return password.replace(/[^0-9]/g, '').length >= minNumbers;
  },
  min_special_characters(password, minSpecial) {
    return password.replace(/[A-Za-z0-9\s]/g, '').length >= minSpecial;
  },
};


class PasswordPoliciesApi extends ReadOnlyApi {
  constructor() {
    super('passwordPolicies');
  }

  async list() {
    const resp = await super.list();
    if (resp.ok) {
      return {
        ...resp,
        data: resp.data.map(pp => ({
          ...pp,
          func: RULES[pp.name] || (() => {}),
        })),
      };
    }
    return resp;
  }
}

const passwordPoliciesApi = new PasswordPoliciesApi();
export default passwordPoliciesApi;
