
const TheHome = () => import(/* webpackChunkName: "mainApp" */ './TheHome');

export default [
  {
    name: 'home',
    path: 'home',
    component: TheHome,
    meta: {
      title: () => 'home',
    },
  },
];
