<template>
  <base-loader v-if="loading"></base-loader>
  <DiscountForm
    v-else
    :credit="credit"
    :title="$t(`monetization.discounts.${type}.edit`)"
    :organization="organization"
    :modelValue="discount"
    :packages="packagesForCycle"
    :pricingPackage="selectedPricingPackage"
    :effectivePricing="effectivePricing"
    :minStartDate="minStartDateValue"
    :isCustomDiscount="true"
    @startUpdated="fetchEffectiveCyclesAndPricings"
    @packageUpdated="updatePackage"
    @submit="editDiscount"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import BaseLoader from '@/components/BaseLoader.vue';
import { getDate, getMin, getNow, startOfDay } from '@/utils/dates';
import DiscountForm from '@/app/Main/Administration/monetization/pricingPackage/DiscountForm.vue';
import { pricingMixin } from '@/mixins/pricing';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';

export default {
  name: 'EditOrganizationDiscount',
  components: { DiscountForm, BaseLoader },
  mixins: [orgSwitchNavigationMixin, pricingMixin, billingCycleMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    credit: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      discount: undefined,
      packagesForCycle: [],
      billingCycle: {},
      effectivePricing: {},
      selectedPricingPackageId: null,
      selectedPricingPackage: {},
      allCyclesForOrg: [],
    };
  },
  computed: {
    ...mapGetters(['locale']),
    type() {
      return this.credit ? 'credit' : 'percentage';
    },
    pricingPackagesForRange() {
      return this.billingCycles ?
        new Set(this.billingCycles.flatMap(cycle => cycle.pricingPackages)
          .map(pp => pp.packageId)) : [];
    },
    minStartDateValue() {
      return this.allCyclesForOrg.reduce((earliestStartDate, currentCycle) => {
        if (this.isOngoing(currentCycle)) {
          const cycleStart = getDate(currentCycle.startDate, true);
          return getMin(cycleStart, earliestStartDate);
        }
        return earliestStartDate;
      }, startOfDay(getNow(true), true));
    },
  },
  async created() {
    this.loading = true;

    await this.fetchDiscount();
    await this.fetchEffectiveBillingCycles(this.discount.startDate);
    await this.fetchPricingPackages();
    await this.fetchEffectivePricing(this.discount.startDate);

    this.loading = false;
  },
  methods: {
    async fetchPricingPackages() {
      const resp = await apis.organizations
        .fetchPackagesForBillingCycle(this.organization.id, this.billingCycle.id);
      if (resp && resp.status !== 200) {
        notify.error('monetization.error_list_pricing_package');
        return;
      }
      const pricingPackages = resp.data.map(pp => ({
        ...pp,
        display: pp.name[this.locale],
        value: pp.id,
      }));
      this.packagesForCycle = pricingPackages;
      if (this.packagesForCycle.length > 0) {
        this.selectedPricingPackage = this.packagesForCycle[0];
      }
    },
    async fetchEffectiveBillingCycles(start) {
      const resp = await apis.billable.getBillingCycles(this.organization.id, true);
      if (resp && resp.status !== 200) {
        notify.error(this.$t('error_fetching_cycles'));
        return;
      }
      this.allCyclesForOrg = resp.data;
      this.billingCycle = this.getCycleWithinRangeOrLatest(this.allCyclesForOrg, start);
    },
    async fetchDiscount() {
      const resp = await apis.organizations
        .getDiscount(this.organization.id, this.credit ? this.$route.params.creditId : this.$route.params.discountId);

      if (resp && resp.status === 200) {
        this.discount = resp.data;
      } else {
        notify.error(this.$t('monetization.discounts.errors.edit_discount'));
        this.$router.navigateBackOrDefault({ name: 'billingOverview' });
      }
    },
    async editDiscount(discount) {
      const resp = await apis.organizations
        .editDiscount(this.organization.id, discount.id, discount);
      if (resp && resp.status === 200) {
        notify.success(this.$t('monetization.discounts.'.concat(this.type, '.edited'), { name: this.discount.name[this.locale] }));
      } else if (this.credit) {
        notify.error(this.$t('monetization.discounts.errors.edit_credit'));
      } else {
        notify.error(this.$t('monetization.discounts.errors.edit_discount'));
      }
      this.$router.navigateBackOrDefault({ name: 'billingOverview' });
    },
    async updatePackage(pricingPackage, effectiveStartDate) {
      this.selectedPricingPackageId = pricingPackage;
      this.selectedPricingPackage = this.packagesForCycle
        .find(pp => pp.id === this.selectedPricingPackageId);
      await this.fetchEffectivePricing(effectiveStartDate);
    },
    async fetchEffectivePricing(date) {
      this.effectivePricing = await this.fetchEffectivePricingForDate(date,
        this.selectedPricingPackage, 'org-billing');
    },
    async fetchEffectiveCyclesAndPricings(start) {
      await this.fetchEffectiveBillingCycles(getDate(start, true));
      await this.fetchPricingPackages();
      await this.fetchEffectivePricing(start);
    },
  },
};

</script>
