
// initial state
const intialState = {
};

// getters
const getters = {
};

// actions
const actions = {
};

// mutations
const mutations = {
};

export default {
  state: intialState,
  getters,
  actions,
  mutations,
};
