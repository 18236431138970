<template>
  <form-row
    v-for="field in customFieldsToDisplay"
    :key="field.id"
    :error="fieldErrorsToLabels[field.field]"
  >
    <cmc-text
      v-if="field.mode !== 'MULTISELECT_WITH_COUNT'"
      asLabel
      :asOptional="field.mode === 'ALPHANUMERIC' && !field.modeOptions?.isRequired"
      :text="field.nameTranslations[locale]"
      :withTooltip="field.descriptionTranslations[locale]"
      :withWarningTooltip="field.isAllowedOnResource ? '' : 'custom_fields.cannot_update_restricted_field'"
      withWarningTooltipI18n
    />
    <base-select
      v-if="field.mode === 'PREDEFINED_LIST'"
      :modelValue="model[field.field]"
      :items="field.values"
      :disabled="!field.isAllowedOnResource"
      @update:modelValue="updateModel(field.field, $event)"
    />
    <MultiSelectWithCount
      v-else-if="field.mode === 'MULTISELECT_WITH_COUNT'"
      :modelValue="model[field.field]"
      :items="multiselectWithCountItems(field.values)"
      :disabled="!field.isAllowedOnResource"
      :name="field.nameTranslations[locale]"
      :description="field.descriptionTranslations[locale]"
      :nameI18n="false"
      :descriptionI18n="false"
      @update:modelValue="updateModel(field.field, $event)"
    />
    <base-multi-select
      v-else-if="field.mode === 'MULTISELECT'"
      :modelValue="model[field.field]"
      :items="field.values"
      :selectedPlaceholder="field.nameTranslations[locale]"
      :disabled="!field.isAllowedOnResource"
      :filter="buildFilter(field)"
      class="multiselect-no-counter"
      maxCharactersOnCheckbox="100"
      maxCharactersOnPreview="100"
      @update:modelValue="updateMultiselectValue(field.field, $event)"
    />
    <base-input
      v-else
      :disabled="field.mode === 'AUTOGENERATED' || !field.isAllowedOnResource"
      :modelValue="model[field.field]"
      @input="updateModel(field.field, $event.target.value)"
    />
  </form-row>
</template>

<script>

import MultiSelectWithCount from '@/app/Main/Administration/customfields/MultiSelectWithCount';
import BaseInput from "@/components/input/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import CmcText from "@/components/nextgen/typography/CmcText.vue";
import FormRow from "@/components/form/FormRow.vue";
import BaseMultiSelect from "@/components/BaseMultiSelect.vue";

export default {
  name:'CustomFieldComponent',
  components: { BaseMultiSelect, FormRow, CmcText, BaseSelect, BaseInput, MultiSelectWithCount },
  props: {
    customFields: {
      type: Array,
      default: undefined
    },
    routeName: {
      type: String,
      default: undefined
    },
    locale: {
      type: String,
      default: undefined
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: undefined,
    }
  },
  emits: ['update:modelValue'],
  computed: {
    customFieldsToDisplay() {
      const fields = (this.customFields || []).map((f) => ({
        ...f,
        values: f.values?.map((t) => ({
          value: t.value,
          translationMap: t.translationMap,
          label: t.translationMap[this.locale],
        })) || [],
      }));

      if (this.routeName === 'addOrganization' || this.routeName === 'addUser') {
        return fields.filter((cf) => !(cf.mode === 'AUTOGENERATED'));
      }

      return fields;
    },
    fieldErrorsToLabels() {
      const fieldToLabel = {};
      this.errors?.filter(err => err.context && err.context.field && err.context.labelKey)
        .filter(err => this.customFields.some(cf => cf.field === err.context.field))
        .forEach((err) => {
          fieldToLabel[err.context.field] = this.$t(err.context.labelKey, err.context)
        });
      return fieldToLabel;
    }
  },
  methods: {
    multiselectWithCountItems(customFieldOptions) {
      if (customFieldOptions) {
        return customFieldOptions.map(val => ({
          isI18n: false,
          name: val.translationMap[this.locale],
          type: "option",
          value: val.value,
        }));
      }
      return customFieldOptions;
    },
    updateModel(fieldName,value) {
      this.$emit('update:modelValue', fieldName,  value);
    },
    updateMultiselectValue(fieldName, value) {
      if (value === undefined || value.length === 0) {
        this.$emit('update:modelValue', fieldName, undefined);
        return;
      }
      const valuesAsStringArray = [];
      value.forEach(v => valuesAsStringArray.push(v.value));
      this.$emit('update:modelValue', fieldName, valuesAsStringArray);
    },
    buildFilter(fromField) {
      const valuesAsOptions = this.model[fromField.field]?.map(v =>
        fromField.values.filter(value => value.value === v)[0]
      );
      return {
        clearable: true,
        singleOption: false,
        loadDefaultValue: false,
        values: valuesAsOptions,
        hasSelectedValue: !!this.model[fromField.field]
      };
    }
  },
};
</script>
<style scoped lang="scss">
.multiselect-no-counter {
  max-width: 600px;
  margin: 0;
  :deep(.container) {
    margin: 0;
  }
  :deep(.input-container) {
    border: 1px solid var(--input-placeholder-text);
  }
}

</style>
