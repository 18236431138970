<template>
  <MultiSelectWithCount
    :modelValue="modelValue"
    :items="items"
    :disabled="disabled"
    :name="name"
    :description="description"
    :placeholderLabel="placeholderLabel"
    :countLabel="countLabel"
    :errors="errors"
    nameI18n
    descriptionI18n
    @update:modelValue="updateModel($event)"
  />
</template>

<script>
import MultiSelectWithCount from '@/app/Main/Administration/customfields/MultiSelectWithCount';

export default {
  name: "MultiselectWithCounterFormElement",
  components: { MultiSelectWithCount },
  props: {
    modelValue: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formElement: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    items() {
      return this.formElement?.options;
    },
    name(){
      return this.formElement?.label || '';
    },
    description() {
      return this.formElement?.descriptionLabel || '';
    },
    placeholderLabel(){
      return this.formElement?.placeholderLabel;
    },
    countLabel() {
      return this.formElement?.countLabel;
    },
  },
  methods: {
    updateModel(updatedModelValue) {
      if (updatedModelValue === undefined || updatedModelValue.length === 0) {
        this.$emit('update:modelValue', []);
        return;
      }
      this.$emit('update:modelValue', updatedModelValue);
    },
  },
};
</script>